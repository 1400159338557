import React, { useEffect, useState } from 'react';
import { SSO } from 'constants/general'
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import {SHA1} from 'functions/generateSHA1'

/* New */
import Header from 'pages/Header/HeaderSimple';
import './style.sass';
import Loading from 'components/Loading';
import { useIsMobile } from 'hooks/useIsMobile';
import { ErrorBoundary } from 'components/Error/ErrorBoundary'


/* Old */
import { useLogin } from 'hooks/useLogin'
import ErrorType from 'pages/Error/ErrorType';
import LoginForm from 'components/LoginRegistro/Login';


export default function Login() {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Login Page');
        window.scrollTo(0, 0);
    }, [])

    const { isMobile } = useIsMobile();

    useEffect(() => {
        if (isMobile) {
            if (isMobile === 'desktop') {
                document.querySelector('#root').classList.add('desktopView')
            }
            else{
                document.querySelector('#root').classList.add('loginHeaderMobile')
            }
        }
    }, [isMobile])


    return(
        <>
            <ErrorBoundary>
                <Header pageClass="loginHeader" isMobile={isMobile} pagina="login"/>
            </ErrorBoundary>

            {SSO
                ? SSO.active
                    ? <LoginSSO />
                    : <LoginOld isMobile={isMobile} />
                : <LoginOld isMobile={isMobile} />
            }
        </>
    )
}


export function LoginSSO() {

    const isLoged = decodeStorage('session_token');
    const dateActual = new Date()
    const rand = Math.random()
    const [loading, setLoading] = useState(true)

    const redirURL =  encodeURIComponent(`${window.location.origin}/authenticated`)
    const redirectURL = encodeURIComponent( window.location.href.split('?redirect=')[1] )

    useEffect(() => {
        
        if(redirectURL != "undefined" && redirectURL != null){
            encodeStorage('redirectURL', redirectURL)
        }else{
            removeStorage('redirectURL')
        }

        if(!isLoged){
            let state = SHA1(rand+'|'+dateActual)
            let nonce = SHA1((rand * 2) +'|'+dateActual)
            encodeStorage('stateSSO', state)
            encodeStorage('nonceSSO', nonce )
            window.location.href = `${SSO.ssoURL}/auth/realms/${SSO.realmSSO}/protocol/openid-connect/auth?client_id=${SSO.clientSSO}&redirect_uri=${redirURL}&state=${state}&response_mode=fragment&response_type=code&scope=openid&nonce=${nonce}`
        }else{
            let redirectURL = decodeStorage('redirectURL')
            if(redirectURL){
                window.location.href = decodeURIComponent( redirectURL )
            }else{
                window.location.href = window.location.origin
            }
        }

    },[])

    return(
        <>
            {loading && <Loading />}
            <main>
                <p></p>
            </main>
        </>
        
    )

}


export function LoginOld(props) {
    const {
        isMobile
    } = props

    const {
        dataLogin,
        reciveDataLogin,
        reciveDataFacebook
    } = useLogin()

    return (
        <>
            {dataLogin.loading && <Loading />}
            <main>
                {dataLogin.statusCode !== 500
                    ?
                    <>
                        {!dataLogin.isLoged &&
                            <section className="loginRegistroPage">
                                <div className="container">
                                    <h1 className="strong">Bienvenido</h1>
                                    <ErrorBoundary>
                                        <LoginForm
                                            dataForm={reciveDataLogin}
                                            warningMessage={dataLogin.errorMessage}
                                            urlRedirect={dataLogin.redir}
                                            responseFacebook={reciveDataFacebook}
                                            isMobile={isMobile}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </section>
                        }
                    </>
                    :
                    <>
                        <ErrorBoundary>
                            <ErrorType
                                codeStatus={500}
                                errorText={""}
                            />
                        </ErrorBoundary>
                    </>
                }

            </main>
        </>
    )
}