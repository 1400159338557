import React, { useState, useEffect } from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import ModalVideoProduct from 'components/Modal'
import { useIsMobile } from 'hooks/useIsMobile'

export function VideoProductComponent(props){

    const {
        video
    } = props
    
    const history = useHistory()
    const location = useLocation()
    const urlLink = window.location.href
    const hashLink = window.location.hash
    const { isMobile } = useIsMobile()

    const [showModal, SetShowModal] = useState(false)
    const showVideoProduct = () => {
        SetShowModal(true)
    }
    const handleClose = () => {
        history.push(location.pathname)
    }
    useEffect(()=>{
        if(hashLink === "#modalVideoProduct"){
            // console.log('video.length', video.length)
            if(video){
                if(video.length >= 1){
                    showVideoProduct()
                }
                else{
                    history.replace(location.pathname)
                }
            }
        }else{
            SetShowModal(false)
        }
    },[hashLink, urlLink])

    return(
        <>
            {video &&
                video.length >= 1 &&
                    <Link to={"#modalVideoProduct"} className="videoProduct" ></Link>
            }
            {showModal &&
                <ModalVideoProduct onClose={handleClose} type={isMobile === "desktop" ? "showVideoP" : "full"} title={isMobile === "desktop" ? "" :""}>
                    <div className="videoYtProduct">
                        {video.map(({ url, order }, i) =>
                            <iframe width="460" height="266" src={`${url}?enablejsapi=1&version=3&playerapiid=ytplayer`} frameborder="0" allowfullscreen="allowfullscreen" key={order} d={i} data-slide-index={i} className="ytVideo"></iframe>
                            )
                        }
                    </div>     
                </ModalVideoProduct>
            }
        </>
    )

}