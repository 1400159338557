import {BASE_PATH_API, STAGE_DIRNAME, BASE_PATH_API_DEV} from 'constants/general'
import axios from 'axios'
import { getCategoryAdapter, getCategoryAdapterOld } from 'adapters/category_Adapter'


export default async function getCategoryMenu() {
    const apiURL = BASE_PATH_API + '/categories/v1/oldapirest'

    const config = {
        method: 'get',
        url: apiURL
    };


    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        let errorMsg = "Network Error"
        if(error.request.status !== 0){
            errorMsg = error.response.data.msg
        }
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': errorMsg
        }
    })
}

export async function getCategoryMenuV1() {
    let apiURL = null
    
    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.home}/categories/v1/category/tree`
    }else{
        apiURL = `${BASE_PATH_API}/categories/v1/category/tree`
    }

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        }
    };


    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data.data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        let errorMsg = "Network Error"
        if(error.request.status !== 0){
            errorMsg = error.response.data.msg
        }
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': errorMsg
        }
    })
}

export async function getCategoryMenuNew() {
    let apiURL = null
    
    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.home}/categories/v1/category/tree`
    }else{
        apiURL = `${BASE_PATH_API}/categories/v1/category/tree`
    }

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        }
    };


    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': getCategoryAdapter(data),
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        let errorMsg = "Network Error"
        if(error.request.status !== 0){
            errorMsg = error.response.data.msg
        }
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': errorMsg
        }
    })
}


export async function getCategoryMenuTree(id) {
    let apiURL = null

    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.home}/categories/v1/category/${id}/tree`
    }else{
        apiURL = `${BASE_PATH_API}/categories/v1/category/${id}/tree`
    }

    const config = {
        method: 'get',
        url: apiURL
    };


    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        let errorMsg = "Network Error"
        if(error.request.status !== 0){
            errorMsg = error.response.data.msg
        }
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': errorMsg
        }
    })
}