import React, { useState, useEffect } from 'react';
import { getPedido } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import { ModalError } from './vista4-modal';
import ModalPortal from 'components/Modal';
import { PEDIDOS_PRODUCTO_ENTREGADO, NO_IMAGE } from 'constants/general';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useIsMobile } from 'hooks/useIsMobile';
import { MotivoCancelacionDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista3-motivo-desktop.js';
import { useToken } from 'hooks/useToken';
import { repareTextHtml } from 'functions/repareDataService';
import { decodeStorage } from 'functions/storageBase64';

export function MotivoCancelacionMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [orderNumber, setOrderNumber] = useState(0);
    const [productos, setProductos] = useState(false);
    const [estatusPedido, setEstatusPedido] = useState(0);

    const [valorInput, setValorInput] = useState("");
    const [contador, setContador] = useState(0);
    const [row, setRow] = useState(1);
    const [showModalError, SetShowModalError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idClaro, setIdClaro] = useState(false);
    const [statusProducto, setStatusProducto] = useState(0);
    const [guia, setGuia] = useState(0);
    const [saveIdStatusProducto, setSaveIdStatusProducto] = useState(false);
    const [productoUnico, setProductoUnico] = useState(false);
    const [formaPago, setFormaPago] = useState(false);
    const profileData = useStorage64('profileData');
    const [, setSaveStatus] = useStorage64('saveStatus', '');
    const [saveStatusProducto,] = useStorage64('saveStatusProducto', '');
    const [, setStorageMotivoEntregado] = useStorage64('storageMotivoEntregado', '');
    const [nombreProducto, setNombreProducto] = useState(false);
    const [ima, setima] = useState(false);
    const actionSeleccionada = decodeStorage("storageActionId");

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // obtengo el id del usuario del storage
    useEffect(() => {
        if (profileData != "undefined") {
            setIdClaro(profileData[0]?.idClaro);
        }
    }, [profileData])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i]?.indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista3-cuentanos mas / cual es la falla-Mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let productosInterno = [];
        let producto = [];
        let productoGuia = [];
        let productoIdStatus = [];
        let imagenProducto = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                if (resultadoPedido.pedido && Number(idProductoEstate) === 0) {

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        productosInterno.push({ "id": elem.related_product_id })
                    )
                    setProductos(productosInterno);
                    setOrderNumber(resultadoPedido.pedido[0].order_number);
                    setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setFormaPago(resultadoPedido.pedido[0].payment_method.id);

                }
                // recorre por producto
                else if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {

                    if (resultadoPedido.pedido[0]) {

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && producto.push({ "id": elem.related_product_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoGuia.push({ "code": elem.tracking.code })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoIdStatus.push({ "status_id": elem.status_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem?.images[0]?.url, "id": elem.related_product_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )

                        let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                        setProductoUnico(limpiaProducto);
                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setNombreProducto(productoNombre);

                        setStatusProducto(saveStatusProducto);
                        let limpiaGuia = removeDuplicates(productoGuia, idProductoEstate);
                        setGuia(limpiaGuia[0].code);

                        setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setOrderNumber(resultadoPedido.pedido[0].order_number);
                        setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                        setSaveIdStatusProducto(productoIdStatus[0].status_id);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);
                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 2000);
            }
        }
    }, [resultadoPedido])

    // regresar pagina anterior
    const onClose = () => {
        history.goBack(-1);
    }

    // continuar eliminando pedido, llama a modal estas seguro
    const handleContinuar = () => {
        // producto entregado
        if (PEDIDOS_PRODUCTO_ENTREGADO.includes(saveIdStatusProducto)) {
            setStorageMotivoEntregado(valorInput);
            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/adjuntar-fotografias");
        }
    }

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // actualizar valor del texto en input
    const cambiarValorInput = (valor) => {
        let texto = valor.target.value;
        let cont = String(texto).length;

        setValorInput(texto);
        setContador(cont);

        var maxrows = 8;
        var txt = texto;
        var cols = valor.target.cols;
        let arraytxt = txt.split('\n');
        let rows = arraytxt.length;

        for (let i = 0; i < arraytxt.length; i++) {
            rows += Number(arraytxt[i].length / cols);
        }
        if (rows > maxrows) { setRow(maxrows); }
        else { setRow(rows); }
    }


    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }


    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        {Number(actionSeleccionada) === 10
                            ? <p className="title">¿Cuál es la falla?</p>
                            : <p className="title">Cuéntanos más de tu caso</p>
                        }
                    </div>

                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            {ima && ima.length > 1
                                ? <div className="contenedorImagenesMas1">
                                    <ul className='ulMas1'>
                                        {ima && ima.map((elemento, i) => i <= 2 && <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={50} />
                                        </li>
                                        )}
                                        {/* mas de 3 */}
                                        {ima.length > 3 && <li className="mas1"> más ({ima.length - 3})</li>}
                                    </ul>
                                </div>
                                : <div className="contenedorImagenesIgual1">
                                    <ul className='ulIgual1'>
                                        {ima && ima.map((elemento, i) =>
                                            <li key={i}>
                                                <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            }
                            <div className='tituloImagenes'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                        </div>

                        <div className="boxMotivoCancelacion marginT60">
                            <textarea className={"inputMotivo"} placeholder={Number(actionSeleccionada) === 10 ? "Describe la falla que presenta el producto" : "Escribe más a detalle tu caso"} cols={40} rows={row} value={valorInput} onChange={cambiarValorInput} type="text" maxLength={250} minLength={10}></textarea>
                            <div className="contadorCaracteres"> {contador}/250</div>

                            <div className="boxBtn marginT60">
                                <button className="btnContinuar" onClick={handleContinuar} disabled={valorInput.length === 0 ? true : false}>Continuar</button>
                            </div>
                        </div>
                    </div>

                    {showModalError &&
                        <ModalPortal type="modalSB" onClose={handleCloseError} >
                            <ModalError okRegresar={regresarPedidos} />
                        </ModalPortal>
                    }
                </div>
            </div>

        </>
    )
}

export function MotivoCancelacion(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <MotivoCancelacionDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <MotivoCancelacionMovil />
                : null
            }
        </>
    )
}