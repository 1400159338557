import React, {useEffect, useState} from 'react';
import './style.sass';

export function StarsPrincipal2({ ...props }) {
    const {
        score
    } = props;

    const cero5 = 0.5;
    const cero0 = 0;
    var decimales = score % 1;

    return (
        <div className="contStarsModule">
            { (Number(decimales) < Number(cero5) || Number(decimales) === Number(cero0))
                ? <div className="estrellasPintadas">
                    {/* siempre pinta estrellas completas */}
                    {Math.floor(score) >= 1  && <span id="1" className="startAmarilla"></span>}
                    {Math.floor(score) >= 2  && <span id="2" className="startAmarilla"></span>}
                    {Math.floor(score) >= 3  && <span id="3" className="startAmarilla"></span>}
                    {Math.floor(score) >= 4  && <span id="4" className="startAmarilla"></span>}
                    {Math.floor(score) === 5 && <span id="5" className="startAmarilla"></span>}

                    {Math.floor(score) < 5 && <span id="5" className="startVacia"></span>}
                </div>
                : Number(decimales) >= Number(cero5)
                    ? <div className="estrellasPintadas">
                        {/* pinta una estrella por la mitad */}
                        {Math.floor(Number.parseFloat(score).toFixed(1)) === 1 && <>
                            <span id="1" className="startAmarilla"></span>
                            <span id="2" className="startMitad"></span>
                            <span id="3" className="startGris"></span>
                            <span id="4" className="startGris"></span>
                            <span id="5" className="startGris"></span>

                        </>}
                        {Math.floor(Number.parseFloat(score).toFixed(1)) === 2 && <>
                            <span id="1" className="startAmarilla"></span>
                            <span id="2" className="startAmarilla"></span>
                            <span id="3" className="startMitad"></span>
                            <span id="4" className="startGris"></span>
                            <span id="5" className="startGris"></span>
                        </>}
                        {Math.floor(Number.parseFloat(score).toFixed(1)) === 3 && <>
                            <span id="1" className="startAmarilla"></span>
                            <span id="2" className="startAmarilla"></span>
                            <span id="3" className="startAmarilla"></span>
                            <span id="4" className="startMitad"></span>
                            <span id="5" className="startGris"></span>
                        </>}

                        {Math.floor(Number.parseFloat(score).toFixed(1)) === 4 && <>
                            <span id="1" className="startAmarilla"></span>
                            <span id="2" className="startAmarilla"></span>
                            <span id="3" className="startAmarilla"></span>
                            <span id="4" className="startAmarilla"></span>
                            <span id="5" className="startMitad"></span>
                        </>}

                        {Math.floor(Number.parseFloat(score).toFixed(1)) === 5 && <>
                            <span id="1" className="startAmarilla"></span>
                            <span id="2" className="startAmarilla"></span>
                            <span id="3" className="startAmarilla"></span>
                            <span id="4" className="startAmarilla"></span>
                            <span id="5" className="startAmarilla"></span>
                        </>}

                    </div>
                    : null
            }
        </div >
    )
}



export default function StarsPrincipal({ ...props }) {
    const {
        score
    } = props;

    const cero5 = 0.5;
    const cero0 = 0;

    const [classStart, setClassStart]= useState(false);
    const valuateStars = () =>{
        let decimales = score % 1;
        if(Number(decimales) < Number(cero5) || Number(decimales) === Number(cero0)){
            setClassStart(`star_${ Math.floor(Number.parseFloat(score).toFixed(1)) }`);
        }else if(Number(decimales) >= Number(cero5)){
            setClassStart(`star_${ Math.floor(Number.parseFloat(score).toFixed(1)) }_5`);
        }
    };
    
    useEffect(()=>{
        valuateStars();
    },[score]);

    return (
        <div className={`stars ${classStart}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}