import React, { useState, useEffect } from 'react'
import './questions.sass'

export default function QuestionsMiTelmexPage(props) {
    const { preguntas } = props

    //- Codigo de instana para track de pagina 
    useEffect(()=>{
        window.ineum('page', 'My Account | Mi Telmex - Questions');
    },[])

    const handleClickContinuar = () => {
        console.log("presiono continuar...")
    }

    return (
        <>
         
            <div className="contenedorQuestions">
                <div className="titleQuestions">
                    <h3>Preguntas</h3>
                </div>

                <form className="boxQuestions">
                    <ul>
                        {preguntas.map((pre, index) => {
                            return <li key={index}> {index + 1}- {pre.question}
                                <label>Si <input className="radio" type="radio" value={1} name={pre.question_number} /></label>
                                <label>No <input className="radio" type="radio" value={2} name={pre.question_number} /></label>
                            </li>
                        })}
                    </ul>

                    <button className="btn btn-red" onClick={handleClickContinuar}> Continuar </button>
                </form>

            </div>


        </>
    )
}