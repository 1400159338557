import React from 'react';
import './style.sass';

import BoxNuestraCompania from 'components/Footer/Upperfooter/BoxNuestraCompania';
import BoxDejanosAyudarte from 'components/Footer/Upperfooter/BoxDejanosAyudarte/index.js';
import BoxPoliticasSears from 'components/Footer/Upperfooter/BoxPoliticasSears';
import BoxRedes  from 'components/Footer/Upperfooter/BoxRedes'

export default function UpperFooter() {
    return (
        <div className="upperfooter">
            <BoxNuestraCompania />
            <BoxPoliticasSears />
            <BoxDejanosAyudarte />
            <BoxRedes />
        </div>
    )
}