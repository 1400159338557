import { useState, useEffect } from 'react'
import { decodeStorage } from 'functions/storageBase64'
import { useUserInfo } from 'hooks/usePersonalData'

export function useTWA(){

	let url = window.location.href
	let twa = url.split('?')[1]
	let movil =  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
	let twaSession = window.sessionStorage.getItem("twa")
	let userAgent = navigator.userAgent
	
	//let twaHeight = window.localStorage.getItem("heighTWA")
	let pageHeight = String(window.innerHeight)

	const [okTWA, setOkTWA] = useState(false)
	const [isTWA, setIsTWA] = useState(false)
	const [okSessionTWA, setOkSessionTWA] = useState(false)

	// const [validaTwaHeight, setValidaTwaHeight] = useState(false)
	
	let protocol = `${window.location.protocol}`
	let NotifSSID = window.localStorage.getItem("NotifSSID")
	const isLoged = decodeStorage('session_token')
	const { profileGet:profileData } = useUserInfo()

	useEffect(()=>{
		if(movil){
			if(twa?.indexOf("app_twa_manifest") >= 0){
				setOkTWA(true)
				if(!twaSession){
					setIsTWA(true)
				}
			}else{
				setOkTWA(false)
				setOkSessionTWA(true)
			}
		}
	},[])
	
	useEffect(()=>{
		if(okTWA){
			if(!twaSession){
				window.ineum('meta', 'portal', 'TWA')
				window.sessionStorage.setItem("twa","TWA")
				twaSession = window.sessionStorage.getItem("twa")
				setIsTWA(true)
			}
		}else{
			if(okSessionTWA){
				if(twaSession === "TWA"){
					window.ineum('meta', 'portal', 'TWA')
					window.sessionStorage.setItem("twa","TWA")
					twaSession = window.sessionStorage.getItem("twa")
					setIsTWA(true)
					if(!movil){
						window.sessionStorage.removeItem("twa")
						setIsTWA(false)
					}
				}else{
					window.sessionStorage.setItem("twa","NoTWA")
					twaSession = window.sessionStorage.getItem("twa")
					setIsTWA(false)
				}
			}else{
				if(twaSession === "TWA"){
					window.ineum('meta', 'portal', 'TWA')
					window.sessionStorage.setItem("twa","TWA")
					twaSession = window.sessionStorage.getItem("twa")
					setIsTWA(true)
					if(!movil){
						window.sessionStorage.removeItem("twa")
						setIsTWA(false)
					}
				}
			}
		}
	},[okTWA, okSessionTWA])


	// useEffect(()=>{
	// 	if(movil){	
	// 		if(twa === "app_twa_manifest"){
	// 			if(!twaHeight){
	// 				window.localStorage.setItem("heighTWA", window.innerHeight)
	// 				setValidaTwaHeight(false)
	// 				setOkTWA(true)
	// 			}
	// 			else{
	// 				setValidaTwaHeight(true)
	// 			}
	// 		}
	// 	}
	// },[validaTwaHeight])


	// useEffect(()=>{
	// 	if(!twaSession){
	// 		if(pageHeight === twaHeight){
	// 			window.ineum('meta', 'portal', 'TWA')
	// 			window.sessionStorage.setItem("twa","TWA")
	// 			twaSession = window.sessionStorage.getItem("twa")
	// 			setIsTWA(true)
	// 			setOkSessionTWA(true)
	// 		}
	// 		else{
	// 			window.sessionStorage.setItem("twa","NoTWA")
	// 			twaSession = window.sessionStorage.getItem("twa")
	// 			setIsTWA(false)
	// 			setOkSessionTWA(true)
	// 		}
	// 	}else{
	// 		if(twaSession === "TWA"){
	// 			if(pageHeight === twaHeight){
	// 				window.ineum('meta', 'portal', 'TWA')
	// 				window.sessionStorage.setItem("twa","TWA")
	// 				twaSession = window.sessionStorage.getItem("twa")
	// 				setIsTWA(true)
	// 				setOkSessionTWA(false)
	// 			}
	// 			else{
	// 				window.sessionStorage.setItem("twa","NoTWA")
	// 				twaSession = window.sessionStorage.getItem("twa")
	// 				setIsTWA(false)
	// 				setOkSessionTWA(true)
	// 			}
	// 			if(!movil){
	// 				window.sessionStorage.removeItem("twa")
	// 				setIsTWA(false)
	// 			}
	// 		}else{
	// 			if(pageHeight === twaHeight){
	// 				window.ineum('meta', 'portal', 'TWA')
	// 				window.sessionStorage.setItem("twa","TWA")
	// 				twaSession = window.sessionStorage.getItem("twa")
	// 				setIsTWA(true)
	// 				setOkSessionTWA(true)
	// 			}else{
	// 				window.sessionStorage.setItem("twa","NoTWA")
	// 				twaSession = window.sessionStorage.getItem("twa")
	// 				setIsTWA(false)
	// 				setOkSessionTWA(true)
	// 			}
	// 		}
	// 	}
	// },[okTWA, okSessionTWA])

	//Push notification servicio de jafet
	// useEffect(()=>{
	// 	let session
	// 	if(isTWA){
	// 		if (isLoged) {
	// 			if (profileData.idClaro) {
	// 				if(!NotifSSID){
	// 					if(url.indexOf('?') >= 1){
	// 						session = `searsapp:${url.replace(protocol, '')}&SSID=${profileData.idClaro}`
	// 					}else{
	// 						session = `searsapp:${url.replace(protocol, '')}?SSID=${profileData.idClaro}`
	// 					}
	// 					window.localStorage.setItem("NotifSSID","true")
	// 					window.location.href = session
	// 					console.log("NotifSSID 1 :",session)
	// 				}
	// 				else{
	// 					if(NotifSSID === "false"){
	// 						if(url.indexOf('?') >= 1){
	// 							session = `searsapp:${url.replace(protocol, '')}&SSID=${profileData.idClaro}`
	// 						}else{
	// 							session = `searsapp:${url.replace(protocol, '')}?SSID=${profileData.idClaro}`
	// 						}
	// 						window.localStorage.setItem("NotifSSID","true")
	// 						window.location.href = session
	// 						console.log("NotifSSID 2 :",session)
	// 					}
	// 				}
	// 			}
	// 		}
	// 		else{
	// 			if(!NotifSSID){
	// 				if(url.indexOf('?') >= 1){
	// 					session = `searsapp:${url.replace(protocol, '')}&SSID=null`
	// 				}else{
	// 					session = `searsapp:${url.replace(protocol, '')}?SSID=null`
	// 				}
	// 				window.localStorage.setItem("NotifSSID","false")
	// 				window.location.href = session
	// 				console.log("NotifSSID 3 : ",session)
	// 			}else{
	// 				if(NotifSSID === "true"){
	// 					if(url.indexOf('?') >= 1){
	// 						session = `searsapp:${url.replace(protocol, '')}&SSID=null`
	// 					}else{
	// 						session = `searsapp:${url.replace(protocol, '')}?SSID=null`
	// 					}
	// 					window.localStorage.setItem("NotifSSID","false")
	// 					window.location.href = session
	// 					console.log("NotifSSID 4 : ",session)
	// 				}
	// 			}
	// 		}
	// 	}
	// },[isLoged, profileData, isTWA])


	const [ruta, setRuta] = useState()
	const [ruta2, setRuta2] = useState()
	const [ruta3, setRuta3] = useState()
	const [ruta4, setRuta4] = useState()
	const [ruta5, setRuta5] = useState()
	useEffect(()=>{
		setRuta( url )
		setRuta2( userAgent )
		setRuta3( String(isTWA) )
		setRuta4( pageHeight )
		setRuta5( twaSession )
		// console.log(String(isTWA))
	},[isTWA])

    const valResp = [
        ruta,
        ruta2,
        ruta3,
        ruta4,
        ruta5
    ]

    return {
        isTWA,
        valResp
    }
}