import {BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL} from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'


//- Get Delivery date
export async function getDeliveryDate({...props}) {
    // trae la variable codigo postal
    const codigoPostal  = decodeStorage('codigoPostal')
    
    const {
        idProduct,
        sku = '',
        codPostal = codigoPostal
    } = props

    let skuProduct
    if(sku === 0){
        skuProduct = ''
    }
    else{
        skuProduct = sku
    }

    const apiURL = `${BASE_PATH_API}/shipping/v1/delivery`
    
    const raw = JSON.stringify({
        "cp": codPostal,
        "id": idProduct,
        "sku": skuProduct
    })

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        data: raw
    }

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        // console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}
