import React, { useState, useEffect } from 'react';
import './style.sass';
import Modal from 'components/Modal';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';
import { removeStorage } from 'functions/storageBase64';
// import {useStorage64} from 'hooks/useStorageBase64';
import ModuleIsLogin from 'components/BarCP/ModuleIsLogin';
import ModuleNotLogin from 'components/BarCP/ModuleNotLogin';
import { Link, useHistory, useLocation } from 'react-router-dom';

export default function BarraCP(props) {
    const {
        changeCP,
        clase,
        fechaEstimada
    } = props;

    const history = useHistory();
    const location = useLocation();
    const hashLink = window.location.hash;
    const urlLink = window.location.href;

    const isLoged = decodeStorage('session_token');
    const codigoP = decodeStorage('codigoPostal');
    const shipping = decodeStorage('shipping');
    const dirPredeterminada = decodeStorage('dirPredeterminada');

    const [showModal, setShowModal] = useState(false);
    const [codigoPostal, setCodigoPostal] = useState(codigoP);

    const showModalCP = () => {
        setShowModal(true);
    }

    useEffect(() => {
        if (hashLink === "#modalPostalCode") {
            if (!decodeStorage('modalPostalCode')) {
                encodeStorage('modalPostalCode', true);
                setShowModal(true);
            }
        }
        else {
            removeStorage('modalPostalCode');
            setShowModal(false);
        }
    }, [hashLink, urlLink])

    //- funcion para visualizar el modal de cp
    const handleClose = () => {
        history.push(location?.pathname);
        setShowModal(false)
    }

    //- Funcion que devuelve el cp y lo agrega al storage
    const changeDirectionBar = (val) => {
        changeCP(val);
        encodeStorage('codigoPostal', val);
        setCodigoPostal(val);
        history.push(location?.pathname);
    }

    //efecto para re-renderizar cuando cambia el CP o la direccion
    useEffect(() => {
        if (codigoPostal) {
            // console.log('codigoPostal ',codigoPostal)
            // console.log('codigoP ',codigoP)
            setCodigoPostal(codigoP);
        }
        if (dirPredeterminada) {
            //console.log('dirPredeterminada ',dirPredeterminada)
            if (!codigoPostal) {
                // console.log('codigoP ',codigoP)
                setCodigoPostal(codigoP);
            }
        }
    }, [codigoPostal, dirPredeterminada, codigoP])

    const showMenu = () => {
        if(clase === "cpDelivery" && fechaEstimada){
            return
        }else{
            history.push('#modalPostalCode');
        }
    }

    // console.log("codigo postal--> ", codigoPostal, "--", codigoP, " direccion predeterminada--> ", dirPredeterminada, " CLASE--> ", clase)
    return (
        <div className={clase}>
            <div className="titleCP" onClick={showMenu}>
                {codigoP
                    ? clase === 'barHeaderCP'
                        ?
                            <p>
                                <span className="firstTextCP">{`Enviar a ${dirPredeterminada ? dirPredeterminada?.nombre + ',' : ''} `} </span>
                                <span className="lastTextCP">{`${codigoPostal ? codigoPostal : codigoP}, ${dirPredeterminada ? dirPredeterminada.calle : shipping?.municipio}`}</span>
                            </p>
                        : null

                    : (clase === 'barHeaderCP'
                        ? <p>Introduce un Código Postal</p>
                        : clase === "cpDetalle"
                            ? <div className="etiquetaP"><p className="dateDelivery">Conoce cuando te llega</p></div>
                            : <p className="cp">Introduce un C.P</p>
                    )
                }

                {codigoP
                    ? (clase !== 'barHeaderCP' && clase !== 'fechaEstimada' && clase !== "cpDelivery")
                        ? <p className="textoDireccion">{`${dirPredeterminada ? dirPredeterminada?.nombre + ',' : ''} ${dirPredeterminada ? dirPredeterminada?.municipio : shipping?.municipio} ${codigoPostal}`}</p>
                        : null
                    : null
                }

                {codigoP
                    ? clase === 'fechaEstimada' && <p className="icono"></p>
                    : clase === "fechaEstimada" && <p className="icono"></p>
                }

                {/* tiene codigo postal pero no direccion  */}
                {codigoP && !dirPredeterminada
                    ? clase === "cpDelivery" && fechaEstimada
                        ?
                            <div className="dateDeliveryConFecha">
                                <span className=""> {fechaEstimada} </span>
                                {codigoP &&
                                    <div className="cpBloqye">
                                        <p onClick={showMenu}> Enviar a C.P. {codigoP}</p>
                                    </div>
                                }
                            </div>
                        : null
                    : codigoP && dirPredeterminada && clase === "cpDelivery" && fechaEstimada
                        ?
                            <div className="dateDeliveryConFecha">
                                <span className=""> {fechaEstimada} </span>
                                {codigoP &&
                                    <div className="cpBloqye">
                                        <p onClick={showMenu}> Enviar a {dirPredeterminada?.nombre}, C.P. {codigoP}</p>
                                    </div>
                                }
                            </div>
                        : null
                }
            </div>

            {showModal &&
                <Modal onClose={handleClose} closeBody="modalcp" >
                    {isLoged?.length > 0
                        ? <ModuleIsLogin returnDirection={changeDirectionBar} onClose={handleClose} />
                        : <ModuleNotLogin returnDirection={changeDirectionBar} onClose={handleClose} />
                    }
                </Modal>
            }
        </div>
    )
}