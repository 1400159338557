import React from 'react';
import './style.sass';
import { BASE_PORTAL } from 'constants/general';

export default function ButtonVenderSears() {

    let url = BASE_PORTAL + "/c/Vender_en_internet/";

    return (
        <>
            <div className="venderSears" id="venderSears">
                <a className="venderSears-btn btn" href={url} target="_blank" rel="noopener noreferrer">
                    Vender en
                </a>
            </div>
        </>
    )
}