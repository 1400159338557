import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from 'pages/Header/HeaderSimple';
import { BASE_PORTAL } from 'constants/general';
// import './style.sass';

export default function Servicios() {
    const history = useHistory();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Section Services');
        window.scrollTo(0, 0);
    }, [])

    const backHistory = () => {
        history.go(-1);
    }

    return (
        <>
            <Header pageClass="hidden" />
            <div className="sectionTitleServicioCliente">
                <button onClick={backHistory}>Servicios</button>
            </div>

            <div className="opcionesCliente">
                <ul className="listaExterna">
                    <Link to="/auto-center" className="enlaces">
                        <li className="telefono"><span className="nombre">Autocenter</span> <span className="icono"></span></li>
                    </Link>

                    <a href={BASE_PORTAL + "/c/servicio-tecnico/index_36.html"} className="enlaces">
                        <li className="telefono"><span className="nombre">Servicio Técnico</span> <span className="icono"></span></li>
                    </a>

                    <a href={"https://serviciosgfi.inbursa.com:4070/multiplataforma/mvc/others/autotalPublico/init?ca=PUBLICO&pa=P&pro=SEARS#no-back-button"} target="_blank" rel="noopener noreferrer" className="enlaces">
                        <li className="telefono"><span className="nombre">Autotal Inbursa</span> <span className="icono"></span></li>
                    </a>

                    <a href="https://www.viajesears.com/" className="enlaces">
                        <li className="telefono"><span className="nombre">Viajes Sears</span> <span className="icono"></span></li>
                    </a>

                    <Link to="/servicio-de-conexion/" className="enlaces">
                        <li className="telefono"><span className="nombre">Servicio de Conexión</span> <span className="icono"></span></li>
                    </Link>
                </ul>
            </div>
        </>
    )
}