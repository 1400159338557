import React, { useState, useEffect } from 'react';
import { getPedido } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import { ModalError } from '../vista4-modal';
import ModalPortal from 'components/Modal';
import { NO_IMAGE } from 'constants/general';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeDuplicates } from 'functions/removeDuplicates';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useToken } from 'hooks/useToken';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import FooterPortal from 'pages/Footer/FooterDesktop';
import { repareTextHtml } from 'functions/repareDataService';

export function ProcesoAclaracionDesktop(props) {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [orderNumber, setOrderNumber] = useState(0);
    const [productos, setProductos] = useState(false);
    const [estatusPedido, setEstatusPedido] = useState(0);

    const [showModalError, SetShowModalError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idClaro, setIdClaro] = useState(false);
    const [statusProducto, setStatusProducto] = useState(0);
    const [guia, setGuia] = useState(0);
    const [saveIdStatusProducto, setSaveIdStatusProducto] = useState(false);
    const [productoUnico, setProductoUnico] = useState(false);
    const [formaPago, setFormaPago] = useState(false);
    const profileData = useStorage64('profileData');
    const [, setSaveStatus] = useStorage64('saveStatus', '');
    const [saveStatusProducto,] = useStorage64('saveStatusProducto', '');
    const [ima, setima] = useState([]);
    const [nombreProducto, setNombreProducto] = useState(false);

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // obtengo el id del usuario del storage
    useEffect(() => {
        if (profileData != "undefined") {
            setIdClaro(profileData[0]?.idClaro);
        }
    }, [profileData])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista31- condiciones del paquete - desktop" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let productosInterno = [];
        let producto = [];
        let productoGuia = [];
        let productoIdStatus = [];
        let imagenesProductos = [];
        let imagenProducto = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                if (resultadoPedido.pedido && Number(idProductoEstate) === 0) {

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        productosInterno.push({ "id": elem.related_product_id })
                    )
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        imagenesProductos.push({ "url": elem.images[0]?.url })
                    )

                    setima(imagenesProductos);
                    setProductos(productosInterno);
                    setOrderNumber(resultadoPedido.pedido[0].order_number);
                    setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setFormaPago(resultadoPedido.pedido[0].payment_method.id);

                }
                // recorre por producto
                else if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {

                    if (resultadoPedido.pedido[0]) {

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && producto.push({ "id": elem.related_product_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoGuia.push({ "code": elem.tracking.code })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoIdStatus.push({ "status_id": elem.status_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )
                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setNombreProducto(productoNombre);

                        let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                        setProductoUnico(limpiaProducto);

                        setStatusProducto(saveStatusProducto);
                        let limpiaGuia = removeDuplicates(productoGuia, idProductoEstate);
                        setGuia(limpiaGuia[0].code);

                        setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setOrderNumber(resultadoPedido.pedido[0].order_number);
                        setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                        setSaveIdStatusProducto(productoIdStatus[0].status_id);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // regresar pagina anterior
    const onClose = () => {
        history.goBack(-1);
    }

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    //redirecciona a pagina: tengo mi paquete
    const irTengoMiPaquete = () => {

        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/tengo-mi-paquete");
    }

    // redirecciona a pagina: no termino como esperabamos
    const iniciarAclaracion = () => {

    }

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">
                        <div className='menuMiCuentaAD'>
                            <MenuMiCuenta />
                        </div>

                        <div className='contenidoGeneralAD'>
                            <div className="boxBtnRegresarDktop">
                                <div className="icoBack" onClick={onClose}></div>
                                <button className="btnBackAD" onClick={onClose}>Proceso de Aclaración</button>
                            </div>

                            <div className="boxGeneralAD">
                                <div className="boxLeftAD">
                                    <div className="contSectionRed">
                                        <div className={ima.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                            {ima.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={70} />)}
                                        </div>

                                        {ima && ima.length > 1
                                            ? <div className="textDektop"><p>Proceso de Aclaración</p></div>
                                            : <div className='textDektop'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                                        }

                                    </div>
                                </div>

                                <div className="boxRightAD ">

                                    <div className=''>
                                        <h2>Gracias por tu paciencia.</h2>

                                        <p>Por favor selecciona alguna de las siguientes opciones para continuar con tu aclaración</p>
                                    </div>

                                    <div className=''>
                                        <button className='' onClick={irTengoMiPaquete}>Tengo mi paquete</button>
                                    </div>

                                    <div className=''>
                                        <button className='' onClick={iniciarAclaracion}>No tengo mi paquete, iniciar aclaración</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <FooterPortal />
        </>
    )
}
