import {useState, useEffect} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getClicyRecoge } from 'services/ClicRecoge'
import { useIsMobile } from 'hooks/useIsMobile'
import { useImageArray } from 'hooks/useImageArray'


export function useClicyRecoge (id, dataImage, skuChildren, store, size_color, skuChildrenFF) {

    // console.log('id: ', id,' dataImage: ', dataImage,' skuChildren: ', skuChildren,' store: ', store,' size_color: ', size_color,' skuChildrenFF: ', skuChildrenFF)
    
    const hora = new Date()
    const history = useHistory()
    const location = useLocation()
    const urlLink = window.location.href
    const hashLink = window.location.hash
    const { isMobile } = useIsMobile()
    const [fechaDeRecogida, setFechaDeRecogida] = useState()
    const diaActual = ['domingo','lunes','martes','miércoles','jueves','viernes','sábado','domingo','lunes']
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    const diaMas = ['31','28','31','30','31','30','31','31','30','31','30','31']
    const [showModal, SetShowModal] = useState(false)

    const [toast, setToast] = useState(false)
    const [messageToast, setMessageToast] = useState({ type: '', time: '', textMessage: '' })

    //actualiza valores dependiendo del sku
    const [returnCyR, setReturnCyR] = useState()
    const [sendCyR, setSendCyR] = useState(false)
    const [skuEnviado, setSkuEnviado] = useState(skuChildren)
    const [errorMessage, setErrorMessage] = useState()
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        if(skuChildren){
            setSkuEnviado(skuChildren)
        }
    },[skuChildren])

    useEffect(() => {
        if(skuChildren){
            setSkuEnviado(skuChildren)
        }
    },[skuChildren])

    useEffect(()=>{
        if(sendCyR){
            setErrorMessage('')
            setLoading(true)
            getClicyRecoge({'sku': skuEnviado, 'seller_id':store.id})
            .then(setReturnCyR)
            setSendCyR(false)
        }
    },[sendCyR])


    //actualiza el horario dependiendo de las reglas
    useEffect(()=>{
        if(hora.getHours() <= 18 ){
            setFechaDeRecogida(`${diaActual[hora.getDay()]} ${hora.getDate()} ${meses[hora.getMonth()]}`)
        }
        else{
            if(hora.getFullYear() % 400 === 1){
                
                if(hora.getMonth() === 1){
                    if( hora.getDate() + 1 > diaMas[29] ){
                        setFechaDeRecogida(`${diaActual[hora.getDay() +1]} ${1} ${meses[hora.getMonth()+1]}`)
                    }else{
                        setFechaDeRecogida(`${diaActual[hora.getDay() +1]} ${hora.getDate()+1} ${meses[hora.getMonth()]}`)
                    }
                }else{
                    if( hora.getDate() + 1 > diaMas[hora.getMonth()] ){
                        setFechaDeRecogida(`${diaActual[hora.getDay() +1]} ${1} ${meses[hora.getMonth()+1]}`)
                    }else{
                        setFechaDeRecogida(`${diaActual[hora.getDay() +1]} ${hora.getDate()+1} ${meses[hora.getMonth()]}`)
                    }
                }

            }
            
            else{
                if( hora.getDate() + 1 > diaMas[hora.getMonth()] ){
                    setFechaDeRecogida(`${diaActual[hora.getDay() +1]} ${1} ${meses[hora.getMonth()+1]}`)
                }else{
                    setFechaDeRecogida(`${diaActual[hora.getDay() +1]} ${hora.getDate()+1} ${meses[hora.getMonth()]}`)
                }
            }
        } 
    },[skuEnviado, id])

    // actualiza la imagen si no tiene a una por default
    const dataImagenNew = useImageArray(dataImage)

    
    const [dataSucursales, setDataSucursales] = useState([])
    useEffect(()=>{
        if(returnCyR){
            setLoading(false)
            if(returnCyR.statusCode === 200){
                if(returnCyR.data){
                    if(returnCyR.data.data){
                        setDataSucursales(returnCyR.data.data)
                        setErrorMessage('')
                    }
                }
            }if(returnCyR.statusCode === 404){
                setDataSucursales([])
                if(returnCyR?.data?.metadata){
                    setErrorMessage(returnCyR.data?.metadata?.message)
                }
            }
        }
    },[returnCyR])


    const showCyR = () => {
        if(size_color){
            if(size_color.length >=1){
                //console.log('perate')
                if(skuChildren && store.id){
                    if(skuChildrenFF){
                        setSkuEnviado(skuChildrenFF)
                        setSendCyR(true)
                        SetShowModal(true)
                    }else{
                        setMessageToast({ type: 'warning', time: '2.5', textMessage: 'Falta seleccionar Talla / Color' })
                        setToast(true)
                    }
                }
            }else{
                //console.log('todo chido')
                if(skuChildren && store.id){
                    setSendCyR(true)
                    SetShowModal(true)
                }
            }
        }
        //SetShowModal(true)
    }
    const handleClose = () => {
        if (hashLink != ""){
            history.push(location.pathname)
        }else{
            SetShowModal(false)
        }
    }
    useEffect(() => {
        if (hashLink === "#modalCickyRecoge") {
            showCyR()
        } else {
            SetShowModal(false)
        }
    }, [hashLink, urlLink])

    // actualiza el Sku del Talla color
    const [idState, setIdState] = useState(undefined)
    const updateID = (newID) => {
        if(newID != idState){
            setIdState(newID)
            setSkuState(undefined)
        }
    }

    // actualiza el Sku del Talla color
    const [skuState, setSkuState] = useState(undefined)
    const updateSKU = (newSku) => {
        if(newSku != skuState){
            setSkuState(newSku)
            setSkuEnviado(newSku)
        }
    }

    const handleToast = () => {
        setToast(false)
    }

    const dataCyR = {
        fechaDeRecogida,
        dataImagenNew,
        isMobile,
        showModal
    }

    const dataToast = {
        toast,
        messageToast
    }

    return{
        dataCyR,
        dataToast,
        dataSucursales,
        errorMessage,
        loading,
        updateID,
        updateSKU,
        showCyR,
        handleClose,
        handleToast
    }
}