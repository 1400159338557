import React from 'react'
import {Link} from 'react-router-dom'

export default function ErrorWishlistServices () {
    return(
        <div className="errorProductServices">
            <img src="/img/empty_wish_list.svg" alt="Página no encontrada" width="450" height="450" loading="lazy"/>
            <p className="titleError">¡Lista de deseos vacía!</p>
            <p className="suggestion">Toca el corazón para agregar los productos que más te gusten.</p>
            <Link className="btn rojo" to={`/`} title="Ir al inicio">Agregar Productos</Link>
        </div>
    )
}