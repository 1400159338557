import { SEARCH_ANTEATER, PRODUCTS_PER_PAGINATION, BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL } from 'constants/general'
import axios from 'axios'


export default async function getSearch({ searchText = '', brand = '', higherPrice = '', lowerPrice = '', id_Category = '', shipping = '', fulfillment = '', discount = '', start = '', order = '', pagination = '' } = {}) {
    // console.log(" servicio:", { searchText, brand, higherPrice, lowerPrice, id_Category, order, shipping, start }, " fulfillment:", fulfillment, " pagina: ", pagination)
    var prices, brands, byOreder, byCategory, dots, dotsShip, dotsSt, byPagination, starts, shippings, full, dotsFull, disc, dotsDisc

    brand && higherPrice && lowerPrice
        ? dots = `.`
        : dots = ''

    start && brand
        ? dotsSt = `.`
        : start && higherPrice && lowerPrice
            ? dotsSt = `.`
            : dotsSt = ''

    brand && shipping
        ? dotsShip = `.`
        : shipping && higherPrice && lowerPrice
            ? dotsShip = `.`
            : start && shipping
                ? dotsShip = `.`
                : dotsShip = ''

    brand && fulfillment
        ? dotsFull = `.`
        : higherPrice && lowerPrice && fulfillment
            ? dotsFull = `.`
            : start && fulfillment
                ? dotsFull = `.`
                : shipping && fulfillment
                    ? dotsFull = `.`
                    : dotsFull = ''

    brand && discount
        ? dotsDisc = `.`
        : higherPrice && lowerPrice && discount
            ? dotsDisc = `.`
            : start && discount
                ? dotsDisc = `.`
                : shipping && discount
                    ? dotsDisc = `.`
                    : fulfillment && discount
                        ? dotsDisc = `.`
                        :  dotsDisc = ''


    higherPrice && lowerPrice
        ? prices = `sale_price>=${lowerPrice.replace('.','\\.')}.sale_price<=${higherPrice.replace('.','\\.')}`
        : prices = ''

    brand
        ? brands = `attribute_marca:${brand.replace(/\-/g,'\\-')}`
        : brands = ''
    order
        ? byOreder = `meta:sale_price:${order}`
        : byOreder = ''

    start
        ? starts = `review>3` 
        : starts = ''

    shipping
        ? shippings = `shipping_price:1`
        : shippings = ''

    fulfillment
        ? full = `fulfillment:1`
        : full = ''

    discount
        ? disc = `discount>=${discount}`
        : disc = ''

    id_Category
        ? byCategory = `categories->id:${id_Category}` //`breadcrumbs->id:${id_Category}`
        : byCategory = ''
    pagination
        ? byPagination = pagination - 1
        : byPagination = 0

    const apiURL = SEARCH_ANTEATER + `&proxystylesheet=xml2json&oe=UTF-8&getfields=*&sort=${byOreder}&start=${byPagination * PRODUCTS_PER_PAGINATION}&num=${PRODUCTS_PER_PAGINATION}&q=${decodeURIComponent(searchText).replace(/\-/g,'')}&requiredfields=${brands}${dots}${prices}${dotsSt}${starts}${dotsShip}${shippings}${dotsFull}${full}${dotsDisc}${disc}&ds=marcas:attribute_marca:0:0:100:0:1.sale_precio:sale_price:1:1:1000.ranking:review:0:0:8:0:1.full:fulfillment:0:0:8:0:1.free:shipping_price:0:0:8:0:1.discount:discount:0:0:1000:0:1&do=breadcrumbs:breadcrumbs:id,name,padre:100:1:2:1&requiredobjects=${byCategory}&client=pwa`
    // const apiURL = SEARCH_ANTEATER + `&proxystylesheet=xml2json&oe=UTF-8&getfields=*&sort=${byOreder}&start=${byPagination * PRODUCTS_PER_PAGINATION}&num=${PRODUCTS_PER_PAGINATION}&q=${searchText}&requiredfields=${brands}${dots}${prices}                                         &ds=marcas:attribute_marca:0:0:8:0:1.sale_precio:sale_price:1:1:1000                                                       &do=breadcrumbs:breadcrumbs:id,name,padre:10:1&requiredobjects=${byCategory}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: 5000,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    };


    return await axios(config)
        .then(res => {
            const {
                //config,
                //headers, 
                status,
                data
            } = res
            //console.log(res)
            // console.log(config)
            //console.log(data)
            // console.log(headers)
            // console.log(status)

            const searchResult = data
            if (status === 200) {
                if (!Array.isArray(searchResult)) {
                    return { searchResult }
                }
                else {
                    return { 'error': status, 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            //console.log(error.request.status)
            if (error.message === 'Network Error') {
                console.log('Error de conexión')
            }
            else if (error.request) {
                console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                console.log('request 0')
            } else {
                if (error.response.status === 401) {
                    console.log(error.response.data.msg)
                    return { error: error.response.status, message: error.response.data.msg }
                }
                else if (error.response.status === 404) {
                    console.error(error.response);
                    console.log('request 404')
                }
                else if (error.response.status === 440) {
                    console.log('request 440')
                }
                else if (error.response.status === 500) {
                    console.error(error.response);
                    console.log('request 500')
                }
                else {
                    console.error(error.response.status);
                    console.error(error.response);
                }
            }
        })
}


export async function desindexProduct(idProduct){
    const apiURL = BASE_PATH_API + `/app/v1/anteater-index?id=${idProduct}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    }
    
    return await axios(config)
    .then(res => {
        const {
            status, 
            data
        } = res
        const {metadata, data: producto } = data

        if(status === 200){
            if(!Array.isArray(producto)){
                return{ producto }
            }
            else{
                return {'error': metadata.http_status,'response': data}
            }
        }
        return []
    })
    .catch(error =>{

        if(error.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error.request){
            console.log(error.request.responseText)
        }
        if(error.request.status === 0) {
            console.log('request 0')
            return {'error': '0', 'response': error.message}
        }else{
            if(error.response.status === 401) {
                console.log(error.response.data.msg)
                return {error: error.response.status, message: error.response.data.msg }
            }
            else if(error.response.status === 404) {
                console.error(error.response);
                return {error: error.response.status, message: error.response.data.msg }
            }
            else if(error.response.status === 440) {
                return {error: error.response.status, message: error.response.data.msg }
            }
            else if(error.response.status === 500) {
                console.error(error.response);
                return {error: error.response.status, message: error.response.data.msg }
            }
            else{
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}