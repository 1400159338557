import React from 'react'
import { Link } from "react-router-dom"

export function HeaderMisPedidos({ ...props }) {
    const {
        ruta = '/login',
        type
    } = props

    return (
        type !== "anchor"
            ?
            <Link
                to={ruta}
                className="icoPedidos"
                aria-label="Ir a Mis Pedidos"
            >
                <span className="textReference">
                    {/* <span className="smallText"> */}
                        Mis Pedidos
                    {/* </span> */}
                   
                </span>
            </Link>
            : <a href={ruta} className="icoPedidos" aria-label="Ir a Mis Pedidos"><span className="textReference">Mis Pedidos</span></a>
    )
}