import React, { useEffect } from 'react';
import './style.sass';
import ReactDOM from 'react-dom';
import '../Modal/style.sass';
import $ from 'jquery';

export default function ModalDiscount({ limpiarDiscount, verDiscount, checkedDiscount, seleccionarDiscount, valorDescuento }) {
    // se verifica que exista el descuento para mostrarlo
    useEffect(() => {
        for (let i = 1; i < 8; i++) {
            var valor = $("#d" + i).val();
            if (valor < checkedDiscount) {
                document.getElementById("discount" + i).classList.add("none");
            }
        }
    }, [])

    return (
        ReactDOM.createPortal(
            <div className="modal full mCategorias">
                <div className="contentModal largo contenidoCategorias">
                    <div className="seccionSuperiorCategoria">

                        <div className="contentClose">
                            <span onClick={verDiscount} className="btn close"> </span>
                            <span className="h2" onClick={verDiscount}>Descuento</span>
                            <span onClick={limpiarDiscount} className="limpiarText"></span>
                        </div>

                    </div>

                    <div className="dataModal iteracion">
                        <ul onChange={seleccionarDiscount}>
                            {valorDescuento >= 10 &&
                                <li id="discount1">
                                    <label name="d1" htmlFor="d1"> Descuento del 10% o más </label>
                                    <input className="radio" id="d1" type="radio" value={Number(10)} name="discount" checked={Number(checkedDiscount) === Number(10) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 20 &&
                                <li id="discount2">
                                    <label name="d2" htmlFor="d2"> Descuento del 20% o más </label>
                                    <input className="radio" id="d2" type="radio" value={Number(20)} name="discount" checked={Number(checkedDiscount) === Number(20) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 30 &&
                                <li id="discount3">
                                    <label name="d3" htmlFor="d3"> Descuento del 30% o más </label>
                                    <input className="radio" id="d3" type="radio" value={Number(30)} name="discount" checked={Number(checkedDiscount) === Number(30) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 40 &&
                                <li id="discount4">
                                    <label name="d4" htmlFor="d4"> Descuento del 40% o más </label>
                                    <input className="radio" id="d4" type="radio" value={Number(40)} name="discount" checked={Number(checkedDiscount) === Number(40) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 50 &&
                                <li id="discount5">
                                    <label name="d5" htmlFor="d5"> Descuento del 50% o más </label>
                                    <input className="radio" id="d5" type="radio" value={Number(50)} name="discount" checked={Number(checkedDiscount) === Number(50) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 60 &&
                                <li id="discount6">
                                    <label name="d6" htmlFor="d6"> Descuento del 60% o más </label>
                                    <input className="radio" id="d6" type="radio" value={Number(60)} name="discount" checked={Number(checkedDiscount) === Number(60) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 70 &&
                                <li id="discount7">
                                    <label name="d7" htmlFor="d7"> Descuento del 70% o más </label>
                                    <input className="radio" id="d7" type="radio" value={Number(70)} name="discount" checked={Number(checkedDiscount) === Number(70) ? true : false} />
                                </li>
                            }
                        </ul>
                    </div>

                </div>
            </div>
            , document.getElementById('modal-root')
        )
    )
}