import {BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL} from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'


// Funcion Get Direccion Mi Cuenta
export  async function getAddress({id = ''} = {}){
    // trae la variable session token
    const accessToken = "Bearer " + decodeStorage("access_token");
    const isLoged = decodeStorage('session_token')
    let  dirID
    id
        ? dirID = `/${id}`
        : dirID = ''
        
    //const apiURL = `${BASE_PATH_API}/app/v1/address${dirID}?token=${isLoged}`
    const apiURL = `${BASE_PATH_API}/app/v1/address${dirID}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}


// Funcion Post Direccion Mi Cuenta
export  async function postAddress({...props}){
    // trae la variable session token
    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");

    const {
        nombre,
        estado,
        ciudad,
        colonia,
        calle,
        codigoPostal,
        numeroExterior,
        numeroInterior,
        telefono,
        entreCalles,
        referencias,
        municipio,
        accesibilidad
    } = props

    const raw = JSON.stringify({
        "nombre": nombre,
        "pais": "México",
        "estado": estado,
        "ciudad": ciudad,
        "colonia": colonia,
        "calle": calle,
        "codigoPostal": codigoPostal,
        "numeroExterior": numeroExterior,
        "numeroInterior": numeroInterior,
        "telefono": telefono,
        "entreCalles": entreCalles,
        "referencias": referencias,
        "municipio": municipio,
        "accesibilidad": accesibilidad,
        "id": "",
        "token": isLoged
    })
        
    const apiURL = `${BASE_PATH_API}/app/v1/address`

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: raw
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}


// Funcion Put Direccion Mi Cuenta
export  async function putAddress({...props}){
    // trae la variable session token
    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");

    const {
        id,
        nombre,
        estado,
        ciudad,
        colonia,
        calle,
        codigoPostal,
        numeroExterior,
        numeroInterior,
        telefono,
        entreCalles,
        referencias,
        municipio,
        accesibilidad
    } = props

    const raw = JSON.stringify({
        "nombre": nombre,
        "pais": "México",
        "estado": estado,
        "ciudad": ciudad,
        "colonia": colonia,
        "calle": calle,
        "codigoPostal": codigoPostal,
        "numeroExterior": numeroExterior,
        "numeroInterior": numeroInterior,
        "telefono": telefono,
        "entreCalles": entreCalles,
        "referencias": referencias,
        "municipio": municipio,
        "accesibilidad": accesibilidad,
        "id": id,
        "token": isLoged
    })
        
    const apiURL = `${BASE_PATH_API}/app/v1/address/${id}`

    const config = {
        method: 'put',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: raw
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}


// Funcion Delete Direccion Mi Cuenta
export async function deleteAddress ({idAddress} = {}){
    // trae la variable session token
    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");

    //const apiURL = `${BASE_PATH_API}/app/v1/address/${idAddress}?token=${isLoged}`
    const apiURL = `${BASE_PATH_API}/app/v1/address/${idAddress}`


    const config = {
        method: 'delete',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}


// Funcion Put para Direccion Predeterminada en Mi Cuenta
export  async function putDefaultAddress({...props}){
    // trae la variable session token
    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");

    const {
        id,
    } = props

    const raw = JSON.stringify({
        "token": isLoged
    })
        
    const apiURL = `${BASE_PATH_API}/app/v1/default-address/${id}`

    const config = {
        method: 'put',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: raw
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}