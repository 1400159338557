import React, {useState, useEffect} from 'react'
import { CardCategoryGroupNew, CardCategoryGroup } from 'components/CardCategory'
import ErrorType from 'pages/Error/ErrorType'
import Header from 'pages/Header/HeaderNavegacion'
import Footer from 'pages/Footer/FooterDesktop'
import BarraCP from 'components/BarCP/BarHeaderCP'
import { removeStorage } from 'functions/storageBase64'


export function CategoriaDesktop(props){
    const {
        response,
        setLoading
    } = props

    const [categorias, setCategorias] = useState([])
    const [ errors, setErrors ] = useState('')
    removeStorage('searchBusqueda')

    //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
    useEffect(()=>{
        if(response){
            if(response.statusCode === 200){
                if(response.data){
                    if(typeof(response.data) === 'object'){
                        setCategorias({
                            'categoriasMenu': response.data
                        })
                        //console.log('response.statusCode',response.data)
                    }
                    else{
                        setCategorias({})
                        setErrors({'error': response.statusCode ,'response': response.data.response})
                    }
                }
            }
            else if(response.statusCode){
                setErrors({'error': response.statusCode ,'response': response.data})
                setLoading(false)
            }
        }
        else if(response.statusCode !== undefined){
            setCategorias({})
            setErrors({'error': response.statusCode ,'response': response.data})
            setLoading(false)
        }
        
    },[response])
    
    useEffect( () => {
        if(categorias){
            if(Object.keys(categorias).length > 0){
                setLoading(false)
            }
        }
    },[categorias])

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
	const reciveDirection = (val) =>{
		setUpdateCPBar(val)
	}
	const [upCP, setUpCP] = useState()
	useEffect(()=>{
		if(upCP){
			//console.log('')
		}
	},[upCP, updateCPBar])

    return(
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
            <section className="barCpResponsive">
                <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
            </section>
            <main>
                <section className="SectionCategory">
                    <h1>Departamentos</h1>
                    
                    {response &&
                        response.statusCode === 200
                            ?
                                categorias.categoriasMenu &&
                                    <>
                                        {
                                            categorias.categoriasMenu.map(({name, icon, seo, external_id, children, estatus, visible}) => 
                                                estatus !== 0  && visible !== 0 && String(name).toLowerCase() != "marketplace"
                                                ?
                                                    <CardCategoryGroupNew
                                                        key={external_id}
                                                        catFisrt={external_id}
                                                        nombre={name}
                                                        icon={icon}
                                                        seo={seo}
                                                        subcat={children}
                                                    />
                                                :
                                                    null
                                            )
                                        }
                                    </>
                            : null
                    }

                    <ErrorType
                        codeStatus={errors.error}
                        errorText={errors.response}
                    />
                </section>
            </main>
            <Footer />
        </>
    )
}



export function CategoriaDesktopOld(props){
    const {
        response,
        setLoading
    } = props

    const [categorias, setCategorias] = useState([])
    const [ errors, setErrors ] = useState('')

    //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
    useEffect(()=>{
        if(response){
            //console.log('response.statusCode',response.statusCode)
            if(response.statusCode === 200){
                if(typeof(response.data) === 'object'){
                    setCategorias({
                        'categoriasMenu': response.data
                    })
                }
                else{
                    setCategorias({})
                    setErrors({'error': response.statusCode ,'response': response.data.response})
                }
            }
            else if(response.statusCode){
                setErrors({'error': response.statusCode ,'response': response.data})
                setLoading(false)
            }
        }
        else if(response.statusCode !== undefined){
            setCategorias({})
            setErrors({'error': response.statusCode ,'response': response.data})
            setLoading(false)
        }
        
    },[response])
    
    useEffect( () => {
        if(categorias){
            if(Object.keys(categorias).length > 0){
                setLoading(false)
            }
        }
    },[categorias])

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
	const reciveDirection = (val) =>{
		setUpdateCPBar(val)
	}
	const [upCP, setUpCP] = useState()
	useEffect(()=>{
		if(upCP){
			//console.log('')
		}
	},[upCP, updateCPBar])

    return(
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
            <section className="barCpResponsive">
                <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
            </section>
            <main>
                <section className="SectionCategory">
                    <h1>Departamentos</h1>
                    {response &&
                        response.statusCode === 200
                            ?
                                categorias.categoriasMenu &&
                                    <>
                                        {
                                            categorias.categoriasMenu.map(({nombre, icon, seo, id_cat, subcat, estatus, visible}) => 
                                                estatus !== 0  && visible !== 0
                                                ?
                                                    <CardCategoryGroup
                                                        key={id_cat}
                                                        catFisrt={id_cat}
                                                        nombre={nombre}
                                                        icon={icon}
                                                        seo={seo}
                                                        subcat={subcat}
                                                    />
                                                :
                                                    null
                                            )
                                        }
                                    </>
                            : null
                    }
                    <ErrorType
                        codeStatus={errors.error}
                        errorText={errors.response}
                    />
                </section>
            </main>
            <Footer />
        </>
    )
}