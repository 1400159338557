import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { postListaBlanca } from 'services/MiTelmexServices';
import CurrencyFormat from "react-currency-format";
import './modalMiTelmex.sass';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';
import { useStorage64 } from 'hooks/useStorageBase64';
import { VARIANTS_PER_PORTAL } from 'constants/general'

export function ModalMiTelmexHome() {

    const [flagOcultarMiTelmex, setFlagOcultarMiTelmex] = useState(false);
    const [flagBTNclose, setFlagBTNclose] = useState(null);
    const [telefono] = useStorage64('telefono_usuario'); //obtiene el telefono del usuario desde el storage
    const [successListaBlanca, getSuccessListaBlanca] = useState(false);
    const [info, setInfo] = useState([]);
    const [, setInfoError] = useState([]);
    const [balance, setBalance] = useState(0);
    const informacion = [];
    const infoErrorArray = [];

    let saldoDisponible = <CurrencyFormat
        value={balance}
        displayType={'text'}
        decimalScale={2}
        FixedDecimalScale={true}
        thousandSeparator={true} prefix={'$'} renderText={value => <>{value}</>}
    />

    const handleClick_ocultarModalMiTelmex = () => {
        setFlagOcultarMiTelmex(true);
        window.localStorage.setItem("closebtnMT-home", "true");
    };

    useEffect(() => {
        if (window.localStorage.getItem("closebtnMT-home") === "true") {
            setFlagBTNclose(true);
        } else {
            setFlagBTNclose(false);
        }
    }, []);

    // envia la peticion al servicio lista blanca
    useEffect(() => {
        if (telefono) {
            if (!decodeStorage("saldoTelmexService")) {
                const env = [{ phone: telefono }];
                postListaBlanca({ raw: env })
                    .then(getSuccessListaBlanca)
            } else {
                setBalance(decodeStorage("saldoTelmexService"));
            }
        }
    }, [telefono]);

    // guarda informacion de respuesta lista blanca
    useEffect(() => {
        if (successListaBlanca) {
            [successListaBlanca].map((ar, i) => {
                var lis = Object.entries(ar);
                lis.map((item, j) => {
                    return (item[0] === "data"
                        ? informacion.push(item[1][0])
                        : null
                    )
                })
            })
            setInfo(informacion[0]);
        }

        if (successListaBlanca) {
            [successListaBlanca].map((ar, i) => {
                var lis = Object.entries(ar);
                lis.map((item, j) => {
                    return (item[0] === "metadata"
                        ? infoErrorArray.push(item[1])
                        : null
                    )
                })
            })
            setInfoError(infoErrorArray[0]);
        }
    }, [successListaBlanca]);

    useEffect(() => {
        let balance = 0;
        if (info != undefined && info != '') {
            if (info.credit != null) {
                if (Number(info.credit.balance % 1) === 0) {
                    balance = info.credit.balance + '.001';
                    setBalance(balance);
                    encodeStorage('saldoTelmexService', Number(balance).toFixed(2));
                } else {
                    balance = info.credit.balance;
                    setBalance(balance);
                    encodeStorage('saldoTelmexService', Number(balance).toFixed(2));
                }
            }
        }
    }, [info]);


    return (
        <>
            {/* {VARIANTS_PER_PORTAL.telmex &&
                flagOcultarMiTelmex === false &&
                    <> */}
                        {/* NO se ha registrado con telmex */}
                        {/* {!telefono &&
                            <div className="sectionModalMiTelmex">
                                {flagBTNclose !== true &&
                                    <div className="sectionClose">
                                        <button onClick={handleClick_ocultarModalMiTelmex} className="closeTelmex" aria-label="Boton cerrar Telmex"></button>
                                    </div>
                                }

                                <div className="sectionTextos">
                                    <p>Comprar <span className="span">SIN TARJETA </span></p>
                                    <p>con tu recibo</p>
                                </div>

                                <div className="sectionLogos">
                                    <div className="icoTelmex">
                                        <img alt="logo-telmex" src="/img/telmexColor.svg"></img>
                                    </div>
                                    <div className="span"></div>
                                    <div className="icoClaro">
                                        <img alt="logo-claroshop" src="/img/claroshop.svg"></img>
                                    </div>
                                </div>

                                <div className="sectionButton">
                                    <Link className="btn-credito-disponible btn rojo" to="/loginMiTelmex/">Consultar crédito disponible</Link>
                                </div>
                            </div>
                        } */}

                        {/* se ha registrado con telmex */}
                        {/* {telefono &&
                            <Link className="sectionModalMiTelmex isLoged" to="/mi-cuenta/mitelmex/">

                                <div className="sectionLogos">
                                    <div className="icoTelmex">
                                        <img alt="logo-telmex" src="/img/telmexColor.svg"></img>
                                    </div>
                                    <div className="span"></div>
                                    <div className="icoClaro">
                                        <img alt="logo-claroshop" src="/img/claroshop.svg"></img>
                                    </div>
                                </div>

                                <div className="sectionTextosSaldo">
                                    <p className="textCredit">Crédito disponible</p>
                                    {balance != 0 && <p className="colorTelmex"><span className="span"> {saldoDisponible}</span></p>}
                                </div>

                            </Link>
                        } */}
                    {/* </>
            } */}
        </>
    )
}

export default function ModalMiTelmexDetalle() {

    const [flagOcultarMiTelmex, setFlagOcultarMiTelmex] = useState(false);
    const [flagBTNclose, setFlagBTNclose] = useState(null);
    const [telefono] = useStorage64('telefono_usuario'); //obtiene el telefono del usuario desde el storage
    const [successListaBlanca, getSuccessListaBlanca] = useState(false);
    const [info, setInfo] = useState([]);
    const [, setInfoError] = useState([]);
    const [balance, setBalance] = useState(0);
    const informacion = [];
    const infoErrorArray = [];

    let saldoDisponible = <CurrencyFormat
        value={balance}
        displayType={'text'}
        decimalScale={2}
        FixedDecimalScale={true}
        thousandSeparator={true} prefix={'$'} renderText={value => <>{value}</>}
    />


    const handleClick_ocultarModalMiTelmex = () => {
        setFlagOcultarMiTelmex(true);
        window.localStorage.setItem("closebtnMT-detalle", "true");
    }

    useEffect(() => {
        if (window.localStorage.getItem("closebtnMT-detalle") === "true") {
            setFlagBTNclose(true);
        } else {
            setFlagBTNclose(false);
        }
    }, []);

    // envia la peticion al servicio lista blanca
    useEffect(() => {
        if (telefono) {
            if (!decodeStorage("saldoTelmexService")) {
                const env = [{ phone: telefono }];
                postListaBlanca({ raw: env })
                    .then(getSuccessListaBlanca)
            } else {
                setBalance(decodeStorage("saldoTelmexService"));
            }
        }
    }, [telefono]);

    // guarda informacion de respuesta lista blanca
    useEffect(() => {
        if (successListaBlanca) {

            [successListaBlanca].map((ar, i) => {
                var lis = Object.entries(ar);
                lis.map((item, j) => {
                    return (item[0] === "data"
                        ? informacion.push(item[1][0])
                        : null
                    )
                })
            })
            setInfo(informacion[0]);
        }

        if (successListaBlanca) {
            [successListaBlanca].map((ar, i) => {
                var lis = Object.entries(ar);
                lis.map((item, j) => {
                    return (item[0] === "metadata"
                        ? infoErrorArray.push(item[1])
                        : null
                    )
                })
            })
            setInfoError(infoErrorArray[0]);
        }
    }, [successListaBlanca]);

    useEffect(() => {
        let balance = 0;
        if (info != undefined && info != '') {

            if (info.credit !== null) {
                if (Number(info.credit.balance % 1) === 0) {
                    balance = info.credit.balance + '.001';
                    setBalance(balance);
                    encodeStorage('saldoTelmexService', Number(balance).toFixed(2));
                } else {
                    balance = info.credit.balance;
                    setBalance(balance);
                    encodeStorage('saldoTelmexService', Number(balance).toFixed(2));
                }
            }
        }
    }, [info]);

    return (
        <>
            {/* {VARIANTS_PER_PORTAL.telmex &&
                flagOcultarMiTelmex === false &&
                    <> */}
                        {/* NO se ha registrado con telmex */}
                        {/* {!telefono &&
                            <div className="sectionModalMiTelmex">
                                {flagBTNclose !== true &&
                                    <div className="sectionClose">
                                        <button onClick={handleClick_ocultarModalMiTelmex} className="closeTelmex" aria-label="Boton cerrar Telmex"></button>
                                    </div>
                                }

                                <div className="sectionTextos">
                                    <p>Comprar <span className="span">SIN TARJETA </span></p>
                                    <p>con tu recibo</p>
                                </div>

                                <div className="sectionLogos">
                                    <div className="icoTelmex"></div>

                                    <div className="span"></div>

                                    <div className="icoClaro"></div>
                                </div>

                                <div className="sectionButton">
                                    <Link className="btn-credito-disponible btn rojo" to="/loginMiTelmex/">Consultar crédito disponible</Link>
                                </div>
                            </div>
                        } */}

                        {/* se ha registrado con telmex */}
                        {/* {telefono &&
                            <Link className="sectionModalMiTelmex isLoged" to="/mi-cuenta/mitelmex/">

                                <div className="sectionLogos">
                                    <div className="icoTelmex"></div>
                                    <div className="span"></div>
                                    <div className="icoClaro"></div>
                                </div>

                                <div className="sectionTextosSaldo">
                                    <p className="textCredit">Crédito disponible</p>
                                    {balance != 0 && <p className="colorTelmex"><span className="span"> {saldoDisponible}</span></p>}
                                </div>
                            </Link>
                        } */}
                    {/* </>
            } */}
        </>
    )
}