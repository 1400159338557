import { repareTitleSEO } from 'functions/repareDataService'
import { WHITE_PIXEL }from 'constants/general'

//New
export const getProductAdapter = (data) =>{
    
    let newData
    if(data){
        let images = data?.pictures?.map(imag =>{
            return {
                order: imag?.order,
                thumbnail: imag?.thumbnail,
                url: imag?.source
            }
        })
        let videos = data?.videos ? data?.videos : []
        
        newData = {
            id: data?.id,
            title: data?.title,
            title_seo: data?.title_seo,
            brand: data?.brand,
            categories: data?.categories[0],
            ean: data?.ean,
            images: images,
            last_modified: data?.updated_at,
            price: data?.pricing?.list_price,
            sale_price: data?.pricing?.sales_price,
            discount: data?.pricing?.percentage_discount,
            stock: data?.stock,
            videos: videos,
            size_color: [],
            shipping:{
                is_free: data?.shipping?.is_free
            },
            features: {
                back_order: data?.features?.is_backorder,
                big_ticket: data?.features?.is_big_ticket,
                is_digital: data?.features?.digital,
                is_store_only: data?.features?.is_store_only,
                super_express: data?.features?.super_express
            },
            store: {
                id: data?.seller?.id,
                logo: data?.seller?.logo,
                name: data?.seller?.name,
            },
            reviews: {
                score: data?.rating?.average_score,
                total_reviews: data?.rating?.total_reviews,
                commentaries: null,
                stars: data?.rating?.total_reviews >= 1 ? { star_1: 0, star_2: 0, star_3: 0, star_4: 0, star_5: 1 } : null
            },
            score: data?.rating.average_score,
            status: data?.is_active,
            warrantyDetails: data?.warranties
        }
    }

    // console.log('data: ', data)
    // console.log('newData: ', newData)

    return {
        producto : newData
    } 
}

//Old
export const getProductAdapterOld = (data) =>{
    
    let newData
    if(data){
        newData = {
            ...data
        }
    }

    // console.log('data: ', data)
    // console.log('newData: ', newData)

    return {
        producto : data
    } 
}

export const emarsysProductAdapter = (data) =>{
    
    let newData
    if(data){
        newData = {
            id: Number(data?.id),
            title: data?.title,
            title_seo: repareTitleSEO(data?.title),
            brand: data?.brand,
            ean: data?.c_ean,
            categories: null,
            images: [{
                order: 1,
                thumbnail: data?.image,
                url: data?.image
            }],
            price: data?.msrp,
            sale_price: data?.price,
            discount: data?.c_descuento,
            stock: 30,
            videos: [],
            size_color: [],
            store: {
                id: null,
                logo: WHITE_PIXEL,
                name: null,
            },
            reviews: {
                score: data?.c_reviewsscore,
                total_reviews: data?.c_reviewstotal,
                commentaries: null,
                stars: data?.c_reviewstotal >= 1 ? { star_1: 0, star_2: 0, star_3: 0, star_4: 0, star_5: 1 } : null
            },
            score: data?.c_reviewsscore,
            status: true
        }
    }

        // console.log('data: ', data)
        // console.log('newData: ', newData)

    return newData
}

export const anteaterProductAdapter = (data) =>{
    
    let newData
    if(data){
        newData = {
            id: data?.id,
            title: data?.nombre,
            title_seo: data?.nombreSEO,
            brand: data?.store,
            categories: null,
            ean: data?.c_ean,
            images: [{
                order: 1,
                thumbnail: data?.foto,
                url: data?.foto
            }],
            price: data?.precio,
            sale_price: data?.precioLista,
            discount: data?.descuento,
            stock: 30,
            videos: [],
            size_color: [],
            reviews: {
                score: data?.review,
                total_reviews: data?.total_review,
                commentaries: null,
                stars: data?.total_review >= 1 ? { star_1: 0, star_2: 0, star_3: 0, star_4: 0, star_5: 1 } : null
            },
            store: {
                id: null,
                logo: WHITE_PIXEL,
                name: null,
            },
            score: data?.review,
            status: true
        }
    }

        // console.log('data: ', data)
        // console.log('newData: ', newData)

    return newData
}