import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import Header from 'pages/Header/HeaderSimple'
import './styles.sass'

export default function Contacto() {
    const history = useHistory()

    //- Codigo de instana para track de pagina 
    useEffect(()=>{
        window.ineum('page', 'Contact Page');
    },[])

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    const backHistory = () => {
        history.go(-1)
    }


    return (
        <>
            <Header pageClass="hidden"/>
            <div className="sectionTitleContacto">
                <button onClick={backHistory}> Contacto</button>
            </div>


            <div className="boxContacto1">
                <p className="comunicate">¡Comunícate! resolveremos tus preguntas.</p>

                <ul>
                    <li><i className="envelope"></i>
                        <div className="textoEscribenos">
                            <span className="titulo">Escríbenos</span>
                            <a href="mailto: clientes@claroshop.com">clientes@claroshop</a>
                        </div>
                    </li>
                    <li><i className="whats"></i>
                        <div className="textoEscribenos">
                            <span className="titulo">Whatsapp</span>
                            <a className="enlaces" href="https://api.whatsapp.com/send?phone=525518110000" target="_blank">55 1811 0000</a>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="boxContactoMitad">
                <p className="titulo">Teléfonos de contacto</p>
                <p className="texto">Horario: 08:00 a 20:00 hrs.</p>
            </div>

            <div className="boxContacto2">
                <ul>
                    <li><span className="phone"></span>
                        <div className="textoEscribenos">
                            <span className="titulo">CDMX y área metropolitana</span>
                            <a className="enlaces" href="tel:5512030502">55 1203 0502</a>
                        </div>
                    </li>
                    <li><span className="phone"></span>
                        <div className="textoEscribenos">
                            <span className="titulo">República Mexicana</span>
                            <a className="enlaces" href="tel:8008906566">800 890 6566</a>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}