import {useState, useEffect} from 'react'
import { NO_IMAGE } from 'constants/general'


export function useImageArray(initialImage){

    const [dataImage, setDataImage] = useState([{
        order: 1,
        thumbnail: NO_IMAGE,
        url: NO_IMAGE
    }])

    const [updateArray, setUpdateArray] = useState()
    useEffect(() => {
        if(updateArray){
            if(updateArray.length === 0){
                setDataImage([{
                    order: 1,
                    thumbnail: NO_IMAGE,
                    url: NO_IMAGE
                }])
            }else{
                setDataImage(updateArray)
            }
        }
        if(initialImage){
            if(initialImage.length === 0){
                setDataImage([{
                    order: 1,
                    thumbnail: NO_IMAGE,
                    url: NO_IMAGE
                }])
            }else{
                setDataImage(initialImage)
            }
        }
    }, [updateArray, initialImage]);

    const updateDataImage = (valArray) =>{
        setUpdateArray(valArray)
    }

    return{
        dataImage,
        updateDataImage
    }
}