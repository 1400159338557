import {BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL} from 'constants/general'
import axios from 'axios'
import { getProductAdapter, getProductAdapterOld } from 'adapters/products_Adapter'

export default async function getProduct ({idProduct} = {}) {

    const apiURL = BASE_PATH_API + `/app/v1/product/${idProduct}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    }
    
    return await axios(config)
    .then(res => {
        const {
            //config,
            //headers, 
            status, 
            data
        } = res
        //console.log(res)
        // console.log(config)
        //console.log(data)
        // console.log(headers)
        // console.log(status)
        const {metadata, data: producto } = data

        if(status === 200){
            if(!Array.isArray(producto)){
                return getProductAdapterOld(producto)
            }
            else{
                return {'error': metadata.http_status,'response': data}
            }
        }
        return []
    })
    .catch(error =>{
        // console.log(error)
        // console.log(error.stack)
        // console.log(error.message)
        // console.log(error.request)
        // console.log(error.request.status)
        if(error.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error.request){
            console.log(error.request.responseText)
        }
        if(error.request.status === 0) {
            console.log('request 0')
            return {'error': '0', 'response': error.message}
        }else{
            if(error.response.status === 401) {
                console.log(error.response.data.msg)
                return {error: error.response.status, message: error.response.data.msg }
            }
            else if(error.response.status === 404) {
                console.error(error.response);
                return {error: error.response.status, message: error.response.data.msg }
            }
            else if(error.response.status === 440) {
                return {error: error.response.status, message: error.response.data.msg }
            }
            else if(error.response.status === 500) {
                console.error(error.response);
                return {error: error.response.status, message: error.response.data.msg }
            }
            else{
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}


export async function getProductNew ({idProduct} = {}) {

    const apiURL = BASE_PATH_API + `/products/v1/products/${idProduct}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    }
    
    return await axios(config)
    .then(res => {
        const {
            //config,
            //headers, 
            status, 
            data
        } = res
        const {metadata, data: producto } = data

        if(status === 200){
            if(!Array.isArray(producto)){
                return getProductAdapter(producto) 
            }
            else{
                return {'error': metadata.http_status,'response': data}
            }
        }
        return []
    })
    .catch(error =>{
        if(error?.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error?.request){
            console.log(error?.request?.responseText)
        }
        if(error?.request?.status === 0) {
            console.log('request 0')
            return {'error': '0', 'response': error?.message}
        }else{
            if(error?.response?.status === 401) {
                console.log(error?.response?.data.msg)
                return {error: error?.response?.status, message: error?.response?.data.msg }
            }
            else if(error?.response?.status === 404) {
                console.error(error.response);
                return {error: error?.response?.status, message: error?.response?.data.msg }
            }
            else if(error?.response?.status === 440) {
                return {error: error?.response?.status, message: error?.response?.data.msg }
            }
            else if(error?.response?.status === 500) {
                console.error(error.response);
                return {error: error?.response?.status, message: error?.response?.data.msg }
            }
            else{
                console.error(error?.response?.status);
                console.error(error?.response);
            }
        }
    })
}