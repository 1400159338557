import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function AvisoMesaDeRegalosDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Mesa de Regalos Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Aviso de Privacidad Mesa de Regalos</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Quién dará tratamiento a sus datos personales?</h2>
                                <p>Sears Operadora México S.A. de C.V., (en adelante Sears) con domicilio en Lago Zúrich No. 245, Edificio Presa Falcón piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, C.P. 11529, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                <p>Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de Sears a la dirección electrónica <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                            </div>

                            <div className="question1">
                                <h2>¿Qué información vamos a recabar?</h2>
                                <p>Sears para cumplir con las finalidades señaladas en el presente Aviso tratará datos personales de identificación, datos personales de contacto, datos personales patrimoniales y/o financieros, así como aquella información relacionada con sus gustos, preferencias y tendencias de consumo de nuestros productos y servicios. Le informamos que para efectos de lo señalado en el presente aviso de privacidad Sears no tratará datos personales sensibles.</p>
                                <p>Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sears podrá dar tratamiento a datos personales de terceros, por lo que al proporcionar los datos personales de dichos terceros para que Sears les contacte por medios físicos y/o electrónicos usted reconoce haber recabado el consentimiento requerido en términos de la normatividad aplicable.</p>
                                <p>Sears no recabará ni tratará datos personales sensibles. En caso de que usted nos contacte por redes sociales, podremos recabar los datos de su perfil público de redes sociales para darle atención a sus peticiones y/o solicitudes de información en relación con los productos que ofrecemos.</p>
                            </div>

                            <div className="question1">
                                <h2>No tratamiento de datos personales de menores de edad </h2>
                                <p>Sears no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad.</p>
                            </div>

                            <div className="question1">
                                <h2>Tratamiento de datos sin decisión humana valorativa y actividades de perfilamiento</h2>
                                <p>Le informamos que para dar cumplimiento a las finalidades previstas en este Aviso de Privacidad Sears realizará tratamientos de datos personales como parte de un proceso de toma de decisiones sin intervención humana valorativa y técnicas de análisis masivo de datos para realizar actividades de perfilamiento a partir de sus hábitos de consumo, gustos e intereses, monitorear su comportamiento y ofrecerle una experiencia personalizada en relación con el uso de los servicios, así como para dar cumplimiento a algunas(s) de las finalidades(es ) informadas en el presente Aviso de Privacidad.</p>
                            </div>

                            <div className="question1">
                                <h2>¿Para qué fines usamos su información?</h2>
                                <p>Sears tratará sus datos personales para las siguientes finalidades primarias y necesarias:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Generar una lista de los regalos y/o productos preferidos por usted;</li>
                                    <li>Identificarle y contactarlo a través de distintos medios de comunicación electrónica para facilitar la localización, tramitación, suministro, distribución y entrega de los productos solicitados por usted o que un tercero le haya obsequiado;</li>
                                    <li>Para cambio de los productos seleccionados en alguna de nuestras sucursales;</li>
                                    <li>Atender y resolver sus dudas relacionadas con la adquisición de nuestros productos y/o servicios; </li>
                                    <li>Expedición de un monedero electrónico, acumulación y canje de puntos en este último; </li>
                                    <li>Realizar consultas, investigaciones y revisiones en relación a quejas o reclamaciones;</li>
                                    <li>Facilitar la localización, tramitación, suministro, distribución y entrega de los productos solicitados por usted;</li>
                                    <li>Informarle sobre cambios o nuevos productos o servicios que estén relacionados con los productos adquiridos por usted y en el supuesto de que el producto adquirido cuente con una garantía vigente, para brindarle el servicio de asistencia técnica y/o reparación requerido;</li>
                                    <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que Sears considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros;</li>
                                    <li>Determinar la zona geográfica donde usted se encuentre para ofrecerle contenidos, promociones o beneficios correspondientes a esa zona;</li>
                                    <li>En el supuesto de que usted nos contacte a través de redes sociales además de las finalidades anteriores, podremos divulgar a través de distintos medios de información tanto físicos como electrónicos los contenidos publicados por usted dentro de las redes sociales en las que interactúe con Sears;</li>
                                    <li>En el supuesto de que usted cumpla con los requisitos establecidos por Sears para darle de alta en sus programas de lealtad;</li>
                                    <li>Identificarlo como visitante en nuestras sucursales y/o como participante en dinámicas, juegos, sorteos y actividades similares que se organicen en nuestras instalaciones. Si usted ingresa a nuestras instalaciones y/o participa en alguna de las actividades señaladas se entenderá que otorga su consentimiento expreso para que podamos dar tratamiento a su imagen para realizar la publicación de contenidos de diversa índole en plataformas y canales de comunicación de la empresa con el fin de dar publicidad a nuestras actividades, promociones, productos y servicios;</li>
                                    <li>En el supuesto de que el producto que usted adquiera cuente con una garantía vigente, para brindarle el servicio de asistencia técnica y/o reparación requeridos;</li>
                                    <li>Para dar cumplimiento a lo dispuesto por la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita;</li>
                                    <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos;</li>
                                    <li>Para llevar a cabo actividades de contacto y validación de información con los terceros que usted haya designado como referencias personales a fin de garantizar el cumplimiento de la relación jurídica con nosotros;</li>
                                    <li>Dar cumplimiento a las políticas y procedimientos de carácter interno;</li>
                                    <li>Dar cumplimiento a obligaciones previstas en la normatividad aplicable;</li>
                                    <li>Para las gestiones de facturación relacionadas con los productos adquiridos.</li>
                                </ul>
                            </div>

                            <div className="question1">
                                <h2>¿Qué otros usos podemos darle a su información?</h2>
                                <p>De manera adicional, si usted no se opone, Sears podrá mantener su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de Sears de acuerdo con sus intereses, así como de otras marcas y/o Empresas del Grupo incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos;</li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sears y/o Empresas del Grupo;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero;</li>
                                    <li>Realizar estadísticas, encuestas o estudios sobre hábitos de mercado y comportamiento que tengan por finalidad evaluar la calidad de los servicios, bienes, productos y/o experiencia de compra;</li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                </ul>

                                <p>En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                                <p>Adicionalmente, te informamos que tú puedes en todo momento cancelar el envío de mensajes promocionales haciendo clic en el enlace Cancelar Suscripción que está integrado en el pie de página de todos los correos electrónicos enviados por Sears.</p>
                                <p>La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>
                            </div>

                            <div className="question1">
                                <h2>¿A qué terceros podemos transferir su información?</h2>
                                <p>Sears para cumplir la(s) finalidad(es) anteriormente descrita(s) transferirá sin requerir de tu consentimiento los datos necesarios a terceros tanto nacionales como extranjeros, intervinientes en la prestación de los productos y/o servicios y forma de pago, solicitados por Usted.</p>
                                <p>Sears podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes, tanto nacionales como internacionales, en los casos legalmente previstos, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                <p>Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en <a href="http://www.gsanborns.com.mx/perfil-corporativo.html" target="_blank" rel="noopener noreferrer">http://www.gsanborns.com.mx/perfil-corporativo.html</a></p>
                                <p>En caso de que las operaciones que usted realice con Sears, y las cuales estén clasificadas en Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, como actividades vulnerables, Sears, podrá entregar determinada información de usted sin requerir de su consentimiento a las autoridades fiscales competentes con la finalidad de dar cumplimiento a lo dispuesto por la Ley.</p>
                                <p>Sears podrá transferir  sin requerir de su consentimiento los datos personales necesarios  a:  socios comerciales y/o terceros con los que tengamos celebrados contratos de prestación de servicios para prestar servicios de entrega de bienes y productos, postventa, gestionar cuentas y registros de clientes, enviar comunicación relativa a productos o servicios que pueden ser de su interés, programas de lealtad, así como comunicaciones de marketing, con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p>Asimismo, le informamos que en el supuesto de que usted incumpla con las obligaciones de pago contraídas, sus datos personales podrán ser transferidos a las Sociedades de Información Crediticia con las que tengamos celebrados acuerdos de comunicación de datos sin requerir su consentimiento por ser una obligación legal en términos de la Ley respectiva.</p>
                                <p>Si usted no se opone, Sears podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen.</p>
                                <p>En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                            </div>

                            <div className="question1">
                                <h2>¿Cómo puede ejercer tus derechos ARCO y/o revocar tu consentimiento?</h2>
                                <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sears a la dirección electrónica <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>.</p> 
                                <p>Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sears pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sears.</p>
                                <p>Le recordamos que, si usted desea eliminar sus datos, deberá promover su derecho de cancelación, en los términos antes descritos.</p>
                                <p>En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>
                            </div>

                            <div className="question1">
                                <h2>¿Cómo puede limitar el uso y/o divulgación de tus datos?</h2>
                                <p>Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de Sears. Para mayor información favor de contactar al Departamento de Protección de Datos de Sears.</p>
                            </div>

                            <div className="question1">
                                <h2>Uso de cookies y web beacons</h2>
                                <p>Le informamos que Sears en nuestro sitio web y/o aplicaciones podemos hacer uso de cookies, web beacons y otras tecnologías de rastreo que nos permiten cumplir con las finalidades informadas en el presente Aviso de Privacidad. Los diferentes tipos de cookies que utilizamos son:
                                </p>
                                <ul className="ulQuestionDisc">
                                    <li><span className='bold'>Cookies esenciales:</span> para permitirle un uso adecuado de nuestro sitio web y/o aplicaciones, por lo que las mismas no pueden ser deshabilitadas.</li>
                                    <li><span className='bold'>Cookies para recordar sus preferencias y experiencia:</span> estas cookies nos permiten recordar sus preferencias, opciones de navegación y funciones personalizadas.</li>
                                    <li><span className='bold'>Cookies para objetivos de rendimiento y análisis:</span> podemos usar cookies propias y de terceros para identificar la forma en la cual usted utiliza nuestros Servicios con la finalidad de mejorar su rendimiento y futuros desarrollos.</li>
                                    <li><span className='bold'>Cookies publicitarias propias y de terceros:</span> podemos colocar cookies publicitarias propias y de terceros (como socios comerciales y empresas del grupo) para mostrarle publicidad que consideremos relevante para usted.</li>
                                </ul>
                                <p>Usted puede deshabilitar el uso de cookies, a través de su navegador siguiendo el procedimiento establecido por éste. Aquí alguna información del procedimiento establecido en los siguientes navegadores:</p>
                                <ul className='ulQuestionDisc'>
                                    <li>Internet Explorer: Herramientas -&gt; Opciones de Internet -&gt; Privacidad -&gt; Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                    <li>Firefox: Herramientas -&gt; Opciones -&gt; Privacidad y Seguridad. Para más información, puedes consultar el soporte de Mozilla o la Ayuda del navegador.</li>
                                    <li>Chrome: Configuración -&gt; Verificación de seguridad y/o -&gt; Privacidad y seguridad. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.</li>
                                    <li>Safari: Preferencias -&gt; Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.</li>
                                    <li>Edge: Configuración -&gt; Privacidad, búsqueda y servicios y/o Permisos del sitio. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                </ul>
                            </div>

                            <div className="question1">
                                <h2>¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</h2>
                                <p>Sears le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet <Link to="/">http://www.sears.com.mx</Link>, por lo cual le pedimos revise la misma de manera periódica.</p>
                            </div>

                            <div className="question1">
                                <h2>¿Cómo puede hacernos llegar tus dudas?</h2>
                                <p>Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                            </div>

                            <div className="question1">
                                <h2>¿Cómo protegemos sus transacciones?</h2>
                                <p>Con el propósito de proteger las transacciones que usted realiza a través de nuestro sitio web, tenemos implementado un servidor seguro bajo el protocolo SSL (Secure Socket Layer) de tal menara que la información que nos envía, se transmite encriptada para asegurar su protección o si lo prefiere, puede utilizar los servicios de PayPal los cuales también ofrecen el protocolo SSL.</p>
                                <p>Para verificar que se encuentra en un entorno protegido, asegúrese de que aparezca una "S" en la barra de navegación "httpS"://.</p>
                            </div>

                            <div className="question1">
                                <h2>Recomendaciones de seguridad</h2>
                                <p>Es importante que elija una contraseña única y distinta a la que uses en otros sitios de internet. Asimismo, es de vital importancia que no comparta su contraseña con ningún tercero, si cree que alguien pudiere llegar a tener conocimiento de su contraseña cámbiela de inmediato.</p>
                            </div>

                            <div className="question1">
                                <h2>INAI</h2>
                                <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                            </div>

                            <div className="question1">
                                <h2>Aceptación de Aviso de Privacidad</h2>
                                <p>Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último.</p>

                                <p className="ps">Fecha de última actualización: Junio del 2023</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function AvisoMesaDeRegalosMobile() {

    const history = useHistory();
    const isNativeApp = window.location.search.indexOf('isNativeApp=true')

    // cerrar modal
    const onClose = () => {
        // history.goBack(-1);
        window.location.href = "/acerca-sears"
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Mesa de Regalos Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    {isNativeApp < 0
                        ?
                            <div className="contentCloseAN">
                                <div className="btn closeBack" onClick={onClose} ></div>
                                <p className="title">Aviso de Privacidad Mesa de Regalos</p>
                            </div>
                        : null
                    }
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <div className="contenedorImagenes">
                                    <ul>
                                        <img src="/img/logo-sears.svg" alt={"logo-sears"} width={100} />
                                    </ul>
                                </div>

                                <p className="pSliderTitleLred">¿Quién dará tratamiento a sus datos personales?</p>
                                <p className="pSliderL">Sears Operadora México S.A. de C.V., (en adelante Sears) con domicilio en Lago Zúrich No. 245, Edificio Presa Falcón piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, C.P. 11529, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                <p className="pSliderL">Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de Sears a la dirección electrónica <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                            
                                <p className="pSliderTitleLred">¿Qué información vamos a recabar?</p>
                                <p className="pSliderL">Sears para cumplir con las finalidades señaladas en el presente Aviso tratará datos personales de identificación, datos personales de contacto, datos personales patrimoniales y/o financieros, así como aquella información relacionada con sus gustos, preferencias y tendencias de consumo de nuestros productos y servicios. Le informamos que para efectos de lo señalado en el presente aviso de privacidad Sears no tratará datos personales sensibles.</p>
                                <p className="pSliderL">Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sears podrá dar tratamiento a datos personales de terceros, por lo que al proporcionar los datos personales de dichos terceros para que Sears les contacte por medios físicos y/o electrónicos usted reconoce haber recabado el consentimiento requerido en términos de la normatividad aplicable.</p>
                                <p className="pSliderL">Sears no recabará ni tratará datos personales sensibles. En caso de que usted nos contacte por redes sociales, podremos recabar los datos de su perfil público de redes sociales para darle atención a sus peticiones y/o solicitudes de información en relación con los productos que ofrecemos.</p>
                            
                                <p className="pSliderTitleLred">No tratamiento de datos personales de menores de edad </p>
                                <p className="pSliderL">Sears no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad.</p>
                            
                                <p className="pSliderTitleLred">Tratamiento de datos sin decisión humana valorativa y actividades de perfilamiento</p>
                                <p className="pSliderL">Le informamos que para dar cumplimiento a las finalidades previstas en este Aviso de Privacidad Sears realizará tratamientos de datos personales como parte de un proceso de toma de decisiones sin intervención humana valorativa y técnicas de análisis masivo de datos para realizar actividades de perfilamiento a partir de sus hábitos de consumo, gustos e intereses, monitorear su comportamiento y ofrecerle una experiencia personalizada en relación con el uso de los servicios, así como para dar cumplimiento a algunas(s) de las finalidades(es ) informadas en el presente Aviso de Privacidad.</p>
                            
                                <p className="pSliderTitleLred">¿Para qué fines usamos su información?</p>
                                <p className="pSliderL">Sears tratará sus datos personales para las siguientes finalidades primarias y necesarias:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Generar una lista de los regalos y/o productos preferidos por usted;</li>
                                    <li>Identificarle y contactarlo a través de distintos medios de comunicación electrónica para facilitar la localización, tramitación, suministro, distribución y entrega de los productos solicitados por usted o que un tercero le haya obsequiado;</li>
                                    <li>Para cambio de los productos seleccionados en alguna de nuestras sucursales;</li>
                                    <li>Atender y resolver sus dudas relacionadas con la adquisición de nuestros productos y/o servicios; </li>
                                    <li>Expedición de un monedero electrónico, acumulación y canje de puntos en este último; </li>
                                    <li>Realizar consultas, investigaciones y revisiones en relación a quejas o reclamaciones;</li>
                                    <li>Facilitar la localización, tramitación, suministro, distribución y entrega de los productos solicitados por usted;</li>
                                    <li>Informarle sobre cambios o nuevos productos o servicios que estén relacionados con los productos adquiridos por usted y en el supuesto de que el producto adquirido cuente con una garantía vigente, para brindarle el servicio de asistencia técnica y/o reparación requerido;</li>
                                    <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que Sears considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros;</li>
                                    <li>Determinar la zona geográfica donde usted se encuentre para ofrecerle contenidos, promociones o beneficios correspondientes a esa zona;</li>
                                    <li>En el supuesto de que usted nos contacte a través de redes sociales además de las finalidades anteriores, podremos divulgar a través de distintos medios de información tanto físicos como electrónicos los contenidos publicados por usted dentro de las redes sociales en las que interactúe con Sears;</li>
                                    <li>En el supuesto de que usted cumpla con los requisitos establecidos por Sears para darle de alta en sus programas de lealtad;</li>
                                    <li>Identificarlo como visitante en nuestras sucursales y/o como participante en dinámicas, juegos, sorteos y actividades similares que se organicen en nuestras instalaciones. Si usted ingresa a nuestras instalaciones y/o participa en alguna de las actividades señaladas se entenderá que otorga su consentimiento expreso para que podamos dar tratamiento a su imagen para realizar la publicación de contenidos de diversa índole en plataformas y canales de comunicación de la empresa con el fin de dar publicidad a nuestras actividades, promociones, productos y servicios;</li>
                                    <li>En el supuesto de que el producto que usted adquiera cuente con una garantía vigente, para brindarle el servicio de asistencia técnica y/o reparación requeridos;</li>
                                    <li>Para dar cumplimiento a lo dispuesto por la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita;</li>
                                    <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos;</li>
                                    <li>Para llevar a cabo actividades de contacto y validación de información con los terceros que usted haya designado como referencias personales a fin de garantizar el cumplimiento de la relación jurídica con nosotros;</li>
                                    <li>Dar cumplimiento a las políticas y procedimientos de carácter interno;</li>
                                    <li>Dar cumplimiento a obligaciones previstas en la normatividad aplicable;</li>
                                    <li>Para las gestiones de facturación relacionadas con los productos adquiridos.</li>
                                </ul>
                            
                                <p className="pSliderTitleLred">¿Qué otros usos podemos darle a su información?</p>
                                <p className="pSliderL">De manera adicional, si usted no se opone, Sears podrá mantener su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de Sears de acuerdo con sus intereses, así como de otras marcas y/o Empresas del Grupo incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos;</li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sears y/o Empresas del Grupo;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero;</li>
                                    <li>Realizar estadísticas, encuestas o estudios sobre hábitos de mercado y comportamiento que tengan por finalidad evaluar la calidad de los servicios, bienes, productos y/o experiencia de compra;</li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                </ul>

                                <p className="pSliderL">En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                                <p className="pSliderL">Adicionalmente, te informamos que tú puedes en todo momento cancelar el envío de mensajes promocionales haciendo clic en el enlace Cancelar Suscripción que está integrado en el pie de página de todos los correos electrónicos enviados por Sears.</p>
                                <p className="pSliderL">La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>
                            
                                <p className="pSliderTitleLred">¿A qué terceros podemos transferir su información?</p>
                                <p className="pSliderL">Sears para cumplir la(s) finalidad(es) anteriormente descrita(s) transferirá sin requerir de tu consentimiento los datos necesarios a terceros tanto nacionales como extranjeros, intervinientes en la prestación de los productos y/o servicios y forma de pago, solicitados por Usted.</p>
                                <p className="pSliderL">Sears podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes, tanto nacionales como internacionales, en los casos legalmente previstos, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                <p className="pSliderL">Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en <a href="http://www.gsanborns.com.mx/perfil-corporativo.html" target="_blank" rel="noopener noreferrer">http://www.gsanborns.com.mx/perfil-corporativo.html</a></p>
                                <p className="pSliderL">En caso de que las operaciones que usted realice con Sears, y las cuales estén clasificadas en Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, como actividades vulnerables, Sears, podrá entregar determinada información de usted sin requerir de su consentimiento a las autoridades fiscales competentes con la finalidad de dar cumplimiento a lo dispuesto por la Ley.</p>
                                <p className="pSliderL">Sears podrá transferir  sin requerir de su consentimiento los datos personales necesarios  a:  socios comerciales y/o terceros con los que tengamos celebrados contratos de prestación de servicios para prestar servicios de entrega de bienes y productos, postventa, gestionar cuentas y registros de clientes, enviar comunicación relativa a productos o servicios que pueden ser de su interés, programas de lealtad, así como comunicaciones de marketing, con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p className="pSliderL">Asimismo, le informamos que en el supuesto de que usted incumpla con las obligaciones de pago contraídas, sus datos personales podrán ser transferidos a las Sociedades de Información Crediticia con las que tengamos celebrados acuerdos de comunicación de datos sin requerir su consentimiento por ser una obligación legal en términos de la Ley respectiva.</p>
                                <p className="pSliderL">Si usted no se opone, Sears podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen.</p>
                                <p className="pSliderL">En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>

                            
                                <p className="pSliderTitleLred">¿Cómo puede ejercer tus derechos ARCO y/o revocar tu consentimiento?</p>
                                <p className="pSliderL">Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sears a la dirección electrónica <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>.</p> 
                                <p className="pSliderL">Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sears pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sears.</p>
                                <p className='pSliderL'>Le recordamos que, si usted desea eliminar sus datos, deberá promover su derecho de cancelación, en los términos antes descritos.</p>
                                <p className="pSliderL">En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>
                            
                                <p className="pSliderTitleLred">¿Cómo puede limitar el uso y/o divulgación de tus datos?</p>
                                <p className="pSliderL">Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de Sears. Para mayor información favor de contactar al Departamento de Protección de Datos de Sears.</p>
                            
                                <p className="pSliderTitleLred">Uso de cookies y web beacons</p>
                                <p className="pSliderL">Le informamos que Sears en nuestro sitio web y/o aplicaciones podemos hacer uso de cookies, web beacons y otras tecnologías de rastreo que nos permiten cumplir con las finalidades informadas en el presente Aviso de Privacidad. Los diferentes tipos de cookies que utilizamos son:
                                </p>
                                <ul className="ulDeclaracionesDisc">
                                    <li><span className='bold'>Cookies esenciales:</span> para permitirle un uso adecuado de nuestro sitio web y/o aplicaciones, por lo que las mismas no pueden ser deshabilitadas.</li>
                                    <li><span className='bold'>Cookies para recordar sus preferencias y experiencia:</span> estas cookies nos permiten recordar sus preferencias, opciones de navegación y funciones personalizadas.</li>
                                    <li><span className='bold'>Cookies para objetivos de rendimiento y análisis:</span> podemos usar cookies propias y de terceros para identificar la forma en la cual usted utiliza nuestros Servicios con la finalidad de mejorar su rendimiento y futuros desarrollos.</li>
                                    <li><span className='bold'>Cookies publicitarias propias y de terceros:</span> podemos colocar cookies publicitarias propias y de terceros (como socios comerciales y empresas del grupo) para mostrarle publicidad que consideremos relevante para usted.</li>
                                </ul>
                                <p className="pSliderL">Usted puede deshabilitar el uso de cookies, a través de su navegador siguiendo el procedimiento establecido por éste. Aquí alguna información del procedimiento establecido en los siguientes navegadores:</p>
                                <ul className='ulDeclaracionesDisc'>
                                    <li>Internet Explorer: Herramientas -&gt; Opciones de Internet -&gt; Privacidad -&gt; Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                    <li>Firefox: Herramientas -&gt; Opciones -&gt; Privacidad y Seguridad. Para más información, puedes consultar el soporte de Mozilla o la Ayuda del navegador.</li>
                                    <li>Chrome: Configuración -&gt; Verificación de seguridad y/o -&gt; Privacidad y seguridad. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.</li>
                                    <li>Safari: Preferencias -&gt; Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.</li>
                                    <li>Edge: Configuración -&gt; Privacidad, búsqueda y servicios y/o Permisos del sitio. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                </ul>
                            
                                <p className="pSliderTitleLred">¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</p>
                                <p className="pSliderL">Sears le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet <Link to="/">http://www.sears.com.mx</Link>, por lo cual le pedimos revise la misma de manera periódica.</p>
                            
                                <p className="pSliderTitleLred">¿Cómo puede hacernos llegar tus dudas?</p>
                                <p className="pSliderL">Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                            
                                <p className="pSliderTitleLred">¿Cómo protegemos sus transacciones?</p>
                                <p className="pSliderL">Con el propósito de proteger las transacciones que usted realiza a través de nuestro sitio web, tenemos implementado un servidor seguro bajo el protocolo SSL (Secure Socket Layer) de tal menara que la información que nos envía, se transmite encriptada para asegurar su protección o si lo prefiere, puede utilizar los servicios de PayPal los cuales también ofrecen el protocolo SSL.</p>
                                <p className="pSliderL">Para verificar que se encuentra en un entorno protegido, asegúrese de que aparezca una "S" en la barra de navegación "httpS"://.</p>
                            
                                <p className="pSliderTitleLred">Recomendaciones de seguridad</p>
                                <p className="pSliderL">Es importante que elija una contraseña única y distinta a la que uses en otros sitios de internet. Asimismo, es de vital importancia que no comparta su contraseña con ningún tercero, si cree que alguien pudiere llegar a tener conocimiento de su contraseña cámbiela de inmediato.</p>
                            
                                <p className="pSliderTitleLred">INAI</p>
                                <p className="pSliderL">En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                            
                                <p className="pSliderTitleLred">Aceptación de Aviso de Privacidad</p>
                                <p className="pSliderL">Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último.</p>

                                <p className="pSliderL">Fecha de última actualización: Junio de 2023</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export function AvisoMesaDeRegalos() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Mesa de Regalos Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <AvisoMesaDeRegalosDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AvisoMesaDeRegalosMobile />
            }
        </>
    )
}