import React from 'react'
import Modal from 'react-modal'
import './blackList.sass'
import { Link } from 'react-router-dom'

//estilos aplicados al modal
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: '557px',//'40vw',
        // height: '160px', //"25vh",
        transform: 'translate(-50%, -50%)',
        maxWidth: '1400px',
        maxHeight: '800px',
        backgroundColor: 'white',
        overflow: 'initial !important'
    }
}

export default function ModalBlackList({ openModal, cerrarModal, mensaje }) {
    return (
        <Modal isOpen={openModal} onRequestClose={cerrarModal} style={customStyles} aria-modal="true" ariaHideApp={false}>
            <div id="modal-newsletter">
                <p className="textoModal">{mensaje}</p>
                <Link to={"/loginMiTelmex/"} className="closeNWL redbtn" title="close" onClick={cerrarModal} >Enterado</Link>
            </div>
        </Modal>
    );
}
