import React, { useState, useEffect } from 'react';
import { getAction, getPedido } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import { removeDuplicates } from 'functions/removeDuplicates';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { decodeStorage, removeStorage } from 'functions/storageBase64';
import { useIsMobile } from 'hooks/useIsMobile';
import { CambioDevolucionDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista22-CambioDevolucion-desktop';
import { NO_IMAGE } from 'constants/general';
import { useToken } from 'hooks/useToken';
import { repareTextHtml } from 'functions/repareDataService';

export function CambioDevolucionMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);

    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState(false);
    const [listAction, setListAction] = useState(false);
    const [, setStorageCD] = useStorage64('storageCD', '');
    const [, setActionId] = useStorage64("storageActionId", "");
    const [nombreProducto, setNombreProducto] = useState(false);
    const idActionSeleccionada = decodeStorage("storageIdProblem");


    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((action && action.action) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, action])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    useEffect(() => { }, [idActionSeleccionada])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista22mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )

                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setNombreProducto(productoNombre);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
               
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // obtengo list action id
    useEffect(() => {
        let reasonId = idActionSeleccionada;

        getAction(reasonId)
            .then(setAction);
    }, [])

    // almaceno listado de action
    useEffect(() => {
        let arrayAction = [];
        let reasonId = idActionSeleccionada;

        if (action) {
            if (action.action) {
                action.action.map((act, i) =>
                    arrayAction.push({ "id": act.id, "name": act.name })
                )
                setListAction(arrayAction);
            }
            else if (action.statusCode && action.statusCode === 401) {
               
                setTokenJWT(true);
                setTimeout(() => {
                    getAction(reasonId)
                        .then(setAction);
                }, 1000);
            }
        }
    }, [action])


    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a cual es tu motivo
    const handleContinuarAseleccion = (id) => {
        removeStorage('storageCD');
        setStorageCD(id);
        setActionId(id);

        // lleva a solicitar cambio 9 --- lleva a solicitar devolucion 10
        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/solicitar-cambio-devolucion");
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">¿Qué quieres hacer?</p>
                    </div>

                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            <div className="contenedorImagenesIgual1">
                                <ul className='ulIgual1'>
                                    {ima && ima.map((elemento, i) =>
                                        <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                        </li>
                                    )}
                                </ul>
                            </div>

                            {ima && ima.length > 1
                                ? <div className='tituloImagenes'>
                                    <p>¿Qué quieres hacer?</p>
                                </div>
                                : <div className='tituloImagenes'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                            }
                        </div>

                        <div className="boxUlRazones">
                            <ul className="">
                                {listAction && listAction.map((problem) =>
                                    <li id={problem.id} key={problem.id} onClick={() => handleContinuarAseleccion(problem.id)}>
                                        <span className="nombreRazon">{problem.name}</span>
                                        <span className="iconoRazon"></span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export function CambioDevolucion(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <CambioDevolucionDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <CambioDevolucionMovil />
                : null
            }
        </>
    )
}