import React, { useState, useEffect } from 'react';
import { getBanks, getPedido, postCreateTiket } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import { useForm } from "react-hook-form";
import InputType from 'components/Forms/InputType';
import { removeDuplicates } from 'functions/removeDuplicates';
import { SelectTypeAD } from 'components/Forms/Select';
import ModalPortal from 'components/Modal';
import { ModalError } from './vista4-modal';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeStorage } from 'functions/storageBase64';
import Loading from 'components/Loading';
import { FORMA_PAGO_REEMBOLSABLE, LABEL_AUTORIZADO, LABEL_PREPARANDO_PRODUCTO, NO_IMAGE, PEDIDOS_PRODUCTO_ENTREGADO } from 'constants/general';
import { useIsMobile } from 'hooks/useIsMobile';
import { FormularioDatosReembolsoDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista35-datosReembolso-desktop.js';
import { useToken } from 'hooks/useToken';

export function FormularioDatosReembolsoMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    const [datosBancarios, setDatosBancarios] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            clabe: datosBancarios.clabe,
            banco: datosBancarios.banco,
            titular: datosBancarios.titular,
            curp: datosBancarios.curp
        }
    });
    const [datos, setDatos] = useState();
    const [returnForm, SetReturnForm] = useState(false);

    // listado de bancos
    const [bancos, setBancos] = useState(false);
    const [lisBanks, setListBanks] = useState(false);

    // informacion de pedido
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [, setEstatusPedido] = useState(0);
    const [formaPago, setFormaPago] = useState(0);

    // banderas para mostrar ayuda inputs
    const [flagClabe, setFlagClabe] = useState(false);
    const [, setFlagBancos] = useState(false);
    const [, setFlagTitular] = useState(false);
    const [flagCurp, setFlagCurp] = useState(false);

    // crear ticket
    const [showModalError, SetShowModalError] = useState(false); //mostrar modal error
    const [crearTicket, setCrearTicket] = useState(false);
    const [loading, setLoading] = useState(false); //loading mientras se crea el ticket
    const [remover, setRemover] = useState(false); // activa loading cuando se comienza a crear el ticket
    const [infoStorage] = useStorage64("infoTicket"); // obtiene la informacion del storage para crear el ticket
    const [infoStorageEntregado] = useStorage64("infoTicketEntregado"); // obtiene la informacion del storage para crear el ticket entregado
    const [storageImgProducto,] = useStorage64('storageImgProducto'); // obtiene las imagenes del storage
    const [saveStatusProducto,] = useStorage64('saveStatusProducto', '');
    const [storageActionId,] = useStorage64('storageActionId', '');
    const [storageMotivoEntregado,] = useStorage64('storageMotivoEntregado', '');
    const [saveIdStatusProducto,] = useStorage64('saveIdStatusProducto', '');

    //nuevas variables Sears, peso y tipo de guia
    const [peso_volumetrico, setPeso_volumetrico] = useState(false);
    const [storageDatosBancarios, setStorageDatosBancarios] = useStorage64("storageDatosBancarios", "");
    const [VieneDeNoTerminoComoEsperabamos] = useStorage64("storageEsUnRegalo");
    const [yaNoSePuedeRegalar] = useStorage64('storageyanosepuederegalar');
    const [, setStorageOpcionDevolver] = useStorage64('storageDevolverOpcion', '');
    const [correoUsuario] = useStorage64('storageCorreoUsuario');
    const [shopId, setShopId] = useState(false);
    const [storageReasonId] = useStorage64('storageIdProblem');
    const [, setRecoleccionDomicilio] = useState("storageRecoleccionDomicilio", "");
    const [idTiendaFisica, setIdTiendaFisica] = useState(false);
    const [tipoGuia, setTipoGuia] = useState(false);

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((bancos && bancos.bancos) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, bancos])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista35- datos bancarios - mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];
        let pesovolumetrico = [];
        let shopProducto = [];
        let arrayGuia = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem?.images[0]?.url, "id": elem?.related_product_id })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && pesovolumetrico.push({ "peso_volumetrico": elem?.dynamic_help?.return_by_volumetric_weight })
                        )

                        // guarda informacion sobre el vendedor del producto
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && shopProducto.push({ "idTienda": elem.shop?.idTienda, "nameTienda": elem.shop?.name, "public_shop_id": elem.shop?.public_shop_id })
                        )

                        // guarda informacion sobre el tipo de guia
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && arrayGuia.push({ "tipoGuia": elem.tracking.guide_type })
                        )

                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);

                        setEstatusPedido(resultadoPedido?.pedido[0]?.axxi.status_axxi_id);
                        setFormaPago(resultadoPedido?.pedido[0]?.payment_method.id);

                        setPeso_volumetrico(pesovolumetrico[0]?.peso_volumetrico);
                        setShopId(shopProducto[0].idTienda);
                        setIdTiendaFisica(shopProducto[0]?.public_shop_id);
                        setTipoGuia(arrayGuia[0].tipoGuia);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // obtiene servicio bancos
    useEffect(() => {
        getBanks()
            .then(setBancos);
    }, [])

    useEffect(() => {
        if (bancos) {
            if (bancos.bancos && bancos.status === 200) {
                setListBanks(bancos.bancos);
            }
            else if (bancos.statusCode && bancos.statusCode === 401) {
                setTokenJWT(true);
                setTimeout(() => {
                    getBanks()
                        .then(setBancos);
                }, 1000);
            }
            else {
                history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
            }
        }
    }, [bancos])

    // guarda la informacion enviada en el formulario
    const sendFormInformacionBancaria = data => {
        setDatos({
            "voucher_url": "n/a",
            "interbank_code": data.clabe,
            "bank_id": Number(data.banco),
            "identification_id": 1,
            "identification": data.curp,
            "account_holder": data.titular
        })
        SetReturnForm(true);
    }

    // al recibir data del formulario se guarda en variable datos bancarios
    useEffect(() => {
        if (returnForm) {
            setDatosBancarios(datos);
            SetReturnForm(false);
        }
    }, [returnForm])

    // muesta el modal seguro de que desea cancelar el pedido
    useEffect(() => {
        if (datosBancarios) {

            setStorageDatosBancarios(datosBancarios);
            siRemove();
        }
    }, [datosBancarios])

    // regresa a la pagina anterior al formulario
    const onClose = () => {
        history.goBack();
    }

    // accion para si cancelar pedido
    const siRemove = () => {

        // preparando tu producto / forma pago igual a oxxo, seven, deposito o tienda departamental
        if (idProductoEstate !== 0 &&
            (LABEL_PREPARANDO_PRODUCTO === String(saveStatusProducto).toLowerCase() || LABEL_AUTORIZADO === String(saveStatusProducto).toLowerCase() ||
                !PEDIDOS_PRODUCTO_ENTREGADO.includes(saveIdStatusProducto)) && !FORMA_PAGO_REEMBOLSABLE.includes(formaPago)) {
            setRemover(true); // activa loading

            let reason_id = infoStorage.reason_id;
            let order_id = infoStorage.order_id;
            let order_number = infoStorage.order_number;
            let client_id = infoStorage.client_id;
            let description = infoStorage.description;
            let products_to_cancel = infoStorage.products_to_cancel;
            let ticket_type_id = infoStorage.ticket_type_id;
            let refound_details = datosBancarios;
            let additional_details = infoStorage.additional_details;
            let action_id = infoStorage.action_id;
            let correo = correoUsuario;

            postCreateTiket({
                reason_id,
                order_id,
                order_number,
                client_id,
                description,
                products_to_cancel,
                ticket_type_id,
                refound_details,
                additional_details,
                action_id,
                correo
            })
                .then(setCrearTicket);
        }

        // producto entregado / forma pago igual a oxxo, seven, deposito o tienda departamental
        if (idProductoEstate !== 0 && PEDIDOS_PRODUCTO_ENTREGADO.includes(saveIdStatusProducto) && !FORMA_PAGO_REEMBOLSABLE.includes(formaPago)) {
            let reason_id = infoStorageEntregado?.reason_id;
            let order_id = infoStorageEntregado?.order_id;
            let order_number = infoStorageEntregado?.order_number;
            let client_id = infoStorageEntregado?.client_id;
            let description = storageMotivoEntregado ? storageMotivoEntregado : "";
            let ticket_type_id = infoStorageEntregado?.ticket_type_id;
            let refound_details = datosBancarios;
            let additional_details = storageImgProducto;
            let action_id = storageActionId ? storageActionId : 0;
            let correo = correoUsuario;

            setStorageDatosBancarios(datosBancarios);

            //--------- viene de flujo: no termino como esperabamos GRATIS
            if (VieneDeNoTerminoComoEsperabamos === true && Number(infoStorageEntregado?.reason_id) === 9 && (Number(storageActionId) === 1 || Number(storageActionId) === 2 || Number(storageActionId) === 3 || Number(storageActionId) === 4)) {
                let products_to_cancel = [{ "id": idProductoEstate, "return_type": null, "subsidiary_id": null }];
                setRemover(true); // activa loading

                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }


            //--- viene de reason 9. tengo un problema con mi producto, action: 1,2,3,4 menor a 200 pero ha devuelto 2 
            else if (yaNoSePuedeRegalar === true && Number(infoStorageEntregado?.reason_id) === 9 && (Number(storageActionId) === 1 || Number(storageActionId) === 2 || Number(storageActionId) === 3 || Number(storageActionId) === 4)) {
                setStorageOpcionDevolver(1);
                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/elegir-tienda");
            }

            // viene de razon 9, action 1, 2, 3, 4  && precio mayor a 200 pesos
            // viene de razon 9. tengo un problema con mi producto, action 8
            // viene de razon 9. tengo un problema con mi producto, action 9
            else if ((Number(infoStorageEntregado?.reason_id) === 9 && Number(storageActionId) === 9) ||
                (Number(infoStorageEntregado?.reason_id) === 9 && Number(storageActionId) === 8) ||
                (Number(infoStorageEntregado?.reason_id) === 9 && (Number(storageActionId) === 1 || Number(storageActionId) === 2 || Number(storageActionId) === 3 || Number(storageActionId) === 4))) {

                if (peso_volumetrico === true) {
                    history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/como-prefieres-devolver");
                }
                else {
                    //crea el ticket para la devolucion, redirecciona a cancelacion exitosa 
                    let products_to_cancel = [{ "id": idProductoEstate, "return_type": 2, "subsidiary_id": null }];
                    setRemover(true); // activa loading

                    postCreateTiket({
                        reason_id,
                        order_id,
                        order_number,
                        client_id,
                        description,
                        products_to_cancel,
                        ticket_type_id,
                        refound_details,
                        additional_details,
                        action_id,
                        correo
                    })
                        .then(setCrearTicket);
                }
            }
            // 9. tengo un problema con mi producto, 11. mi producto esta maltratado o roto
            else if (Number(infoStorageEntregado?.reason_id) === 9 && Number(storageActionId) === 11) {
                let products_to_cancel = [{ "id": idProductoEstate, "return_type": null, "subsidiary_id": null }];
                setRemover(true); // activa loading

                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }

            //14 recibi el paquete vacio
            //16 aparece como entregado pero aun no lo recibo
            else if (Number(infoStorageEntregado?.reason_id) === 16 || Number(infoStorageEntregado?.reason_id) === 14) {

                //crea el ticket para la devolucion, redirecciona a cancelacion exitosa 
                let products_to_cancel = [{ "id": idProductoEstate, "return_type": null, "subsidiary_id": null }];
                setRemover(true); // activa loading

                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }
        }
    }

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    const [idTicketStorage, setIdTicketStorage] = useStorage64('storageIdTicket');
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {

                    removeStorage("infoTicket");
                    removeStorage("infoTicketEntregado");

                    //--------- viene de flujo: no termino como esperabamos
                    if (VieneDeNoTerminoComoEsperabamos === true) {
                        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
                    }
                    //--------- NO VIENE DE flujo: no termino como esperabamos
                    else {
                        if (idProductoEstate !== 0) {
                            setIdTicketStorage(crearTicket.data.id);
                            // es reason 9 y action: 8 ó 9
                            // es reason 9 y action 1, 2, 3, 4
                            if ((storageReasonId === 9 && storageActionId === 8) || (storageReasonId === 9 && storageActionId === 9) ||
                                (storageReasonId === 9 && (storageActionId === 1 || storageActionId === 2 || storageActionId === 3 || storageActionId === 4))) {
                                //es guia automatica
                                if (tipoGuia === 2) {
                                    setRecoleccionDomicilio(2);

                                    history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/typ");
                                }
                                //no es guia automatica
                                else {
                                    history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/seller-typ");
                                }
                            }
                            else {
                                history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cancelacion-exitosa");
                            }
                        }
                        else {
                            history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                        }
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    // activar on focus
    const onFocusInputs = (e) => {
        let idFocus = e.target.id;

        if (idFocus === "clabe") {
            setFlagClabe(true);
        }
        else if (idFocus === "banco") {
            setFlagBancos(true);
        }
        else if (idFocus === "titular") {
            setFlagTitular(true);
        }
        else if (idFocus === "curp") {
            setFlagCurp(true);
        }
    }

    // desactivar on focus
    const onBlurInputs = (e) => {
        let idFocus = e.target.id;

        if (idFocus === "clabe") {
            setFlagClabe(false);
        }
        else if (idFocus === "banco") {
            setFlagBancos(false);
        }
        else if (idFocus === "titular") {
            setFlagTitular(false);
        }
        else if (idFocus === "curp") {
            setFlagCurp(false);
        }
    }

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }
    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    //------ Validaciones ------
    const valClabe = {
        required: 'Campo obligatorio',
        minLength: {
            value: 18,
            message: 'Faltan dígitos. Deben ser 18 dígitos'
        },
        maxLength: {
            value: 18,
            message: 'No debe contener más de 18 dígitos'
        }
    }
    const valBanco = {
        required: 'Campo obligatorio',
        minLength: {
            value: 1,
            message: 'El banco es requerido'
        }
    }
    const valTitular = {
        required: 'Campo obligatorio',
        minLength: {
            value: 6,
            message: 'El nombre del titular al menos debe de contener 6 carácteres'
        }
    }
    const valCurp = {
        required: 'Campo obligatorio',
        minLength: {
            value: 12,
            message: 'Faltan carácteres, Debe ser de al menos 12 carácteres'
        },
        maxLength: {
            value: 18,
            message: 'Debe contener máximo 18 carácteres'
        }
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Ingresa tus datos</p>
                    </div>

                    <div className='headerImagenesTitulo marginT30'>
                        {ima && ima.length > 1
                            ? <div className="contenedorImagenesMas1">
                                <ul className='ulMas1'>
                                    {ima && ima.map((elemento, i) => i <= 2
                                        && <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={50} />
                                        </li>
                                    )}
                                    {/* mas de 3 */}
                                    {ima?.length > 3 && <li className="mas1"> más ({ima.length - 3})</li>}
                                </ul>
                            </div>
                            : <div className="contenedorImagenesIgual1">
                                <ul className='ulIgual1'>
                                    {ima && ima.map((elemento, i) =>
                                        <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                        </li>
                                    )}
                                </ul>
                            </div>
                        }
                    </div>

                    <div className="copyForm">
                        <p>Para poder generar tu reembolso, ingresa la siguiente información:</p>
                    </div>

                    <form onSubmit={handleSubmit(sendFormInformacionBancaria)} className="formInformacionBancaria">
                        <InputType
                            type="text"
                            name="clabe"
                            id="clabe"
                            placeholder="Clabe interbancaria"
                            refs={register(valClabe)}
                            autocomplete='clabe'
                            errorMessage={errors.clabe && errors.clabe.message}
                            onFocus={(e) => onFocusInputs(e)}
                            onBlur={(e) => onBlurInputs(e)}
                            flag={flagClabe}
                            texto={"Tu clabe esta compuesta por 18 dígitos."}
                            max="999999999999999999"
                            maxLength={18}
                            onKeyDown={function (e) {
                                if ((e.keyCode < '48' || e.keyCode > '57') && e.keyCode != "8") {
                                    e.preventDefault()
                                }
                            }}
                        />

                        <SelectTypeAD
                            type="text"
                            name="banco"
                            id="banco"
                            placeholder="Selecciona tu banco"
                            refs={register(valBanco)}
                            options={lisBanks}
                            errorMessage={errors.banco && errors.banco.message}
                        />

                        <InputType
                            type="text"
                            name="titular"
                            id="titular"
                            placeholder="Titular de la cuenta"
                            refs={register(valTitular)}
                            autocomplete='titular'
                            errorMessage={errors.titular && errors.titular.message}
                        />

                        <InputType
                            type="text"
                            name="curp"
                            id="curp"
                            placeholder="RFC o CURP"
                            refs={register(valCurp)}
                            autocomplete='curp'
                            errorMessage={errors.curp && errors.curp.message}
                            onFocus={(e) => onFocusInputs(e)}
                            onBlur={(e) => onBlurInputs(e)}
                            flag={flagCurp}
                            texto={"Tu curp esta compuesto por 18 carácteres / Tu RFC esta compuesto por 12 ó 13 carácteres."}
                            maxLength={18}
                        />

                        <div className="boxBtnFinalizar">
                            <button className="btnFinalizarForm">Finalizar</button>
                        </div>
                    </form>


                    {showModalError &&
                        <ModalPortal type="modalSB" onClose={handleCloseError} >
                            <ModalError okRegresar={regresarPedidos} />
                        </ModalPortal>
                    }
                </div>
            </div>
        </>
    )
}

export function FormularioDatosReembolso(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <FormularioDatosReembolsoDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <FormularioDatosReembolsoMovil />
                : null
            }
        </>
    )
}