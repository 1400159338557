import React, { useState, useEffect } from 'react';
import './style.sass';
import { useStorage64 } from 'hooks/useStorageBase64';
import CurrencyFormat from "react-currency-format";
import { useHistory } from 'react-router-dom';
import $ from "jquery";
import SlidersEmarsys from 'components/Emarsys/SlidersEmarsys';
import { encodeStorage } from 'functions/storageBase64';
import { useIsMobile } from 'hooks/useIsMobile';
import { VARIANTS_PER_PORTAL } from 'constants/general'


export default function MiTelmex({ telefono, credito, meses }) {
    const [mostrarMensualidades, setMostrarMensualidades] = useState(false);
    const [profileData] = useStorage64('profileData', '');
    const history = useHistory();
    const { isMobile } = useIsMobile();

    var usuario = profileData.nombre;
    var tel = telefono;

    // revisa si balance (saldo disponible) tiene decimales sino los agrega
    if (Number(credito.balance % 1) === 0) {
        var balance = credito.balance + '.001';
    } else {
        var balance = credito.balance;
    }
    var saldoDisponible = <CurrencyFormat
        value={balance}
        displayType={'text'}
        decimalScale={2}
        FixedDecimalScale={true}
        thousandSeparator={true} prefix={'$'} renderText={value => <>{value} MXN </>}
    />

    // guarda en el storage el saldo disponible de telmex
    useState(() => {
        if (balance) {
            encodeStorage('saldoTelmexService', Number(balance).toFixed(2));
        }
    }, [balance]);

    // revisa si spent (saldo gastado) tiene decimales sino los agrega
    if (Number(credito.spent % 1) === 0) {
        var spent = credito.spent + '.001';
    } else {
        var spent = credito.spent;
    }
    var saldoGastado = <CurrencyFormat
        value={spent}
        displayType={'text'}
        decimalScale={2}
        FixedDecimalScale={true}
        thousandSeparator={true} prefix={'$'} renderText={value => <>{value} MXN</>}
    />

    // revisa si limit (limite de credito) tiene decimales sino los agrega
    if (Number(credito.limit % 1) === 0) {
        var limite = credito.limit + '.001';
    } else {
        var limite = credito.limit;
    }
    var limiteCredito = <CurrencyFormat
        value={Number(limite)}
        displayType={'text'}
        decimalScale={2}
        FixedDecimalScale={true}
        thousandSeparator={true} prefix={'$'} renderText={value => <>{value} MXN</>}
    />

    // activa bandera para mostrar mensualidades
    const handleMostrarMensualidades = () => {
        setMostrarMensualidades(!mostrarMensualidades);
    };

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account | Mi Telmex');
        if (tel) {
            window.ineum('meta', 'telmex_phone', tel);
        }
    }, []);

    // al presionar regresar cambia la url a home
    useEffect(() => {
        if (window.history && window.history.pushState) {
            window.history.pushState('forward', null, '');
            $(window).on('popstate', function () {
                // removeStorage('profileData')
                // removeStorage('telefono_usuario')
                history.push("/");
            });
        }
    }, [window.history]);

    return (
        <>
            {/* <Header /> */}

            {/* <div className="sectionTitle">
                <Link to=""> Mi crédito Telmex</Link>
            </div> */}

            <main>
                <div className="boxGeneralTelmex">
                    <div className="boxDatosTelmex">
                        <p className="textUsuario">{usuario}</p>
                        <p className="textTelefono">{tel}</p>
                    </div>

                    <div className="boxtopTelmex">
                        <p>Crédito disponible: </p>

                        <h1 className="textSaldoDisponible">{saldoDisponible}</h1>

                        <div className="boxTexts">
                            <p className="textSaldoGastado"><span className="izqT">Has gastado:       </span> <span className="derT">{saldoGastado} </span></p>
                            <p className="textLimiteCredito"><span className="izqT">Límite de crédito:</span> <span className="derT">{limiteCredito}</span></p>
                        </div>
                    </div>

                    <div className="boxbottomTelmex">
                        <div className="listToggle">
                            <dl className="opciones" >
                                {mostrarMensualidades === true
                                    ? <>
                                        <dt className="verMontos">
                                            <p className="titleOpciones" onClick={handleMostrarMensualidades}>Ver montos máximos por mensualidad <span className="flecha-arr"></span></p>
                                        </dt>
                                        <table>
                                            <thead>
                                                <tr className="title"><th>Mensualidades</th><th>Monto</th></tr>
                                            </thead>

                                            <tbody>
                                                {meses.map((mes, i) => {
                                                    return (<tr key={i}><td>{mes.month}</td>
                                                        <td><CurrencyFormat
                                                            value={mes.price}
                                                            displayType={'text'}
                                                            thousandSeparator={true} prefix={'$'} renderText={value => <>{value} MXN</>}
                                                        /></td>
                                                    </tr>
                                                    )
                                                })}
                                            </tbody>

                                        </table>
                                    </>
                                    : <dt>
                                        <p className="title" onClick={handleMostrarMensualidades}>Ver montos máximos por mensualidad <span className="flecha-sigui"></span></p>
                                    </dt>
                                }
                            </dl>
                        </div>
                    </div>

                    <div className="comoComprar">
                        <a href="https://www.youtube.com/watch?v=DgRxMuo7dxg&feature=youtu.be&ab_channel=Claroshop" target="_blank" rel="noopener noreferrer">¿Cómo comprar con cargo a mi recibo Telmex?</a>
                    </div>
                </div>


                <div className="boxDesktop">
                    <div className="contenedorLeftRight">
                        <div className="boxLeft">
                            <div className="datos">
                                <h3>{usuario}</h3>
                                <p>{telefono}</p>
                            </div>
                            <div className="saldos">
                                <p className="textDisponible">Crédito Telmex disponible:</p>
                                <h3 className="saldo">{saldoDisponible}</h3>
                                <p className="textGastado"><span className="izqT">Has gastado:       </span> <span className="derT">{saldoGastado} </span></p>
                                <p className="textLimite">Límite de crédito: {limiteCredito}</p>
                            </div>
                        </div>
                        <div className="boxRight">
                            <div className="tituloMensualidades">
                                <p>Montos máximos por mensualidad</p>
                            </div>

                            <div className="mensualidades">
                                <table>
                                    <thead>
                                        <tr className="title"><th>Mensualidades</th><th>Monto</th></tr>
                                    </thead>

                                    <tbody>
                                        {meses.map((mes, i) => {
                                            return (<tr key={i}><td>{mes.month}</td>
                                                <td><CurrencyFormat
                                                    value={mes.price}
                                                    displayType={'text'}
                                                    thousandSeparator={true} prefix={'$'} renderText={value => <>{value} MXN</>}
                                                /></td>
                                            </tr>
                                            )
                                        })}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="comoComprar">
                        <a href="https://www.youtube.com/watch?v=DgRxMuo7dxg&feature=youtu.be&ab_channel=Claroshop" target="_blank" rel="noopener noreferrer">¿Cómo comprar con cargo a mi recibo Telmex?</a>
                    </div>
                </div>

                {VARIANTS_PER_PORTAL?.emarsys?.telmex
                    ?
                        <div id="cartSlider">
                            {isMobile
                                ?
                                isMobile === 'desktop'
                                    ?
                                    <SlidersEmarsys
                                        logica={'CART'}
                                        idContent={'cartSlider'}
                                        title={"Recomendaciones para ti"}
                                        typeView={'sliderView'}
                                        cant={32}
                                    />
                                    : isMobile === 'movile'
                                        ? null
                                        :
                                        <SlidersEmarsys
                                            logica={'CART'}
                                            idContent={'cartSlider'}
                                            title={"Recomendaciones para ti"}
                                            typeView={'blockView'}
                                            cant={32}
                                        />
                                : null

                            }
                        </div>
                    : null
                }
            </main>

        </>
    )
}