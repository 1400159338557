import { useState, useEffect } from "react";

import { decodeStorage, encodeStorage, removeStorage } from "functions/storageBase64";
import MD5 from "crypto-js/md5"
import getUserProfile from "services/UserProfile";
import { useToken } from 'hooks/useToken'


export function useTimeToken(){

    const [token, setToken] = useState(false);
    const [loginFinish, setLoginFinish] = useState(false)
    const [finishTokens, setFinishTokens] = useState(false)
    const [finishInfo, setFinishInfo] = useState(false)
    const domain = window.location.hostname

    useEffect(()=>{
        if(token){
            if(token?.access_token && token?.refresh_token){

                let tokenJWT = window.atob(token?.access_token?.split('.')[1])
                encodeStorage('access_token',token?.access_token)
                encodeStorage('session_token', token?.access_token)
                encodeStorage('access_token_exp', JSON.parse(tokenJWT)?.exp)
                encodeStorage('idCarrito', MD5(`${JSON.parse(tokenJWT)?.email}`).toString() )
                
                document.cookie = `access_token=${token?.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                document.cookie = `token_cart=${ MD5(`${JSON.parse(tokenJWT)?.email}`).toString()  };Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                let tokenRefreshJWT = window.atob(token?.refresh_token?.split('.')[1])
                encodeStorage('refresh_token',token?.refresh_token)
                encodeStorage('refresh_token_exp', JSON.parse(tokenRefreshJWT)?.exp)
                
                document.cookie = `refresh_token=${token?.refresh_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`   
                setFinishTokens(true)
            }
        }
    },[token])


    const [profileGet, setProfleGet] = useState(false);
    const [profileResponse, setProfleResponse] = useState();
    useEffect(() => {
        if(loginFinish){
            getUserProfile()
            .then(setProfleResponse)
            setLoginFinish(false)
        }
    }, [loginFinish]);


    useEffect(() => {
        if (profileResponse) {
            if(profileResponse.statusCode === 200) {
                if(profileResponse.data) {
                    if(profileResponse.data.data) {
                        const {
                            name,
                            email,
                            id,
                            last_name,
                            second_last_name,
                            gender,
                            birthday
                        } = profileResponse.data.data
                        
                        let userDataProfile = {
                            idClaro: id,
                            nombre: name,
                            apellidoP: last_name,
                            apellidoM: second_last_name,
                            nombreCompleto: name+' '+last_name+' '+second_last_name,
                            genero: gender,
                            cumpleanios: birthday,
                            correo: email
                        }
                        setProfleGet(userDataProfile);
                        window.ineum('meta', 'Id_Sears', id);
                        window.ineum('user', id, name, email);
                        window.ineum('meta', 'LoginDate', Date.now() );
                        encodeStorage('LoginDate', Date.now() )
                        window.ineum('meta', 'Logout', 'null');
                        setFinishInfo(true)
                    }
                }
            }

        }
    }, [profileResponse]);

    useEffect(()=>{
        if(profileGet){
            encodeStorage('profileData', profileGet)
        }
    },[profileGet])

    const typeToken = (valToken) => {
        setToken(valToken)
    }
    
    const usrData = (val) => {
        setFinishTokens(false)
        setLoginFinish(val)
    }

    return{
        typeToken,
        usrData,
        finishTokens,
        finishInfo
    }
}


export function usePrelogin(){

    const {
        typeToken,
        usrData,
        finishTokens,
        finishInfo 
    } = useTimeToken()

    const domain = window.location.hostname
    const refreshCyR = useToken({firstActive: false, name: "Prelogin", timeRef: 1000})

    const closeWebview = () => {
        try{
            window.location.href = "searswebview://closeWebView";
        }catch(e){
            console.log('error al cerrar webview: ',e)
        }
        removeStorage("misDatos")
        removeStorage('rutaGet')
        removeStorage('access_token')
        removeStorage('refresh_token')
        removeStorage('session_token')
        removeStorage('idCarrito')
        removeStorage('access_token_exp')
        removeStorage('refresh_token_exp')
        removeStorage("profileData")

        document.cookie = `access_token=;Max-Age=${0};Path=/;Domain=${domain};`
        document.cookie = `refresh_token=;Max-Age=${0};Path=/;Domain=${domain};`
        // window.location.replace(window.location.href)
    }

    const [misDatos, setMisDatos] = useState();
    const [redirect, setRedirect] = useState();
    useEffect(()=>{
        if(window.location.pathname === '/pre_login'){
            
            setMisDatos( decodeStorage("misDatos") )

            if(window.location.search.indexOf('redirect_url') >= 1){
                setRedirect(window.location.search.split('redirect_url=')[1].split('&')[0])
                encodeStorage('rutaGet', window.location.search.split('redirect_url=')[1].split('&')[0])
            }
            else{
                closeWebview()
            }
        }
    },[])

    useEffect(()=>{
        if(misDatos){
            const access_token = misDatos?.access_token
            const refresh_token = misDatos?.refresh_token

            if(access_token && refresh_token){
                typeToken(
                    {
                        'access_token': access_token,
                        'refresh_token': refresh_token
                    }
                )
            }
        }
    },[misDatos])

    const [serviceRT, setServiceRT] = useState(false)
    const [uD, setUD] = useState(false)
    useEffect(()=>{
        if(finishTokens){
            let isLogin = decodeStorage('access_token')
            let  actTimeLS = Date.now() 
            let  atExpLS = decodeStorage('access_token_exp') * 1000
            setUD(false)

            // console.log('------------------------------')
            // console.log('actTimeLS : ', new Date() )
            // console.log('atExp   : ', new Date (atExpLS) )
            // console.log('time ok : ', actTimeLS <= atExpLS)
            // console.log('actionOK: ', finishTokens)
            // console.log('------------------------------')

            if(isLogin){
                if(actTimeLS <= atExpLS || Date.now() <= decodeStorage('access_token_exp') * 1000 ){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }
    },[finishTokens, uD])

    
    useEffect(()=>{
        if(serviceRT){
            usrData(true)
        }
    },[serviceRT])


    const [finish, setFinish] = useState(false)
    useEffect(()=>{
        if(finishInfo){
            // if(redirect === 'mesa_de_regalos'){
            //     window.location.href = '/test'
            // }
            setFinish(true)
        }
    },[finishInfo])

    const setAction = (val) => {
        if(val === 'close'){
            closeWebview()
        }
    }

    return {
        setAction,
        misDatos,
        redirect,
        finish
    }
}
