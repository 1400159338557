import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import EmptyCart from 'components/Error/EmptyCart'
import EmptyWishlist from 'components/Error/EmptyWishlist'
import ErrorConection from 'components/Error/ErrorConection'
import ErrorProduct from 'components/Error/ErrorProduct'
import ErrorSearch from 'components/Error/ErrorSearch'
import ErrorMessageServices from 'components/Error/ErrorServices'
import ErrorSession from 'components/Error/ErrorSession'
import ModalPortal from 'components/Modal'
import { removeStorage } from 'functions/storageBase64'

export default function ErrorType(props){
    const{
        section,
        codeStatus,
        errorText,
        //acceptReturn,
        searchQ,
        suggestion
    } = props
    const hisotry = useHistory()

    const [showModal, SetShowModal] = useState(false)
    // funciones para mostrar el modal
    const handleClose = () => SetShowModal(false)

    useEffect(()=>{
        //console.log(acceptReturn)
        SetShowModal(true)
    },[])

    const acceptReturn401Cart = (val) =>{
        SetShowModal(!val)
        hisotry.push('/carrito/detalle')
    }
    const acceptReturn401 = (val) =>{
        SetShowModal(!val)
        //-----
        removeStorage('session_token')
        removeStorage('idCarrito')
        removeStorage('sendDirections')
        removeStorage('total_items')
        removeStorage('profileData')
        removeStorage('productsWishlist')
        removeStorage('cartData')
        removeStorage('selectId')
        removeStorage('shipping')
        removeStorage('dirPredeterminada')
        removeStorage('codigoPostal')
        removeStorage('existDirPredeterminada')
        removeStorage('telefono_usuario')
        removeStorage('saldoTelmexService')
        removeStorage('cartFormEm')
        removeStorage('busquedas')
        removeStorage('dataFacebook')
        removeStorage('typeButton')
        removeStorage('wishlistAfter')
        removeStorage('productAfter')
        removeStorage('okCartProductAfter')
        removeStorage('okBuyProductAfter')
        window.localStorage.removeItem("closebtnMT-detalle")
        window.localStorage.removeItem("closebtnMT-home")
        //-----
        hisotry.push('/login')
    }

    // console.log('errorText',errorText)
    // console.log('codeStatus',codeStatus)

    return(
        <>
            {codeStatus === 0
                ? <ErrorConection errorCode={codeStatus} />
                : null
            }
            {codeStatus === 401  && section === 'cart' && errorText
                ? showModal && <ModalPortal type="modalSB" onClose={handleClose} ><ErrorSession errorText="Tu sesion expiró por seguridad" acceptReturn={acceptReturn401Cart}/></ModalPortal>
                : null
            }
            {codeStatus === 401 && section !== 'cart'
                ? showModal && <ModalPortal type="modalSB" onClose={handleClose} ><ErrorSession errorText={errorText}  acceptReturn={acceptReturn401}/></ModalPortal>
                : null
            }
            {codeStatus === 404 && section === 'cart'
                ? <EmptyCart/>
                : null
            }
            {codeStatus === 404 && section === 'wishlist'
                ? <EmptyWishlist/>
                : null
            }
            {codeStatus === 404 && !section
                ? <ErrorProduct errorText={errorText} />
                : null
            }
            {codeStatus === 500 && errorText
                ? <ErrorMessageServices errorCode={codeStatus} error_message={errorText} />
                : null
            }
            {codeStatus === 500 && !errorText
                ? <ErrorMessageServices errorCode={codeStatus} error_message={'Código de error: '+ codeStatus} />
                : null
            }
            {codeStatus > 500 && codeStatus
                ? <ErrorConection errorCode={codeStatus} />
                : null
            }
            {errorText && codeStatus === 200
                ? <ErrorMessageServices error_message={errorText} />
                : null
            }
            {searchQ && suggestion
                ? <ErrorSearch searchQ={searchQ} suggestion={suggestion}/>   
                : null
            }
        </>
    )
}