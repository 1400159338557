import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function UseModal({ dataImage, setIsShowing, fijarIndex, indexAbrir, setIndexAbrir }) {

    const [photoIndex, setphotoIndex] = useState(Number(indexAbrir));

    let images = []
    dataImage.map((ima, i) => {
        return (images.push(ima.url))
    })

    useEffect(() => {
        fijarIndex(Number(photoIndex))
    }, [photoIndex])

    useEffect(() => {
        setIndexAbrir(Number(photoIndex))
    }, [photoIndex])

    return (
        <div className="modalSliderMobile">
            {images.length > 0 &&
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsShowing(false)}
                    onMovePrevRequest={() => setphotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % images.length)}
                    enableZoom={true}
                    imageCaption={`${photoIndex + 1} / ${images.length}`}
                />
            }
        </div>
    )
}

// import React, { useState, useRef, Fragment } from 'react';
// import Slider from 'react-slick';

// const UseModal = () => {
//     const [isShowing, setIsShowing] = useState(false);
//     const [imagen, setImagen] = useState("")
//     let [index, setIndex] = useState(0)
//     const [zoom, setZoom] = useState(false)

//     const [nav1, setNav1] = useState();
//     const [nav2, setNav2] = useState();
//     const slider1 = useRef();
//     const slider2 = useRef();

//     function click(e) {
//         setZoom(!zoom)
//     }

//     function toggle(e) {
//         setIsShowing(!isShowing);
//         setImagen(e.target.src)
//         setIndex(e.target.id)
//     }

//     function sliderP({ dataImage, slider1, setNav1, setNav2, nav2, toggle, setPosition }) {
//         return (<Slider
//             slidesToShow={1}
//             swipeToSlide={true}
//             focusOnSelect={true}
//             arrows={false}
//             asNavFor={nav2} ref={slider2 => setNav2(slider2)}
//             beforeChange={(current, next) => setPosition({ value: next })}
//             afterChange={current => setPosition({ value: current })}
//         >
//             {
//                 dataImage.map(({ url, order }, i) =>
//                     <img loading="lazy" src={url} alt={order} key={order} width="500" height="500" onClick={toggle} id={order} data-slide-index={i} />
//                 )
//             }
//         </Slider>)
//     }

//     function sliderS({ slider2, setNav2, nav1, dataImage, slider1, nav2 }) {

//         return <Slider
//             asNavFor={nav2}
//             slidesToShow={1}
//             swipeToSlide={true}
//             focusOnSelect={true}
//             arrows={false}
//             ref={slider1 => setNav1(slider1)}
//         >

//             {dataImage.map(({ order, url }) => {
//                 return (
//                     <Fragment key={order}>
//                         <img loading="lazy" key={order} src={url} alt={"imagen-" + order} className={zoom === true ? 'zoom transition ' : 'zoo'} onClick={click} />
//                         {/* <div className="cajaCantidad">
//                             <p className="cantidad">{order}/{dataImage.length}</p>
//                         </div> */}
//                     </Fragment>
//                 )
//             })}
//         </Slider>
//     }

//     return {
//         isShowing,
//         toggle,
//         imagen,
//         index,
//         click,
//         zoom,
//         nav1,
//         nav2,
//         setNav1,
//         setNav2,
//         slider1,
//         slider2,
//         sliderP,
//         sliderS
//     }
// };

// export default UseModal;