import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { ErrorBoundary } from 'components/Error/ErrorBoundary';


import BarraCP from 'components/BarCP/BarHeaderCP';
import MenuPrincipal from 'components/Menu';
import CartHeaderCount from 'components/Cart/CartHeaderCount';
import SearchModule from 'components/SearchHeader';
import { HeaderWishlist } from 'components/Header/HeaderWishlist';
import { HeaderMisPedidos } from 'components/Header/HeaderMisPedidos';
import { HeaderMiCuenta } from 'components/Header/HeaderMiCuenta';
import { CategoryMenuHeader } from 'components/Header/CategoryMenuHeader';
import { HeaderTiendasMenu } from 'components/Header/MenuTiendas';
import { HeaderPromo } from 'components/Header/PromoHeader';

import { HeaderBarMovile } from 'pages/Header/HeaderNavegacion/HeaderBarMovile'

export function HeaderDesktop(props) {
    const {
        isLoged,
        totalItems,
        profile,
        changeDirectionBar,
        searchWork
    } = props;

    const [showModal, SetShowModal] = useState(false);
    const hostName = window.location.origin;
    const history = useHistory();

    //- Funciones para activar o desactivar el modal del menu lateral
    const showMenu = () => {
        history.push("/menu-mas");
        // SetShowModal(true); 
    }
    const handleClose = () => { SetShowModal(false); }

    // const desktopView = () => document.querySelector('#root').classList.add('desktopView');

    // useEffect(() => {
    //     desktopView();
    // }, [])


    return (
        <>
            <header>
                <div className="headerSup">
                    <div className="container">
                        <a href="/" className="logoClaroshop" aria-label="logoSears">.</a>

                        <ErrorBoundary>
                            <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
                        </ErrorBoundary>

                        <ErrorBoundary>
                            <SearchModule {...props} searchWork={searchWork} />
                        </ErrorBoundary>

                        <div className="menuPrincipal">
                            <img src='https://notify.nuviad.com/conversion/pixel' referrerPolicy="no-referrer-when-downgrade" type='image/gif'/>
                            <div className="ocultarMenu">
                                <ErrorBoundary>
                                    <HeaderMiCuenta
                                        ruta={isLoged ? '/mi-cuenta' : `/login?redirect=${hostName}/mi-cuenta`} profile={profile}
                                    />
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    <HeaderMisPedidos
                                        ruta={isLoged ? '/mi-cuenta/mis-pedidos/dias=30/pagina=1' : `/login?redirect=${hostName}/mi-cuenta/mis-pedidos/dias=30/pagina=1`}
                                    />
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    <HeaderWishlist
                                        ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`}
                                    />
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    <CartHeaderCount
                                        ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`} cantidad={isLoged ? totalItems : 0}
                                    />
                                </ErrorBoundary>
                            </div>
                        
                            <HeaderBarMovile isLoged={isLoged} profile={profile} />
                        </div>

                    </div>
                </div>
                <div className="headerInf">
                    <div className="container paddingHeader">
                        <ErrorBoundary>
                            <CategoryMenuHeader />
                        </ErrorBoundary>

                        <HeaderPromo />
                    </div>
                </div>
            </header>
            {showModal && <MenuPrincipal onClose={handleClose} />}
        </>
    )
}