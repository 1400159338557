import React, { useState, useEffect } from 'react';
import { getPedido, postCreateTiket, getCopysRecoleccion } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import Loading from 'components/Loading';
import { removeDuplicates } from 'functions/removeDuplicates';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import FooterPortal from 'pages/Footer/FooterDesktop';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import { NO_IMAGE } from 'constants/general';
import { useToken } from 'hooks/useToken';
import { ModalError } from '../vista4-modal';
import ModalPortal from 'components/Modal';
import { useStorage64 } from 'hooks/useStorageBase64';
import { repareTextHtml } from 'functions/repareDataService';

export function SolicitarRecoleccionDesktop() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [nombreProducto, setNombreProducto] = useState(false);

    const [loading, setLoading] = useState(false);
    const [informacion, setInformacion] = useState(false);
    //variables para crear ticket
    const [showModalError, SetShowModalError] = useState(false);
    const [remover, setRemover] = useState(false);
    const [crearTicket, setCrearTicket] = useState(false);
    const [infoTicketEntregado] = useStorage64("infoTicketEntregado");
    const [storageActionId] = useStorage64("storageActionId");
    const [storageMotivoEntregado] = useStorage64("storageMotivoEntregado");
    const [storageImgProducto] = useStorage64("storageImgProducto");
    const [storageDatosBancarios] = useStorage64("storageDatosBancarios");
    const [storageDevolverOpcion] = useStorage64("storageDevolverOpcion");
    const [correoUsuario] = useStorage64('storageCorreoUsuario');


    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((informacion && informacion.informacion) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, informacion])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '')
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //obtiene informacion para copys
    useEffect(() => {
        getCopysRecoleccion()
            .then(setInformacion);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista93- solicitar recoleccion -desktop" })
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    // obtengo informacion de pedido
    useEffect(() => {
        let imagenProducto = [];
        let shopProducto = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                    )
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                    )

                    // guarda informacion sobre el vendedor del producto
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && shopProducto.push({ "idTienda": elem.shop.idTienda, "nameTienda": elem.shop.name })
                    )

                    let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                    setima(limpioImagen);
                    setNombreProducto(productoNombre);

                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
               
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);

                    getCopysRecoleccion()
                        .then(setInformacion);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // regresar a la pagina anterior
    const onClose = () => {
        history.goBack(-1);
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    // realiza la peticion de creacion de ticket para devolver y solicitar la recoleccion
    const solicitarRecoleccion = () => {
        // ------informacion para crear ticket ------------
        let client_id = infoTicketEntregado.client_id;
        let order_id = infoTicketEntregado.order_id;
        let order_number = infoTicketEntregado.order_number;
        let ticket_type_id = infoTicketEntregado.ticket_type_id;
        let action_id = storageActionId;
        let reason_id = infoTicketEntregado.reason_id;
        let description = storageMotivoEntregado;
        let refound_details;
        if (storageDatosBancarios) {
            refound_details = storageDatosBancarios;
        } else {
            refound_details = [];
        }

        let correo = correoUsuario;
        let products_to_cancel = [{ "id": infoTicketEntregado.products_to_cancel[0].id, "return_type": storageDevolverOpcion, "subsidiary_id": null }];
        let additional_details = storageImgProducto;

        setRemover(true);

        // se crea ticket 
        postCreateTiket({
            reason_id,
            order_id,
            order_number,
            client_id,
            description,
            products_to_cancel,
            ticket_type_id,
            refound_details,
            additional_details,
            action_id,
            correo
        })
            .then(setCrearTicket);
    }

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {
                    if (idProductoEstate === 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                    }
                    else if (idProductoEstate !== 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cancelacion-exitosa");
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">

                        <div className='menuMiCuentaAD'>
                            <MenuMiCuenta />
                        </div>

                        <div className='contenidoGeneralAD'>
                            <div className="boxBtnRegresarDktop">
                                <div className="icoBack" onClick={onClose}></div>
                                <button className="btnBackAD" onClick={onClose}>Solicitar recolección</button>
                            </div>

                            <div className="boxGeneralAD">
                                <div className="boxLeftAD">
                                    <div className="contSectionRed">
                                        <div className={ima.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                            {ima.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={70} />)}
                                        </div>

                                        <div className='textDektop'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                                    </div>
                                </div>

                                <div className="boxRightAD">

                                    <div className='boxIndicacionesAD marginT30'>
                                        <div className='indicacionesBoxinterno'>
                                            <h3 className='bold'>{Array.isArray(informacion?.informacion) && informacion?.informacion[0].message_parent}</h3>

                                            <ul className="normasL1 marginT30">
                                                {Array.isArray(informacion?.informacion) && informacion?.informacion[0].child.map((inf, id) =>
                                                    <li key={id}>{inf.message_parent}
                                                        {Array.isArray(inf?.child) && inf?.child.length > 0 && inf?.child !== null
                                                            ? <ol className="normasL2">
                                                                {inf?.child.map((inf2, id2) =>
                                                                    <li key={id2}>{inf2?.message_parent}</li>
                                                                )}
                                                            </ol>
                                                            : null
                                                        }
                                                    </li>
                                                )}
                                            </ul>

                                        </div>

                                        <div className='boxBtn'>
                                            <button className='btnContinuar' onClick={solicitarRecoleccion}>Continuar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {showModalError &&
                                <ModalPortal type="modalSB" onClose={handleCloseError} >
                                    <ModalError okRegresar={regresarPedidos} />
                                </ModalPortal>
                            }
                        </div>
                    </div>
                </section>
            </main>

            <FooterPortal />
        </>
    )
}