import React, { useState, useEffect } from 'react'
import './style.sass'
import { useHistory } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import SlidersEmarsys from 'components/Emarsys/SlidersEmarsys'
import { ENVIO_GRATIS, BASE_PATH_CAJA, NO_IMAGE } from 'constants/general'
import { decodeStorage, encodeStorage, encodeStorageLatin } from 'functions/storageBase64'
import { dataLayerCartAdd, dataLayerCartAddOld, dataLayerCheckout } from 'functions/dataLayerFunction'
import { useImageArray } from 'hooks/useImageArray'
import { repareTextHtml, repareTextDlayer } from 'functions/repareDataService';
import getInitialPayment from 'services/inicioCaja'
import Toast from 'components/Toast'
import Loading from 'components/Loading'
import { VARIANTS_PER_PORTAL } from 'constants/general'

export default function ModalAddToCart({ ...props }) {
    const {
        idProduct,
        cantProduct,
        title,
        price,
        sale_price,
        dataImage,
        totalCart,
        is_digital,
        onClose,
        brand,
        variant,
        category
    } = props

    const history = useHistory()
    const [sendCart, setSendCart] = useState(false)
    const [evalPriceCart, setEvalPriceCart] = useState(false)
    const byCart = decodeStorage('idCarrito')
    const isLoged = decodeStorage('session_token')
    const total = decodeStorage("total_items")

    const [loading, setLoading] = useState(false)
    const [toast, setToast] = useState(false)
    const [messageToast, setMessageToast] = useState({ type: '', time: '', textMessage: '' })
    const domain = window.location.host

    const handleToast = () => {
        setToast(false)
    }


    //- dataleyer agrega item
    const findAddItem = () => {
        if (idProduct) {

            let catPadre, catHija, catNieta, variante

            let nombre = title
            let id = idProduct
            let precio = sale_price
            let marca = brand
            let cantidad = cantProduct

            if (category) {
                catPadre = category[2]?.name
                catHija = category[1]?.name
                catNieta = category[0]?.name
            } else {
                catPadre = ""
                catHija = ""
                catNieta = ""
            }
            if (variant) {
                variante = variant
            } else {
                variante = ""
            }
            console.log('dataLayerCartAdd:1 ')
            dataLayerCartAdd({
                item: [
                    {
                        item_name: String(nombre),
                        item_id: String(id),
                        price: String(precio),
                        item_brand: String(marca),
                        item_category: String(catPadre),
                        item_category2: String(catHija),
                        item_category3: String(catNieta),
                        item_variant: String(variante),
                        quantity: String(cantidad)
                    }
                ]
            })
            dataLayerCartAddOld({
                item: [
                    {
                        name: String(nombre),
                        id: String(id),
                        price: String(precio),
                        brand: String(marca),
                        category: String(catPadre + '/' + catHija + '/' + catNieta),
                        variant: String(variante),
                        quantity: String(cantidad)
                    }
                ]
            })
        }
    }

    useEffect(() => {
        setLoading(false)
        //findAddItem()
    }, [])

    useEffect(() => {
        if (sendCart) {
            history.replace('/carrito/detalle')
            setSendCart(false)
        }
    }, [sendCart])

    //-datalayer chechout
    const [cartProduct, setCartProduct] = useState()
    useEffect(() => {
        if (totalCart) {
            if (totalCart.totalArrayProd) {
                setCartProduct(totalCart.totalArrayProd)
            }
        }
    }, [totalCart])

    const [updateCategory, setUpdateCategory] = useState()
    useEffect(() => {
        if (category) {
            setUpdateCategory(true)
        }
    }, [category])

    const sendToCart = () => {
        setSendCart(true)
    }


    //se ejecuta cuadno tiene datos el producto para llenar el datalayer dataLayerCart
    //window.productsCarrito = cart.products
    const [dataLayerData, setDataLayerData] = useState()
    const [totalCartDL, setTotalCartDL] = useState()
    const indexCount = []
    const listArrayCart = []
    useEffect(() => {
        //console.log('productDAta-> ', cartProduct)
        if (cartProduct) {
            //console.log('val',cartProduct)
            encodeStorage('cartFormEm', cartProduct)
            cartProduct.map(({ id, title, cart_quantity, brand, stock, status, selected_size_color, sale_price, categories }) => {
                if (stock >= 1 && status === true) {
                    indexCount.push(1)
                    if (categories) {
                        if (selected_size_color.color) {
                            listArrayCart.push({
                                item_name: String(title),
                                item_id: String(id),
                                price: String(sale_price),
                                item_brand: String(brand),
                                item_category: String(categories[2]?.name),
                                item_category2: String(categories[1]?.name),
                                item_category3: String(categories[0]?.name),
                                item_variant: String(selected_size_color.color),
                                index: indexCount.length,
                                quantity: String(cart_quantity)
                            })
                        } else {
                            listArrayCart.push({
                                item_name: String(title),
                                item_id: String(id),
                                price: String(sale_price),
                                item_brand: String(brand),
                                item_category: String(categories[2]?.name),
                                item_category2: String(categories[1]?.name),
                                item_category3: String(categories[0]?.name),
                                index: indexCount.length,
                                quantity: String(cart_quantity)
                            })
                        }
                    } else {
                        if (selected_size_color.color) {
                            listArrayCart.push({
                                item_name: String(title),
                                item_id: String(id),
                                price: String(sale_price),
                                item_brand: String(brand),
                                item_variant: String(selected_size_color.color),
                                item_category: "",
                                item_category2: "",
                                item_category3: "",
                                index: indexCount.length,
                                quantity: String(cart_quantity)
                            })
                        } else {
                            listArrayCart.push({
                                item_name: String(title),
                                item_id: String(id),
                                price: String(sale_price),
                                item_brand: String(brand),
                                item_category: "",
                                item_category2: "",
                                item_category3: "",
                                index: indexCount.length,
                                quantity: String(cart_quantity)
                            })
                        }
                    }
                }
            })
            setDataLayerData(listArrayCart)
            setTotalCartDL(totalCart.totalCartPrice)
        }
    }, [cartProduct])

    //- evalua cantidad de carrito si es 0 si nop inicia caja para ver si es un 409 de contador
    const [initCaja, setInitCaja] = useState()
    useEffect(() => {
        if (totalCartDL === 0) {
            setToast(true)
            setMessageToast({
                type: 'warning',
                time: 7,
                textMessage: 'Producto sin existencia en el carrito'
            })
            setTimeout(function () {
                history.push('/carrito/detalle')
            }, 7000)
        }
    }, [totalCartDL])

    // Evalua que todo este Ok y si el precio es mayor a 0 inicia caja
    useEffect(() => {
        if (evalPriceCart) {
            if (dataLayerData) {
                if (totalCartDL >= 1) {
                    setLoading(true)
                    getInitialPayment()
                        .then(setInitCaja)
                } else {
                    history.push('/carrito/detalle')
                }
            }
        }
    }, [evalPriceCart, byCart, dataLayerData])
    const submitCart = () => setEvalPriceCart(true)

    //- evalua el status para ver si manda a caja o si Muestra mensjae de producto sin existencia
    useEffect(() => {
        if (initCaja) {
            if (initCaja.newStatus === 200) {
                dataLayerCheckout({ items: dataLayerData })
                dataLayerCheckout({ valueCart: totalCartDL, items: dataLayerData })
                document.cookie = `token_cart=${byCart};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                window.location.href = `${BASE_PATH_CAJA}`
            }
            else if (initCaja.newStatus === 409) {
                setLoading(false)
                setToast(true)
                setMessageToast({
                    type: 'warning',
                    time: 7,
                    textMessage: initCaja.response
                })
                setTimeout(function () {
                    history.push('/carrito/detalle')
                }, 7000)
            }
        }
    }, [initCaja])

    const imageAddCart = useImageArray(dataImage)

    return (
        <>
            {loading
                ? <Loading />
                : null
            }
            <div className="contentModalAddCartMobile">
                <div className="dataModalCart">
                    <div className="contDataPreCart">

                        <div className="contentDataAdd">
                            <div className="contImageCart">
                                <img src={imageAddCart.dataImage[0].url} alt={repareTextHtml(title)} loading="lazy" width="100" height="100" />
                            </div>
                            <div className="contTitlesCart">
                                <p className="titleDataAdd">Agregado a tu bolsa</p>
                                <p className="titleData">{repareTextHtml(title)}</p>
                                {is_digital && <div className="stickerDigital">Producto Digital</div>}
                            </div>
                        </div>

                        <div className="priceProductCart">
                            {totalCart &&
                                <p>
                                    {totalCart.totalCartItems} productos en tu bolsa:
                                    <CurrencyFormat
                                        value={totalCart.totalCartPrice}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        renderText={value => <span className="strong">{value} MXN</span>}
                                    />
                                </p>
                            }
                            {totalCart &&
                                totalCart.totalCartPrice >= Number(ENVIO_GRATIS)
                                ? <p className="isFree">ENVÍO GRATIS </p>
                                : <p>Te faltan
                                    <CurrencyFormat
                                        value={Number(ENVIO_GRATIS) - totalCart.totalCartPrice}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        renderText={value => <span className="strong"> {value} </span>}
                                    /> para que tu envío sea <span className="isFree">gratis</span></p>
                            }
                        </div>

                        <div className="contButtons">
                            <div className="btn rojo" onClick={submitCart}>Comprar bolsa</div>
                            <div className="btn blanco" onClick={sendToCart}>Ir a la bolsa</div>
                        </div>
                    </div>
                </div>

                <section id="alsoBoutghtSlider">
                    <SlidersEmarsys
                        logica={'ALSO_BOUGHT'}
                        idContent={'alsoBoutghtSlider'}
                        title={"Productos que te pueden interesar"}
                        idProd={idProduct}
                        cant={12}
                        typeView={'blockView'}
                    />
                </section>

                <section id="cartSlider">
                    <SlidersEmarsys
                        logica={'CART'}
                        idContent={'cartSlider'}
                        title={"Productos que te pueden interesar"}
                        cart={[{ item: idProduct, price: price, quantity: cantProduct }]}
                        cant={12}
                        typeView={'blockView'}
                    />
                </section>

                {toast &&
                    <Toast
                        type={messageToast.type}
                        time={messageToast.time}
                        onClose={handleToast}
                    >
                        {messageToast.textMessage}
                    </Toast>
                }
            </div>
        </>
    )
}

function SlidersBlocks(props) {
    const {
        id,
        price,
        quantity,
        category
    } = props

    //console.log('category',category.length)

    let catString, catStringLow
    if (category.length > 1) {
        catString = category.filter(valor => valor.path_length === 2)[0]?.name
        catStringLow = catString.toLowerCase()
    } else {
        catString = null
    }

    const catPadre = catString
    const idString = String(id)

    const categoryString = catStringLow


    return (
        <>
            {id &&
                <section id="alsoBoutghtSlider">
                    <SlidersEmarsys
                        logica={'ALSO_BOUGHT'}
                        idContent={'alsoBoutghtSlider'}
                        title={"También Compraron"}
                        idProd={id}
                        cant={30}
                        typeView={'sliderView'}
                    />
                </section>
            }

            {id && price && quantity &&
                <section id="cartSlider">
                    <SlidersEmarsys
                        logica={'CART'}
                        idContent={'cartSlider'}
                        title={"Productos que te pueden interesar"}
                        cart={[{ item: id, price: price, quantity: quantity }]}
                        cant={30}
                        typeView={'sliderView'}
                    />
                </section>
            }

            {categoryString &&
                <section id="categorySlider">
                    <SlidersEmarsys
                        logica={'POPULAR'}
                        idContent={'categorySlider'}
                        breadcrumb={categoryString}
                        title={`Otros de ${catPadre}`}
                        typeView={'sliderView'}
                        cant={50}
                    />
                </section>
            }
            {id &&
                <section id="similarProduct">
                    <SlidersEmarsys
                        logica={'RELATED'}
                        idContent={'similarProduct'}
                        title={"Productos similares"}
                        idProd={id}
                        typeView={'sliderView'}
                        cant={20}
                    />
                </section>
            }
        </>
    )
}

export function ModalAddToCartDesktop({ ...props }) {
    const {
        idProduct,
        cantProduct,
        title,
        price,
        sale_price,
        totalCart,
        dataImage,
        onClose,
        is_digital,
        brand,
        variant,
        category,
        seller
    } = props

    const history = useHistory()
    const [sendCart, setSendCart] = useState(false)
    const [evalPriceCart, setEvalPriceCart] = useState(false)
    const byCart = decodeStorage('idCarrito')
    const isLoged = decodeStorage('session_token')

    const [loading, setLoading] = useState(false)
    const [toast, setToast] = useState(false)
    const [messageToast, setMessageToast] = useState({ type: '', time: '', textMessage: '' })

    const handleToast = () => {
        setToast(false)
    }


    //- dataleyer agrega item
    const findAddItem = () => {
        if (idProduct) {

            let catPadre, catHija, catNieta, variante

            let nombre = title
            let id = idProduct
            let precio = sale_price
            let marca = brand
            let cantidad = cantProduct
            if (category) {
                //console.log('categories-ATC: ', category)
                if (category.length === 1) {
                    catPadre = ""
                    catHija = ""
                    catNieta = category[0]?.name
                }
                else if (category.length === 2) {
                    catPadre = ""
                    catHija = category[1]?.name
                    catNieta = category[0]?.name
                }
                else if (category.length >= 3) {
                    catPadre = category[2]?.name
                    catHija = category[1]?.name
                    catNieta = category[0]?.name
                } else {
                    catPadre = ""
                    catHija = ""
                    catNieta = ""
                }
            }
            if (variant) {
                variante = variant
            } else {
                variante = ""
            }
            console.log('dataLayerCartAdd: 2')
            dataLayerCartAdd({
                item: [
                    {
                        item_name: repareTextDlayer(nombre),
                        item_id: id,
                        price: precio,
                        affiliation: repareTextDlayer(seller),
                        item_brand: repareTextDlayer(marca),
                        item_category: repareTextDlayer(catPadre),
                        item_category2: repareTextDlayer(catHija),
                        item_category3: repareTextDlayer(catNieta),
                        item_variant: "Disponible",
                        quantity: cantidad
                    }
                ]
            })
            dataLayerCartAddOld({
                item: [
                    {
                        name: String(nombre),
                        id: String(id),
                        price: String(precio),
                        brand: String(marca),
                        category: String(catPadre + '/' + catHija + '/' + catNieta),
                        variant: String(variante),
                        quantity: String(cantidad)
                    }
                ]
            })
        }
    }

    useEffect(() => {
        setLoading(false)
        findAddItem()
    }, [])

    useEffect(() => {
        if (sendCart) {
            history.replace('/carrito/detalle')
            setSendCart(false)
        }
    }, [sendCart])


    //-datalayer chechout
    const [cartProduct, setCartProduct] = useState()
    useEffect(() => {
        if (totalCart) {
            if (totalCart.totalArrayProd) {
                setCartProduct(totalCart.totalArrayProd)
            }
        }
    }, [totalCart])

    const [updateCategory, setUpdateCategory] = useState()
    useEffect(() => {
        if (category) {
            setUpdateCategory(true)
        }
    }, [category])

    const sendToCart = () => {
        setSendCart(true)
    }


    //se ejecuta cuadno tiene datos el producto para llenar el datalayer dataLayerCart
    //window.productsCarrito = cart.products
    const [dataLayerData, setDataLayerData] = useState()
    const [totalCartDL, setTotalCartDL] = useState()
    const indexCount = []
    const listArrayCart = []
    useEffect(() => {
        //console.log('productDAta-> ', cartProduct)
        if (cartProduct) {
            //console.log('val',cartProduct)
            try {
                encodeStorage('cartFormEm', cartProduct)
            }
            catch {
                encodeStorageLatin('cartFormEm', cartProduct)
            }
            cartProduct.map(({ id, title, cart_quantity, brand, stock, status, selected_size_color, sale_price, categories, store }) => {
                //if (stock >= 1 && status === true) {
                    indexCount.push(1)
                    if (categories) {
                        if (selected_size_color.color) {
                            listArrayCart.push({
                                item_name: repareTextDlayer(title),
                                item_id: id,
                                price: sale_price,
                                affiliation: repareTextDlayer(store?.name),
                                item_brand: repareTextDlayer(brand),
                                item_category: repareTextDlayer(categories[2]?.name),
                                item_category2: repareTextDlayer(categories[1]?.name),
                                item_category3: repareTextDlayer(categories[0]?.name),
                                //item_variant: repareTextDlayer(selected_size_color.color),
                                item_variant: stock >= 1 && status === true ? "disponible": "agotado",
                                index: indexCount.length,
                                quantity: cart_quantity
                            })
                        } else {
                            listArrayCart.push({
                                item_name: repareTextDlayer(title),
                                item_id: id,
                                price: sale_price,
                                affiliation: repareTextDlayer(store?.name),
                                item_brand: repareTextDlayer(brand),
                                item_category: repareTextDlayer(categories[2]?.name),
                                item_category2: repareTextDlayer(categories[1]?.name),
                                item_category3: repareTextDlayer(categories[0]?.name),
                                item_variant: stock >= 1 && status === true ? "disponible": "agotado",
                                index: indexCount.length,
                                quantity: cart_quantity
                            })
                        }
                    } else {
                        if (selected_size_color.color) {
                            listArrayCart.push({
                                item_name: repareTextDlayer(title),
                                item_id: id,
                                price: sale_price,
                                affiliation: repareTextDlayer(store?.name),
                                item_brand: repareTextDlayer(brand),
                                //item_variant: repareTextDlayer(selected_size_color.color),
                                item_variant: stock >= 1 && status === true ? "disponible": "agotado",
                                item_category: "",
                                item_category2: "",
                                item_category3: "",
                                index: indexCount.length,
                                quantity: cart_quantity
                            })
                        } else {
                            listArrayCart.push({
                                item_name: repareTextDlayer(title),
                                item_id: id,
                                price: sale_price,
                                affiliation: repareTextDlayer(store?.name),
                                item_brand: repareTextDlayer(brand),
                                item_category: "",
                                item_category2: "",
                                item_category3: "",
                                item_variant: stock >= 1 && status === true ? "disponible": "agotado",
                                index: indexCount.length,
                                quantity: cart_quantity
                            })
                        }
                    }
               // }
            })
            setDataLayerData(listArrayCart)
            setTotalCartDL(totalCart.totalCartPrice)
        }
    }, [cartProduct])

    //- evalua cantidad de carrito si es 0 si nop inicia caja para ver si es un 409 de contador
    const [initCaja, setInitCaja] = useState()
    useEffect(() => {
        if (totalCartDL === 0) {
            setToast(true)
            setMessageToast({
                type: 'warning',
                time: 7,
                textMessage: 'Producto sin existencia en el carrito'
            })
            setTimeout(function () {
                history.push('/carrito/detalle')
            }, 7000)
        }
    }, [totalCartDL])

    // Evalua que todo este Ok y si el precio es mayor a 0 inicia caja
    useEffect(() => {
        if (evalPriceCart) {
            if (dataLayerData) {
                if (totalCartDL >= 1) {
                    setLoading(true)
                    getInitialPayment()
                        .then(setInitCaja)
                } else {
                    history.push('/carrito/detalle')
                }
            }
        }
    }, [evalPriceCart, byCart, dataLayerData])
    const submitCart = () => setEvalPriceCart(true)

    //- evalua el status para ver si manda a caja o si Muestra mensjae de producto sin existencia
    useEffect(() => {
        if (initCaja) {
            if (initCaja.newStatus === 200) {
                //dataLayerCheckout({ items: dataLayerData })
                dataLayerCheckout({ valueCart: totalCartDL, items: dataLayerData })
                document.cookie = `token_cart=${byCart};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=claroshop.com;`
                document.cookie = `token_api_manager=${isLoged};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=claroshop.com;`
                window.location.href = `${BASE_PATH_CAJA}`
            }
            else if (initCaja.newStatus === 409) {
                setLoading(false)
                setToast(true)
                setMessageToast({
                    type: 'warning',
                    time: 7,
                    textMessage: initCaja.response
                })
                setTimeout(function () {
                    history.push('/carrito/detalle')
                }, 7000)
            }
        }
    }, [initCaja])

    const imageAddCartDesktop = useImageArray(dataImage)

    return (
        <>
            {loading
                ? <Loading />
                : null
            }
            <div className="contentModalAddCartDesktop">
                <div className="dataModalCart">
                    <div className="contDataPreCart">
                        <div className="contentDataAdd">
                            <div className="contImageCart">
                                //{console.log('dataImagenNew', imageAddCartDesktop.dataImage)}
                                <img src={imageAddCartDesktop.dataImage[0].url} alt={repareTextHtml(title)} loading="lazy" width="100" height="100" />
                            </div>
                            <div className="contTitlesCart">
                                <p className="titleDataAdd">Agregado a tu bolsa</p>
                                <p className="titleData">{repareTextHtml(title)}</p>
                                {is_digital &&
                                    <div className="stickerDigital">Producto Digital</div>
                                }
                            </div>
                        </div>
                        <div className="priceProductCart">
                            {totalCart &&
                                <p>
                                    {totalCart.totalCartItems} productos en tu bolsa:
                                    <CurrencyFormat
                                        value={totalCart.totalCartPrice}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        renderText={value => <span className="strong">{value} MXN</span>}
                                    />
                                </p>
                            }
                            {totalCart &&
                                totalCart.totalCartPrice >= Number(ENVIO_GRATIS)
                                ? <p className="isFree">ENVÍO GRATIS </p>
                                : <p>Te faltan
                                    <CurrencyFormat
                                        value={Number(ENVIO_GRATIS) - totalCart.totalCartPrice}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        renderText={value => <span className="strong"> {value} </span>}
                                    /> para que tu envío sea <span className="isFree">gratis</span></p>
                            }
                        </div>
                        <div className="contButtons">
                            <div className="btn rojo" onClick={submitCart}>Comprar bolsa</div>
                            <div className="btn blanco" onClick={sendToCart}>Ir a la bolsa</div>
                        </div>
                    </div>
                </div>

                {VARIANTS_PER_PORTAL?.emarsys?.modal_addCart
                    ?
                        <div className="contSlidersPreCart">
                            {updateCategory &&
                                    
                                <SlidersBlocks
                                    id={idProduct}
                                    price={price}
                                    quantity={cantProduct}
                                    category={category}
                                />
                            }
                        </div>
                    : null
                }
            </div>

            {toast &&
                <Toast
                    type={messageToast.type}
                    time={messageToast.time}
                    onClose={handleToast}
                >
                    {messageToast.textMessage}
                </Toast>
            }
        </>
    )
}