import React, { useState, useEffect, Suspense } from 'react';
import 'components/ProductDetail/ShopProduct/ContentCardProduct/style.sass';
import loadable from '@loadable/component'
import { useParams, useHistory } from 'react-router-dom'
import { useStorage64 } from 'hooks/useStorageBase64';
import { decodeStorage } from 'functions/storageBase64';
import { VARIANTS_PER_PORTAL } from 'constants/general'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'


import ErrorType from 'pages/Error/ErrorType'
//import ModuleShopProductCart from 'components/ProductDetail/ShopProduct/ContentCardProduct';
//import ModuleSliderImageProdut from 'components/ProductDetail/SlideImageProduct/SlideImageProductOld';
import ButtonSellinClaroshop from 'components/ProductDetail/ShopProduct/ButtonSellinClaroshop';
import ModuleShopData from 'components/ProductDetail/ShopProduct/ModuleShopData';
import ModuleShopBuy from 'components/ProductDetail/ShopProduct/ModuleShopBuy';
import ModuleFeatures from 'components/ProductDetail/Features';
import ModuleDeliveryDate from 'components/ProductDetail/DeliveryDate';
import ModuleTelmexPromotion from 'components/ProductDetail/TelmexPromotion';
import ModuleCreditCardPromotion from 'components/ProductDetail/CreditCardPromotion';
import ModuleSpecificationProduct from 'components/ProductDetail/Specification';
import { ModuleDescriptionProductDesktop } from 'components/ProductDetail/Description';
import { ModuleReviewsDesktop } from 'components/ProductDetail/Reviews/ProductReviews';
import ModulePaymentMethods from 'components/ProductDetail/PaymentMethods';
import ModuleStoreCardProduct from 'components/ProductDetail/Store';
import SlidersEmarsys from 'components/Emarsys/SlidersEmarsys/index_product';
import ErrorProductServices from 'components/Error/ErrorProduct';
import ErrorConnectionServices from 'components/Error/ErrorConection';
import ModuleSliderImageProdut from 'components/ProductDetail/SlideImageProduct';
import ModalMiTelmexDetalle from 'components/MiTelmex/modaMiTelmex/modalMiTelmex'
import { ShareComponentDetailTexto } from 'components/Share'
import { BtnWishlistDetail } from 'components/Wishlist'
import ModuleWarrantyProduct from 'components/ProductDetail/Warranty';
import ModuleCreditCardSearsPromotion from 'components/ProductDetail/CreditCardSearsPromotion';
import ModuleTabsDesktop from 'components/ProductDetail/ModuleTabsDesktop';
import ButtonVenderSears from 'components/ProductDetail/ShopProduct/ButtonVenderSears';
import ModuleServicioInstalacion from 'components/ProductDetail/ModuleServicioInstalacion';
import { ModuleClickRecoge } from 'components/ProductDetail/ShopProduct/ClicyRecoge'
import { SirenaWhatsApp } from 'components/ProductDetail/SirenaWhatsApp'
import ButtonCyR from 'components/ProductDetail/ShopProduct/ButtonCyR';



function SlidersBlocks(props) {
    const { id, category } = props;

    let catString, catStringLow, catStringTitle;
    if (category?.length > 1) {
        catStringTitle = category?.filter(valor => valor.path_length === 0)[0]?.name;
        if (category?.length >= 3) {
            //catString = category.filter(valor => valor.path_length === 2)[0].name;
            catString = category?.filter(valor => valor.path_length === 2)[0]?.name + ' > ' + category?.filter(valor => valor.path_length === 1)[0]?.name + ' > ' + category?.filter(valor => valor.path_length === 0)[0]?.name
            catStringLow = catString?.toLowerCase();
        }
        else if (category?.length === 2) {
            //catString = category.filter(valor => valor.path_length === 2)[0].name;
            catString = category?.filter(valor => valor.path_length === 1)[0]?.name + ' > ' + category?.filter(valor => valor.path_length === 0)[0]?.name
            catStringLow = catString?.toLowerCase();
        }
        else if (category?.length === 1) {
            //catString = category.filter(valor => valor.path_length === 2)[0].name;
            catString = category?.filter(valor => valor.path_length === 0)[0]?.name
            catStringLow = catString?.toLowerCase();
        }
    } else {
        catString = null;
        catStringTitle = null;
    }

    const catPadre = catString;
    const idString = String(id);
    const categoryString = catStringLow;

    return (
        <>
            <ErrorBoundary>
                {idString &&
                    VARIANTS_PER_PORTAL?.emarsys?.product_also
                    ?
                    <section id="tambienCompraronSlider">
                        <SlidersEmarsys
                            logica={'ALSO_BOUGHT'}
                            idContent={'tambienCompraronSlider'}
                            idProd={idString}
                            title={"También compraron"}
                            typeView={'sliderView'}
                            cant={50}
                        />
                    </section>
                    : null
                }
            </ErrorBoundary>

            <ErrorBoundary>
                {categoryString &&
                    VARIANTS_PER_PORTAL?.emarsys?.product_cat
                    ?
                    <section id="categorySlider">
                        <SlidersEmarsys
                            logica={'CATEGORY'}
                            idContent={'categorySlider'}
                            breadcrumb={categoryString}
                            title={`Otros de ${catStringTitle}`}
                            typeView={'sliderView'}
                            cant={50}
                        />
                    </section>
                    : null
                }
            </ErrorBoundary>
        </>
    )
}

export function DetalleProductoDesktop(props) {
    const {
        changeDirectionBar,
        // loading,
        productDataSup,
        opinionData,
        mostrarResumen,
        setLoading,
        promoDepart,
        formasPagoArray,
        formasPagoOrdenadoArray,
        catPadre,
        catHija_Hombre,
        catHija_Mujer,
        catHija_Nino,
        catHija_Nina,
        tallasNino, tallasNina, tallasHombre, tallasMujer,
        SKUsServicioTecnico
    } = props;

    // console.log('productDataSup ',productDataSup.producto)
    // console.log('mostrarResumen ',mostrarResumen)

    const [jsonInfo, setJsonInfo] = useState();
    const [colorText, setColorText] = useState(null);
    const [tallaText, setTallaText] = useState(null);
    const [superExpres, setSuperExpres] = useState();

    useEffect(() => {
        if (SKUsServicioTecnico) {
            setJsonInfo(SKUsServicioTecnico);
        }
    }, [SKUsServicioTecnico])

    // const history = useHistory();
    const [yaVisitoProductos, setYaVisitoProductos] = useStorage64('yaVisitoProductos', 0);
    const [yaPresionoSimilares, setYaPresionoSimilares] = useStorage64('yaPresionoSimilares', 0);
    const [flagNuevo, setFlagNuevo] = useState(false);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Product Page');
    }, [])

    const [productData, SetProductData] = useState([]);
    useEffect(() => {
        // console.log('productDataSup: ', productDataSup)
        if (productDataSup) {
            SetProductData(productDataSup);
        }
    }, [productDataSup]);

    //- remueve loading con errores
    useEffect(() => {
        if (productData) {
            if (productData.error) {
                setLoading(false);
            }
            setSuperExpres(productData?.producto?.features?.super_express)
        }
    }, [productData]);

    useEffect(() => {
        setYaVisitoProductos(yaVisitoProductos + 1);
    }, []);

    useEffect(() => {
        if (flagNuevo == true) {
            setYaPresionoSimilares(yaPresionoSimilares + 1);
        }
    }, [flagNuevo]);

    //- Evalua talla color stock para mostrar reglas de mensaje
    const [updateStockTC, setUpdateStockTC] = useState();
    const [updateFF, setUpdateFF] = useState();
    const stockSizeColorSKU = (val) => {
        setUpdateStockTC(val);
    };
    const stockSizeColorSKUFF = (val) => {
        setUpdateFF(val);
    };

    const [updateFFVariant, setUpdateFFVariant] = useState();
    const stockSizeColorVariant = (val) => {
        setUpdateFFVariant(val);
    };


    useEffect(() => {
        // console.log('updateFFVariant: ', updateFFVariant)
        // console.log('updateFF: ', updateFF)
        // console.log('updateStockTC: ', updateStockTC)
    }, [updateFFVariant, superExpres]);

    const [sizeColorSKUprincipal, setSizeColorSKUprincipal] = useState(false);
    const [scSKUprincipal, setscSKUprincipal] = useState(false);

    useEffect(() => {

        if (sizeColorSKUprincipal) {
            setscSKUprincipal(sizeColorSKUprincipal)
        }
    }, [sizeColorSKUprincipal])

    const [preseleccionar, setPreseleccionar] = useState(false);

    return (
        <>
            <main className="viewDetailProduct">

                {/* resumen */}
                {mostrarResumen === true
                   ? <>
                        <section>
                            <div className="container">
                                <div className="gridTemplateProduct">

                                    {productData?.error >= 500 &&
                                        <ErrorType
                                            codeStatus={productData?.error}
                                            errorText={'Código de error: ' + productData?.error}
                                        />
                                    }
                                    {productData?.error === 404 &&
                                        <ErrorProductServices />
                                    }
                                    {productData?.error === '0' &&
                                        <ErrorConnectionServices errorCode={productData?.response} />
                                    }
                                    {productData?.producto
                                        ?
                                            <>
                                                <div className="productSupModule">
                                                    <ErrorBoundary>
                                                        <ModuleSliderImageProdut
                                                            id={productData?.producto?.id}
                                                            dataImage={productData?.producto?.images}
                                                            dataVideo={productData?.producto?.videos}
                                                            title={productData?.producto?.title}
                                                            category={productData?.producto?.categories}
                                                            brand={productData?.producto?.brand}
                                                            sale_price={productData?.producto?.sale_price}
                                                            seller={productData?.producto?.store?.name}
                                                        />
                                                    </ErrorBoundary>

                                                    
                                                    <div className="moduleShopCardProduct">

                                                        <ErrorBoundary>
                                                            {opinionData
                                                                ?
                                                                    <ModuleShopData
                                                                        id={productData?.producto?.id}
                                                                        title={productData?.producto?.title}
                                                                        store={productData?.producto?.store}
                                                                        discount={productData?.producto?.discount}
                                                                        price={productData?.producto?.price}
                                                                        sale_price={productData?.producto?.sale_price}
                                                                        is_digital={productData?.producto?.is_digital}
                                                                        reviews={opinionData?.response}
                                                                        isfree={productData?.producto?.shipping?.is_free}
                                                                        fullfilment={productData?.producto?.features}
                                                                        stock={productData?.producto?.stock}
                                                                        updateStockTC={updateStockTC}
                                                                        updateFF={updateFF}
                                                                        status={productData?.producto?.status}
                                                                        brand={productData?.producto?.brand}
                                                                        skuChildren={productData?.producto?.sku}
                                                                        colorText={colorText}
                                                                        catPadre={catPadre}
                                                                        catHija_Hombre={catHija_Hombre}
                                                                        catHija_Mujer={catHija_Mujer}
                                                                        catHija_Nino={catHija_Nino}
                                                                        catHija_Nina={catHija_Nina}
                                                                        superExpres={superExpres}
                                                                    />
                                                                :
                                                                    <ModuleShopData
                                                                        id={productData?.producto?.id}
                                                                        title={productData?.producto?.title}
                                                                        store={productData?.producto?.store}
                                                                        discount={productData?.producto?.discount}
                                                                        price={productData?.producto?.price}
                                                                        sale_price={productData?.producto?.sale_price}
                                                                        is_digital={productData?.producto?.is_digital}
                                                                        reviews={null}
                                                                        isfree={productData?.producto?.shipping?.is_free}
                                                                        fullfilment={productData?.producto?.features}
                                                                        stock={productData?.producto?.stock}
                                                                        updateStockTC={updateStockTC}
                                                                        updateFF={updateFF}
                                                                        status={productData?.producto?.status}
                                                                        brand={productData?.producto?.brand}
                                                                        skuChildren={productData?.producto?.sku}
                                                                        colorText={colorText}
                                                                        catPadre={catPadre}
                                                                        catHija_Hombre={catHija_Hombre}
                                                                        catHija_Mujer={catHija_Mujer}
                                                                        catHija_Nino={catHija_Nino}
                                                                        catHija_Nina={catHija_Nina}
                                                                        superExpres={superExpres}
                                                                    />
                                                            }
                                                        </ErrorBoundary>

                                                        <ErrorBoundary>
                                                            {productData?.producto?.stock && productData?.producto?.stock > 0
                                                                ? promoDepart && promoDepart?.length > 0
                                                                    ? <ModuleCreditCardSearsPromotion credit_card_months={promoDepart} tieneFormasPago={productData?.producto?.payment_methods} />
                                                                    : null
                                                                : null 
                                                            }
                                                        </ErrorBoundary>

                                                        <ErrorBoundary>
                                                            {productData?.producto?.stock && productData?.producto?.stock > 0
                                                                ? productData?.producto?.payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0
                                                                    ? <ModuleCreditCardPromotion credit_card_months={productData?.producto?.payment_methods?.find(item => item.id === 4468)?.promotion[0]?.monthly_payments} />
                                                                    : null
                                                                : null
                                                            }
                                                        </ErrorBoundary>

                                                        <ErrorBoundary>
                                                            {productData?.producto?.delivery_date?.length !== 0
                                                                ? <ModuleDeliveryDate
                                                                    is_digital={productData?.producto?.is_digital}
                                                                    id_Product={productData?.producto?.id}
                                                                    skuChildren={productData?.producto?.sku}
                                                                    stock={productData?.producto?.stock}
                                                                    status={productData?.producto?.status}
                                                                    changeDirectionBar={changeDirectionBar}
                                                                    skuChildrenFF={updateFFVariant}
                                                                    updateFF={updateFF}
                                                                    fullfilment={productData?.producto?.features}
                                                                    skuProducto={productData?.producto?.sku}
                                                                    setTallaText={setTallaText}
                                                                    setColorText={setColorText}
                                                                    colorText={colorText}
                                                                    sizeColorLongitud={productData?.producto?.size_color?.length}
                                                                    tallaColor={productData?.producto?.size_color}
                                                                    // sizeColorSKU={scSKUprincipal}
                                                                    sizeColorSKU={sizeColorSKUprincipal}
                                                                    setPreseleccionar={setPreseleccionar}
                                                                    isfree={productData?.producto?.shipping?.is_free}
                                                                    sale_price={productData?.producto?.sale_price}
                                                                    //setSuperExpres={setSuperExpres}
                                                                    superExpres={superExpres}
                                                                />
                                                                : null
                                                            }
                                                        </ErrorBoundary>

                                                        <ErrorBoundary>
                                                            {productData?.producto?.features &&
                                                                productData?.producto?.features.cyr
                                                                ? <ModuleClickRecoge
                                                                    cyr={productData?.producto?.cyr}
                                                                    id={productData?.producto?.id}
                                                                    title={productData?.producto?.title}
                                                                    dataImage={productData?.producto?.images}
                                                                    precioLista={productData?.producto?.sale_price}
                                                                    discount={productData?.producto?.discount}
                                                                    skuChildren={productData?.producto?.sku}
                                                                    skuChildrenFF={updateFFVariant}
                                                                    store={productData?.producto?.store}
                                                                    stock={productData?.producto?.stock}
                                                                    categories={productData?.producto?.categories}
                                                                    status={productData?.producto?.status}
                                                                    size_color={productData?.producto?.size_color}
                                                                    colorCyR={colorText}
                                                                    tallaCyR={tallaText}
                                                                />
                                                                : null
                                                            }
                                                        </ErrorBoundary>

                                                        <ErrorBoundary>
                                                            {jsonInfo
                                                                ? 
                                                                    jsonInfo.includes(Number(productData?.producto?.sku)) === true || jsonInfo.includes(Number(updateFFVariant)) === true
                                                                        ?<ModuleServicioInstalacion skuProducto={productData?.producto?.sku} />
                                                                        : null
                                                                : null
                                                            }
                                                        </ErrorBoundary>

                                                        <div className="boxWishlistShareDesktop">
                                                            <div className="listaDeseos">
                                                                <ErrorBoundary>
                                                                    <BtnWishlistDetail
                                                                        id={productData?.producto?.id}
                                                                        nombre={productData?.producto?.title}
                                                                        precioLista={productData?.producto?.sale_price}
                                                                        brand={productData?.producto?.brand}
                                                                        categories={productData?.producto?.categories}
                                                                        index='1'
                                                                        seller={productData?.producto?.store?.name}
                                                                    />
                                                                </ErrorBoundary>
                                                            </div>

                                                            <div className="compartir">
                                                                <ErrorBoundary>
                                                                    <ShareComponentDetailTexto title={productData?.producto?.title} />
                                                                </ErrorBoundary>
                                                            </div>
                                                        </div>

                                                    </div>
                                                   
                                                    <div className="modulePaymentButtons">
                                                        <ErrorBoundary>
                                                            <ModuleShopBuy
                                                                id={productData?.producto?.id}
                                                                size_color={productData?.producto?.size_color}
                                                                stock={productData?.producto?.stock}
                                                                brand={productData?.producto?.brand}
                                                                status={productData?.producto?.status}
                                                                title={productData?.producto?.title}
                                                                price={productData?.producto?.price}
                                                                sale_price={productData?.producto?.sale_price}
                                                                dataImage={productData?.producto?.images}
                                                                stockSizeColorSKU={stockSizeColorSKU}
                                                                stockSizeColorSKUFF={stockSizeColorSKUFF}
                                                                stockSizeColorVariant={stockSizeColorVariant}
                                                                is_digital={productData?.producto?.is_digital}
                                                                category={productData?.producto?.categories}
                                                                skuProducto={productData?.producto?.sku}
                                                                setTallaText={setTallaText}
                                                                setColorText={setColorText}
                                                                colorText={colorText}
                                                                tallaText={tallaText}
                                                                setSizeColorSKUprincipal={setSizeColorSKUprincipal}
                                                                preseleccionar={preseleccionar}
                                                                isfree={productData?.producto?.shipping?.is_free}
                                                                catPadre={catPadre}
                                                                catHija_Hombre={catHija_Hombre}
                                                                catHija_Mujer={catHija_Mujer}
                                                                catHija_Nino={catHija_Nino}
                                                                catHija_Nina={catHija_Nina}
                                                                tallasNino={tallasNino} tallasNina={tallasNina} tallasHombre={tallasHombre} tallasMujer={tallasMujer}
                                                                last_modified={productData?.producto?.last_modified}
                                                                store={productData?.producto?.store?.name}
                                                            />
                                                        </ErrorBoundary>

                                                        <ErrorBoundary>
                                                            {productData?.producto?.stock && productData?.producto?.stock > 0
                                                                ?
                                                                    <ButtonCyR
                                                                        cyr={productData?.producto?.cyr}
                                                                        id={productData?.producto?.id}
                                                                        title={productData?.producto?.title}
                                                                        dataImage={productData?.producto?.images}
                                                                        precioLista={productData?.producto?.sale_price}
                                                                        discount={productData?.producto?.discount}
                                                                        skuChildren={productData?.producto?.sku}
                                                                        skuChildrenFF={updateFFVariant}
                                                                        store={productData?.producto?.store}
                                                                        stock={productData?.producto?.stock}
                                                                        categories={productData?.producto?.categories}
                                                                        status={productData?.producto?.status}
                                                                        size_color={productData?.producto?.size_color}
                                                                        colorCyR={colorText}
                                                                        tallaCyR={tallaText}
                                                                        precio={productData?.producto?.price}
                                                                    />
                                                                : null
                                                            }
                                                        </ErrorBoundary>

                                                        <ErrorBoundary>
                                                            {productData?.producto?.stock && productData?.producto?.stock > 0
                                                                ? <ButtonSellinClaroshop id={productData?.producto?.id} title={productData?.producto?.title} />
                                                                : null
                                                            }
                                                        </ErrorBoundary>

                                                        {/* modulo garantia */}
                                                        <ErrorBoundary>
                                                            {productData?.producto?.stock && productData?.producto?.stock > 0
                                                                ? productData?.producto?.warrantyDetails && productData?.producto?.warrantyDetails?.length >= 1
                                                                    ? <ModuleWarrantyProduct warrantyDetails={productData?.producto?.warrantyDetails} />
                                                                    : null
                                                                : null
                                                            }
                                                        </ErrorBoundary>

                                                        <ErrorBoundary>
                                                            <ButtonVenderSears />
                                                        </ErrorBoundary>
                                                    </div>
                                                </div>

                                                <ErrorBoundary>
                                                    <ModuleTabsDesktop
                                                        id={productData?.producto?.id}
                                                        description={productData?.producto?.description}
                                                        attributes={productData?.producto?.attributes}
                                                        payment_methods={productData?.producto?.payment_methods}
                                                        promoDepart={promoDepart}
                                                        formasPagoArray={formasPagoArray}
                                                        formasPagoOrdenadoArray={formasPagoOrdenadoArray}
                                                    />
                                                </ErrorBoundary>

                                                <ErrorBoundary>
                                                    {VARIANTS_PER_PORTAL?.emarsys?.product_related
                                                        ?
                                                            <div id="relatedSlider">
                                                                <SlidersEmarsys
                                                                    logica={'RELATED'}
                                                                    idContent={'relatedSlider'}
                                                                    idProd={String(productData?.producto?.id)}
                                                                    title={"Productos similares"}
                                                                    typeView={'sliderView'}
                                                                    cant={16}
                                                                />
                                                            </div>
                                                        : null
                                                    }
                                                </ErrorBoundary>

                                                <ErrorBoundary>
                                                    {opinionData?.response?.reviews?.length >= 1
                                                        ? <ModuleReviewsDesktop response={opinionData?.response} />
                                                        : null
                                                    }
                                                </ErrorBoundary>

                                                <ErrorBoundary>
                                                    {VARIANTS_PER_PORTAL?.emarsys?.product_personal
                                                        ?
                                                            <section id="personalSlider">
                                                                <SlidersEmarsys
                                                                    logica={'PERSONAL'}
                                                                    idContent={'personalSlider'}
                                                                    title={"Productos basados en tu navegación"}
                                                                    typeView={'sliderView'}
                                                                    cant={16}
                                                                />
                                                            </section>
                                                        : null
                                                    }
                                                </ErrorBoundary>
                                            </>
                                        : null
                                    }
                                </div>
                            </div>
                        </section>

                        <ErrorBoundary>
                            {productData?.producto
                                ?
                                    <SlidersBlocks
                                        id={productData?.producto?.id}
                                        category={productData?.producto?.categories}
                                    />
                                : null
                            }
                        </ErrorBoundary>

                        <ErrorBoundary>
                            {productData?.producto
                                ?
                                    <SirenaWhatsApp
                                        store={productData?.producto?.store}
                                        category={productData?.producto?.categories}
                                        price={productData?.producto?.price}
                                        sale_price={productData?.producto?.sale_price}
                                        stock={productData?.producto?.stock}
                                        status={productData?.producto?.status}
                                    />
                                : null
                            }
                        </ErrorBoundary>

                    </>
                    : null
                }
            </main>
        </>
    )
}