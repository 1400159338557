import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderSimple';
import '../Login/style.sass';
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import {getTokenSSO} from 'services/SSO'
import { useToken } from 'hooks/useToken'
import Loading from 'components/Loading';
import { useIsMobile } from 'hooks/useIsMobile';
import { ErrorBoundary } from 'components/Error/ErrorBoundary'

import { useLoginSSO } from 'hooks/useLogin';


export default function Authenticated() {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Authenticated');
        window.scrollTo(0, 0);
    }, [])

    const { isMobile } = useIsMobile();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (isMobile) {
            if (isMobile === 'desktop') {
                document.querySelector('#root').classList.add('desktopView')
            }
            else{
                document.querySelector('#root').classList.add('loginHeaderMobile')
            }
        }
    }, [isMobile])


    return(
        <>
            {loading && <Loading />}
            <ErrorBoundary>
                <Header pageClass="loginHeader" isMobile={isMobile} pagina="login"/>
            </ErrorBoundary>

            <AuthenticatedSSO  setLoading={setLoading}/>

        </>
    )
}



export function AuthenticatedSSO(props) {
    const {
        setLoading
    } = props

    const { dataLogin, reciveRedirect } = useLoginSSO()

    return(
        <>
            <main>
 
            </main>
        </>
    )
}


export function AuthenticatedSSO_Fun(props) {
    const {
        setLoading
    } = props

    let isLoged = decodeStorage('session_token')
    const stateSSO = decodeStorage('stateSSO')
    const nonceSSO = decodeStorage('nonceSSO' )
    const [response, setResponse] = useState(false)
    const redirectURL = decodeURIComponent( decodeStorage('redirectURL') )
    const tokenUD = useToken()
    const domain = window.location.hostname

    let code = window.location.hash?.split('&code=')[1]?.split('&')?.[0]
    let stateURL = window.location.hash?.split('state=')[1]?.split('&')?.[0]
    let nonceURL = window.location.hash?.split('nonce=')[1]?.split('&')?.[0]

    useEffect(()=>{
        if(code){
            if(stateSSO === stateURL){
                getTokenSSO({'code': code})
                .then(setResponse)
            }else{
                window.location.href = `${window.location.origin}/login`
            }
        }else{
            window.location.href = window.location.origin
        }
    },[])

    const [jwt, setJwt] = useState(false)
    useEffect(()=>{
        if(response){
            if(response.statusCode === 200){
                //console.log(response)
                // console.log(response.data.access_token)
                // console.log(response.data.refresh_token)

                encodeStorage('access_token',response.data.access_token)
                encodeStorage('refresh_token',response.data.refresh_token)
                document.cookie = `access_token=${response.data.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                document.cookie = `refresh_token=${response.data.refresh_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                
                
                let tokenJWT = window.atob(response.data.access_token?.split('.')[1])
                let tokenRefreshJWT = window.atob(response.data.refresh_token?.split('.')[1])
                
                // console.log('asdasdasdas: ',tokenJWT)
                // console.log('asdasdasdas: ',tokenRefreshJWT)
                
                encodeStorage('access_token_exp', JSON.parse(tokenJWT)?.exp)
                encodeStorage('refresh_token_exp', JSON.parse(tokenRefreshJWT)?.exp)

                setTimeout(function(){
                    setJwt(response?.data?.access_token)
                },700)
                
            }else if(response.statusCode === 400){
                isLoged = decodeStorage('session_token')
                let redirURL = redirectURL.replace(window.location.origin,'')
                if(isLoged){
                    if(redirURL){
                        //console.log(':   ',redirURL)
                        window.location.href = redirURL
                    }
                    else{
                        //console.log(':   ',window.location.origin)
                        window.location.href = window.location.origin
                    }
                }else{
                    //console.log(':   ',window.location.origin+'/login')
                    window.location.href = `${window.location.origin}/login`
                }
                
            }
        }
    },[response])


    useEffect(()=>{
        if(jwt){
            // console.log('access_token: ',decodeStorage('access_token'))
            tokenUD.forceToken(true)
        }
    },[jwt])


    useEffect(()=>{
        if(tokenUD.newToken){
            isLoged = decodeStorage('session_token')
            let redirURL = redirectURL.replace(window.location.origin,'')
            if(isLoged){
                //console.log('isLoged: ',isLoged)
                if(redirURL){
                    window.location.href = redirURL
                }else{
                    window.location.href = window.location.origin
                }
                setLoading(false)
            }
        }
    },[tokenUD.newToken])
    

    return(
        <>
            <main>
 
            </main>
        </>
    )
}