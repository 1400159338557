import React from 'react';
import './style.sass';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

function Modal({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        isMobile
    } = props

    return (
        <div className={`modal ${type}`}>
            <div className={isMobile === "desktop" ? "contentModal modalTallas" : "contentModal modalTallasM"}>
                <div className={type === "full" ? "contentCloseTallas" : "contentCloseTallas"}>
                    <div className={type === "full" ? "btn closeX" : "btn closeX"} onClick={onClose}></div>
                    {title
                        ? <p className="title">{title}</p>
                        : null
                    }
                </div>

                <div className="dataModalInstalacion">
                    <div className="headerInstalacion">
                        <img src="/img/servicio-tecnico.png" alt="servicio-instalacion" className={isMobile === "desktop" ? "imgM" : ""} />
                        <h2 className={isMobile === "desktop" ? "h2M" : ""}>Servicio de instalación gratis</h2>
                        <p className={isMobile === "desktop" ? "pDesktopM" : ""}>Conectamos tu aparato completamente gratis.*</p>
                    </div>

                    <div className="bodyInstalacion">
                        {isMobile === "desktop"
                            ? <>
                                <p className="ptitleBodyM">Una vez que tu producto sea entregado llámanos para agendar tu cita:</p>
                            </>
                            : <>
                                <p className="ptitleBody">Una vez que tu producto sea entregado</p>
                                <p className="ptitleBody ptb">llámanos para agendar tu cita:</p>
                            </>
                        }

                        <ul className={isMobile === "desktop" ? "ulInsDesk" : "ulInstalacion"}>
                            <li>
                                <div className="divImgInsta">
                                    <img src="/img/telefono.png" alt=""></img>
                                </div>
                                <div className="divTextInsta">
                                    <p>CDMX y área metropolitana</p>
                                    <a href="tel:5551303940">55 5130 3940</a>
                                </div>
                            </li>

                            <li>
                                <div className="divImgInsta">
                                    <img src="/img/telefono.png" alt=""></img>
                                </div>
                                <div className="divTextInsta">
                                    <p>República Mexicana</p>
                                    <a href="tel:8003373275">800 3373 275</a>
                                </div>
                            </li>
                        </ul>

                        <p className={isMobile === "desktop" ? "pBodyDesk" : "pBody"} >*Consulta <Link to="/terminos-condiciones/">Términos y condiciones</Link>.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default function ModalInstalacion({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        isMobile
    } = props

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} isMobile={isMobile}>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}