import React, {useState, useEffect, Fragment} from 'react'
import './style.sass'
import './icons.sass'
import { Link } from "react-router-dom"
import getCategoryMenu from 'services/getCategoryMenu'
import { getCategoryMenuNew } from 'services/getCategoryMenu'
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64'
import { getCategoryAdapter, getCategoryAdapterOld } from 'adapters/category_Adapter'

function MenuFirstLevel(props){
    const{
        catFisrt,
        nombre,
        seo,
        subcat
    } = props

    const clickLink = () =>{
        removeStorage('searchBusqueda')
    }

    return(
        <dl className="menuFirstLevel">
            <dt>
                <Link to={`/categoria/${catFisrt}/${seo}/`} className={`ico-${seo}`} onClick={clickLink}>{nombre}</Link>
                <dl className="menuSecondLevel">
                    <dt>
                        <dl className="menuThirdLevel">
                            <MenuSecondLevel subcat={subcat} />
                        </dl>
                    </dt>
                </dl>
            </dt>
        </dl>
    )
}


function MenuSecondLevel(props){
    const {
        subcat
    } = props

    const [subCategorias, setSubCategorias] = useState(subcat)
    useEffect(()=>{
        if(subcat){
            setSubCategorias(subcat)
        }
    },[subcat])

    const clickLink = () =>{
        removeStorage('searchBusqueda')
    }

    return(
        subCategorias &&
            subCategorias.map(({id_cat, nombre, seo, subcategorias, estatus, visible}) =>
                estatus !== 0 && visible !== 0
                    ? 
                        <dt className="more" key={id_cat}>
                            <Link to={`/categoria/${id_cat}/${seo}/`} onClick={clickLink}>{nombre}</Link>
                            <MenuThirdLevel subcategorias={subcategorias} id_parent={id_cat} seo_parent={seo} />
                        </dt>
                    : null
            )
    )
}


function MenuThirdLevel(props){
    const {
        id_parent,
        seo_parent,
        subcategorias
    } = props

    const [subCatFinish, setSubCatFinish] = useState(subcategorias)

    useEffect(()=>{
        if(subcategorias){
            setSubCatFinish(subcategorias)
        }
    },[subcategorias])

    const clickLink = () =>{
        removeStorage('searchBusqueda')
    }

    return(
        <ul>
            {subCatFinish &&
                subCatFinish.map(({id_cat, nombre, seo, estatus, visible}, index) =>
                    estatus !== 0 && visible !== 0
                        ?
                            index < 6
                                ? <li key={id_cat} className="alone marginTopCa"><Link to={`/categoria/${id_cat}/${seo}/`} onClick={clickLink}>{nombre}</Link></li>
                                : 
                                    index === 6
                                        ? <li key={id_cat} className="alone marginTopCa"><Link to={`/categoria/${id_parent}/${seo_parent}/`} onClick={clickLink}>Ver más</Link></li>
                                        : null
                        : null
                )
            }
        </ul>
    )
}


export function MenuCategoryHeader(){

    const [response, setResponse] = useState()
    const [categorias, setCategorias] = useState([])
    const [responseSorage, setResponseStorage] = useState([])
    const updateCategory = sessionStorage.getItem('updateCategory')
    const [responseV1, setResponseV1] = useState()
    
    //- Funcion que va al servicio de categorias
    useEffect( () => {
        if(!decodeStorage('categoryTree') || updateCategory === null){
            // getCategoryMenu()
            // .then(setResponse)

            getCategoryMenuNew()
            .then(setResponse)
            sessionStorage.setItem('updateCategory',true)
        }else{
            //- Funcion que toma las categorias del storage
            setResponseStorage(decodeStorage('categoryTree'))
        }
    },[])


    // const [men1, setMen1] =useState()
    // const [men2, setMen2] =useState()
    // useEffect( () => {
    //     getCategoryMenu()
    //     .then(setMen1)
    //     getCategoryMenuNew()
    //     .then(setMen2)
    // },[])
    // useEffect( () => {
    //     if(men1){
    //         console.log("getCategoryMenu: ", getCategoryAdapterOld(men1) )
    //     }
    //     if(men2){
    //         console.log("getCategoryMenuNew: ", getCategoryAdapter(men2) )
    //     }
    // },[men1, men2])


    useEffect( () => {
        if(response){
            if(response?.data){
                if(response?.data?.length >= 2){
                    encodeStorage('categoryTree', response)
                    setResponseStorage(response)
                }
            }
        }
        else if(responseV1){
            if(responseV1?.data){
                // console.log('')
            }
        }
    },[response, responseV1])
    
    //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
    useEffect(()=>{
        if(responseSorage){
            if(responseSorage?.statusCode === 200){
                if(typeof(responseSorage?.data) === 'object'){
                    setCategorias({
                        'categoriasMenu': responseSorage?.data
                    })
                }
                else{
                    setCategorias({})
                }
            }
        }
        else if(responseSorage.statusCode !== undefined){
            setCategorias({})
        }
        
    },[responseSorage])


    //- valida si todo el arbol esta desactivado o invisible
    useEffect(()=>{
        setTimeout(function(){
            if(document.querySelector('.subMenu > *') === null && responseSorage){
                if(String(decodeStorage('validMenuCat')).length === 0 ){
                    setTimeout(function(){
                        // getCategoryMenu()
                        // .then(setResponse)

                        getCategoryMenuNew()
                        .then(setResponse)
                        console.log('1')
                        
                        sessionStorage.setItem('updateCategory',true)
                        encodeStorage('validMenuCat', Date.now()+15*60000)
                    },3000)
                }else if(String(decodeStorage('validMenuCat')).length >= 10 ){
                    if(Number( decodeStorage('validMenuCat') ) <= Date.now()){
                        setTimeout(function(){
                            // getCategoryMenu()
                            // .then(setResponse)
                            
                            getCategoryMenuNew()
                            .then(setResponse)
                            console.log('2')
                
                            sessionStorage.setItem('updateCategory',true)
                            encodeStorage('validMenuCat', Date.now()+15*60000)
                        },3000)
                    }
                }
            }else{
                removeStorage('validMenuCat')
            }
        },1000)
    },[categorias])
    
    
    return(
        <div className="menuHeader">
            <div className="subMenu">
                {responseSorage &&
                    responseSorage?.statusCode === 200
                        ?
                            categorias?.categoriasMenu &&
                                <>
                                    {
                                        categorias?.categoriasMenu?.map(({nombre, icon, seo, id_cat, subcat, estatus, visible}) => 
                                            estatus !== 0  && visible !== 0 && String(nombre).toLowerCase() != "marketplace"
                                                ?
                                                    <Fragment key={id_cat}>
                                                        <MenuFirstLevel
                                                            key={id_cat}
                                                            catFisrt={id_cat}
                                                            nombre={nombre}
                                                            icon={icon}
                                                            seo={seo}
                                                            subcat={subcat}
                                                        />
                                                    </Fragment>
                                                :
                                                    null
                                        )
                                    }
                                </>
                        : null
                }
                
            </div>
        </div>
    )
}