import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactDOM from 'react-dom';
import { repareImage } from 'functions/repareDataService'

export default function SliderModal({ dataImage, setIsShowing, fijarIndex, indexAbrir, setIndexAbrir}) {

    const [photoIndex, setphotoIndex] = useState(Number(indexAbrir));

    let images = []
    dataImage.map((ima, i) => {
        return (images.push( repareImage(ima.url) ))
    })

    useEffect(() => {
        fijarIndex(photoIndex)
    }, [photoIndex])

    useEffect(() => {
        setIndexAbrir(Number(photoIndex))
    }, [photoIndex])

    return (
        ReactDOM.createPortal(
            <div className="modal2">
                {images.length > 0 &&
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsShowing()}
                        onMovePrevRequest={() => setphotoIndex((photoIndex + images.length - 1) % images.length)}
                        onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % images.length)}
                        enableZoom={true}
                        imageCaption={`${photoIndex + 1} / ${images.length}`}
                    />
                }
            </div>, document.getElementById('modal-root'))
    )
}
