import React, { useState, useEffect, Fragment, useRef } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import Loading from 'components/Loading';
import { repareTextSimple } from 'functions/repareDataService';
import { API_KEY } from 'constants/general'
import FooterPortal from 'pages/Footer/FooterDesktop';
import { useHistory } from 'react-router-dom';
import { useStorage64 } from 'hooks/useStorageBase64';
import { postCreateTiket } from 'services/AyudaDinamicaServices';
import { ModalError } from '../vista4-modal';
import ModalPortal from 'components/Modal';
const { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } = require("react-google-maps");


export function ElegirTiendaDesktop({ tiendas, response, loading2 }) {

    const history = useHistory();
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    // variables para el mapa
    const urlGoogle = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${API_KEY}`;
    const storageLatitud = decodeStorage('latitud');
    const storageLongitud = decodeStorage('longitud');
    const [latitud, setLatitud] = useState(false);
    const [longitud, setLongitud] = useState(false);
    const [latitudMexico,] = useState(19.4381193);   // LATITUD INICIAL DE SEARS POLANCO (TIENDA PRINCIPAL)
    const [longitudMexico,] = useState(-99.2069279); // LONGITUD INICIAL DE SEARS POLANCO (TIENDA PRINCIPAL)
    const [isOpen, setIsOpen] = useState(false);
    const [ventanaAbierta, setVentanaAbierta] = useState(false);
    const [storeActive, setStoreActive] = useState(false);
    const [primeraPosicion, setPrimeraPosicion] = useState([]);

    // variables para el buscador
    const [valorSearch, setValorSearch] = useState('');
    const search = useRef(null);
    const [, setBandera] = useState(false);
    let respuesta = [];
    const focusTextInput = () => search.current.focus();
    const [valorSearchClick, setValorSearchClick] = useState("");
    const [verTodas, setVerTodas] = useState(false);
    const profileData = useStorage64('profileData');


    //variables para crear ticket
    const [loading, setLoading] = useState(false);
    const [crearTicket, setCrearTicket] = useState(false);
    const [infoTicketEntregado] = useStorage64("infoTicketEntregado");
    const [storageActionId] = useStorage64("storageActionId");
    const [storageMotivoEntregado] = useStorage64("storageMotivoEntregado");
    const [storageImgProducto] = useStorage64("storageImgProducto");
    const [storageDatosBancarios] = useStorage64("storageDatosBancarios");
    const [storageDevolverOpcion] = useStorage64("storageDevolverOpcion");
    const [correoUsuario] = useStorage64('storageCorreoUsuario');
    const [, setDireccionTiendaStorage] = useStorage64('storageDireccionTienda');
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'AD elige tu tienda desktop');
    }, [])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i]?.indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])


    // posiciona en x/y 0,0
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    // revisa si se tiene valor en el campo de busqueda
    useEffect(() => {
        if (valorSearch.length > 0) {
            setBandera(true);
        } else {
            setBandera(false);
        }
    }, [valorSearch])

    // borrar el contenido buscado
    const borrar = (e) => {
        if (Number(e.keyCode) === 8) {
            if (valorSearch.length === 1) {
                setValorSearch("");
                if (tiendas.length > 0) {
                    if (tiendas[0].status === true) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
            if (valorSearch.length === 0) {
                setValorSearch("");

                if (tiendas.length > 0) {
                    if (tiendas[0].status === true) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
            if (valorSearchClick.length === 1) {
                setValorSearchClick("");

                if (tiendas.length > 0) {
                    if (tiendas[0].status === true) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
            if (valorSearchClick.length === 0) {
                setValorSearchClick("");
                if (tiendas.length > 0) {
                    if (tiendas[0].status === true) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
        }
    }

    // resetea valorSearchClick y el valor del input se resetea
    useEffect(() => {
        if (valorSearch.length === 0) {
            setValorSearchClick("");
            if (tiendas.length > 0) {
                if (tiendas[0].status === true) {
                    setPrimeraPosicion(tiendas[0]);
                    setStoreActive(1);
                    setLatitud(tiendas[0].latitude);
                    setLongitud(tiendas[0].longitude);
                }
                else {
                    for (let i = 0; i <= tiendas.length; i++) {
                        if (tiendas[i].status === true) {
                            setPrimeraPosicion(tiendas[i]);
                            setStoreActive(i + 1);
                            setLatitud(tiendas[i].latitude);
                            setLongitud(tiendas[i].longitude);
                            return
                        }
                    }
                }
            }
        }
    }, [valorSearch])

    useEffect(() => {
        if (valorSearchClick.length === 0) {
            setValorSearchClick("");
            if (tiendas.length > 0) {
                if (tiendas[0].status === true) {
                    setPrimeraPosicion(tiendas[0]);
                    setStoreActive(1);
                    setLatitud(tiendas[0].latitude);
                    setLongitud(tiendas[0].longitude);
                }
                else {
                    for (let i = 0; i <= tiendas.length; i++) {
                        if (tiendas[i].status === true) {
                            setPrimeraPosicion(tiendas[i]);
                            setStoreActive(i + 1);
                            setLatitud(tiendas[i].latitude);
                            setLongitud(tiendas[i].longitude);
                            return
                        }
                    }
                }
            }
        }
    }, [valorSearchClick])

    useEffect(() => {
        if (tiendas.length > 0) {
            if (tiendas[0].status === true) {
                setStoreActive(0);
            }
            else {
                for (let i = 0; i <= tiendas.length; i++) {
                    if (tiendas[i].status === true) {
                        setStoreActive(i + 1);
                        return
                    }
                }
            }
        }
    }, [tiendas])

    // si el el valor de busqueda tiene valor, busca por ese campo al hacer click
    const clickBuscarTienda = () => {
        if (valorSearch.length > 0)
            setValorSearchClick(valorSearch);
    }

    // funcion llamada en el boton buscar del input
    const buscar = (e) => {
        if (String(e.target.value) === "") {
            setValorSearch('');
        }
        if (String(e.target.value) !== "") {
            setValorSearch(e.target.value);
        }
    }

    // abre ventana de direccion del marcador seleccionado
    const handleToggle = (e, id) => {
        setVentanaAbierta(id);
        setIsOpen(!isOpen);
    }

    // detecta la longitud y latitud inicial (primera tienda de la lista)
    useEffect(() => {
        if (tiendas.length > 0) {
            setLatitud(tiendas[0].latitude);
            setLongitud(tiendas[0].longitude);
            if (tiendas.length > 0) {
                if (tiendas[0].status === true) {
                    setLatitud(tiendas[0].latitude);
                    setLongitud(tiendas[0].longitude);
                }
                else {
                    for (let i = 0; i <= tiendas.length; i++) {
                        if (tiendas[i].status === true) {
                            setLatitud(tiendas[i].latitude);
                            setLongitud(tiendas[i].longitude);
                            return
                        }
                    }
                }
            }
        }
    }, [tiendas])

    // mapa general con todos los marcadores
    const GoogleMapLocalizador = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={14}
            defaultCenter={{ lat: Number(latitud), lng: Number(longitud) }}
            center={{ lat: Number(latitud), lng: Number(longitud) }}
        >
            {props.isMarkerShown &&
                tiendas.map((marker, id) => (
                    marker.status === true && <Fragment key={id}>
                        <Marker
                            position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
                            key={marker.id}
                            labelStyle={{ textAlign: "center", width: '220px', fontSize: "14px", paddingTop: "8px", marginTop: "30px" }}
                            labelAnchor={{ x: (220 / 2) + 8, y: 80 }}
                            label={{ text: `${marker.name}`, color: "#e4002b", top: "20px", position: "relative", fontWeight: "400" }}
                            onClick={(e) => handleToggle(e, marker.id)}
                            icon={{
                                url: '/img/pinSearsPeque.png',
                                labelOrigin: new window.google.maps.Point(85, 45),
                                anchor: new window.google.maps.Point(16, 32)
                            }}
                            labelClass='map-price-marker'
                            markerWithLabel={window.MarkerWithLabel}
                            labelContent="<div class='marker'></div>"
                        >

                            {isOpen && ventanaAbierta === marker.id &&

                                <InfoWindow onCloseClick={(e) => handleToggle(e, marker.id)}  >
                                    <div className="ventanaInfo">
                                        <div className="nameTienda">
                                            <p>{marker.name}</p>
                                        </div>
                                        <div className="direccionTienda">
                                            <span></span>
                                            <p>{marker.street} {marker.number} {marker.suburb}, C.P. {marker.zip_code}, {marker.state}</p>
                                        </div>
                                        <div className="horarioTienda">
                                            <span></span>
                                            <p>Horario de {marker.schedule}</p>
                                        </div>
                                        <div className="telefonoTienda">
                                            <span></span>
                                            <p>Tel. ({marker.phone.lada}) {marker.phone.number}</p>
                                        </div>

                                        <div className="botones">
                                            <div className="btnElegir">
                                                <button className="elegir" onClick={(e) => ElegirTienda(marker.external_id, marker.name, marker.street, marker.number, marker.suburb, marker.zip_code, marker.state, marker.province, marker.phone)}>Elegir esta tienda</button>
                                            </div>
                                        </div>
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    </Fragment>
                ))
            }
        </GoogleMap>
    ))

    // mapa con marcador filtrado por tienda
    const GoogleMapLocalizadorConFiltro = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
            center={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
        >
            <Marker
                position={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
                key={primeraPosicion.id}
                labelStyle={{ textAlign: "center", width: '220px', fontSize: "14px", paddingTop: "8px", fontWeight: "400" }}
                labelAnchor={{ x: (220 / 2) + 8, y: 80 }}
                onClick={(e) => handleToggle(e, primeraPosicion.id)}
                label={{ text: `${primeraPosicion.name}`, color: "#e4002b" }}
                icon={{
                    url: '/img/pinSearsPeque.png',
                    labelOrigin: new window.google.maps.Point(85, 45),
                    anchor: new window.google.maps.Point(16, 32)
                }}
            >
                {isOpen && ventanaAbierta === primeraPosicion.id &&

                    <InfoWindow onCloseClick={(e) => handleToggle(e, primeraPosicion.id)}  >
                        <div className="ventanaInfo">
                            <div className="nameTienda">
                                <p>{primeraPosicion.name}</p>
                            </div>
                            <div className="direccionTienda">
                                <span></span>
                                <p>{primeraPosicion.street} {primeraPosicion.number} {primeraPosicion.suburb}, C.P. {primeraPosicion.zip_code}, {primeraPosicion.state}</p>
                            </div>
                            <div className="horarioTienda">
                                <span></span>
                                <p>Horario de {primeraPosicion.schedule}</p>
                            </div>
                            <div className="telefonoTienda">
                                <span></span>
                                <p>Tel. ({primeraPosicion.phone.lada}) {primeraPosicion.phone.number}</p>
                            </div>

                            <div className="botones">
                                <div className="btnElegir">
                                    <button className="elegir" onClick={(e) => ElegirTienda(primeraPosicion.external_id, primeraPosicion.name, primeraPosicion.street, primeraPosicion.number, primeraPosicion.suburb, primeraPosicion.zip_code, primeraPosicion.state, primeraPosicion.province, primeraPosicion.phone)}>Elegir esta tienda</button>
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                }
            </Marker>

        </GoogleMap>
    ))

    // obtiene la primera posicion del resultado de busqueda
    useEffect(() => {
        if (respuesta.length > 0) {
            if (respuesta[0].status === true) {
                setPrimeraPosicion(respuesta[0]);
                setStoreActive(respuesta[0].id);
            }
            else {
                for (let i = 0; i <= respuesta.length; i++) {
                    if (respuesta[i].status === true) {
                        setPrimeraPosicion(respuesta[i]);
                        setStoreActive(respuesta[i].id);
                        return
                    }
                }
            }
            setVerTodas(false);
        }
    }, [respuesta])

    const [, setFiltroG] = useState([]);
    // guarda informacion de tienda activa en radio button
    const seleccionarTienda = (e) => {
        let numero = Number(e.target.value);
        let filtro = [];

        tiendas.forEach(function (task) {
            if (Number(task.id) === numero) {
                filtro.push(task);
            }
        });

        setFiltroG(filtro[0]);
        setStoreActive(numero);
        setLatitud(filtro[0].latitude);
        setLongitud(filtro[0].longitude);
        setVerTodas(false);
    }

    // funcion que activa ver tiendas cercanas
    const showAllStores = () => {
        setVerTodas(true);
        setValorSearchClick("");
        setValorSearch("");
    }

    const actualizarLocalizacion = () => {
        setVerTodas(true);
    }

    // enviar coordenadas a mapa para mostrar tiendas cercanas
    useEffect(() => {
        // presiono boton ver tiendas cercanas
        if (verTodas === true) {
            // ya tiene una ubicacion permitida
            if (storageLatitud && storageLongitud) {
                setLatitud(Number(storageLatitud));
                setLongitud(Number(storageLongitud));
            }
            // no tiene ubicacion permitida
            else {
                if ("geolocation" in navigator) {
                    console.log("Geolocalization Available");
                    // solicita coordenadas 
                    navigator.geolocation.getCurrentPosition(position => {
                        encodeStorage('latitud', position.coords.latitude);
                        encodeStorage('longitud', position.coords.longitude);
                        setLatitud(position.coords.latitude);
                        setLongitud(position.coords.longitude);
                    }, e => {
                        console.log("usuario rechaza permisos. ", e);
                        if (e.code === e.PERMISSION_DENIED) {
                            // pop up dialog asking for location
                            removeStorage("latitud");
                            removeStorage("longitud");
                        }
                        setLatitud(19.4381193);
                        setLongitud(-99.2069279);
                    });
                }
                // explorador no tiene disponible geolocalizacion
                else {
                    console.log("Geolocalization not Available.");
                    setLatitud(19.4381193);
                    setLongitud(-99.2069279);
                }
            }
        }
    }, [verTodas])

    // regresar a pedido en modal error
    const regresarAnterior = () => {
        history.goBack(-1);
    }

    //********************* FUNCIONAMIENTO PARA AYUDA DINAMICA ************************ */
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];

    const [showModalError, SetShowModalError] = useState(false);
    const [remover, setRemover] = useState(false);

    //guarda los parametros de la tienda seleccionada
    const ElegirTienda = (idTienda, name, street, number, suburb, zip_code, state, province, phone) => {

        let direccionTienda = [{ "id": idTienda, "name": name, "street": street, "number": number, "suburb": suburb, "zip_code": zip_code, "state": state, "province": province, "phone": phone }];
        setDireccionTiendaStorage(direccionTienda);

        let client_id = infoTicketEntregado.client_id;
        let order_id = infoTicketEntregado.order_id;
        let order_number = infoTicketEntregado.order_number;
        let ticket_type_id = infoTicketEntregado.ticket_type_id;
        let action_id = storageActionId;
        let reason_id = infoTicketEntregado.reason_id;
        let description = storageMotivoEntregado;
        let refound_details;
        if (storageDatosBancarios) {
            refound_details = storageDatosBancarios;
        } else {
            refound_details = [];
        }

        let products_to_cancel = [{ "id": infoTicketEntregado.products_to_cancel[0].id, "return_type": storageDevolverOpcion, "subsidiary_id": idTienda }];
        let additional_details = storageImgProducto;
        let correo = correoUsuario;

        setRemover(true);

        postCreateTiket({
            reason_id,
            order_id,
            order_number,
            client_id,
            description,
            products_to_cancel,
            ticket_type_id,
            refound_details,
            additional_details,
            action_id,
            correo
        })
            .then(setCrearTicket);
    }

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    const [idTicketStorage, setIdTicketStorage] = useStorage64('storageIdTicket');
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {

                    if (idProductoEstate !== 0) {
                        setIdTicketStorage(crearTicket.data.id);
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/typ");
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }


    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                {loading && <Loading />}

                {loading2 && <Loading />}

                {response &&
                    response.statusCode === 200
                    ?
                    tiendas &&
                    <section className="sectionAuto">

                        <div className="sectionContentLocalizador sectionContentElegir">
                            <section className="sectionTitleAutoLocalizador">
                                <div className="divTitleElegir">
                                    <div className="icoBack" onClick={regresarAnterior}></div>
                                    <p className="btnBackAD">Elige una tienda</p>
                                </div>
                            </section>

                            <div className="contentLocalizador">
                                <div className="localizadorL">

                                    <div className="buscadorLocalizadorDesk">
                                        <div className="divLabel">
                                            <label>
                                                <input aria-label="Buscador Tiendas" autoComplete="off" type="search" placeholder="Buscar por nombre o C.P."
                                                    id="buscadorStore" className="buscadorStore"
                                                    value={valorSearch}
                                                    ref={search}
                                                    onChange={(text1) => buscar(text1)}
                                                    onKeyDown={(e) => borrar(e)}>
                                                </input>
                                            </label>
                                        </div>
                                        <div className="bBuscar">
                                            <button onClick={clickBuscarTienda}> Buscar</button>
                                        </div>
                                    </div>

                                    <div className="contenedorCards">
                                        {tiendas.filter((dataInterna, id) => {
                                            if ((valorSearchClick == null || valorSearchClick === '')) {
                                                return dataInterna
                                            }
                                            else if (
                                                (repareTextSimple(String(dataInterna.name).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.state).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.suburb).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.zip_code).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())))
                                            ) {
                                                respuesta.push(dataInterna)
                                                return dataInterna
                                            }
                                        }).map(({ id, street, latitude, longitude, name, phone, number, suburb, zip_code, state, schedule, status, external_id, province }, i) =>
                                            status === true &&
                                            <div className="direccionTienda" key={i}>
                                                <div className="bxCardTienda">

                                                    <h3 className="tituloTienda" onChange={(e) => seleccionarTienda(e)}>
                                                        <input className="radio" type="radio" value={id} id={id} name="store"
                                                            checked={Number(id) === Number(storeActive) ? true : false}>
                                                        </input>
                                                        <label name={id} htmlFor={id}>{String(name).toUpperCase()} </label>
                                                    </h3>

                                                    <p className="d1bx"><span className="direcBx"></span> {street} {number} {suburb}, C.P. {zip_code} {state}</p>
                                                    <p className="d3bx"><span className="horarioBx"></span> Horario de {schedule}</p>
                                                    <p className="d2bx"><span className="telBx"></span> Tel. ({phone.lada})  {phone.number}</p>

                                                    <div className="botones">
                                                        <div className="btnElegir">
                                                            <button className="elegir" onClick={(e) => ElegirTienda(external_id, name, street, number, suburb, zip_code, state, province, phone)} disabled={Number(id) === Number(storeActive) ? false : true}>Elegir esta tienda</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )}

                                        {(valorSearchClick.length > 0 && respuesta.length === 0) && (search.current && search.current.defaultValue)
                                            && <div className="bxSinResultados">
                                                <p>No se encontraron tiendas con el nombre o c.p. indicado.</p>
                                            </div>
                                        }
                                    </div>

                                </div>

                                <div className="localizadorR">

                                    <div className="mapBx">
                                        <div className="mapT">
                                            <div className="cajaButtons">
                                                <div className="btnStore">
                                                    <button onClick={showAllStores} className={verTodas === true ? "active" : ""}>Tiendas cercanas</button>
                                                </div>
                                                <div className="btnAct">
                                                    {/* <button onClick={actualizarLocalizacion} className={""}>
                                                        <p>Actualizar ubicación</p>
                                                        <span></span>
                                                    </button> */}
                                                </div>
                                            </div>

                                            <div className="copyStore">
                                                <p>Para mostrar tus tiendas cercanas pediremos acceso a tu localización</p>
                                            </div>
                                        </div>

                                        <div className="mapB">
                                            {respuesta.length > 0
                                                ? <GoogleMapLocalizadorConFiltro
                                                    isMarkerShown={true}
                                                    googleMapURL={urlGoogle}
                                                    containerElement={<div style={{ height: '550px' }} />}
                                                    mapElement={<div style={{ height: "100%" }} />}
                                                    loadingElement={<p>Cargando...</p>}
                                                    center={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
                                                />
                                                : <GoogleMapLocalizador
                                                    isMarkerShown={true}
                                                    googleMapURL={urlGoogle}
                                                    containerElement={<div style={{ height: '550px', width: '100%', maxWidth: '1200px', minHeight: '320px' }} />}
                                                    mapElement={<div style={{ height: "100%" }} />}
                                                    loadingElement={<div style={{ textAlign: "center", fontWeight: "600" }}> <p>Cargando...</p></div>}
                                                    center={{ lat: latitudMexico, lng: longitudMexico }}
                                                />
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    : null
                }

                {showModalError &&
                    <ModalPortal type="modalSB" onClose={handleCloseError} >
                        <ModalError okRegresar={regresarPedidos} />
                    </ModalPortal>
                }
            </main>

            <FooterPortal />
        </>
    )
}