import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import UpdatePassForm from 'components/UpdatePassForm'
import Loading from 'components/Loading'
import { postUpdatePass } from 'services/RecoveryPassword'
import { useStorage64 } from 'hooks/useStorageBase64'
import { useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile'
import ContraseniaSegura from './contraseniaSegura'

export default function UpdatePassword(props) {
    const {
        tokenUpdate,
        setUpdatePage
    } = props

    const location = window.location.origin

    const [successForm, getSuccessForm] = useState(false)
    const [dataForm, getDataForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isLoged] = useStorage64('session_token', '')
    const { isMobile } = useIsMobile()
    const history = useHistory()

    const backHistory = () => {
        history.go(-1)
    }

    const backHistoryHome = () => {
        history.replace(`/login?redirect=${location}/`);
    }

    //- Funcion que manda el correo a solicitar su password
    useEffect(() => {
        if (dataForm) {
            postUpdatePass({ password: dataForm.password, token: tokenUpdate })
                .then(getSuccessForm)
        }
    }, [dataForm])

    //- Remueve el modal una ves responde el servicio

    useEffect(() => {
        if (successForm) {
            setLoading(false)
            // console.log(successForm)
        }
    }, [successForm])

    // recibe los datos del formulario
    const reciveData = (val) => {
        getDataForm(val)
        setLoading(true)
    }
    const refreshPage = () => {
        setUpdatePage(true)
    }

    const prevUrl = document.referrer;
    const urlLink = window.location.href;
    const hashLink = window.location.hash;

    const [showModal, SetShowModal] = useState(false);
    const showModalSegura = () => {
        SetShowModal(true);
    };

    // useEffect(() => {
    //     if (prevUrl === "") {
    //         if (hashLink === "#modalContrasenaSegura") {
    //             showModalSegura();
    //         } else {
    //             SetShowModal(false);
    //         }
    //     } else {
    //         if (hashLink === "#modalContrasenaSegura") {
    //             showModalSegura();
    //         } else {
    //             SetShowModal(false);
    //         }
    //     }
    // }, [hashLink, urlLink])

    const handleClose = () => {
        // history.push(location.pathname);
        // history.goBack();
        SetShowModal(false);
    };


    return (
        <>
            {loading && <Loading />}

            {successForm
                ?
                successForm.statusCode === 200
                    ?
                    <>
                        {isMobile === 'desktop'
                            ? <h1 className="titleRegistro">Contraseña actualizada</h1>
                            // <h1 className="titleRegistro">Generar nueva contraseña</h1>
                            :
                            <div className="sectionTitleServicioClienteLogo">
                                {/* <button onClick={backHistory}>Generar nueva contraseña</button> */}
                                <img src="/img/logo-sears.svg" alt="logo-sears"></img>
                            </div>
                        }
                        <div className="loginPage">
                            {isMobile !== 'desktop' && <h2 className="titleGenerar textCenter">Contraseña actualizada</h2>}
                            <img src="img/checkMark.svg" width="200" height="200" className="icoCheck" alt="contrasena-actualizada" />
                            <p className="textResponseMessagePass">Tu contraseña se generó exitosamente.</p>
                            {isLoged
                                ?
                                <Link to={`/`} className="btn rojo">Ir al home</Link>
                                :
                                <Link to={`/login?redirect=${location}/`} className="btn rojo">Regresar a Login</Link>
                            }
                        </div>
                    </>
                    :
                    <>
                        {isMobile !== 'desktop'
                            && <div className="sectionTitleServicioClienteLogo">
                                <img src="/img/logo-sears.svg" alt="logo-sears"></img>
                                <button onClick={backHistoryHome} className="botonCerrar"></button>
                            </div>
                        }

                        <img src="img/error-link.svg" width="200" height="200" className="icoOpps" alt="¡¡Oooops!!" />
                        {/* <h1 className="h1oops">¡¡Oooops!!</h1> */}
                        <div className="loginPage">
                            <p className="textResponseMessagePass">El link para recuperar la contraseña a caducado o ya ha sido utilizado. <span className="text2">Te recomendamos volver al proceso.</span></p>
                            {isMobile === 'desktop' && <Link to={`/login?redirect=${location}/`} className="btn rojo" >Regresar a Login</Link>}
                        </div>
                    </>

                :
                <>
                    {isMobile === 'desktop'
                        ?
                        <h1 className="titleRegistro">Generar nueva contraseña</h1>
                        :
                        <div className="sectionTitleServicioClienteLogo">
                            {/* <button onClick={backHistory}>Generar nueva contraseña</button> */}
                            <img src="/img/logo-sears.svg" alt="logo-sears"></img>
                        </div>
                    }

                    {isMobile !== 'desktop' && <h3 className="titleGenerar"> Generar nueva contraseña</h3>}
                    <p className="smallText infoCopy" onClick={showModalSegura}>Ingresa una contraseña segura</p>

                    <UpdatePassForm dataForm={reciveData} />
                </>
            }

            {showModal && <ContraseniaSegura onClose={handleClose} type={isMobile === "desktop" ? "" : "full"} title={""} logo={true} />}

        </>
    )
}