import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';

export function PoliticaCancelacionDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica Cancelacion Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Política de Cancelación</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <p>Podrás efectuar la cancelación total o parcial de una compra hecha a través de la página sears.com.mx siempre y cuando no se te haya enviado la mercancía, para ello, deberás envíar un correo electrónico <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a> o bien, comunicándote con nosotros vía telefónica al <a href="tel:015553451606">(01-55) 53 45 16 06</a> o del interior de la República al <a href="tel:018008368246">(01 800) 836 82 46</a>.</p>
                                <p>Si al momento de recibir tu aviso (por cualquier medio antes mencionado) la mercancía ya ha sido enviada, te pedimos rechazar la entrega en tu domicilio para que el servicio de mensajería nos la devuelva. Al recibir nosotros la mercancía de regreso, te haremos el reembolso en la misma forma de pago original exceptuando los gastos de envío.</p>

                                <p>Sí tu forma de pago fue depósito bancario, te pedimos que al momento de solicitar la cancelación nos proporciones: CLABE Interbancaria, Banco y Nombre del titular de la cuenta; el reembolso se hará mediante transferencia electrónica por el importe del pedido dentro de los siguientes cinco días hábiles a la obtención de tus datos. No se harán reembolsos si no contamos con los datos antes mencionados. Si no tienes alguna cuenta bancaria, debes proporcionarnos el nombre al que se le expedirá un cheque y la tienda en la que deseas recogerlo (previa identificación oficial).</p>
                                <p>En caso de cancelaciones parciales, solo se reembolsará el precio de la mercancía y no los gastos de envío.</p>
                                <p>Únicamente se reembolsarán los gastos de envío cuando el motivo de la cancelación sea imputable a Sears por no tener existencias y no poder surtir el pedido.</p>
                                <p>Sears podrá cancelar un pedido hecho para ser pagado mediante depósito bancario si no realizas el depósito dentro de los 3 días naturales siguientes a tu solicitud de compra.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function PoliticaCancelacionMobile() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Cancelacion Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Política de cancelación</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <p className="pSliderL">Podrás efectuar la cancelación total o parcial de una compra hecha a través de la página sears.com.mx siempre y cuando no se te haya enviado la mercancía, para ello, deberás envíar un correo electrónico <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a> o bien, comunicándote con nosotros vía telefónica al <a href="tel:015553451606">(01-55) 53 45 16 06</a> o del interior de la República al <a href="tel:018008368246">(01 800) 836 82 46</a>.</p>
                                <p className="pSliderL">Si al momento de recibir tu aviso (por cualquier medio antes mencionado) la mercancía ya ha sido enviada, te pedimos rechazar la entrega en tu domicilio para que el servicio de mensajería nos la devuelva. Al recibir nosotros la mercancía de regreso, te haremos el reembolso en la misma forma de pago original exceptuando los gastos de envío.</p>

                                <p className="pSliderL">Sí tu forma de pago fue depósito bancario, te pedimos que al momento de solicitar la cancelación nos proporciones: CLABE Interbancaria, Banco y Nombre del titular de la cuenta; el reembolso se hará mediante transferencia electrónica por el importe del pedido dentro de los siguientes cinco días hábiles a la obtención de tus datos. No se harán reembolsos si no contamos con los datos antes mencionados. Si no tienes alguna cuenta bancaria, debes proporcionarnos el nombre al que se le expedirá un cheque y la tienda en la que deseas recogerlo (previa identificación oficial).</p>
                                <p className="pSliderL">En caso de cancelaciones parciales, solo se reembolsará el precio de la mercancía y no los gastos de envío.</p>
                                <p className="pSliderL">Únicamente se reembolsarán los gastos de envío cuando el motivo de la cancelación sea imputable a Sears por no tener existencias y no poder surtir el pedido.</p>
                                <p className="pSliderL">Sears podrá cancelar un pedido hecho para ser pagado mediante depósito bancario si no realizas el depósito dentro de los 3 días naturales siguientes a tu solicitud de compra.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export function PoliticaCancelacion() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Cancelación');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <PoliticaCancelacionDesktop />

                : isMobile === 'movile'
                    ? null
                    : <PoliticaCancelacionMobile />
            }
        </>
    )
}