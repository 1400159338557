import React, { useEffect, useState } from 'react';
import "./style.sass";
import Slider from 'react-slick';
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import { useServices } from 'hooks/useServices'
import { decodeStorage } from 'functions/storageBase64';
import { BASE_PATH_RESOURCES, VARIANTS_PER_PORTAL } from 'constants/general';


import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';
import Loading from 'components/Loading'

export function AutocenterDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP)

    const {
        headers,
        statusCode,
        data,
        error,
        loading
    } = useServices({
        method: 'get',
        url: `/staticJson/autocenter.json`,
        timeout: 25000,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })

    let fecha = new Date()
    
    useEffect(()=>{
        if(statusCode === 200){
            if(data){
                console.log('data: ', data )
            }
        }
    },[statusCode, data])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const settings = {
        arrows: true,
        dots: false,
        autoplay: true,
        infinite: true,
        centerMode: false,
        swipe: true,
        touchMove: true,
        slidesToShow: 1,
        speed: 700,
        responsive: [
            {
                breakpoint: 780,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: false,
                    swipe: true,
                    touchMove: true,
                    slidesToShow: 1,
                    speed: 700,
                }
            }
        ]
    };

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Autocenter</p>
                            </div>
                        </section>

                        <div className="sec1">
                            <div className="bx1">
                                <Slider {...settings}>
                                    {data &&
                                        data[0]?.arrayImagenesDesktop.map(({ url, id, alt }) =>
                                            <div title={alt} key={id} className="cardSliderAutocenter">
                                                <img loading="lazy" src={`${url}?ver=${String(fecha.getFullYear())+ String(fecha.getMonth())+ String(fecha.getDate())}`} alt={alt} width="260" height="235" importance="low" className="radiusImg" />
                                            </div>
                                        )
                                    }
                                </Slider>
                            </div>
                        </div>

                        {statusCode === 200 &&
                            <div className="sec2">
                                <div className="bx1">
                                    <img src={data ? `${data[0]?.autocenter}?ver=${String(fecha.getFullYear())+ String(fecha.getMonth())+ String(fecha.getDate())}` : null} alt="verificacion" />
                                </div>
                            </div>
                        }

                        {data &&
                            <div className="sec3">
                                <div className="titleSectionServi">
                                    <p>Nuestros Servicios</p>
                                </div>

                                <ul className="ulServ">
                                    {data &&
                                        data[0]?.servicios.map((servicio, i) =>
                                            <div className={"servicio" + i}>
                                                {servicio.seccion === 1
                                                    ? <div className="bxTitleSer" key={i}><p>{servicio.title}</p></div>
                                                    : Number(servicio.seccion) === 2
                                                        ? <div className="bxTitleSer" key={i}><p>{servicio.title}</p></div>
                                                        : <div className="bxTitleSer " key={i}><p>{servicio.title}</p></div>
                                                }

                                                <ul className={"internoServ"}>
                                                    {servicio.servicios.map((ser, id) =>
                                                        <li key={id} className="liServ">
                                                            <div className="im">
                                                                <img src={"/img/autocenterimg" + ser.img} alt={"servicio" + id} />
                                                            </div>
                                                            <div className="im">
                                                                <span>{ser.name}</span>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        )
                                    }

                                </ul>
                            </div>
                        }

                        {data &&
                            <div className="sec4">
                                <div className="titleSection">
                                    <p>Directorio</p>
                                </div>

                                <ul className="ulDirectorio">
                                    {data &&
                                        data[0]?.directorio.map((direc, i) =>
                                            <>
                                                {direc.id === 0
                                                    ? <div className="bxTitleDir" key={i}><p>{direc.title}</p></div>
                                                    : <div className="bxTitleDir " key={i}><p>{direc.title}</p></div>
                                                }

                                                <ul className="internoDirectorio">
                                                    {direc.tiendas.map((tienda, id) =>
                                                        <li key={id} className="liDir">
                                                            <h3>{tienda.name}  {tienda.plaza != null && <>- {tienda.plaza}</>}</h3>
                                                            <p>{tienda.direccion},</p>
                                                            {tienda.colonia != null && <p>{tienda.colonia},</p>}
                                                            <p>{tienda.estado}</p>
                                                            {tienda.telefonodirecto != null && <p>{tienda.telefonodirecto}</p>}
                                                            {tienda.tel2 != null && <p className="tel2">{tienda.tel2}</p>}
                                                            {tienda.telefonoconm != null && <p>{tienda.telefonoconm}</p>}
                                                            {tienda.tel3 != null && <p className="tel3">{tienda.tel3}</p>}
                                                            {tienda.extension != null && <p>{tienda.extension}</p>}
                                                            <p>{tienda.correo}</p>
                                                        </li>
                                                    )}
                                                </ul>
                                            </>
                                        )
                                    }

                                </ul>
                            </div>
                        }


                    </div>
                </section>

            </main>

            <Footer />
        </>

    )
}

export function AutocenterMobile() {
    const history = useHistory()

    const {
        headers,
        statusCode,
        data,
        error,
        loading
    } = useServices({
        method: 'get',
        url: `/staticJson/autocenterMovil.json`,
        timeout: 25000,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })

    let fecha = new Date()
    
    useEffect(()=>{
        if(statusCode === 200){
            if(data){
                console.log('data: ', data )
            }
        }
    },[statusCode, data])

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const settings = {
        arrows: false,
        dots: false,
        autoplay: true,
        infinite: true,
        centerMode: false,
        swipe: true,
        touchMove: true,
        slidesToShow: 1,
        speed: 700,
        responsive: [
            {
                breakpoint: 780,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: false,
                    swipe: true,
                    touchMove: true,
                    slidesToShow: 1,
                    speed: 700,
                }
            }
        ]
    };

    const handleAgendarCita = () => {
        history.push("/agendar-cita");
    };

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal contentModalPadd">

                    <div className="contentClose contentC">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Autocenter</p>
                    </div>

                    <div className="bxPF">
                        <p className="colorRed">Nuestros Servicios</p>
                    </div>

                    <div className="dataModal">
                        <div className="boxAN">

                            <div className="bxSlider">
                                <div className="boxSliderAutocenter">
                                    <Slider {...settings}>
                                        {data &&
                                            data[0]?.arrayImagenesMovil.map(({ url, id, alt }) =>
                                                <div title={alt} key={id} className="cardSliderAutocenter">
                                                    <img loading="lazy" src={`${url}?ver=${String(fecha.getFullYear())+ String(fecha.getMonth())+ String(fecha.getDate())}`} alt={alt} width="260" height="235" importance="low" />
                                                </div>
                                            )
                                        }
                                    </Slider>

                                </div>

                                <ul className="ulServMo">
                                    {data &&
                                        data[0]?.servicios.map((servicio, i) =>
                                            <>
                                                {servicio.seccion === 1
                                                    ? <div className="bxTitleSerMo colorRed" key={i}><p>{servicio.title}</p></div>
                                                    : servicio.seccion === 2
                                                        ? <div className="bxTitleSerMo colorRed" key={i}><p>{servicio.title}</p></div>
                                                        : <div className="bxTitleSerMo colorRed " key={i}><p>{servicio.title}</p></div>
                                                }

                                                <ul className="internoServMo">
                                                    {servicio.servicios.map((ser, id) =>
                                                        <li key={id} className="liServMo">
                                                            <div className="imgLi">
                                                                <img src={"/img/autocenterimg" + ser.img} alt={"servicio" + id} />
                                                            </div>

                                                            <div className="spanLi">
                                                                <span>{ser.name}</span>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </>
                                        )
                                    }

                                </ul>

                                <div onClick={handleAgendarCita} className="barraAgendar">Agenda tu cita</div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Autocenter() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina
    useEffect(() => {
        window.ineum('page', 'Autocenter page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile
                ?
                    isMobile === 'desktop'
                        ? <AutocenterDesktop />
                        : <AutocenterMobile />
                : null
                
            }
        </>
    )
}