import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { InputTypeAndroid } from 'components/Forms/InputType';
import SelectType from 'components/Forms/Select';
import Toast from 'components/Toast';
import getShipping from 'services/getShipping';
import $ from 'jquery';
import './styles.sass';
import ModalCPinvalido from '../../Modal/cpInvalido';

export default function FormAddress(...props) {
    const [directionD] = useState(...props);
    const history = useHistory();
    const url = history.location.pathname;

    const { register, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            nombre: directionD.nombre,
            telefono: directionD.telefono,
            calle: directionD.calle,
            numeroExterior: directionD.numeroExterior,
            numeroInterior: directionD.numeroInterior,
            codigoPostal: directionD.codigoPostal,
            estado: directionD.estado,
            municipio: directionD.municipio,
            colonia: directionD.colonia,
            entreCalles: directionD.entreCalles,
            referencias: directionD.referencias,
            accesibilidad: directionD.accesibilidad
        }
    });

    const [direction, setDirection] = useState();
    const [toast, setToast] = useState(false);
    const [messageToast, setMessageToast] = useState({ type: '', time: '', textMessage: '' });
    const [cpValid, setCpValid] = useState(false);
    const [shipping, setShupping] = useState({});

    const [nombre, setNombre] = useState(directionD.nombre);
    const [telefono, setTelefono] = useState(directionD.telefono);
    const [calle, setCalle] = useState(directionD.calle);
    const [numExt, setNunExt] = useState(directionD.numeroExterior);
    const [numInt, setNumInt] = useState(directionD.numeroInterior);
    const [realCP, setRealCP] = useState(directionD.codigoPostal);
    
    const [cP, setCP] = useState(directionD.codigoPostal);
    const [returnForm, SetReturnForm] = useState(false);

    const [predeterminada, setPredeterminada] = useState(directionD.accesibilidad);

    const sendFormDirection = data => {
        setDirection({
            "nombre": data.nombre,
            "telefono": data.telefono,
            "calle": data.calle,
            "numeroExterior": data.numeroExterior,
            "numeroInterior": data.numeroInterior,
            "codigoPostal": data.codigoPostal,
            "estado": data.estado,
            "ciudad": data.estado,
            "municipio": data.municipio,
            "colonia": data.colonia,
            "pais": "México",
            "entreCalles": data.entreCalles,
            "referencias": data.referencias,
            "accesibilidad": predeterminada,
            "direccion": ""
        });
        SetReturnForm(true);
    }

    const seleccionarPredeterminada = (e) => {
        setPredeterminada(!predeterminada);
    };

    useEffect(() => {
        let locacion = String(history.location.pathname).split("/");

        if (returnForm) {
            directionD.success(direction);

            if (predeterminada === true || predeterminada == "true") {
                directionD.changeDefaultA(locacion[locacion.length - 1]);
            }

            SetReturnForm(false);
        }
    }, [returnForm])

    useEffect(() => {
        if (returnForm) {
            directionD.success(direction);
            SetReturnForm(false);
        }
    }, [returnForm])

    // useEffect(() => {
    //     if (directionD.codigoPostal) {
    //         getShipping({ codigoPostal: realCP })
    //             .then(setShupping)
    //         setCpValid(false);
    //     }
    // }, [])

    useEffect(() => {
        if (directionD?.codigoPostal) {
            if (cpValid) {
                getShipping({ codigoPostal: realCP })
                    .then(setShupping)
                setCpValid(false);
            }
        }else{
            if (cpValid) {
                getShipping({ codigoPostal: realCP })
                    .then(setShupping)
                setCpValid(false);
            }
        }
    }, [cpValid])

    useEffect(() => {
        if (shipping) {
            setValue('estado', shipping.estado);
            setValue('municipio', shipping.municipio);
            setValue('colonia', shipping.colonias);
        }

        if (shipping.status == false) {
            setModalToggle(true);
            setFlagValidad(false);
        }
        if (shipping) {
            if (shipping.colonias) {
                // console.log('shipping ',shipping.colonias.length)
            }
        }
    }, [shipping])

    //-- Validates
    const valName = {
        required: 'El nombre es requerido',
        minLength: {
            value: 2,
            message: 'El nombre al menos debe de contener 2 caracteres'
        },
        maxLength: {
            value: 50,
            message: 'El nombre debe de tener máximo 50 dígitos'
        }
    };
    const valPhone = {
        required: 'El teléfono es requerido',
        minLength: {
            value: 10,
            message: 'El teléfono debe de tener 10 dígitos'
        },
        maxLength: {
            value: 10,
            message: 'El teléfono debe de tener 10 dígitos'
        },
        pattern: {
            value: /[0-9]{10}/,
            message: 'El formato de teléfono es inválido'
        }
    };
    const valStreet = {
        required: 'La calle es requerida',
        minLength: {
            value: 3,
            message: 'La calle al menos debe de contener 3 caracteres'
        },
        maxLength: {
            value: 60,
            message: 'La calle debe tener un máximo de 60 caracteres'
        }
    };
    const valStreetNum = {
        required: 'El número externo es requerido',
        minLength: {
            value: 1,
            message: 'El número externo al menos debe de contener 1 número'
        },
        maxLength: {
            value: 60,
            message: 'El número externo debe tener un máximo de 60 caracteres'
        }
    };
    const valStreetNumInt = {
        maxLength: {
            value: 60,
            message: 'El número externo debe tener un máximo de 60 caracteres'
        }
    };
    const valCP = {
        required: 'El código postal es requerido',
        pattern: {
            value: /[0-9]{5}/,
            message: 'El código postal debe de contener 5 números'
        }
    };
    const valEdo = {
        required: 'El estado es requerido',
        minLength: {
            value: 1,
            message: 'El estado es requerido'
        }
    };
    const valMunic = {
        required: 'El municipio es requerido',
        minLength: {
            value: 1,
            message: 'El estado es requerido'
        }
    };
    const valCol = {
        required: 'La Colonia es requerida',
        minLength: {
            value: 1,
            message: 'El estado es requerido'
        },
        maxLength: {
            value: 120,
            message: 'La colonia debe tener un máximo de 120 caracteres'
        }
    };

    const handleToast = () => {
        setToast(false);
    }

    useEffect(()=>{
        if (String(cP).length === 5) {
            setRealCP(cP);
            setCpValid(true);
        }
    },[cP])

    function returnBack() {
        history.goBack();
    }

    useEffect(() => {
        var inputT = $('#codigoPostal').val();

        $('.form-js-label').find('input').on('input', function (e) {
            $(e.currentTarget).attr('data-empty', !e.currentTarget.value);
        });

        if (inputT.trim().length > 0) {
            $('#estado').attr('data-empty', false);
            $('#municipio').attr('data-empty', false);
        }

        $('#colonia').attr('data-empty', false);

        if ($('#nombre').val().trim().length > 0) {
            $('#nombre').attr('data-empty', false);
        }
        if ($('#telefono').val().trim().length > 0) {
            $('#telefono').attr('data-empty', false);
        }
        if ($('#calle').val().trim().length > 0) {
            $('#calle').attr('data-empty', false);
        }
        if ($('#numeroExterior').val().trim().length > 0) {
            $('#numeroExterior').attr('data-empty', false);
        }
        if ($('#numeroInterior').val().trim().length > 0) {
            $('#numeroInterior').attr('data-empty', false);
        }
        if ($('#codigoPostal').val().trim().length > 0) {
            $('#codigoPostal').attr('data-empty', false);

            if (shipping.status == true) {
                setFlagValidad(true);
                setValue('estado', shipping.estado);
                setValue('municipio', shipping.municipio);
                // setValue('colonia', shipping.colonias);
            }
        }
        if ($('#entreCalles').val().trim().length > 0) {
            $('#entreCalles').attr('data-empty', false);
        }
        if ($('#referencias').val().trim().length > 0) {
            $('#referencias').attr('data-empty', false);
        }

    })

    const [flagValidad, setFlagValidad] = useState(false);
    const [modalToggle, setModalToggle] = useState(false); // mostrar modal

    const validarCodigoPostal = (e) => {
        e.preventDefault();

        if (shipping.status == true) {
            setFlagValidad(true);
            setValue('estado', shipping.estado);
            setValue('municipio', shipping.municipio);
            setValue('colonia', shipping.colonias);
        }
        if (shipping.status != true) {
            setFlagValidad(false);
            setModalToggle(true);
        }
    }


    /** funcion para cerrar modal */
    const closeModal = () => {
        setModalToggle(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit(sendFormDirection)} className="formTemplate">
                <h2>Datos de contacto</h2>
                <InputTypeAndroid
                    type="text"
                    name="nombre"
                    id="nombre"
                    placeholder="Nombre de quien recibe"
                    refs={register(valName)}
                    autocomplete='nombre'
                    errorMessage={errors.nombre && errors.nombre.message}
                    maxLength={50}
                    value={nombre}
                    setNumero={setNombre}
                    pattern={'numberText'}
                />

                <InputTypeAndroid
                    type="text"
                    name="telefono"
                    id="telefono"
                    placeholder="Teléfono de contacto"
                    refs={register(valPhone)}
                    autocomplete="tel"
                    errorMessage={errors.telefono && errors.telefono.message}
                    maxLength={10}
                    value={telefono}
                    setNumero={setTelefono}
                    pattern={'number'}
                    
                />

                <h2>Dirección</h2>
                <InputTypeAndroid
                    type="text"
                    name="calle"
                    id="calle"
                    placeholder="Calle"
                    refs={register(valStreet)}
                    autocomplete="address"
                    errorMessage={errors.calle && errors.calle.message}
                    value={calle}
                    setNumero={setCalle}
                    pattern={'numberText'}
                />
                <InputTypeAndroid
                    type="text"
                    name="numeroExterior"
                    id="numeroExterior"
                    placeholder="Número Exterior"
                    refs={register(valStreetNum)}
                    autocomplete="street_number"
                    errorMessage={errors.numeroExterior && errors.numeroExterior.message}
                    value={numExt}
                    setNumero={setNunExt}
                    pattern={'numberText'}
                />
                <InputTypeAndroid
                    type="text"
                    name="numeroInterior"
                    id="numeroInterior"
                    placeholder="Número Interior (opcional)"
                    refs={register(valStreetNumInt)}
                    errorMessage={errors.numeroInterior && errors.numeroInterior.message}
                    clase="AddressPage"
                    value={numInt}
                    setNumero={setNumInt}
                    pattern={'numberText'}
                />

                <InputTypeAndroid
                    type="text"
                    name="codigoPostal"
                    id="codigoPostal"
                    placeholder="Código Postal"
                    refs={register(valCP)}
                    autocomplete="postal_code"
                    // change={validateCP}
                    errorMessage={errors.codigoPostal && errors.codigoPostal.message}
                    clase="AddressPage"
                    maxLength={5}
                    value={cP}
                    setNumero={setCP}
                    pattern={'number'}
                />

                {(flagValidad === false) && <button onClick={validarCodigoPostal} className="btnPostal"> Validar código postal</button>}

                {modalToggle ? <ModalCPinvalido openModal={modalToggle} cerrarModal={closeModal} mensaje={"Código postal inválido"} clase="left" /> : null}

                <div className={flagValidad === true ? 'mostrarCampos' : 'ocultarCampos'}>
                    <InputTypeAndroid
                        type="text"
                        name="estado"
                        id="estado"
                        placeholder="Estado"
                        refs={register(valEdo)}
                        readonly="readonly"
                        errorMessage={errors.estado && errors.estado.message}
                    />
                    <InputTypeAndroid
                        type="text"
                        name="municipio"
                        id="municipio"
                        placeholder="Municipio"
                        refs={register(valMunic)}
                        readonly="readonly"
                        errorMessage={errors.municipio && errors.municipio.message}
                    />
                    {shipping &&
                        shipping.colonias &&
                        shipping.colonias.length >= 2
                        ?
                        <SelectType
                            type="text"
                            name="colonia"
                            id="colonia"
                            placeholder="Colonia"
                            refs={register(valCol)}
                            options={shipping.colonias}
                            errorMessage={errors.colonia && errors.colonia.message}
                        />
                        :
                        <InputTypeAndroid
                            type="text"
                            name="colonia"
                            id="colonia"
                            placeholder="Colonia"
                            refs={register(valCol)}
                            options={shipping.colonias}
                            readonly="readonly"
                            errorMessage={errors.colonia && errors.colonia.message}
                        />

                    }

                    <InputTypeAndroid
                        type="text"
                        name="entreCalles"
                        id="entreCalles"
                        placeholder="Entre calles (opcional)"
                        refs={register}
                        errorMessage={errors.entreCalles && errors.entreCalles.message}
                    />

                    <InputTypeAndroid
                        type="text"
                        name="referencias"
                        id="referencias"
                        placeholder="Referencias (opcional)"
                        refs={register}
                        errorMessage={errors.referencias && errors.referencias.message}
                    />

                    {directionD.longitudDirecciones && directionD.longitudDirecciones.length > 1 &&
                        <div className="contenedorFiltros">
                            <div className="seccionEstrellas sinBorde">
                                <div className="cajaEstrellas">
                                    <div className="cajaTexto predeterminada">
                                        <p> Establecer predeterminada</p>
                                    </div>

                                    <div className="cajaSwitch">
                                        <label>
                                            <input type="checkbox" className="input" name="predeterminada" id="predeterminada" onChange={seleccionarPredeterminada} checked={predeterminada === true ? true : false}></input>
                                            <div className="toggle">
                                                <span className="selector"></span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <button className={url.includes("editar") ? "mostrarCampos btn rojo" : "ocultarCampos btn rojo"}>Guardar</button>

                    <button className={!url.includes("editar") ? "mostrarCampos btn rojo" : "ocultarCampos btn rojo"}>Agregar dirección</button>

                    <button className="btn blanco" onClick={returnBack}>Regresar</button>

                </div>
            </form>
            {toast &&
                <Toast
                    type={messageToast.type}
                    time={messageToast.time}
                    onClose={handleToast}
                >
                    {messageToast.textMessage}
                </Toast>
            }
        </>
    )
}