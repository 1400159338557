import React, { useState, useEffect }  from "react"
import './style.sass'
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useHistory, useLocation } from 'react-router-dom'
import { decodeStorage } from 'functions/storageBase64'
import { powerReviewsWrite } from 'functions/powerReviewsFunction';

export function ReviewsWrite (){

    const location = useLocation()
    const history = useHistory()
    const urlActual = window.location.href
    const isLoged = decodeStorage('session_token')
    const profileData = decodeStorage('profileData');


    const [search, setSearch] = useState(false);
    useEffect(()=>{
        if(isLoged){
            powerReviewsWrite()
        }else{
            history.replace(`/login?redirect=${urlActual}`)
        }
        if(location.search){
            setSearch(location.search)
        }
    },[])

    const [email, setEmail] = useState(false);
    const [udEmal, setUdEmal] = useState(false);
    useEffect(()=>{
        if(search){
            console.log('check U...  ', search)
            setTimeout(function(){
                setEmail(document.querySelector("#pr-email_collection-input").value)
                console.log('check UD')
                setUdEmal(false)
            },2000)
        }
    },[search, udEmal])

    const [okRev, setOkRev] = useState(false);
    useEffect(()=>{
        if(search){
            if(email){
                if(email === profileData?.correo){
                    setOkRev(false)
                    console.log('check')
                }else{
                    setOkRev(true)
                    console.log('no check')
                }
            }else{
                console.log('no check2 ', email+'   '+ profileData?.correo)
                setEmail(false)
                setUdEmal(true)
            }
        }
    },[email])



    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(()=>{
        if(upCP){
            //console.log('')
        }
    },[upCP,updateCPBar]);


    return(
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionCategoryProducts">
                    <div className="container">
                        <div className="powerRevContent">
                            {!okRev
                                ? <div id="pr-write"></div>
                                : <div >El correo de la cuenta no es valido para este Review</div>
                            }
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}