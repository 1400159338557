import React, { useState, useEffect } from "react";
import Header from "pages/Header/HeaderNavegacion";
import BarraCP from "components/BarCP/BarHeaderCP";
import { useIsMobile } from "hooks/useIsMobile";
import stylePrelogin from 'pages/LoginRegistro/PreLogin/Prelogin.module.sass'

import { decodeStorage } from "functions/storageBase64";
import { usePrelogin } from "hooks/usePrelogin";

function TemplateTestInt(){
    const {
        setAction
    } = usePrelogin()

    const closeWebview = () => {
        setAction('close')
    }

    const [nombre, setNombre] = useState();
    const [email, setEmail] = useState();
    const [ruta, setRuta] = useState();
    const [cart, setCart] = useState();
    const [token, setToken] = useState();
    const [refresh, setRefresh] = useState();
    useEffect(()=>{

        let datos = decodeStorage('profileData')
        let login = decodeStorage('access_token')

        setNombre( datos?.nombre)
        setEmail( datos?.correo)
        
        setRuta( decodeStorage('rutaGet') )
        setCart( decodeStorage('idCarrito') )
        setToken( decodeStorage('access_token') )
        setRefresh( decodeStorage('session_token') )
        if(!login){
            window.location.href = "/"
        }
    },[])

    return(
        <main>
            <section className={stylePrelogin?.prelogin}>
                <div className="container">
                    <div className={stylePrelogin?.contentMisDatos2}>
                        <p>nombre: <span>{nombre}</span></p>
                        <p>email: <span>{email}</span></p>
                        <p>ruta: <span>{ruta}</span></p>
                        <p>cart: <span>{cart}</span></p>
                        <p>token: <span>{token}</span></p>
                        <p>refresh: <span>{refresh}</span></p>
                    </div>
                    <div onClick={closeWebview} className="btn rojo">cerrar webview</div>
                </div>
            </section>
        </main>
    )
}

export default function TemplateTest(){
    
    const { isMobile } = useIsMobile();

    
    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
      setUpdateCPBar(val);
    };

    const [upCP, setUpCP] = useState();
    useEffect(() => {
      if (upCP) {}
    }, [upCP, updateCPBar]);

    return(
        isMobile === "desktop"
            ?
                <>
                    <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
                    <section className="barCpResponsive">
                        <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
                    </section>
        
                    <TemplateTestInt />
                </>
            :
                <>
                    <TemplateTestInt />
                </>
    )
}