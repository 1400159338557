import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import RecoveryPassForm from 'components/RecoveryPassForm'
import Loading from 'components/Loading'
import { postRecoveryPass } from 'services/RecoveryPassword'
import { useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile'

export default function RecoveryPassword() {

    const [successForm, getSuccessForm] = useState(false)
    const [dataForm, getDataForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const { isMobile } = useIsMobile()
    const history = useHistory()

    const backHistory = () => {
        history.go(-1)
    }

    //- Funcion que manda el correo a solicitar su password
    useEffect(() => {
        if (dataForm) {
            postRecoveryPass({ email: dataForm.email })
                .then(getSuccessForm)
        }
    }, [dataForm])

    //- Remueve el modal una ves responde el servicio
    useEffect(() => {
        if (successForm) {
            setLoading(false)
            // console.log(successForm)
        }
    }, [successForm])

    // recibe los datos del formulario
    const reciveData = (val) => {
        getDataForm(val)
        setLoading(true)
    }

    return (
        <>
            {loading && <Loading />}
            {isMobile === 'desktop'
                ?
                <h1 className="titleRegistro">Recuperar contraseña</h1>
                :
                <div className="sectionTitleServicioClienteLogo">
                    <button onClick={backHistory} className="botonRegresar"></button>
                    <img src="/img/logo-sears.svg" alt="logo-sears"></img>
                </div>
            }
            {successForm
                ?
                <div className="loginPage">
                     {isMobile !== 'desktop' && <h3 className="titleGenerar">Recuperar contraseña</h3>}

                    <p className="textResponseMessagePass">Hemos enviado a tu correo las indicaciones para continuar con la recuperación de tu contraseña. <span className="text2">Recuerda revisar tu bandeja de spam.</span></p>
                    <Link to="/" className="btn rojo">Regresar a Home</Link>
                </div>
                :
                <>
                    {isMobile !== 'desktop' && <h3 className="titleGenerar">Recuperar contraseña</h3>}
                    <p className="smallText">Ingresa tu correo electrónico asociado a tu cuenta de Sears</p>
                    <RecoveryPassForm
                        dataForm={reciveData}
                    />
                </>
            }
        </>
    )
}