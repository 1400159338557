import React, { useState, useEffect } from 'react';
import './style.sass';
import { Link } from "react-router-dom";
import { NO_IMAGE } from 'constants/general';
import { useHistory } from 'react-router-dom';
import { decodeStorage} from 'functions/storageBase64';
import Header from 'pages/Header/HeaderNavegacion';

export default function CardCategory(props) {
    const {
        nombre,
        icon,
        seo,
        id_cat
    } = props;

    // console.log('props', props)

    const history = useHistory();

    const showSubCategory = () => {
        window.scrollTo(0, 0);
        history.push("/categoria/" + id_cat);
    }

    return (
        String(nombre).toLowerCase() !== "marketplace" &&
        <div className="cardCategory" >
            <div onClick={showSubCategory} className="contentCategoryFirstLevel">
                <img loading="lazy" src={icon != null ? icon : NO_IMAGE} alt={seo} width="80" height="80" />
                <p>{nombre}</p>
            </div>
        </div>
    )
}

export function CategoriasHijas() {

    const [categorias, setCategorias] = useState([]);
    const [, setLoading] = useState(true);
    const [response, setResponse] = useState([]);
    const [, setErrors] = useState('');
    const history = useHistory();

    // carga las categorias del storage
    useEffect(() => {
        if (decodeStorage('categoryTreeNew')) {
            setResponse(decodeStorage('categoryTreeNew'));
        }
        // if (decodeStorageLatin('categoryTreeNew')) {
        //     setResponse(decodeStorageLatin('categoryTreeNew'));
        // }
        // if (decodeStorage('categoryTree')) {
        //     setResponse(decodeStorage('categoryTree'));
        // }
    }, [])

    //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
    useEffect(() => {
        if (response) {
            if (response.statusCode === 200) {
                if (typeof (response.data) === 'object') {
                    setCategorias({ 'categoriasMenu': response.data });
                    setLoading(false);
                }
                else {
                    setCategorias({ });
                    setErrors({ 'error': response.statusCode, 'response': response.data.response });
                }
            }
            else if (response.statusCode) {
                setErrors({ 'error': response.statusCode, 'response': response.data });
                setLoading(false);
            }
        }
        else if (response.statusCode !== undefined) {
            setCategorias({ });
            setErrors({ 'error': response.statusCode, 'response': response.data });
            setLoading(false);
        }

    }, [response])

    // console.log('response 1', response)

    // revisa que existan las categorias para ocultar el loading
    useEffect(() => {
        if (categorias) {
            if (Object.keys(categorias).length > 0) {
                setLoading(false);
            }
        }
    }, [categorias])

    const url = history.location.pathname;
    const conversion = url.split("/");
    let lastItem = conversion[conversion.length - 1];

    //recibe la direccion del CP    
    const codigoP = decodeStorage('codigoPostal');
    const [, setCodigoPostal] = useState(codigoP);
    const [updateCPBar, setUpdateCPBar] = useState();

    const changeDirectionBar = (val) => {
        setCodigoPostal(val);
        setUpdateCPBar(val);
    };

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar]);


    const [, setNCategoria] = useState(false);
    const enviaNombre = (e) => {
        setNCategoria(e.target.id)
    }
    const [nombreCategoriaPadre, setNombreCategoriaPadre] = useState(false);
    useEffect(() => {
        if (categorias && categorias.categoriasMenu) {
            categorias.categoriasMenu.map(({ name, external_id, visible }) =>
                Number(external_id) === Number(lastItem)
                && setNombreCategoriaPadre(name)
            )
        }
    },[categorias, lastItem])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} headerEnCategoria={"headerEnCategoriaHija"} categoriaPadre={nombreCategoriaPadre} />

            <div className={"contentSubCategory show"}>
                {categorias.categoriasMenu &&
                    <>
                        {
                            //categorias.categoriasMenu.map(({ nombre, icon, seo, id_cat, subcat, estatus, visible }) =>
                            categorias.categoriasMenu.map(({ name: nombre, icon: icon, seo, external_id: id_cat, children: subcat, status: estatus, visible: visible }) =>
                                estatus !== 0 && visible !== 0 && id_cat === Number(lastItem)
                                    ? <>
                                        {(typeof (subcat) === 'object')
                                            ? <div className="subCategory">

                                                {
                                                    //subcat.map(({ nombre, icon, seo, id_cat, estatus, visible }) =>
                                                    subcat.map(({ name: nombre, icon: icon, seo, external_id: id_cat, status: estatus, visible: visible }) =>

                                                        estatus !== 0 && visible !== 0
                                                            ? <> <Link to={`/categoria/${id_cat}/${seo}/pagina=1`} className="contentCategorySecondLevel" key={id_cat} id={nombre} onClick={(e) => enviaNombre(e)}>
                                                                <img loading="lazy" src={icon != null ? icon : NO_IMAGE} alt={seo} width="80" height="80" />
                                                                <p>{nombre}</p>
                                                            </Link>
                                                            </>
                                                            : null
                                                    )}
                                            </div>
                                            : null
                                        }
                                    </>
                                    :
                                    null
                            )}
                    </>
                }
            </div>
            {/* <div className={subCat === true ? "returnBtn show " : "returnBtn"} onClick={isReturn}></div> */}
        </>
    )
}


export function CardCategoryGroup(props) {
    const {
        nombre,
        icon,
        seo,
        subcat,
        catFisrt
    } = props


    return (
        <div className="cardCategory" >
            <div className="container">
                <div className="firstLevelDesktop">
                    <Link to={`/categoria/${catFisrt}/${seo}/pagina=1`} key={catFisrt}>
                        <img loading="lazy" src={icon != null ? icon : NO_IMAGE} alt={seo} width="80" height="80" />
                        <p>{nombre}</p>
                    </Link>
                </div>
                <div className="secondLevelDesktop">
                    {(typeof (subcat) === 'object')
                        ?
                        <>
                            {
                                subcat.map(({ nombre, icon, seo, id_cat, estatus, visible }) =>

                                    estatus !== 0 && visible !== 0 && String(nombre).toLowerCase() !== "marketplace"
                                        ?
                                        <Link to={`/categoria/${id_cat}/${seo}/pagina=1`} key={id_cat}>
                                            <img loading="lazy" src={icon != null ? icon : NO_IMAGE} alt={seo} width="40" height="40" />
                                            <p>{nombre}</p>
                                        </Link>
                                        :
                                        null
                                )
                            }
                        </>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}


function CardCategoryFinishLevel(props) {
    const {
        id,
        seoSuper,
        subcat
    } = props


    return (
        <ul>
            {
                subcat.map(({ name, external_id, seo, estatus, visible }, idCount) =>
                    estatus !== 0 && visible !== 0
                        ?
                        idCount <= 3
                            ?
                            <li key={external_id}>
                                <Link to={`/categoria/${external_id}/${seo}/pagina=1`}><p>{name}</p></Link>
                            </li>
                            : idCount === 4
                                ?
                                <li key={external_id}>
                                    <Link to={`/categoria/${id}/${seoSuper}/pagina=1`} className="link">Ver más</Link>
                                </li>
                                : null
                        : null
                )
            }
        </ul>
    )

}
export function CardCategoryGroupNew(props) {
    const {
        catFisrt,
        nombre,
        icon,
        seo,
        subcat
    } = props


    return (
        <div className="cardCategory versionV1" >
            <div className="container">
                {String(seo).toLowerCase() !== "marketplace" &&
                    <div className="firstLevelDesktop">
                        <Link to={`/categoria/${catFisrt}/${seo}/pagina=1`} key={catFisrt}>
                            <img loading="lazy" src={icon != null ? icon : NO_IMAGE} alt={seo} width="80" height="80" />
                            <p>{nombre}</p>
                        </Link>
                    </div>
                }
                <div className="secondLevelDesktop">
                    {(typeof (subcat) === 'object')
                        ?
                        subcat.map(({ name, children, seo, external_id, estatus, visible }) =>
                            estatus !== 0 && visible !== 0 && String(nombre).toLowerCase() !== "marketplace"
                                ?
                                <div className="thirdLevelDesktop" key={external_id}>
                                    <Link to={`/categoria/${external_id}/${seo}/pagina=1`}><p>{name}</p></Link>
                                    <CardCategoryFinishLevel
                                        id={external_id}
                                        seoSuper={seo}
                                        subcat={children}
                                    />
                                </div>

                                :
                                null
                        )

                        : null
                    }
                </div>
            </div>
        </div>
    )
}