import React, { useState, useEffect } from 'react';
import { getPedido, postCreateTiket, putObtenerLinks, postGenerarLinks } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import Loading from 'components/Loading';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useIsMobile } from 'hooks/useIsMobile';
import { ApareceComoEntregadoFotosDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista92-apareceComoEntregadoFotos-desktop';
import { useToken } from 'hooks/useToken';
import ImageUploader from "react-images-upload";
import swal from 'sweetalert'
import { useStorage64 } from 'hooks/useStorageBase64';
import { FORMA_PAGO_REEMBOLSABLE, NO_IMAGE } from 'constants/general';
import { ModalError } from './vista4-modal';
import ModalPortal from 'components/Modal';

export function ApareceComoEntregadoFotosMovil() {

    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [cargoFotos, setCargoFotos] = useState(false);
    const [pictures, setPictures] = useState([]);
    const [ima, setima] = useState(false);

    //nueva forma de cargar imagenes
    const [pictures1, setPictures1] = useState([]);
    const [longitud1, setLongitud1] = useState();
    const [pictures2, setPictures2] = useState([]);
    const [longitud2, setLongitud2] = useState();
    const [picturesArray, setPicturesArray] = useState();

    const [storageReasonId] = useStorage64('storageIdProblem');
    const [storageActionId] = useStorage64('storageActionId');
    const [storageMotivoEntregado] = useStorage64('storageMotivoEntregado');
    const [, setStorageImgProducto] = useStorage64('storageImgProducto');
    const profileData = useStorage64('profileData');
    const [idClaro, setIdClaro] = useState(false);
    const [, setEstatusPedido] = useState(0);
    const [formaPago, setFormaPago] = useState(0);
    const [orderNumber, setOrderNumber] = useState(0);
    const [almacenarImagenes, setAlmacenarImagenes] = useState(false);
    const [loading, setLoading] = useState(false);
    const [remover, setRemover] = useState(false);
    const [crearTicket, setCrearTicket] = useState(false);
    const [showModalError, SetShowModalError] = useState(false);
    const [correoUsuario] = useStorage64('storageCorreoUsuario');

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // obtengo el id del usuario del storage
    useEffect(() => {
        if (profileData != "undefined") {
            setIdClaro(profileData[0]?.idClaro);
        }
    }, [profileData])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista92-aparece como entregado fotos-mobile" })
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                    setOrderNumber(resultadoPedido.pedido[0].order_number);

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                    )
                    let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                    setima(limpioImagen);
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);

                }, 1000);
            }
        }
    }, [resultadoPedido])


    // cerrar modal, regresa 1 en el historial
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a cual es tu motivo
    const [respuestaUpload, setRespuestaUpload] = useState(false);
    const [newArrayImages, setnewArrayImages] = useState(false);
    const [respuestaPut1, setRespuestaPut1] = useState(false);
    const [respuestaPut2, setRespuestaPut2] = useState(false);
    const [longitud, setLongitud] = useState();
    const [ruta1, setRuta1] = useState(false);
    const [ruta2, setRuta2] = useState(false);
    const [activarPut, setActivarPut] = useState(false);

    const handleContinuar = () => {
        let arrayImagenes = [];
        let unionArrays = [pictures1[pictures1.length - 1], pictures2[pictures2.length - 1]];

        // si tiene 1 imagen
        if (longitud1 === 0 || longitud2 === 0) {
            swal({
                type: 'info',
                text: "¡Aún te faltan fotos! Deben ser 2. ",
                timer: "4000",
                showConfirm: false
            })
        }
        else {
            // si tiene 2 imagenes
            setPicturesArray(unionArrays);

            if (unionArrays.length === 2) {
                unionArrays.map((ima) => {
                    // arrayImagenes.push({ "lastModified": ima[0].lastModified, "name": ima[0].name, "size": ima[0].size, "type": ima[0].type, "webkitRelativePath": ima[0].webkitRelativePath })
                    arrayImagenes.push({ "lastModified": ima[0].lastModified, "name": ima[0].name, "size": ima[0].size, "type": "jpg", "webkitRelativePath": ima[0].webkitRelativePath })
                })

                //guarda longitud para saber si son 3 o 4 imagenes
                setLongitud(arrayImagenes.length);
                setActivarPut(true);
            }
        }
    }

    //----- envio de servicio post para reservar espacio para imagenes ----------
    useEffect(() => {
        if (idClaro && orderNumber) {
            const urlPrincipio = idClaro + "/" + orderNumber + "/";
            const arrayImagenes = [urlPrincipio + "identificacion1.jpg", urlPrincipio + "identificacion2.jpg"];

            postGenerarLinks(arrayImagenes)
                .then(setRespuestaUpload);
        }
    }, [idClaro, orderNumber])

    // -------------- guardo respuesta post upload --------------
    useEffect(() => {
        if (respuestaUpload?.statusCode === 200) {
            setnewArrayImages(respuestaUpload?.data?.[`signed-urls`]);
        }
    }, [respuestaUpload])

    //** PRIMERA PETICION PUT **/ 
    useEffect(() => {
        if (activarPut === true) {
            setLoading(true);

            putObtenerLinks(
                { imagen: ruta1[0], url: newArrayImages[0] }
            )
                .then(setRespuestaPut1);
        }
    }, [activarPut])

    //** SEGUNDA PETICION PUT **/ 
    useEffect(() => {
        if (respuestaPut1.statusCode === 200) {
            setLoading(true);

            putObtenerLinks(
                { imagen: ruta2[0], url: newArrayImages[1] }
            )
                .then(setRespuestaPut2);
        }
    }, [respuestaPut1])

    //** ACCION CUANDO TIENE 2 IMAGENES LUEGO DEL PUT **/ 
    useEffect(() => {
        if (respuestaPut2.statusCode === 200) {

            setLoading(false);

            setTimeout(function () {
                setAlmacenarImagenes(true);
            }, 1000);

            setActivarPut(false);
        }

    }, [respuestaPut2])

    useEffect(() => {
        if (almacenarImagenes === true) {
            setAlmacenarImagenes(false);
            let urlParaImagen = idClaro + "/" + orderNumber + "/";
            let arrayImagenes = [];

            picturesArray.map((ima, i) =>
                // arrayImagenes.push({ "document_url": urlParaImagen + "identificacion" + (i + 1) + "." + String(ima[0].type).split("/")[1], "order": i + 1 })
                arrayImagenes.push({ "document_url": urlParaImagen + "identificacion" + (i + 1) + ".jpg", "order": i + 1 })
            )
            setStorageImgProducto(arrayImagenes);

            //------ crear ticket ------------
            let reason_id = storageReasonId;
            let order_id = orderNumber;
            let order_number = orderNumber;
            let client_id = idClaro;
            let description = storageMotivoEntregado ? storageMotivoEntregado : "";
            let ticket_type_id = 4; //siempre es 4 si es devolucion (sin ser gratis)
            let refound_details = [];
            let action_id = storageActionId? storageActionId : 0;
            let products_to_cancel = [{ "id": idProductoEstate, "return_type": null, "subsidiary_id": null }];
            let additional_details = arrayImagenes;
            let correo= correoUsuario;

            if (FORMA_PAGO_REEMBOLSABLE.includes(formaPago)) {
                setRemover(true);
               
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }
            else {
                // se guardan en storage imagenes cargadas
                setStorageImgProducto(additional_details);

                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/datos-bancarios");
            }
        }
    }, [almacenarImagenes])

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {
                    if (idProductoEstate === 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                    }
                    else if (idProductoEstate !== 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cancelacion-exitosa");
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])


    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    // almacena en el array las fotos subidas
    const onDropFrente = (picture) => {
        let ruta1 = document.querySelectorAll('input[name="upload1"]')[0].files;
        setRuta1(ruta1);
        setLongitud1(picture.length)
        setPictures1([...pictures1, picture]);
        setPictures([...pictures, picture]);
    }

    // almacena en el array las fotos subidas
    const onDropAtras = (picture) => {
        let ruta2 = document.querySelectorAll('input[name="upload2"]')[0].files;
        setRuta2(ruta2);
        setLongitud2(picture.length)
        setPictures2([...pictures2, picture]);
        setPictures([...pictures, picture]);
    }

    // revisa el valor del array de pictures para activar o desactivar el boton de continuar
    useEffect(() => {
        if ((pictures1[pictures1.length - 1] !== undefined && pictures1[pictures1.length - 1].length > 0) ||
            (pictures2[pictures2.length - 1] !== undefined && pictures2[pictures2.length - 1].length > 0)
        ) {
            setCargoFotos(true);
        }
        else {
            setCargoFotos(false);
        }
    }, [pictures1, pictures2])

    useEffect(() => {
        if (pictures1[pictures1.length - 1] !== undefined && pictures1[pictures1.length - 1].length > 0) {
            document.querySelector('.chooseFileButton').classList.add('noneButton');
            document.querySelector('.aniadePhotos').classList.add('noneButton');
        }
        else {
            document.querySelector('.chooseFileButton').classList.remove('noneButton');
            document.querySelector('.aniadePhotos').classList.remove('noneButton');
        }
    }, [pictures1])

    useEffect(() => {
        if (pictures2[pictures2.length - 1] !== undefined && pictures2[pictures2.length - 1].length > 0) {
            document.querySelector('.chooseFileButton2').classList.add('noneButton');
            document.querySelector('.aniadePhotos2').classList.add('noneButton');
        }
        else {
            document.querySelector('.chooseFileButton2').classList.remove('noneButton');
            document.querySelector('.aniadePhotos2').classList.remove('noneButton');
        }
    }, [pictures2])

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }


    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Adjuntar imágenes</p>
                    </div>

                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            {ima && ima.length > 1
                                ? <div className="contenedorImagenesMas1">
                                    <ul className='ulMas1'>
                                        {ima && ima.map((elemento, i) => i <= 2
                                            && <li key={i}>
                                                <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={50} />
                                            </li>
                                        )}
                                        {/* mas de 3 */}
                                        {ima.length > 3 && <li className="mas1"> más ({ima.length - 3})</li>}
                                    </ul>
                                </div>
                                : <div className="contenedorImagenesIgual1">
                                    <ul className='ulIgual1'>
                                        {ima && ima.map((elemento, i) =>
                                            <li key={i}>
                                                <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            }
                            <div className='tituloImagenes'><p>Identificación</p></div>
                        </div>

                        <div className="boxGeneralAdjuntar">

                            <p className='copy1'>Para el proceso de de aclaración necesitamos que nos proporciones la fotografía de una identificación del titular de la compra por ambos lados.</p>

                            <div className="boxFotos">
                                <div className='tituloporfoto'>
                                    <p>Frente</p>
                                </div>
                                <div className='boxInternoPorFoto'>
                                    <div className='referencia'>
                                        <img src='/img/identificacion-frente.png' alt='ima-frente-referencia'></img>
                                    </div>
                                    <div className='carga'>
                                        <ImageUploader
                                            withIcon={false}
                                            withPreview={true}
                                            buttonText=""
                                            onChange={onDropFrente}
                                            imgExtension={[".jpg", ".jpeg", ".png"]}
                                            maxFileSize={10485760} //{5242880}
                                            fileTypeError={"No es una extensión de archivo compatible, debe ser JPG ó PNG"}
                                            fileSizeError={"El tamaño del archivo es demasiado grande."}
                                            name="upload1"
                                            singleImage={true}
                                        />

                                        <h3 className="aniadePhotos">Sube aquí tu fotografía</h3>
                                    </div>
                                </div>


                                <div className='tituloporfoto'>
                                    <p>Atrás</p>
                                </div>
                                <div className='boxInternoPorFoto'>
                                    <div className='referencia'>
                                        <img src='/img/identificacion-atras.png' alt='ima-atras-referencia'></img>
                                    </div>
                                    <div className='carga'>
                                        <ImageUploader
                                            withIcon={false}
                                            withPreview={true}
                                            buttonText=""
                                            onChange={onDropAtras}
                                            imgExtension={[".jpg", ".jpeg", ".png"]}
                                            maxFileSize={10485760} //{5242880}
                                            fileTypeError={"No es una extensión de archivo compatible, debe ser JPG ó PNG"}
                                            fileSizeError={"El tamaño del archivo es demasiado grande."}
                                            buttonClassName={"chooseFileButton2"}
                                            name="upload2"
                                            singleImage={true}
                                        />

                                        <h3 className="aniadePhotos2">Sube aquí tu fotografía</h3>
                                    </div>
                                </div>


                            </div>

                            <p className="copy1 copyGris">Asegurate que se vean bien los datos y el producto.</p>
                            <p className="copy2 copyGris">Aceptamos jpg ó png de hasta 10 MB de tamaño.</p>

                            <div className="boxBtnContinuarFotos">
                                <button className="btnContinuarFotos" onClick={handleContinuar} disabled={cargoFotos === true ? false : true}>Continuar</button>
                            </div>
                        </div>

                        {showModalError &&
                            <ModalPortal type="modalSB" onClose={handleCloseError} >
                                <ModalError okRegresar={regresarPedidos} />
                            </ModalPortal>
                        }
                    </div>

                </div>
            </div>
        </>

    )
}

export function ApareceComoEntregadoFotos() {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <ApareceComoEntregadoFotosDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <ApareceComoEntregadoFotosMovil />
                : null
            }
        </>
    )
}