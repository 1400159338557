import React from 'react'
import './style.sass'

export default function ErrorMessageServices ({error_message}) {

    const refreshView = () =>{ window.location.reload()} 

    return(
        <section>
            <div className="content">
                <div className="errorMessageServices">
                    <img src="/img/errorService.svg" alt="Error en el sistema" width="450" height="450" loading="lazy"/>
                    <p className="titleError">¡¡Oooops!!</p>
                    <p className="bodyError">Hubo un error en el sistema, inténtalo más tarde.</p>
                    <p className="codeError">
                        <span dangerouslySetInnerHTML={{__html:error_message}}></span>
                    </p>
                    <div onClick={refreshView} className="btn rojo">Enterado</div>
                </div>
            </div>
        </section>
    )
}