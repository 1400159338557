export function urlGetFilterFunction() {

	let urlPath = window.location.pathname
	const page = urlPath.split('/').pop()
	let propsURL

	if (page?.indexOf('=') === -1) {
		let posFinal = urlPath.lastIndexOf('/')
		urlPath = urlPath.substring(posFinal, '')
	}

	if (urlPath.split('/')[1] === "categoria") {
		let seoCat = urlPath.split('/')[3]
		let seoCatForm = 'seo_Cat" : "' + urlPath.split('/')[3]
		let arrayURL = urlPath.replace(/%20/g, ' ').replace(/%7C/g, '|').replace(/\/$/, "").replace('/categoria/', '{"id_Cat":"').replace(seoCat, seoCatForm).replace(/=/g, '":"').replace(/\//g, '","') + '"}'
		propsURL = JSON.parse(arrayURL)
	}
	else if (urlPath.split('/')[1] === "tienda" || urlPath.split('/')[1] === "Tienda") {
		let seoCat = urlPath.split('/')[3]
		// console.log("seo cat: ", seoCat)
		let seoCatForm = 'seo_Cat" : "' + urlPath.split('/')[3]
		if (urlPath.split('/')[1] === "tienda") {
			let arrayURL = urlPath.replace(/%20/g, ' ').replace(/%7C/g, '|').replace(/\/$/, "").replace('/tienda/', '{"id_Cat":"').replace(seoCat, seoCatForm).replace(/=/g, '":"').replace(/\//g, '","') + '"}'
			propsURL = JSON.parse(arrayURL)
		}
		else if (urlPath.split('/')[1] === "Tienda") {
			let arrayURL = urlPath.replace(/%20/g, ' ').replace(/%7C/g, '|').replace(/\/$/, "").replace('/Tienda/', '{"id_Cat":"').replace(seoCat, seoCatForm).replace(/=/g, '":"').replace(/\//g, '","') + '"}'
			propsURL = JSON.parse(arrayURL)
		}
	}
	else if (urlPath.split('/')[1] === "resultados") {
		let arrayURL = urlPath.replace(/%20/g, ' ').replace(/%7C/g, '|').replace(/\/$/, "").replace('/resultados/', '{"').replace(/=/g, '":"').replace(/\//g, '","') + '"}'
		propsURL = JSON.parse(arrayURL)
	}

	let busqueda = propsURL.q
	if (busqueda === undefined) { busqueda = "" }

	let idtienda = propsURL.id_Cat
	if (idtienda === undefined) { idtienda = null }

	let subCategory = propsURL.seo_Cat
	if (subCategory === undefined) { subCategory = null }

	let marca = propsURL.marca
	if (marca === undefined) { marca = null }

	let precioMenor = propsURL.preciomenor
	if (precioMenor === undefined) { precioMenor = null }

	let precioMayor = propsURL.preciomayor
	if (precioMayor === undefined) { precioMayor = null }

	let discount = propsURL.descuento
	if (discount === undefined) { discount = null }

	let categoria = propsURL.categoria
	if (categoria === undefined) { categoria = null }

	let start = propsURL.estrellas
	if (start === undefined) { start = null }

	let shipping = propsURL.enviogratis
	if (shipping === undefined) { shipping = null }

	let fulfillment = propsURL.fulfillment
	if (fulfillment === undefined) { fulfillment = null }

	let orden = propsURL.orden
	if (orden === undefined) { orden = ''}//localStorage.getItem('ordenado') }
	else if (orden === "A") { orden = "A:Y" }
	else if (orden === "D") { orden = "D:Y" }

	let pagina = propsURL.pagina
	if (pagina === undefined) { pagina = 1 }

	// console.log("descuento: "+ propsURL.descuento)
	// console.log("id_Cat: "+ propsURL.id_Cat)
	// console.log("id_Store: "+ propsURL.id_Cat)
	// console.log("marca: "+propsURL.marca)
	// console.log("precio-mayor: "+propsURL.preciomenor)
	// console.log("precio-menor: "+propsURL.preciomayor)
	// console.log("categoria: "+ propsURL.categoria)
	// console.log("pagina: "+propsURL.pagina)
	// console.log("start: "+propsURL.estrellas)
	// console.log("shipping: "+propsURL.shipping)
	// console.log("fulfillment: "+ propsURL.fulfillment)
	// console.log("orden: "+ propsURL.orden)

	let searchParams = {
		busqueda: busqueda,
		idtienda: idtienda,
		subCategory: subCategory,
		discount: discount,
		marca: marca,
		precioMenor: precioMenor,
		precioMayor: precioMayor,
		categoria: categoria,
		start: start,
		shipping: shipping,
		fulfillment: fulfillment,
		page: pagina,
		order: orden
	}
	return searchParams
}