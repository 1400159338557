import React from 'react';
import './styles.sass';
import CurrencyFormat from "react-currency-format";
import BarraCP from 'components/BarCP/BarHeaderCP';

export default function ResumeCart(props) {
    const {
        reciveDirection,
        shipping,
        total_items,
        total_price,
        submitCart
    } = props;

    return (
        <div className="cardFixed resumeCart">

            <div className="generalResumen">
                <div className="seccionResumen">
                    <p>Resumen de Compra</p>
                </div>

                <div className="seccionEnvio">

                    <div className="envio">

                        {/* {shipping_Address != 0
                        ? <p>Envío a: {shipping_Address} </p>
                        :  */}
                        <>
                            <p>Envío a</p><BarraCP changeCP={reciveDirection} clase="barraCarritoDirecciones" />
                        </>
                        {/* } */}
                    </div>

                    {shipping && shipping.is_free
                        ? <p className="pEnvioGratis"> Gratis</p>
                        : null
                    }
                </div>

                <div className="seccionDetalle">
                    <p className="left">Total {Number(total_items) === 1 ? <>({total_items} producto) </> : <>({total_items} productos) </>}  </p>
                    <p className="right">
                        <CurrencyFormat
                            value={total_price % 1 === 0 ? total_price + '.001' : total_price}
                            displayType={'text'}
                            decimalScale={2}
                            FixedDecimalScale={true}
                            thousandSeparator={true} prefix={'$'} renderText={value => <>{value}</>} />
                    </p>
                </div>

            </div>

            <div className="btnResumenBx">
                <button onClick={submitCart} className="btn rojo" disabled={total_price >= 1 ? null : 'disabled'}>Continuar compra</button>
            </div>

        </div>
    )
}