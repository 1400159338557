import React, { useEffect, useState } from 'react';
import { useIsMobile } from 'hooks/useIsMobile';
import ReactDOM from 'react-dom';

export function ModalContraseniaSegura({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        logo
    } = props

    const { isMobile } = useIsMobile();

    return (
        <>
            <div className={`modal ${type}`}>
                <div className={isMobile === "desktop" ? "contentModal modalTallas " : "contentModal modalTallasM modalCSegura"}>
                    <div className={type === "full" ? "contentCloseTallas" : "contentCloseTallas"}>
                        <div className={type === "full" ? "btn closeX" : "btn closeX"} onClick={onClose}></div>

                        {isMobile !== "desktop" &&
                            <div className="imagen">
                                <img src="/img/logo-sears.svg" alt="logo-sears" className="logoModal"></img>
                            </div>
                        }
                    </div>

                    <div className="dataModalTallas">
                        <div className="modalCSegura">
                            <h1 className="titleRegistro">¿Contraseña Segura?</h1>
                            <div className="imagen">
                                <img src="/img/contraseniaSegura.svg" alt="contraseña segura"></img>
                            </div>

                            <div className="contraseniaSegura">
                                <p>Para generar una contraseña segura te recomendamos:</p>

                                <div className="bxUlSegura">
                                    <ul className="ulSegura">
                                        <li>Usar una combinación de letras, números y caracteres especiales.</li>

                                        <li>Evita caracteres idénticos y consecutivos (por ejemplo: 12345678).</li>

                                        <li>No use información personal ni contraseñas que utilices en otros sitios.</li>
                                    </ul>
                                </div>

                                <div className="">
                                    <button className="btn rojo" onClick={onClose}>Entendido</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default function ContraseniaSegura({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        logo
    } = props

    return ReactDOM.createPortal(
        <ModalContraseniaSegura onClose={onClose} type={type} title={title} logo={logo}>
            {children}
        </ModalContraseniaSegura>,
        document.getElementById('modal-root')
    )
}