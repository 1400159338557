import {STAR_YOTPO} from 'constants/general'
import { getReviewAdapterOld } from 'adapters/reviews_Adapter'
const property = {method: 'GET', mode: 'cors', cache: 'default'}

const fromApiResponseToOptins = apiResponse => {
    // const { response } = apiResponse
    // const { status } = apiResponse
    if(apiResponse){
        const {
            response: resp
        } = apiResponse

        let response = getReviewAdapterOld(resp)
        return {
            response
        }
    }
    return []
}

export default function getStarsOpinion ({idProduct}){
    //let star, sort, direction
    const apiURL = `${STAR_YOTPO}/${idProduct}/reviews.json?per_page=150&page=1`

    return fetch(apiURL, property)
    .then(res => res.json())
    .then(fromApiResponseToOptins)
    .catch(error => console.log('error', error))
}