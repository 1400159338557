import React, { useState, useEffect } from 'react';
import { getPedido, getAction } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import Loading from 'components/Loading';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useIsMobile } from 'hooks/useIsMobile';
import { ApareceComoEntregadoDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista9-apareceComoEntregado-desktop';
import { useToken } from 'hooks/useToken';
import { NO_IMAGE } from 'constants/general';
import { useStorage64 } from 'hooks/useStorageBase64';
import { decodeStorage } from 'functions/storageBase64';
import { repareTextHtml } from 'functions/repareDataService';

export function ApareceComoEntregadoMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [loading, setLoading] = useState();
    const [mensajeria, setMensajeria] = useState();
    const [, setActionId] = useStorage64("storageActionId", "");
    const idActionSeleccionada = decodeStorage("storageIdProblem");
    const [action, setAction] = useState(false);
    const [nombreProducto, setNombreProducto] = useState(false);

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((action && action.action) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, action])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    // obtengo list action id
    useEffect(() => {
        let reasonId = idActionSeleccionada ? idActionSeleccionada : 16;

        getAction(reasonId)
            .then(setAction);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista9-considera lo siguiente-mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];
        let mensajeriaData = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                        )

                        // guarda informacion sobre la mensajeria por la que se envio el producto
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && mensajeriaData.push({ "company_name": elem.tracking.company_name })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )

                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setMensajeria(mensajeriaData[0].company_name);
                        setNombreProducto(productoNombre);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);
                
                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);

                    getAction(idActionSeleccionada)
                        .then(setAction);
                }, 1000);
            }
        }
    }, [resultadoPedido])


    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    // redireccionar a agregar imagenes
    const handleContinuar = () => {
        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/no-entregado-que-quieres-hacer/fotografias");
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                            <p className="title">Considera lo siguiente</p>
                    </div>

                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            <div className="contenedorImagenesIgual1">
                                <ul className='ulIgual1'>
                                    {ima && ima.map((elemento, i) =>
                                        <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                        </li>
                                    )}
                                </ul>
                            </div>

                            {ima && ima.length > 1
                                ? <div className='tituloImagenes'>
                                    <p>Cuéntanos tu problema</p>
                                </div>
                                : <div className='tituloImagenes'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                            }
                        </div>

                        <div className='boxSolicitarDevolucion marginT60'>

                            <ul className='ulEntregado'>
                                <li>Asegúrate que ningún conocido haya recibido el paquete por ti.</li>
                                <li>Comprueba con tu guía de {mensajeria} el estatus de tu envío.</li>
                                <li>Verifica que la dirección de entrega que ingresaste haya sido correcta.</li>
                            </ul>

                            <p className='devolucionp2 textLeft'>De lo contrario te podemos ayudar iniciando una solicitud de aclaración.</p>

                            <div className="boxBtnContinuarDevolucion marginT60">
                                <button className="btnContinuarDevolucion" onClick={handleContinuar}>Iniciar Aclaración</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}


export function ApareceComoEntregado() {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <ApareceComoEntregadoDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <ApareceComoEntregadoMovil />
                : null
            }
        </>
    )
}