import {BASE_PATH_PORTAL, TIMEOUT_TIME, VARIANTS_PER_PORTAL} from 'constants/general'
import axios from 'axios'


//- Post Boletin Emarsys
export async function postBoletin (raw) {
    //const apiURL = `${BASE_PATH_PORTAL}/suscribirseBoletin/`
    //const apiURL = `https://www.sears.com.mx/suscribirseBoletin/`
    const apiURL = `/api/boletin` 

    const dataToSend = {
        correo: raw.raw.correo,
        genero: raw.raw.genero
      };

    
    const config = {
        method: 'post',
        url: apiURL,                
        headers: {                        
            'Content-Type': 'application/json'
        },                    
        data: dataToSend 
    }


    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': 'Por favor intenta mas tarde.'
        }
    })
}