import React, { useState } from 'react';
import './styleModalPedido.sass';
import ReactDOM from 'react-dom';
import '../../../../components/Modal/style.sass';

export default function ModalFiltrosPedidos({ setOcultarMfiltroPedido, setCheckedFecha, checkedFecha, handleSelect, cerrarModal }) {

    let arrayFechas = [
        { "id": 0, "name": "Todos los pedidos" },
        { "id": 7, "name": "Últimos 7 días" },
        { "id": 30, "name": "Últimos 30 días" },
        { "id": 180, "name": "Últimos 6 meses" },
        { "id": 360, "name": "Último año" }
    ]

    // filtro actual en el select
    const seleccionarFiltroFecha = (e) => {
        const fecha = e.target.value;
        setCheckedFecha(fecha);
    }

    // activar boton filtrar pedidos
    const filtrarPedidos = () => {
        handleSelect(checkedFecha);
        setOcultarMfiltroPedido(false);
    }

    return (
        ReactDOM.createPortal(
            <div className="modal full modalFiltroPedido">
                <div className="contentModal largo contenidoFiltroPedido">

                    <div className="seccionSuperiorFiltroPedido">
                        <div className="contentCloseFiltroPedido">
                            <span onClick={cerrarModal} className="btn close" > </span>
                            <p className="title" onClick={cerrarModal}>Filtrar por</p>
                            <span className="aplicarFiltro" onClick={filtrarPedidos}> Aplicar</span>
                        </div>
                    </div>

                    <div className="dataModal iteracionFiltroPedido">

                        <ul className="ulFiltroFecha" onChange={seleccionarFiltroFecha}>
                            <div className="titleFilter">
                                <p>Fecha de Pedido</p>
                            </div>

                            {arrayFechas.map((fecha, i) =>
                                <li key={fecha.id}>
                                    <input type="radio" id={fecha.id} value={fecha.id} name="fecha" checked={Number(checkedFecha) === fecha.id ? true : false}></input>
                                    <label name={fecha.id} htmlFor={fecha.id}> {fecha.name}</label>
                                </li>
                            )}
                        </ul>
                    </div>

                    <div className="botonFiltrarPedido">
                        <button className="bFiltrar" onClick={filtrarPedidos}>Aplicar</button>
                    </div>
                </div>
            </div>
            , document.getElementById('modal-root')
        )
    )
}