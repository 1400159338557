import React, { useState, useEffect } from "react";
import Header from "pages/Header/HeaderNavegacion";
import BarraCP from "components/BarCP/BarHeaderCP";
import { useIsMobile } from "hooks/useIsMobile";
import stylePrelogin from './Prelogin.module.sass'
import { usePrelogin } from "hooks/usePrelogin";
import Loading from 'components/Loading';

function TemplateMisDataos(){

    return(
        <main>
            <section className={stylePrelogin?.prelogin}>
                <div className="container">
                {/* <div className={stylePrelogin?.contentMisDatos} onClick={() => action('close')}>valor misDatos: <p>{JSON.stringify(misDatos)}</p>
                    </div> */}
                </div>
            </section>
        </main>
    )
}

export default function PreLogin(){
    
    const { isMobile } = useIsMobile();
    const [loading, setLoading] = useState(true)

    const {
        setAction,
        misDatos,
        redirect,
        finish
    } = usePrelogin()

    const closeBTN = (val) => {
        setAction(val)
    }

    useEffect(()=>{
        if(finish){
            setLoading(false)
            if(redirect === 'mesa_de_regalos'){
                window.location.replace('/Mesa-de-Regalos/')
                // window.location.replace('/test')
            }
        }
    },[finish])


    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
      setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
      if (upCP) {}
    }, [upCP, updateCPBar]);

    return(
        isMobile === "desktop"
            ?
                <>
                    {loading && <Loading />}
                    <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
                    <section className="barCpResponsive">
                        <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
                    </section>
        
                    <TemplateMisDataos misDatos={misDatos} action={closeBTN}/>
                </>
            :
                <>
                    {loading && <Loading />}
                    <TemplateMisDataos misDatos={misDatos}/>
                </>
    )
}