import TagManager from 'react-gtm-module';
import { repareTextDlayer, repareTextSimple } from 'functions/repareDataService'

const tagManagerReset = {
    dataLayerName: 'PageDataLayer',
    dataLayer: {
        ecommerce:  null 
    }
}

// Datalayer Media type
export function datalayerMedia(colorSelectUser){
    let colorModeSystemSel, colorModeUserSel, displayModeSel
    if(window.matchMedia('(prefers-color-scheme: dark)').matches){ colorModeSystemSel = "darkMode" }
    else{ colorModeSystemSel = "lightMode" }
    if(colorSelectUser){ colorModeUserSel = "darkMode" }
    else{ colorModeUserSel = "lightMode" }
    if(navigator.standalone || window.matchMedia('mqStandAlone').matches){ displayModeSel = "standalone" }
    else{ displayModeSel = "browser" }
    

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'configMedia',
            colorModeSystem: colorModeSystemSel,
            colorModeUser: colorModeUserSel,
            displayMode: displayModeSel
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Home
export function dataLayerHome(props){
    //console.log('Home-DL new: ', props)

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'viewHome',
            PageType: 'HomePage',
            seccion: 'Home'
        }
    }
    TagManager.dataLayer(tagManagerArgs)
    //TagManager.dataLayer(tagManagerReset)

    var listBanner     = [];
    var tagManagerBanner = {};    
    let countpromotionAnnouncement = 1;
    let sumpromotionAnnouncement = props.filter(function (obj) {        
        return obj.type === "promotionAnnouncement";
    }).length + 1;


    props.forEach((homeItems)=>{        
        
        if (homeItems.visible==true) {                        
            if (homeItems.items != null) {            
                //console.log('nombre global: ',homeItems.globalOrder)
                let count = 0                             
                if(homeItems.type == "promotionAnnouncement"){
                    countpromotionAnnouncement = countpromotionAnnouncement+1                          
                    //console.log("banner "+countpromotionAnnouncement)
                }
                
                homeItems.items.forEach((homeElements)=>{                                                   
                    if (homeItems.type=='carousels') {                          
                        if(homeElements.products != undefined ){    
                            homeElements.products.forEach((homeProducts)=>{  
                                count = count + 1  
                                listBanner.push({
                                    item_id: homeProducts.id,
                                    item_name: repareTextDlayer(homeProducts?.title),                                    
                                    affiliation:repareTextDlayer(homeProducts?.store?.name),
                                    item_brand: repareTextDlayer(homeProducts.brand),                                    
                                    item_category: homeProducts.categories[2]  != undefined ? repareTextDlayer(homeProducts.categories[2].name): null,
                                    item_category2: homeProducts.categories[1] != undefined ? repareTextDlayer(homeProducts.categories[1].name): null,
                                    item_category3: homeProducts.categories[0] != undefined ? repareTextDlayer(homeProducts.categories[0].name): null,                                    
                                    item_variant: homeProducts.stock == true ||  homeProducts.stock >= 1 ?  "disponible" : "agotado", 
                                    price: homeProducts.sale_price,
                                    item_list_name: "carrusel - "+repareTextDlayer(homeElements.name),
                                    item_list_id: "home",
                                    index: count,
                                    quantity: 1
                                })        
                            }) 
                        }

                    }else{                          
                        count = count + 1  
                        if((homeItems.type == "sliders" || homeItems.type == "quickAccess") && homeElements.name != undefined  ){                                                                                    
                            listBanner.push({
                                promotion_id: repareTextDlayer(homeElements.name),
                                promotion_name: 'home', 
                                creative_name: homeElements.link,
                                creative_slot:homeElements.order,
                                location_id: homeItems.type=="sliders"?"banner superior":repareTextDlayer(homeItems.type),
                                //item_list_name: homeElements.link,                                                                                                   
                                // index: homeElements.order,
                                // location_id: "Banner"+homeItems.globalOrder,
                                // nombre_modulo: homeItems.type,
                                // item_list_name: homeItems.type,
                                // link: homeElements.link,
                            })    
                        }
                        else if(homeItems.type == "promotionAnnouncement"){
                            // console.log('homeElements?.link:  ', homeElements?.link)
                            let nombrePromo
                            try{
                                nombrePromo = homeElements?.link.split("/")
                            }catch(e){
                                nombrePromo = homeElements?.link
                            }
                            listBanner.push({
                                //promotion_id: homeItems.type+homeItems.globalOrder,
                                promotion_id: nombrePromo[2] != undefined ? repareTextDlayer(nombrePromo[2]) : null,
                                promotion_name: 'home', 
                                creative_name: homeElements.link,
                                creative_slot: count,
                                location_id: countpromotionAnnouncement==sumpromotionAnnouncement ? "banner Inferior" : "banners "+countpromotionAnnouncement
                                // item_id: "Banner"+homeItems.globalOrder,
                                // item_name: 'Home',                              
                                // index: count,
                                // location_id: "Banner"+homeItems.globalOrder,
                                // nombre_modulo: homeItems.type,
                                // item_list_name: homeItems.type
                            })
                        }
                                
                           
                    }   
                        
                })      

                if(listBanner.length > 0){                                            
                    tagManagerBanner = {
                        dataLayerName: 'PageDataLayer',
                        dataLayer: {
                            event: homeItems.type=='carousels' ? 'view_item_list': 'view_promotion',
                            ecommerce: {
                                items: listBanner
                            }
                        }
                    }                            
                    TagManager.dataLayer(tagManagerReset)
                    TagManager.dataLayer(tagManagerBanner)
                    listBanner     = [];
                }
                        
              
                } //fin if (homeItems.items != null)
            
        }        

    })


}



// Datalayer Discovery
export function dataLayerDiscovery(props,seccionPage,origenBread){
    //console.log('datalayer discovery: ', props)
    //console.log('datalayer discovery origenBread:', origenBread)
    
    var listBanner     = [];
    var tagManagerBanner = {};

    if(props.length > 0){                        
        let count = 0                                                         
        props.forEach((homeProducts)=>{                                            
            count = count + 1  
            const categorias = homeProducts.category.split(">");
            listBanner.push({
                item_id: homeProducts.id,
                item_name: repareTextDlayer(homeProducts?.title),          
                affiliation:repareTextDlayer(homeProducts?.c_tienda),      
                item_brand: repareTextDlayer(homeProducts.brand),                
                item_category: categorias[0]  != undefined ? repareTextDlayer(categorias[0]): null,
                item_category2: categorias[1]  != undefined ? repareTextDlayer(categorias[1]): null,
                item_category3: categorias[2]  != undefined ? repareTextDlayer(categorias[2]): null,
                price: homeProducts.price,
                item_variant: "disponible",
                //item_list_name: categorias[0]  != undefined ? "Discovery-"+categorias[0]: "Discovery-",
                item_list_id: "home-discovery",
                item_list_name: origenBread == null || origenBread == "" ? "discovery - para ti " : 'discovery - '+repareTextDlayer(origenBread),
                index: count,
                quantity: 1
            })                             
        })    
              

        if(listBanner.length > 0){                        
            tagManagerBanner = {
                dataLayerName: 'PageDataLayer',
                dataLayer: {
                    event: 'view_item_list',
                    ecommerce: {
                        items: listBanner
                    }
                }
            }                            
            TagManager.dataLayer(tagManagerReset)
            TagManager.dataLayer(tagManagerBanner)
            listBanner     = [];
        }
        
    } 
}


// Datalayer taps slider home
export function dataLayerTapsSliderHome(homeItems, num){
	
    //console.log('datalayer taps home xxxxxzzzzz: ',homeItems)
    
    var listBanner     = [];
    var tagManagerBanner = {};
    let count = 0 

    if (homeItems != null) {                        
        listBanner.push({
            promotion_id: repareTextDlayer(homeItems.name),
            promotion_name: 'home',  
            creative_name: homeItems.link,
            creative_slot: num+1,
            location_id:"banner superior"            
        })               
    }                                        
    
    // if (homeItems.items != null) {            
    //     homeItems.items.forEach((homeElements)=>{                                                           
    //     //count = count + 1  
    //         if(homeItems.type == "sliders"  && homeElements.name != undefined  ){                    
    //             listBanner.push({
    //                 item_id: homeElements.name,
    //                 item_name: 'Home',  
    //                 //item_list_name: homeElements.link,                                                                                                   
    //                 index: homeElements.order,
    //                 location_id: "Banner"+homeItems.globalOrder,
    //                 nombre_modulo: homeItems.type,
    //                 item_list_name: homeItems.type
    //             })    
    //         } 
    //        /* else if(homeItems.type == "promotionAnnouncement"){
    //             listBanner.push({
    //                 item_id: "Banner"+homeItems.globalOrder,
    //                 item_name: 'Home',                              
    //                 index: count,
    //                 location_id: "Banner"+homeItems.globalOrder,
    //                 nombre_modulo: homeItems.type,
    //                 item_list_name: homeItems.type
    //             })
    //         }*/                                                                       
    //     }) 
    // }

    if(listBanner.length > 0){      
                         // console.log('datalayer mayor a 0 ')
        tagManagerBanner = {
            dataLayerName: 'PageDataLayer',
            dataLayer: {
                event:'select_promotion',
                ecommerce: {
                    items: listBanner
                }
            }
        }                            
        TagManager.dataLayer(tagManagerReset)
        TagManager.dataLayer(tagManagerBanner)
        listBanner     = [];
    }    
}

// Datalayer taps 
export function dataLayerTapsPA(bannerIndex, index, link, allPromotionAnnouncement){

    let numBanner = allPromotionAnnouncement.findIndex( x => x.globalOrder == bannerIndex )+2;    
    let sumpromotionAnnouncement   = allPromotionAnnouncement.length+1;
    //console.log("numbanner:  ",numBanner, "tamañlo ",sumpromotionAnnouncement, "bannerindex: ",bannerIndex)

    const tagManagerReset = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            ecommerce:  null 
        }
    }
    
    var listBanner     = [];
    var tagManagerBanner = {};

    let nombrePromo = link.split("/");
    listBanner.push({
        promotion_id: nombrePromo[2] != undefined ? repareTextDlayer(nombrePromo[2]) : null,
        promotion_name: 'home', 
        creative_name: link,
        creative_slot: index+1,
        location_id: numBanner==sumpromotionAnnouncement ? "banner Inferior" : "banners "+numBanner
    })

    if(listBanner.length > 0){                        
        tagManagerBanner = {
            dataLayerName: 'PageDataLayer',
            dataLayer: {
                event: 'select_promotion',
                ecommerce: {
                    items: listBanner
                }
            }
        }                            
        TagManager.dataLayer(tagManagerReset)
        TagManager.dataLayer(tagManagerBanner)
        listBanner     = [];
    }
    
}


// Datalayer taps home
export function dataLayerTapsQuick(index, name, link){
	   
    var listBanner     = [];
    var tagManagerBanner = {};
    
    listBanner.push({
        promotion_id: repareTextDlayer(name),
        promotion_name: 'home', 
        creative_name: link,
        creative_slot: index+1,
        location_id: "quickaccess"
    })

    if(listBanner.length > 0){                        
        tagManagerBanner = {
            dataLayerName: 'PageDataLayer',
            dataLayer: {
                event: 'select_promotion',
                ecommerce: {
                    items: listBanner
                }
            }
        }                            
        TagManager.dataLayer(tagManagerReset)
        TagManager.dataLayer(tagManagerBanner)
        listBanner     = [];
    }
    
}



// Datalayer taps carrusel home
export function dataLayerTapsCarrusel(listBanner)
{	
    console.log('sliders ',listBanner)
                
        var tagManagerBanner = {};
        let count = 0                                         
    
        if(listBanner.length > 0){                        
            tagManagerBanner = {
                dataLayerName: 'PageDataLayer',
                dataLayer: {
                    event: 'select_item',
                    ecommerce: {
                        items: listBanner
                    }
                }
            }                            
            TagManager.dataLayer(tagManagerReset)
            TagManager.dataLayer(tagManagerBanner)        
        }
        
}


// Datalayer Producto Carruseles Emarsys
export function dataLayerProductoEmarsys(props, title, seccionPage){

    //console.log('datalayer productos carrsuel  title: ', title, " seccion: ", seccionPage, " porps: ",  props)
    var listBanner     = [];
    var tagManagerBanner = {};

    if(props.length > 0){                        
        let count = 0                                                             
        props.forEach((homeProducts)=>{                                              
            count = count + 1  
            const categorias = homeProducts.category.split(">");
            listBanner.push({
                item_name: repareTextDlayer(homeProducts.title),                
                item_id: homeProducts.id,                
                affiliation:repareTextDlayer(homeProducts?.c_tienda),      
                price: homeProducts.price,
                item_brand: repareTextDlayer(homeProducts?.brand),
                item_category: categorias[0]  != undefined ? repareTextDlayer(categorias[0]): null,
                item_category2: categorias[1]  != undefined ? repareTextDlayer(categorias[1]): null,
                item_category3: categorias[2]  != undefined ? repareTextDlayer(categorias[2]): null,
                item_list_name:  repareTextDlayer(title),
                item_variant:"disponible",
                //item_list_name: "Discovery",
                item_list_id: seccionPage,
                index: count,
                quantity: 1
            })                             
        })    
              

        if(listBanner.length > 0){                        
            tagManagerBanner = {
                dataLayerName: 'PageDataLayer',
                dataLayer: {
                    event: 'view_item_list',
                    ecommerce: {
                        items: listBanner
                    }
                }
            }                            
            TagManager.dataLayer(tagManagerReset)
            TagManager.dataLayer(tagManagerBanner)
            listBanner     = [];
        }        
    } 
 
    TagManager.dataLayer(tagManagerReset)
}


// Datalayer Producto
export function dataLayerProduct(props){
    //console.log('Producto-DLs: ', props)
    const {
        id,
        title,
        sale_price,
        price,
        store,
        brand,
        categories,
        producto_estrellas,
        producto_fulfillment,
        producto_digital,
        sku,
        stage,
        catPadre,
        catHija,
        catNieta,
        variante,
        index,
        quantity
    } = props


    const tagManArg = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_item',
            PageType: 'ProductPage',
            idProducto: id,
            precioProducto: sale_price,
            categoria: categories,
            ecomm_pagetype: 'product',
            ecomm_prodid: id,
            ecomm_totalvalue: price,
            ecomm_category: categories,
            ProductID: id,
            productoFB: title,
            stageFB: stage,
            ecommerce: {
                items:[
                    {
                        item_id: id,
                        item_name: repareTextDlayer(title),                        
                        index: index,
                        //item_list_name: "DetalleProducto",
                        //item_list_id: "DetalleProducto",
                        affiliation:repareTextDlayer(store),
                        item_brand: repareTextDlayer(brand),                        
                        item_category: repareTextDlayer(catPadre),
                        item_category2: repareTextDlayer(catHija),
                        item_category3: repareTextDlayer(catNieta),
                        item_variant: "disponible",
                        price: sale_price,                        
                        quantity: 1
                        
                    }
                ],
                detail: {
                    actionField: {
                        list: 'Product'
                    },
                    products:[
                        {
                            name: title,
                            id: id,
                            price: sale_price,
                            brand: brand,
                            category: categories,
                            affiliation: store,
                            producto_estrellas: producto_estrellas,
                            producto_fulfillment: producto_fulfillment,
                            producto_digital: producto_digital,
                            dimension6: 'disponible',
                            dimension7: sale_price,
                            dimension8: sku,
                            dimension19: store,
                            dimension20: price,
                            dimension21: store,
                            dimension22: brand,
                            dimension23: categories,
                            dimension24: sku,
                            dimension25: id,
                            dimension26: title,
                            dimension27: sale_price,
                            dimension28: store,
                            dimension38: producto_estrellas,
                            dimension39: producto_fulfillment,
                            dimension40: producto_digital,
                            item_name: title,
                            item_id: id,
                            item_brand: brand,
                            item_category: catPadre,
                            item_category2: catHija,
                            item_category3: catNieta,
                            item_variant: variante,
                            index: index,
                            quantity: quantity
                        }
                    ]
                }
            }
        }  
    }
    try{
        TagManager.dataLayer(tagManagerReset)
        TagManager.dataLayer( tagManArg )
    }catch(e){
        console.log('error: ',e)
    }
}

export function dataLayerOutProduct(props){
    const {
        id,
        stock,
        message
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: message,
            id: id,
            stock: stock
        }
    }
    TagManager.dataLayer(tagManagerReset)
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Categoria
export function dataLayerCategory(props){
    //console.log('Categoria-DL c: ', props)
    const {
        idCat,
        categories,
        listIdProducts,
        results,
        listProducts,
        itemListProducts
    } = props

    var tagManagerBanner = {};
    
    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_category',
            PageType: "ListingPage",
            idCategoria: idCat,
            ecomm_pagetype: "category",
            ecomm_category: categories,
            ProductIDList: listIdProducts,
            numResultados20: results,
            categoriaClaro20: idCat,
            ecommerce:{
                currencyCode: "MXN",
                impressions: listProducts
            },
            firstResults: listIdProducts
        }
    }
    TagManager.dataLayer(tagManagerReset)
    TagManager.dataLayer(tagManagerArgs)

    if(itemListProducts.length > 0){                        
        tagManagerBanner = {
            dataLayerName: 'PageDataLayer',
            dataLayer: {
                event: 'view_item_list',
                ecommerce: {
                    items: itemListProducts
                }
            }
        }                            
        TagManager.dataLayer(tagManagerReset)
        TagManager.dataLayer(tagManagerBanner)        
    }        
}


// Datalayer Buscador
export function dataLayerBuscador(itemListProducts){
    //console.log('Bsucador-DL ssssss: ', itemListProducts)
      
    var tagManagerBanner = {};
    
    if(itemListProducts.length > 0){                        
        tagManagerBanner = {
            dataLayerName: 'PageDataLayer',
            dataLayer: {
                event: 'view_item_list',
                ecommerce: {
                    items: itemListProducts
                }
            }
        }                            
        TagManager.dataLayer(tagManagerReset)
        TagManager.dataLayer(tagManagerBanner)        
    }        

}

// Datalayer Buscador
export function dataLayerSearchTerm(busqueda){
    //console.log('Bsucaqued-DL: ', busqueda)
      
    var tagManagerBanner = {};
    
    tagManagerBanner = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'search',
            search_term: repareTextDlayer(busqueda)
        }
    }

    TagManager.dataLayer(tagManagerReset)
    TagManager.dataLayer(tagManagerBanner)          

}


// Datalayer wishlist Agrgar
export function dataLayerWishlistAdd(productWLDL){   
    //console.log('WishlistAdd-DL: ', productWLDL)
        
    var tagManagerBanner = {};
    
    if(productWLDL.length > 0){                        
        tagManagerBanner = {
            dataLayerName: 'PageDataLayer',
            dataLayer: {
                event: 'add_to_wishlist',
                ecommerce: {                    
                    items: productWLDL
                }
            }
        }                                          

        try{
            TagManager.dataLayer(tagManagerReset)
            TagManager.dataLayer(tagManagerBanner) 
            }catch(e){
                console.log('error: ',e)
            }
    }

    // const tagManagerArgs = {
    //     dataLayerName: 'PageDataLayer',
    //     dataLayer: {
    //         event: 'add_to_wishlist',
    //         ecommerce: {
    //             currency: "MXN",
    //             items: productWLDL
    //         }
    //     }  
    // }
}

// Datalayer Carrito
export function dataLayerCart(props){   
    //console.log('View Carrito-DL: ', props)
    const {
        valueCart,
        items
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_cart',
            ecommerce: {
                value: valueCart,
                currencyCode: 'MXN',
                view: {
                    products: items
                },
                items: items
            }
        }  
    }
    try{
        TagManager.dataLayer( tagManagerArgs )
    }catch(e){
        console.log('error: ',e)
    }
}

// Datalayer Carrito Agregar
export function dataLayerCartAdd(props){   
    //console.log('CarritoAdd-DL: ', props)
    const {
        item
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'add_to_cart',
            ecommerce: {
                currencyCode: 'MXN',
                add: {
                    products: item
                },
                items: item
            }
        }  
    }
    try{
        TagManager.dataLayer( tagManagerArgs )
    }catch(e){
        console.log('error: ',e)
    }
}
export function dataLayerCartAddOld(props){   
    //console.log('CarritoAdd-DL: ', props)
    const {
        item
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'addToCart',
            idProducto: item[0].id,
            ProductID: item[0].id,
            ecomm_prodid: item[0].id,
            categoria:  item[0].category,
            ecomm_category:  item[0].category,
            ecommerce: {
                currencyCode: 'MXN',
                add: {
                    products: item
                }
            }
        }  
    }
    try{
        TagManager.dataLayer( tagManagerArgs )
    }catch(e){
        console.log('error: ',e)
    }
}

// Datalayer Carrito Remover
export function dataLayerCartRemove(props){   
    //console.log('CarritoRemove-DL: ', props)
    const {
        item
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'remove_from_cart',
            ecommerce: {
                //currencyCode: 'MXN',
                //remove: {
                  //  products: item
                //},
                items: item
            }
        }  
    }
    try{
        TagManager.dataLayer(tagManagerReset)
        TagManager.dataLayer( tagManagerArgs )
    }catch(e){
        console.log('error: ',e)
    }
}

// Datalayer checkout Agrgar
export function dataLayerCheckout(props){   
    //console.log('Checkout-DL begin: ', props)
    const {
        valueCart,
        items
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'begin_checkout',
            ecommerce: {
                //value: valueCart,
                //currencyCode: 'MXN',
                //products: items,
                items: items
            }
        }  
    }
    try{
        TagManager.dataLayer(tagManagerReset)
        TagManager.dataLayer( tagManagerArgs )
    }catch(e){
        console.log('error: ',e)
    }
}

// Datalayer Login
export function dataLayerLogin(){
    //console.log('Login-DL: ', props)

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'checkoutStep1',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 1,
                        option: 'Web'
                    },
                    products: []
                }
            }
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

export function dataLayerLogout(){
    //console.log('Login-DL: ', props)

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'checkoutOption',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 5,
                        option: 'logout'
                    },
                    products: []
                }
            }
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Login
export function dataLayerUserInfo(props){
    const {
        idUser,
        userInfo
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'loginUserid',
            userId: String(idUser),
            userData: window.btoa(userInfo)
        }
    }

     const tagManagerArgsGA4 = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'login',
            userId: String(idUser),
        }
    }


    TagManager.dataLayer(tagManagerArgs)
    TagManager.dataLayer(tagManagerArgsGA4)
}


export function dataLayerShare(origen, producto){
    
    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'share',
            methodShare: origen,            
            method: origen,            
            productoShare: producto,            
        }
    }

    TagManager.dataLayer(tagManagerArgs)    
}


export function dataLayerGenerateLead(origen){
    
    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'generate_lead',
            methodShare: origen,            
            method: origen,            
        }
    }

    TagManager.dataLayer(tagManagerArgs)    
}


/* faltantes */
// Datalayer Search
export function dataLayerSearch(props){
    //console.log('Categoria-DL: ', props)
    const {
        idCat,
        categories,
        listIdProducts,
        results,
        listProducts
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_Search',
            PageType: "ListingPage",
            idCategoria: idCat,
            ecomm_pagetype: "category",
            ecomm_category: categories,
            ProductIDList: listIdProducts,
            numResultados20: results,
            categoriaClaro20: idCat,
            ecommerce:{
                currencyCode: "MXN",
                impressions: listProducts
            },
            firstResults: listIdProducts
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Store
export function dataLayerStore(props){
    //console.log('Categoria-DL: ', props)
    const {
        idCat,
        categories,
        listIdProducts,
        results,
        listProducts
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_Search',
            PageType: "ListingPage",
            idCategoria: idCat,
            ecomm_pagetype: "category",
            ecomm_category: categories,
            ProductIDList: listIdProducts,
            numResultados20: results,
            categoriaClaro20: idCat,
            ecommerce:{
                currencyCode: "MXN",
                impressions: listProducts
            },
            firstResults: listIdProducts
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}


// Datalayer Store
export function onPromoClick(id, name, creative, position){

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'promotionClick',
            ecommerce: {
                promoClick: {
                    promotions: [
                        {
                            id: id,
                            name: name,
                            creative: creative,
                            position: position
                        }
                    ]
                }
            }
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}
