import React, { useState, useEffect } from 'react';
import { getPedido, postCreateTiket, getNoTerminoComoEsperabamos } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import { ModalError } from './vista4-modal';
import ModalPortal from 'components/Modal';
import { NO_IMAGE, FORMA_PAGO_POST_PAGO } from 'constants/general';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useIsMobile } from 'hooks/useIsMobile';
import { NoterminoComoEsperabamosDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista24-NoterminoComoEspabamos-desktop';
import { useToken } from 'hooks/useToken';
import { repareTextHtml } from 'functions/repareDataService';


export function NoterminoComoEsperabamosMovil() {

    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [orderNumber, setOrderNumber] = useState(0);
    const [productos, setProductos] = useState(false);
    const [estatusPedido, setEstatusPedido] = useState(0);

    const [showModalError, SetShowModalError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idClaro, setIdClaro] = useState(false);
    const [statusProducto, setStatusProducto] = useState(0);
    const [guia, setGuia] = useState(0);
    const [saveIdStatusProducto, setSaveIdStatusProducto] = useState(false);
    const [productoUnico, setProductoUnico] = useState(false);
    const [formaPago, setFormaPago] = useState(false);
    const profileData = useStorage64('profileData');
    const [, setSaveStatus] = useStorage64('saveStatus', '');
    const [saveStatusProducto,] = useStorage64('saveStatusProducto', '');
    const [, setStorageMotivoEntregado] = useStorage64('storageMotivoEntregado', '');
    const [nombreProducto, setNombreProducto] = useState(false);
    const [ima, setima] = useState(false);

    const [, setInformacionStorageEntregado] = useStorage64("infoTicketEntregado", "");
    const [storageReasonId] = useStorage64('storageIdProblem');
    const [storageActionId] = useStorage64('storageActionId');
    const [, setGuardarDatosTicket] = useStorage64('storageDatosActualizadosTicket');
    const [crearTicket, setCrearTicket] = useState(false);
    const [remover, setRemover] = useState(false);
    const [infoNoTermino, setInfoNoTermino] = useState(false);
    const [esUnRegalo, setEsUnRegalo] = useStorage64('storageEsUnRegalo');
    const [correoUsuario] = useStorage64('storageCorreoUsuario');

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido && infoNoTermino) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, infoNoTermino])

    // obtengo el id del usuario del storage
    useEffect(() => {
        if (profileData != "undefined") {
            setIdClaro(profileData[0]?.idClaro);
        }
    }, [profileData])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i]?.indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    // get informacion de pagina
    useEffect(() => {
        getNoTerminoComoEsperabamos()
            .then(setInfoNoTermino);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista3-no termino como esperabamos-Mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let productosInterno = [];
        let producto = [];
        let productoGuia = [];
        let productoIdStatus = [];
        let imagenProducto = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                if (resultadoPedido.pedido && Number(idProductoEstate) === 0) {

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        productosInterno.push({ "id": elem.related_product_id })
                    )
                    setProductos(productosInterno);
                    setOrderNumber(resultadoPedido.pedido[0].order_number);
                    setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setFormaPago(resultadoPedido.pedido[0].payment_method.id);

                }
                // recorre por producto
                else if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {

                    if (resultadoPedido.pedido[0]) {

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && producto.push({ "id": elem.related_product_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoGuia.push({ "code": elem.tracking.code })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoIdStatus.push({ "status_id": elem.status_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem?.images[0]?.url, "id": elem.related_product_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )


                        let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                        setProductoUnico(limpiaProducto);
                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setNombreProducto(productoNombre);

                        setStatusProducto(saveStatusProducto);
                        let limpiaGuia = removeDuplicates(productoGuia, idProductoEstate);
                        setGuia(limpiaGuia[0].code);

                        setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setOrderNumber(resultadoPedido.pedido[0].order_number);
                        setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                        setSaveIdStatusProducto(productoIdStatus[0].status_id);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);

                    getNoTerminoComoEsperabamos()
                        .then(setInfoNoTermino);
                }, 2000);
            }
        }
    }, [resultadoPedido])

    // regresar pagina anterior
    const onClose = () => {
        history.goBack(-1);
    }

    const HandleContinuar = () => {

        // redirecciona a ingresar datos bancarios
        if (FORMA_PAGO_POST_PAGO.includes(formaPago)) {

            let reason_id = storageReasonId;
            let order_id = orderNumber;
            let order_number = orderNumber;
            let client_id = idClaro;
            let description = "";
            let ticket_type_id = 5; // siempre es 5, si es devolucion gratis
            let refound_details = []; // 
            let additional_details = []; // 
            let action_id = storageActionId;
            let products_to_cancel = [{
                "id": productoUnico,
                "return_type": null,
                "subsidiary_id": null
            }];

            setInformacionStorageEntregado({
                "reason_id": reason_id,
                "order_id": order_id,
                "order_number": order_number,
                "client_id": client_id,
                "description": description,
                "products_to_cancel": [{
                    "id": productoUnico,
                    "return_type": null,
                    "subsidiary_id": null
                }],
                "ticket_type_id": ticket_type_id,
                "additional_details": refound_details,
                "action_id": action_id
            });

            setEsUnRegalo(true);

            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/datos-bancarios");
        }

        else {
            // ------ crear ticket ------------
            let reason_id = storageReasonId;
            let order_id = orderNumber;
            let order_number = orderNumber;
            let client_id = idClaro;
            let description = "";
            let ticket_type_id = 5; //siempre es 5, si es devolucion gratis
            let refound_details = [];
            let action_id = storageActionId;
            let products_to_cancel = [{ "id": idProductoEstate, "return_type": null, "subsidiary_id": null }];
            let additional_details = [];
            let correo = correoUsuario;

            //crea ticket para guia MANUAL
            postCreateTiket({
                reason_id,
                order_id,
                order_number,
                client_id,
                description,
                products_to_cancel,
                ticket_type_id,
                refound_details,
                additional_details,
                action_id,
                correo
            })
                .then(setCrearTicket);

        }
    }

    // si la respuesta es ticket creado === 201 redirecciona a mis pedidos
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {
                    if (idProductoEstate === 0) {
                        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
                    }
                    else if (idProductoEstate !== 0) {
                        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">No terminó como esperábamos...</p>
                    </div>

                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            {ima && ima.length > 1
                                ? <div className="contenedorImagenesMas1">
                                    <ul className='ulMas1'>
                                        {ima && ima.map((elemento, i) => i <= 2 && <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={50} />
                                        </li>
                                        )}
                                        {/* mas de 3 */}
                                        {ima.length > 3 && <li className="mas1"> más ({ima.length - 3})</li>}
                                    </ul>
                                </div>
                                : <div className="contenedorImagenesIgual1">
                                    <ul className='ulIgual1'>
                                        {ima && ima.map((elemento, i) =>
                                            <li key={i}>
                                                <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            }
                            <div className='tituloImagenes'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                        </div>


                        <div className=''>
                            <div className='bxNoTerminoDesk'>
                                <div className='marginT60 textCenter'>
                                    <img src='/img/devolucionIcon.svg' alt='icon-devolucion' width={110}></img>
                                </div>

                                <div className='marginT60 textCenter'>
                                    {infoNoTermino && infoNoTermino.refound[0]
                                        ? <p>{infoNoTermino?.refound[0]?.message_parent}</p>
                                        : <p>Por ser un excelente cliente podrás quedarte con el producto y procesaremos la cancelación y reembolso de la compra.</p>
                                    }
                                </div>

                                <div className='bxBtnNotermino'>
                                    <button className='btnNoTermino' onClick={HandleContinuar}>Continuar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function NoterminoComoEsperabamos(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <NoterminoComoEsperabamosDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <NoterminoComoEsperabamosMovil />
                : null
            }
        </>
    )
}