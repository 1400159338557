import React, { useState, useEffect } from 'react';
import ModalCantidad from './modalQuantity';
import './style.sass';

export default function ModuleQuantity({ ...props }) {
    const {
        id,
        stock,
        cantBase = 1,
        returnNumber,
        updateProduct,
        clase
    } = props;


    const [mostrarCantidad, setMostrarCantidad] = useState(false);
    const [cantidadSeleccionada, setCantidadSeleccionada] = useState(cantBase);

    // cierra modal cantidad
    const cerrar = () => {
        setMostrarCantidad(false);
    }

    // cambia flag para mostrar modal cantidad
    const handleMostrarCantidad = () => {
        setMostrarCantidad(!mostrarCantidad);
    }

    // guarda la cantidad seleccionada
    const aceptar = (cantidad) => {
        setCantidadSeleccionada(cantidad);
    }

    const [cantProduct, setCantProduct] = useState(cantBase);

    // actualiza la cantidad de producto con la inicial
    useEffect(() => {
        setCantProduct(cantBase);
    }, [cantBase])

    useEffect(() => {
        if (cantProduct === '') {
            return setCantProduct('');
        }
        else if (cantProduct <= 1) {
            return setCantProduct(1);
        }
        else if (cantProduct >= (stock)) {
            return setCantProduct(stock);
        }
        else if (cantProduct > 99) {
            return setCantProduct(99);
        }
        else { return }

    }, [stock, cantProduct])

    //-regresa a 1 el input de cantidad cuando cambia el producto
    useEffect(() => {
        if (stock) {
            setCantidadSeleccionada(1);
        }
    }, [stock, id])

    useEffect(() => {
        returnNumber(cantProduct);
        setCantidadSeleccionada(cantProduct);
    }, [cantProduct])

    // const changeCant = evt => {
    //     if (evt.target.value === '') {
    //         setCantProduct('');
    //     }
    //     setCantProduct(evt.target.value);
    // }

    // const blurCant = evt => {
    //     if (evt.target.value === '') {
    //         setCantProduct(1);
    //     }
    //     else {
    //         setCantProduct(Math.floor(Number(cantProduct)));
    //     }
    // }

    // aumenta en 1 la cantidad de producto
    const addProduct = () => {
        if (cantProduct < 99) {
            setCantProduct(Math.floor(Number(cantProduct)) + 1);
        }
    }

    // disminuye en 1 la cantidad de producto
    const removeProduct = () => {
        if (cantProduct > 1) {
            setCantProduct(Math.floor(Number.parseFloat(cantProduct)) - 1);
        }
    }



    return (
        <>
            {clase !== true && <div className={clase ? "sinTallaColor" : "boxCantidadQ"} onClick={handleMostrarCantidad}>
                <>
                    {/* <p className="textCantidad">Cantidad: </p> */}
                    <p onClick={removeProduct} className="pmenos">-</p>
                    <label className="cantidadSeleccionada">
                        {cantidadSeleccionada}
                    </label>
                    <p onClick={addProduct} className="pmas">+</p>
                </>
            </div>
            }

            {/* {mostrarCantidad === true && clase !== true
                ? <ModalCantidad onClose={mostrarCantidad} type="" title="Cantidad" cerrar={cerrar} aceptar={aceptar} stock={stock} cantBase={cantBase} returnNumber={returnNumber} />
                : null
            } */}

            {clase === true &&
                <>
                    <label className="fieldset count">
                        <p onClick={removeProduct} className="pmenos">-</p>
                        {/* <input type="number"
                            name="cantidad"
                            onChange={changeCant}
                            onBlur={blurCant}
                            value={cantProduct}
                            pattern="[0-9]*"
                            className="number"
                            inputMode="numeric"
                            min="1"
                            max="99"
                        /> */}
                        <span className="number" onClick={updateProduct}>{cantProduct}</span>
                        <p onClick={addProduct} className="pmas">+</p>
                    </label>
                </>
            }
        </>
    )
}