import React, {useState, useEffect} from 'react'
import './style.sass'
import Header from 'pages/Header/HeaderNavegacion'
import Footer from 'pages/Footer/FooterDesktop'
import getLanding from 'services/getLandings'
import BarFilter from 'components/BarFilter'
import CardProduct from 'components/CardProduct/ClasicCard'
import Loading from 'components/Loading'
import BarraCP from 'components/BarCP/BarHeaderCP'
import { removeStorage } from 'functions/storageBase64'

export default function Landings (props) {

    //- Codigo de instana para track de pagina 
    useEffect(()=>{
        window.ineum('page', 'Landings Page');
        window.scrollTo(0, 0)

        removeStorage('wishlistAfter')
        removeStorage('productAfter')
        removeStorage('typeButton')

    },[])

    const params = props.match.params
//  const url= props.location.pathname

    const [landing, setLanding] = useState([])
    const [loading, setLoading ] = useState(true)
    const [isShowing, setIsShowing] = useState(false); //mostrar modal filtros

    useEffect(()=>{
        getLanding({landingName : params.landingName})
        .then(landing => {
            setLanding(landing)
            setLoading(false)
        })
    },[params.landingName])

    const [vista, setVista] = useState(false)

    const seEnviaVista = (vistaS) => {
        setVista(vistaS)
    }

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
	const reciveDirection = (val) =>{
		setUpdateCPBar(val)
	}
	const [upCP, setUpCP] = useState()
	useEffect(()=>{
		if(upCP){
			//console.log('')
		}
	},[upCP, updateCPBar])

    return(
        <> 
            {loading
                ? <Loading />
                : null
            }
            
            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
            <section className="barCpResponsive">
                <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
            </section>

            <main>
                <section className="contentLandings">
                    <div className="container">
                        {landing.productos
                            ? <BarFilter results={landing.productos.productosCategorias.length} disableFilters={1} isShowing={isShowing} setIsShowing={setIsShowing} seEnviaVista={seEnviaVista}/>
                            : null
                        }
                        

                        {landing.banner && landing.banner.length !== 0
                            ?
                                <div className="contBannerLanding">
                                    <img loading="lazy" src={landing.banner.contenido} alt={landing.banner.nombre} height="400" width="100%"/>
                                </div>
                            : null

                        }
                        {landing.productos
                            ?
                                <div className="contProductsLanding">
                                    {
                                        landing.productos.productosCategorias.map( ({id_producto, nombre, precio, precio_lista, Foto, descuento, nombreTienda, brand, categories},index) =>
                                            <CardProduct
                                                key={id_producto}
                                                id={id_producto}
                                                nombre={nombre}
                                                precio={precio_lista}
                                                precioLista={precio}
                                                foto={Foto}
                                                descuento={descuento}
                                                store={nombreTienda}
                                                vista={vista}
                                                brand={brand}
                                                categories={categories}
                                                index={index}
                                            />
                                        )
                                    }
                                </div>
                            : null
                        }
                    </div>
                </section>  
            </main>
            <Footer/>
        </>
    )
}