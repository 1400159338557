import React, { useState, useEffect } from 'react';
import './style.sass';
import { NavLink, useLocation, useHistory, Link } from 'react-router-dom';
import { useStorage64 } from 'hooks/useStorageBase64';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';
// import { BASE_PATH_PORTAL, PORTAL_URL } from 'constants/general';
// import Switch from 'components/Forms/Switch';
// import ReactDOM from 'react-dom';
import Header from 'pages/Header/HeaderNavegacion';
import { BASE_PORTAL, URL_SERVICIO } from 'constants/general';


export function ContentMenuPrincipal({ onClose }) {
    const { pathname } = useLocation();
    const [isLoged] = useStorage64('session_token', '');
    const [profileData] = useStorage64('profileData', '');
    // const [telefono, setTelefono] = useStorage64('telefono_usuario', '');
    // const [successVerificacion, setSuccessVerificacion] = useState(false);
    // const [siredireccionar, setSiRedireccionar] = useState(false);
    // const [swipe, setSwipe] = useState({ x: null, y: null });
    // const hostName = window.location.origin;
    const history = useHistory();

    // const touchStart = (evt) => {
    //     // const firstTouchEvent = evt.changedTouches[0]
    //     // const location = {
    //     //     x: firstTouchEvent.clientX,
    //     //     y: firstTouchEvent.clientY
    //     // }
    //     // setSwipe(location)
    //     // window.scrollTo(0, 0);

    // }

    // const touchEnd = (evt) => {
    //     // const firstTouchEvent = evt.changedTouches[0]
    //     // const location = {
    //     //     x: firstTouchEvent.clientX,
    //     //     y: firstTouchEvent.clientY
    //     // }
    //     // const final = {
    //     //     x: swipe.x - location.x,
    //     //     y: swipe.y - location.y
    //     // }

    //     // if (final.x >= 50) {
    //     //     onClose()
    //     // }
    // }

    // envia la peticion al servicio verificar si usuario ya ha comprado en claroshop
    // useEffect(() => {
    //     if (isLoged) {
    //     getVerificacionUsuarioTelmex()
    //         .then(setSuccessVerificacion)
    //     }
    //     console.log(" sucess verificacion en is loged: ", successVerificacion)
    //     return
    // }, [isLoged])

    // recibe la respuesta del servicio de verificacion de usuario ( ha comprado antes o no)
    // useEffect(() => {
    //     const resultado = []
    //     const tele = []

    //     console.log(" sucess verificacion: ", successVerificacion)
    //     if (successVerificacion) {
    //         // guarda el valor para verificar si el usuario ya ha comprado con telmex
    //         // for (let [key, value] of Object.entries(successVerificacion)) {
    //         //     if (key == "data") {
    //         //         for (let [key1, value1] of Object.entries(value)) {
    //         //             if (key1 === "data") {
    //         //                 for (let [key2, value2] of Object.entries(value1)) {
    //         //                     if (key2 === "registered_with_tmx") {
    //         //                         resultado.push(value2)
    //         //                     }
    //         //                     if (key2 === 'phone') {
    //         //                         tele.push(value2)
    //         //                     }
    //         //                 }
    //         //             }
    //         //         }
    //         //     }
    //         // }
    //         // if (resultado[0] === true) { //esta registrado true
    //         //     setTelefono(tele[0])
    //         //     setSiRedireccionar(true)
    //         //     // history.push("/mi-cuenta/mitelmex/")
    //         // }
    //         // if (resultado[0] === false) { // no ha comprado antes false
    //             setSiRedireccionar(false)
    //             // history.push("/loginMiTelmex/")
    //         // }
    //     }
    // }, [successVerificacion])

    //- Evalua si es dark mode dependiendo de la seleccion del usuario
    const [esDark, setEsDark] = useState(false);
    // useEffect(() => {
    //     if (document.body.className === "is-dark-mode") {
    //         document.querySelector('#darkMode').checked = true
    //         setEsDark(true)
    //     } else {
    //         document.querySelector('#darkMode').checked = false
    //         setEsDark(false)
    //     }
    // }, [])


    // const valChange = () => {
    //     let darkModeEval = document.querySelector('#darkMode').checked
    //     encodeStorage('darkMode', darkModeEval)
    //     if (decodeStorage('darkMode')) {
    //         document.body.classList.add('is-dark-mode')
    //         setEsDark(true)
    //     }
    //     else {
    //         document.body.classList.remove('is-dark-mode')
    //         setEsDark(false)
    //     }
    //     sessionStorage.setItem('SelectUser', true)
    // }

    const cerrar = () => {
        history.goBack(-1);
    };

    //recibe la direccion del CP    
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPostal] = useState(codigoP);
    const [updateCPBar, setUpdateCPBar] = useState();

    const changeDirectionBar = (val) => {
        setCodigoPostal(val);
        setUpdateCPBar(val);
    };

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar]);

    return (
        <>
            <Header setUpCP={setUpCP} setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} claseMas="claseMas" />

            <div className="moduleMenu">
                <div className="pestana"></div>
                <div className="contentMenu"
                // onTouchStart={touchStart} onTouchEnd={touchEnd}
                >
                    <div className="headerLogo">
                        <div className="close">
                            <button className="closeBtn" onClick={cerrar}></button>
                        </div>
                        <div className="img">
                            {decodeStorage('darkMode') === true || esDark === true
                                ? <img src="/img/menuMobile/logoSearsBlanco.png" alt="logo-sears" />
                                : <img src="https://resources.sears.com.mx/medios-plazavip/swift/v1/Sears/assets/css/vector/logo-sears.svg" alt="logo-sears" />
                            }

                        </div>
                    </div>

                    <div className="headerModule">
                        {isLoged && profileData
                            ? <div className="goMiCuenta">
                                <NavLink to="/mi-cuenta" title="Mi cuenta" className="goToMiCuenta">
                                    <p>¡Hola {profileData.nombre}!</p>
                                    <span>{profileData.correo}</span>
                                </NavLink>
                            </div>
                            : <div>
                                <NavLink to="/login" className="goToLogin" title="Inicia Sesión o Regístrate" > Inicia sesión o Regístrate </NavLink>
                            </div>
                        }
                    </div>

                    <div className="contentSubSeccionMenu">
                        <div className="contSectionMenu">
                            <ul>
                                {pathname !== '/'
                                    ? <li><NavLink to="/" exact className="icoInicio" title="Inicio" activeClassName="active">Inicio</NavLink></li>
                                    : <li><NavLink to="/" exact className="icoInicio" onClick={onClose} title="Inicio" activeClassName="active">Inicio</NavLink></li>
                                }
                            </ul>
                        </div>

                        {isLoged
                            && <div className="contSectionMenu">
                                <ul>
                                    {isLoged
                                        ? pathname !== '/mi-cuenta/mis-pedidos/'
                                            ? <li><NavLink exact to="/mi-cuenta/mis-pedidos/dias=30/pagina=1" className="icoPedidos" title="Mis pedidos" activeClassName="active">Mis Pedidos</NavLink></li>
                                            : <li><NavLink exact to="/mi-cuenta/mis-pedidos/dias=30/pagina=1" className="icoPedidos" onClick={onClose} title="Mis pedidos" activeClassName="active">Mis Pedidos</NavLink></li>
                                        : null
                                    }

                                    {isLoged
                                        ? pathname !== '/Credito-Sears/'
                                            ? <li>
                                                <a href={BASE_PORTAL + "/Credito-Sears/"} className="icoCredito" title="Mi Crédito Sears" activeClassName="active">Mi Crédito Sears</a>
                                                {/* <NavLink to="/Credito-Sears/" className="icoCredito" title="Mi Crédito Sears" activeClassName="active">Mi Crédito Sears</NavLink> */}
                                            </li>
                                            : <li>
                                                <a href={BASE_PORTAL + "/Credito-Sears/"} onClick={onClose} className="icoCredito" title="Mi Crédito Sears" activeClassName="active">Mi Crédito Sears</a>
                                                {/* <NavLink to="/Credito-Sears/" className="icoCredito" onClick={onClose} title="Mi Crédito Sears" activeClassName="active">Mi Crédito Sears</NavLink> */}
                                            </li>
                                        : null
                                    }
                                    {isLoged
                                        ? pathname !== '/mi-cuenta/listadeseos/'
                                            ? <li><NavLink exact to="/mi-cuenta/listadeseos/" className="icoWishlist" title="Mi Lista de deseos" activeClassName="active">Mi lista de deseos</NavLink></li>
                                            : <li><NavLink exact to="/mi-cuenta/listadeseos/" className="icoWishlist" onClick={onClose} title="Mi Lista de deseos" activeClassName="active">Mi lista de deseos</NavLink></li>
                                        : null//<li><NavLink to={`/login?redirect=${hostName}/mi-cuenta/listadeseos/`} className="icoWishlist" title="Mi Lista de deseos">Mi Lista de Deseos</NavLink></li>
                                    }

                                    {isLoged
                                        ? pathname !== '/mi-cuenta/mis-direcciones'
                                            ? <li><NavLink exact to="/mi-cuenta/direcciones-envio/" className="icoDirecciones" title="Mis direcciones" activeClassName="active">Mis direcciones</NavLink></li>
                                            : <li><NavLink exact to="/mi-cuenta/direcciones-envio/" className="icoDirecciones" onClick={onClose} title="Mis direcciones" activeClassName="active">Mis direcciones</NavLink></li>
                                        : null
                                    }

                                    {/* {isLoged
                                        ? pathname !== '/mi-cuenta/mis-formas-pago'
                                            ? <li><NavLink exact to="/mi-cuenta/mis-formas-pago/" className="icoFormasPago" title="Mis Formas de Pago" activeClassName="active">Mis Formas de Pago</NavLink></li>
                                            : <li><NavLink exact to="/mi-cuenta/mis-formas-pago/" className="icoFormasPago" onClick={onClose} title="Mis Formas de Pago" activeClassName="active">Mis Formas de Pago</NavLink></li>
                                        : null
                                    } */}

                                    {/* {isLoged
                                        ? pathname !== '/micuenta'
                                            ? <li><NavLink exact to="/mi-cuenta" className="icoMiCuenta" title="Mi Cuenta" activeClassName="active">Mi Cuenta</NavLink></li>
                                            : <li><NavLink exact to="/mi-cuenta" className="icoMiCuenta" onClick={onClose} title="Mi Cuenta" activeClassName="active">Mi Cuenta</NavLink></li>
                                        : null
                                    } */}
                                </ul>
                            </div>
                        }

                        {isLoged ?
                            <div className="contSectionMenu">
                                <ul>
                                    {pathname !== '/categoria'
                                        ? <li><NavLink to="/categoria" className="icoCategoria" title="Departamentos" activeClassName="active">Departamentos</NavLink></li>
                                        : <li><NavLink to="/categoria" className="icoCategoria" onClick={onClose} title="Departamentos" activeClassName="active">Departamentos</NavLink></li>
                                    }

                                    {pathname !== '/localizador-tiendas'
                                        ? <li><NavLink to="/localizador-tiendas" className="icoTienda" title="Encuentra tu tienda" activeClassName="active">Encuentra tu tienda</NavLink></li>
                                        : <li><NavLink to="/localizador-tiendas" className="icoTienda" onClick={onClose} title="Encuentra tu tienda" activeClassName="active">Encuentra tu tienda</NavLink></li>
                                    }

                                    {pathname !== '/Mesa-de-Regalos/'
                                        ?
                                            <li>
                                                <a href="/Mesa-de-Regalos/" className="icoMesa" title="Mesa de Regalos">Mesa de Regalos</a>
                                                {/* <Link to="/Mesa_Regalos?utm_source=direct&utm_medium=menu-superior-home-sears-com&utm_campaign=mesa_de_regalos" className="icoMesa" title="Mesa de Regalos" activeClassName="active">Mesa de Regalos</Link> */}
                                            </li>
                                        :
                                            <li>
                                                <a href="/Mesa-de-Regalos/" className="icoMesa" onClick={onClose} title="Mesa de Regalos">Mesa de Regalos</a>
                                                {/* <Link to="/Mesa_Regalos?utm_source=direct&utm_medium=menu-superior-home-sears-com&utm_campaign=mesa_de_regalos" className="icoMesa" onClick={onClose} title="Mesa de Regalos" activeClassName="active">Mesa de Regalos</Link> */}
                                            </li>
                                    }

                                    {pathname !== '/servicios'
                                        ? <li>                                           
                                            <a href={URL_SERVICIO} className="icoServicios" title="Servicios"  rel="noopener noreferrer" >Servicios</a>
                                            {/* <NavLink to="/servicios/" className="icoServicios" title="Servicios" activeClassName="active">Servicios</NavLink> */}
                                        </li>
                                        : <li>
                                            <a href={URL_SERVICIO} className="icoServicios" onClick={onClose} title="Servicios" activeClassName="active"  rel="noopener noreferrer">Servicios</a>
                                            {/* <NavLink to="/servicios/" className="icoServicios" onClick={onClose} title="Servicios" activeClassName="active">Servicios</NavLink> */}
                                        </li>
                                    }

                                </ul>
                            </div>
                            : <div className="contSectionMenu">
                                <ul>
                                    {pathname !== 'https://www.sears.com.mx/Credito-Sears/'
                                        ? <li>
                                            <a href={BASE_PORTAL + "/Credito-Sears/"} className="icoCredito" title="Mi Crédito Sears" activeClassName="active">Mi Crédito Sears</a>
                                            {/* <NavLink to="/Credito-Sears/" className="icoCredito" title="Mi Crédito Sears" activeClassName="active">Mi Crédito Sears</NavLink> */}
                                        </li>
                                        : <li>
                                            <a href={BASE_PORTAL + "/Credito-Sears/"} onClick={onClose} className="icoCredito" title="Mi Crédito Sears" activeClassName="active">Mi Crédito Sears</a>
                                            {/* <NavLink to="/Credito-Sears/" className="icoCredito" onClick={onClose} title="Mi Crédito Sears" activeClassName="active">Mi Crédito Sears</NavLink> */}
                                        </li>
                                    }

                                    {pathname !== '/categoria'
                                        ? <li><NavLink to="/categoria" className="icoCategoria" title="Departamentos" activeClassName="active">Departamentos</NavLink></li>
                                        : <li><NavLink to="/categoria" className="icoCategoria" onClick={onClose} title="Departamentos" activeClassName="active">Departamentos</NavLink></li>
                                    }

                                    {pathname !== '/Mesa-de-Regalos/'
                                        ?
                                            <li>
                                                <a href="/Mesa-de-Regalos/" className="icoMesa" title="Mesa de Regalos">Mesa de Regalos</a>
                                                {/* <Link to="/Mesa_Regalos?utm_source=direct&utm_medium=menu-superior-home-sears-com&utm_campaign=mesa_de_regalos" className="icoMesa" title="Mesa de Regalos" activeClassName="active">Mesa de Regalos</Link> */}
                                            </li>
                                        :
                                            <li>
                                                <a href="/Mesa-de-Regalos/" className="icoMesa" onClick={onClose} title="Mesa de Regalos">Mesa de Regalos</a>
                                                {/* <Link to="/Mesa_Regalos?utm_source=direct&utm_medium=menu-superior-home-sears-com&utm_campaign=mesa_de_regalos" className="icoMesa" onClick={onClose} title="Mesa de Regalos" activeClassName="active">Mesa de Regalos</Link> */}
                                            </li>
                                    }

                                    {pathname !== '/localizador-tiendas'
                                        ? <li><NavLink to="/localizador-tiendas" className="icoTienda" title="Encuentra tu tienda" activeClassName="active">Encuentra tu tienda</NavLink></li>
                                        : <li><NavLink to="/localizador-tiendas" className="icoTienda" onClick={onClose} title="Encuentra tu tienda" activeClassName="active">Encuentra tu tienda</NavLink></li>
                                    }

                                    {pathname !== '/servicios'
                                        ? <li>
                                            {/* <NavLink to="/servicios" className="icoServicios" title="Servicios" activeClassName="active">Servicios</NavLink> */}
                                            <a href={URL_SERVICIO} className="icoServicios" title="Servicios" activeClassName="active" rel="noopener noreferrer">Servicios</a>
                                        </li>
                                        : <li>
                                            {/* <NavLink to="/servicios" className="icoServicios" onClick={onClose} title="Servicios" activeClassName="active">Servicios</NavLink> */}
                                            <a href={URL_SERVICIO} className="icoServicios" onClick={onClose} title="Servicios" activeClassName="active" rel="noopener noreferrer">Servicios</a>
                                        </li>
                                    }
                                    <li>
                                        <a 
                                            href="https://serviciosgfi.inbursa.com:4070/multiplataforma/mvc/others/autotalPublico/init?ca=PUBLICO&pa=P&pro=SEARS#no-back-button" 
                                            className="sinIco"
                                            activeClassName="active" 
                                            target="blank_" 
                                            rel="noopener noreferrer" 
                                            >Seguros</a>
                                    </li>
                                       
                                </ul>
                            </div>
                        }

                        <div className="contSectionMenu">
                            <ul>
                                {/* /testModules */}
                                <li><NavLink to="/servicios-cliente" className="icoAyuda" title="¿Necesitas ayuda?">¿Necesitas ayuda?</NavLink></li>

                                {isLoged
                                    ? pathname !== '/ajustes'
                                        ? <li><NavLink to="/ajustes" className="icoAjustes" title="Ajustes">Ajustes</NavLink></li>
                                        : <li><NavLink to="/ajustes" className="icoAjustes" onClick={onClose} title="Ajustes">Ajustes</NavLink></li>
                                    : null
                                }
                            </ul>
                        </div>
                       
                        <div className="contSectionMenu ultimaSection">
                            <ul>
                                <li>
                                    <NavLink to="/aviso-privacidad" className="icoPolitica" title="Aviso de Privacidad Clientes">Aviso de Privacidad Clientes</NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="contSectionMenu ultimaSection">
                            <ul>
                                <li>
                                    <NavLink to="/acerca-sears" className="icoAcerca" title="Acerca de Sears">Acerca de Sears</NavLink>
                                </li>
                            </ul>
                        </div>


                        {/* 
                    <div className="contSectionMenu">
                        <ul>
                            <li>
                                <Switch title="Modo oscuro" name="darkMode" position="right" type="darkMode" change={valChange} />
                            </li>
                        </ul>
                    </div> */}
                    </div>

                </div>
                <div className="backgroundMenu" onClick={onClose}></div>
            </div>
        </>
    )
}

export default function MenuPrincipal({ onClose }) {
    return (
        //  ReactDOM.createPortal(
        <ContentMenuPrincipal onClose={onClose} />
        //,
        // document.getElementById('modal-root')
    )
}