import { BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL, BASE_PATH_API_DEV, STAGE_DIRNAME } from 'constants/general';
import axios from 'axios';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';

// login ayuda-dinamica
export async function LoginAyudaDinamica({ username, password, publicKey }) {
    const idSesionToken = decodeStorage('session_token');
    const apiURL = BASE_PATH_API + '/app/auth/login';
    const raw = JSON.stringify({ "username": username, "password": password, "publicKey": publicKey })

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res
            // console.log("servicio login token ------>", data.data.access_token)
            encodeStorage('accessToken', data.data.access_token);
            return data
        })
        .catch(error => {
            // console.log(error.request.status)
            if (error.message === 'Network Error') {
                // console.log('Error de conexión')
            }
            else if (error.request) {
                // console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                // console.log('request 0')
            } else {
                if (error.response.status === 401) {
                    // console.log(error.response.data.msg)
                    return { error: error.response.status, message: error.response.data.msg }
                }
                else if (error.response.status === 404) {
                    // console.error(error.response);
                    // console.log('request 404')
                }
                else if (error.response.status === 440) {
                    // console.log('request 440')
                }
                else if (error.response.status === 500) {
                    // console.error(error.response);
                    // console.log('request 500')
                    return (error.response)
                }
                else {
                    // console.error(error.response.status);
                    // console.error(error.response);
                }
            }
        })
}

// obtener bancos
export async function getBanks() {
    const idSesionToken = decodeStorage('session_token');
    const accessToken = "Bearer " + decodeStorage("access_token");
    // const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/banks?token=${idSesionToken}`;
    const apiURL = `${BASE_PATH_API}/tickets/banks`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: bancos } = data
            if (status === 200) {
                if (bancos) {
                    return {
                        bancos,
                        status
                    }
                }
                else {
                    return {
                        'error': '404',
                        'response': data,
                        'statusCode': status
                    }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// obtener razones de cancelacion
export async function getReasons() {
    // console.log(" decode--> ", decodeStorage("accessToken"))
    const accessToken = "Bearer " + decodeStorage("access_token");
    // const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/reason`;
    const apiURL = `${BASE_PATH_API}/tickets/reason`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res
            const { data: razones } = data
            if (status === 200) {
                if (razones) {
                    return {
                        razones,
                        status
                    }
                }
                else {
                    return {
                        'error': '404',
                        'response': data,
                        "statusCode": status
                    }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// obtener copys pagina solicitar recoleccion
export async function getCopysRecoleccion() {

    const idSesionToken = decodeStorage('session_token');
    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/tickets/shipment-information`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res
            const { data: informacion } = data
            if (status === 200) {
                if (informacion) {
                    return {
                        informacion,
                        status
                    }
                }
                else {
                    return {
                        'error': '404',
                        'response': data,
                        "statusCode": status
                    }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// obtener copys pagina solicitar devolucion
export async function getCopysDevolucion() {

    const idSesionToken = decodeStorage('session_token');
    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/tickets/return-information`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res
            const { data: informacion } = data
            if (status === 200) {
                if (informacion) {
                    return {
                        informacion,
                        status
                    }
                }
                else {
                    return {
                        'error': '404',
                        'response': data,
                        "statusCode": status
                    }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// obtener pedido por id
export async function getPedido(id) {
    const idSesionToken = decodeStorage('session_token');
    const accessToken = "Bearer " + decodeStorage("access_token");
    //const apiURL = `${BASE_PATH_API}/app/v1/orders/${id}?token=${idSesionToken}`;
    const apiURL = `${BASE_PATH_API}/app/v1/orders/${id}`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        Authorization: accessToken
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: pedido } = data
            if (status === 200) {
                if (pedido) {
                    return {
                        pedido,
                        status
                    }
                }
                else {
                    return {
                        'error': '404',
                        'response': data,
                        "statusCode": status
                    }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// GET PARA OBTENER EL TIPO DE RETORNO : DEVOLUCION EN TIENDA - SOLICITAR RECOLECCION
export async function getTipoRetorno() {
    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/tickets/return-type`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res
            const { data: TipoRetorno } = data
            if (status === 200) {
                if (TipoRetorno) {
                    return {
                        TipoRetorno,
                        status
                    }
                }
                else {
                    return {
                        'error': '404',
                        'response': data,
                        "statusCode": status
                    }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// post crear ticket
export async function postCreateTiket({ reason_id, order_id, order_number, client_id, description, products_to_cancel, ticket_type_id, refound_details, additional_details, action_id, correo }) {
    const accessToken = decodeStorage('access_token'); 
    const apiURL = BASE_PATH_API + '/tickets/index';

    const raw = JSON.stringify({
        "reason_id": reason_id, "order_id": order_id, "order_number": order_number, "client_id": client_id,
        "description": description, "products_to_cancel": products_to_cancel, "ticket_type_id": ticket_type_id,
        "refound_details": refound_details, "additional_details": additional_details, "action_id": action_id, "source": 1,
        "email": correo
    })

    console.log("lo que se envia al servicio:", raw);

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + accessToken
            // ,'source': 1
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res
            console.log("lo que devuelve el servicio", data);
            return data
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error?.request?.status,
                'data': error?.request?.response,
                'error': error?.response?.data?.msg
            }
        })
}

// post para generar la guia
export async function postGenerarGuia({ email, related_product, ticket_id, subsidiary_id, seller_id, return_type_id }) {
    const accessToken = decodeStorage('access_token');
    const apiURL = BASE_PATH_API + '/tickets/tracking';


    const raw = JSON.stringify({
        "email": email,
        "related_product": related_product,
        "ticket_id": ticket_id,
        "subsidiary_id": subsidiary_id,
        "seller_id": seller_id,
        "return_type_id": return_type_id,
        "source": 1
    })

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + accessToken
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res

            return data
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })

}

// get obtener accion 
export async function getAction(idReason) {
    const accessToken = "Bearer " + decodeStorage("access_token");
    // const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/action?reason_id=${idReason}`;
    const apiURL = `${BASE_PATH_API}/tickets/action?reason_id=${idReason}`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: action } = data
            if (status === 200) {
                if (action) {
                    return { action, status }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

export async function postVerificacionDevolucion({ idPedido }) {
    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = BASE_PATH_API + '/tickets/price-rule';

    const raw = JSON.stringify({
        "order_number": idPedido
    });

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res

            return data
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error?.request?.status,
                'data': error?.request?.response,
                'error': error?.response?.data?.msg
            }
        })

}
// get refound info / informacion de reembolso
export async function getRefoundInfo(id) {
    const accessToken = "Bearer " + decodeStorage("access_token");
    // const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/refound-info/${id}`;
    const apiURL = `${BASE_PATH_API}/tickets/refound-info/${id}`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };
    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: refound } = data
            if (status === 200) {
                if (refound) {
                    return { refound }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// get parcel / paqueterias
export async function getParcel() {
    const accessToken = "Bearer " + decodeStorage("access_token");
    // const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/parcel`;
    const apiURL = `${BASE_PATH_API}/tickets/parcel`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };
    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: parcel } = data
            if (status === 200) {
                if (parcel) {
                    return { parcel }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}


// servios upload imagenes
export async function postGenerarLinks(arrayImagenes) {

    const accessToken = decodeStorage('access_token');

    let apiURL = null
    if (STAGE_DIRNAME === "dev") {
        apiURL = `${BASE_PATH_API_DEV.uploadImage}/gs-s3-adm/v1/document-links`;
    } else {
        apiURL = `${BASE_PATH_API}/gs-s3-adm/v1/document-links`;
    }

    const raw = { "upload-documents": arrayImagenes }

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + accessToken
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                headers,
                data,
                status
            } = res

            return {
                "headers": headers,
                "data": data,
                "statusCode": status
            }
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.data,
                'error': error.response.data.msg
            }
        })
}

export async function putObtenerLinks(props) {
    const {
        url,
        imagen
    } = props;

    let apiURL = `${url}`;

    const config = {
        method: 'put',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        headers: {
            'Content-Type': `application/binary, ${imagen?.type}`

        },
        data: imagen
    };

    return await axios(config)
        .then(res => {
            const {
                headers,
                status,
                data

            } = res

            return {
                'headers': headers,
                'statusCode': status,
                'data': data,
                'error': ''
            }

        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error?.request?.status,
                'data': error?.request?.response,
                'error': error?.response?.data?.msg
            }
        })
}

//obtiene la bandera para saber el numero de veces que un cliente ha devuelto un producto < 200
export async function getVecesDevuelto(numPedido) {
    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${VARIANTS_PER_PORTAL.ambienteAxxi}/pc/ticket/price-rule/${numPedido}`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        Authorization: accessToken
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: pedido } = data
            if (status === 200) {
                if (pedido) {
                    return {
                        pedido,
                        status
                    }
                }
                else {
                    return {
                        'error': '404',
                        'response': data,
                        "statusCode": status
                    }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })

}

//obtiene informacion para vista no termino como esperabamos
export async function getNoTerminoComoEsperabamos() {

    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/tickets/info/nice_client`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };
    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: refound } = data
            if (status === 200) {
                if (refound) {
                    return { refound }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })

}

//obtiene informacion para vista no funciona
export async function getNoFunciona() {

    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/tickets/info/not_working`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };
    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: refound } = data
            if (status === 200) {
                if (refound) {
                    return { refound }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })

}

//obtiene informacion para vista solicitar garantia
export async function getSolicitarGarantia() {

    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/tickets/info/warranty`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };
    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: refound } = data
            if (status === 200) {
                if (refound) {
                    return { refound }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })

}

// THANK YOU PAGE 
export async function postTYP({ ticket_id, product_id, assosiated_address, tracking_data }) {

    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = BASE_PATH_API + '/tickets/thankyou'; 

    let raw = "";

    if(!tracking_data){
        console.log("Entra sin traquin");
        raw = JSON.stringify({
            "ticket_id": ticket_id,
            "product_id": product_id,
            "assosiated_address": {
                "street": assosiated_address.street,
                "postal_code": assosiated_address.postal_code,
                "place_name": assosiated_address.place_name,
                "suburb": assosiated_address.suburb,
                "city": assosiated_address.city,
                "town": assosiated_address.town,
                "phone": assosiated_address.phone
            }
        });
    }else{
        console.log("Entra con traquin");
        raw = JSON.stringify({
            "ticket_id": ticket_id,
            "product_id": product_id,
            "assosiated_address": {
                "street": assosiated_address.street,
                "postal_code": assosiated_address.postal_code,
                "place_name": assosiated_address.place_name,
                "suburb": assosiated_address.suburb,
                "city": assosiated_address.city,
                "town": assosiated_address.town,
                "phone": assosiated_address.phone
            },
            "tracking_data": {
                "company_name": tracking_data?.company_name
            }
        });
    }

    console.log("postTYP_BASE", raw);

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res

            console.log("postTYP_BASE data: ",data)
            return data
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error?.request?.status,
                'data': error?.request?.response,
                'error': error?.response?.data?.msg
            }
        })
}

// informacion seller 
export async function getInformacionSeller() {

    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = BASE_PATH_API + '/tickets/info/seller_help';

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: informacion } = data
            if (status === 200) {
                if (informacion) {
                    return { informacion }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error?.request?.status,
                'data': error?.request?.response,
                'error': error?.response?.data?.msg
            }
        })
}