import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { MailComplete } from 'services/MailComplete'
import { BASE_PATH_RESOURCES } from 'constants/general'
import {useHistory} from 'react-router-dom'

export function MailsCorreoCompleto() {
    const history = useHistory()
    const location = useLocation()

    const [responseData, setResponseData] = useState()
    const [mailData, setMailData] = useState()
    const [mailMessage, setMailMessage] = useState()

    useEffect(()=>{
        if(location.search?.indexOf('?id=bk_') >= 0){
            MailComplete({ id: location.search.replace('?hs_email=bk_','').replace('?id=bk_',''), bk: true })
            .then(setResponseData)
        }else{
            MailComplete({ id: location.search.replace('?id=',''), bk: false  })
            .then(setResponseData)
        }
    },[])

    useEffect(()=>{
        console.log('responseData: ',responseData)
        if(responseData){
            if(responseData.data){
                setMailData( responseData.data.replace('<body style="background-color: #f7f7f7;" align="center">', '').replace('</body>','').replace('http://medios.plazavip.com/publicidad/', `${BASE_PATH_RESOURCES}/medios-plazavip/mkt/`) )
            }else{
                if(responseData?.message){
                    //let message = JSON.parse(responseData.message)
                    //setMailMessage(message.message)
                    history.replace('/404')
                }else{
                    history.replace('/404')
                }
            }
        }
    },[responseData])

    return (
        mailData 
            ? <div dangerouslySetInnerHTML={{ __html: mailData }} className="mailContent"></div>
            : <div>{mailMessage}</div>
    )
}