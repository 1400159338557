import React, { useState, useEffect } from 'react'
import './style.sass'
import { RecomendationAppHome, RecomendationScrollHome, RecomendationSliderHome } from 'components/Home/RecomendationProductsHome/TypeVersionProductsHome'
import { RecomendationAppHomeNew, RecomendationScrollHomeNew, RecomendationSliderHomeNew } from 'components/Home/RecomendationProductsHome/TypeVersionProductsHome'
import { useIsMobile } from 'hooks/useIsMobile'
import { HomeSkeletonCarrusel, HomeSkeletonCarruselNew } from 'pages/Home/HomeSkeleton/Carruseles'

export function RecomendationProductsHomeNew(props) {
    const {
        carruseles,
        styleView,
        type = "slider",
        seccionPage
    } = props

    const [mobileEval, setMobileEval] = useState([])
    const { isMobile } = useIsMobile()

    //evalua si es mobile
    useEffect(() => {
        if (isMobile === true) {
            setMobileEval(isMobile)
        } else if (isMobile === false) {
            setMobileEval(isMobile)
        }
    }, [isMobile])

    // <type === "block"
    // ? < RecomendationAppHome carruseles={carruseles} />
    // : <RecomendationScrollHomeNew carruseles={carruseles} />
    return (
        <>
            {carruseles &&
                <>
                    {isMobile
                        ?
                        isMobile === 'desktop'
                            ? type === "slider"
                                ? <RecomendationSliderHomeNew carruseles={carruseles} seccionPage={seccionPage} />
                                : <RecomendationScrollHomeNew carruseles={carruseles} seccionPage={seccionPage} />
                            : styleView === "a"
                                ? < RecomendationAppHomeNew carruseles={carruseles}  styleType="cardGrid" seccionPage={seccionPage} />
                                : styleView === "b"
                                    ?< RecomendationScrollHomeNew carruseles={carruseles} seccionPage={seccionPage} />
                                    : < RecomendationAppHomeNew carruseles={carruseles} styleType="cardLine" seccionPage={seccionPage}  />

                        : <HomeSkeletonCarrusel />
                    }
                </>
            }
        </>
    )
}


export function RecomendationProductsHome(props) {
    const {
        carruseles,
        type = "slider"
    } = props

    const [mobileEval, setMobileEval] = useState([])
    const { isMobile } = useIsMobile()

    //evalua si es mobile
    useEffect(() => {
        if (isMobile === true) {
            setMobileEval(isMobile)
        } else if (isMobile === false) {
            setMobileEval(isMobile)
        }
    }, [isMobile])

    //  type === "block"
    // ? <RecomendationAppHome carruseles={carruseles} />
    // : <RecomendationScrollHomeNew carruseles={carruseles} />//<RecomendationAppHome carruseles={carruseles}/>

    return (
        <>
            {carruseles &&
                <>
                    {isMobile
                        ? isMobile === 'desktop'
                            ? type === "slider"
                                ? <RecomendationSliderHome carruseles={carruseles} />
                                : <RecomendationScrollHome carruseles={carruseles} />
                            : isMobile === 'movile'
                                ? null
                                : <RecomendationAppHome carruseles={carruseles} />
                        : <HomeSkeletonCarrusel />
                    }
                </>
            }
        </>
    )
}
