import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function AvisoPrivacidadDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Clientes Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Aviso de Privacidad Clientes</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Quién dará tratamiento a sus datos personales?</h2>
                                <p>Sears Operadora México S.A. de C.V., (en adelante Sears) con domicilio en Lago Zúrich No. 245, Edificio Presa Falcón piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, C.P. 11529, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                <p>Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de SEARS a la dirección electrónica <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Qué información vamos a recabar?</h2>
                                <p>Sears para cumplir con las finalidades señaladas en el presente Aviso recabará y tratará datos personales de identificación, incluyendo su imagen personal que podrá ser registrada a través de vídeos y/o fotografías cuando ingresa a nuestras sucursales y/o sea participe de nuestros eventos,  datos personales de contacto, datos fiscales,  datos de ubicación, datos personales patrimoniales y/o financieros, datos biométricos (huella dactilar y características de su rostro), datos demográficos, información generada con motivo de la prestación de nuestros servicios así como sobre su participación en nuestros sorteos, concursos y promociones, información sobre su vehículo, e información que usted nos proporcione de manera voluntaria para la atención de quejas, reclamaciones o solicitudes de información.</p>
                                <p>Sears verificará que los datos personales contenidos en la credencial para votar que usted presente, incluyendo sus datos biométricos coincidan con los que obran en poder del Instituto Nacional Electoral (INE).</p>
                                <p>Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sears dará tratamiento a datos personales de terceros en calidad de referencias personales, por lo que al proporcionar los datos personales de dichos terceros para que Sears les contacte por medios físicos y/o electrónicos usted reconoce haber recabado el consentimiento requerido en términos de la normatividad aplicable.</p>
                                <p>En caso de que usted nos contacte por redes sociales, podremos recabar los datos de su perfil público de redes sociales para darle atención a sus peticiones y/o solicitudes de información en relación con los productos que ofrecemos, podremos dar tratamiento a la información contenida en su perfil público de la red social de que se trate, así como al contenido de sus publicaciones.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>No tratamiento de datos personales de menores de edad</h2>
                                <p>Sears no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad. </p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Tratamiento de datos sin decisión humana valorativa y actividades de perfilamiento</h2>
                                <p>Le informamos que para dar cumplimiento a las finalidades previstas en este Aviso de Privacidad Sears realizará tratamientos de datos personales como parte de un proceso de toma de decisiones sin intervención humana valorativa y técnicas de análisis masivo de datos para realizar actividades de perfilamiento a partir de sus hábitos de consumo, gustos e intereses, monitorear su comportamiento y ofrecerle una experiencia personalizada en relación con el uso de los servicios, así como para dar cumplimiento a algunas(s) de las finalidades(es ) informadas en el presente Aviso de Privacidad. </p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Para qué fines usamos su información?</h2>
                                <p>Sears tratará sus datos personales para las siguientes finalidades primarias y necesarias:</p>

                                <ul className="ulQuestionDisc">
                                  <li>Creación y gestión de su cuenta de usuario;</li>
                                  <li>Identificarle y contactarlo para dar seguimiento a la entrega de los productos adquiridos;</li>
                                  <li>Verificar y confirmar su identidad; incluyendo realizar procesos de verificación mediante el uso de datos biométricos en cumplimiento a la normatividad aplicable;</li>
                                  <li>Acreditar, validar y verificar su identidad. Lo anterior incluye la verificación de los datos contenidos en su Credencial para Votar y datos biométricos (similitud de huellas dactilares y características del rostro) ante los registros del Instituto Nacional Electoral.</li>
                                  <li>Atender y resolver sus dudas relacionadas con la adquisición de nuestros productos y/o servicios;</li>
                                  <li>Atender las solicitudes de compra remitidas y procesar pagos;</li>
                                  <li>Facilitar la localización, tramitación, suministro, distribución y entrega de los productos solicitados por usted;</li>
                                  <li>Para dar cumplimiento a lo dispuesto por la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita y demás normatividad aplicable;</li>
                                  <li>Aclaraciones bancarias;</li>
                                  <li>Realizar consultas, investigaciones y revisiones en relación a quejas o reclamaciones;</li>
                                  <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos;</li>
                                  <li>Para las gestiones de facturación relacionadas con los productos adquiridos;</li>
                                  <li>Llevar un histórico de sus compras;</li>
                                  <li>Otorgamiento de créditos;</li>
                                  <li>Monitorear su comportamiento crediticio;</li>
                                  <li>Detectar y prevenir fraudes;</li>
                                  <li>Brindarle los servicios de sastrería;</li>
                                  <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que Sears considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros.</li>
                                  <li>Para llevar a cabo actividades de contacto, cobranza y validación de información con los terceros que usted haya designado como referencias personales a fin de garantizar el cumplimiento de la relación jurídica con nosotros. </li>
                                  <li>Informarle sobre cambios o nuevos productos o servicios que estén relacionados con los productos adquiridos por usted;</li>
                                  <li>Activación, cancelación y análisis del uso de su(s) tarjeta(s);</li>
                                  <li>Acumulación de puntos a través de monederos electrónicos o similares;</li>
                                  <li>Para alimentar la base de datos del buró de crédito;</li>
                                  <li>En su caso, para integrar una lista sobre los regalos de su preferencia y hacerlos llegar a su domicilio y/o canjearlos por productos en alguna de nuestras sucursales;</li>
                                  <li>En el supuesto de que el producto que usted adquiera cuente con una garantía vigente, para brindarle el servicio de asistencia técnica y/o reparación requeridos;</li>
                                  <li>En el supuesto de que usted cumpla con los requisitos establecidos por Sears para darle de alta como cliente preferido y enviarle comunicaciones electrónicas personalizadas de acuerdo a sus intereses;</li>
                                  <li>Identificarle como cliente de CÍRCULO PLUS;</li>
                                  <li>Determinar la zona geográfica donde usted se encuentre para ofrecerle contenidos, promociones o beneficios correspondientes a esa zona; </li>
                                  <li>Para realizar la venta de productos a través de Marketplace;</li>
                                  <li>En el supuesto de que usted nos contacte a través de redes sociales además de las finalidades anteriores, podremos elaborar material informativo sobre las actividades que realizamos y divulgar a través de distintos medios de información tanto físicos como electrónicos los contenidos publicados por usted dentro de las redes sociales y que se refieran a interacciones o actividades de contacto directo con Sears;</li>
                                  <li>En el supuesto de que usted cumpla con los requisitos establecidos por Sears para darle de alta en sus programas de lealtad;</li>
                                  <li>Para identificarle como usuario de Auto Center, llevar un registro de los servicios que usted ha contratado, así como atender dudas relacionadas con los servicios que presta Auto Center;</li>
                                  <li>Registrarle como participante en nuestros sorteos, concursos y promociones. En el supuesto de que usted resulte ganador, además podremos utilizar su información para contactarle, realizar la entrega del premio al que usted sea acreedor, así como para realizar diversas publicaciones sobre su participación a través de distintos medios de comunicación físicos y electrónicos;</li>
                                  <li>Identificarlo como visitante en nuestras sucursales y/o como participante en dinámicas, juegos, sorteos y actividades similares que se organicen en nuestras instalaciones. Si usted ingresa a nuestras instalaciones y/o participa en alguna de las actividades señaladas se entenderá que otorga su consentimiento expreso para que podamos dar tratamiento a su imagen para realizar la publicación de contenidos de diversa índole en plataformas y canales de comunicación de la empresa con el fin de dar publicidad a nuestras actividades, promociones, productos y servicios;</li>
                                  <li>Realizar actividades de monitoreo de calidad en los servicios o sobre su experiencia de compra con nosotros;</li>
                                  <li>Mantener la seguridad de las redes y servicios de comunicaciones electrónicas, detectar fallos o errores técnicos en la transmisión de las comunicaciones electrónicas, así como cualquier tratamiento que sea necesario para la correcta prestación de los servicios;</li>
                                  <li>Elaborar material informativo sobre las actividades que realizamos y divulgarlo a través de distintos medios de información tanto físicos como electrónicos, incluyendo los contenidos publicados por usted dentro de las redes sociales y que se refieran a interacciones o actividades de contacto directo con Sears;</li>
                                  <li>Garantizar la seguridad física de nuestras instalaciones y de las personas presentes en ellas;</li>
                                  <li>Videovigilancia dentro de nuestras instalaciones para su seguridad y la del personal que se encuentra en ellas;</li>
                                  <li>Dar atención a sus solicitudes de información, quejas o reclamaciones;</li>
                                  <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted;</li>
                                  <li>Dar cumplimiento a las políticas y procedimientos de carácter interno;</li>
                                  <li>Dar cumplimiento a obligaciones previstas en la normatividad aplicable, incluyendo la atención de requerimientos debidamente fundados y motivados emitidos por autoridades competentes.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Qué otros usos podemos darle a su información?</h2>
                                <p>De manera adicional, si usted no se opone, Sears podrá mantener su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de Sears de acuerdo con sus intereses, así como de otras marcas y/o Empresas del Grupo incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos;</li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sears y/o Empresas del Grupo;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero;</li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras;</li>
                                    <li>Realizar estadísticas, encuestas o estudios sobre hábitos de mercado y comportamiento que tengan por finalidad evaluar la calidad de los servicios, bienes, productos y/o experiencia de compra.</li>
                                </ul>

                                <p>En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sears.com.mx'>arco@sears.com.mx</a></p>
                                <p>Adicionalmente, te informamos que tú puedes en todo momento cancelar el envío de mensajes promocionales haciendo clic en el enlace Cancelar Suscripción que está integrado en el pie de página de todos los correos electrónicos enviados por Sears.</p>
                                <p>La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿A qué terceros podemos transferir su información?</h2>
                                <p>Sears para cumplir la(s) finalidad(es) anteriormente descrita(s) transferirá sin requerir de tu consentimiento los datos necesarios a terceros tanto nacionales como extranjeros, intervinientes en la prestación de los productos y/o servicios y forma de pago, solicitados por Usted.</p>
                                <p>Sears podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes, tanto nacionales como internacionales, en los casos legalmente previstos, a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso, así como al Instituto Nacional Electoral y terceros con los que tengamos celebrados acuerdos jurídicos vinculantes para verificar y validar la autenticidad de los datos y documentos proporcionados por el Titular y relacionados respecto de su identidad.</p>
                                <p>Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>
                                <p>En caso de que las operaciones que usted realice con Sears, y las cuales estén clasificadas en Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, como actividades vulnerables, Sears, podrá entregar determinada información de usted sin requerir de su consentimiento a las autoridades fiscales competentes con la finalidad de dar cumplimiento a lo dispuesto por la Ley.</p>
                                <p>Sears podrá transferir  sin requerir de su consentimiento los datos personales necesarios  a:  socios comerciales y/o terceros con los que tengamos celebrados contratos de prestación de servicios para prestar servicios de entrega de bienes y productos, postventa, gestionar cuentas y registros de clientes, enviar comunicación relativa a productos o servicios que pueden ser de su interés, programas de lealtad, así como comunicaciones de marketing, con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p>Asimismo, le informamos que en el supuesto de que usted incumpla con las obligaciones de pago contraídas, sus datos personales podrán ser transferidos a las Sociedades de Información Crediticia con las que tengamos celebrados acuerdos de comunicación de datos sin requerir su consentimiento por ser una obligación legal en términos de la Ley respectiva.</p>
                                <p>Si usted no se opone, Sears podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen. Además, Sears podrá compartir los datos personales necesarios a autoridades de los distintos órdenes de gobierno e instancias de la administración pública local o federal, para que dicha información sea consignada como prueba en un procedimiento judicial o administrativo, según se requiera.</p>
                                <p>En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo arco@sears.com.mx</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</h2>
                                <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sears a la dirección electrónica arco@sears.com.mx.</p>
                                <p>Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sears pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sears.</p>
                                <p>Le recordamos que, si usted desea eliminar sus datos, deberá promover su derecho de cancelación, en los términos antes descritos.</p>
                                <p>En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo puede limitar el uso y/o divulgación de sus datos?</h2>
                                <p>Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href='arco@sears.com.mx'>arco@sears.com.mx</a>. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de Sears. Para mayor información favor de contactar al Departamento de Protección de Datos de Sears.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Uso de cookies y web beacons</h2>
                                <p>Le informamos que Sears en nuestro sitio web y/o aplicaciones podemos hacer uso de cookies, web beacons y otras tecnologías de rastreo que nos permiten cumplir con las finalidades informadas en el presente Aviso de Privacidad. Los diferentes tipos de cookies que utilizamos son:</p>
                                <ul className="ulQuestionDisc">
                                    <li><span className='bold'>Cookies esenciales:</span> para permitirle un uso adecuado de nuestro sitio web y/o aplicaciones, por lo que las mismas no pueden ser deshabilitadas.</li>
                                    <li><span className='bold'>Cookies para recordar sus preferencias y experiencia:</span> estas cookies nos permiten recordar sus preferencias, opciones de navegación y funciones personalizadas.</li>
                                    <li><span className='bold'>Cookies para objetivos de rendimiento y análisis:</span> podemos usar cookies propias y de terceros para identificar la forma en la cual usted utiliza nuestros Servicios con la finalidad de mejorar su rendimiento y futuros desarrollos.</li>
                                    <li><span className='bold'>Cookies publicitarias propias y de terceros:</span> podemos colocar cookies publicitarias propias y de terceros (como socios comerciales y empresas del grupo) para mostrarle publicidad que consideremos relevante para usted.</li>
                                </ul>

                                <p>Usted puede deshabilitar el uso de cookies, a través de su navegador siguiendo el procedimiento establecido por éste. Aquí alguna información del procedimiento establecido en los siguientes navegadores: </p>
                                <ul className="ulQuestionDisc">
                                    <li>Internet Explorer: Herramientas - Opciones de Internet - Privacidad - Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                    <li>Firefox: Herramientas - Opciones - Privacidad y Seguridad. Para más información, puedes consultar el soporte de Mozilla o la Ayuda del navegador.</li>
                                    <li>Chrome: Configuración - Verificación de seguridad y/o - Privacidad y seguridad. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.</li>
                                    <li>Safari: Preferencias - Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.</li>
                                    <li>Edge: Configuración - Privacidad, búsqueda y servicios y/o Permisos del sitio. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</h2>
                                <p>Sears le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet http://www.sears.com.mx por lo cual le pedimos revise la misma de manera periódica.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo puede hacernos llegar sus dudas?</h2>
                                <p>Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href="arco@sears.com.mx">arco@sears.com.mx</a>.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo protegemos sus transacciones?</h2>
                                <p>Con el propósito de proteger las transacciones que usted realiza a través de nuestro sitio web, tenemos implementado un servidor seguro bajo el protocolo SSL (Secure Socket Layer) de tal menara que la información que nos envía, se transmite encriptada para asegurar su protección.</p>
                                <p>Para verificar que se encuentra en un entorno protegido, asegúrese de que aparezca una "S" en la barra de navegación "httpS"://.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Recomendaciones de seguridad</h2>
                                <p>Es importante que elija una contraseña única y distinta a la que uses en otros sitios de internet. Asimismo, es de vital importancia que no comparta su contraseña con ningún tercero, si cree que alguien pudiere llegar a tener conocimiento de su contraseña cámbiela de inmediato.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>INAI</h2>
                                <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Aceptación de Aviso de Privacidad</h2>
                                <p>Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último. </p>
                                <p><span className='bold'>Fecha de última actualización:</span> Febrero de 2024.</p>
                            </div>
                        </div>

                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function AvisoPrivacidadMobile() {
    const history = useHistory();
    const isNativeApp = window.location.search.indexOf('isNativeApp=true')
    // cerrar modal
    const onClose = () => {
        // history.goBack(-1);
        window.location.href = "/menu-mas"
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Clientes Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">
                    {isNativeApp < 0
                        ?
                            
                            <div className="contentCloseAN">
                                <div className="btn closeBack" onClick={onClose} ></div>
                                <p className="title">Aviso de Privacidad Clientes</p>
                            </div>
                        : null 
                    }
                    <div className="dataModal">
                        <div className="boxAN">

                            <div className="bxSlider">
                                <div className="contenedorImagenes">
                                    <ul>
                                        <img src="/img/logo-sears.svg" alt={"logo-sears"} width={100} />
                                    </ul>
                                </div>

                                <p className="pSliderTitleLred">¿Quién dará tratamiento a sus datos personales?</p>
                                <p className="pSliderL">Sears Operadora México S.A. de C.V., (en adelante Sears) con domicilio en Lago Zúrich No. 245, Edificio Presa Falcón piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, C.P. 11529, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                <p className="pSliderL">Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de Sears a la dirección electrónica arco@sears.com.mx</p>


                                <p className="pSliderTitleLred">¿Qué información vamos a recabar?</p>
                                <p className="pSliderL">Sears para cumplir con las finalidades señaladas en el presente Aviso recabará y tratará datos personales de identificación, incluyendo su imagen personal que podrá ser registrada a través de vídeos y/o fotografías cuando ingresa a nuestras sucursales y/o sea participe de nuestros eventos,  datos personales de contacto, datos fiscales,  datos de ubicación, datos personales patrimoniales y/o financieros, datos biométricos (huella dactilar y características de su rostro), datos demográficos, información generada con motivo de la prestación de nuestros servicios así como sobre su participación en nuestros sorteos, concursos y promociones, información sobre su vehículo, e información que usted nos proporcione de manera voluntaria para la atención de quejas, reclamaciones o solicitudes de información.</p>
                                <p className="pSliderL">Sears verificará que los datos personales contenidos en la credencial para votar que usted presente, incluyendo sus datos biométricos coincidan con los que obran en poder del Instituto Nacional Electoral (INE).</p>
                                <p className="pSliderL">Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sears dará tratamiento a datos personales de terceros en calidad de referencias personales, por lo que al proporcionar los datos personales de dichos terceros para que Sears les contacte por medios físicos y/o electrónicos usted reconoce haber recabado el consentimiento requerido en términos de la normatividad aplicable.</p>
                                <p className='pSliderL'>En caso de que usted nos contacte por redes sociales, podremos recabar los datos de su perfil público de redes sociales para darle atención a sus peticiones y/o solicitudes de información en relación con los productos que ofrecemos, podremos dar tratamiento a la información contenida en su perfil público de la red social de que se trate, así como al contenido de sus publicaciones.</p>

                                <p className="pSliderTitleLred">No tratamiento de datos personales de menores de edad</p>
                                <p className="pSliderL">Sears no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad. </p>

                                <p className="pSliderTitleLred">Tratamiento de datos sin decisión humana valorativa y actividades de perfilamiento</p>
                                <p className="pSliderL">Le informamos que para dar cumplimiento a las finalidades previstas en este Aviso de Privacidad Sears realizará tratamientos de datos personales como parte de un proceso de toma de decisiones sin intervención humana valorativa y técnicas de análisis masivo de datos para realizar actividades de perfilamiento a partir de sus hábitos de consumo, gustos e intereses, monitorear su comportamiento y ofrecerle una experiencia personalizada en relación con el uso de los servicios, así como para dar cumplimiento a algunas(s) de las finalidades(es ) informadas en el presente Aviso de Privacidad. </p>

                                <p className="pSliderTitleLred">¿Para qué fines usamos su información?</p>
                                <p className="pSliderL">Sears tratará sus datos personales para las siguientes finalidades primarias y necesarias:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li>Creación y gestión de su cuenta de usuario;</li>
                                    <li>Identificarle y contactarlo para dar seguimiento a la entrega de los productos adquiridos;</li>
                                    <li>Verificar y confirmar su identidad; incluyendo realizar procesos de verificación mediante el uso de datos biométricos en cumplimiento a la normatividad aplicable;</li>
                                    <li>Acreditar, validar y verificar su identidad. Lo anterior incluye la verificación de los datos contenidos en su Credencial para Votar y datos biométricos (similitud de huellas dactilares y características del rostro) ante los registros del Instituto Nacional Electoral.</li>
                                    <li>Atender y resolver sus dudas relacionadas con la adquisición de nuestros productos y/o servicios;</li>
                                    <li>Atender las solicitudes de compra remitidas y procesar pagos;</li>
                                    <li>Facilitar la localización, tramitación, suministro, distribución y entrega de los productos solicitados por usted;</li>
                                    <li>Para dar cumplimiento a lo dispuesto por la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita y demás normatividad aplicable;</li>
                                    <li>Aclaraciones bancarias;</li>
                                    <li>Realizar consultas, investigaciones y revisiones en relación a quejas o reclamaciones;</li>
                                    <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos;</li>
                                    <li>Para las gestiones de facturación relacionadas con los productos adquiridos;</li>
                                    <li>Llevar un histórico de sus compras;</li>
                                    <li>Otorgamiento de créditos;</li>
                                    <li>Monitorear su comportamiento crediticio;</li>
                                    <li>Detectar y prevenir fraudes;</li>
                                    <li>Brindarle los servicios de sastrería;</li>
                                    <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que Sears considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros.</li>
                                    <li>Para llevar a cabo actividades de contacto, cobranza y validación de información con los terceros que usted haya designado como referencias personales a fin de garantizar el cumplimiento de la relación jurídica con nosotros. </li>
                                    <li>Informarle sobre cambios o nuevos productos o servicios que estén relacionados con los productos adquiridos por usted;</li>
                                    <li>Activación, cancelación y análisis del uso de su(s) tarjeta(s);</li>
                                    <li>Acumulación de puntos a través de monederos electrónicos o similares;</li>
                                    <li>Para alimentar la base de datos del buró de crédito;</li>
                                    <li>En su caso, para integrar una lista sobre los regalos de su preferencia y hacerlos llegar a su domicilio y/o canjearlos por productos en alguna de nuestras sucursales;</li>
                                    <li>En el supuesto de que el producto que usted adquiera cuente con una garantía vigente, para brindarle el servicio de asistencia técnica y/o reparación requeridos;</li>
                                    <li>En el supuesto de que usted cumpla con los requisitos establecidos por Sears para darle de alta como cliente preferido y enviarle comunicaciones electrónicas personalizadas de acuerdo a sus intereses;</li>
                                    <li>Identificarle como cliente de CÍRCULO PLUS;</li>
                                    <li>Determinar la zona geográfica donde usted se encuentre para ofrecerle contenidos, promociones o beneficios correspondientes a esa zona; </li>
                                    <li>Para realizar la venta de productos a través de Marketplace;</li>
                                    <li>En el supuesto de que usted nos contacte a través de redes sociales además de las finalidades anteriores, podremos elaborar material informativo sobre las actividades que realizamos y divulgar a través de distintos medios de información tanto físicos como electrónicos los contenidos publicados por usted dentro de las redes sociales y que se refieran a interacciones o actividades de contacto directo con Sears; </li>
                                    <li>En el supuesto de que usted cumpla con los requisitos establecidos por Sears para darle de alta en sus programas de lealtad;</li>
                                    <li>Para identificarle como usuario de Auto Center, llevar un registro de los servicios que usted ha contratado, así como atender dudas relacionadas con los servicios que presta Auto Center;</li>
                                    <li>Registrarle como participante en nuestros sorteos, concursos y promociones. En el supuesto de que usted resulte ganador, además podremos utilizar su información para contactarle, realizar la entrega del premio al que usted sea acreedor, así como para realizar diversas publicaciones sobre su participación a través de distintos medios de comunicación físicos y electrónicos;</li>
                                    <li>Identificarlo como visitante en nuestras sucursales y/o como participante en dinámicas, juegos, sorteos y actividades similares que se organicen en nuestras instalaciones. Si usted ingresa a nuestras instalaciones y/o participa en alguna de las actividades señaladas se entenderá que otorga su consentimiento expreso para que podamos dar tratamiento a su imagen para realizar la publicación de contenidos de diversa índole en plataformas y canales de comunicación de la empresa con el fin de dar publicidad a nuestras actividades, promociones, productos y servicios;</li>
                                    <li>Realizar actividades de monitoreo de calidad en los servicios o sobre su experiencia de compra con nosotros;</li>
                                    <li>Mantener la seguridad de las redes y servicios de comunicaciones electrónicas, detectar fallos o errores técnicos en la transmisión de las comunicaciones electrónicas, así como cualquier tratamiento que sea necesario para la correcta prestación de los servicios;</li>
                                    <li>Elaborar material informativo sobre las actividades que realizamos y divulgarlo a través de distintos medios de información tanto físicos como electrónicos, incluyendo los contenidos publicados por usted dentro de las redes sociales y que se refieran a interacciones o actividades de contacto directo con Sears;</li>
                                    <li>Garantizar la seguridad física de nuestras instalaciones y de las personas presentes en ellas;</li>
                                    <li>Videovigilancia dentro de nuestras instalaciones para su seguridad y la del personal que se encuentra en ellas;</li>
                                    <li>Dar atención a sus solicitudes de información, quejas o reclamaciones;</li>
                                    <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted;</li>
                                    <li>Dar cumplimiento a las políticas y procedimientos de carácter interno;</li>
                                    <li>Dar cumplimiento a obligaciones previstas en la normatividad aplicable, incluyendo la atención de requerimientos debidamente fundados y motivados emitidos por autoridades competentes.</li>
                                </ul>

                                <p className="pSliderTitleLred">¿Qué otros usos podemos darle a su información?</p>
                                <p className="pSliderL">De manera adicional, si usted no se opone, Sears podrá mantener su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de Sears de acuerdo con sus intereses, así como de otras marcas y/o Empresas del Grupo incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos;</li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sears y/o Empresas del Grupo;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero; </li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras;</li>
                                    <li>Realizar estadísticas, encuestas o estudios sobre hábitos de mercado y comportamiento que tengan por finalidad evaluar la calidad de los servicios, bienes, productos y/o experiencia de compra.</li>
                                </ul>

                                <p className="pSliderL">En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo arco@sears.com.mx</p>
                                <p className="pSliderL">Adicionalmente, te informamos que tú puedes en todo momento cancelar el envío de mensajes promocionales haciendo clic en el enlace Cancelar Suscripción que está integrado en el pie de página de todos los correos electrónicos enviados por Sears.</p>
                                <p className="pSliderL">La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>

                                <p className="pSliderTitleLred">¿A qué terceros podemos transferir su información?</p>
                                <p className="pSliderL">Sears para cumplir la(s) finalidad(es) anteriormente descrita(s) transferirá sin requerir de tu consentimiento los datos necesarios a terceros tanto nacionales como extranjeros, intervinientes en la prestación de los productos y/o servicios y forma de pago, solicitados por Usted.</p>
                                <p className="pSliderL">Sears podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes, tanto nacionales como internacionales, en los casos legalmente previstos, a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso, así como al Instituto Nacional Electoral y terceros con los que tengamos celebrados acuerdos jurídicos vinculantes para verificar y validar la autenticidad de los datos y documentos proporcionados por el Titular y relacionados respecto de su identidad.</p>
                                <p className="pSliderL">Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>
                                <p className="pSliderL">En caso de que las operaciones que usted realice con Sears, y las cuales estén clasificadas en Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, como actividades vulnerables, Sears, podrá entregar determinada información de usted sin requerir de su consentimiento a las autoridades fiscales competentes con la finalidad de dar cumplimiento a lo dispuesto por la Ley.</p>
                                <p className="pSliderL">Sears podrá transferir  sin requerir de su consentimiento los datos personales necesarios  a:  socios comerciales y/o terceros con los que tengamos celebrados contratos de prestación de servicios para prestar servicios de entrega de bienes y productos, postventa, gestionar cuentas y registros de clientes, enviar comunicación relativa a productos o servicios que pueden ser de su interés, programas de lealtad, así como comunicaciones de marketing, con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p className="pSliderL">Asimismo, le informamos que en el supuesto de que usted incumpla con las obligaciones de pago contraídas, sus datos personales podrán ser transferidos a las Sociedades de Información Crediticia con las que tengamos celebrados acuerdos de comunicación de datos sin requerir su consentimiento por ser una obligación legal en términos de la Ley respectiva.</p>
                                <p className="pSliderL">Si usted no se opone, Sears podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen. Además, Sears podrá compartir los datos personales necesarios a autoridades de los distintos órdenes de gobierno e instancias de la administración pública local o federal, para que dicha información sea consignada como prueba en un procedimiento judicial o administrativo, según se requiera. </p>
                                <p className="pSliderL">En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo arco@sears.com.mx</p>

                                <p className="pSliderTitleLred">¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</p>
                                <p className="pSliderL">Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sears a la dirección electrónica <a href='mailto:arco@sears.com.mx'>arco@sears.com.mx</a>.</p>
                                <p className="pSliderL">Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sears pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sears.</p>
                                <p className='pSliderL'>Le recordamos que, si usted desea eliminar sus datos, deberá promover su derecho de cancelación, en los términos antes descritos.</p>
                                <p className='pSliderL'>En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia..</p>

                                <p className="pSliderTitleLred">¿Cómo puede limitar el uso y/o divulgación de sus datos?</p>
                                <p className="pSliderL">Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de SEARS. Para mayor información favor de contactar al Departamento de Protección de Datos de SEARS.</p>

                                <p className="pSliderTitleLred">Uso de cookies y web beacons</p>
                                <p className="pSliderL">Le informamos que Sears en nuestro sitio web y/o aplicaciones podemos hacer uso de cookies, web beacons y otras tecnologías de rastreo que nos permiten cumplir con las finalidades informadas en el presente Aviso de Privacidad. Los diferentes tipos de cookies que utilizamos son:</p>

                                <ul className='ulDeclaracionesDisc'>
                                    <li><span className='bold'>Cookies esenciales:</span> para permitirle un uso adecuado de nuestro sitio web y/o aplicaciones, por lo que las mismas no pueden ser deshabilitadas.</li>
                                    <li><span className='bold'>Cookies para recordar sus preferencias y experiencia:</span> estas cookies nos permiten recordar sus preferencias, opciones de navegación y funciones personalizadas.</li>
                                    <li><span className='bold'>Cookies para objetivos de rendimiento y análisis:</span> podemos usar cookies propias y de terceros para identificar la forma en la cual usted utiliza nuestros Servicios con la finalidad de mejorar su rendimiento y futuros desarrollos.</li>
                                    <li><span className='bold'>Cookies publicitarias propias y de terceros: </span>podemos colocar cookies publicitarias propias y de terceros (como socios comerciales y empresas del grupo) para mostrarle publicidad que consideremos relevante para usted.</li>
                                </ul>

                                <p className="pSliderL">Usted puede deshabilitar el uso de cookies, a través de su navegador siguiendo el procedimiento establecido por éste. Aquí alguna información del procedimiento establecido en los siguientes navegadores:  </p>

                                <ul className='ulDeclaracionesDisc'>
                                    <li>Internet Explorer: Herramientas - Opciones de Internet - Privacidad - Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                    <li>Firefox: Herramientas  - Opciones - Privacidad y Seguridad. Para más información, puedes consultar el soporte de Mozilla o la Ayuda del navegador.</li>
                                    <li>Chrome: Configuración - Verificación de seguridad y/o - Privacidad y seguridad. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.</li>
                                    <li>Safari: Preferencias - Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.</li>
                                    <li>Edge: Configuración - Privacidad, búsqueda y servicios y/o Permisos del sitio. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                </ul>

                                <p className="pSliderTitleLred">¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</p>
                                <p className="pSliderL">SEARS le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet <Link to="/">https://www.sears.com.mx</Link> por lo cual le pedimos revise la misma de manera periódica.</p>

                                <p className="pSliderTitleLred">¿Cómo puede hacernos llegar sus dudas?</p>
                                <p className="pSliderL">Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>.</p>


                                <p className="pSliderTitleLred">¿Cómo protegemos sus transacciones?</p>
                                <p className="pSliderL">Con el propósito de proteger las transacciones que usted realiza a través de nuestro sitio web, tenemos implementado un servidor seguro bajo el protocolo SSL (Secure Socket Layer) de tal menara que la información que nos envía, se transmite encriptada para asegurar su protección.</p>
                                <p className="pSliderL">Para verificar que se encuentra en un entorno protegido, asegúrese de que aparezca una "S" en la barra de navegación "httpS"://.</p>

                                <p className="pSliderTitleLred">Recomendaciones de seguridad</p>
                                <p className="pSliderL">Es importante que elija una contraseña única y distinta a la que uses en otros sitios de internet. Asimismo, es de vital importancia que no comparta su contraseña con ningún tercero, si cree que alguien pudiere llegar a tener conocimiento de su contraseña cámbiela de inmediato.</p>

                                <p className="pSliderTitleLred">INAI</p>
                                <p className="pSliderL">En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                                
                                <p className="pSliderTitleLred">Aceptación de Aviso de Privacidad</p>
                                <p className="pSliderL">Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último. </p>
                                <p className="pSliderL">Fecha de última actualización: Febrero de 2024.</p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export function AvisoPrivacidad() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Clientes');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <AvisoPrivacidadDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AvisoPrivacidadMobile />
            }
        </>
    )
}