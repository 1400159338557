import React from 'react'
import '../style.sass'
import { Link } from "react-router-dom"
import CardProduct from 'components/CardProduct/ClasicCard'
import Slider from 'react-slick'
import { NO_IMAGE } from 'constants/general';

import {ErrorBoundary} from 'components/Error/ErrorBoundary'

//- Contenedor de productos tipo App V1
export function RecomendationAppHome ({carruseles}){
    return(
        <>
            {
                carruseles?.map( ({nombre, link, productos}, index) => 
                    <div className="moduleCarruselHome" key={index}>
                        <div className="container">
                            {typeof(link) !== 'function'
                                ?
                                    link?.split("/")[1] === "c"
                                        ?
                                            <a href={link} className="h3 verMas">{nombre}</a>
                                        :
                                            <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="h3 verMas">{nombre}</Link>
                                : null
                            }
                            {nombre && <div className="borderRed"></div>}
                           
                            <div className={index%2 ? 'cardGrid' : 'cardLine'} >
                                {
                                    productos?.map(({id, nombre, precio, precioLista, foto, descuento}, ind) => 
                                        <CardProduct 
                                            key={`${id}_${ind}`}
                                            id={id}
                                            nombre={nombre}
                                            precio={precio}
                                            precioLista={precioLista}
                                            foto={foto}
                                            descuento={descuento}
                                            index={ind}
                                            productTotal={productos[ind]}
                                        />
                                    )
                                }
                            </div>
                            {typeof(link) !== 'function' 
                                ?
                                    link?.split("/")[1] === "c"
                                        ?
                                            <a href={link} className="verMas btnVer">Ver más</a>
                                        :
                                            <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="verMas btnVer">Ver más</Link>    
                                : null            
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

//- Contenedor de productos tipo App V2
export function RecomendationAppHomeNew ({carruseles, styleType, seccionPage}){
    return(
        <>
            {
                carruseles?.map( ({name, link, products}, index) => 
                    <div className="moduleCarruselHome" key={index}>
                        <div className="container">
                            {typeof(link) !== 'function'
                                ?
                                    link?.split("/")[1] === "c"
                                        ?
                                            <a href={link} className="h3 verMas">{name}</a>
                                        :
                                            <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${name.trim().replace(/ /g,'-')}/`} className="h3 verMas">{name}</Link>
                                : null
                            }
                            {name && <div className="borderRed"></div>}
                            {/* <div className={index%2 ? 'cardGrid' : 'cardLine'} > */}
                            <div className={ styleType === "cardGrid" ? "cardGrid" : styleType === "cardLine" ? "cardLine" :  index%2 ? 'cardGrid' : 'cardLine'} >
                                {
                                    products?.map(({id, title, title_seo, price, sale_price, images, discount, reviews, shipping, features, categories, brand, telmex_months, payment_methods, store}, index) => 
                                        telmex_months.length
                                            ?
                                                payment_methods
                                                    ?
                                                        payment_methods?.find(idProd => idProd.id === 4468)?.promotion?.length
                                                            ?
                                                                <CardProduct 
                                                                    key={`${id}_${index}`} id={id}
                                                                    nombre={title} nombreSEO={title_seo}
                                                                    precio={price} precioLista={sale_price}
                                                                    foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                                    review={reviews?.score} comments={reviews?.total_reviews}
                                                                    shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                                    categories={categories} brand={brand} 
                                                                    creditCardMonths={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].months}
                                                                    creditCardPrice={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].price}
                                                                    telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                    index={index}
                                                                    productTotal={products[index]}
                                                                    name={name}
                                                                    seccionPage={seccionPage}
                                                                    seller={store}
                                                                />
                                                            :
                                                                <CardProduct 
                                                                    key={`${id}_${index}`} id={id}
                                                                    nombre={title} nombreSEO={title_seo}
                                                                    precio={price} precioLista={sale_price}
                                                                    foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                                    review={reviews?.score} comments={reviews?.total_reviews}
                                                                    shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                                    categories={categories} brand={brand}
                                                                    telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                    index={index}
                                                                    productTotal={products[index]}
                                                                    name={name}
                                                                    seccionPage={seccionPage}
                                                                    seller={store}
                                                                />
                                                                
                                                        :
                                                            <CardProduct 
                                                                key={`${id}_${index}`} id={id}
                                                                nombre={title} nombreSEO={title_seo}
                                                                precio={price} precioLista={sale_price}
                                                                foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                                review={reviews?.score} comments={reviews?.total_reviews}
                                                                shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                                categories={categories} brand={brand}
                                                                telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                index={index}
                                                                productTotal={products[index]}
                                                                name={name}
                                                                seccionPage={seccionPage}
                                                                seller={store}
                                                                />
                                            :
                                                <CardProduct 
                                                    key={`${id}_${index}`} id={id}
                                                    nombre={title} nombreSEO={title_seo}
                                                    precio={price} precioLista={sale_price}
                                                    foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                    review={reviews?.score} comments={reviews?.total_reviews}
                                                    shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                    categories={categories} brand={brand}
                                                    index={index}
                                                    productTotal={products[index]}
                                                    name={name}
                                                    seccionPage={seccionPage}
                                                    seller={store}
                                                />
                                    )
                                }
                            </div>
                            {typeof(link) !== 'function'
                                ?
                                    link?.split("/")[1] === "c"
                                        ?
                                            <a href={link} className="verMas btnVer">Ver más</a>
                                        :
                                            <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${name.trim().replace(/ /g,'-')}/`} className="verMas btnVer">Ver más</Link>
                                : null
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}


//- Contenedor de productos tipo scroll V1
export function RecomendationScrollHome({carruseles, seccionPage}){    
    return(
        <>
            {
                carruseles?.map( ({nombre, link, productos}, index) => 
                    <div className="moduleCarruselHome" key={index}>
                        <div className="container contScroll">
                            {typeof(link) !== 'function'
                                ?
                                    link?.split("/")[1] === "c"
                                        ?
                                            <a href={link} className="h3 verMas">{nombre}</a>
                                        :
                                            <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="h3 verMas">{nombre}</Link>
                                : null
                            }
                            {nombre && <div className="borderRed"></div>}
                            <div className="cardGrid " >
                                <div className="scrollNav">
                                    {
                                        productos?.map(({id, nombre, precio, precioLista, foto, descuento, store}, ind) => 
                                            <CardProduct 
                                                key={`${id}_${ind}`}
                                                id={id}
                                                nombre={nombre}
                                                precio={precio}
                                                precioLista={precioLista}
                                                foto={foto}
                                                descuento={descuento}
                                                index={ind}
                                                productTotal={productos[ind]}                                                
                                                seccionPage={seccionPage}
                                                seller={store}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            {typeof(link) !== 'function'
                                ? 
                                    link?.split("/")[1] === "c"
                                        ?
                                            <a href={link} className="verMas btnVer">Ver más</a>
                                        :
                                            <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="verMas btnVer">Ver más</Link>
                                :null
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}


//- Contenedor de productos tipo scroll V2
export function RecomendationScrollHomeNew({carruseles, seccionPage}){    
    return(
        <>
            {
                carruseles?.map( ({name, link, products}, index) => 
                    <div className="moduleCarruselHome" key={index}>
                        <div className="container contScroll">
                            {typeof(link) !== 'function'
                                ?
                                link
                                    ?
                                        link?.split("/")[1] === "c"
                                            ?
                                                <a href={link} className="h3 verMas">{name}</a>
                                            :
                                                <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${name.trim().replace(/ /g,'-')}/`} className="h3 verMas">{name}</Link>
                                    : null
                                : null
                            }
                            {name && <div className="borderRed"></div>}
                            <div className="cardGrid " >
                                <div className="scrollNav">
                                    {
                                        products?.map(({id, title, title_seo, price, sale_price, images, discount, reviews, shipping, features, categories, brand, telmex_months, payment_methods, store}, index) => 
                                            telmex_months?.length
                                                ?
                                                    payment_methods
                                                        ?
                                                            payment_methods?.find(idProd => idProd.id === 4468)?.promotion?.length
                                                                ?
                                                                    <CardProduct 
                                                                        key={`${id}_${index}`} id={id}
                                                                        nombre={title} nombreSEO={title_seo}
                                                                        precio={price} precioLista={sale_price}
                                                                        foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                                        review={reviews?.score} comments={reviews?.total_reviews}
                                                                        shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                                        categories={categories} brand={brand} 
                                                                        creditCardMonths={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].months}
                                                                        creditCardPrice={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].price}
                                                                        telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                        index={index}
                                                                        productTotal={products[index]}
                                                                        name={name}
                                                                        seccionPage={seccionPage}
                                                                        seller={store}
                                                                    />
                                                                :
                                                                    <CardProduct 
                                                                        key={`${id}_${index}`} id={id}
                                                                        nombre={title} nombreSEO={title_seo}
                                                                        precio={price} precioLista={sale_price}
                                                                        foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                                        review={reviews?.score} comments={reviews?.total_reviews}
                                                                        shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                                        categories={categories} brand={brand}
                                                                        telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                        index={index}
                                                                        productTotal={products[index]}
                                                                        name={name}
                                                                        seccionPage={seccionPage}
                                                                        seller={store}
                                                                    />
                                                                    
                                                            :
                                                                <CardProduct 
                                                                    key={`${id}_${index}`} id={id}
                                                                    nombre={title} nombreSEO={title_seo}
                                                                    precio={price} precioLista={sale_price}
                                                                    foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                                    review={reviews?.score} comments={reviews?.total_reviews}
                                                                    shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                                    categories={categories} brand={brand}
                                                                    telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                    index={index}
                                                                    productTotal={products[index]}
                                                                    name={name}
                                                                    seccionPage={seccionPage}
                                                                    seller={store}
                                                                    />
                                                :
                                                    <CardProduct 
                                                        key={`${id}_${index}`} id={id}
                                                        nombre={title} nombreSEO={title_seo}
                                                        precio={price} precioLista={sale_price}
                                                        foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                        review={reviews?.score} comments={reviews?.total_reviews}
                                                        shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                        categories={categories} brand={brand}
                                                        index={index}
                                                        productTotal={products[index]}
                                                        name={name}
                                                        seccionPage={seccionPage}
                                                        seller={store}
                                                    />
                                        )
                                    }
                                </div>
                            </div>
                            {typeof(link) !== 'function'
                                ?
                                    link?.split("/")[1] === "c"
                                        ?
                                            <a href={link} className="verMas btnVer">Ver más</a>
                                        :
                                            <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${name.trim().replace(/ /g,'-')}/`} className="verMas btnVer">Ver más</Link>
                                    : null    
                                }
                        </div>
                    </div>
                )
            }
        </>
    )
}


//- Contenedor de productos tipo slider actual claroshop V1
export function RecomendationSliderHome({carruseles}){
    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 500,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    };
    return(
        <>
            {
                carruseles?.map( ({nombre, link, productos}, index) => 
                    <div className="moduleCarruselHome sinBG" key={index}>
                        <div className="container contRecomendation">
                            <div className="h3">
                                <h2>{nombre}</h2>
                                {typeof(link) !== 'function'
                                    ?
                                        link?.split("/")[1] === "c"
                                            ?
                                                <a href={link} className="verMas">Ver más</a>
                                            :
                                                <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link?.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="verMas">Ver más</Link>
                                    : null            
                                }
                            </div>
                            {nombre && <div className="borderT"></div> }
                            <div className="contProductoRecomend" >
                                <div className="productContent sliderView">
                                    <Slider {...settings}>
                                        {
                                            productos?.map(({id, nombre, precio, precioLista, foto, descuento}, ind) => 
                                                <CardProduct 
                                                    key={id}
                                                    id={id}
                                                    nombre={nombre}
                                                    precio={precio}
                                                    precioLista={precioLista}
                                                    foto={foto ? foto : NO_IMAGE}
                                                    descuento={descuento}
                                                    index={ind}
                                                    productTotal={productos[ind]}
                                                />
                                            )
                                        }
                                    </Slider>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                )
            }
        </>
    )
}

//- Contenedor de productos tipo slider actual claroshop V2
export function RecomendationSliderHomeNew({carruseles, seccionPage}){
    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 500,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    };
    return(
        <ErrorBoundary>
            {
                carruseles?.map( ({name, link, products}, index) => 
                    <div className="moduleCarruselHome sinBG" key={index}>
                        <div className="container contRecomendation">
                            <div className="h3">
                                <h2>{name}</h2>
                                { typeof(link) !== 'function'
                                    ?
                                        link?.split("/")[1] === "c"
                                            ?
                                            
                                                <a href={link} className="verMas">Ver más</a>

                                            :
                                                <Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/')?.replace('/p/', '/landing/')}/`  : `${link?.replace('/l/', '/landing/')?.replace('/p/', '/landing/')}/${name?.trim()?.replace(/ /g,'-')}/`} className="verMas">Ver más</Link>
                                    : null
                                }
                            </div>
                            
                            {name && <div className="borderT"></div> }
                            <div className="contProductoRecomend" >
                                <div className="productContent sliderView">
                                    <Slider {...settings}>
                                        {
                                            products?.map(({id, title, title_seo, price, sale_price, images, discount, reviews, shipping, features, categories, brand, telmex_months, payment_methods, store}, index) => 
                                                telmex_months?.length 
                                                    ?
                                                        payment_methods
                                                            ?
                                                                payment_methods?.find(idProd => idProd.id === 4468).promotion.length
                                                                    ?
                                                                        <CardProduct 
                                                                            key={id} id={id}
                                                                            nombre={title} nombreSEO={title_seo}
                                                                            precio={price} precioLista={sale_price}
                                                                            foto={images?.length >=1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                                            review={reviews?.score} comments={reviews?.total_reviews}
                                                                            shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                                            categories={categories} brand={brand} 
                                                                            creditCardMonths={payment_methods?.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods?.find(idProd => idProd.id === 4468)?.promotion[0]?.monthly_payments?.length -1]?.months}
                                                                            creditCardPrice={payment_methods?.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods?.find(idProd => idProd.id === 4468)?.promotion[0]?.monthly_payments?.length -1]?.price}
                                                                            telmexMonths={telmex_months[telmex_months.length -1]?.month} telmexPrice={telmex_months[telmex_months.length -1]?.price}
                                                                            index={index}
                                                                            productTotal={products[index]}
                                                                            name={name}
                                                                            seccionPage={seccionPage}
                                                                            seller={store}
                                                                        />
                                                                    :
                                                                        <CardProduct 
                                                                            key={id} id={id}
                                                                            nombre={title} nombreSEO={title_seo}
                                                                            precio={price} precioLista={sale_price}
                                                                            foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                                            review={reviews?.score} comments={reviews?.total_reviews}
                                                                            shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                                            categories={categories} brand={brand}
                                                                            telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                            index={index}
                                                                            productTotal={products[index]}
                                                                            name={name}
                                                                            seccionPage={seccionPage}
                                                                            seller={store}
                                                                        />
                                                                        
                                                                :
                                                                    <CardProduct 
                                                                        key={`${id}_${index}`} id={id}
                                                                        nombre={title} nombreSEO={title_seo}
                                                                        precio={price} precioLista={sale_price}
                                                                        foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                                        review={reviews?.score} comments={reviews?.total_reviews}
                                                                        shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                                        categories={categories} brand={brand}
                                                                        telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                        index={index}
                                                                        productTotal={products[index]}
                                                                        name={name}
                                                                        seccionPage={seccionPage}
                                                                        seller={store}
                                                                        />
                                                    :
                                                        <CardProduct 
                                                            key={id} id={id}
                                                            nombre={title} nombreSEO={title_seo}
                                                            precio={price} precioLista={sale_price}
                                                            foto={images?.length >= 1 ? images[0]?.url : NO_IMAGE} descuento={discount}
                                                            review={reviews?.score} comments={reviews?.total_reviews}
                                                            shipping_price={shipping?.is_free} fulfillment={features?.fulfillment}
                                                            categories={categories} brand={brand}
                                                            index={index}
                                                            productTotal={products[index]}
                                                            name={name}
                                                            seccionPage={seccionPage}
                                                            seller={store}
                                                        />
                                            )
                                        }
                                    </Slider>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                )
            }
        </ErrorBoundary>
    )
}