import React, {useEffect} from "react"
import { Link } from 'react-router-dom'
import SlidersEmarsys from 'components/Emarsys/SlidersEmarsys'
import { functionCartEmarsysEmpty } from 'functions/cartEmarsys'
import { encodeStorage } from 'functions/storageBase64'
import { VARIANTS_PER_PORTAL } from 'constants/general'

export default function EmptyCart() {

    useEffect(() => {
        encodeStorage('cartFormEm',null)
        functionCartEmarsysEmpty()
    },[])

    return (
        <>
            <div className="viewEmptyCart">
                <div className="cartEmpty">
                    <div className="carritoVacio"></div>
                    <h1>¡Bolsa vacía!</h1>
                    <p>Aún no has agregado productos a tu bolsa</p>
                    <Link to="/" className="btn rojo">Continuar comprando</Link>
                </div>
                
                {VARIANTS_PER_PORTAL?.emarsys?.cart
                    ?
                        <div id="cartSlider">
                            <SlidersEmarsys
                                logica={'CART'}
                                idContent={'cartSlider'}
                                title={"Productos que te pueden interesar"}
                                typeView={'sliderView'}
                                cant={32}
                            />
                        </div>
                    : null
                }
            </div>
        </>
    )
}