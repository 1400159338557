import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import InputType from 'components/Forms/InputType'

export default function RecoveryPassForm(props){
    const {
        dataForm,
        warningMessage
    } = props


    const { register, handleSubmit, errors } = useForm()
    const [recoveryPass, setRecoveryPass] = useState()

    //recibe parametros del formulario
    const sendFormRecoveryPass = data => {
        setRecoveryPass({
            "email": data.correo
        })
    }

    //valida si los datos son correctos y manda resultado al dataForm
    useEffect(() => {
        if (recoveryPass) {
            dataForm(recoveryPass)
        }
    }, [recoveryPass])

    //-- Validaciones del formulario
    const valEmail = {
        required: 'El Correo electrónico es requerido',
        pattern: {
            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'El formato de correo electrónico es inválido'
        }
    }

    return(
        <>
            {warningMessage
                ? <p className="errorMessageWarning">{warningMessage}</p>
                : null
            }
            <form onSubmit={handleSubmit(sendFormRecoveryPass)} className="loginPage">
                <InputType
                    type="email"
                    name="correo"
                    id="correo"
                    placeholder="Correo electrónico"
                    refs={register(valEmail)}
                    autocomplete="email"
                    errorMessage={errors.correo && errors.correo.message}
                />
                
                <button className="btn rojo">Recuperar contraseña</button>
            </form>
        </>
    )
}