import React, { useState, useEffect } from 'react';
import { getPedido, getInformacionSeller } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import FooterPortal from 'pages/Footer/FooterDesktop';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import './ayudaDinamicaDesktop.sass';
import { removeDuplicates } from 'functions/removeDuplicates';
import Loading from 'components/Loading';
import { useToken } from 'hooks/useToken';

export function SellerTypDesktop() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];

    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [, setProductoUnico] = useState(false);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [loading, setLoading] = useState(false);

    const [informacionSeller, setInformacionSeller] = useState(false);
    const [informacion, setInformacion] = useState(false);
    const [nameShop, setNameShop] = useState(false);

    let urlCheck = "/img/checkMark.svg";

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedidos
    useEffect(() => {
        window.scrollTo(0, 0);

        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //post informacion del seller
    useEffect(() => {
        getInformacionSeller()
            .then(setInformacionSeller);
    }, [])

    useEffect(() => {
        if (informacionSeller) {
            setInformacion(informacionSeller?.informacion[0]?.message_parent);
        }
    }, [informacionSeller])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido && informacionSeller) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])


    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista96- seller typ -desktop" })
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let producto = [];
        let imagenProducto = [];
        let idStatusProd = [];
        let shopProducto = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {

                if (idProductoEstate !== 0) {
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.id) === Number(idProductoEstate) && producto.push({ "id": elem?.id, "estatus_producto": elem?.status?.id })
                    )

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem?.related_product_id })
                    )
                    let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                    setProductoUnico(limpiaProducto);

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && idStatusProd.push({ "idStatusP": elem?.status_id })
                    )

                    // guarda informacion sobre el vendedor del producto
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && shopProducto.push({ "idTienda": elem?.shop?.idTienda, "nameTienda": elem?.shop?.name })
                    )
                    setNameShop(shopProducto[0].nameTienda);
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {

                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 1000);
            }
        }
    }, [resultadoPedido])


    // regresar a pagina pedidos
    const RegresarPedidos = () => {
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const handleBack = () => {
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">

                        <div className='menuMiCuentaAD'>
                            <MenuMiCuenta />
                        </div>

                        <div className='contenidoGeneralAD'>
                            <div className="boxBtnRegresarDktopRojo">
                                <div className="icoBackClose" onClick={handleBack}></div>
                                <button className="btnBackADLogo" onClick={handleBack}></button>
                            </div>

                            <div className="boxGeneralAD">
                                <div className="boxRightAD marginT60">
                                    <div className="boxCancelacionExitosa">
                                        <div className="boxContenido">
                                            <>
                                                <img alt="check-pal" src={urlCheck} />
                                                <h3>{nameShop} {informacion}</h3>
                                            </>
                                        </div>

                                        <div className="boxBtnRegresar marginT60">
                                            <button className="btnRegresar" onClick={RegresarPedidos}>Regresar a mis pedidos</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <FooterPortal />
        </>
    )

}