import { useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import { encodeStorage, decodeStorage, removeStorage } from 'functions/storageBase64'

export function useReturn(props = false){

    const {
        timeRef = 0,
        firstActive = true,
        name
    } = props

    const [readyActive, setReadyActive] = useState(firstActive)
    
    useEffect(()=>{
        if(firstActive){
            setReadyActive(true)
        }else{
            setReadyActive(false)
        }
        if(name){
            //console.log('nombreHookToken: ',name)
        }
    },[firstActive])
    

    const [click, setClick] = useState(false)
    const history = useHistory();

    
    useEffect(()=>{
        if(click){
            if(decodeStorage('backAutenticate')){
                removeStorage('backAutenticate')
                setTimeout(function(){
                    if(window.history.length >= 3){
                        window.history.go(-3)
                    }else{
                        window.location.replace('/');
                    }
                },700)
            }else{
                history.goBack();
            }
            setClick(false)
        }
    },[click])

    const clickGoBack = () =>{
        setClick(true)
    }
    
    return { clickGoBack }
}