import React, { useEffect, useState} from 'react'
import './style.sass'

import Header from 'pages/Header/HeaderNavegacion'
import Footer from 'pages/Footer/FooterDesktop'
import Error from 'components/Error/ErrorProduct'

export default function Error404 () {

    //- Codigo de instana para track de pagina 
    useEffect(()=>{
        window.ineum('page', 'Error 404 Page');
    },[])

    const [upCP, setUpCP] = useState()
    useEffect(()=>{
        if(upCP){
            //console.log('')
        }
    },[upCP])

    return(
        <>
            <Header setUpCP={setUpCP}/>
            <main>
                <section>
                    <div className="errorPage">
                        <Error />
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}