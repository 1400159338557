import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { InputTypeContact } from 'components/Forms/InputType';
import { SelectTypeFormularioContacto } from 'components/Forms/Select';

export function DudasComentariosDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Dudas y Comentarios Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const areas = [
        { "id": 0, "name": "BOLSA DE TRABAJO" },
        { "id": 1, "name": "CRÉDITO" },
        { "id": 2, "name": "INTERNET" },
        { "id": 3, "name": "OPERACIONES" },
        { "id": 4, "name": "MESA DE REGALO" },
        { "id": 5, "name": "SERVICIO TÉCNICO" }
    ]
    const estados = [
        { "id": 0, "name": "Aguascalientes" },
        { "id": 1, "name": "Baja California" },
        { "id": 2, "name": "Baja California Sur" },
        { "id": 3, "name": "Campeche" },
        { "id": 4, "name": "Chiapas" },
        { "id": 5, "name": "Chihuahua" },
        { "id": 6, "name": "Coahuila" },
        { "id": 7, "name": "Colima" },
        { "id": 8, "name": "Distrito Federal" },
        { "id": 9, "name": "Durango" },
        { "id": 10, "name": "Guanajuato" },
        { "id": 11, "name": "Guerrero" },
        { "id": 12, "name": "Hidalgo" },
        { "id": 13, "name": "Jalisco" },
        { "id": 14, "name": "Estado de México" },
        { "id": 15, "name": "Michoacán" },
        { "id": 16, "name": "Morelos" },
        { "id": 17, "name": "Nayarit" },
        { "id": 18, "name": "Nuevo León" },
        { "id": 19, "name": "Oaxaca" },
        { "id": 20, "name": "Puebla" },
        { "id": 21, "name": "Querétaro" },
        { "id": 22, "name": "Quintana Roo" },
        { "id": 23, "name": "San Luis Potosí" },
        { "id": 24, "name": "Sinaloa" },
        { "id": 25, "name": "Sonora" },
        { "id": 26, "name": "Tabasco" },
        { "id": 27, "name": "Tamaulipas" },
        { "id": 28, "name": "Tlaxcala" },
        { "id": 29, "name": "Veracruz" },
        { "id": 30, "name": "Yucatán" },
        { "id": 31, "name": "Zacatecas" }
    ]
    const [datosContact, setDatosContact] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            area: datosContact.area,
            nombre: datosContact.nombre,
            apellidoP: datosContact.apellidoP,
            apellidoM: datosContact.apellidoM,
            email: datosContact.email,
            estado: datosContact.estado,
            cp: datosContact.cp,
            comentario: datosContact.comentario
        }
    })
    const [datos, setDatos] = useState();
    const [returnForm, SetReturnForm] = useState(false);

    // guarda la informacion enviada en el formulario
    const sendFormInformacionContact = data => {
        setDatos({
            "area": data.area,
            "nombre": data.nombre,
            "apellidop": data.apellidoP,
            "apellidom": data.apellidoM,
            "email": data.email,
            "estado": data.estado,
            "cp": data.cp,
            "comentario": data.comentario
        })
        SetReturnForm(true);
    };

    // al recibir data del formulario se guarda en variable datos contact
    useEffect(() => {
        if (returnForm === true) {
            setDatosContact(datos);
            SetReturnForm(false);
            console.log("informacion que se guarda del formulario:", datos);
        }
    }, [datos, returnForm])

    // envia informacion del formulario al servicio
    useEffect(() => {
        if (datosContact) {
            // SetShowModal(true);
        }
    }, [datosContact])

    //------ Validaciones ------
    const valNombre = {
        required: 'Campo obligatorio',
    };
    const valApellidoP = {
        required: 'Campo obligatorio',
    };
    const valApellidoM = {
        required: 'Campo obligatorio',
    };
    const valEmail = {
        required: 'Campo obligatorio',
        pattern: {
            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'El formato de correo electrónico es inválido'
        }
    };
    const valCp = {
        required: 'Campo obligatorio',
        minLength: {
            value: 5,
            message: 'El CP debe contener 5 dígitos'
        },
        maxLength: {
            value: 5,
            message: 'El CP debe contener 5 dígitos'
        }
    };
    const valArea = {
        required: 'Campo obligatorio',
        minLength: {
            value: 1,
            message: 'El área es requerida'
        }
    };
    const valEstado = {
        required: 'Campo obligatorio',
        minLength: {
            value: 1,
            message: 'El estado es requerido'
        }
    };
    const valComentario = {
        required: 'Campo obligatorio',
        minLength: {
            value: 1,
            message: 'El comentario es requerido'
        }
    };

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Teléfonos de contacto SEARS</p>
                            </div>
                        </section>

                        <div className="contentDudas">
                            <p className="leftCopy">Comunícate con nosotros para resolver tus dudas</p>

                            <div className="dudas2 espacio2">
                                <h3>Atención a compras por Internet</h3>
                                {/* <p className="casos1">Únicamente en los siguientes casos:</p> */}

                                <ul className="ulD2">
                                    <li className="liD2">Dudas referentes a una tu Compra Por Internet: estatus de entrega, devoluciones y cancelaciones</li>
                                    {/* <li className="liD2">Asistencia con contraseñas y activación de cuentas del portal.</li> */}
                                    {/* <li className="liD2">Aclaración de Pagos en Línea.</li> */}
                                </ul>


                                <div className="telefonos2">
                                    <div className="bxtl1">
                                        <div className="bxIcon">
                                            <img src="/img/call.png" alt="icon-telefono"></img>
                                        </div>

                                        <div className="bxTel">
                                            <p><span>Teléfono:</span> <a href="tel: 5553451606">55 53451606</a>. Desde cualquier ciudad del país.</p>

                                            <p className="phorario"><span>Horario:</span> Lunes a Domingo de 08:00 a 21:00 hrs.</p>
                                        </div>
                                    </div>
                                    <div className="bxtl1">
                                        <div className="bxIcon2">
                                            <img src="/img/envelope.png" alt="icon-mail"></img>
                                        </div>

                                        <div className="bxTel">
                                            <p><span>Correo electrónico:</span> <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a></p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="dudas2">
                                <h3>Atención a clientes de Crédito SEARS</h3>
                                {/* <p className="casos">En los Siguientes Casos:</p> */}

                                <ul className="ulD2">
                                    <li className="liD2">Reporte de Tarjeta Robada o Extraviada.</li>
                                    <li className="liD2">Obtén tu Número de Cliente.</li>
                                    <li className="liD2">Saldos y Fechas de Pago.</li>
                                    <li className="liD2">Aclaración o duda general sobre el Estado de Cuenta.</li>
                                </ul>

                                <div className="telefonos2">
                                    <div className="bxtl1">
                                        <div className="bxIcon">
                                            <img src="/img/call.png" alt="icon-telefono"></img>
                                        </div>
                                        <div className="bxTel">
                                            <p className=""> <span className='bold'>Teléfono:</span>  <a href="tel: 5551303900">5551303900</a>. Desde cualquier ciudad del país.</p>
                                            {/* <p className="p1 bold"> Interior de la República: <a href="tel: 018009002123">01-800-900-2123</a></p> */}
                                            <p className="phorario"><span>Horario:</span> Lunes a Domingo de 08:00 a 21:00 hrs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="dudas2">
                                <h3>Atención a Clientes de Servicio Técnico SEARS</h3>

                                <ul className="ulD2">
                                    <li className="liD2">Solicita servicios de reparación/Diagnóstico.</li>
                                    <li className="liD2">Contrata mejoras para tu hogar. </li>
                                </ul>

                                <div className="telefonos2">
                                    <div className="bxtl1">
                                        <div className="bxIcon">
                                            <img src="/img/call.png" alt="icon-telefono"></img>
                                        </div>

                                        <div className="bxTel">
                                            <p><span>Teléfono:</span> <a href="tel: 5551303940">55-51303940</a>. Desde cualquier ciudad del país.</p>

                                            <p className="phorario"><span>Horario:</span> Lunes a Domingo de 08:00 a 20:00 hrs.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* <div className="dudasContacto">
                                <div className="superiorForm">
                                    <h3>Contáctanos</h3>
                                    <p className="copyP">En caso de contar con algún otro comentario, duda o sugerencia puedes hacerla llegar vía electrónica en el siguiente formulario, seleccionando el área a la que va dirigida.</p>

                                    <form className="formContact" onSubmit={handleSubmit(sendFormInformacionContact)}>
                                        <SelectTypeFormularioContacto
                                            type="text"
                                            name="area"
                                            id="area"
                                            placeholder="Área que desea contactar"
                                            refs={register(valArea)}
                                            options={areas}
                                            errorMessage={errors.area && errors.area.message}
                                        // label="Area que desea contactar:"
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="nombre"
                                            id="nombre"
                                            placeholder="Nombre(s)"
                                            texto=""
                                            refs={register(valNombre)}
                                            autocomplete='nombre'
                                            errorMessage={errors.nombre && errors.nombre.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="apellidoP"
                                            id="apellidoP"
                                            placeholder="Apellido Paterno"
                                            texto=""
                                            refs={register(valApellidoP)}
                                            autocomplete='apellidoP'
                                            errorMessage={errors.apellidoP && errors.apellidoP.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="apellidoM"
                                            id="apellidoM"
                                            placeholder="Apellido Materno"
                                            texto=""
                                            refs={register(valApellidoM)}
                                            autocomplete='apellidoM'
                                            errorMessage={errors.apellidoM && errors.apellidoM.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="email"
                                            id="email"
                                            placeholder="Correo Electróncio"
                                            texto=""
                                            refs={register(valEmail)}
                                            autocomplete='email'
                                            errorMessage={errors.email && errors.email.message}
                                        />

                                        <SelectTypeFormularioContacto
                                            type="text"
                                            name="estado"
                                            id="estado"
                                            placeholder="Estado"
                                            refs={register(valEstado)}
                                            options={estados}
                                            errorMessage={errors.estado && errors.estado.message}
                                        // label="Estado:"
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="cp"
                                            id="cp"
                                            placeholder="Código Postal"
                                            texto=""
                                            refs={register(valCp)}
                                            autocomplete='cp'
                                            errorMessage={errors.cp && errors.cp.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="comentario"
                                            id="comentario"
                                            placeholder="Comentario"
                                            texto=""
                                            refs={register(valComentario)}
                                            autocomplete='comentario'
                                            errorMessage={errors.comentario && errors.comentario.message}
                                        />

                                        <div className="btnEnviarMensaje">
                                            <button className="btnEnviarMsj">Enviar</button>
                                        </div>

                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export function DudasComentariosMobile() {
    const history = useHistory();

    const [mostrarUno, setMostrarUno] = useState(true);
    const [mostrarDos, setMostrarDos] = useState(false);

    const areas = [
        { "id": 0, "name": "BOLSA DE TRABAJO" },
        { "id": 1, "name": "CRÉDITO" },
        { "id": 2, "name": "INTERNET" },
        { "id": 3, "name": "OPERACIONES" },
        { "id": 4, "name": "MESA DE REGALO" },
        { "id": 5, "name": "SERVICIO TÉCNICO" }
    ]
    const estados = [
        { "id": 0, "name": "Aguascalientes" },
        { "id": 1, "name": "Baja California" },
        { "id": 2, "name": "Baja California Sur" },
        { "id": 3, "name": "Campeche" },
        { "id": 4, "name": "Chiapas" },
        { "id": 5, "name": "Chihuahua" },
        { "id": 6, "name": "Coahuila" },
        { "id": 7, "name": "Colima" },
        { "id": 8, "name": "Distrito Federal" },
        { "id": 9, "name": "Durango" },
        { "id": 10, "name": "Guanajuato" },
        { "id": 11, "name": "Guerrero" },
        { "id": 12, "name": "Hidalgo" },
        { "id": 13, "name": "Jalisco" },
        { "id": 14, "name": "Estado de México" },
        { "id": 15, "name": "Michoacán" },
        { "id": 16, "name": "Morelos" },
        { "id": 17, "name": "Nayarit" },
        { "id": 18, "name": "Nuevo León" },
        { "id": 19, "name": "Oaxaca" },
        { "id": 20, "name": "Puebla" },
        { "id": 21, "name": "Querétaro" },
        { "id": 22, "name": "Quintana Roo" },
        { "id": 23, "name": "San Luis Potosí" },
        { "id": 24, "name": "Sinaloa" },
        { "id": 25, "name": "Sonora" },
        { "id": 26, "name": "Tabasco" },
        { "id": 27, "name": "Tamaulipas" },
        { "id": 28, "name": "Tlaxcala" },
        { "id": 29, "name": "Veracruz" },
        { "id": 30, "name": "Yucatán" },
        { "id": 31, "name": "Zacatecas" }
    ]

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Dudas y comentarios');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleMostrarUno = () => {
        setMostrarDos(false);
        setMostrarUno(true);
    }
    const handleMostrarDos = () => {
        setMostrarUno(false);
        setMostrarDos(true);
    }

    //------------------------
    const [datosContact, setDatosContact] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            area: datosContact.area,
            nombre: datosContact.nombre,
            apellidoP: datosContact.apellidoP,
            apellidoM: datosContact.apellidoM,
            email: datosContact.email,
            estado: datosContact.estado,
            cp: datosContact.cp,
            comentario: datosContact.comentario
        }
    });
    const [datos, setDatos] = useState();
    const [returnForm, SetReturnForm] = useState(false);

    // guarda la informacion enviada en el formulario de contacto
    const sendFormInformacionContact = data => {
        setDatos({
            "area": data.area,
            "nombre": data.nombre,
            "apellidop": data.apellidoP,
            "apellidom": data.apellidoM,
            "email": data.email,
            "estado": data.estado,
            "cp": data.cp,
            "comentario": data.comentario
        })
        SetReturnForm(true);
    };

    // al recibir data del formulario se guarda en variable datos bancarios
    useEffect(() => {
        if (returnForm) {
            setDatosContact(datos);
            SetReturnForm(false);
            // console.log("datos recibidos por el formulario--> ", datos);
        }
    }, [datos, returnForm])

    // envia informacion del formulario al servicio 
    useEffect(() => {
        if (datosContact) {
            // SetShowModal(true);
        }
    }, [datosContact])

    //------ Validaciones ------
    const valNombre = {
        required: 'Campo obligatorio',
    };
    const valApellidoP = {
        required: 'Campo obligatorio',
    };
    const valApellidoM = {
        required: 'Campo obligatorio',
    };
    const valEmail = {
        required: 'Campo obligatorio',
        pattern: {
            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'El formato de correo electrónico es inválido'
        }
    };
    const valCp = {
        required: 'Campo obligatorio',
        minLength: {
            value: 5,
            message: 'El CP debe contener 5 dígitos'
        },
        maxLength: {
            value: 5,
            message: 'El CP debe contener 5 dígitos'
        }
    };
    const valArea = {
        required: 'Campo obligatorio',
        minLength: {
            value: 1,
            message: 'El área es requerida'
        }
    };
    const valEstado = {
        required: 'Campo obligatorio',
        minLength: {
            value: 1,
            message: 'El estado es requerido'
        }
    };
    const valComentario = {
        required: 'Campo obligatorio',
        minLength: {
            value: 1,
            message: 'El comentario es requerido'
        }
    };

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal contentModalPadd">

                    <div className="contentClose contentC">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Teléfonos de contacto SEARS</p>
                    </div>

                    <div className="dataModal">

                        <div className="barraInferiorDudas">
                            <p className="boldSlider size12px">Comunícate con nosotros para resolver tus dudas</p>

                            <div className="barraDudas">
                                <p className={mostrarUno === true ? 'activaSeccionDudas p2Dudas' : 'noActivaDudas p2Dudas'} onClick={handleMostrarUno}>Compras por Internet</p>
                                <p className={mostrarDos === true ? 'activaSeccionDudas p1Dudas' : 'noActivaDudas p1Dudas'} onClick={handleMostrarDos}>Crédito Sears</p>

                            </div>
                        </div>

                        <div className="boxAN">
                            {mostrarDos &&
                                <div className="bxSlider">


                                    <p className="pSliderL colorRed boldSlider size14px">Atención a clientes de Crédito SEARS</p>

                                    {/* <p className="pSliderL size14px">En los siguientes Casos:</p> */}
                                    <ul className="ulDeclaracionesDisc ulW85 size14px">
                                        <li>Reporta tu Tarjeta Robada o Extraviada.</li>
                                        <li>Obtén tu Número de Cliente.</li>
                                        <li>Saldos y Fechas de Pago.</li>
                                        <li>Aclaración o duda general sobre el Estado de Cuenta.</li>
                                    </ul>

                                    <div className="bxDudasC size14px">
                                        <div className="bxD1">
                                            <a href="tel:5551303900">
                                                <img src="/img/call.png" alt="icon-telefono"></img>
                                            </a>
                                        </div>

                                        <div className="bxD2">
                                            <p className="pd boldSlider">Teléfono: </p>
                                            <a className="pd azulL" href="tel:5551303900">5551303900</a>
                                            <p className="pd pt">Desde cualquier ciudad del país.</p>
                                        </div>
                                    </div>

                                    <div className="bxDudasC size14px">
                                        <div className="bxD1fijo2">
                                        </div>

                                        <div className="bxD2">
                                            <p className="pd">
                                                <span className="boldSlider">Horario:</span> Lunes a Domingo de 08:00 a 20:00 hrs.</p>
                                        </div>
                                    </div>

                                    {/* 
                                    <div className="bxDudasC size14px">
                                        <div className="bxD1">
                                            <a href="tel:018009002123">
                                                <img src="/img/call.png" alt="icon-telefono"></img>
                                            </a>
                                        </div>
                                        <div className="bxD2">
                                            <p className="pd boldSlider">Interior de la República:</p>
                                            <a className="pd azulL" href="tel:018009002123">01-800-900-2123</a>
                                        </div>
                                    </div> */}
                                </div>
                            }

                            {mostrarUno &&
                                <div className="bxSlider">
                                    <>
                                        <p className="pSliderL colorRed boldSlider size14px">Atención a compras por Internet</p>

                                        {/* <p className="pSliderL size14px">Únicamente en los siguientes casos:</p> */}
                                        <ul className="ulDeclaracionesDisc ulW85 size14px">
                                            <li>Dudas Referentes a tu Compra Por Internet: estatus de entrega, devoluciones y cancelaciones.</li>
                                            {/* <li>Asistencia con contraseñas y activación de cuentas del portal.</li> */}
                                            {/* <li>Aclaración de Pagos en Línea.</li> */}
                                        </ul>

                                        <div className="bxDudasC size14px">
                                            <div className="bxD1">
                                                <a href="tel:015553451606">
                                                    <img src="/img/call.png" alt="icon-telefono"></img>
                                                </a>
                                            </div>
                                            <div className="bxD2">
                                                <p className="pd boldSlider">Teléfono: </p>
                                                <a href="tel:5553451606">5553451606</a>
                                                <p>Desde cualquier ciudad del país.</p>
                                            </div>


                                        </div>
                                        <div className="bxDudasC size14px">
                                            <div className="bxD1fijo">
                                            </div>

                                            <div className="bxD2">
                                                <p className="pd">
                                                    <span className="boldSlider">Horario:</span> Lunes a Domingo de 08:00 a 21:00 hrs.</p>
                                            </div>
                                        </div>

                                        <div className="bxDudasC size14px">
                                            <div className="bxD12">
                                                <a href="mailto:sears.internet@sears.com.mx">
                                                    <img src="/img/envelope.png" alt="icon-email"></img>
                                                </a>
                                            </div>
                                            <div className="bxD2">
                                                <p className="pd">
                                                    <span className="boldSlider">Correo electrónico:</span> <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a></p>
                                            </div>
                                        </div>
                                    </>

                                    <>
                                        <p className="pSliderL colorRed boldSlider size14px">Atención a Clientes de Servicio Técnico SEARS</p>

                                        <ul className="ulDeclaracionesDisc ulW85 size14px">
                                            <li>Solicita servicios de reparación/Diagnóstico.</li>
                                            <li>Contrata mejoras para tu hogar.</li>
                                        </ul>


                                        <div className="bxDudasC size14px">
                                            <div className="bxD1">
                                                <a href="tel:5553451606">
                                                    <img src="/img/call.png" alt="icon-telefono"></img>
                                                </a>
                                            </div>
                                            <div className="bxD2 size14px">
                                                <p className="pd boldSlider">Teléfono: </p>
                                                <a className="pd azulL" href="tel:5551303940">55 51303940</a>
                                                <p className="pd pt">Desde cualquier ciudad del país.</p>
                                            </div>
                                        </div>

                                        <div className="bxDudasC size14px">
                                            <div className="bxD1fijo2">
                                            </div>

                                            <div className="bxD2">
                                                <p className="pd">
                                                    <span className="boldSlider">Horario:</span> Lunes a Domingo de 08:00 a 20:00 hrs.</p>
                                            </div>
                                        </div>
                                    </>


                                    {/* <p className="pSliderL grisClaro">En caso de contar con algún otro comentario, duda o sugerencia puedes hacerla llegar vía electrónica en el siguiente formulario, seleccionando el área a la que va dirigida.</p> */}

                                    {/* <form className="formContact" onSubmit={handleSubmit(sendFormInformacionContact)}>
                                        <SelectTypeFormularioContacto
                                            type="text"
                                            name="area"
                                            id="area"
                                            placeholder="Área que desea contactar"
                                            refs={register(valArea)}
                                            options={areas}
                                            errorMessage={errors.area && errors.area.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="nombre"
                                            id="nombre"
                                            placeholder="Nombre(s)"
                                            refs={register(valNombre)}
                                            autocomplete='nombre'
                                            errorMessage={errors.nombre && errors.nombre.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="apellidoP"
                                            id="apellidoP"
                                            placeholder="Apellido Paterno"
                                            refs={register(valApellidoP)}
                                            autocomplete='apellidoP'
                                            errorMessage={errors.apellidoP && errors.apellidoP.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="apellidoM"
                                            id="apellidoM"
                                            placeholder="Apellido Materno"
                                            refs={register(valApellidoM)}
                                            autocomplete='apellidoM'
                                            errorMessage={errors.apellidoM && errors.apellidoM.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="email"
                                            id="email"
                                            placeholder="Correo Electróncio"
                                            refs={register(valEmail)}
                                            autocomplete='email'
                                            errorMessage={errors.email && errors.email.message}
                                        />

                                        <SelectTypeFormularioContacto
                                            type="text"
                                            name="estado"
                                            id="estado"
                                            placeholder="Estado"
                                            refs={register(valEstado)}
                                            options={estados}
                                            errorMessage={errors.estado && errors.estado.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="cp"
                                            id="cp"
                                            placeholder="Código Postal"
                                            refs={register(valCp)}
                                            autocomplete='cp'
                                            errorMessage={errors.cp && errors.cp.message}
                                        />

                                        <InputTypeContact
                                            type="text"
                                            name="comentario"
                                            id="comentario"
                                            placeholder="Comentario"
                                            refs={register(valComentario)}
                                            autocomplete='comentario'
                                            errorMessage={errors.comentario && errors.comentario.message}
                                        />


                                        <div className="btnEnviarMensaje">
                                            <button className="btnEnviarMsj">Enviar</button>
                                        </div>

                                    </form> */}
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export function DudasComentarios() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Dudas y Comentarios');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <DudasComentariosDesktop />

                : isMobile === 'movile'
                    ? null
                    : <DudasComentariosMobile />
            }
        </>
    )
}