import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.sass';

export default function BoxNuestraCompania() {

    const [menuView, setMenuView] = useState(false);

    const toggleClass = () => {
        setMenuView(!menuView);
    };

    return (
        <div className="boxClaroShop">
            <p onClick={toggleClass} className={menuView ? 'active' : null}>NUESTRA COMPAÑIA</p>
            <ul>
                <li><Link to={`/acerca-nosotros/`}  >Acerca de Nosotros</Link></li>
                <li><Link to={`/localizador-tiendas/`} >Localizador de Tiendas</Link></li>
                <li><a href={"http://facturaelectronica.sears.com.mx/"} target="_blank" rel="noopener noreferrer">Facturación electrónica</a></li>
                <li>
                    {/* <Link to={"/Mesa_Regalos?utm_source=direct&utm_medium=menu-superior-home-sears-com&utm_campaign=mesa_de_regalos"}>Mesa de Regalos</Link> */}
                    <a href="/Mesa-de-Regalos/" title="Mesa de Regalos">Mesa de Regalos</a>
                </li>
            </ul>
        </div>
    )
}
