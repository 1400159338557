import React from 'react'
import './style.sass'
import { Link } from "react-router-dom"

import { useStore } from 'context/storeProvider';

export default function CartHeaderCount(props) {
    const {
        ruta = '/login', 
        cantidad = 0
    } = props

    const contextProduct = useStore()

    const cartCount = contextProduct?.cart?.cart_Count

    return(
        <Link
            to={ruta}
            className="cartIcon"
            aria-label="Ir a Carrito"
        >
            <span className="contCart">{cartCount > 99 ? '+99' : cartCount}</span>
            <span className="textReference"><span className="smallText">
                {/* Mi bolsa  */}
                </span>
                {/* de compras */}
                </span>
        </Link>
    )
}