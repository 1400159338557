import React from 'react';
import './style.sass'
import ReactDOM from 'react-dom';

function Modal({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        formasPago
    } = props

    return (
        <div className={`modal ${type}`}>
            <div className="contentModal">
                <div className="contentCloseAN2">
                    <div className="btn closeAN2" onClick={onClose}></div>
                    {title ? <p className="title centrado">{title}</p> : null}
                </div>
                <div className="dataModal11">
                    <p className="copyPrincipal">Comprar en Sears.com.mx es muy fácil con nuestras múltiples formas de pago. <span className="bold">¡Elige la que más te convenga!</span></p>

                    <div className="divFP">
                        {formasPago.map(data =>
                            <div className="fpInt">
                                <div className="uno">
                                    <img src={data.url_logo} alt={"Logo"} loading="lazy" width="50" height="50" />
                                </div>
                                <div className="dos">
                                    <p>{data.name} </p>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}


export default function ModalPaymentMethods({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        formasPago
    } = props;

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} formasPago={formasPago}>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}