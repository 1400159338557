import React, { useState, Fragment, useRef, useEffect } from 'react';
import './sliderProductoDesktop.sass';
import { useHistory, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import ProgressiveImage from 'react-progressive-graceful-image'
import { repareImage, repareTextHtml } from 'functions/repareDataService';
import { NO_IMAGE, IMAGE_THUMB, SIZE_IMAGE_THUMB } from 'constants/general'

import SliderModal from './sliderModalProducto';
import { ShareComponentDetail } from 'components/Share';
import { VideoProductComponent } from 'components/ProductDetail/VideoProduct';
import BtnWishlist from 'components/Wishlist';

export default function SliderProductoDesktop({ ...props }) {
    const {
        id,
        dataImage,
        dataVideo,
        title,
        category,
        brand,
        sale_price,
        seller
    } = props

//console.log("props slider produ deskclasic card ", props)
    const history = useHistory()
    const location = useLocation()
    const urlLink = window.location.href
    const hashLink = window.location.hash

    const [position, setPosition] = useState({ value: 0 })
    //const [isShowing, setIsShowing] = useState(true);
    const [indexModal, setIndexModal] = useState(0)
    const [indexAbrir, setIndexAbrir] = useState(null)
    const slider1 = useRef(null)
    const slider2 = useRef(null)

    const [showModal, SetShowModal] = useState(false)
    const showModalSliderProduct = () => {
        SetShowModal(true)
        document.querySelector('body').classList.add('open')
    }

    const handleClose = () => {
        history.push(location.pathname)
    }
    useEffect(() => {
        if (hashLink === "#modalSliderProduct") {
            showModalSliderProduct()
        } else {
            SetShowModal(false)
            document.querySelector('body').classList.remove('open')
        }
    }, [hashLink, urlLink])


    const settings = {
        beforeChange: (current, next) => {
            setPosition({ value: next })
            // if(dataVideo){
            //     document.querySelector('.ytVideo').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
            // }
        },
        afterChange: current => {
            setPosition({ value: current })
            // if(dataVideo){
            //     document.querySelector('.ytVideo').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
            // }
        }
    };

    // if (isShowing) {
    //     document.querySelector('body').classList.add('open');
    // }
    // if (!isShowing) {
    //     document.querySelector('body').classList.remove('open');
    // }

    function toggle(e) {
        //setIsShowing(!isShowing);
        setIndexAbrir(Number(e.target.id))
        history.push('#modalSliderProduct')
    }


    const fijarIndex = (index) => {
        setIndexModal(index)
        slider2.current.slickGoTo(index)
        // if(dataVideo){
        //     document.querySelector('.ytVideo').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        // }
    }

    const cambiarImage = (indice, order) => {
        // setNindice(indice)
        // // setTimeout(function () {
        //console.log(" on mouse enter")
        setIndexModal(indice)
        slider2.current.slickGoTo(indice)
        // // }, 1000)
    }

    useEffect(() => {
        if (id) {
            setIndexModal(0)
            slider2.current.slickGoTo(0)
        }
    }, [id])

    return (
        <Fragment>
            <div className="contImagesProductDesktop" id="contImages">
                {/* <BtnWishlist
                    id={id}
                    nombre={title}
                    precioLista={sale_price}
                    brand={brand}
                    categories={category}
                    index='1'
                /> */}
                <ShareComponentDetail title={title} />
                {/* <p className="countImageDesktop">{(position.value + 1)} / {dataImage.length}</p> */}

                <div className="contSliderDesktop">
                    {dataImage
                        ?
                            <Fragment>
                                <div className="grandeS media-container">
                                    <Slider {...settings}
                                        slidesToShow={1}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                        arrows={false}
                                        speed={600}
                                        asNavFor={slider2.current}
                                        ref={slider1}
                                        infinite={true}
                                        slidesToScroll={1}
                                    >
                                        {dataImage?.map(({ url, order }, ind) =>
                                            ind === 0
                                                ?
                                                    <ProgressiveImage
                                                        src={url != null ? url?.indexOf('?') >= 0 ? `${repareImage(url).replace('publicidad', 'mkt')}&scale=550` : `${repareImage(url).replace('publicidad', 'mkt')}?scale=550` : NO_IMAGE}
                                                        placeholder={url != null ? url?.indexOf('?') >= 0 ? `${repareImage(url).replace('publicidad', 'mkt')}&scale=350` : `${repareImage(url).replace('publicidad', 'mkt')}?scale=350` : NO_IMAGE}
                                                        key={`big_${order}`}
                                                    >
                                                        {(src) => <img
                                                                className="imagenActual"
                                                                fetchpriority={ind === 0 ? 'High' :'low'}
                                                                loading={ind === 0 ? 'eager' :'lazy'}
                                                                src={src}
                                                                alt={title}
                                                                key={`big_${order}`}
                                                                width="500"
                                                                height="500"
                                                                decoding="async"
                                                                onClick={toggle}
                                                                id={ind}
                                                                data-slide-index={ind}
                                                            />
                                                        }
                                                    </ProgressiveImage>
                                                :
                                                    <img
                                                        className="imagenActual"
                                                        fetchpriority={ind === 0 ? 'High' :'low'}
                                                        loading={ind === 0 ? 'eager' :'lazy'}
                                                        src={url != null ? url?.indexOf('?') >= 0 ? `${repareImage(url).replace('publicidad', 'mkt')}&scale=550` : `${repareImage(url).replace('publicidad', 'mkt')}?scale=550` : NO_IMAGE}
                                                        alt={title}
                                                        key={`big_${order}`}
                                                        width="500"
                                                        height="500"
                                                        decoding="async"
                                                        onClick={toggle}
                                                        id={ind}
                                                        data-slide-index={ind}
                                                    />
                                        )}
                                    </Slider >
                                </div>
                                <div className="pequeS media-container">
                                    {/* <div style={{ display: 'none' }}> */}
                                    <Slider {...settings}
                                        // vertical={true}
                                        // infinite={true}
                                        // slidesToShow={4}
                                        slidesToScroll={1}
                                        // verticalSwiping={true}
                                        // speed={600}
                                        swipe={false}
                                        swipeToSlide={false}
                                        focusOnSelect={true}
                                        asNavFor={slider1.current}
                                        slickGoTo={indexModal}
                                        ref={slider2}
                                        infinite={false}
                                    >
                                        {/* {dataVideo &&
                                            dataVideo.map(({ order }, i) =>
                                                <img className="imagenInferiorActual" loading="lazy" src="img/imgPlayerVideo.png" alt={title} key={order} width="50" height="50" id={order} data-slide-index={i} />
                                            )
                                        } */}
                                        {dataImage?.map(({ url, order }, ind) =>
                                            ind === 0
                                                ?
                                                    <ProgressiveImage
                                                        src={url != null ? url?.indexOf('?') >= 0 ? `${repareImage(url).replace('publicidad', 'mkt')}&scale=50` : `${repareImage(url).replace('publicidad', 'mkt')}?scale=50` : NO_IMAGE}
                                                        placeholder={url != null ? url?.indexOf('?') >= 0 ? `${repareImage(url).replace('publicidad', 'mkt')}&scale=350` : `${repareImage(url).replace('publicidad', 'mkt')}?scale=350` : NO_IMAGE}
                                                        key={`small_${order}`}
                                                    >
                                                        {(src) => <img
                                                                className="imagenInferiorActual"
                                                                fetchpriority={ind === 0 ? 'High' :'low'}
                                                                loading={ind === 0 ? 'eager' :'lazy'}
                                                                src={src}
                                                                alt={title}
                                                                key={`small_${order}`}
                                                                width="50"
                                                                height="50"
                                                                decoding="async"
                                                                id={ind}
                                                                data-slide-index={ind}
                                                            />
                                                        }
                                                    </ProgressiveImage>
                                                :
                                                    <img
                                                        className="imagenInferiorActual"
                                                        loading="eager"
                                                        fetchpriority={'High'}
                                                        src={url != null ? url?.indexOf('?') >= 0 ? `${repareImage(url).replace('publicidad', 'mkt')}&scale=${SIZE_IMAGE_THUMB}` : `${repareImage(url).replace('publicidad', 'mkt')}?scale=${SIZE_IMAGE_THUMB}` : NO_IMAGE}
                                                        alt={title}
                                                        key={`small_${order}`}
                                                        width="50"
                                                        height="50"
                                                        decoding="async"
                                                        id={order}
                                                        data-slide-index={ind}
                                                    />
                                        )}
                                    </Slider>
                                    {/* </div> */}

                                    {/* <div className="prueba">
                                        {dataImage.map(({ url, order }, i) => i < 5 &&
                                            <div className="marcoImg">
                                                <img className="imagenInferiorActual" onMouseEnter={() => cambiarImage(i, order)} loading="lazy" src={url} alt={title} key={order} width="500" height="500" id={order} data-slide-index={i} />
                                            </div>
                                        
                                        )}
                                    </div> */}
                                </div>

                                {showModal &&
                                    <SliderModal
                                        indexAbrir={indexAbrir}
                                        setIndexAbrir={setIndexAbrir}
                                        dataImage={dataImage}
                                        setIsShowing={handleClose}
                                        fijarIndex={fijarIndex}
                                    />
                                }

                            </Fragment>
                        : 
                            <Fragment>
                                <div className="grandeS media-container">
                                    <img
                                        className="imagenActual"
                                        fetchpriority='low'
                                        loading='lazy'
                                        src={NO_IMAGE}
                                        alt={title}
                                        width="550"
                                        height="550"
                                        decoding="async"
                                        id="1"
                                        data-slide-index="1"
                                    />
                                </div>
                                <div className="pequeS media-container">
                                    <Slider {...settings}
                                        slidesToScroll={1}
                                        swipe={false}
                                        swipeToSlide={false}
                                        focusOnSelect={true}
                                        asNavFor={slider1.current}
                                        slickGoTo={indexModal}
                                        ref={slider2}
                                        infinite={false}
                                    >
                                        <img
                                            className="imagenInferiorActual"
                                            fetchpriority='low'
                                            loading='lazy'
                                            src={NO_IMAGE}
                                            alt={title}
                                            width="50"
                                            height="50"
                                            decoding="async"
                                            id="1"
                                            data-slide-index="1"
                                        />
                                    </Slider>
                                </div>
                            </Fragment>
                    }
                </div>
                <div className="contDataSliderDesktop">
                    <div className="contenedorCantidadWish">
                        {/* <p className="countImageDesktop">{(position.value + 1)} / {dataVideo ? dataImage.length + dataVideo.length : dataImage.length }</p> */}
                        <p className="countImageDesktop">{(position?.value + 1)} / {dataImage?.length}</p>
                        <VideoProductComponent video={dataVideo} />
                        {/* <ShareComponentDetail title={title} /> */}
                        <BtnWishlist
                            id={id}
                            nombre={repareTextHtml(title)}
                            precioLista={sale_price}
                            brand={brand}
                            categories={category}
                            index='1'
                            seller={seller}
                        />
                    </div>
                </div>
            </div>
        </Fragment >
    )
}