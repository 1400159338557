import { useState, useEffect } from 'react'
import { VARIANTS_PER_PORTAL } from 'constants/general';

export function useFilterVerification(searchResults) {

    // para saber si los resultados contienen el filtro: fulfillment, review, envioGratis
    const [tieneFulfillment, setTieneFulfillment] = useState(false);
    const [tieneReview, setTieneReview] = useState(false);
    const [tieneEnvioGratis, setTieneEnvioGratis] = useState(false);
    const [valorDescuento, setValorDescuento] = useState(0);
    const [minimo, setMinimo] = useState(0);
    const [maximo, setMaximo] = useState(0);
    const arregloDescuento = [];
    const arregloEnvioGratis = [];
    const arregloFullfilment = [];
    const arregloReview = [];
    const arregloMin = [];
    const arregloMax = [];

    useEffect(() => {
        if (searchResults) {
            if (searchResults.searchResult) {
                if (searchResults.searchResult.GSP.RES.M !== 0) {

                    VARIANTS_PER_PORTAL.express === true &&
                        searchResults.searchResult.GSP.RES.PARM.PMT.map((opcion, i) =>
                            opcion.NM === 'fulfillment' &&
                                opcion.PV
                                ? opcion.PV.length > 0
                                    ? opcion.PV.map(opc => arregloFullfilment.push(Number(opc.V)))
                                    : [opcion.PV].map(opc => arregloFullfilment.push(Number(opc.V)))
                                : null
                        )


                    searchResults.searchResult.GSP.RES.PARM.PMT.map((opcion, i) =>
                        opcion.NM === 'review' &&
                            opcion.PV
                            ? opcion.PV.length > 0
                                ? opcion.PV.map(opc => arregloReview.push(Number(opc.V)))
                                : [opcion.PV].map(opc => arregloReview.push(Number(opc.V)))
                            : null
                    )

                    searchResults.searchResult.GSP.RES.PARM.PMT.map((opcion, i) =>
                        opcion.NM === 'shipping_price' &&
                            opcion.PV
                            ? opcion.PV.length > 0
                                ? opcion.PV.map(opc => arregloEnvioGratis.push(Number(opc.V)))
                                : [opcion.PV].map(opc => arregloEnvioGratis.push(Number(opc.V)))
                            : null
                    )

                    // PARA OBTENER EL NUMERO MAYOR DE DESCUENTO 
                    searchResults.searchResult.GSP.RES.PARM.PMT.map((opcion, i) =>
                        opcion.NM === 'discount' &&
                            opcion.PV
                            ? opcion.PV.length > 0
                                ? opcion.PV.map(opc => arregloDescuento.push(Number(opc.V)))
                                : [opcion.PV].map(opc => arregloDescuento.push(Number(opc.V)))
                            : null
                    )
                    
                    if (arregloDescuento.length > 0) {
                        if (Math.max(...arregloDescuento) > 9)
                            setValorDescuento(Math.max(...arregloDescuento));
                        else {
                            setValorDescuento(0)
                        }
                    }

                    if (arregloEnvioGratis.length > 0) {
                        if (Math.max(...arregloEnvioGratis) > 0)
                            setTieneEnvioGratis(true);
                        else
                            setTieneEnvioGratis(false);
                    }
                    if (arregloFullfilment.length > 0) {
                        if (Math.max(...arregloFullfilment) > 0)
                            setTieneFulfillment(true);
                        else
                            setTieneFulfillment(false);
                    }
                    if (arregloReview.length > 0) {
                        if (Math.max(...arregloReview) > 0)
                            setTieneReview(true);
                        else
                            setTieneReview(false);
                    }
                    // PARA OBTENER EL PRECIO MAX Y PRECIO MIN
                    searchResults.searchResult.GSP.RES.PARM.PMT.map((opcion, i) =>
                        opcion.NM === 'sale_price' &&
                            opcion.PV
                            ? opcion.PV.length > 0
                                ? opcion.PV.map(opc => arregloMin.push(opc.MIN))
                                : [opcion.PV].map(opc => arregloMin.push(opc.MIN))
                            : null
                    )
                    searchResults.searchResult.GSP.RES.PARM.PMT.map((opcion, i) =>
                        opcion.NM === 'sale_price' &&
                            opcion.PV
                            ? opcion.PV.length > 0
                                ? opcion.PV.map(opc => arregloMax.push(opc.MAX))
                                : [opcion.PV].map(opc => arregloMax.push(opc.MAX))
                            : null
                    )
                    if (arregloMin.length > 0) {
                        setMinimo(Math.min(...arregloMin));
                        setMaximo(Math.max(...arregloMax));
                    }
                    // console.log("results -------> ", searchResults.searchResult.GSP.RES.PARM, " minimo: ", Intl.NumberFormat().format(minimo), " Maximo: ", maximo.toLocaleString("en-US"))

                }
            }
        }
    }, [searchResults])

    return {
        tieneFulfillment,
        tieneEnvioGratis,
        tieneReview,
        valorDescuento,
        minimo,
        maximo

    }
}