import React,{useEffect, useState} from 'react'
import {Helmet} from "react-helmet"
import {useHistory} from 'react-router-dom'
import Header from 'pages/Header/HeaderNavegacion'
import BarraCP from 'components/BarCP/BarHeaderCP'
import Footer from 'pages/Footer/FooterSimple'
import Loading from 'components/Loading'
import ErrorType from 'pages/Error/ErrorType'
import SliEmarsys from 'pages/Recomendation'
import MD5 from "crypto-js/md5"

import { decodeStorage } from 'functions/storageBase64'
import { useStorage64 } from 'hooks/useStorageBase64'



export default function Prueba(){

    //- Codigo de instana para track de pagina 
    useEffect(()=>{
        window.ineum('page', 'Test Page');
    },[])

    const history = useHistory()
    const params = history.pathname
    const codigoP = decodeStorage('codigoPostal')

    const profileData = decodeStorage('profileData')


    const cart1 = decodeStorage('idCarrito')
    const cart2 = profileData.correo
    const cart3 = MD5(`${profileData.correo}`).toString()
    const cart4 = MD5("bulungas01@mailinator.com").toString()
    const cart5 = MD5('BULUNGAS01@MAILINATOR.COM').toString()

    console.log('cart1: ',cart1)
    console.log('cart3: ',cart3)

    console.log('cart2: ',cart2)

    
    const [codigoPostal , setCodigoPsotal] = useState(codigoP)
    const [loading, setLoading ] = useState(true)
    const [update, setUpdate] = useState(403)

    const statusCode = update
    const errorText = 'asdada dadsasdasd'

    /*prueba */
    var prueba2 = decodeStorage('prueba2')
    const [prueba, setPrueba] = useStorage64('prueba', '');

    // useEffect(()=>{
    //     if (prueba2 == []) {
    //         encodeStorage('prueba2', 'klaskdjaklsdjasd asdaskldjaklsdklasdasd')
    //     }
    //     console.log('prueba2  ', prueba2)
    // },[prueba2])

    // useEffect(()=>{
    //     if(!prueba){
    //         setPrueba('klaskdjaklsdjasd asdaskldjaklsdklasdasd as90890890890 09809')
    //     }
    //     //console.log('prueba ',prueba)
    // },[prueba])

    const dateActual = new Date()
    const valuetkn = dateActual.getTime()

    //console.log('SHA1 ',SHA1(valuetkn) )
    // removeStorage('prueba2')
    // removeStorage('prueba')

    /*prueba */


    useEffect(() => {
        setLoading(false)
    },[codigoPostal])

    const returnBtn = (val) =>{
        history.push(val)
    }

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val)
        setUpdateCPBar(val)
    }
    const [upCP, setUpCP] = useState()
    useEffect(()=>{
        if(upCP){
            //console.log('')
        }
    },[upCP,updateCPBar])

    return(
        <>
            { loading && <Loading  type="telmexLoading"/> }
            <Helmet>
				<title>SEARS - Me entiende</title>
				<link rel="canonical" href={`https://www.sears.com.mx${params}`} />
				<meta name="Description" content="SEARS.COM.MX | Envíos a todo México · Más de 500 mil productos con todas las Formas de Pago  Compras 100% Seguras · Lo mejor en Moda, Línea blanca, Pantallas, Computo, Celulares, Juguetes, Muebles, Ferretería y más · Click & Recoge en Tienda" />
			</Helmet>


            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar}/>
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP"/>
            </section>


            <main>
                <section>
                    {statusCode === 200
                        ?
                            <div className="container">
                                <h1>Tiendas Oficiales</h1>
                            </div>
                        : null
                    }
                    <SliEmarsys />
                    <ErrorType
                        section="cart"
                        codeStatus={statusCode}
                        errorText={errorText}
                        acceptReturn={returnBtn}
                        searchQ=''
                        suggestion=''
                    />
                </section>
            </main>
            <Footer />

        </>
    )
}