import React, { useEffect, useState, useRef } from 'react'
import { useForm } from "react-hook-form"
import InputType from 'components/Forms/InputType'
import InputPassword from 'components/Forms/InputPassword';

export default function UpdatePassForm(props){
    const {
        dataForm,
        warningMessage
    } = props


    const { register, handleSubmit, errors, watch } = useForm()
    const [updatePass, setUpdatePass] = useState()

    //recibe parametros del formulario
    const sendFormupdatePass = data => {
        setUpdatePass({
            "password": data.password,
        })
    }

    //valida si los datos son correctos y manda resultado al dataForm
    useEffect(() => {
        if (updatePass) {
            dataForm(updatePass)
        }
    }, [updatePass])

    //-- Validaciones del formulario
    const valPassword = {
        required: 'La contraseña es requerida',
        minLength: {
            value: 8,
            message: 'La contraseña al menos debe de contener 8 caracteres'
        },
        maxLength: {
            value: 20,
            message: 'La contraseña no puede sobrepasar los 20 caracteres'
        },
        validate: {
            letras: value => {
                let regex = /^(?=.*[a-záéíóúüñ,A-ZÁÉÍÓÚÜÑ])/;
                if(regex.test(value)){
                    return 
                } else{
                    return "La contraseña debe contener al menos 1 letra"
                }
            },
            numeros: value => {
                let regex = /^(?=.*\d)/;
                if(regex.test(value)){
                    return 
                } else{
                    return "La contraseña debe contener al menos 1 número"
                }
            } 
        } 
    }
    const password = useRef()
    password.current = watch("password", "")
    const valRePassword = {
        validate: value => value === password.current || "Los campos no son iguales"
        
    }

    return(
        <>
            {warningMessage
                ? <p className="errorMessageWarning">{warningMessage}</p>
                : null
            }
            <form onSubmit={handleSubmit(sendFormupdatePass)} className="loginPage">
                <InputPassword
                    name="password"
                    id="password"
                    placeholder="Nueva contraseña"
                    refs={register(valPassword)}
                    errorMessage={errors.password && errors.password.message}
                    autocomplete="new-password"
                />

                <InputPassword
                    name="passwordCopy"
                    id="passwordCopy"
                    placeholder="Confirmar contraseña"
                    refs={register(valRePassword)}
                    errorMessage={errors.passwordCopy && errors.passwordCopy.message}
                    autocomplete="passwordCopy"
                />
                
                <button className="btn rojo">Generar</button>
            </form>
        </>
    )
}