import React, { useState, useEffect } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useIsMobile } from 'hooks/useIsMobile';
import SinDireccionesDesktop from './sindireccionesDesktop';
import SinDireccionesMobile from './sindireccionesMobile';
import { decodeStorage } from 'functions/storageBase64';

export default function SinDirecciones() {

    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account | No Addresses Page');
        window.scrollTo(0, 0);
    }, [])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
        setUpdateCPBar(val);
    }

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    //recibe la direccion del CP   
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPostal] = useState(codigoP);
    const changeDirectionBar = (val) => {
        setCodigoPostal(val);
        setUpdateCPBar(val);
    };

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} headerEnCategoria={"headerListaDeseos"} tituloPagina="Mis direcciones" />
            {isMobile && (isMobile === 'desktop' || isMobile === 'movile')
                ? <section className="barCpResponsive">
                    <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
                </section>
                : null
            }

            {isMobile
                ? isMobile === 'desktop'
                    ? <SinDireccionesDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <SinDireccionesMobile />
                : null
            }

            <Footer />
        </>
    )
}