import React, {useEffect, useState} from 'react'
import './style.sass'

export default function StarsFive({...props}){
    const {
        score,
        comments
    } = props
    //console.log(score)
    //console.log(comments)

    const cero5 = 0.5
    const cero0 = 0
    const [classStart, setClassStart] = useState(false)
    const valuateStars = () =>{
        let decimales = score % 1
        if(Number(decimales) < Number(cero5) || Number(decimales) === Number(cero0)){
            setClassStart(`star_${ Math.floor(Number.parseFloat(score).toFixed(1)) }`)
        }else if(Number(decimales) >= Number(cero5)){
            setClassStart(`star_${ Math.floor(Number.parseFloat(score).toFixed(1)) }_5`)
        }
    }
    useEffect(()=>{
        valuateStars()
    },[score])


    return(
        <div className="contStarsModule">
            <div className={`stars ${classStart}`}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            {comments &&
                comments != 0 && comments !== "null"
                    ? <p className="comments">({comments})</p>
                    : null
            }
        </div>
    )
}