import { PENDIENTE, PEDIDOS_PENDIENTES_AUTORIZAR, PEDIDOS_PENDIENTES_PAGO, PEDIDOS_PRODUCTO_ENTREGADO } from 'constants/general';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getPedido, getRefoundInfo } from 'services/AyudaDinamicaServices';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeStorage } from 'functions/storageBase64';
import Loading from 'components/Loading';
import { useIsMobile } from 'hooks/useIsMobile';
import { CancelarPedidoDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista5-pedidoCancelado-desktop.js';
import { useToken } from 'hooks/useToken';

export function BloqueFormasPago({ payment, setResultRefoundProp, setLoading }) {

    const [resultadoRefound, setResultadoRefound] = useState(false);
    const [texto, setTexto] = useState("");

    // get refoundInfo
    useEffect(() => {
        let formaPago = payment;

        getRefoundInfo(formaPago)
            .then(setResultadoRefound)
    }, [])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoRefound && resultadoRefound.refound) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoRefound])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "bloqueFPmobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    // almacena copy obtenido
    useEffect(() => {
        if (resultadoRefound) {
            setResultRefoundProp(resultadoRefound);

            if (resultadoRefound.refound) {
                setTexto(resultadoRefound.refound.info);
            }
            else if (resultadoRefound.statusCode && resultadoRefound.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getRefoundInfo(payment)
                        .then(setResultadoRefound);
                }, 2000);
            }
        }
    }, [resultadoRefound])

    return (
        <div className="textFp">
            <p>{texto}</p>
        </div>
    )
}

export function CancelarPedidoMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];

    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [productoUnico, setProductoUnico] = useState(false);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [resultadoRefoundProp, setResultRefoundProp] = useState(false);
    const [estatusPedido, setEstatusPedido] = useState(0);
    const [payment, setPayment] = useState([]);

    const [saveStatus,] = useStorage64('saveStatus');
    const [saveStatusProducto,] = useStorage64('saveStatusProducto');
    const [saveIdStatusProducto] = useStorage64('saveIdStatusProducto', '');
    const [estatusProducto, setEstatusProducto] = useState(0);
    const [loading, setLoading] = useState(false);
    const [idStatusProducto, setIdStatusProducto] = useState(false);

    let urlCheck = "/img/checkMark.svg";
    let urlLogo = "/img/logoSE.svg";

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista5- cancelacion exitosa-mobile" })
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    // get pedidos
    useEffect(() => {
        window.scrollTo(0, 0);
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido && (Number(estatusPedido) !== Number(PEDIDOS_PENDIENTES_PAGO))) {
            if (resultadoRefoundProp) {
                setLoading(false);
            }
        }
        else if (resultadoPedido && resultadoPedido.pedido && Number(estatusPedido) === Number(PEDIDOS_PENDIENTES_PAGO)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, resultadoRefoundProp])

    useEffect(() => {
        let producto = [];
        let idStatusProd = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                setEstatusPedido(saveStatus);
                setPayment(resultadoPedido.pedido[0].payment_method);

                if (idProductoEstate !== 0) {
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.id) === Number(idProductoEstate) && producto.push({ "id": elem.id, "estatus_producto": elem.status.id })
                    )
                    let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                    setProductoUnico(limpiaProducto);

                    setEstatusProducto(saveStatusProducto);

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && idStatusProd.push({ "idStatusP": elem.status_id })
                    )
                    setIdStatusProducto(idStatusProd[0].idStatusP);
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
            
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 2000);
            }
        }
    }, [resultadoPedido])

    // regresar a pagina pedidos
    const RegresarPedidos = () => {
        removeStorage('saveStatus');
        removeStorage('saveStatusProducto');
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">

                <div className="contentCloseX">
                    <div className="imgClaro">
                        <img alt="Claroshop" src={urlLogo} />
                    </div>
                    <div className="btn closeX" onClick={RegresarPedidos} title="Cerrar"></div>
                </div>

                <div className="contentModal">
                    <div className="boxCancelacionExitosa">
                        <div className="boxContenido">

                            {/* Bloque copy pedido ha sido cancelado */}
                            {(!PEDIDOS_PRODUCTO_ENTREGADO.includes(Number(saveIdStatusProducto)) && (Number(estatusPedido) === Number(PEDIDOS_PENDIENTES_PAGO) || PEDIDOS_PENDIENTES_AUTORIZAR.includes(Number(estatusPedido)) || idProductoEstate === 0)) &&
                                <>
                                    <img alt="check-eliminado" src={urlCheck} />
                                    <h3>Tu pedido ha sido cancelado.</h3>
                                </>
                            }
                            {(Number(estatusPedido) !== Number(PEDIDOS_PENDIENTES_PAGO)) && !PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido) &&
                                (idProductoEstate !== 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(Number(saveIdStatusProducto))) &&
                                <>
                                    <img alt="check-eliminado" src={urlCheck} />
                                    <h3>Tu pedido ha sido cancelado.</h3>
                                </>
                            }

                            <>
                                {/* bloque pendiente de autorizacion y  bloque pedido autorizado*/}
                                {payment.id != undefined &&
                                    <>
                                        {(
                                            (idProductoEstate === 0 && Number(estatusPedido) === Number(PEDIDOS_PENDIENTES_PAGO) && PENDIENTE.includes(Number(payment.id)))
                                            || (idProductoEstate !== 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(Number(saveIdStatusProducto)))
                                            || (idProductoEstate === 0 && PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido))
                                        ) &&
                                            <BloqueFormasPago payment={payment.id} setResultRefoundProp={setResultRefoundProp} setLoading={setLoading} />
                                        }
                                    </>
                                }

                                {/* bloque pedido entregado */}
                                {(idProductoEstate !== 0 && PEDIDOS_PRODUCTO_ENTREGADO.includes(Number(saveIdStatusProducto))) &&
                                    <>
                                        <img alt="check-eliminado" src={urlCheck} />
                                        <h3>Revisaremos tu caso y nos contactaremos contigo para darte una solución.</h3>
                                    </>
                                }
                            </>
                        </div>

                        <div className="boxBtnRegresar">
                            <button className="btnRegresar" onClick={RegresarPedidos}>Regresar a mis pedidos</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function CancelarPedido(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <CancelarPedidoDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <CancelarPedidoMovil />
                : null
            }
        </>
    )
}