import React, { useState, useEffect } from 'react';
import { getPedido} from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import Loading from 'components/Loading';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useIsMobile } from 'hooks/useIsMobile';
import { ProcesoAclaracionDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista91-procesoClaracion-desktop';
import { useToken } from 'hooks/useToken';
import { NO_IMAGE } from 'constants/general';
import { repareTextHtml } from 'functions/repareDataService';

export function ProcesoAclaracionMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [loading, setLoading] = useState();
    const [nombreProducto, setNombreProducto] = useState(false);


    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista91-proceso aclaracion-mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];
        let mensajeriaData = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                        )

                        // guarda informacion sobre la mensajeria por la que se envio el producto
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && mensajeriaData.push({ "company_name": elem.tracking.company_name })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )
                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setNombreProducto(productoNombre);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);
                
                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //redirecciona a pagina: tengo mi paquete
    const irTengoMiPaquete = () => {

        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/tengo-mi-paquete");
    }

    // redirecciona a pagina: no termino como esperabamos
    const iniciarAclaracion = () => {

    }


    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Proceso de Aclaración</p>
                    </div>

                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            <div className="contenedorImagenesIgual1">
                                <ul className='ulIgual1'>
                                    {ima && ima.map((elemento, i) =>
                                        <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                        </li>
                                    )}
                                </ul>
                            </div>

                            {ima && ima.length > 1
                                ? <div className='tituloImagenes'>
                                    <p>Solicitar Devolución</p>
                                </div>
                                : <div className='tituloImagenes'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                            }
                        </div>

                        <div className='boxSolicitarDevolucion'>

                            <div className=''>
                                <h2>Gracias por tu paciencia.</h2>

                                <p>Por favor selecciona alguna de las siguientes opciones para continuar con tu aclaración</p>
                            </div>

                            <div className="boxBtnContinuarDevolucion">
                                <button className="btnContinuarDevolucion" onClick={irTengoMiPaquete}>Tengo mi paquete</button>
                            </div>

                            <div className="boxBtnContinuarDevolucion">
                                <button className="btnContinuarDevolucion" onClick={iniciarAclaracion}>No tengo mi paquete, iniciar aclaración</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}


export function ProcesoAclaracion() {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <ProcesoAclaracionDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <ProcesoAclaracionMovil />
                : null
            }
        </>
    )
}