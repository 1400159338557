import React from 'react';
import './style.sass';
import { Link } from 'react-router-dom';
import SlidersEmarsys from 'components/Emarsys/SlidersEmarsys';
import { useIsMobile } from 'hooks/useIsMobile';
import { WhatsBuscadorCard } from 'components/ProductDetail/SirenaWhatsApp'
import { VARIANTS_PER_PORTAL } from 'constants/general'

export default function ErrorSearchServices({ ...params }) {
    const { searchQ, suggestion } = params;
    const { isMobile } = useIsMobile();

    console.log('searchQ:   :',searchQ)
    console.log('searchQ:',searchQ.search("😡"))

    return (
        <div className="errorSearchServices">
            <div className="boxCopys">
                <p className="titleError">No se encontraron resultados para <span>"{decodeURI(searchQ)}"</span></p>
                {searchQ?.indexOf("😡") >= 0  &&
                    <p className="titleError">Tal vez quisiste decir: <span>😊</span></p>
                }
                <p className="titleRevisar">Revisa la ortografía o intenta con términos genéricos</p>
            </div>

            <div className="noResult">
                <WhatsBuscadorCard busqueda="" />
            </div>
            {/* {suggestion && 
                <p className="suggestion">Tal ves quisiste decir:
                <Link className="link" to={`/resultados/q=${suggestion}/pagina=1`} title={suggestion}>{suggestion}</Link>
                </p>
            } */}
            <div className="genericResultError">
                <ul className="opcionesBusqueda">
                    <li> <Link to="/categoria" title="categorias" className="icoCategoria">Buscar por departamento</Link></li>
                    {/* <li> <Link to="/tiendas" title="tiendas oficiales" className="icoTienda">Buscar en Tiendas Oficiales</Link></li> */}
                </ul>
            </div>

            {VARIANTS_PER_PORTAL?.emarsys?.error
                ?        
                    <div id="personalSlider">
                        {isMobile
                            ? isMobile === 'desktop'
                                ? <SlidersEmarsys
                                    logica={'PERSONAL'}
                                    idContent={'personalSlider'}
                                    title={"Productos que te pueden interesar"}
                                    typeView={'sliderView'}
                                    cant={12}
                                />
                                : isMobile === 'movile'
                                    ? null
                                    : <SlidersEmarsys
                                        logica={'PERSONAL'}
                                        idContent={'personalSlider'}
                                        title={"También te puede interesar"}
                                        typeView={'blockView'}
                                        cant={12}
                                    />
                            : null
                        }
                    </div>
                : null
            }
        </div>
    )
}