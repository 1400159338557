import {BASE_PATH_API, VARIANTS_PER_PORTAL} from 'constants/general'
const property = {method: 'GET', mode: 'cors', cache: 'default'}

export default function getLanding ({landingName} = {}) {
    var domain, idLand, typeLanding

    typeLanding = Number(landingName)
    
    console.log()

    if(isNaN(typeLanding) === true){
        domain = `${VARIANTS_PER_PORTAL.domain}/l/`
        idLand = `${landingName}?webapp=1`
    }else if(isNaN(typeLanding) === false){
        domain = `${BASE_PATH_API}/app/v1/landing/`
        idLand = `${landingName}`
    }
    
    const apiURL = `${domain}${idLand}`

    return fetch(apiURL, property)
    .then(res => res.json())
    .then(landingData =>{ 
        if(isNaN(typeLanding) === true){
            const {
                banner,
                productos
            } = landingData
            //console.log(landingData)
            return{
                banner,
                productos
            }
        }else if(isNaN(typeLanding) === false){
            const {
                data:{
                    banner,
                    productos
                }
            } = landingData
            //console.log(landingData)
            return{
                banner,
                productos
            }
        }
    })
    .catch(error => console.log('error', error))
}