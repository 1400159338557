import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'
import Header from 'pages/Header/HeaderNavegacion'
import './style.sass'

function Modal({ ...props }) {
    const {
        children,
        onClose,
        type,
        closeBody,
        title
    } = props

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState()
    const reciveDirection = (val) => {
        setUpdateCPBar(val)
    }
    const [upCP, setUpCP] = useState()

    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        closeBody !== "closeBody" && closeBody !== "modalcp" && closeBody !== "closeBodyBtn" && closeBody !== "backBodyBtn" && closeBody !== "modalSinHeader"
            ? <div className={`modal ${type}`}>
                <div className={closeBody === "modalAddCartMobile" ? "contentModal contentModalAddCart" : "contentModal"} >
                    {type === "fullHeader"
                        ? <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
                        : (closeBody === "modalAddCartMobile" && type === "fullSinHeader")
                            ? <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} headerEnCategoria={"modalAddCart"} />
                            : <div className="contentCloseAN2">
                                <div className="btn closeAN2" onClick={onClose}></div>
                                {title ? <p className="title centrar">{title}</p> : null}
                            </div>
                    }
                    <div className="dataModal">
                        {children}
                    </div>
                </div>
            </div>
            : closeBody === "modalcp"
                ? <div className={`modal ${type}`}>
                    <div className="contentModal" >
                        {type === "fullHeader"
                            ? <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
                            : <div className="contentCloseCerrar">
                                <div className="btn closeCerrar" onClick={onClose}></div>
                                {title ? <p className="title centrar">{title}</p> : null}
                            </div>
                        }
                        <div className="dataModal">
                            {children}
                        </div>
                    </div>
                </div>
                : closeBody === "closeBodyBtn"
                    ? <div className={`modal ${type}`} >
                        <span className="closeModalBody" onClick={onClose}></span>
                        <div className="contentModal">
                            {type === "fullHeader"
                                ? <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
                                : <div className="contentCloseCerrar">
                                    <div className="btn closeCerrar" onClick={onClose}></div>
                                    {title ? <p className="title">{title}</p> : null}
                                </div>
                            }
                            <div className="dataModal">
                                {children}
                            </div>
                        </div>
                    </div>
                    : closeBody === "backBodyBtn"
                        ? <div className={`modal ${type}`} >
                            <div className="contentModal">
                                {type === "fullHeader"
                                    ? <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
                                    : <div className="contentCloseAN">
                                        <div className="btn closeBackSpe" onClick={onClose}></div>
                                        {title ? <p className="title pCloseBack">{title}</p> : null}
                                    </div>
                                }
                                <div className="dataModal">
                                    {children}
                                </div>
                            </div>
                        </div>
                        : closeBody === "modalSinHeader"
                            ? <div className={`modal ${type}`} >
                                <div className="contentModal">
                                    {type === "fullHeader"
                                        ? <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
                                        : <div className="contentClose">
                                            {/* <div className="btn closeBackSpe" onClick={onClose}></div> */}
                                            {title ? <p className="title pCloseBack">{title}</p> : null}
                                        </div>
                                    }
                                    <div className="dataModal">
                                        {children}
                                    </div>
                                </div>
                            </div>
                            : <div className={`modal ${type}`} onClick={onClose}>
                                <div className="contentModal">
                                    {type === "fullHeader"
                                        ? <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
                                        : <div className="contentCloseCerrar">
                                            <div className="btn closeCerrar" onClick={onClose}></div>
                                            {title ? <p className="title">{title}</p> : null}
                                        </div>
                                    }
                                    <div className="dataModal">
                                        {children}
                                    </div>
                                </div>
                            </div>

    )
}

export default function ModalPortal({ ...props }) {
    const {
        children,
        onClose,
        type,
        closeBody,
        title,
    } = props

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} closeBody={closeBody}>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}