import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import { LABEL_PROCESO_RECOLECCION, LABEL_EN_CAMINO } from 'constants/general';
import Loading from 'components/Loading';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useStorage64 } from 'hooks/useStorageBase64';
import FooterPortal from 'pages/Footer/FooterDesktop';
import { MenuMiCuenta } from 'components/MenuMiCuenta';

export function SectionProcesoRecoleccionEnCaminoDesktop(props) {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [saveStatusProducto, setSaveStatusProducto] = useStorage64('saveStatusProducto', '');
    const [loading, setLoading] = useState(false);
    let urlAlert = "/img/trianguloAlert.svg";


    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // regresar a pagina pedidos
    const RegresarPedidos = () => {
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const handleBack = () => {
        history.goBack(-1);
    }

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">
                        <div className='menuMiCuentaAD'>
                            <MenuMiCuenta />
                        </div>

                        <div className='contenidoGeneralAD'>
                            <div className="boxBtnRegresarDktopRojo">
                                <div className="icoBackClose" onClick={handleBack}></div>
                                <button className="btnBackADLogo" onClick={handleBack}></button>
                            </div>


                            <div className="boxGeneralAD">
                                <div className="boxRightAD marginAD">
                                    <div className="boxCancelacionExitosa">
                                        <div className="boxContenido sinMarginTop">
                                            {/* bloque pedido en proceso de recolección */}
                                            {LABEL_PROCESO_RECOLECCION === String(saveStatusProducto).toLowerCase() &&
                                                <>
                                                    <img alt="check-eliminado" src={urlAlert} className="marginB40 marginT30" />
                                                    <h3>La mensajería ya va en camino por tu producto, el envío ya no puede ser detenido. Por favor rechaza la entrega cuando llegue a tu domicilio.</h3>
                                                </>
                                            }

                                            {/* bloque pedido en camino */}
                                            {LABEL_EN_CAMINO === String(saveStatusProducto).toLowerCase() &&
                                                <>
                                                    <img alt="check-eliminado" src={urlAlert} className="marginB40 marginT30" />
                                                    <h3>El envío del producto ya no puede ser detenido, por favor rechaza la entrega cuando llegue a tu domicilio.</h3>
                                                </>
                                            }

                                            <div className="boxBtnRegresar marginT60">
                                                <button className="btnRegresar" onClick={RegresarPedidos}>Regresar a mis pedidos</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>

            <FooterPortal />
        </>
    )
}