import React, { useState, useEffect } from 'react';
import { getAction, getPedido, postVerificacionDevolucion } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import { removeDuplicates } from 'functions/removeDuplicates';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { useIsMobile } from 'hooks/useIsMobile';
import { CuentanosTuProblemaDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista21-cuentanosTuProblema-desktop.js';
import { useToken } from 'hooks/useToken';
import { NO_IMAGE } from 'constants/general';
import { repareTextHtml } from 'functions/repareDataService';
import ModalPortal from 'components/Modal';
import { ModalOcurrioUnError } from './vista4-modal';

export function CuentanosTuProblemaMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState(false);
    const [listAction, setListAction] = useState(false);
    const [, setStorageActionId] = useStorage64('storageActionId', '');
    const [precioProducto, setPrecioProducto] = useState(0);
    const [, setStorageMotivoEntregado] = useStorage64('storageMotivoEntregado', '');
    const [vecesDevuelto, setVecesDevuelto] = useState(false);
    const [formaPago, setFormaPago] = useState(false);
    const [nombreProducto, setNombreProducto] = useState(false);
    const [montoParaComparar, setMontoParaComparar] = useState(0);
    const [respuestapostDevoluciones, setrespuestapostDevoluciones] = useState(false);
    const [, setYaNoSePuedeRegalar] = useStorage64('storageyanosepuederegalar');
    const [flagEstadoNoManejado, setFlagEstadoNoManejado] = useState(false);
    const [showModalError, SetShowModalError] = useState(false);

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((action.action && action) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, action])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '')
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    // obtengo list action id
    useEffect(() => {
        let reasonId = 9;

        getAction(reasonId)
            .then(setAction);
    }, [])


    // obtengo cantidad de veces que se ha devuelto un producto <200 en un mes
    useEffect(() => {
        if (precioProducto > 0 && idPedido) {
            setLoading(true);

            postVerificacionDevolucion({ idPedido })
                .then(setrespuestapostDevoluciones);
        }
    }, [precioProducto, idPedido])
    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista21- cuentanos tu problema -mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        if (respuestapostDevoluciones) {
            setLoading(false);

            if (respuestapostDevoluciones?.metadata?.http_status === 200) {
                setVecesDevuelto(respuestapostDevoluciones?.data?.external_service_response?.body?.rule);
            }
            else {
                setVecesDevuelto(false);
            }
        }
    }, [respuestapostDevoluciones])

    // obtengo informacion de pedido
    useEffect(() => {
        let imagenProducto = [];
        let productoNombre = [];
        let precio = [];
        let montoComparacion = [];


        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem?.images[0]?.url, "id": elem?.related_product_id })
                    )

                    // almacena el nombre del producto
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem?.name })
                    )
                    // almacena el precio del producto
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && precio.push({ "price": elem?.price })
                    )
                    // almacena el monto con el que se compara el precio
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && montoComparacion.push({ "monto": elem?.dynamic_help?.price_compare })
                    )

                    let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                    setima(limpioImagen);
                    setNombreProducto(productoNombre);
                    setPrecioProducto(precio[0]?.price);
                    setMontoParaComparar(montoComparacion[0]?.monto);
                    setFormaPago(resultadoPedido.pedido[0]?.payment_method.id);

                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido()
                        .then(setResultadoPedido);

                    postVerificacionDevolucion({ idPedido })
                        .then(setrespuestapostDevoluciones);
                }, 1000);
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode !== 401) {
                setFlagEstadoNoManejado(true);
            }
        }
    }, [resultadoPedido])

    // regresar a la pagina anterior
    const onClose = () => {
        history.goBack(-1);
    }

    // almaceno listado de action
    useEffect(() => {
        let arrayAction = [];
        let reasonId = 9;

        if (action) {
            if (action.action) {
                action.action.map((act) =>
                    arrayAction.push({ "id": act.id, "name": act.name, "apply_for_return": act.apply_for_return })
                )
                setListAction(arrayAction);
            }
            else if (action.statusCode && action.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getAction(reasonId)
                        .then(setAction);

                }, 1000);
            }
            else if (action.statusCode && action.statusCode !== 401) {
                setFlagEstadoNoManejado(true);
            }
        }
    }, [action])

    // lleva a pagina solicitar devolucion
    const seleccionoProblema = (id, apply_for_return) => {
        let idSeleccionado = id;
        setStorageActionId(idSeleccionado);


        // 1. no es lo que esperaba / 2. no me quedo / 3. no necesito el articulo /  4. el pedido llego tarde 
        if (id === 1 || id === 2 || id === 3 || id === 4) {
            setStorageMotivoEntregado("Tengo un problema con mi producto");

           
            // valia si el precio es < a 200
            if (Number(precioProducto) < Number(montoParaComparar)) {
                //si el precio es menor a 200, revisa que el numero de veces devuelto en 1 mes sea menor a 2 para enviar a : NO TERMINO COMO ESPERABAMOS
                if (respuestapostDevoluciones && vecesDevuelto === true) {
                    history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/no-termino-como-esperabamos");
                }
                //De lo contrario envia a solicitar devolucion
                else if ((respuestapostDevoluciones && vecesDevuelto === false) || (!respuestapostDevoluciones)) {
                    setYaNoSePuedeRegalar(true);
                    history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/solicitar-cambio-devolucion");
                }
            }
            //PRODUCTO MAYOR A 200, muestra solicitar devolucion 
            else {
                setYaNoSePuedeRegalar(false);
                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/solicitar-cambio-devolucion");
            }

        }
        // 8. no es lo que compre / 9. mi producto parece usado o de exhibicion
        else if (id === 8 || id === 9) {
            setYaNoSePuedeRegalar(false);
            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/solicitar-cambio-devolucion");
        }
        // mi producto no funciona correctamente o no enciende
        else if (id === 10 && apply_for_return === false) {
            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/no-funciona");
        }
        //mi producto esta maltratado o roto
        else if (id === 11 && apply_for_return === false) {
            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/condiciones-del-paquete");
        }

    }

    // cerrar modal de error
    const handleCloseError = () => {
        SetShowModalError(false);
        setFlagEstadoNoManejado(false);
    }

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">¿Cuéntanos tu problema?</p>
                    </div>

                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            <div className="contenedorImagenesIgual1">
                                <ul className='ulIgual1'>
                                    {ima && ima.map((elemento, i) =>
                                        <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                        </li>
                                    )}
                                </ul>
                            </div>

                            {ima && ima.length > 1
                                ? <div className='tituloImagenes'>
                                    <p>Cuéntanos tu problema</p>
                                </div>
                                : <div className='tituloImagenes'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                            }
                        </div>

                        <div className="boxUlRazones">
                            <ul>
                                {listAction && listAction.map((problem) =>
                                    <li id={problem.id} key={problem.id} onClick={() => seleccionoProblema(problem.id, problem.apply_for_return)}>
                                        <span className="nombreRazon">{problem.name}</span>
                                        <span className="iconoRazon"></span>
                                    </li>
                                )}
                            </ul>
                        </div>

                    </div>
                </div>

                {/* diferente de producto entregado, pedido pendiente de pago , pedido pendiente de autorizar, pedido autorizado, preprarando tu producto */}
                {flagEstadoNoManejado &&
                    <ModalPortal type="modalSB" onClose={handleCloseError} >
                        <ModalOcurrioUnError okRegresar={regresarPedidos} />
                    </ModalPortal>
                }
            </div >
        </>
    )
}

export function CuentanosTuProblema() {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <CuentanosTuProblemaDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <CuentanosTuProblemaMovil />
                : null
            }
        </>
    )
}