import React from 'react'
import './style.sass'

export default function ErrorSession(props){
    const {
        errorText,
        acceptReturn
    } = props

    let newText, newTitle, textBtn
    if(errorText === 'Your session has been expired. Login again'){
        newTitle = "Tu sesión expiró por seguridad"
        newText = 'Por favor inténtalo de nuevo.'
        textBtn = "Aceptar"
    }
    else{
        newTitle="Tu sesión expiró por seguridad"
        newText = 'Por favor inténtalo de nuevo.'
        textBtn = "Intentar de nuevo"
    }

    function returnButton(){
        acceptReturn(true)
    }

    return(
        <>
            <div className="errorSession">
                <div className="iconSession"></div>
                <h1 className="textMessage">{newTitle}</h1>
                <p className="textMessage">{newText}</p>
                <div className="btn rojo" onClick={returnButton}>{textBtn}</div>
            </div>
        </>
    )
}