import React from 'react'
import './style.sass'
import { Link } from "react-router-dom"
import LazyLoad from 'react-lazy-load'
import { repareImage } from 'functions/repareDataService'
import { BASE_PORTAL, PORTAL_URL, LAZY_L, NO_IMAGE, IMAGE_THUMB, SIZE_IMAGE_THUMB } from 'constants/general'
import { dataLayerTapsPA } from 'functions/dataLayerFunction'

export default function PromotionAnnouncement(props) {

    const {
        styleView,
        promotionAnnouncement,
        bannerIndex,
        allPromotionAnnouncement        
    } = props


    //console.log('este el banner promotionAnnouncementxx: ', allPromotionAnnouncement)
    
    const clickBotonTaggeo = (bannerIndex, index, link, allPromotionAnnouncement) => {
        //console.log('este el banner numero cc: ', bannerIndex, ' index: ', index, " link", link)
        dataLayerTapsPA(bannerIndex, index, link, allPromotionAnnouncement);
    }


    return (
        promotionAnnouncement &&
        <div className={`container`} >
            {styleView === "a"
                ?
                <div className="promotionA1" >
                    {
                        promotionAnnouncement?.map(({ link, image, id }, index) =>
                            index <= 2 &&
                                typeof (link) !== 'function' //&&
                                ? link?.split('/')[1] === "c" || link?.indexOf('https://') >= 0 || link?.indexOf('http://') >= 0
                                    ? <a href={PORTAL_URL +link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)}>
                                       {LAZY_L
                                            ?
                                                <LazyLoad height={768} width={800} offset={100} threshold={0.95}>
                                                    <img
                                                        src={`${repareImage(image)}`}
                                                        alt={BASE_PORTAL + link + 1}
                                                        width="768"
                                                        height="768"
                                                        loading="lazy"
                                                        decoding="async"
                                                        fetchpriority="low"
                                                    />
                                                </LazyLoad>
                                            :
                                                <img
                                                    src={`${repareImage(image)}`}
                                                    alt={BASE_PORTAL + link + 1}
                                                    width="768"
                                                    height="768"
                                                    loading="lazy"
                                                    decoding="async"
                                                    fetchpriority="low"
                                                />
                                       }
                                    </a>
                                    :
                                     <Link to={PORTAL_URL + link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                        {LAZY_L
                                            ?
                                                <LazyLoad height={768} width={450} offset={100} threshold={0.95}>
                                                    <img
                                                        src={`${repareImage(image)}`}
                                                        alt={BASE_PORTAL + link + 2}
                                                        width="768"
                                                        height="768"
                                                        loading="lazy"
                                                        decoding="async"
                                                        fetchpriority="low"
                                                    />
                                                </LazyLoad>
                                            :
                                                <img
                                                    src={`${repareImage(image)}`}
                                                    alt={BASE_PORTAL + link + 2}
                                                    width="768"
                                                    height="768"
                                                    loading="lazy"
                                                    decoding="async"
                                                    fetchpriority="low"
                                                />
                                       }
                                    </Link>
                                : null
                        )
                    }
                </div>
                :
                styleView === "b"
                    ?
                    <div className="promotionA2" >
                        {
                            promotionAnnouncement.map(({ link, image, id }, index) =>
                                index <= 2 &&
                                    typeof (link) !== 'function' //&&
                                    ? link?.split('/')[1] === "c" || link?.indexOf('https://') >= 0 || link?.indexOf('http://') >= 0
                                        ? <a href={PORTAL_URL +link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                            {LAZY_L
                                                ?
                                                    <LazyLoad height={768} width={550} offset={100} threshold={0.95}>
                                                        <img
                                                            src={`${repareImage(image)}`}
                                                            alt={BASE_PORTAL + link + 1}
                                                            width="768"
                                                            height="768"
                                                            loading="lazy"
                                                            decoding="async"
                                                            fetchpriority="low"
                                                        />
                                                    </LazyLoad>
                                                :
                                                    <img
                                                        src={`${repareImage(image)}`}
                                                        alt={BASE_PORTAL + link + 1}
                                                        width="768"
                                                        height="768"
                                                        loading="lazy"
                                                        decoding="async"
                                                        fetchpriority="low"
                                                    />
                                            }
                                        </a>
                                        : 
                                        <Link to={PORTAL_URL + link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                            {LAZY_L
                                                ?
                                                    <LazyLoad height={768} width={550} offset={100} threshold={0.95}>
                                                        <img
                                                            src={`${repareImage(image)}`}
                                                            alt={PORTAL_URL + link + 2}
                                                            width="768"
                                                            height="768"
                                                            loading="lazy"
                                                            decoding="async"
                                                            fetchpriority="low"
                                                        />
                                                    </LazyLoad>
                                                :
                                                    <img
                                                        src={`${repareImage(image)}`}
                                                        alt={PORTAL_URL + link + 2}
                                                        width="768"
                                                        height="768"
                                                        loading="lazy"
                                                        decoding="async"
                                                        fetchpriority="low"
                                                    />
                                            }
                                        </Link>
                                    : null
                            )
                        }
                    </div>
                    :
                    styleView === "c"
                        ?
                        <div className="promotionA3" >
                            {
                                promotionAnnouncement.map(({ link, image, id }, index) =>
                                    index <= 2 &&
                                        typeof (link) !== 'function' //&&
                                        ? link?.split('/')[1] === "c" || link?.indexOf('https://') >= 0 || link?.indexOf('http://') >= 0
                                            ?
                                                <a href={PORTAL_URL +link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                                    {LAZY_L
                                                        ?
                                                            <LazyLoad height={768} width={768} offset={100} threshold={0.95}>
                                                                <img
                                                                    src={`${repareImage(image)}`}
                                                                    alt={BASE_PORTAL + link + 1}
                                                                    width="768"
                                                                    height="768"
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    fetchpriority="low"
                                                                />
                                                            </LazyLoad>
                                                        :
                                                            <img
                                                                src={`${repareImage(image)}`}
                                                                alt={BASE_PORTAL + link + 1}
                                                                width="768"
                                                                height="768"
                                                                loading="lazy"
                                                                decoding="async"
                                                                fetchpriority="low"
                                                            />
                                                    }
                                                </a>
                                            : 
                                                <Link to={PORTAL_URL + link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                                    {LAZY_L
                                                        ?
                                                            <LazyLoad height={768} width={768} offset={100} threshold={0.95}>
                                                                <img
                                                                    src={`${repareImage(image)}`}
                                                                    alt={PORTAL_URL + link + 2}
                                                                    width="768"
                                                                    height="768"
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    fetchpriority="low"
                                                                />
                                                            </LazyLoad>
                                                        :
                                                            <img
                                                                src={`${repareImage(image)}`}
                                                                alt={PORTAL_URL + link + 2}
                                                                width="768"
                                                                height="768"
                                                                loading="lazy"
                                                                decoding="async"
                                                                fetchpriority="low"
                                                            />
                                                    }
                                                </Link>
                                        : null
                                )
                            }
                        </div>
                        :
                        styleView === "d"
                            ?
                            <div className="promotionA4" >
                                {
                                    promotionAnnouncement.map(({ link, image, id }, index) =>
                                        index <= 1 &&
                                            typeof (link) !== 'function' //&& 
                                            ? link?.split('/')[1] === "c" || link?.indexOf('https://') >= 0 || link?.indexOf('http://') >= 0
                                            ? <a href={PORTAL_URL +link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                                {LAZY_L
                                                        ?
                                                            <LazyLoad height={768} width={900} offset={100} threshold={0.95}>
                                                                <img
                                                                    src={`${repareImage(image)}`}
                                                                    alt={BASE_PORTAL + link + 1}
                                                                    width="768"
                                                                    height="768"
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    fetchpriority="low"
                                                                />
                                                            </LazyLoad>
                                                        :
                                                            <img
                                                                src={`${repareImage(image)}`}
                                                                alt={BASE_PORTAL + link + 1}
                                                                width="768"
                                                                height="768"
                                                                loading="lazy"
                                                                decoding="async"
                                                                fetchpriority="low"
                                                            />
                                                    }
                                            </a>
                                            : 
                                            <Link to={PORTAL_URL + link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                                {LAZY_L
                                                    ?
                                                        <LazyLoad height={768} width={900} offset={100} threshold={0.95}>
                                                            <img
                                                                src={`${repareImage(image)}`}
                                                                alt={PORTAL_URL + link + 2}
                                                                width="768"
                                                                height="768"
                                                                loading="lazy"
                                                                decoding="async"
                                                                fetchpriority="low"
                                                            />
                                                        </LazyLoad>
                                                    :
                                                        <img
                                                            src={`${repareImage(image)}`}
                                                            alt={PORTAL_URL + link + 2}
                                                            width="768"
                                                            height="768"
                                                            loading="lazy"
                                                            decoding="async"
                                                            fetchpriority="low"
                                                        />
                                                }
                                            </Link>
                                            : null
                                    )
                                }
                            </div>
                            :
                            styleView === "e"
                                ?
                                <div className="promotionA5" >
                                    {
                                        promotionAnnouncement.map(({ link, image, id }, index) =>
                                            index <= 3 &&
                                                typeof (link) !== 'function' //&&
                                                ? link?.split('/')[1] === "c" || link?.indexOf('https://') >= 0 || link?.indexOf('http://') >= 0
                                                    ? <a href={PORTAL_URL +link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                                        {LAZY_L
                                                            ?
                                                                <LazyLoad height={768} width={768} offset={100} threshold={0.95}>
                                                                    <img
                                                                        src={`${repareImage(image)}`}
                                                                        alt={BASE_PORTAL + link + 1}
                                                                        width="768"
                                                                        height="768"
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        fetchpriority="low"
                                                                    />
                                                                </LazyLoad>
                                                            :
                                                                <img
                                                                    src={`${repareImage(image)}`}
                                                                    alt={BASE_PORTAL + link + 1}
                                                                    width="768"
                                                                    height="768"
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    fetchpriority="low"
                                                                />
                                                        }
                                                    </a>
                                                    :
                                                     <Link to={PORTAL_URL + link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                                        {LAZY_L
                                                            ?
                                                                <LazyLoad height={768} width={768} offset={100} threshold={0.95}>
                                                                    <img
                                                                        src={`${repareImage(image)}`}
                                                                        alt={PORTAL_URL + link + 2}
                                                                        width="768"
                                                                        height="768"
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        fetchpriority="low"
                                                                    />
                                                                </LazyLoad>
                                                            :
                                                                <img
                                                                    src={`${repareImage(image)}`}
                                                                    alt={PORTAL_URL + link + 2}
                                                                    width="768"
                                                                    height="768"
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    fetchpriority="low"
                                                                />
                                                        }
                                                    </Link>
                                                : null
                                        )
                                    }
                                </div>
                                :
                                <div className="promotionAD" >
                                    {
                                        promotionAnnouncement.map(({ link, image, id }, index) =>
                                            index === 0 &&
                                                typeof (link) !== 'function' //&&
                                                ? link?.split('/')[1] === "c" || link?.indexOf('https://') >= 0 || link?.indexOf('http://') >= 0
                                                    ? <a href={PORTAL_URL +link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)}>
                                                        {LAZY_L
                                                            ?
                                                                <LazyLoad height={768} width={768} offset={100} threshold={0.95}>
                                                                    <img
                                                                        src={`${repareImage(image)}`}
                                                                        alt={BASE_PORTAL + link + 1}
                                                                        width="768"
                                                                        height="768"
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        fetchpriority="low"
                                                                    />
                                                                </LazyLoad>
                                                            :
                                                                <img
                                                                    src={`${repareImage(image)}`}
                                                                    alt={BASE_PORTAL + link + 1}
                                                                    width="768"
                                                                    height="768"
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    fetchpriority="low"
                                                                />
                                                        }
                                                    </a>
                                                    : 
                                                    <Link to={PORTAL_URL + link} key={id} className={`link${index}`} onClick={ (event) => clickBotonTaggeo(bannerIndex, index, link, allPromotionAnnouncement)} >
                                                        {LAZY_L
                                                            ?
                                                                <LazyLoad height={768} width={768} offset={100} threshold={0.95}>
                                                                    <img
                                                                        src={`${repareImage(image)}`}
                                                                        alt={PORTAL_URL + link + 2}
                                                                        width="768"
                                                                        height="768"
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        fetchpriority="low"
                                                                    />
                                                                </LazyLoad>
                                                            :
                                                                <img
                                                                    src={`${repareImage(image)}`}
                                                                    alt={PORTAL_URL + link + 2}
                                                                    width="768"
                                                                    height="768"
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    fetchpriority="low"
                                                                />
                                                        }
                                                    </Link>
                                                : null
                                        )
                                    }
                                </div>

            }
        </div>
    )
}

//old
export function PromotionAnnouncementOld({ promotionAnnouncement }) {

    const { link, image } = promotionAnnouncement

    return (
        <div className="promotionAnnouncement">
            <div className="container">
                <a href={PORTAL_URL + link} title="Beneficios">
                    {LAZY_L
                        ?
                            <LazyLoad height={768} width={768} offset={100} threshold={0.95}>
                                <img
                                    src={`${repareImage(image)}`}
                                    alt={PORTAL_URL + link}
                                    width="768"
                                    height="768"
                                    loading="lazy"
                                    decoding="async"
                                    fetchpriority="low"
                                />
                            </LazyLoad>
                        :
                            <img
                                src={`${repareImage(image)}`}
                                alt={PORTAL_URL + link}
                                width="768"
                                height="768"
                                loading="lazy"
                                decoding="async"
                                fetchpriority="low"
                            />
                    }
                </a>
            </div>
        </div>
    )
}