import React, {useEffect, useState} from 'react'

export default function CardProductSkeleton (props) {
   const {
       cantidad: cantidad = 1
    } = props
    const [ok, setOk] = useState(false)
    const [cantidadItems, setCantidadItems] = useState([])
    // useEffect(()=>{
    //     if(cantidad === 2){
    //         setCantidadItems([1,2])
    //     }
    //     else if(cantidad === 4){
    //         setCantidadItems([1,2,3,4])
    //     }
    //     else if(cantidad === 8){
    //         setCantidadItems([1,2,3,4,5,6,7,8])
    //     }
    //     else if(cantidad === 10){
    //         setCantidadItems([1,2,3,4,5,6,7,8,9,10])
    //     }
    // },[])
    useEffect(()=>{
        let ItemsCount = []
        for(let i = 1; i <= cantidad; i++){
            ItemsCount.push(i)
            if(i === cantidad){
                setCantidadItems(ItemsCount)
                setOk(true)
            }
        }
    },[])
    useEffect(()=>{
        if(ok){
            setOk(false)
        }
    },[ok])

    return(
        <>
            {cantidadItems
                ?
                    cantidadItems.map(({},index) =>
                        <article key={index} className="cardProduct skeleton">
                            <span className="btnWishlist bones"></span>
                            <div className="cardSkel">
                                <div className="img bones"></div>
                                <div className="contDataCard">
                                    <p className="h4 bones"></p>
                                    <p className="h4 bones"></p>
                                    <p className="h5 bones"></p>
                                    <p className="h5 bones"></p>
                                    <p className="bones"></p>
                                    <p className="envioGratis bones"></p>
                                </div>
                            </div>
                        </article>
                    )
                : null
            }
        </>
    )
}