import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import './style.sass';

export default function Paginador({ page, cambiarPagina, total, total_items, rangoDias }) {

    const [flagInicial, setFlagInicial] = useState(false); // si presiono ir al inicio
    const [flagFinal, setFlagFinal] = useState(false); // si presiono ir al final
    const [upperPageBound, setUpperPageBound] = useState(10);  // cantidad de numero de paginas a mostrar en el paginador
    const [lowerPageBound, setLowerPageBound] = useState(0); //  pagina inferior
    const [pageBound, setPageBound] = useState(0); // pagina enlazada 10

    // aumentar numero de pagina
    const incrementPage = () => {
        window.scrollTo(0, 0);
        if ((page[0] + 1) > upperPageBound) {
            setUpperPageBound(upperPageBound + pageBound);
            setLowerPageBound(lowerPageBound + pageBound);
        }

        let listid = page[0] + 1;
        if (listid < Math.ceil(Number(total_items) / Number(total))) {
            cambiarPagina(listid);
        }
    };

    // disminuir numero de pagina
    const decrementPage = () => {
        window.scrollTo(0, 0);
        if ((page[0] - 1) % pageBound === 0) {
            setUpperPageBound(upperPageBound - pageBound);
            setLowerPageBound(lowerPageBound - pageBound);
        }
        let listid = page[0] - 1;
        if (listid === 0) {
            cambiarPagina(1);
        }
        else {
            cambiarPagina(listid);
        }
    };

    // ir a la ultima pagina
    const ultimatePage = (ultima) => {
        window.scrollTo(0, 0);
        if (ultima <= Math.ceil(Number(total_items) / Number(total))) {
            cambiarPagina(ultima);
        }
        setFlagInicial(false);
        setFlagFinal(true);
    };

    // ir a la primera pagina
    const initialPage = () => {
        window.scrollTo(0, 0);
        cambiarPagina(1);

        setFlagInicial(true);
        setFlagFinal(false);
    };

    // actualizar la pagina seleccionada
    const handleClick = (event) => {
        let listid = Number(event.target.id);
        cambiarPagina(listid);
        $("ul li a.activarPaginacion").removeClass('activarPaginacion');
        $('ul li#' + listid + ' a ').addClass('activarPaginacion');
    };

    // activa la clase css de pagina activa en el elemento correspondiente
    useEffect(() => {
        $("ul li a.activarPaginacion").removeClass('activarPaginacion');
        $('ul li#' + Number(page[0]) + ' a ').addClass('activarPaginacion');
    });

    // establece el numero de paginas a agregar para visualizar en el paginador
    useEffect(() => {
        if (page[0] >= 9) {
            setPageBound(8);
        }
    });

    useEffect(() => {
        setPageBound(Number(page[0]));
        if (Number(page[0]) === 1) {
            setUpperPageBound(pageBound + 9);
            setLowerPageBound(pageBound - 0);
        }
        else if (Number(page[0]) > 1 && Number(page[0]) < 10) {
            setUpperPageBound(pageBound + 7);
            setLowerPageBound(pageBound - 3);
        }
        else {
            setLowerPageBound(pageBound - 5);
            setUpperPageBound(pageBound + 5);
        }
    });

    // incrementa el numero de pagina
    const btnIncrementClick = () => {
        setUpperPageBound(upperPageBound + pageBound);
        setLowerPageBound(lowerPageBound + pageBound);
        let listid = upperPageBound;

        if ((listid < Math.ceil(Number(total_items) / Number(total)) && listid < 100)) {
            cambiarPagina(listid);
        }
        if ((listid < Math.ceil(Number(total_items) / Number(total)) && listid > 100)) {
            cambiarPagina(100);
        }
    };

    // decrementa el numero de pagina
    const btnDecrementClick = () => {
        setUpperPageBound(upperPageBound - pageBound);
        setLowerPageBound(lowerPageBound - pageBound);
        let listid = lowerPageBound;

        if ((listid < Math.ceil(Number(total_items) / Number(total)) && listid > 0)) {
            cambiarPagina(listid);
        }
    };

    // obtener el numero de paginas encontradas
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(Number(total_items) / Number(total)); i++) {
        pageNumbers.push(i);
    }

    const pageNumbersSlice = pageNumbers.slice(0, 100);
    const renderPageNumbers = pageNumbersSlice.map(number => {

        if (number === 1 && Number(page[0]) === 1) {
            return (
                <li key={number} id={number}>
                    <Link className='activarPaginacion' to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=${number}`} id={number} onClick={(e) => handleClick(e)} >{number}</Link>
                </li>
            )
        }
        else if ((number < upperPageBound + 1) && number > lowerPageBound) {
            if ((number <= pageNumbersSlice.length)) {
                return (
                    <li key={number} id={number}>
                        <Link to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=${number}`} id={number} onClick={(e) => handleClick(e)}> {number} </Link>
                    </li>
                )
            }
        }
    });

    // botones ... incrementar
    let pageIncrementBtn = null;
    if ((pageNumbersSlice.length > upperPageBound) &&
        (upperPageBound <= pageNumbersSlice.length - 1) &&
        (page[0] > 2) &&
        (page[0] < 92)) {

        if ((Number(page[0]) + 8) < pageNumbersSlice.length) {
            pageIncrementBtn = <li className=''><Link
                to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=${Number(page[0]) + 8}`}
                onClick={btnIncrementClick}
            > &hellip; </Link></li>
        }
    }
    // botones ... decrementar
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1 && page[0] > 10) {
        if ((Number(page[0]) - 8) < 0) {
            pageDecrementBtn = <li className=''><Link
                to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=1`}
                onClick={btnDecrementClick}
            > &hellip; </Link></li>
        } else {
            pageDecrementBtn = <li className=''><Link
                to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=${Number(page[0]) - 8}`}
                onClick={btnDecrementClick}
            > &hellip; </Link></li>
        }
    }

    return (
        <>
            <div className="paginador">
                <ul>
                    {/* visualizar back  e ir a la primera pagina */}
                    {Number(page[0]) >= 2
                        ? <>
                            <li>
                                <Link to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=${1}`} onClick={initialPage} className={flagInicial === true ? "backback activarPaginacion" : "backback"} >
                                </Link>
                            </li>
                            <li>
                                <Link to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=${Number(page[0]) - 1}`} onClick={decrementPage} className="backArrow" >
                                    <span>Anterior</span>
                                </Link>
                            </li>
                        </>
                        : Number(page[0]) > 1 && Number(page[0]) < 3
                            ? <>
                                <li>
                                    <Link to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=${Number(page[0]) - 1}`}
                                        onClick={decrementPage} className="backArrow" />

                                </li>
                            </>
                            : null
                    }

                    {pageDecrementBtn}
                    {renderPageNumbers}
                    {pageIncrementBtn}

                    {/* visualizar next e ir ultima pagina */}
                    {((Number(page[0]) !== Math.ceil(Number(total_items) / Number(total)))
                        && (Number(page[0]) <= 99) && Math.ceil(Number(total_items) / Number(total)) > 1)
                        ? <>
                            <li>
                                <Link to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=${Number(page[0]) + 1}`}
                                    onClick={incrementPage} className={flagFinal ? "next activarPaginacion" : ' next'} data-tooltip="siguiente">
                                    Siguiente
                                </Link>
                            </li>

                            <li>
                                <Link to={`/mi-cuenta/mis-pedidos/dias=${rangoDias}/pagina=${pageNumbersSlice.length}`}
                                    onClick={() => ultimatePage(pageNumbersSlice.length)}
                                    className={flagFinal ? "nextnext activarPaginacion" : "nextnext"} >
                                </Link>
                            </li>

                        </>
                        : ((Number(page[0]) === Math.ceil(Number(total_items) / Number(total))
                            || (Number(page[0]) >= 100)
                            || Math.ceil(Number(total_items) / Number(total)) == 0
                            || Math.ceil(Number(total_items) / Number(total)) == 1)
                            ? <>
                                {/* <li> <span className={"nextnext activarPaginacion"} /> </li>
                                <li> <span className={"next activarPaginacion"} /> </li> */}
                            </>
                            : null
                        )
                    }

                </ul>
            </div>
        </>
    )
}