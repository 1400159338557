import { useEffect, useState } from 'react'

export function useRepairResponse(){
    const [responseDataUD, setResponseDataUD] = useState()
    const [responseData, setResponseData] = useState({})

    useEffect(() => {
        if(responseDataUD){
            let removeError = responseDataUD?.indexOf('{')
            let responseOk = responseDataUD.substring(removeError)
            let responseDataFinish = JSON.parse(responseOk)
            setResponseData(responseDataFinish)
        }
    },[responseDataUD])

    const setData = (val) =>{
        setResponseDataUD(val)
    }

    return {
        responseData,
        setData
    }
}