import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';

export function PreguntasFrecuentesDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Contact Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Preguntas Frecuentes</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <div className="preguntas">
                                    <div className="textPreguntas">
                                        <h2>¿Qué es una compra por Internet?</h2>
                                        <p>Adquirir productos o servicios desde tu computadora cómoda, fácil y rápidamente con sólo hacer algunos clics, puedes encontrar información más detallada sobre los artículos de tu interés y realizar comparativos sobre las características de estos.</p>
                                    </div>

                                    <div className="imagenPreguntas">
                                        <img src="/img/pregunta1.png" alt="ico1" />
                                    </div>
                                </div>
                            </div>

                            <div className="question1">
                                <div className="preguntas">
                                    <div className="imagenPreguntas">
                                        <img src="/img/pregunta2.png" alt="ico2" />
                                    </div>

                                    <div className="textPreguntas">
                                        <h2>¿Qué debo hacer para comprar en la página de Sears?</h2>
                                        <p>Para realizar compras en sears.com.mx, deberás registrarte ingresando a la opción Crear nueva cuenta que aparece en la parte superior derecha de la página. Una vez realizado el registro podrás seleccionar todos los productos que desees adquirir y agregarlos a la bolsa de compras, al concluir tu selección, sólo debes dar clic en Colocar mi pedido y seguir estos sencillos pasos:</p>
                                        <ul className="ulQuestionNumerico">
                                            <li>Envío. Elige la dirección de envío de tu pedido.</li>
                                            <li>Pago. Selecciona la forma de pago.</li>
                                            <li>Revisar. Verifica toda la información del pedido.</li>
                                            <li>Terminar. Concluye la compra.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="question1">
                                <div className="preguntas">
                                    <div className="textPreguntas">
                                        <h2>¿Para qué registrarme en la página?</h2>
                                        <ul className="ulQuestionDisc">
                                            <li>Realizar compras en nuestra tienda virtual sears.com.mx</li>
                                            <li>Obtener un servicio personalizado al realizar tus compras.</li>
                                            <li>Consultar tu saldo y pagar tu Tarjeta SEARS en línea.</li>
                                            <li>Recibir promociones especiales por correo electrónico.</li>
                                            <li>Administrar perfil de usuario: compras en línea, estatus de pedido, direcciones de envío y formas de pago.</li>
                                            <li>Registrar más de una dirección de facturación y envío.</li>
                                        </ul>
                                    </div>

                                    <div className="imagenPreguntas">
                                        <img src="/img/pregunta3.png" alt="ico3" />
                                    </div>
                                </div>
                            </div>

                            <div className="question1">
                                <div className="preguntas">
                                    <div className="imagenPreguntas">
                                        <img src="/img/pregunta4.png" alt="ico4" />
                                    </div>

                                    <div className="textPreguntas">
                                        <h2>¿Cómo me registro como Cliente?</h2>
                                        <p>Es muy sencillo, sólo da clic a la opción Crear nueva cuenta que aparece en la parte superior derecha de la página, llena la información correctamente y ya estás listo para comprar en línea. Si tienes alguna duda sobre el registro, llámanos desde el D.F. y área Metropolitana al (01 55) 5345 1606 y desde el interior de la república (01 800) 836 82 46 de lunes a viernes de 9:00 a 20:00 horas o envíanos un correo electrónico a sears.internet@sears.com.mx, aquí te daremos toda la información necesaria y recibirás apoyo para agilizar tu registro.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="question1">
                                <div className="preguntas">
                                    <div className="textPreguntas">
                                        <h2>¿Cuáles son las formas de pago?</h2>
                                        <p>Puedes realizar tus compras con cualquiera de las siguientes formas de pago:</p>

                                        <ul className="ulQuestionDisc">
                                            <li>Tarjeta de Crédito Sears</li>
                                            <li>Visa</li>
                                            <li>American Express</li>
                                            <li>Monedero/Certificado Sears</li>
                                            <li>Oxxo Pay</li>
                                            <li>Pago en tienda</li>
                                            <li>Paypal</li>
                                        </ul>
                                    </div>


                                    <div className="imagenPreguntas">
                                        <img src="/img/pregunta5.png" alt="ico5" />
                                    </div>
                                </div>
                            </div>

                            <div className="question1">
                                <div className="preguntas">
                                    <div className="imagenPreguntas">
                                        <img src="/img/pregunta6.png" alt="ico6" />
                                    </div>

                                    <div className="textPreguntas">
                                        <h2>¿Cuál es el nivel de seguridad para comprar?</h2>
                                        <p>Todos los datos que teclees, incluyendo el número de tu tarjeta de crédito, nombre, domicilio, teléfono, etc, se encriptan bajo un método sofisticado utilizando tecnología de punta y se envían a nuestro servidor seguro. Estos datos no pueden ser leídos de ninguna manera durante su transmisión. Al recibir nosotros esos datos, los almacenamos encriptados en una base de datos alojada en una computadora inaccesible desde Internet, preservando la seguridad de los mismos después de su transmisión.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="question1">
                                <div className="preguntas">
                                    <div className="textPreguntas">
                                        <h2>¿Puedo enviar a otras personas los productos que compre?</h2>
                                        <p>Claro que si puedes, sólo debes especificar en tu pedido la dirección de envío y datos de la persona a la que deseas enviar los productos que compraste. También puedes registrar más de una dirección de envío en tu cuenta personal si realizas compras a familiares o clientes que se encuentran en el interior de la república.</p>
                                    </div>

                                    <div className="imagenPreguntas">
                                        <img src="/img/pregunta7.png" alt="ico7" />
                                    </div>
                                </div>
                            </div>

                            <div className="question1">
                                <div className="preguntas">
                                    <div className="imagenPreguntas">
                                        <img src="/img/pregunta8.png" alt="ico8" />
                                    </div>

                                    <div className="textPreguntas">
                                        <h2>¿Cómo realizan la entrega de productos?</h2>
                                        <p>En general nuestros productos tienen un tiempo de entrega máximo de 10 días hábiles, siempre y cuando no se presente ningun inconveniente con la forma de pago y la disponibiliadad de los artículos. Los productos denominados como big ticket (muebles, línea blanca, electrónica y aparatos de ejercicio) tienen un tiempo sujeto a el reaprovisionamiento de los proveedores indicando la fecha promesa de entrega en cada detalle del producto. En la zona metropolitana el envío no tiene costo alguno y la entrega se realiza por transporte Sears. Los envíos al interior de la Republica Mexicana se hacen por mensajería DHL con un cargo adicional (verificar costo de envío).</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}



export function PreguntasFrecuentesMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Preguntas frecuentes');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal contentModalPadd">

                    <div className="contentClose contentC">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Preguntas Frecuentes</p>
                    </div>

                    <div className="bxPF">
                        <p className="colorRojo">Preguntas Frecuentes</p>
                    </div>

                    <div className="dataModal padding10PF">
                        <div className="boxAN">
                            <div className="bxSlider">

                                <Accordion atomic={true}>

                                    <AccordionItem title="¿Qué es una compra por internet?">
                                        <p className="pSliderL">Adquirir productos o servicios desde tu computadora cómoda, fácil y rápidamente con sólo hacer algunos clics, puedes encontrar información más detallada sobre los artículos de tu interés y realizar comparativos sobre las características de estos.</p>
                                    </AccordionItem>

                                    <AccordionItem title="¿Qué debo hacer para comprar en la página de Sears?">
                                        <p className="pSliderL">Para realizar compras en sears.com.mx, deberás registrarte ingresando a la opción Crear nueva cuenta que aparece en la parte superior derecha de la página. Una vez realizado el registro podrás seleccionar todos los productos que desees adquirir y agregarlos a la bolsa de compras, al concluir tu selección, sólo debes dar clic en Colocar mi pedido y seguir estos sencillos pasos:</p>
                                        <ul className="ulDeclaracionesNumber">
                                            <li>Envío. Elige la dirección de envío de tu pedido.</li>
                                            <li>Pago. Selecciona la forma de pago.</li>
                                            <li>Revisar. Verifica toda la información del pedido.</li>
                                            <li>Terminar. Concluye la compra.</li>
                                        </ul>
                                    </AccordionItem>

                                    <AccordionItem title="¿Para qué registrarme en la página?">
                                        <ul className="ulDeclaracionesDiscAccordion">
                                            <li>Realizar compras en nuestra tienda virtual sears.com.mx</li>
                                            <li>Obtener un servicio personalizado al realizar tus compras.</li>
                                            <li>Consultar tu saldo y pagar tu Tarjeta SEARS en línea.</li>
                                            <li>Recibir promociones especiales por correo electrónico.</li>
                                            <li>Administrar perfil de usuario: compras en línea, estatus de pedido, direcciones de envío y formas de pago.</li>
                                            <li>Registrar más de una dirección de facturación y envío.</li>
                                        </ul>
                                    </AccordionItem>

                                    <AccordionItem title="¿Cómo me registro como Cliente?">
                                        <p className="pSliderL">Es muy sencillo, sólo da clic a la opción Crear nueva cuenta que aparece en la parte superior derecha de la página, llena la información correctamente y ya estás listo para comprar en línea.</p>
                                        <p className="pSliderL">Si tienes alguna duda sobre el registro, llámanos desde el D.F. y área Metropolitana al <a href="tel: 015553451606">(01 55) 5345 1606</a> y desde el interior de la república <a href="tel: 018008368246">(01 800) 836 82 46</a> de lunes a viernes de 9:00 a 20:00 horas o envíanos un correo electrónico a <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a>, aquí te daremos toda la información necesaria y recibirás apoyo para agilizar tu registro.</p>
                                    </AccordionItem>

                                    <AccordionItem title="¿Cuáles son las formas de pago?">
                                        <p className="pSliderL">Puedes realizar tus compras con cualquiera de las siguientes formas de pago:</p>
                                        <ul className="ulDeclaracionesDiscAccordion">
                                            <li>Tarjeta de Crédito Sears</li>
                                            <li>Visa</li>
                                            <li>American Express</li>
                                            <li>Monedero/Certificado Sears</li>
                                            <li>Oxxo Pay</li>
                                            <li>Pago en tienda</li>
                                            <li>Paypal</li>
                                            
                                        </ul>
                                    </AccordionItem>

                                    <AccordionItem title="¿Cuál es el nivel de seguridad para comprar?">
                                        <p className="pSliderL">Todos los datos que teclees, incluyendo el número de tu tarjeta de crédito, nombre, domicilio, teléfono, etc, se encriptan bajo un método sofisticado utilizando tecnología de punta y se envían a nuestro servidor seguro. Estos datos no pueden ser leídos de ninguna manera durante su transmisión. Al recibir nosotros esos datos, los almacenamos encriptados en una base de datos alojada en una computadora inaccesible desde Internet, preservando la seguridad de los mismos después de su transmisión.</p>
                                    </AccordionItem>

                                    <AccordionItem title="¿Puedo enviar a otras personas los productos que compre?">
                                        <p className="pSliderL">Claro que si puedes, sólo debes especificar en tu pedido la dirección de envío y datos de la persona a la que deseas enviar los productos que compraste. También puedes registrar más de una dirección de envío en tu cuenta personal si realizas compras a familiares o clientes que se encuentran en el interior de la república.</p>
                                    </AccordionItem>

                                    <AccordionItem title="¿Cómo realizan la entrega de productos?">
                                        <p className="pSliderL">En general nuestros productos tienen un tiempo de entrega máximo de 10 días hábiles, siempre y cuando no se presente ningun inconveniente con la forma de pago y la disponibiliadad de los artículos.</p>
                                        <p className="pSliderL">Los productos denominados como big ticket (muebles, línea blanca, electrónica y aparatos de ejercicio) tienen un tiempo sujeto a el reaprovisionamiento de los proveedores indicando la fecha promesa de entrega en cada detalle del producto. En la zona metropolitana el envío no tiene costo alguno y la entrega se realiza por transporte Sears. Los envíos al interior de la Republica Mexicana se hacen por mensajería DHL con un cargo adicional (verificar costo de envío).</p>
                                    </AccordionItem>

                                </Accordion>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export function PreguntasFrecuentes() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Preguntas frecuentes');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <PreguntasFrecuentesDesktop />

                : isMobile === 'movile'
                    ? null
                    : <PreguntasFrecuentesMobile />
            }
        </>
    )
}