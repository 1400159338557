import React, { useState, useEffect } from 'react';
import { getPedido, getCopysDevolucion } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import { NO_IMAGE } from 'constants/general';
import { removeDuplicates } from 'functions/removeDuplicates';
import Loading from 'components/Loading';
import { useIsMobile } from 'hooks/useIsMobile';
import { SolicitarCambioDevolucionDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista23-solicitarCambioDevolucion-desktop.js';
import { useToken } from 'hooks/useToken';
import { repareTextHtml } from 'functions/repareDataService';

export function SolicitarCambioDevolucionMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nombreProducto, setNombreProducto] = useState(false);
    const [informacion, setInformacion] = useState(false);

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido && informacion) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //obtiene informacion para copys
    useEffect(() => {
        getCopysDevolucion()
            .then(setInformacion);
    }, [])

    useEffect(() => { }, [informacion])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista23mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )
                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setNombreProducto(productoNombre);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);
                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);

                    getCopysDevolucion()
                        .then(setInformacion);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a cual es tu motivo
    const handleContinuar = () => {
        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/motivo-cancelacion");
    }

    const [mostrarDrop, setMostrarDrop] = useState(false);
    const ActivarDrop = () => {
        setMostrarDrop(!mostrarDrop);
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Solicitar Devolución</p>
                    </div>

                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            <div className="contenedorImagenesIgual1">
                                <ul className='ulIgual1'>
                                    {ima && ima.map((elemento, i) =>
                                        <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                        </li>
                                    )}
                                </ul>
                            </div>

                            {ima && ima.length > 1
                                ? <div className='tituloImagenes'>
                                    <p>Solicitar Devolución</p>
                                </div>
                                : <div className='tituloImagenes'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                            }
                        </div>

                        {/* es lo que compre pero tengo un problema (cualquier action)*/}
                        <div className="boxSolicitarDevolucion">
                            <h3 className='marginT30'>{Array.isArray(informacion?.informacion) && informacion?.informacion[0].message_parent}</h3>

                            <ul className="normasL1 marginT30">
                                {Array.isArray(informacion?.informacion) && informacion?.informacion[0].child.map((inf, id) =>
                                    String(inf.type).toLowerCase() === "dropdown"
                                        ? <>
                                            <li key={id} className={mostrarDrop ? "liLink link flechaUp" : "liLink link flechaBottom"} onClick={(e) => ActivarDrop()}>{inf.message_parent} </li>
                                            {mostrarDrop
                                                ? <>
                                                    {Array.isArray(inf?.child) && inf?.child.length > 0 && inf?.child !== null
                                                        ? <ul className="normasL1">
                                                            {inf?.child.map((inf2, id2) =>
                                                                <li key={id2}>{inf2?.message_parent}</li>
                                                            )}
                                                        </ul>
                                                        : null
                                                    }
                                                </>
                                                : null
                                            }
                                        </>
                                        : <li key={id}>{inf.message_parent} </li>
                                )}
                            </ul>

                            {/* <p className="nota textLeft marginB40">{Array.isArray(informacion?.informacion) && informacion?.informacion[1].message_parent}</p> */}

                            {Array.isArray(informacion?.informacion) && informacion?.informacion[1]
                                ? <div className="boxPoliticas marginB40" >
                                    {String(informacion?.informacion[1].type).toLowerCase() === "link"
                                        ? <a href={informacion?.informacion[1]?.additional_params?.url} className='link' target="_blank">
                                            {informacion?.informacion[1]?.message_parent}
                                        </a>
                                        : <p className=''>{informacion?.informacion[1]?.message_parent}</p>
                                    }
                                </div>
                                : null
                            }

                            <div className="boxBtnContinuarDevolucion">
                                <button className="btnContinuarDevolucion" onClick={handleContinuar}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export function SolicitarCambioDevolucion() {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <SolicitarCambioDevolucionDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <SolicitarCambioDevolucionMovil />
                : null
            }
        </>
    )
}