import React from 'react'
import { MenuCategoryHeader } from "components/Header/MenuCategory"
import { Link } from "react-router-dom"

export function CategoryMenuHeader() {

    return(
        <nav className="categoryMenuHeader">
            <ul>
                <li className="productosNewCat">
                    <p>Departamentos</p>
                    {/* <Link to="/categoria/">Departamentos</Link> */}
                    <MenuCategoryHeader />
                </li>
            </ul>
        </nav>
    )
}