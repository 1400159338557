import React, { useState, useEffect } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import getCategoryMenu from 'services/getCategoryMenu';
import { getCategoryMenuV1 } from 'services/getCategoryMenu';
import './style.sass';
import { useIsMobile } from 'hooks/useIsMobile';
import Loading from 'components/Loading';
import { CategoriaDesktop } from 'pages/Categorias/MenuCategoria/CategoriaDesktop';
import { CategoriaMobile } from 'pages/Categorias/MenuCategoria/CategoriaMobile';
import { encodeStorage, encodeStorageLatin } from 'functions/storageBase64';
import { removeStorage } from 'functions/storageBase64';
import { decodeStorage } from 'functions/storageBase64';

export default function MenuCategorias() {

    const { isMobile } = useIsMobile();
    const [response, setResponse] = useState([]);
    const [newResponse, setNewResponse] = useState([]);
    const [loading, setLoading] = useState(true);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Category List Page');

        removeStorage('wishlistAfter');
        removeStorage('productAfter');
        removeStorage('typeButton');
    }, [])

    //- Funcion que va al servicio de categorias
    useEffect(() => {
        getCategoryMenu()
        .then(setResponse)
        window.scrollTo(0, 0);
    }, [])

    //- Funcion que va al servicio de categorias V1
    useEffect(() => {
        getCategoryMenuV1()
        .then(setNewResponse)
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (response) {
            setTimeout(function () {
                encodeStorage('categoryTree', response)
            }, 700);
        }
    }, [response])

    useEffect(() => {
        if (newResponse) {
            setTimeout(function () {
                //encodeStorageLatin('categoryTreeNew', newResponse)
                encodeStorage('categoryTreeNew', newResponse)
            }, 700);
        }
    }, [newResponse])

    //recibe la direccion del CP    
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPostal] = useState(codigoP);
    const [updateCPBar, setUpdateCPBar] = useState();

    const changeDirectionBar = (val) => {
        setCodigoPostal(val);
        setUpdateCPBar(val);
    };

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar]);


    return (
        <>
            { loading && <Loading />}


            {isMobile
                ? isMobile === 'desktop'
                    ? <CategoriaDesktop
                        response={newResponse}
                        setLoading={setLoading}
                    />
                    : isMobile === 'movile'
                        ? null
                        : <>
                            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} headerEnCategoria={"headerEnCategoria"} />
                            <CategoriaMobile
                                response={newResponse}
                                setLoading={setLoading}
                            />
                        </>
                : null
            }
        </>
    )
}