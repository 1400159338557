import { useState, useEffect } from 'react'
import axios from 'axios'

export function useServices(config){

    const [data, setData] = useState(null)
    const [statusCode, setStatusCode] = useState(null)
    const [headers, setHeaders] = useState(null)
    const [error, setError] = useState({error: null, message: null})
    const [loading, setLoading] = useState(true)

    const response = async () =>{
        await axios(config)
        .then(res => {
            const {
                //config,
                headers, 
                status, 
                data
            } = res
            //console.log(res)
            // console.log(config)
            // console.log(data)
            // console.log(headers)
            // console.log(status)
            setData(data)
            setStatusCode(status)
            setHeaders(headers)
        })
        .catch(error =>{
            // console.log(error)
            // console.log(error.request)
            // console.log(error.request.status)
            // console.log(error.response.data)
            setData(error.request.response)
            setError({error: error.response.status, message: error.response.data.msg })
            setStatusCode(error.request.status)
        })
        setLoading(false)
    }

    useEffect(()=>{
        response()
    },[])

    return {
        headers,
        statusCode,
        data,
        error,
        loading
    }
}