import React from 'react';
import { Link } from 'react-router-dom';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import CardAddress from 'components/Address/CardAddress';
import ErrorType from 'pages/Error/ErrorType';

export function SelecDireccionTemplateDesktop(props) {
    const {
        rawAddress,
        address,
        errorText,
        changeDefaultA,
        removeValueA
    } = props;

    return (
        <>
            <main>
                <div className="content myAccountSection">

                    <MenuMiCuenta />

                    <div className="navigationContentMyAccount">
                        <h1 className="h1TitleDireccion">Mis Direcciones</h1>

                        {rawAddress.statusCode === 200
                            ? <div className="contentAllDirection">

                                <div className="contenDireccion">
                                    {address.address
                                        ? address.address.map(({ id, accesibilidad, nombre, direccion, numeroExterior, colonia, codigoPostal, municipio, ciudad, telefono }) =>
                                            <CardAddress
                                                key={id}
                                                id={id}
                                                accesibilidad={accesibilidad}
                                                nombre={nombre}
                                                direccion={direccion}
                                                numeroExterior={numeroExterior}
                                                colonia={colonia}
                                                codigoPostal={codigoPostal}
                                                municipio={municipio}
                                                ciudad={ciudad}
                                                telefono={telefono}
                                                selectDefaultA={changeDefaultA}
                                                removeButton={removeValueA}
                                                editRoute="/mi-cuenta/direcciones-envio/editar"
                                            />
                                        )
                                        : null
                                    }
                                </div>

                                <Link to="/mi-cuenta/direcciones-envio/agregar" className="btn blanco addDir">
                                    <p className="blanco1">Agregar dirección</p>
                                    {/* <p className="blanco2"></p> */}
                                </Link>
                            </div>
                            : null
                        }
                        <ErrorType
                            section=""
                            codeStatus={rawAddress.statusCode}
                            errorText={errorText}
                        />
                    </div>
                </div>
            </main>
        </>
    )
}