import React, { useEffect, useState, Fragment } from 'react';
import "./style.sass";
import { useHistory } from 'react-router-dom';

function Option({ value, text }) {
    return (
        <option value={value}>{text}</option>)
}

export function AgendarCita() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Agendar Cita Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const estados = [
        {
            "id": 0, "name": "Ciudad de México",
            "municipios": [
                { "id": 0, "alcaldia": "Cuauhtémoc" },
                { "id": 1, "alcaldia": "Benito Juárez" },
                { "id": 2, "alcaldia": "Gustavo A. Madero" }
            ]
        },
        {
            "id": 1, "name": "Estado de México",
            "municipios": [
                { "id": 0, "alcaldia": "Naucalpan de Juárez" },
                { "id": 1, "alcaldia": "Cd. Nezahualcoyotl" },
                { "id": 2, "alcaldia": "Cuautitlán Izcalli" }
            ]
        },
        {
            "id": 2, "name": "Nuevo León",
            "municipios": [
                { "id": 0, "alcaldia": "San Nicolás de los Garza" },
                { "id": 1, "alcaldia": "Monterrey" }
            ]
        },
        {
            "id": 3, "name": "Jalisco",
            "municipios": [
                { "id": 0, "alcaldia": "Guadalajara" }
            ]
        },
        {
            "id": 4, "name": "Puebla",
            "municipios": [
                { "id": 0, "alcaldia": "Puebla" }
            ]
        },
        {
            "id": 5, "name": "Morelos",
            "municipios": [
                { "id": 0, "alcaldia": "Cuernavaca" }
            ]
        },
        {
            "id": 6, "name": "Durango",
            "municipios": [
                { "id": 0, "alcaldia": "Gómez Palacio" }
            ]
        },
        {
            "id": 7, "name": "Guanajuato",
            "municipios": [
                { "id": 0, "alcaldia": "Celaya" },
                { "id": 1, "alcaldia": "León" }
            ]
        },
        {
            "id": 8, "name": "Hidalgo",
            "municipios": [
                { "id": 0, "alcaldia": "Pachuca" }
            ]
        },
        {
            "id": 9, "name": "Veracruz",
            "municipios": [
                { "id": 0, "alcaldia": "Coatzacoalcos" }
            ]
        }
    ];

    const municipios = [
        { "id": 0, "alcaldia": "Cuauhtémoc", "id_estado": 0 },
        { "id": 1, "alcaldia": "Benito Juárez", "id_estado": 0 },
        { "id": 2, "alcaldia": "Gustavo A. Madero", "id_estado": 0 },
        { "id": 3, "alcaldia": "Naucalpan de Juárez", "id_estado": 1 },
        { "id": 4, "alcaldia": "Cd. Nezahualcoyotl", "id_estado": 1 },
        { "id": 5, "alcaldia": "Cuautitlán Izcalli", "id_estado": 1 },
        { "id": 6, "alcaldia": "San Nicolás de los Garza", "id_estado": 2 },
        { "id": 7, "alcaldia": "Monterrey", "id_estado": 2 },
        { "id": 8, "alcaldia": "Guadalajara", "id_estado": 3 },
        { "id": 9, "alcaldia": "Puebla", "id_estado": 4 },
        { "id": 10, "alcaldia": "Cuernavaca", "id_estado": 5 },
        { "id": 11, "alcaldia": "Gómez Palacio", "id_estado": 6 },
        { "id": 12, "alcaldia": "Celaya", "id_estado": 7 },
        { "id": 13, "alcaldia": "León", "id_estado": 7 },
        { "id": 14, "alcaldia": "Pachuca", "id_estado": 8 },
        { "id": 15, "alcaldia": "Coatzacoalcos", "id_estado": 9 }
    ];

    const tiendas = [
        {
            "id": 0, "name": "Plaza Insurgentes", "direccion": "San Luis Potosí #214",
            "plaza": null,
            "colonia": "Col. Roma, C.P. 06700",
            "estado": "Ciudad de México",
            "municipio": "Cuauhtémoc",
            "telefonodirecto": "(55) 5230 3960",
            "tel2": null,
            "telefonoconm": "(55) 5230 3900",
            "tel3": null,
            "extension": "ext. 1028 1090",
            "correo": "ca0421@sears.com.mx",
            "id_estado": 0,
            "id_municipio": 0
        },
        {
            "id": 1, "name": "Plaza Universidad", "direccion": "Av. Universidad #1000",
            "plaza": null,
            "colonia": "Col. Gral. Anaya, C.P. 03540",
            "estado": "Ciudad de México",
            "municipio": "Benito Juárez",
            "telefonodirecto": "(55) 5604 7378",
            "tel2": null,
            "telefonoconm": "(55) 5422 8000",
            "tel3": null,
            "extension": "ext. 1028 1090",
            "correo": "ca0423@sears.com.mx",
            "id_estado": 0,
            "id_municipio": 1
        },
        {
            "id": 2, "name": "Plaza Satélite", "direccion": "Av. Dr. Gustavo Baz #210",
            "plaza": null,
            "colonia": "Fracc. Colón Echegaray, C.P. 53300",
            "estado": "Estado de México",
            "municipio": "Naucalpan de Juárez",
            "telefonodirecto": "5363 8991 ",
            "tel2": null,
            "telefonoconm": null,
            "tel3": null,
            "extension": null,
            "correo": "ca0424@sears.com.mx",
            "id_estado": 1,
            "id_municipio": 3
        },
        {
            "id": 3, "name": "Plaza Lindavista", "direccion": "Av. Montevideo #481. Esq. con Piura",
            "plaza": null,
            "colonia": "Col. Lindavista, C.P. 07300",
            "estado": "Ciudad de México",
            "municipio": "Gustavo A. Madero",
            "telefonodirecto": "5119 9147",
            "tel2": "5119 9465",
            "telefonoconm": null,
            "tel3": null,
            "extension": null,
            "correo": "ca0426@sears.com.mx",
            "id_estado": 0,
            "id_municipio": 2
        },
        {
            "id": 4, "name": "Nezahualcoyotl", "direccion": "Av. Bordo de Xochiaca #3",
            "plaza": "C.C Jardín Bicentenario",
            "colonia": "Col. Cd Jardín Bicentenario, C.P. 57205",
            "estado": "Estado de México",
            "municipio": "Cd. Nezahualcoyotl",
            "telefonodirecto": "(55) 1558 4004",
            "tel2": null,
            "telefonoconm": "(55) 2619 7101",
            "tel3": null,
            "extension": "ext 1028 2028",
            "correo": "ca0488@sears.com.mx",
            "id_estado": 1,
            "id_municipio": 4
        },
        {
            "id": 5, "name": "Cuautitlan", "direccion": "Av 1° de Mayo s/n Mza C-34",
            "plaza": "C.C Luna Parc",
            "colonia": null,
            "estado": "Estado de México",
            "municipio": "Cuautitlén Izcalli",
            "telefonodirecto": null,
            "tel2": null,
            "telefonoconm": "(55) 5864 3890",
            "tel3": null,
            "extension": "ext 1028 1098",
            "correo": "ca0489@sears.com.mx",
            "id_estado": 1,
            "id_municipio": 5
        },
        {
            "id": 0, "name": "Anáhuac", "direccion": "Manuel Barragán #325 Nte. y Fray Bartolome de las Casas",
            "plaza": null,
            "colonia": "Col. Residencial Anáhuac, C.P 66450",
            "estado": "Nuevo León",
            "municipio": "San Nicolás de los Garza",
            "telefonodirecto": null,
            "tel2": null,
            "telefonoconm": "(81) 8318 0405",
            "tel3": null,
            "extension": null,
            "correo": "ca0431@sears.com.mx",
            "id_estado": 2,
            "id_municipio": 6
        },
        {
            "id": 1, "name": "Monterrey", "direccion": "Padre Mier #143 Pte.",
            "plaza": null,
            "colonia": "Col. Centro, C.P. 64000",
            "estado": "Nuevo León",
            "municipio": "Monterrey",
            "telefonodirecto": null,
            "tel2": null,
            "telefonoconm": "(81) 8340 3081",
            "tel3": "(81) 8318 0100",
            "extension": "exts. 1028 y 1098",
            "correo": "ca0432@sears.com.mx",
            "id_estado": 2,
            "id_municipio": 7
        },
        {
            "id": 2, "name": "Guadalajara Centro", "direccion": "Av. 16 de Septiembre #650",
            "plaza": null,
            "colonia": "Col. Centro, C.P. 44100",
            "estado": " Jalisco",
            "municipio": "Guadalajara",
            "telefonodirecto": "(33) 3614 9963",
            "tel2": null,
            "telefonoconm": "(33) 3669 0200",
            "tel3": null,
            "extension": "exts. 1028 y 1095",
            "correo": "ca0433@sears.com.mx",
            "id_estado": 8,
            "id_municipio": 3
        },
        {
            "id": 3, "name": "Puebla", "direccion": "Av. 3 Poniente #138",
            "plaza": null,
            "colonia": "Centro Histórico, C.P. 72000",
            "estado": "Puebla",
            "municipio": "Puebla",
            "telefonodirecto": "(222) 232 5455",
            "tel2": "(222) 242 1811",
            "telefono-conm": null,
            "tel3": null,
            "extension": null,
            "correo": "ca0434@sears.com.mx",
            "id_estado": 4,
            "id_municipio": 9
        },
        {
            "id": 4, "name": "Cuernavaca", "direccion": "Av. Alta Tensión Esq. Río Balsas",
            "plaza": null,
            "colonia": "Col. Lomas de la Selva, C.P. 62270",
            "estado": "Morelos",
            "municipio": "Cuernavaca",
            "telefonodirecto": null,
            "tel2": null,
            "telefonoconm": "(777) 362 0208",
            "tel3": null,
            "extension": "exts. 1028 y 1095",
            "correo": "ca0439@sears.com.mx",
            "id_estado": 5,
            "id_municipio": 10
        },
        {
            "id": 5, "name": "Gomez Palacio", "direccion": "Blvd. M. Alemán y Campecha s/n",
            "plaza": "C.C. Plaza Imagen",
            "colonia": "Col. Las Rosas, C.P. 35000",
            "estado": "Durango",
            "municipio": "Gómez Palacio",
            "telefonodirecto": null,
            "tel2": null,
            "telefonoconm": "(871) 748 0404",
            "tel3": null,
            "extension": "exts. 1028 y 1095",
            "correo": "ca0441@sears.com.mx",
            "id_estado": 6,
            "id_municipio": 11
        },
        {
            "id": 6, "name": "León Plaza", "direccion": "(Acceso por Av. Cerro Gordo) Av. de las Torres #2002",
            "plaza": "C.C. Plaza Mayor",
            "colonia": "Col. La Presa, C.P. 37160",
            "estado": "Guanajuato",
            "municipio": "León",
            "telefonodirecto": "(477) 781 1762",
            "tel2": "(477) 781 1765",
            "telefonoconm": null,
            "tel3": null,
            "extension": null,
            "correo": "ca0442@sears.com.mx",
            "id_estado": 7,
            "id_municipio": 13
        },

        {
            "id": 7, "name": "Celaya", "direccion": "Av. Torres Landa s/n",
            "plaza": "C.C. Galerías Tecnológico",
            "colonia": "Col. Del Parque, C.P 38010",
            "estado": "Guanajuato",
            "municipio": "Celaya",
            "telefonodirecto": null,
            "tel2": null,
            "telefonoconm": "(461) 598 5920",
            "tel3": null,
            "extension": "exts. 1028 y 1095",
            "correo": "ca0448@sears.com.mx",
            "id_estado": 7,
            "id_municipio": 12
        },
        {
            "id": 8, "name": "Pachuca", "direccion": "Blvd. Felipe Ángeles s/n",
            "plaza": "C.C. Plaza Fiesta Perisur",
            "colonia": "Col. Venta Prieta, C.P. 42080",
            "estado": "Hidalgo",
            "municipio": "Pachuca",
            "telefonodirecto": null,
            "tel2": null,
            "telefonoconm": "(771) 717 0265",
            "tel3": null,
            "extension": "exts. 1028 y 1095",
            "correo": "ca0444@sears.com.mx",
            "id_estado": 8,
            "id_municipio": 14
        },
        {
            "id": 9, "name": "Coatzacoalcos", "direccion": "Antigua carretera a Minatitlán Km 8",
            "plaza": "C.C. Forum Coatzacoalcos",
            "colonia": "Col. El Tesoro, C.P. 96536",
            "estado": "Veracruz",
            "municipio": "Coatzacoalcos",
            "telefonodirecto": "(921) 210 9034",
            "tel2": null,
            "telefonoconm": null,
            "tel3": null,
            "extension": null,
            "correo": "ca0477@sears.com.mx",
            "id_estado": 9,
            "id_municipio": 15
        }
    ];

    const [selectEstado, setSelectEstado] = useState(false);
    const [selectMunicipio, setSelectMunicipio] = useState(false);
    const [activarMunicipio, setActivarMunicipio] = useState(false);
    const handleEstado = (e) => {
        setSelectEstado(e.target.value);
        setSelectMunicipio(false);
        handleClicActivar();
    };

    const handleMunicipio = (e) => {
        setSelectMunicipio(e.target.value);
    };
    const handleClicActivar = () => {
        setActivarMunicipio(true);
    }
    useEffect(() => {
        setActivarMunicipio(false);
    }, [selectEstado])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal contentModalPadd">

                    <div className="contentClose contentC">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Agenda tu cita</p>
                    </div>

                    <div className="bxPF">
                        <p className="colorRed">Selecciona tu sucursal por:</p>
                    </div>

                    <div className="dataModal">
                        <div className="boxAN">

                            <div className="bxSelects">
                                <div className="fileset1">
                                    <label htmlFor="estado">Estado</label>

                                    <select id="estado" name="estado" type="text" onChange={handleEstado} defaultValue="">
                                        <option value="" disabled>Selecciona un estado</option>

                                        {estados && estados.map((value, ind) =>
                                            <Fragment key={ind}>
                                                <Option value={value.id} text={value.name} />
                                            </Fragment>
                                        )}
                                    </select>
                                </div>

                                <p className="espacio"></p>

                                <div className="fileset1">
                                    <label htmlFor="alcaldia">Alcaldía / Municipio</label>
                                    <select id="alcaldia" name="alcaldia" type="text" defaultValue="" onChange={handleMunicipio}
                                        onClick={handleClicActivar}
                                        placeholder="Selecciona una alcaldía / municipio">
                                        {selectEstado === false
                                            ? <option value="" disabled selected>Primero selecciona un estado</option>
                                            : activarMunicipio === true && <option value="" selected>Selecciona una alcaldía / municipio</option>
                                        }
                                        {activarMunicipio === false && selectEstado !== false && <option value={""}  disabled selected>Selecciona una alcaldía / municipio</option>}

                                        {selectEstado !== false && activarMunicipio === true && municipios.map((municipio, id) =>
                                            Number(municipio.id_estado) === Number(selectEstado) &&
                                            <option value={municipio.id} key={id}> {municipio.alcaldia}</option>
                                        )}
                                    </select>
                                </div>

                                {selectMunicipio !== false && selectEstado &&
                                    <>
                                        <p className="espacio2"></p>

                                        {tiendas.map((tienda, id) =>
                                            Number(tienda.id_estado) === Number(selectEstado) && Number(tienda.id_municipio) === Number(selectMunicipio)
                                                ? <div className="bxTiendaAutocenter" key={id}>

                                                    <p className="tiendaTitle">{tienda.name}</p>
                                                    <p className="tiendaP">{tienda.direccion}, {tienda.colonia}.</p>
                                                    <p className="tiendaP">{tienda.municipio}, {tienda.estado}</p>

                                                    <div className="telefonos">
                                                        <div className="iconoT">
                                                            <img src="/img/telefono.png" alt="ico-telefono" />
                                                        </div>

                                                        <div className="textoT">
                                                            <p className="subT">Llámanos</p>
                                                            {tienda.telefonodirecto && <a href={"tel:" + tienda.telefonodirecto}>{tienda.telefonodirecto}</a>}
                                                            {tienda.tel2 && <a href={"tel:" + tienda.tel2}>{tienda.tel2}</a>}
                                                            {tienda.telefonoconm && <a href={"tel:" + tienda.telefonoconm}>{tienda.telefonoconm} {tienda.extension && <span>{tienda.extension}</span>}</a>}
                                                            {tienda.tel3 && <a href={"tel: " + tienda.tel3}> {tienda.tel3} {tienda.extension && <span>{tienda.extension}</span>}</a>}
                                                        </div>
                                                    </div>

                                                    <div className="correo">
                                                        <div className="iconoT">
                                                            <img src="/img/email.png" alt="ico-mail" />
                                                        </div>

                                                        <div className="textoT">
                                                            <p><span className="subT">Escríbenos: </span> <a href={"mailto:" + tienda.correo}>{tienda.correo} </a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}