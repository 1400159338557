import React, { useState, useEffect, useRef } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import BarraCP from 'components/BarCP/BarHeaderCP';
import CardOrder from 'components/CardProduct/CardMyAccount/CardOrder';
import PaginadorPedidos from 'components/PaginadorPedidos';
import { Link } from 'react-router-dom';

export function MisPedidosTemplateDesktop(props) {
    const {
        pedidos,
        days,
        setDays,
        handleSubmit,
        handleSelect,
        searchPedidos,
        total_items,
        items_per_page,
        current_page,
        cambiarPagina,
        pag,
        rangoDias,
        setPedidos,
        history
    } = props;

    const selectFilter = useRef(null);

    // console.log('ped: ',pedidos)

    const handleClick = () => {
        setDays(Number(0));
        selectFilter.current.value = 0;
        setPedidos(
            {
                ...pedidos,
                days: 0,
                pages: 1
            }
        )
        let nuevaUrl = `/mi-cuenta/mis-pedidos/dias=0/pagina=1`;
        history.replace(nuevaUrl);
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>
            <main>
                <section className="miCuentaContainer">

                    <div className="container myAccountSection">

                        <MenuMiCuenta />
                        <div className="container contMyOrders">
                            {pedidos?.error && Number(days) === 0
                                ? null
                                : <div className="contFilter">
                                    <form onSubmit={handleSubmit}>
                                        <label className="fileset1">
                                            <select ref={selectFilter} defaultValue={rangoDias} name="filtros" onChange={handleSelect} className="selectFiltros">
                                                <option value="" disabled id="title">Filtrar por:</option>
                                                <option value="0" id="todos">Todos mis pedidos</option>
                                                <option value="7">Últimos 7 días</option>
                                                <option value="30">Últimos 30 días</option>
                                                <option value="180">Últimos 6 meses</option>
                                                <option value="360">Último año</option>
                                            </select>
                                        </label>
                                    </form>
                                </div>
                            }
                            <div className="contPedidos">
                                {pedidos?.error &&
                                    <>
                                        <p className="parrafo1">
                                            {days < 31
                                                ? Number(days) === 0
                                                    ? 'Actualmente no cuentas con ningún pedido'
                                                    : `No tienes pedidos de los últimos ${days} días`
                                                : `No tienes pedidos de los últimos ${days / 30} meses`}.
                                        </p>

                                        {Number(days) === 0
                                            ? <p><Link to="/" className="btn rojo botonEliminar">Comenzar a comprar</Link></p>
                                            : <p><span onClick={handleClick} className="btn rojo botonEliminar">Eliminar filtro</span></p>
                                        }
                                    </>
                                }
                                {pedidos?.pedidos &&
                                    pedidos.pedidos.map((pedido, ind) =>
                                        pedido.products && <CardOrder
                                            key={ind}
                                            order_number={pedido?.order_number}
                                            products={pedido?.products}
                                            buy_date={pedido?.buy_date}
                                            total={pedido?.total}
                                            estatusPedido={pedido?.axxi?.status_axxi_id}
                                        />
                                    )
                                }
                            </div>

                            {searchPedidos &&
                                <PaginadorPedidos
                                    pagination={pag}
                                    cambiarPagina={cambiarPagina}
                                    total_items={total_items[0]}
                                    page={current_page}
                                    page_size={items_per_page[0]}
                                    total={10}
                                    rangoDias={rangoDias} />
                            }
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}