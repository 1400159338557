import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import LoginMiTelmexForm from 'components/MiTelmex/LoginMiTelmex/loginMiTelmexFormComponent';
import './style.sass';
import { getQuestions, postLoginTelmex } from 'services/MiTelmexServices';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useStorage64 } from 'hooks/useStorageBase64';
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import ModalBlackList from 'components/Modal/modalBlackList';
import { encodeStorage } from 'functions/storageBase64';

export default function LoginMiTelmex() {
    const [successQuestions, setSuccessQuestions] = useState(false);
    const [ok, setOk] = useState(false); // inicio sin problema
    const [modalToggle, setModalToggle] = useState(false); // mostrar modal

    const [successForm, getSuccessForm] = useState(false);
    const [dataForm, getDataForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isLoged, setIsLoged] = useStorage64('session_token', '');
    const [, setTelefono] = useStorage64('telefono_usuario', '');
    const [errorMessage, setErrorMessage] = useState(false);
    const [mensajeDelError, setMensajeDelError] = useState();
    const [errorVerificacion, setErrorVerificacion] = useState(false);
    const history = useHistory();
    const [valorServicio, setValorServicio] = useState(false);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account | Mi Telmex - Login');
        window.scrollTo(0, 0);
    }, []);

    //-- valida si tienes sesion activa y regresa 1 al historico
    useEffect(() => {
        if (isLoged === '') {
            setLoading(false);

        } else {
            setLoading(false);
        }
    }, [isLoged]);

    //-- manda la peticion al servicio de login
    useEffect(() => {
        if (dataForm) {
            postLoginTelmex({ raw: dataForm })
                .then(getSuccessForm)
        }
        return
    }, [dataForm]);

    //-- recibe la respuesta del servicio de Login
    useEffect(() => {
        if (successForm) {
            if (successForm.data.is_login) {
                setOk(true);
                setTelefono(dataForm.phone);
                encodeStorage('telefono_usuario', dataForm.phone);
            }
            else if (successForm.data.is_login === false) {
                errorLogin();
            }
        }
    }, [successForm]);

    // recibe los datos del formulario
    const reciveData = (val) => {
        getDataForm(val);
        setLoading(true);
    };

    //------- peticion para saber si el usuario es lista negra o blanca
    useEffect(() => {
        if (ok === true) {
            getQuestions()
                .then(setSuccessQuestions)
        }
        return
    }, [ok]);

    // recibe response de get questions (esta o no en lista negra)
    useEffect(() => {
        const valor = [];
        const valorError = [];

        if (successQuestions) {
            for (let [key, value] of Object.entries(successQuestions)) {
                if (String(key).toLowerCase() === "data") {
                    for (let [key1, value1] of Object.entries(value)) {
                        if (key1 === "data") {
                            valor.push(value1)
                        }
                    }
                }
            }

            if (valor != undefined && valor != []) { setValorServicio(valor); }


            for (let [key, value] of Object.entries(successQuestions)) {
                if (String(key).toLowerCase() === "data") {
                    for (let [key1, value1] of Object.entries(value)) {
                        if (key1 === "metadata") {
                            valorError.push(value1)
                        }
                    }
                }
            }

            setMensajeDelError(valorError[0].message);
            setErrorVerificacion(valorError[0].http_status);

        }
    }, [successQuestions]);


    // revisa si redirecciona o no ( lista blanca o lista negra)
    useEffect(() => {
        if (valorServicio && valorServicio[0].is_blacklist === false) {
            history.push("/mi-cuenta/mitelmex/");
        }
        else if (successQuestions && errorVerificacion !== 200 && errorVerificacion !== 201) {
            setModalToggle(true); // abrir modal
        }
        return
    }, [valorServicio]);


    // funcion cuando regresa algun error con el login
    const errorLogin = () => {
        setErrorMessage(successForm.data.message);
        setLoading(false);
    };

    /** funcion para cerrar modal */
    const closeModal = () => {
        setModalToggle(false);
    };

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    };

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar]);

    return (
        <>
            {loading && <Loading type="telmexLoading" />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="loginRegistroPage">

                    {errorMessage
                        ? <p className="errorMessageWarning">{errorMessage}</p>
                        : null
                    }
                    <div className="container">
                        <LoginMiTelmexForm dataForm={reciveData} warningMessage={errorMessage} />
                    </div>

                    {modalToggle ? <ModalBlackList openModal={modalToggle} cerrarModal={closeModal} mensaje={mensajeDelError} /> : null}
                </section>
            </main>
            <Footer />
        </>
    )
}