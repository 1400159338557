import React, { useEffect } from 'react';
import './style.sass';
import { Link } from 'react-router-dom';
import { MenuMiCuenta } from 'components/MenuMiCuenta';

export default function SinDireccionesDesktop() {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account | No Addresses Page');
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <main>
                <div className="content myAccountSection">

                    <MenuMiCuenta />

                    <div className="navigationContentMyAccount">
                        <div className="bxSindirecciones">
                            <p className="pSindirecciones">Aún no cuentas con direcciones guardadas</p>
                            <Link to="/mi-cuenta/direcciones-envio/agregar" className="btn rojo btnAgregarD">Agregar dirección</Link>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}