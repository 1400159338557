import React, { useState, useEffect, Fragment } from 'react';
import ModuleQuantity from '../Quantity';
import ModuleGuiaTallas from './guiaTallas';
import './style.sass';
import { repareTitleSEO } from 'functions/repareDataService'

function Option({ value, text }) {
    return (
        <option value={String(value)}>{text}</option>)
}

function SelectColor({ ...props }) {
    const {
        id,
        size_color,
        returnColorSelected,
        returnArraySizes,
        returnVariant,
        setVarUpdateTallas
    } = props;



    const tallas = [];
    size_color.map((opcion, i) => opcion?.sizes && tallas.push({ ide: i, lon: opcion.sizes.length }))
    var indiceMax;

    if (tallas.length > 0) {
        var max = tallas[0].lon;
        indiceMax = tallas[0].ide;
        // console.log(" seleccion de color : ", max," INDICE MAX:",indiceMax, " TALLAS: ", tallas)
        for (var i = 0; i < tallas.length; i++) {
            if (tallas[i].lon > max) {
                max = tallas[i].lon;
                indiceMax = tallas[i].ide;
            }
        }
    }
    else {
        if (size_color[0]?.stock > 0) {
            indiceMax = 0;
            // console.log("no tiene tallas...", indiceMax)
            // console.log("size_color.stock  ",size_color[0]?.stock)
        }
    }

    const [selectColor, setSelectColor] = useState(size_color.filter((option, id) => id === Number(indiceMax)));
    const [tallaColor, setTallaColor] = useState();
    const [variant, setVariant] = useState();
    const [showSelectColor, setShowSelectColor] = useState(false);

    useEffect(() => {
        // console.log("color seleccionado: ", size_color.filter((option, id) => id === Number(indiceMax))[0].color)
        setShowSelectColor(false);
        setSelectColor(size_color.filter((option, id) => id === Number(indiceMax)));
    }, [id])

    // se actualizan tallas si cambia el id del producto
    useEffect(() => {
        if (id) {

            let color = size_color.filter((option, id) => id === Number(indiceMax));
            let color2 = color[0].color;

            if (color2) {
                let result = size_color[size_color.findIndex(item => item.color === String(color2))].sizes;

                returnColorSelected(color2);
                returnArraySizes(result);
                setTallaColor(size_color[size_color.findIndex((item, id) => id === Number(indiceMax))].sizes);
                setVariant(size_color[size_color.findIndex((item, id) => id === Number(indiceMax))].variant);

                setVarUpdateTallas(result);
            }
        }
    }, [id])

    useEffect(() => {
        returnColorSelected(selectColor);
        if (tallaColor) {
            returnArraySizes(tallaColor);
        }
        returnVariant(variant);
    }, [selectColor])


    // actualiza al change el color
    const [udColor, setUdColor] = useState(false);
    useEffect(() => {
        if (udColor) {
            setSelectColor(udColor);
            setTallaColor(size_color[size_color.findIndex(item => item.color === String(udColor))].sizes);
            setVariant(size_color[size_color.findIndex(item => item.color === String(udColor))].variant);
        }
    }, [udColor])

    const updateColor = evt => {
        setUdColor(evt.target.value);
    };

    useEffect(() => {
        // console.log('selectColor', selectColor, 'tallas: ', tallas)
        if (selectColor && tallas.length > 0) {
            returnColorSelected(selectColor);
            returnArraySizes(tallaColor);
            setTallaColor(size_color[size_color.findIndex((item, id) => id === Number(indiceMax))].sizes);
            setVariant(size_color[size_color.findIndex((item, id) => id === Number(indiceMax))].variant);
        }
    }, [])

    const showColor = () => {
        setShowSelectColor(true);
        returnColorSelected(size_color.filter((option, id) => id === 0));
        if (tallas.length > 0) {
            returnArraySizes(size_color[size_color.findIndex((item, id) => id === 0)].sizes);
            setTallaColor(size_color[size_color.findIndex((item, id) => id === 0)].sizes);
            setVariant(size_color[size_color.findIndex((item, id) => id === 0)].variant);
        }
    };

    return (
        <div className="selectColor">
            {!showSelectColor && indiceMax
                ? <p onClick={showColor} className="pSelect">
                    {String((size_color.filter((option, id) => id === Number(indiceMax)))[0].color).length > 16
                        ? <>{String((size_color.filter((option, id) => id === Number(indiceMax)))[0].color).substring(0, 16)}</>
                        : String((size_color.filter((option, id) => id === Number(indiceMax)))[0].color)}
                </p>
                : !showSelectColor && !indiceMax &&
                <p onClick={showColor} className="pSelect">
                    {String((size_color.filter((option, id) => id === Number(0)))[0].color).length > 16
                        ? <>{String((size_color.filter((option, id) => id === Number(0)))[0].color).substring(0, 16)}</>
                        : String((size_color.filter((option, id) => id === Number(0)))[0].color)
                    }
                </p>
            }

            {showSelectColor &&
                <label>
                    <select id="selectColor" name="colores" defaultValue={size_color.filter((option, id) => id === Number(indiceMax))} onChange={updateColor}>
                        <option value="" disabled>Selecciona un color</option>
                        {size_color.map(({ color }, ind) =>
                            <Fragment key={ind}>
                                <Option value={color} text={color} />
                            </Fragment>
                        )}
                    </select>
                </label>
            }
        </div>
    )
}




function SelectTalla({ ...props }) {
    const {
        id,
        colorSelected,
        tallaArray,
        showColor,
        setTallaText,
        category,
        preseleccionar,
        varUpdateTallas,
        catPadre,
        catHija_Hombre,
        catHija_Mujer,
        catHija_Nino,
        catHija_Nina,
        tallasNino,
        tallasNina,
        tallasHombre,
        tallasMujer
    } = props;

    const [isTallaArray, setIsTallaArray] = useState(tallaArray);
    // console.log("en talla ---- color activo: ", colorSelected, " arreglo de tallads: ", tallaArray)

    // recibe la prop array de tallas y fija las tallas del elemento
    useEffect(() => {
        if (tallaArray) {
            setIsTallaArray(tallaArray);
            // updateTalla(tallaArray[0].variant);
        }
    }, [tallaArray])

    // si cambia el id del producto resetea las tallas
    useEffect(() => {
        if (id) {
            setIsTallaArray(varUpdateTallas);
        }
    }, [id])

    const [variante, setVariante] = useState(false);
    const updateTalla = variantID => {
        // console.log("talla elegida-->",isTallaArray[isTallaArray.findIndex(item => item.variant === String(evt.target.id))], " evt-->", evt.target.id)

        setVariante(variantID);
        // console.log('varianteID original:', variantID)
        let splitVariant
        if (variantID) {
            if (document.querySelectorAll('.liTalla.active').length >= 1) {
                document.querySelector('.liTalla.active').classList.remove('active');
            }
            else {
                // document.querySelector(`.variant_${variantID}`).classList.add('active');
            }
            splitVariant = repareTitleSEO(variantID)

            // if(splitVariant){
            // console.log("replace -- ", splitVariant)
            document.querySelector(`.variant_${splitVariant}`).classList.add('active');

            // }
        }

        let FFValue;
        if (isTallaArray[isTallaArray.findIndex(item => item.variant === String(variantID))].fulfillment) {
            FFValue = "true";
        }
        else {
            FFValue = "false";
        }
        props.returnSizeColor({ 'sku': variantID, 'stock': isTallaArray[isTallaArray.findIndex(item => item.variant === String(variantID))].stock, 'fulFliment': FFValue })
    }


    const [upD, setUpD] = useState(false);
    // revisa si hay una sola talla la preselecciona
    useEffect(() => {
        if (isTallaArray) {
            if (isTallaArray.length === 1) { //>=1
                updateTalla(isTallaArray[0].variant);
                setUpD(false);
            }
        }
    }, [isTallaArray, upD])

    useEffect(() => {
        if (preseleccionar) {
            // console.log("talla - preselrecionar : ", isTallaArray[0].variant)
            updateTalla(isTallaArray[0].variant);
            setUpD(false);
        }
    }, [preseleccionar])

    useEffect(() => {
        if (colorSelected) {
            if (colorSelected.length >= 2) {
                setUpD(true);
            }
            else if (colorSelected.length === 1) {
                // console.log('color:', colorSelected)
                // console.log('color ?:', colorSelected[0].color)
            }
        }
        else {
            //console.log('????', isTallaArray)
        }
    }, [colorSelected, id])

    useEffect(() => {
        if (variante) {
            //console.log('variante:', variante)
            if (isTallaArray) {
                //console.log('talla variante: ',isTallaArray.find(talla => talla.variant == variante).size)
                setTallaText(isTallaArray.find(talla => talla.variant == variante).size);
            }
        }
    }, [variante])


    return (
        <div className="selectTalla">
            <div className="copymodalT">
                <p onClick={showColor}>Talla </p>
                <ModuleGuiaTallas
                    category={category}
                    catPadre={catPadre}
                    catHija_Hombre={catHija_Hombre}
                    catHija_Mujer={catHija_Mujer}
                    catHija_Nino={catHija_Nino}
                    catHija_Nina={catHija_Nina}
                    tallasNino={tallasNino}
                    tallasNina={tallasNina}
                    tallasMujer={tallasMujer}
                    tallasHombre={tallasHombre}
                />
            </div>

            {isTallaArray &&
                // colorSelected && 
                isTallaArray.length >= 1
                ? <div className="label">
                    {/* <select name="tallas" defaultValue="" onChange={updateTalla}>
                        <option value="" disabled>Talla</option>
                        {
                            isTallaArray.map(({ size, variant }) =>
                                <Fragment key={variant}>
                                    <Option value={variant} text={size} />
                                </Fragment>
                            )
                        }
                    </select> */}

                    <ul className="ulTalla">
                        {
                            isTallaArray.map(({ size, variant, stock }) =>
                            stock > 0 ?
                                <li key={variant} className={`liTalla variant_${repareTitleSEO(variant)}`} onClick={() => updateTalla(variant)} id={variant} value={variant}>{size}</li>
                            :
                            <li key={variant} className={`liTalla tallaDisabled variant_${repareTitleSEO(variant)}`} id={variant} value={variant}>{size}</li>
                                )
                        }
                    </ul>
                </div>
                : null
            }
        </div>
    )
}


export default function SizeColor({ ...props }) {
    const {
        id,
        size_color,
        colorSelData,
        cantBase,
        stock,
        returnNumber,
        status,
        updateStockTC,
        updateFF,
        category,
        setColorText,
        setTallaText,
        colorText,
        // tallaText,
        preseleccionar,
        stockGeneral,
        catPadre,
        catHija_Hombre,
        catHija_Mujer,
        catHija_Nino,
        catHija_Nina,
        tallasNino, tallasNina, tallasMujer, tallasHombre,
        setLongitudTallas
    } = props

    const [colorSelected, setColorSelected] = useState();//size_color.filter((option, id) => id === Number(indiceMax)));
    const [tallaSelected, setTallaSelected] = useState();//size_color[size_color.findIndex((item, id) => id === Number(indiceMax))].sizes);

    const showColor = () => {
        //setShowSelectColor(true)
    };

    const returnColors = (val) => {
        if (val !== undefined) {
            //console.log('1')
            setColorSelected(val);
            colorSelData(val);
        }
        else {
            //console.log('2')
            setColorSelected(colorSelected);
            colorSelData(colorSelected);
        }
    };

    const [varUpdateTallas, setVarUpdateTallas] = useState();
    const returnSizes = (val) => {
        setTallaSelected(val);
    };

    useEffect(() => {
        if (id) {
            //console.log("valor de tallas =", varUpdateTallas)
            setTallaSelected(varUpdateTallas);
        }
    }, [id])

    const returnVariant = (val) => {
        if (val !== undefined) {
            props.returnSizeColor({ 'sku': val });
        }
    };

    const [stockUD, setStockUD] = useState(stock);

    useEffect(() => {
        if (updateStockTC) {
            setStockUD(updateStockTC);
        }
    }, [stock, updateStockTC, updateFF])

    useEffect(() => {
        if (id) {
            setStockUD(stock);
        }
    }, [stock, id])

    useEffect(() => {
        if (colorText) {
            setStockUD(stockGeneral);
            setTallaText(null);
        }
    }, [colorText])

    useEffect(() => {
        if (colorSelected) {
            if (colorSelected.length > 1) {
                setColorText(colorSelected);
            }
            else if (colorSelected.length === 1) {
                setColorText(colorSelected[0].color);
            }
        }
    }, [colorSelected, id])

    const [preseleccionarNivel3, setPreseleccionarNivel3] = useState(false);
    useEffect(() => {
        if (preseleccionar) {
            setPreseleccionarNivel3(preseleccionar);
        }
    }, [preseleccionar])

    useEffect(() => {
        if (size_color && size_color.find(item => item.sizes)) {
            setLongitudTallas(size_color.find(item => item.sizes))
        }
    }, [size_color])

    return (
        <div className="moduleTallaColor">
            {stockUD && status
                ? <>
                    {stockUD === 1
                        ? <div className="lastProducts">Queda 1 pieza</div>
                        : null
                    }
                    {stockUD >= 2 && stockUD <= 14
                        ? <div className="lastProducts">Últimas {stockUD} piezas</div>
                        : null
                    }
                    {stockUD >= 15 && stockUD <= 30
                        ? <div className="lastProducts">Últimas piezas</div>
                        : null
                    }
                </>
                : null
            }

            <div className="moduleColorQuantity">
                <SelectColor
                    id={id}
                    size_color={size_color}
                    returnColorSelected={returnColors}
                    returnArraySizes={returnSizes}
                    returnVariant={returnVariant}
                    setVarUpdateTallas={setVarUpdateTallas}
                />

                <ModuleQuantity
                    id={id}
                    cantBase={cantBase}
                    stock={stock}
                    returnNumber={returnNumber}
                />
            </div>
            {/* {console.log("size_color ---> ", size_color.find(item => item.sizes))} */}
            {size_color.find(item => item.sizes) &&
                <SelectTalla
                    id={id}
                    colorSelected={colorSelected}
                    tallaArray={tallaSelected}
                    showColor={showColor}
                    returnSizeColor={props.returnSizeColor}
                    setTallaText={setTallaText}
                    category={category}
                    preseleccionar={preseleccionarNivel3}
                    varUpdateTallas={varUpdateTallas}
                    catPadre={catPadre}
                    catHija_Hombre={catHija_Hombre}
                    catHija_Mujer={catHija_Mujer}
                    catHija_Nino={catHija_Nino}
                    catHija_Nina={catHija_Nina}
                    tallasNino={tallasNino} tallasNina={tallasNina} tallasMujer={tallasMujer} tallasHombre={tallasHombre}
                />
            }

        </div>
    )
}