import React, { Fragment } from 'react';
import './style.sass';

function Option({ value, text }) {
    return (
        <option value={String(value)}>{text}</option>)
}

export default function SelectType({ ...params }) {

    const {
        name,
        id,
        placeholder,
        change,
        focus,
        errorMessage,
        refs,
        readonly,
        disabled,
        options,
        optionsState,
        children
    } = params

    return (
        <div className="fileset1">
            <select
                name={name}
                id={id}
                onChange={change}
                onFocus={focus}
                readOnly={readonly}
                disabled={disabled}
                ref={refs}
                value={optionsState}
                defaultValue=""
            >
                <option value="" disabled>Selecciona una {placeholder}</option>
                {options
                    ?
                    options.map((value, ind) =>
                        <Fragment key={ind}>
                            <Option value={value} text={value} />
                        </Fragment>
                    )
                    : null
                }
            </select>
            <label htmlFor={id}>{placeholder}</label>
            {children}

            {errorMessage
                ? <div className="error">{errorMessage}</div>
                : null
            }
        </div>
    )
}


function OptionAD({ value, text }) {
    return (
        <option value={value}>{text}</option>
    )
}

export function SelectTypeAD({ ...params }) {

    const {
        name,
        id,
        placeholder,
        change,
        focus,
        errorMessage,
        refs,
        readonly,
        disabled,
        options,
        optionsState,
        children
    } = params

    return (
        <div className="fileset1">
            <select
                name={name}
                id={id}
                onChange={change}
                onFocus={focus}
                readOnly={readonly}
                disabled={disabled}
                ref={refs}
                value={optionsState}
                defaultValue=""
                className="selectAD"
            >
                <option value="" disabled>{placeholder}</option>

                {options && options.map((value, ind) =>
                    <Fragment key={ind}>
                        <OptionAD value={value.id} text={value.name} />
                    </Fragment>
                )}

            </select>
            {children}

            {errorMessage
                ? <div className="error">{errorMessage}</div>
                : null
            }
        </div>
    )
}

export function SelectTypeADDesktop({ ...params }) {

    const {
        name,
        id,
        placeholder,
        change,
        focus,
        errorMessage,
        refs,
        readonly,
        disabled,
        options,
        optionsState,
        children,
        label
    } = params

    return (
        <div className="fileset1 desktInput">
            <label htmlFor={id}>{label}</label>

            <select
                name={name}
                id={id}
                onChange={change}
                onFocus={focus}
                readOnly={readonly}
                disabled={disabled}
                ref={refs}
                value={optionsState}
                defaultValue=""
                className="selectAD bottomDesktop"
            >
                <option value="" disabled>{placeholder}</option>

                {options && options.map((value, ind) =>
                    <Fragment key={ind}>
                        <OptionAD value={value.id} text={value.name} />
                    </Fragment>
                )}

            </select>
            {children}

            {errorMessage
                ? <div className="error">{errorMessage}</div>
                : null
            }
        </div>
    )
}

export function SelectTypeFormularioContacto({ ...params }) {

    const {
        name,
        id,
        placeholder,
        change,
        focus,
        errorMessage,
        refs,
        readonly,
        disabled,
        options,
        optionsState,
        children,
        label
    } = params

    return (
        <div className="desktInputContact">
            {/* <label htmlFor={id}>{label}</label> */}

            <select
                name={name}
                id={id}
                onChange={change}
                onFocus={focus}
                readOnly={readonly}
                disabled={disabled}
                ref={refs}
                value={optionsState}
                defaultValue=""
                className="selectAD bottomDesktop"
            >
                <option value="" disabled>{placeholder}</option>

                {options && options.map((value, ind) =>
                    <Fragment key={ind}>
                        <OptionAD value={value.name} text={value.name} />
                    </Fragment>
                )}

            </select>
            {children}

            {errorMessage
                ? <div className="error">{errorMessage}</div>
                : null
            }
        </div>
    )
}