import { BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL } from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'

//funcion post login mi telmex
export async function postLoginTelmex({ raw } = {}) {
    const isLoged = decodeStorage('session_token') // trae la variable session token
    const apiURL = `${BASE_PATH_API}/app/v1/telmex/login`
    // console.log("en post telemx login:", raw)
    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const { data } = res
            //console.log(res)
            // console.log(config)
            //console.log(data)
            // console.log(headers)
            // console.log(status)
            return data
        })
        .catch(error => {
            console.log(error.request.status)
            if (error.message === 'Network Error') {
                console.log('Error de conexión')
            }
            else if (error.request) {
                console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                console.log('request 0')
            } else {
                if (error.response.status === 401) {
                    console.log(error.response.data.msg)
                    return { error: error.response.status, message: error.response.data.msg }
                }
                else if (error.response.status === 404) {
                    console.error(error.response);
                    console.log('request 404')
                }
                else if (error.response.status === 440) {
                    console.log('request 440')
                }
                else if (error.response.status === 500) {
                    console.error(error.response);
                    console.log('request 500')
                }
                else {
                    console.error(error.response.status);
                    console.error(error.response);
                }
            }
        })
}

// funcion get verificacion de usuario
export async function getVerificacionUsuarioTelmex({token}) {
    // const isLoged = decodeStorage('session_token') //'session_8253846cddbe695cce3525adbef8d165' // trae la variable session token
    const accessToken = "Bearer " + decodeStorage("access_token");
    //const apiURL = `${BASE_PATH_API}/app/v1/telmex/validation-user?token=${token}`
    const apiURL = `${BASE_PATH_API}/app/v1/telmex/validation-user`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        }
    };

    return await axios(config)
        .then(res => {
            const { headers, status, data } = res
            return {
                'headers': headers,
                'statusCode': status,
                'data': data,
                'error': ''
            }
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.response.data,
                'error': error.response.data.msg
            }
        })
}

//funcion post lista blanca
export async function postListaBlanca({ raw } = {}) {
    //console.log("token", decodeStorage('session_token'))
    const apiURL = BASE_PATH_API + '/app/v1/telmex/white-list'
    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res
            // console.log("en el servicio de lista blanca: ", data)
            return data
        })
        .catch(error => {
            console.log(error.request.status)
            if (error.message === 'Network Error') {
                console.log('Error de conexión')
            }
            else if (error.request) {
                console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                console.log('request 0')
            } else {
                if (error.response.status === 401) {
                    console.log(error.response.data.msg)
                    return { error: error.response.status, message: error.response.data.msg }
                }
                else if (error.response.status === 404) {
                    console.error(error.response);
                    console.log('request 404')
                }
                else if (error.response.status === 440) {
                    console.log('request 440')
                }
                else if (error.response.status === 500) {
                    console.error(error.response);
                    console.log('request 500')
                }
                else {
                    console.error(error.response.status);
                    console.error(error.response);
                }
            }
        })
}

//funcion post lista negra
export async function postListaNegra({ raw } = {}) {
    const apiURL = BASE_PATH_API + '/app/v1/telmex/black-list'
    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res
            return data
        })
        .catch(error => {
            console.log(error.request.status)
            if (error.message === 'Network Error') {
                console.log('Error de conexión')
            }
            else if (error.request) {
                console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                console.log('request 0')
            } else {
                if (error.response.status === 401) {
                    console.log(error.response.data.msg)
                    return { error: error.response.status, message: error.response.data.msg }
                }
                else if (error.response.status === 404) {
                    console.error(error.response);
                    console.log('request 404')
                }
                else if (error.response.status === 440) {
                    console.log('request 440')
                }
                else if (error.response.status === 500) {
                    console.error(error.response);
                    console.log('request 500')
                }
                else {
                    console.error(error.response.status);
                    console.error(error.response);
                }
            }
        })
}

//funcion get obtener informacion usuario /telmex/questions?phone=5553835355
export async function getQuestions() {
    const isPhone = decodeStorage('telefono_usuario') //'session_8253846cddbe695cce3525adbef8d165' // trae la variable session token
    const apiURL = `${BASE_PATH_API}/app/v1/telmex/questions?phone=${isPhone}`
    console.log(" telefono en servicio: ", isPhone)
    console.log("token", decodeStorage('session_token'))
    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    };

    return await axios(config)
        .then(res => {
            const { headers, status, data } = res
            console.log("data en service getQuestions: ", data)
            return {
                'headers': headers,
                'statusCode': status,
                'data': data,
                'error': ''
            }
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.response.data,
                'error': error.response.data.msg
            }
        })
}