import {BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL} from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'


export default async function getInitialPayment (){
    const byCart = decodeStorage('idCarrito')
    // const apiURL = `${BASE_PATH_API}/caja/api/inicio-caja/${byCart}`
    const apiURL = `${BASE_PATH_API}/caja/api/inicio-caja`
    const accessToken = "Bearer " + decodeStorage("access_token");

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        }
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            headers, 
            status, 
            data
        } = res
        // console.log(res)
        // console.log(config)
        // console.log(data)
        // console.log(headers)
        // console.log(status)
        const cscKey = headers['csc-key']
        const {
            error,
            status: newStatus,
            message, 
            action
        } = data
        if(status === 200){
            if(!Array.isArray(data)){
                return {
                    error,
                    newStatus,
                    message,
                    action,
                    cscKey
                }
            }
            else{
                return {'error': '404','response': data}
            }
        }
        if(status === 409){
            if(!Array.isArray(data)){
                return {
                    error,
                    newStatus,
                    message,
                    action
                }
            }
            else{
                console.log(data.message)
                return {'error': '409','response': data}
            }
        }
        return []
    })
    .catch(error =>{
        // console.log(error)
        // console.log(error.request)
        // console.log(error.request.status)
        if(error.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error.request){
            //console.log(error.request.responseText)
        }
        if(error.request.status === 0) {
            console.log('request 0')
            return {'error': 'cors', 'response': error.message}
        }else{
            if(error.response.status === 401) {
                //console.log(error.response.data.msg)
                return {'error': 401, 'response': error.message}
            }
            else if(error.response.status === 404) {
                //console.error(error.response);
                return {'error': 404,'newStatus': 404, 'response': error.message}
            }
            else if(error.response.status === 409) {
                return {'error': 409, 'newStatus': error.response.status, 'response': error.response.data.message}
            }
            else if(error.response.status === 440) {
                return {'error': 440, 'newStatus': error.response.status, 'response': error.response.data.message}
            }
            else if(error.response.status === 500) {
                console.error(error.response);
                return {'error': 500, 'response': error.message}
            }
            else{
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}