import React, { useState, useEffect } from 'react'
import './style.sass'
import { VARIANTS_PER_PORTAL } from 'constants/general'

export default function CintilloCounter(){

    const CintiloGTM = window["CintilloHeaderHomeGTM"]
    
    let now = new Date()
    function getRemainingTime(deadline) {

        // La resta de dos fechas nos devuelve milisegundo por lo tanto
        // para obtener los segundos restantes debemos devidir entre 1000
        let remainTime = (new Date(deadline) - now + 1000) / 1000
        // .slice(-2) obtiene un substring de las ultimas dos posiciones
        let remainSeconds = ("0" + Math.floor(remainTime % 60)).slice(-2)
        let remainMinutes = ("0" + Math.floor((remainTime / 60) % 60)).slice(-2)
        let remainHours = ("0" + Math.floor((remainTime / 3600) % 24)).slice(-2)
        let remainDays = Math.floor(remainTime / (3600 * 24))

        return [
            remainSeconds,
            remainMinutes,
            remainHours,
            remainDays,
            remainTime,
        ];
    };

    const [timeCount, setTimeCount] = useState()
    const [dias, setDias] = useState(0)
    const [hrs, setHrs] = useState('00')
    const [min, setMin] = useState('00')
    const [seg, setSeg] = useState('00')
    const [countSeg, setCountSeg] = useState('00')

    useEffect(()=>{
        let fechaActual = new Date()
        if(fechaActual < new Date(CintiloGTM?.fechaFin)){
            setTimeout(function () {
                setTimeCount( getRemainingTime(CintiloGTM?.fechaFin) )
            }, 1000);
        }
        else{
            setTimeout(function () {
                setDias('0');
                setHrs('00');
                setMin('00');
                setSeg('00');
            }, 1000);
        }
    },[countSeg])

    useEffect(()=>{  
        if(timeCount){
            if(dias < 0){
                setDias('0');
                setHrs('00');
                setMin('00');
                setSeg('00');
            }else{
                setDias(timeCount[3]);
                setHrs(timeCount[2]);
                setMin(timeCount[1]);
                setSeg(timeCount[0]);
                setCountSeg(timeCount[0]);
            }
        }
    },[timeCount])

    return(
        CintiloGTM?.active
            ?
                <section>
                    <div className="contador" id="cintilloHeaderHomeGTM" style={{backgroundColor:CintiloGTM?.background }}>
                        <a href="/c/buen-fin-2022/">
                            <div className="contador-imagen">
                                <img
                                    src={`${VARIANTS_PER_PORTAL?.domain}${CintiloGTM?.url}?scale=100`}
                                    loading="eager"
                                    width="140"
                                    height="140"
                                    decoding="async"
                                    fetchpriority="high"
                                    alt={CintiloGTM?.alt}
                                />
                            </div>
                            <div className="fecha" style={{color:CintiloGTM?.color }}>
                                <p>FALTAN: </p>
                            </div>
                            <div className="contador-tiempo">
                                <div className="contador-digito" style={{color:CintiloGTM?.color }}>
                                    <p id="dias">{dias}</p>
                                    <p>DÍAS</p>
                                </div>
                                <div className="contador-digito" style={{color:CintiloGTM?.color }}>
                                    <p id="hrs">{hrs}</p>
                                    <p>HRS</p>
                                </div>

                                <div className="contador-digito" style={{color:CintiloGTM?.color }}>
                                    <p id="min">{min}</p>
                                    <p>MIN</p>
                                </div>

                                <div className="contador-digito" style={{color:CintiloGTM?.color }}>
                                    <p id="seg">{seg}</p>
                                    <p>SEG</p>
                                </div>
                            </div>
                            <div className="contenedor-boton">
                                <div className="button-ofertas" style={{backgroundColor:CintiloGTM?.backgroundButton, color:CintiloGTM?.color }}>Ver más</div>
                            </div>
                            <div className="contenedor-boton contador-elemento-mobile">
                                <div className="button-ofertas" style={{backgroundColor:CintiloGTM?.backgroundButton, color:CintiloGTM?.color }}>Ver más</div>
                            </div>
                        </a>
                    </div>
                </section>
            : null
    )
}