import React, { useState, useEffect } from 'react';
import './style.sass';
import { useParams } from 'react-router-dom'
import { useImageArray } from 'hooks/useImageArray'
import SliderProductoDesktop from './nuevoSlider/sliderProductoDesktop';
import { WHITE_PIXEL_LOADER } from 'constants/general'

export default function SliderImageProdut({ ...props }) {
    const {
        id,
        dataImage,
        dataVideo,
        title,
        category,
        brand,
        sale_price,
        seller
    } = props;

    const [, setIdWish] = useState(id);
    const [image, setImage] = useState(dataImage);
    const imageSliderProduct = useImageArray(dataImage)
    const params = useParams();

    useEffect(() => {
        if (params.id != id) {
            setImage([{order: 1, thumbnail: WHITE_PIXEL_LOADER, url: WHITE_PIXEL_LOADER}])
        }
        if(dataImage != image){
            if(dataImage){
                setImage(dataImage)
            }
        }
    }, [params.id, dataImage]);

    useEffect(() => {
        if (id) {
            setIdWish(id);
        }
    }, [id]);

    return (
        <SliderProductoDesktop
            dataVideo={dataVideo}
            dataImage={image}
            id={id}
            title={title}
            category={category}
            brand={brand}
            sale_price={sale_price}
            seller={seller}
        />
    )
}