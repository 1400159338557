import React, { useState, useEffect } from 'react'
import { encodeStorage, decodeStorage, encodeStorageLatin } from 'functions/storageBase64'
import { removeStorage } from 'functions/storageBase64'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Modal from 'components/Modal'
import Toast from 'components/Toast'
import ModalAddToCart from 'components/ProductDetail/ShopProduct/ModalAddToCart'
import { ModalAddToCartDesktop } from 'components/ProductDetail/ShopProduct/ModalAddToCart'
import postProductCart from 'services/postProductCart'
import Loading from 'components/Loading'
import { useIsMobile } from 'hooks/useIsMobile'
import './style.sass'

import ModalPre from 'components/Modal'
import { ModalPreLogin } from 'components/LoginRegistro/modals'

import { useCart } from 'hooks/useCart'

export default function ButtonAddToCart({ ...props }) {
    const {
        id,
        cantProduct,
        sizeColorSKU = null,
        size_colorExist,
        title,
        price,
        sale_price,
        dataImage,
        is_digital,
        brand,
        variant,
        category,
        tallaText,
        seller
    } = props

    const history = useHistory()
    const location = useLocation()
    const urlLink = window.location.href
    const hashLink = location.hash

    const hostName = window.location.origin + location.pathname

    const isLoged = decodeStorage('session_token')
    const productAfter = decodeStorage('productAfter')
    const okCartProductAfter = decodeStorage('okCartProductAfter')
    const { isMobile } = useIsMobile()

    const [showModal, SetShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [addCart, SetAddCart] = useState(false)
    const [responseCart, SetResponseCart] = useState({})
    const [toast, setToast] = useState(false)
    const [messageToast, setMessageToast] = useState({ type: '', time: '', textMessage: '' })

    const { 
        cartCount, 
        carrito,
        okAdd,
        Product_Add,
        Product_Update,
        Product_Delete
    } = useCart({firstActive: false, name: "btn-addToCart", timeRef: 1000})

    useEffect(()=>{
        // console.log('btn-addToCart add: ', okAdd)
        // console.log('btn-addToCart: ',carrito)
        if(okAdd){
            SetResponseCart(carrito?.data?.data)
        }
    },[okAdd, carrito])

    const showAddToCart = () => {
        SetShowModal(true)
    }

    useEffect(() => {
        if (showModal) {
            setLoading(false)
        }
    }, [showModal])

    const [modalAddToCart, setModalAddToCart] = useState(false)
    useEffect(() => {
        if (hashLink === "#modalAddToCart") {
            if (modalAddToCart) {
                showAddToCart()
            }
            else {
                setLoading(false)
                SetShowModal(false)
                setModalAddToCart(false)
                setTimeout(function () {
                    history.push(location.pathname)
                }, 500)
            }
        } else {
            setLoading(false)
            SetShowModal(false)
            setModalAddToCart(false)
        }
    }, [hashLink, urlLink, modalAddToCart])


    useEffect(() => {
        if (isLoged) {
            if (addCart) {
                if (size_colorExist) {
                    if (productAfter && okCartProductAfter) {
                        Product_Add([productAfter])
                        // postProductCart(productAfter)
                        //     .then(SetResponseCart)
                        removeStorage('productAfter')
                        removeStorage('okCartProductAfter')
                    } else {
                        Product_Add([{ id_product: id, children_sku: sizeColorSKU?.sku, quantity: cantProduct }])
                        // postProductCart({ id_product: id, children_sku: sizeColorSKU.sku, quantity: cantProduct })
                        //     .then(SetResponseCart)
                    }
                } else {
                    if (productAfter && okCartProductAfter) {
                        Product_Add([productAfter])
                        // postProductCart(productAfter)
                        //     .then(SetResponseCart)
                        removeStorage('productAfter')
                        removeStorage('okCartProductAfter')
                    } else {
                        Product_Add([{ id_product: id, children_sku: sizeColorSKU?.sku, quantity: cantProduct }])
                        // postProductCart({ id_product: id, children_sku: 0, quantity: cantProduct })
                        // .then(SetResponseCart)
                    }
                }
                SetAddCart(false)
            }
        }
    }, [addCart])


    const [totalCart, setTotalCart] = useState()
    useEffect(() => {
        //console.log(responseCart)
        if (responseCart) {
            if (Object.keys(responseCart).length > 0) {
                encodeStorage('total_items', responseCart.total_items)
                //console.log('responseCart -> ',responseCart)
                setTotalCart({ "totalCartPrice": responseCart.total_price, "totalCartItems": responseCart.products.length, "totalArrayProd": responseCart.products })
                try {
                    encodeStorage('cartFormEm', responseCart.products)
                }
                catch {
                    encodeStorageLatin('cartFormEm', responseCart.products)
                }
                setModalAddToCart(true)
                history.push('#modalAddToCart')
                //showAddToCart()
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }, [responseCart])

    //console.log(size_colorExist)
    const addCartProduct = () => {
        //console.log(sizeColorSKU)
        removeStorage('wishlistAfter')
        if (size_colorExist) {
            if ((sizeColorSKU !== null && tallaText !== null) || (sizeColorSKU !== null && tallaText == null)) {
                if (isLoged) {
                    setLoading(true)
                    SetAddCart(true)
                } else {
                    encodeStorage('productAfter', { id_product: id, children_sku: sizeColorSKU.sku, quantity: cantProduct })
                    encodeStorage('typeButton', 'buttonAddToCart')
                    //history.push(`/login?redirect=${urlLink}`)
                    history.push('#modalLoginAddCart')
                    setClickButon(true)
                }
            }else{
                // console.log('agrega talla color---- id:', id ," sizeColorSKU:",  sizeColorSKU?.sku, " cantidad: ", cantProduct)
                setMessageToast({type:'warning', time:'2.5', textMessage:'Falta seleccionar Talla / Color'})
                setToast(true)
            }
        }
        else {
            if (isLoged) {
                setLoading(true)
                SetAddCart(true)
            } else {
                encodeStorage('productAfter', { id_product: id, children_sku: 0, quantity: cantProduct })
                encodeStorage('typeButton', 'buttonAddToCart')
                //history.push(`/login?redirect=${urlLink}`)
                history.push('#modalLoginAddCart')
                setClickButon(true)
            }
        }
    }

    useEffect(() => {
        if (isLoged && productAfter && okCartProductAfter) {
            setLoading(true)
            SetAddCart(true)
        }
    }, [])

    const handleClose = () => {
        history.push('/')
        SetShowModal(false)
        setModalAddToCart(false)
    }

    const handleToeast = () => {
        setToast(false)
        setModalAddToCart(false)
    }

    //-modal
    const [showModalLog, setShowModalLog] = useState(false)
    const [clickButon, setClickButon] = useState(false)

    useEffect(() => {
        if (hashLink === "#modalLoginAddCart") {
            if (clickButon) {
                console.log('modalLoginAddCart')
                setShowModalLog(true)
            }
            else {
                history.replace(location.pathname)
            }
        } else {
            setShowModalLog(false)
        }
    }, [hashLink, urlLink, clickButon])


    const handleCloseLog = () => {
        setShowModalLog(false)
        history.replace(location.pathname)
    }

    // const showModalLogin = () =>{
    //     history.push('#modalLoginAddCart')
    //     setClickButon(true)
    // }


    return (
        <>
            <button
                className="btn gris cart"
                onClick={addCartProduct}>
                Agregar a la bolsa
            </button>

            {loading && <Loading />}

            {isMobile &&
                isMobile === 'desktop'
                ?
                showModal &&
                <Modal onClose={handleClose} type="fullHeader" closeBody="modalcp">
                    <ModalAddToCartDesktop
                        idProduct={id}
                        title={title}
                        price={price}
                        sale_price={sale_price}
                        dataImage={dataImage}
                        cantProduct={cantProduct}
                        totalCart={totalCart}
                        is_digital={is_digital}
                        category={category}
                        brand={brand}
                        variant={variant}
                        onClose={handleClose}
                        seller={seller}
                    />
                </Modal>
                : isMobile === 'movil'
                    ?
                    showModal &&
                    <Modal onClose={handleClose} type="fullSinHeader" closeBody="modalAddCartMobile">
                        <ModalAddToCart
                            idProduct={id}
                            title={title}
                            price={price}
                            sale_price={sale_price}
                            dataImage={dataImage}
                            cantProduct={cantProduct}
                            totalCart={totalCart}
                            is_digital={is_digital}
                            category={category}
                            brand={brand}
                            variant={variant}
                            onClose={handleClose}
                        />
                    </Modal>
                    : null
            }
            {toast &&
                <Toast
                    type={messageToast.type}
                    time={messageToast.time}
                    onClose={handleToeast}
                >
                    {messageToast.textMessage}
                </Toast>
            }

            {showModalLog &&
                <ModalPre onClose={handleCloseLog} type="" title="">
                    <ModalPreLogin
                        icon="img/cart_modal.svg"
                        title="Es necesario iniciar sesión para agregar productos a tu bolsa."
                        linkLogin={`/login?redirect=${hostName}`}
                        linkRegister={`/registro?redirect=${hostName}`}
                    />
                </ModalPre>
            }
        </>
    )
}


export function ButtonAddToBagCircle({ ...props }) {
    const {
        id,
        cantProduct,
        sizeColorSKU = null,
        size_colorExist,
        title,
        price,
        sale_price,
        dataImage,
        is_digital,
        brand,
        variant,
        category
    } = props

    const history = useHistory()
    const location = useLocation()
    const urlLink = window.location.href
    const hashLink = location.hash

    const hostNameFloat = window.location.origin + location.pathname

    const isLoged = decodeStorage('session_token')
    const productAfter = decodeStorage('productAfter')
    const okCartProductAfter = decodeStorage('okCartProductAfter')
    const { isMobile } = useIsMobile()

    const [showModalFloat, SetShowModalFloat] = useState(false)
    const [loading, setLoading] = useState(false)
    const [addCartFloat, SetAddCartFloat] = useState(false)
    const [responseCartFloat, SetResponseCartFloat] = useState({})
    const [toastFloat, setToastFloat] = useState(false)
    const [messageToast, setMessageToast] = useState({ type: '', time: '', textMessage: '' })


    const showAddToCart = () => {
        SetShowModalFloat(true)
    }

    useEffect(() => {
        if (showModalFloat === true) {
            setLoading(false)
        }
    }, [showModalFloat])

    const [modalAddToCartFloat, setModalAddToCartFloat] = useState(false)
    useEffect(() => {
        if (String(hashLink) === "#modalAddToCartMobile") {
            if (modalAddToCartFloat === true) {
                showAddToCart()
            }
            else {
                setLoading(false)
                SetShowModalFloat(false)
                setModalAddToCartFloat(false)
                setTimeout(function () {
                    history.push(location.pathname)
                }, 500)
            }
        } else {
            setLoading(false)
            SetShowModalFloat(false)
            setModalAddToCartFloat(false)
        }
    }, [hashLink, urlLink, modalAddToCartFloat])


    useEffect(() => {
        if (isLoged) {
            if (addCartFloat) {
                if (size_colorExist) {
                    if (productAfter && okCartProductAfter) {
                        postProductCart(productAfter)
                            .then(SetResponseCartFloat)
                        removeStorage('productAfter')
                        removeStorage('okCartProductAfter')
                    } else {
                        postProductCart({ id_product: id, children_sku: sizeColorSKU.sku, quantity: cantProduct })
                            .then(SetResponseCartFloat)
                    }
                } else {
                    if (productAfter && okCartProductAfter) {
                        postProductCart(productAfter)
                            .then(SetResponseCartFloat)
                        removeStorage('productAfter')
                        removeStorage('okCartProductAfter')
                    } else {
                        postProductCart({ id_product: id, children_sku: 0, quantity: cantProduct })
                            .then(SetResponseCartFloat)
                    }
                }
                SetAddCartFloat(false)
            }
        }
    }, [addCartFloat])


    const [totalCart, setTotalCart] = useState()
    useEffect(() => {
        //console.log(responseCart)
        if (responseCartFloat) {
            if (Object.keys(responseCartFloat).length > 0) {
                encodeStorage('total_items', responseCartFloat.total_items)
                //console.log('responseCart -> ',responseCart)
                setTotalCart({ "totalCartPrice": responseCartFloat.total_price, "totalCartItems": responseCartFloat.products.length, "totalArrayProd": responseCartFloat.products })
                try {
                    encodeStorage('cartFormEm', responseCartFloat.products)
                }
                catch {
                    encodeStorageLatin('cartFormEm', responseCartFloat.products)
                }
                setModalAddToCartFloat(true)
                history.push('#modalAddToCartMobile')
                //showAddToCart()
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }, [responseCartFloat])

    //console.log(size_colorExist)
    const addCartProductFloat = () => {
        //console.log(sizeColorSKU)
        removeStorage('wishlistAfter')
        if (size_colorExist) {
            if (sizeColorSKU !== null) {
                if (isLoged) {
                    setLoading(true)
                    SetAddCartFloat(true)
                } else {
                    encodeStorage('productAfter', { id_product: id, children_sku: sizeColorSKU.sku, quantity: cantProduct })
                    encodeStorage('typeButton', 'buttonAddToCart')
                    //history.push(`/login?redirect=${urlLink}`)
                    history.push('#modalLoginAddCartMobile')
                    setClickButonFloat(true)
                }
            } else {
                //console.log('agrega talla color')
                setMessageToast({ type: 'warning', time: '2.5', textMessage: 'Falta seleccionar Talla / Color' })
                setToastFloat(true)
            }
        }
        else {
            if (isLoged) {
                setLoading(true)
                SetAddCartFloat(true)
            } else {
                encodeStorage('productAfter', { id_product: id, children_sku: 0, quantity: cantProduct })
                encodeStorage('typeButton', 'buttonAddToCart')
                //history.push(`/login?redirect=${urlLink}`)
                history.push('#modalLoginAddCartMobile')
                setClickButonFloat(true)
            }
        }
    }

    useEffect(() => {
        if (isLoged && productAfter && okCartProductAfter) {
            setLoading(true)
            SetAddCartFloat(true)
        }
    }, [])

    const handleCloseFloat = () => {
        history.push('/')
        SetShowModalFloat(false)
        setModalAddToCartFloat(false)
    }

    const handleToeastFloat = () => {
        setToastFloat(false)
        setModalAddToCartFloat(false)
    }

    //-modal
    const [showModalLogFloat, setShowModalLogFloat] = useState(false)
    const [clickButonFloat, setClickButonFloat] = useState(false)

    useEffect(() => {
        if (String(hashLink) === "#modalLoginAddCartMobile") {
            if (clickButonFloat) {
                setShowModalLogFloat(true)
            }
            else {
                history.replace(location.pathname)
            }
        } else {
            setShowModalLogFloat(false)
        }
    }, [hashLink, urlLink, clickButonFloat])


    const handleCloseLogFloat = () => {
        setShowModalLogFloat(false)
        history.replace(location.pathname)
    }

    // const showModalLogin = () =>{
    //     history.push('#modalLoginAddCart')
    //     setClickButon(true)
    // }

    const [visible, setVisible] = useState(false);
    const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
            {visible === true && <button
                className="cartCircle"
                onClick={addCartProductFloat}>
            </button>
            }

            {loading && <Loading />}

            {isMobile &&
                isMobile === 'movil'
                ? showModalFloat &&
                <Modal onClose={handleCloseFloat} type="fullSinHeader">
                    <ModalAddToCart
                        idProduct={id}
                        title={title}
                        price={price}
                        sale_price={sale_price}
                        dataImage={dataImage}
                        cantProduct={cantProduct}
                        totalCart={totalCart}
                        is_digital={is_digital}
                        category={category}
                        brand={brand}
                        variant={variant}
                        onClose={handleCloseFloat}
                    />
                </Modal>
                : null
            }

            {toastFloat &&
                <Toast
                    type={messageToast.type}
                    time={messageToast.time}
                    onClose={handleToeastFloat}
                >
                    {messageToast.textMessage}
                </Toast>
            }

            {showModalLogFloat &&
                <ModalPre onClose={handleCloseLogFloat} type="" title="">
                    <ModalPreLogin
                        icon="img/cart_modal.svg"
                        title="Es necesario iniciar sesión para agregar productos a tu bolsa."
                        linkLogin={`/login?redirect=${hostNameFloat}`}
                        linkRegister={`/registro?redirect=${hostNameFloat}`}
                    />
                </ModalPre>
            }
        </>
    )
}