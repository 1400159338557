import React from 'react';
import { Link } from 'react-router-dom';
import { CardAddressMobile } from 'components/Address/CardAddress';
import ErrorType from 'pages/Error/ErrorType';

export function SelecDireccionTemplateMobile(props) {
    const {
        rawAddress,
        address,
        errorText,
        changeDefaultA,
        removeValueA
    } = props;

    return (
        <>
            <main>
                <div className="content">
                    {rawAddress.statusCode === 200
                        ? <div className="container contentAllDirection">

                            <div className="contenDireccionMobile">
                                {address.address
                                    ? address.address.map(({ id, accesibilidad, nombre, direccion, numeroExterior, colonia, codigoPostal, municipio, ciudad, telefono }) =>
                                        <CardAddressMobile
                                            key={id}
                                            id={id}
                                            accesibilidad={accesibilidad}
                                            nombre={nombre}
                                            direccion={direccion}
                                            numeroExterior={numeroExterior}
                                            colonia={colonia}
                                            codigoPostal={codigoPostal}
                                            municipio={municipio}
                                            ciudad={ciudad}
                                            telefono={telefono}
                                            selectDefaultA={changeDefaultA}
                                            removeButton={removeValueA}
                                            editRoute="/mi-cuenta/direcciones-envio/editar"
                                        />
                                    )
                                    : null
                                }
                            </div>

                            <Link to="/mi-cuenta/direcciones-envio/agregar" className="btn blanco addDirMobile">
                                <p className="blanco1">Agregar dirección</p>
                                <p className="blanco2"></p>
                            </Link>
                        </div>
                        : null
                    }
                    <ErrorType
                        section=""
                        codeStatus={rawAddress.statusCode}
                        errorText={errorText}
                    />
                </div>
            </main>
        </>
    )
}