import React, { useState, useEffect } from 'react';
import FiltrosDesktop from './paginaFiltrosDesktop';

export default function FiltrosDeskLogica(props) {
    const { searchResults, searchParams, params, setSearchParams, history, primeraParte, marcaP, tieneEnvioGratis, tieneFulfillment, tieneReview, ordenS, setOrdenS,
        valorDescuento, minimo, maximo } = props;

    // bandera activar filtrado
    const [flagFiltar, setFlagFiltrar] = useState(false); // activar filtros seleccionados

    // para el numero de pagina
    const [pagina, setPagina] = useState(1);

    // para filtro ordenar
    const [parametroOrdenar, setParametroOrdenar] = useState(''); // filtro ordenar asc / desc
    const [, setId] = useState(0); // id seleccionado al presionar asc / desc
    const [valor, setValor] = useState(0);

    // para filtro fulfillment
    const [activarFullfilment, setActivarFullfilment] = useState(false); // filtro por fullfilment
    const [binarioFulfillment, setBinarioFulfillment] = useState(0); // convierte a binario
    // para filtro envio gratis
    const [activarEnvioGratis, setActivarEnvioGratis] = useState(false); // filtro envio gratis
    const [binarioEnvioGratis, setBinarioEnvioGratis] = useState(0); // convierte a binario
    // para filtro estrellas
    const [activarEstrellas, setActivarEstrellas] = useState(false); // filtro por estrellas
    const [binarioEstrellas, setBinarioEstrellas] = useState(0); // convierte a binario

    // para filtro categoria
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(0); // filtro por categoria
    const [categoria, setCategoria] = useState(''); // nombre de la categoria seleccionada
    const [idCategoria, setIdCategoria] = useState(0); // id de la categoria seleccionada
    const [verMasCategorias, setVerMasCategoria] = useState(false);

    // para filtro marca
    const [, setMarcaSeleccionada] = useState(''); // filtro por marca
    const [checkedItem, setCheckedItem] = useState(null); // establecer marca 
    const [verMasMarcas, setVerMasMarcas] = useState(false);

    // para filtro descuento
    const [checkedDiscount, setCheckedDiscount] = useState(0); // establecer descuento
    const [discountSeleccionado, setDiscountSeleccionado] = useState(0); // filtro descuento seleccionado 

    // para filtro precios
    const [preciomenor, setPrecioMenor] = useState(0); //filtro precio menor
    const [PrecioMax, setPrecioMax] = useState(0); // filtro precio mayor

    // actualizar seleccion de ordenamiento
    useEffect(() => {
        var valor1 = 1;
        var valor2 = 2;
        var valor3 = 3;

        // RELEVANCIA
        if (Number(ordenS) === Number(valor1)) {
            setValor(valor1);
            setFlagFiltrar(false);
            setParametroOrdenar('');
            filtrarAhora(1, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        }
        // MENOR A MAYOR
        if (Number(ordenS) === Number(valor2)) {
            setValor(valor2);
            setFlagFiltrar(false);
            setParametroOrdenar('A:Y');
            filtrarAhora(ordenS, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        }
        // MAYOR A MENOR
        if (Number(ordenS) === Number(valor3)) {
            setValor(valor3);
            setFlagFiltrar(false);
            setParametroOrdenar('D:Y');
            filtrarAhora(ordenS, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        }

    }, [ordenS])

    // activar / desactivar filtro fulfillment 
    const handleActivarFullfilment = () => {
        setActivarFullfilment(!activarFullfilment);
    }
    useEffect(() => {

        if (activarFullfilment === true) {
            //fullfilment, envio gratis, estrellas, categorias, marcas, descuento
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        }
        else {
            filtrarAhora(valor, false, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        }
    }, [activarFullfilment])

    // activar / desactivar filtro envio gratis
    const handleEnvioGratis = () => {
        setActivarEnvioGratis(!activarEnvioGratis);
    }
    useEffect(() => {
        if (activarEnvioGratis === true) {
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        }
        else {
            filtrarAhora(valor, activarFullfilment, false, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        }
    }, [activarEnvioGratis])

    // activar/ desactivar filtro estrellas
    const handleActivarEstrellas = () => {
        setActivarEstrellas(!activarEstrellas);
    }
    useEffect(() => {
        if (activarEstrellas === true) {
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        }
        else {
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, false, idCategoria, checkedItem, discountSeleccionado);
        }
    }, [activarEstrellas])

    // seleccionar categoria
    const seleccionarCategoria = (event) => {
        const category = event.target.value; //name
        const categoryId = event.target.id; // id cateogria

        if (Number(categoryId) === Number(idCategoria)) {
            setCategoria("");
            setIdCategoria(0);
        }
        else {
            setCategoria(category);
            setIdCategoria(categoryId);
        }
    }
    const VerMasCate = () => {
        setVerMasCategoria(!verMasCategorias);
    }
    useEffect(() => {
        if (Number(idCategoria) !== 0)
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        else {
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, activarEstrellas, 0, checkedItem, discountSeleccionado);
        }
    }, [idCategoria])

    // seleccionar marca 
    const seleccionarMarca = (e) => {
        var marca = String(e.target.value);

        if (marca === String(checkedItem)) {
            setCheckedItem(null);
            setMarcaSeleccionada('');
        }
        else {
            setCheckedItem(marca);
            setMarcaSeleccionada(marca);
        }
    }
    const VerMasMar = () => {
        setVerMasMarcas(!verMasMarcas);
    }
    useEffect(() => {
        if (checkedItem != null)
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, discountSeleccionado);
        else {
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, null, discountSeleccionado);
        }
    }, [checkedItem])

    // seleccionar descuento 
    const seleccionarDiscount = (e) => {
        var descuento = Number(e.target.value);

        if (Number(descuento) === Number(checkedDiscount)) {
            setCheckedDiscount(0);
        }
        else {
            setCheckedDiscount(descuento);
        }

    }
    useEffect(() => {
        if (Number(checkedDiscount) !== 0) {
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, checkedDiscount);
        }
        if (Number(checkedDiscount) === 0) {
            filtrarAhora(valor, activarFullfilment, activarEnvioGratis, activarEstrellas, idCategoria, checkedItem, 0);
        }
    }, [checkedDiscount])

    // filtro menor y mayor precio
    const precioMin = (e) => {
        setPrecioMenor(Number(e.target.value));
    }
    const precioMax = (e) => {
        setPrecioMax(Number(e.target.value));
    }
    const filtrarPrecio = (precioMininmo, precioMaximo) => {
        setFlagFiltrar(true);
    }

    const actualizarMaxDesdeBarra = (e) => {
        setPrecioMax(Number(e));
    }
    const actualizarMinDesdeBarra = (e) => {
        setPrecioMenor(Number(e));
    }

    // FIJA EL valor de precio minimo y maximo del input cuando cambia el minimo /max que retorna el servicio
    useEffect(() => {
        setPrecioMenor(Number(minimo));
    }, [minimo])
    useEffect(() => {
        setPrecioMax(maximo);
    }, [maximo])

    // funcion para activar el filtrado //ordenFiltrar,
    function filtrarAhora(ordenFiltrar, fulfillmentFiltrar, envioGratisFiltrar, estrellasFiltrar, categoriaFiltrar, marcaFiltrar, descuentoFiltrar) { //marcaFiltrar,
        if (Number(ordenFiltrar) !== 1) {
            setId(ordenFiltrar);
        }else if (Number(ordenFiltrar) === 1) {
            setId(1);
            setParametroOrdenar("");
        }

        if (fulfillmentFiltrar === true) {
            setActivarFullfilment(true);
            setBinarioFulfillment(1);
            setPagina(1);
        } else {
            setActivarFullfilment(false);
            setBinarioFulfillment(0);
        }

        if (envioGratisFiltrar === true) {
            setActivarEnvioGratis(true);
            setBinarioEnvioGratis(1);
            setPagina(1);
        } else {
            setActivarEnvioGratis(false);
            setBinarioEnvioGratis(0);
        }

        if (estrellasFiltrar === true) {
            setActivarEstrellas(true);
            setBinarioEstrellas(1);
            setPagina(1);
        } else {
            setActivarEstrellas(false);
            setBinarioEstrellas(0);
        }

        if (Number(categoriaFiltrar) !== 0) {
            setCategoriaSeleccionada(categoriaFiltrar);
            setIdCategoria(categoriaFiltrar);
            setPagina(1);
        } else {
            setCategoriaSeleccionada(0);
            setIdCategoria(0);
        }

        if (marcaFiltrar != null) {
            setMarcaSeleccionada(marcaFiltrar);
            setCheckedItem(marcaFiltrar);
            setPagina(1);
        } else {
            setMarcaSeleccionada('');
            setCheckedItem(null);
        }

        if (Number(descuentoFiltrar) !== 0) {
            setDiscountSeleccionado(descuentoFiltrar);
            setCheckedDiscount(descuentoFiltrar);
            setPagina(1);
        } else {
            setDiscountSeleccionado(0);
            setCheckedDiscount(0);
        }

        if (Number(ordenFiltrar) === 1 && fulfillmentFiltrar === false && envioGratisFiltrar === false && estrellasFiltrar === false &&
            Number(categoriaFiltrar) === 0 && marcaFiltrar === null && Number(descuentoFiltrar) === 0) {
            setPagina(1);
        }
        setFlagFiltrar(true);
    }

    const [bandera, setBandera] = useState(false);

    useEffect(() => {
        setBandera(1);
    }, [])
    // aplica los filtros seleccionados
    // console.log("fuera efecto, valor de minimo--- ", minimo, " valor de maximo--- ", maximo)
    useEffect(() => {
        if (flagFiltar === true) {

            if (checkedItem != null || PrecioMax != 0 || preciomenor != 0 || idCategoria != 0 || parametroOrdenar != "" || binarioEstrellas != 0 || binarioEnvioGratis != 0 ||
                binarioFulfillment != 0 || checkedDiscount != 0) {
                if (bandera === 1) {
                    setSearchParams({
                        ...searchParams,
                        precioMenor: preciomenor,
                        precioMayor: PrecioMax,
                        categoria: idCategoria,
                        marca: checkedItem,
                        order: parametroOrdenar,
                        start: binarioEstrellas, //activarEstrellas,
                        shipping: binarioEnvioGratis, //activarEnvioGratis,
                        fulfillment: binarioFulfillment, //activarFullfilment,
                        discount: checkedDiscount,
                        page: pagina
                    });
                    setBandera(bandera + 1);
                    // console.log("entra a if... ", preciomenor, " ", PrecioMax)
                }
                else {
                    setSearchParams({
                        ...searchParams,
                        precioMenor: preciomenor,
                        precioMayor: PrecioMax,
                        categoria: idCategoria,
                        marca: checkedItem,
                        order: parametroOrdenar,
                        start: binarioEstrellas, //activarEstrellas,
                        shipping: binarioEnvioGratis, //activarEnvioGratis,
                        fulfillment: binarioFulfillment, //activarFullfilment,
                        discount: checkedDiscount,
                        page: 1
                    });
                    // console.log("entra a else ... ", preciomenor, " ", PrecioMax)
                }
            }
            else {
                setSearchParams({
                    ...searchParams,
                    precioMenor: preciomenor,
                    precioMayor: PrecioMax,
                    categoria: idCategoria,
                    marca: null,
                    order: parametroOrdenar,
                    start: binarioEstrellas, //activarEstrellas,
                    shipping: binarioEnvioGratis, //activarEnvioGratis,
                    fulfillment: binarioFulfillment, //activarFullfilment,
                    discount: checkedDiscount,
                    page: pagina
                });
                // console.log("entra a else EXTERNO... ", preciomenor, " ", PrecioMax)
            }
            setFlagFiltrar(false);
        }
        // __________________________________________________
        let param_descuento = '';
        let param_fulfillment = '';
        let param_enviogratis = '';
        let param_estrellas = '';
        let param_categoria = '';
        let param_marca = '';
        let param_precios = '';
        let param_orden = null;

        // se aplica cualquier filtro
        if (((String(searchParams.order) != '') ||
            Number(searchParams.discount) !== 0 ||
            Number(searchParams.fulfillment) === 1 ||
            Number(searchParams.shipping) === 1 ||
            Number(searchParams.start) === 1 ||
            Number(searchParams.categoria) !== 0 ||
            searchParams.marca != null ||
            (Number(searchParams.precioMenor) !== 0 && 
            Number(searchParams.precioMayor) !== 0
            && searchParams.precioMenor != null 
            && searchParams.precioMayor != null))) {

            if (Number(searchParams.discount) !== 0) {
                param_descuento = (`/descuento=${Number(searchParams.discount)}`);
            }
            if (Number(searchParams.fulfillment) === 1) {
                param_fulfillment = (`/fulfillment=${searchParams.fulfillment}`);
            }

            if (Number(searchParams.shipping) === 1) {
                param_enviogratis = (`/enviogratis=${searchParams.shipping}`);
            }
            if (Number(searchParams.start) === 1) {
                param_estrellas = (`/estrellas=${searchParams.start}`);
            }
            if (Number(searchParams.categoria) !== 0) {
                param_categoria = (`/categoria=${Number(searchParams.categoria)}`);
            }
            if (searchParams.marca != null) {
                param_marca = (`/marca=${searchParams.marca}`);
            }
            if (Number(searchParams.precioMenor) !== 0 && Number(searchParams.precioMayor) !== 0
                // && (Number(searchParams.precioMayor) !== maximo || Number(searchParams.precioMenor) !== minimo)
            ) {
                param_precios = (`/preciomenor=${Number(searchParams.precioMenor)}/preciomayor=${Number(searchParams.precioMayor)}`);
            }
            if (searchParams.order != null) {
                if (String(searchParams.order) == '')
                    param_orden = '';
                else {
                    if (searchParams.order === 'A:Y') //menor
                        param_orden = (`/orden=A`);
                    if (searchParams.order === 'D:Y') //mayor
                        param_orden = (`/orden=D`);
                }

            }
            history.replace(primeraParte + param_marca + param_categoria + param_precios + param_descuento + param_estrellas + param_enviogratis + param_fulfillment + param_orden + '/pagina=' + searchParams.page);
        }

        // no se aplican filtros
        else if ((String(searchParams.order) === "") 
        && Number(searchParams.discount) === 0
            && Number(searchParams.fulfillment) === 0 
            && Number(searchParams.shipping) === 0 
            && Number(searchParams.start) === 0
            && Number(searchParams.categoria) === 0 
            && searchParams.marca == null &&
            (Number(searchParams.precioMenor) === 0) &&
             (Number(searchParams.precioMayor) === 0 
            // && Number(searchParams.precioMayor) === maximo && Number(searchParams.precioMenor) === minimo
            )) {
            history.replace(params, null);
            history.replace(primeraParte + '/pagina=' + searchParams.page);
        }

    }, [flagFiltar])

    // limpiar todos los filtros seleccionados en el modal filtros
    function limpiarFiltros() {
        setFlagFiltrar(false);
        setValor(1);
        setId(1);
        setCategoria('');
        setIdCategoria(0);
        setCheckedItem(null);
        setPrecioMax(0); // establece valor maximo inicial
        setPrecioMenor(0); // establece valor minimo inicial
        setActivarEstrellas(false);
        setActivarEnvioGratis(false);
        setActivarFullfilment(false);
        setCheckedDiscount(0);
        setDiscountSeleccionado("");

        setBinarioFulfillment(0);
        setBinarioEstrellas(0);
        setBinarioEnvioGratis(0);

        history.replace(params, null);
        history.replace(primeraParte + "/pagina=1");
    }

    // actualizar parametros de la url y recordar campos al actualizar la pagina
    const updateParamsFilters = ({ params }) => {
        const urlParams = params.split('/');
        urlParams.shift();

        var marca = null;
        var precioMenor = null;
        var precioMayor = null;
        var category = null;
        var page = null;
        var starts = null;
        var shipping = null;
        var fulfillment = null;
        var discount = null;
        var orden = null;

        for (let i = 0; i <= (urlParams.length - 1); i++) {

            if (urlParams[i].indexOf('preciomenor=') >= 0) {
                precioMenor = urlParams[i].replace('preciomenor=', '');
            }
            if (urlParams[i].indexOf('preciomayor=') >= 0) {
                precioMayor = urlParams[i].replace('preciomayor=', '');
            }
            if (urlParams[i].indexOf('marca=') >= 0) {
                marca = urlParams[i].replace('marca=', '');
            }
            if (urlParams[i].indexOf('categoria=') >= 0) {
                category = urlParams[i].replace('categoria=', '');
            }
            if (urlParams[i].indexOf('estrellas=') >= 0) {
                starts = urlParams[i].replace('estrellas=', '');
            }
            if (urlParams[i].indexOf('enviogratis=') >= 0) {
                shipping = urlParams[i].replace('enviogratis=', '');
            }
            if (urlParams[i].indexOf('fulfillment=') >= 0) {
                fulfillment = urlParams[i].replace('fulfillment=', '');
            }
            if (urlParams[i].indexOf('descuento=') >= 0) {
                discount = urlParams[i].replace('descuento=', '');
            }
            if (urlParams[i].indexOf('orden=') >= 0) {
                orden = urlParams[i].replace('orden=', '');
            }
            if (urlParams[i].indexOf('pagina=') >= 0) {
                page = urlParams[i].replace('pagina=', '');
            }
        }

        if (precioMenor != null) {
            setPrecioMenor(precioMenor);
        }
        else {
            setPrecioMenor(0);
        }

        if (precioMayor != null) {
            setPrecioMax(precioMayor);
        }
        else {
            setPrecioMax(0);
        }

        if (marca != null) {
            setCheckedItem(marcaP);
        } else {
            setCheckedItem(null);
        }

        if (category != null) {
            setIdCategoria(category);
        } else {
            setIdCategoria(0);
        }

        if (starts != null) {
            if (Number(starts) === 1) {
                setBinarioEstrellas(1);
                setActivarEstrellas(true);
            } else {
                setActivarEstrellas(false);
                setBinarioEstrellas(0);
            }
        } else {
            setActivarEstrellas(false);
            setBinarioEstrellas(0);
        }

        if (shipping != null) {
            if (Number(shipping) === 1) {
                setBinarioEnvioGratis(1);
                setActivarEnvioGratis(true);
            } else {
                setActivarEnvioGratis(false);
                setBinarioEnvioGratis(0);
            }
        } else {
            setActivarEnvioGratis(false);
            setBinarioEnvioGratis(0);
        }

        if (fulfillment != null) {
            if (Number(fulfillment) === 1) {
                setActivarFullfilment(true);
                setBinarioFulfillment(1);
            } else {
                setActivarFullfilment(false);
                setBinarioFulfillment(0);
            }
        } else {
            setActivarFullfilment(false);
            setBinarioFulfillment(0);
        }

        if (discount != null) {
            setCheckedDiscount(discount);
        } else {
            setCheckedDiscount(0);
        }

        if (orden != null) {
            setValor(orden);
            if (orden === 'A')//menor
            {
                setId(2);
                setParametroOrdenar("A:Y");
                setOrdenS(2);
            }
            if (orden === 'D')//mayor
            {
                setId(3);
                setParametroOrdenar("D:Y");
                setOrdenS(3);
            }
        }
        if (orden == null) {
            setId(1);
            setParametroOrdenar("");
            setOrdenS(1);
        }

        if (page != null) {
            setPagina(page);
        }
    }

    // ejecutar updateParamsFilters cuando pararms cambie
    useEffect(() => {
        updateParamsFilters({ params });

    }, [params])

    const listaCategorias = [];
    const listaMarcas = [];

    if (searchResults.searchResult) {
        if (Number(searchResults.searchResult.GSP.RES.M) !== 0) {

            searchResults.searchResult.GSP.RES.PARM.PMT.map((item) => {
                return item?.DN === 'breadcrumbs'
                    ? (item?.PV && item.PV != "undefined" && item.PV != undefined)
                        ? ((item.PV).length > 1
                            ? item.PV.map((ca) =>
                                listaCategorias.push({ id: ca.id, name: ca.name })
                            )
                            : listaCategorias.push({ id: item.PV.id, name: item.PV.name }))
                        : null
                    : null
            })

            searchResults.searchResult.GSP.RES.PARM.PMT.map((item2) => {
                return item2.DN === 'marcas'
                    ? (item2.PV && item2.PV != "undefined" && item2.PV != undefined)
                        ? ((item2.PV).length > 1
                            ? item2.PV.map((mar) =>
                                listaMarcas.push({ marca: String(mar.V).toLowerCase() })
                            )
                            : listaMarcas.push({ marca: String(item2.PV.V).toLowerCase() }))
                        : null
                    : null
            })


        }
    }

    function removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};

        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    if (listaMarcas.length > 0) {
        var uniqueArray = removeDuplicates(listaMarcas, "marca");
    } else {
        var uniqueArray = false;
    }

    if (listaCategorias && listaCategorias.length > 0) {
        var categoriasUnicas = removeDuplicates(listaCategorias, "name")
    } else {
        var categoriasUnicas = false;
    }

    return (

        <FiltrosDesktop
            tieneFulfillment={tieneFulfillment} tieneEnvioGratis={tieneEnvioGratis} tieneReview={tieneReview}
            filtrarAhora={filtrarAhora} limpiarFiltros={limpiarFiltros}
            verMasCategorias={verMasCategorias} VerMasCate={VerMasCate} verMasMarcas={verMasMarcas} VerMasMar={VerMasMar}
            // valor={valor} activarOrdenar={activarOrdenar}
            activarFullfilment={activarFullfilment} handleActivarFullfilment={handleActivarFullfilment}
            activarEnvioGratis={activarEnvioGratis} handleEnvioGratis={handleEnvioGratis}
            activarEstrellas={activarEstrellas} handleActivarEstrellas={handleActivarEstrellas}
            categoria={categoria} categoriaSeleccionada={categoriaSeleccionada} seleccionarCategoria={seleccionarCategoria} setCategoria={setCategoria}
            idCategoria={idCategoria}

            listaCategorias={categoriasUnicas}
            listaMarcas={uniqueArray}

            seleccionarMarca={seleccionarMarca} checkedItem={checkedItem}
            checkedDiscount={checkedDiscount} seleccionarDiscount={seleccionarDiscount}
            precioMenor={preciomenor} PrecioMax={PrecioMax} precioMin={precioMin} precioMax={precioMax} filtrarPrecio={filtrarPrecio}
            history={history}

            minimo={minimo} maximo={maximo}
            valorDescuento={valorDescuento}

            actualizarMaxDesdeBarra={actualizarMaxDesdeBarra}
            actualizarMinDesdeBarra={actualizarMinDesdeBarra}
        />
    )

}