import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { HIDDEN_DISCOUNT } from 'constants/general';
import CurrencyFormat from 'react-currency-format';
import ModalImageFulFilment from 'components/Modal';
import StarsPrincipal from 'components/StarsFive/starsPrincipal';
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import { ButtonAddToBagCircle } from 'components/ProductDetail/ShopProduct/ButtonsPayment/ButtonAddToCart';
import { repareTitleSEO, repareTextHtml } from 'functions/repareDataService';
import { useIsMobile } from 'hooks/useIsMobile';
import { dataLayerOutProduct } from 'functions/dataLayerFunction';
import { desindexProduct } from 'services/getSearch'


export default function ModuleShopData({ ...props }) {
    const {
        id,
        title,
        store,
        brand,
        discount,
        price,
        sale_price,
        is_digital,
        reviews,
        // isfree,
        stock,
        updateStockTC,
        updateFF,
        status,
        fullfilment,
        // dataImage,
        // category,
        skuChildren,
        colorText,
        superExpres
    } = props

    //console.log('ModuleShopData: ',props)
    const { isMobile } = useIsMobile();
    const history = useHistory();
    const location = useLocation();
    const urlLink = location.href;
    const hashLink = location.hash;
    // const firstVisit = decodeStorage('firstVisit');

    const [showModal, SetShowModal] = useState(false);
    const [stockUD, setStockUD] = useState(stock);
    
    
    const [stockUD2, setStockUD2] = useState(false);
    useEffect(() => {
        //console.log("module shop data ---- ", updateFF)
        if (updateStockTC) {
            setStockUD(updateStockTC);
            setStockUD2(false)
        }
        else{
            if( document.querySelector('.modulePaymentButtons .selectColor') === null ){
                setStockUD2(true)
            }
            setStockUD(stock)
        }
    }, [stock, updateStockTC, updateFF])

    useEffect(() => {
        if (id) {
            setStockUD(stock);
        }
    }, [stock, id])

    const handleClose = () => {
        history.push(location.pathname);
    }

    const showExpress = () => {
        SetShowModal(true);
    }

    useEffect(() => {
        if (hashLink === "#modalExpress") {
            showExpress();
        } else {
            SetShowModal(false);
        }
    }, [hashLink, urlLink])


    const [superE, setSuperE] = useState(false)
    useEffect(() => {
        if (fullfilment) {
            if (fullfilment.fulfillment) {
                if (!decodeStorage('firstVisit')) {
                    if (document.querySelector('.moduleShopCardProduct .fullFilment')) {
                        history.push(`#modalExpress`, null);
                        encodeStorage('firstVisit', true);
                    }
                }
            }
        }
        if(superExpres === "super_express" || superExpres === true){
            //console.log('superExpres: ',superExpres)
            setSuperE("true")
        }
        else{
            //console.log('superExpres!: ',superExpres)
            setSuperE(false)
        }
    }, [fullfilment, superExpres])


    const [messageStock, setMessageStock] = useState("¡Producto Agotado!")
    const vieneBuscador = decodeStorage('vieneBuscador')
    useEffect(() => {
        if (!stock || stock === 0 || status === false) {
            //console.log('agotadoooooo')
            if(!stock || stock === 0){
                setMessageStock("¡Producto Agotado!")
                dataLayerOutProduct({ id: id, stock: stock, message: 'producto_agotado' })
            }else if(stock >= 1 || status === false){
                setMessageStock("¡Producto no disponible!")
                dataLayerOutProduct({ id: id, stock: stock, message: 'no_disponible'  })
            }

            // desindexa productos
            // let arrayOut =  window.sessionStorage.getItem('arrayOut')
            // if(arrayOut === null || arrayOut === "null"){
            //     arrayOut = []
            // }else{
            //     arrayOut = JSON.parse(arrayOut)
            // }

            // if(vieneBuscador === "true"){
            //     if(arrayOut.find(element => element === id)){
            //     }else{
            //         arrayOut.push(id)
            //         desindexProduct(id)
            //         .then()
            //         window.sessionStorage.setItem('arrayOut', JSON.stringify(arrayOut) )
            //     }
            //     removeStorage('vieneBuscador')
            // }
        }
    }, [stock, status])



    // console.log('-------------')
    // console.log(fullfilment.cyr)
    // console.log(updateFF)
    // console.log(fullfilment.is_digital)
    // console.log(fullfilment.fulfillment)
    // console.log(fullfilment.cex)
    // console.log('reviews -------------', reviews)
    const tiendaSeo = repareTitleSEO(store?.name);

    // console.log('MS fullfilment: ',fullfilment)
    // console.log('MS superExpres: ',superExpres)

    return (
        <>
            <div className="moduleShopTitle">
                <h1 className="h2">{repareTextHtml(title)}</h1>
                <div className="contenedorVendidoPor">
                    {store?.id !== 2430
                        ? <p className="vendidoPor">Vendido por <Link to={`/tienda/${store?.id}/${tiendaSeo}/pagina=1`}>{store?.name}</Link></p>
                        : <p className="vendidoPor">Vendido por {store?.name} </p>
                    }
                        <p className="vendidoPor1">SKU {skuChildren}</p>
                    
                </div>
                <div className='contenedorVendidoPor'>
                    <div className="contenedorMarca">
                        <p className="vendidoPor">Marca {brand}</p>
                    </div>
                    <p className='vendidoPor2'>ID {id}</p>
                </div>
                {isMobile === "desktop"
                    ? <>
                        <div className="startsMarca">
                            {/*<div className="contenedorMarca">
                                <p className="vendidoPor">Marca {brand}</p>
                            </div>*/}

                            <div className="startBag">
                                {reviews && reviews?.bottomline?.total_review >= 1
                                    ? <Link to='#modalStarsProduct' className="starsAndReview">
                                        <StarsPrincipal score={reviews?.bottomline.average_score} />
                                        <p className="link">{reviews?.bottomline?.total_review > 1 ? '' + reviews?.bottomline?.total_review + ' opiniones' : '' + reviews?.bottomline?.total_review + ' opinión'}</p>
                                    </Link>
                                    : null
                                }

                                {/* <ButtonAddToBagCircle
                                    id={id}
                                    cantProduct=""//{cantProduct}
                                    sizeColorSKU="" //{sizeColorSKU}
                                    size_colorExist= {3} //={size_color.length}

                                    title={title}
                                    price={price}
                                    sale_price={sale_price}
                                    dataImage={dataImage}
                                    is_digital={is_digital}
                                    category={category}
                                    brand={brand}
                                    variant="" //{colorSel}
                                /> */}
                            </div>
                        </div>
                    </>
                    : <>
                        <div className="contenedorMarca">
                            <p className="vendidoPor">Marca {brand}</p>
                        </div>
                        <div className="startBag">
                            {reviews && reviews?.reviews?.length >= 1
                                ? <Link to='#modalStarsProduct' className="starsAndReview">
                                    <StarsPrincipal score={reviews?.bottomline?.average_score} />
                                    <p className="link">{reviews?.bottomline?.total_review > 1 ? '' + reviews?.bottomline?.total_review + ' opiniones' : '' + reviews?.bottomline?.total_review + ' opinión'}</p>
                                </Link>
                                : null
                            }
                        </div>
                    </>
                }

            </div>

            {stock && stock > 0 && status !== false
                ? <div className="moduleShopPrice">
                    <div className="dataPrice">
                        <CurrencyFormat
                            value={sale_price}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            renderText={value =>
                                <div className="priceSale">
                                    <div className="pPrice">{value} MXN
                                        {price !== sale_price
                                            ? <div className="contDiscount">
                                                <CurrencyFormat
                                                    value={price}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    renderText={value => <p className="textUnderline">{value}MXN</p>}
                                                />
                                                {discount <= HIDDEN_DISCOUNT
                                                    ? null
                                                    : <span className="discount">-{discount}%</span>
                                                }
                                            </div>
                                            : null
                                        }
                                    </div>

                                </div>
                            }
                        />

                        {stockUD2 
                                ?
                                    stockUD && status
                                        ? <>
                                            {stockUD === 1
                                                ? <div className="lastProducts">Queda 1 pieza</div>
                                                : null
                                            }
                                            {stockUD >= 2 && stockUD <= 14
                                                ? <div className="lastProducts">Últimas {stockUD} piezas</div>
                                                : null
                                            }
                                            {stockUD >= 15 && stockUD <= 30
                                                ? <div className="lastProducts">Últimas piezas</div>
                                                : null
                                            }
                                        </>
                                        : null
                                : null
                        }
                        {is_digital && <div className="stickerDigital">Producto Digital</div>}

                        <div className="isFreeRecogeHoy">
                            <div className="isExt">
                                {/* <div className="isFree">
                                <img src="/img/envio.svg" alt="envio"></img>
                                <p>
                                    {isfree === false || sale_price <= ENVIO_GRATIS
                                        ? <span className="noIsFree">Envío a domicilio</span>
                                        : <span className="noIsFree">Envío gratis</span>
                                    }
                                </p>
                            </div> */}

                            </div>
                        </div>

                        {fullfilment?.fulfillment || updateFF === "true" || superE === "true" ? <Link to="#modalExpress" className="fullFilment" onClick={showExpress}>Express</Link> : null}

                    </div>
                    {showModal &&
                        <ModalImageFulFilment onClose={handleClose} closeBody="closeBody">
                            <div className="contentImageFF">
                                {/* <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/app/fullFilment/info_express_desktop.png`} loading="lazy" alt="express image" width="390" height="516" /> */}

                                <img src={`/img/cartSears.png`} loading="lazy" alt="express image" width="255" height="100" />
                                <img src={`/img/titulo_express.png`} loading="lazy" alt="express image" width="255" height="28" />
                                <ul>
                                    <li className="icoFF1">Disponibilidad inmediata y entrega más rápida.</li>
                                    <li className="icoFF2">Rastrea en cualquier momento.</li>
                                    <li className="icoFF3">Devoluciones más fáciles y rápidas. </li>
                                </ul>

                                <div className="boxEnteradoFull">
                                    <button className="btnEnteradoFull" onClick={handleClose}>Enterado</button>
                                </div>
                            </div>
                        </ModalImageFulFilment>
                    }
                </div>
                : <div className="productoAgotado">
                    <p>{messageStock}</p>
                    <span></span>
                </div>
            }
        </>
    )
}