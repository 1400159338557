import { BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL } from 'constants/general'
import axios from 'axios'

//funcion post login mi telmex
export async function MailComplete(props) {// trae la variable session token
    const {
        id,
        bk
    } = props
    let apiURL

    if(bk === true){
        apiURL = `${BASE_PATH_API}/pse/verCorreoCompleto/?hs_email=${id}`
    }else{
        apiURL = `${BASE_PATH_API}/mail/Mail?id=${id}`
    }
    
    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error => {
        console.log(error.request.status)
        if (error.message === 'Network Error') {
            console.log('Error de conexión')
        }
        else if (error.request) {
            console.log(error.request.responseText)
            return { error: error.request.status, message: error.request.responseText}
        }
        if (error.request.status === 0) {
            console.log('request 0')
        } else {
            if (error.response.status === 401) {
                console.log(error.response.data.msg)
                return { error: error.response.status, message: error.response.data.msg }
            }
            else if (error.response.status === 404) {
                console.error(error.response);
                console.log('request 404')
            }
            else if (error.response.status === 440) {
                console.log('request 440')
            }
            else if (error.response.status === 500) {
                console.error(error.response);
                console.log('request 500')
            }
            else {
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}