import React, {useState, useEffect} from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useIsMobile } from 'hooks/useIsMobile'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { CartTerrific } from 'services/Afiliados'
import { postCartBloque } from 'services/postProductCart'
import { decodeStorage, encodeStorage } from 'functions/storageBase64';
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';


import Footer from 'pages/Footer/FooterDesktop'
import Header from 'pages/Header/HeaderNavegacion'
import Loading from 'components/Loading'
import BarraCP from 'components/BarCP/BarHeaderCP'

function ErrorGo(){
    return(
        <section>
            <div className="errorProductServices">
                <img src="/img/maintenanceError.svg" alt="Página no encontrada" width="450" height="450" loading="lazy"/>
                <p className="titleError">Carrito no disponible</p>
                <p className="suggestion">Lo sentimos, los productos o la información que buscas no están disponibles.</p>
                <Link className="btn rojo" to={`/`} title="Ir al inicio">Ir al inicio</Link>
            </div>
        </section>
    )
}

export default function Afiliados(){

    const searsT =  "2430"
    // const sanbornsT = "3496"
    // const claroshopT = "2468"

    const tiendaTerrific = searsT
 
 
    const params = useParams();
    const history = useHistory()
    const isLoged = decodeStorage('session_token');
    
    const { isMobile } = useIsMobile()
	const [ loading, setLoading ] = useState(true)
    
    const [responseCart, setResponseCart] = useState()
    const [respCart, setRespCart] = useState()
    const [cartAfil, setCartAfil] = useState()
    const [cartTerrific, setCartTerrific] = useState()
    const [errorBase, setErrorBase] = useState(false)
    
    useEffect(()=>{
        if(!isLoged){
            history.replace(`/login?redirect=${window.location.href}`)
        }
    },[])


    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession, atExp, actTime} = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "detallePedidoDesckop", timeRef: 1500})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        if(cartAfil){
            setTimeout(function(){
                checkValidateSession(true)
                setActionOk('cartA')
            },700)
        }
    }, [cartAfil]);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            // Va al servicio para traer las direcciones
            if(actionOK === 'cartA'){
                setServiceRT(false)
                setActionOk(false)
                // console.log('cartAfil', cartAfil)
                if(cartAfil[0]){
                    postCartBloque(cartAfil)
                    .then(setRespCart)
                    if(decodeStorage('profileData')?.idClaro){
                        CartTerrific(cartTerrific)
                    }
                }else{
                    setErrorBase(true)
                    setLoading(false)
                }
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(()=>{
        if(params){
            let cartGo 
            try{
                cartGo = JSON.parse( window.atob( params?.hash_cart) )
                setResponseCart(cartGo)
                // console.log('cartGo:  ', cartGo)
            }catch(e){
                console.log(e)
                setErrorBase(true)
                setLoading(false)
            }
        }
    },[params])
    
    const [cartAB, setCartAB] = useState()
    useEffect(()=>{
        if(responseCart){ 
            if(responseCart?.products){
                setCartAB(responseCart?.products)
                let terrific = {
                    "paymentId": responseCart?.paymentId,
                    "redirectTo": responseCart?.redirectTo,
                    "webhook": responseCart?.webhook
                }
                encodeStorage("terrificData", terrific)
            }else{
                setCartAB(responseCart)
            }
        }
    },[responseCart])

    useEffect(()=>{
        if(cartAB){
            let cartFinal = cartAB.map((cartMap) =>  {
                let cart = cartMap?.product_id ? {
                    id_cs: cartMap?.product_id,
                    children_sku: cartMap?.children_sku != null ? cartMap?.children_sku : "0",
                    quantity: cartMap?.quantity
                } : {}

                return cart
            })
            
            let totalCart = cartFinal.filter(elem => {
                if (Object.keys(elem).length !== 0) {
                    return true;
                }
                    return false;
                }
            );

            let terrificData = {
                "user_id": String( decodeStorage('profileData')?.idClaro ),
                "email": decodeStorage('profileData')?.correo,
                "tienda_id": tiendaTerrific,
                "products": responseCart?.products
            } 

            setCartAfil(totalCart)
            setCartTerrific(terrificData)
        }
    },[cartAB])


    useEffect(()=>{
        if(respCart){
            history.replace('/carrito/detalle')
        }
    },[respCart])

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
	const reciveDirection = (val) =>{ setUpdateCPBar(val) }
	const [upCP, setUpCP] = useState()
    useEffect(()=>{ if(upCP){ /*console.log('')*/ } },[upCP, updateCPBar])

    return(
        <>
            { loading && <Loading /> }
            {isMobile === 'desktop'
                ?
                    <>
                        <ErrorBoundary>
                            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <section className="barCpResponsive">
                                <BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>	
                            </section>
                        </ErrorBoundary>
                        <main>
                            {errorBase
                                ? <ErrorGo />
                                : null
                            }
                        </main>
                        <Footer />
                    </>
                :  
                    <>
                        <ErrorBoundary>
                            <Header page='pageHome' setUpCP={setUpCP}/>
                            <Link className="fakeSearch" to="/buscador">
                                <span>¿Qué es lo que buscas?</span>
                            </Link>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>
                        </ErrorBoundary>
                        <main>
                            {errorBase
                                ? <ErrorGo />
                                : null
                            }
                        </main>
                    </>
            }
        </>
    )
}