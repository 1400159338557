import React, { useState, useEffect } from 'react';
import './style.sass';
import ModalDescription from 'components/Modal';
import { Link, useHistory, useLocation } from 'react-router-dom';

export default function ModuleDescriptionProduct(props) {

    const {
        description
    } = props;


    const history = useHistory();
    const location = useLocation();
    const urlLink = window.location.href;
    const hashLink = window.location.hash;

    const [showModal, SetShowModal] = useState(false);

    const showDescription = () => {
        SetShowModal(true);
    };

    const handleClose = () => {
        history.goBack();
        // history.push(location.pathname)
    };

    useEffect(() => {
        if (hashLink === "#modalDescription") {
            showDescription();
            copyPasteFlixMedia();
        } else {
            SetShowModal(false);
        }
    }, [hashLink, urlLink])


    const copyPasteFlixMedia = () => {
        let copyCS = document.querySelector("#flix-inpage").innerHTML;
        setTimeout(function () {
            document.querySelector(".pasteCS").innerHTML = copyCS;
        }, 2000)
    }

    const [totalLineas, setTotalLineas] = useState(0);
    const [ver, setVer] = useState(false);

    useEffect(() => {
        let element = document.getElementById('descripcion');
        let elementStyle = window.getComputedStyle(element);
        let elementColor = elementStyle.getPropertyValue('-webkit-line-clamp');

        var divHeight = Number(document.getElementById('descripcion').offsetHeight);
        var alto = parseInt(elementStyle.getPropertyValue('line-height'));
        var division = divHeight / alto;
        setTotalLineas(division);

    }, [])

    // activa enlace leer todas la descripcioon / ver menos
    const verMas = () => {
        setVer(!ver);
    }

    return (
        <div className="moduleDescription">
            {/* <Link to={'#modalDescription'} onClick={showDescription} className="modulePartDescription"> */}
            <div className="modulePartDescription">
                <p className="h3">Descripción</p>
                <div dangerouslySetInnerHTML={{ __html: description }} className={
                    (totalLineas > 40 && ver === false)
                        ? "textDescription"
                        : (totalLineas > 40 && ver === true)
                            ? "textOculto"
                            : (totalLineas <= 40) && "textOculto"
                }
                    id="descripcion"></div>


                {/* <div id="flix-inpage" className="hiddenCS"></div> */}
                <div id="flix-inpage" className={((totalLineas > 40 && ver === true) || (totalLineas < 40)) ? "pasteCS" : "hiddenCS"}></div>
                {/* <div id="flix-inpage" className={totalLineas < 40 ? "pasteCS" : "hiddenCS"}></div> */}

                {totalLineas > 40 && <p onClick={verMas} className={ver === false ? "continuarLeyendo" : "Vmenos"}> {ver === false ? "Continuar leyendo" : "Ver menos"}</p>}
                {/* <div id="flix-inpage" className="hiddenCS"></div> */}
                {/* </Link> */}
            </div>

            {showModal && <ModalDescription onClose={handleClose} type="full" title="Descripción">
                <div dangerouslySetInnerHTML={{ __html: description }} className="textDescription"></div>
                <div className="pasteCS"></div>
            </ModalDescription>}
        </div>
    )
}



export function ModuleDescriptionProductDesktop({ description }) {
    return (
        <div className="moduleDescription componentDesktop">
            <div className="modulePartDescription">
                <p className="h3">Descripción</p>
                <div dangerouslySetInnerHTML={{ __html: description }} className="textDescription"></div>
            </div>
        </div>
    )
}