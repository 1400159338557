import {TIMEOUT_TIME, STAGE_DIRNAME, SSO, VARIANTS_PER_PORTAL} from 'constants/general'
import { decodeStorage } from 'functions/storageBase64';
import axios from 'axios'

export async function getTokenSSO ({code}){

    const apiURL = `${SSO.ssoURL}/auth/realms/${SSO.realmSSO}/protocol/openid-connect/token`
    const rawSSO = `code=${code}&grant_type=authorization_code&client_id=${SSO.clientSSO}&redirect_uri=${encodeURIComponent(`${window.location.origin}/authenticated`)}`

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        mode: 'no-cors',
		crossdomain: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: rawSSO
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            headers, 
            status, 
            data
        } = res
        let newData
        if(data){
            if(data.data){
                newData = {
                    token : data.data.session_token,
                    idCarrito : data.data.cart_hash

                }
            }
        }
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}

export async function getRefreshSSO(){

    const apiURL = `${SSO.ssoURL}/auth/realms/${SSO.realmSSO}/protocol/openid-connect/token`
    const accessToken = "Bearer " + decodeStorage("access_token");
    const rawSSO = `refresh_token=${decodeStorage('refresh_token')}&client_id=${SSO.clientSSO}&grant_type=refresh_token&redirect_uri=${encodeURIComponent(`${window.location.origin}/authenticated`)}`

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        mode: 'no-cors',
		crossdomain: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: rawSSO
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            headers, 
            status, 
            data
        } = res
        let newData
        if(data){
            if(data.data){
                newData = {
                    token : data.data.session_token,
                    idCarrito : data.data.cart_hash

                }
            }
        }
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}


export async function getLogoutSSO(){

    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${SSO.ssoURL}/auth/realms/${SSO.realmSSO}/protocol/openid-connect/logout`
    const rawSSO = `refresh_token=${decodeStorage('refresh_token')}&client_id=${SSO.clientSSO}&redirect_uri=${encodeURIComponent(`${window.location.origin}/authenticated`)}`
    
    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        mode: 'no-cors',
        crossdomain: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': accessToken
        },
        data: rawSSO
    };
    
    // const rawSSO = `refresh_token=${decodeStorage('refresh_token')}&client_id=${SSO.clientSSO}&redirect_uri=${encodeURIComponent(`${window.location.origin}/authenticated`)}`
    // //const rawSSO = `id_token_hint=${decodeStorage('access_token')}&post_logout_redirect_uri=${encodeURIComponent(`${window.location.origin}/authenticated`)}`
    // const apiURL = `${SSO.ssoURL}/auth/realms/${SSO.realmSSO}/protocol/openid-connect/logout?${rawSSO}`
    // const config = {
    //     method: 'get',
    //     url: apiURL,
    //     timeout: TIMEOUT_TIME,
    //     withCredentials: !VARIANTS_PER_PORTAL?.bManager,
    //     mode: 'no-cors',
	// 	crossdomain: true
    // };

    return await axios(config)
    .then(res => {
        const {
            // config,
            headers, 
            status, 
            data
        } = res
        let newData
        if(data){
            if(data.data){
                newData = {
                    token : data.data.session_token,
                    idCarrito : data.data.cart_hash

                }
            }
        }
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}