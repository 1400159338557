import React from 'react'
import { Link } from 'react-router-dom'
import CurrencyFormat from "react-currency-format"
import LazyLoad from 'react-lazy-load'
import ProgressiveImage from 'react-progressive-graceful-image'
import { LAZY_L, NO_IMAGE, IMAGE_THUMB, SIZE_IMAGE_THUMB } from 'constants/general'

import { repareImage } from 'functions/repareDataService'
import { useDispatch } from 'context/storeProvider'
import { TYPES } from 'context/storeReducer'
import { repareTextDlayer } from 'functions/repareDataService'
import { dataLayerTapsCarrusel } from 'functions/dataLayerFunction'

export default function CardRecomendation(props) {
    const {
        id,
        nombre,
        precio,
        precioLista,
        foto,
        descuento,
        link,
        priority = "low",
        productTotal,
        categoriesEmar,
        brand,
        index,
        seccionPage,
        seller,
        origenBread
    } = props

    const dispatch = useDispatch()

    let newFoto, placeH
    if (foto?.indexOf('?') >= 0) {
        placeH = repareImage(foto)+`&scale=${SIZE_IMAGE_THUMB}`
        newFoto = repareImage(foto)+`&scale=350`
        //newFoto = foto
    } else {
        placeH = repareImage(foto)+`?scale=${SIZE_IMAGE_THUMB}`
        newFoto = repareImage(foto)+`?scale=350`
        //newFoto = foto
    }

    //console.log('seccionPageRE: ',seccionPage)

    var listProductDL   = [];
    const categoriaArr  = categoriesEmar.split(">");
    listProductDL.push({
        item_id: id,
        item_name: repareTextDlayer(nombre),
        affiliation:repareTextDlayer(seller),
        price: precioLista,
        item_brand: repareTextDlayer(brand),
        item_category: categoriaArr[0]  != undefined ? repareTextDlayer(categoriaArr[0]): null,
        item_category2: categoriaArr[1] != undefined ? repareTextDlayer(categoriaArr[1]): null,
        item_category3: categoriaArr[2] != undefined ? repareTextDlayer(categoriaArr[2]): null,                                    
        //item_list_name: categoriaArr[0]  != undefined ? "Discovery-"+categoriaArr[0]: "Discovery-",
        item_list_name: origenBread == null ? "discovery - para ti " : 'discovery - '+repareTextDlayer(origenBread),
        item_variant: "disponible",
        //item_list_name: "Discovery",
        //item_list_id: seccionPage,
        item_list_id: "home-Discovery",
        index: index+1,
        quantity: 1
    })

    //console.log("new foto1: ", props, listProductDL)

    const clickCard = () =>{
        // console.log('productTotal: ',productTotal)
        dispatch({type: TYPES.UPDATE_PRODUCT, payload: productTotal ? productTotal : {} })
        window.sessionStorage.setItem("productTotal",  JSON.stringify(productTotal ? productTotal : {}) )

        dataLayerTapsCarrusel(listProductDL);
        //console.log("new foto: ", listProductDL)
        console.log("click Card Emarsys: ")
    }

    return (
        <article className="cardRecomended" data-scarabitem={id} onClick={clickCard} >
            {/* <BtnWishlist id={id}/> */}
            <Link to={link} title={nombre}>
                <div>
                    <div className="h4P"><span>{nombre}</span></div>
                    {IMAGE_THUMB
                        ?
                            <ProgressiveImage
                                src={foto != null ? newFoto : NO_IMAGE}
                                placeholder={foto != null ? placeH : NO_IMAGE}
                            >
                                {(src) => <img
                                    src={src}
                                    alt={nombre}
                                    width="230"
                                    height="230"
                                    decoding="async"
                                    loading="lazy"
                                    fetchpriority={priority}
                                />
                                }
                            </ProgressiveImage>
                        :
                            <img
                                src={foto != null ? newFoto : NO_IMAGE}
                                alt={nombre}
                                width="230"
                                height="230"
                                decoding="async"
                                loading="lazy"
                                fetchpriority={priority}
                            />
                    
                    }
                    <div className="info">
                        <CurrencyFormat
                            value={precioLista}
                            displayType={'text'}
                            thousandSeparator={true} prefix={'$'} renderText={value => <p className="precioPrincipal">{value}<span className="mxn"></span>
                                {/* {descuento <= HIDDEN_DISCOUNT ? null  : <span className="discoutnCard">-{descuento}%</span> } */}
                            </p>}
                        />
                        {/* <div className="priceDiscountContent"> */}
                        {descuento !== 0 && precioLista !== precio
                            ?
                            <CurrencyFormat
                                value={precio}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                renderText={value => <p className="textUnderline conDiscount">{value}</p>}
                            />
                            : null
                        }
                        {/* </div> */}
                    </div>
                </div>
            </Link>
        </article>
    )
}