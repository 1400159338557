import React, { useState } from "react";
import './style.sass';
import { Link } from "react-router-dom";
import ModalPortal from 'components/Modal';

//- funcion de modal de eliminar direccion
function MessageRemove(props) {
    const { remvoeAction, awaitAction } = props;

    const elimiar = () => remvoeAction(true);

    const recapacitar = () => awaitAction(true);

    return (
        <div className="modalRemoveAddress">
            <p>¿Estas seguro que deseas eliminar esta dirección?</p>
            <div className="contBtns">
                <div className="btn blanco" onClick={recapacitar}>No</div>
                <div className="btn blanco" onClick={elimiar}>Si, eliminar</div>
            </div>
        </div>
    )
}

export default function CardAddress(props) {
    const {
        id,
        accesibilidad,
        nombre,
        direccion,
        numeroExterior,
        colonia,
        codigoPostal,
        municipio,
        ciudad,
        telefono,
        selectDefaultA,
        sendButton,
        sendAddress,
        removeButton,
        editRoute
    } = props;

    const [showModal, SetShowModal] = useState(false);

    // funciones para mostrar el modal
    const showModalRemove = () => SetShowModal(true);

    const handleClose = () => SetShowModal(false);

    // selecciona id de direccion predeterminada
    const sendDefaultAddress = () => selectDefaultA(id);

    // detona modal para eliminar direccion
    const removeAddress = () => showModalRemove();

    const sendAddressMulti = () => sendAddress(id);

    const noRemove = () => SetShowModal(false);

    const siRemove = () => {
        SetShowModal(false);
        removeButton(id);
    }

    return (
        <>
            <div className="cardDirection">
                <div className="principalT">
                    {accesibilidad === '1' || accesibilidad === true
                        ? <div className="predeterminada active">Dirección predeterminada</div>
                        : <div onClick={sendDefaultAddress} className="predeterminada inactive">Establecer como predeterminada</div>
                    }
                </div>

                <div className="principalB">
                    <div className="cardLeft">
                        <div className="superiorData">
                            <p className="nombreDireccion">{nombre}</p>
                            <p className="telefonoDireccion">Celular: {telefono}</p>
                        </div>

                        <div className="dataCardDirection">
                            <p>{direccion} {numeroExterior}, </p>
                            <p>{colonia},</p>
                            <p>C.P. {codigoPostal},</p>
                            <p> {municipio}, {ciudad}</p>
                        </div>

                        {sendButton
                            ? <div className="btn rojo" onClick={sendAddressMulti}>Enviar a esta dirección</div>
                            : null
                        }
                    </div>

                    <div className="cardRight">
                        <Link to={`${editRoute}/${id}`} className="btn22 btn blancoBtn">Editar</Link>
                        <div className="btn11 btn blancoBtn" onClick={removeAddress} >Eliminar</div>
                    </div>
                </div>
            </div>

            {showModal &&
                <ModalPortal type="modalSB" onClose={handleClose} >
                    <MessageRemove
                        remvoeAction={siRemove}
                        awaitAction={noRemove}
                    />
                </ModalPortal>
            }
        </>
    )
}

export function CardAddressMobile(props) {
    const {
        id,
        accesibilidad,
        nombre,
        direccion,
        numeroExterior,
        colonia,
        codigoPostal,
        municipio,
        ciudad,
        telefono,
        selectDefaultA,
        sendButton,
        sendAddress,
        removeButton,
        editRoute
    } = props;

    const [showModal, SetShowModal] = useState(false);

    // funciones para mostrar el modal
    const showModalRemove = () => SetShowModal(true);

    const handleClose = () => SetShowModal(false);

    // selecciona id de direccion predeterminada
    const sendDefaultAddress = () => selectDefaultA(id);

    // detona modal para eliminar direccion
    const removeAddress = () => showModalRemove();

    const sendAddressMulti = () => sendAddress(id);

    const noRemove = () => SetShowModal(false);

    const siRemove = () => {
        SetShowModal(false);
        removeButton(id);
    }

    return (
        <>
            <div className="cardDirectionMobile">
                <div className="principalTmobile">
                    {accesibilidad === '1' || accesibilidad === true
                        ? <div className="predeterminadaMobile active">Dirección predeterminada</div>
                        : <div onClick={sendDefaultAddress} className="predeterminadaMobile inactive">Establecer como predeterminada</div>
                    }
                </div>

                <div className="principalBmobile">
                    <div className="cardLeftMobile">
                        <div className="superiorDataMobile">
                            <div className="nameDeleted">
                                <div className="nameBx">
                                    <p className="nombreDireccion">{nombre}</p>
                                </div>
                                <div className="deleteDir" onClick={removeAddress} ></div>
                            </div>
                            <p className="telefonoDireccion">Celular: {telefono}</p>
                        </div>

                        <div className="dataCardDirectionMobile">
                            <p>{direccion} {numeroExterior}, </p>
                            <p>{colonia},</p>
                            <p>C.P. {codigoPostal},</p>
                            <p> {municipio}, {ciudad}</p>
                        </div>

                        {sendButton
                            ? <div className="btn rojo" onClick={sendAddressMulti}>Enviar a esta dirección</div>
                            : null
                        }
                    </div>

                    <div className="cardRight">
                        <Link to={`${editRoute}/${id}`} className="btn22 btn blancoBtn">Editar</Link>
                    </div>
                </div>
            </div>

            {showModal &&
                <ModalPortal type="modalSB" onClose={handleClose} >
                    <MessageRemove
                        remvoeAction={siRemove}
                        awaitAction={noRemove}
                    />
                </ModalPortal>
            }
        </>
    )
}