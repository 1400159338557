import React, { useState, useEffect, Fragment } from 'react';
import './style.sass';
import { useClicyRecoge } from 'hooks/useClicyRecoge';
import { BASE_PATH_CAJA } from 'constants/general'
import ModalCyR from 'components/Modal';
import CurrencyFormat from 'react-currency-format';
import { repareTextSimple } from 'functions/repareDataService';
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import { useHistory } from 'react-router-dom';
import { useToken } from 'hooks/useToken';
import Toast from 'components/Toast';
import { repareTextHtml } from 'functions/repareDataService';
import Loading from 'components/Loading';

export default function ButtonCyR(props) {
    const {
        cyr,
        id,
        title,
        dataImage,
        precioLista,
        discount,
        skuChildren,
        skuChildrenFF,
        store,
        stock,
        categories,
        status,
        size_color,
        colorCyR,
        tallaCyR,
        precio
    } = props;

    const profileData = decodeStorage('profileData');
    const idCarrito = decodeStorage('idCarrito');
    const session_token = decodeStorage('session_token');
    const preCyR = decodeStorage('preCyR');
    const history = useHistory();
    const refreshCyR = useToken();
    const domain = window.location.hostname;

    const {
        dataCyR, dataToast, dataSucursales, errorMessage, loading,
        showCyR, handleClose, updateSKU, updateID, handleToast
    } = useClicyRecoge(id, dataImage, skuChildren, store, size_color, skuChildrenFF);

    updateID(id);
    updateSKU(skuChildrenFF);

    const [loadingCyR ,setLoadingCyR] = useState(false)
    const [loadingClick ,setLoadingClick] = useState(false)

    const [idSuc, setIdSuc] = useState();
    const btnIrSucursal = (id_sucursal) => {
        if (session_token) {
            refreshCyR.forceToken(true);
            setIdSuc(id_sucursal);
            // aqui para colcoar el loading al boton
            setLoadingClick(true)
            setTimeout(function(){
                setLoadingClick(false)
            },10000)
        } else {

            let sku_hijo;
            if (skuChildrenFF) { sku_hijo = skuChildrenFF; } else { sku_hijo = 0; }
            
            encodeStorage('preCyR', {id: id, id_sucursal: id_sucursal, skuProd: sku_hijo })
            history.push(`/login?redirect=${window.location.href}`);
        }
    }


    useEffect(()=>{
        if(preCyR){
            if(preCyR?.id === id){
                if(session_token) {
                    setTimeout(function(){
                        setLoadingCyR(true)
                    },150)
                    refreshCyR.forceToken(true);
                    setIdSuc(preCyR?.id_sucursal)
                }
            }else{
                removeStorage('preCyR')
                setLoadingCyR(false)
            }
        }
    },[preCyR])

    useEffect(() => {
        if (idSuc) {
            if (refreshCyR.newToken) {
                let sku_hijo;
                if(preCyR){
                    if(preCyR?.skuProd){
                        sku_hijo = preCyR?.skuProd
                    }else{
                        sku_hijo = 0
                    }
                }
                else{
                    if (skuChildrenFF) {sku_hijo = skuChildrenFF; } else { sku_hijo = 0; }
                }
                let producto_id;
                if (id) { producto_id = id; }
                let cliente_id
                if (profileData) { cliente_id = profileData.idClaro; }

                let urlCajaCyRB64 = `${cliente_id}/${producto_id}/${idSuc}/${sku_hijo}`;
                let urlCajaCyR = `${BASE_PATH_CAJA.replace('/direcciones', '/click-recoge')}/${urlCajaCyRB64}`;

                window.localStorage.setItem('CSC-KEY', window.btoa(decodeStorage('session_token')));
                window.localStorage.setItem('CSC-CR', window.btoa(urlCajaCyRB64));

                setTimeout(function () {
                    removeStorage('preCyR')
                    window.location.href = urlCajaCyR;
                    //console.log(':   ',urlCajaCyR)
                }, 300)
            }
        }
    }, [refreshCyR.newToken])

    const activeCard = (idCard) => {
        if (document.querySelectorAll('.cardCyR.active').length >= 1) {
            document.querySelector('.cardCyR.active').classList.remove('active');
        }
        if (document.querySelectorAll(`.cardCyR.card_id${String(idCard)}.active`).length === 0) {
            document.querySelector(`.card_id${String(idCard)}`).classList.add('active');
        }
    }

    // const [searchCyR, setSearchCyR] = useState('');
    const [resultFilter, setResultFilter] = useState(false);
    let respuesta = []

    // const updateSearch = (e) => {
    //     let searchText = e.target.value;
    //     setSearchCyR(searchText);

    //     if (searchText.length >= 1) {
    //         setResultFilter(true);
    //     } else {
    //         setResultFilter(false);
    //     }
    // }

    const [dia, setDia] = useState(false);
    const hora = new Date();
    useEffect(() => {
        if (hora.getHours() < 17) {
            setDia("hoy");
        }
        else {
            setDia("mañana");
        }
    }, [skuChildren, id])

    const [estados, setEstados] = useState(false);
    const [municipios, setMunicipios] = useState(false);
    useEffect(() => {
        let stateTotal = [];
        let stateUnicos = [];

        if (dataSucursales) {

            dataSucursales.map((sucursal) => {
                if (sucursal.status === true) {
                    stateTotal.push(sucursal.state);
                }
            })

        }

        if (stateTotal.length > 0) {
            for (var i = 0; i < stateTotal.length; i++) {

                const elemento = stateTotal[i];

                if (!stateUnicos.includes(stateTotal[i])) {
                    stateUnicos.push(elemento);
                }
            }
        }

        setEstados(stateUnicos);
    }, [dataSucursales])

    const [valorSelect, setValorSelect] = useState("");
    const handleSelectEstado = (e) => {
        setValorSelect(e.target.value);
    }

    useEffect(() => {
        if (valorSelect !== "") {
            setValorSelectMunicipio("");
        }
    }, [valorSelect])

    useEffect(() => {
        let municipiosTotal = [];
        let municipiosUnicos = [];

        // revisa que primero se seleccione un estado
        if (dataSucursales) {
            if (valorSelect) {
                // setVerAlert(false);
                // obtiene el municipio segun el estado seleccionado 
                dataSucursales.filter((info) => {
                    if (valorSelect && (repareTextSimple(String(info.state).toLowerCase()).includes(repareTextSimple(String(valorSelect).toLowerCase())))) {
                        municipiosTotal.push(info.province);
                        return info
                    }
                })

                // revisa si hay repetidos en el array municipios y los elimina
                if (municipiosTotal.length > 0) {
                    for (var i = 0; i < municipiosTotal.length; i++) {

                        const elemento = municipiosTotal[i];

                        if (!municipiosUnicos.includes(municipiosTotal[i])) {
                            municipiosUnicos.push(elemento);
                        }
                    }
                }
            }
        }
        setMunicipios(municipiosUnicos);

    }, [dataSucursales, valorSelect])

    const [valorSelectMunicipio, setValorSelectMunicipio] = useState("");
    const handleSelectMunicipio = (e) => {
        setValorSelectMunicipio(e.target.value);
    }

    useEffect(() => {
        if (dataCyR.showModal === false) {
            setValorSelect("");
            setValorSelectMunicipio("");
            // setSearchCyR("");
        }
    }, [dataCyR.showModal])

    // const [verAlert, setVerAlert] = useState(false);
    // const handleAlert = () => {
    //     setVerAlert(true);

    // }
    // useEffect(() => {
    //     if (verAlert)
    //         setTimeout(function () { setVerAlert(false) }, 3500);
    // }, [verAlert])

    return (
        <>
            {loadingCyR && <Loading />}

            {cyr &&
                stock >= 1 && status &&
                <> <div className="add-tienda" id="add-tienda">
                    <p className="add-tienda-btn btn" onClick={showCyR}>
                        Recoger GRATIS {dia} en tienda
                    </p>
                </div>

                    {dataCyR.showModal &&
                        <ModalCyR onClose={handleClose} type={dataCyR.isMobile === "desktop" ? "" : "full"} title={dataCyR.isMobile === "desktop" ? "Elige tu tienda Sears" : "Elige tu tienda Sears"}>
                            <div className="containerCyR">
                                <div className="cardProductDetailsCyR">
                                    <img src={dataCyR.dataImagenNew.dataImage[0].thumbnail} alt={repareTextHtml(title)} width="100" height="100" loading="lazy" />
                                    <div >
                                        <p className="h1">{repareTextHtml(title)}</p>
                                        {size_color &&
                                            size_color.length >= 1 &&
                                            colorCyR &&
                                            <p>Color: {colorCyR}</p>
                                        }
                                        {size_color &&
                                            size_color.length >= 1 &&
                                            tallaCyR &&
                                            <p>Talla: {tallaCyR}</p>
                                        }
                                        <div className="secPrecio">
                                            {discount >= 10
                                                ? <><CurrencyFormat
                                                    value={precio}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    renderText={value => <p className="tach color"><span className="">{value}</span></p>}
                                                />

                                                    <p className="discount colorVerde">-{discount}%</p>
                                                </>
                                                : null
                                            }
                                            <CurrencyFormat
                                                value={precioLista}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                renderText={value => <p><span className="strong">{value}</span></p>}
                                            />
                                        </div>
                                        <p>Cantidad <span className="strong">1</span></p>
                                    </div>
                                </div>
                                <div className="listadoCR">
                                    <div className="CYRfill">
                                        <p>Encuentra tu tienda más cercana filtrando por estado y municipio</p>
                                    </div>
                                    {loading || loadingClick
                                        ?
                                            <div className={`modal loading small`}>
                                                <div className="contentModal">
                                                    <div className="spinner"></div>
                                                </div>
                                            </div>
                                        : null
                                    }
                                    {dataSucursales && estados.length > 0 &&
                                        <div className="contenedorFiltradoCyR">
                                            <div className="contSearchCyR">
                                                {/* <label>
                                                <input
                                                    className="searchCyR"
                                                    type="search"
                                                    value={searchCyR}
                                                    onChange={updateSearch}
                                                    placeholder="Buscar por CP"
                                                    disabled={!dataSucursales ? 'disabled' : ''}
                                                />
                                            </label> */}
                                                <select value={valorSelect} onChange={handleSelectEstado} name="estado">
                                                    <option disabled value="">Seleccionar Estado</option>
                                                    {estados && estados.length > 0 && estados.sort().map((stado, i) =>
                                                        <option value={stado} key={i}>{stado}</option>
                                                    )}
                                                </select>
                                            </div>

                                            <div className="contEstadoCyR">
                                                {valorSelect === ""
                                                    ? <div className="sinEstado">
                                                        <p>Seleccionar municipio</p>
                                                    </div>
                                                    : <select value={valorSelectMunicipio} onChange={handleSelectMunicipio} name="municipio" id="municipio">
                                                        <option disabled value="" >Seleccionar municipio</option>
                                                        {municipios !== false && municipios.length > 0 && municipios.sort().map((municip, ind) =>
                                                            <option value={municip} key={ind}>{municip}</option>
                                                        )}
                                                    </select>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {/* {verAlert === true && <div className="alertSeleccion"><p>Selecciona Primero un estado</p></div>} */}

                                    <div className="contentCardCyR">
                                        {dataSucursales &&
                                            dataSucursales.filter((data) => {
                                                if (valorSelect === "" && valorSelectMunicipio === "") {
                                                    return data
                                                }
                                                else if (valorSelect.length > 0 && valorSelectMunicipio.length === 0 && repareTextSimple(String(data.state).toLowerCase()).includes(repareTextSimple(String(valorSelect).toLowerCase()))) {
                                                    respuesta.push(data)
                                                    return data
                                                }
                                                else if (valorSelect.length > 0 && valorSelectMunicipio.length > 0 &&
                                                    (
                                                        repareTextSimple(String(data.state).toLowerCase()).includes(repareTextSimple(String(valorSelect).toLowerCase())) &&
                                                        repareTextSimple(String(data.province).toLowerCase()).includes(repareTextSimple(String(valorSelectMunicipio).toLowerCase()))
                                                    )) {
                                                    respuesta.push(data);
                                                    return data
                                                }
                                                // if ((searchCyR == null || searchCyR === '') && (valorSelect === "")) {
                                                //     return data
                                                // }
                                                // else if (searchCyR.length > 0 && valorSelect === "" &&
                                                //     (repareTextSimple(String(data.name).toLowerCase()).includes(repareTextSimple(String(searchCyR).toLowerCase())) ||
                                                //         repareTextSimple(String(data.zip_code).toLowerCase()).includes(repareTextSimple(String(searchCyR).toLowerCase())))
                                                // ) {
                                                //     respuesta.push(data)
                                                //     return data
                                                // }
                                                // else if (valorSelect.length > 0 && searchCyR === '' && repareTextSimple(String(data.state).toLowerCase()).includes(repareTextSimple(String(valorSelect).toLowerCase()))) {
                                                //     respuesta.push(data)
                                                //     return data
                                                // }
                                                // else if (searchCyR.length > 0 && valorSelect.length > 0 && (
                                                //     repareTextSimple(String(data.state).toLowerCase()).includes(repareTextSimple(String(valorSelect).toLowerCase())) &&
                                                //     repareTextSimple(String(data.zip_code).toLowerCase()).includes(repareTextSimple(String(searchCyR).toLowerCase()))
                                                // )) {
                                                //     respuesta.push(data)
                                                //     return data
                                                // }
                                            }).map((sucursalData, i) => {
                                                return (
                                                    sucursalData.status === true && <Fragment key={i}>
                                                        <div className={`cardCyR card_id${sucursalData.id}`} onClick={() => activeCard(sucursalData.id)} key={sucursalData.id}>
                                                            <span className="clircleRadio"></span>
                                                            <p className="h2">{sucursalData.name}</p>
                                                            {sucursalData.latitude && sucursalData.longitude &&
                                                                <a href={`https://maps.google.com?q=${sucursalData.latitude},${sucursalData.longitude}`} target="_blank" rel="noopener noreferrer" className="link showLocation">Ver ubicación</a>
                                                            }
                                                            <p>{sucursalData.street} {sucursalData.number} </p>
                                                            <p>{sucursalData.suburb} {sucursalData.zip_code} {sucursalData.state}</p>
                                                            <p>Horario: {sucursalData.schedule}</p>
                                                            <p>Teléfono: <a href={`tel:${sucursalData.phone.lada}${sucursalData.phone.number}`} className="link">{sucursalData.phone.lada} {sucursalData.phone.number}</a></p>
                                                            <div className="btn rojo" onClick={() => btnIrSucursal(sucursalData.id)} >Recoge GRATIS {dia}</div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        {resultFilter &&
                                            respuesta.length === 0 &&
                                            <>
                                                <div className="errorSearch">
                                                    <p className="h2">No se encontraron tiendas que<br /> coincidan con tu búsqueda</p>
                                                    <p>Intenta filtrar con otro municipio / alcaldía</p>
                                                </div>
                                            </>
                                        }
                                        {errorMessage &&
                                            <>
                                                <div className="errorSearch">
                                                    <p className="h2">{errorMessage}</p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </ModalCyR>
                    }

                    {dataToast.toast &&
                        <Toast
                            type={dataToast.messageToast.type}
                            time={dataToast.messageToast.time}
                            onClose={handleToast}
                        >
                            {dataToast.messageToast.textMessage}
                        </Toast>
                    }
                </>
            }
        </>
    )
}