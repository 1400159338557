import { PENDIENTE, PEDIDOS_PENDIENTES_AUTORIZAR, PEDIDOS_PENDIENTES_PAGO, PEDIDOS_PRODUCTO_ENTREGADO } from 'constants/general';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getPedido, getRefoundInfo } from 'services/AyudaDinamicaServices';
import './ayudaDinamicaDesktop.sass';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeStorage } from 'functions/storageBase64';
import Loading from 'components/Loading';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useToken } from 'hooks/useToken';
import FooterPortal from 'pages/Footer/FooterDesktop';
import { MenuMiCuenta } from 'components/MenuMiCuenta';

export function BloqueFormasPago({ payment, setResultRefoundProp, setLoading }) {

    const [resultadoRefound, setResultadoRefound] = useState(false);
    const [texto, setTexto] = useState("");

    // get refoundInfo
    useEffect(() => {
        let formaPago = payment;

        getRefoundInfo(formaPago)
            .then(setResultadoRefound);
    }, [])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoRefound && resultadoRefound.refound) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoRefound])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "bloqueFPdesktop" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    // almacena copy obtenido
    useEffect(() => {
        if (resultadoRefound) {
            if (resultadoRefound.refound) {
                setTexto(resultadoRefound.refound.info);
                setResultRefoundProp(resultadoRefound);
            }
            else if (resultadoRefound.statusCode && resultadoRefound.statusCode === 401) {
                setTokenJWT(true);
                
                setTimeout(() => {
                    getRefoundInfo(payment)
                        .then(setResultadoRefound);
                }, 1000);
            }
        }
    }, [resultadoRefound])

    return (
        <div className="textFp">
            <p>{texto}</p>
        </div>
    )
}

export function CancelarPedidoDesktop(props) {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];

    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [productoUnico, setProductoUnico] = useState(false);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [resultadoRefoundProp, setResultRefoundProp] = useState(false);
    const [estatusPedido, setEstatusPedido] = useState(0);
    const [payment, setPayment] = useState([]);

    const [saveStatus,] = useStorage64('saveStatus');
    const [saveStatusProducto,] = useStorage64('saveStatusProducto');
    const [saveIdStatusProducto] = useStorage64('saveIdStatusProducto', '');
    const [estatusProducto, setEstatusProducto] = useState(0);
    const [loading, setLoading] = useState(false);
    const [ima, setima] = useState([]);
    const [idStatusProducto, setIdStatusProducto] = useState(false);

    let urlCheck = "/img/checkMark.svg";
    let urlLogo = "/img/logoSE.svg";

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedidos
    useEffect(() => {
        window.scrollTo(0, 0);
        getPedido(idPedido)
            .then(setResultadoPedido)
    }, [])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido && (Number(estatusPedido) !== Number(PEDIDOS_PENDIENTES_PAGO))) {
            if (resultadoRefoundProp) {
                setLoading(false);
            }
        }
        else if (resultadoPedido && resultadoPedido.pedido && Number(estatusPedido) === Number(PEDIDOS_PENDIENTES_PAGO)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, resultadoRefoundProp])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista5-cancelacion exitosa -desktop" })
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let producto = [];
        let imagenesProductos = [];
        let imagenProducto = [];
        let idStatusProd = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                setEstatusPedido(saveStatus);
                setPayment(resultadoPedido.pedido[0].payment_method);

                if (idProductoEstate !== 0) {
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.id) === Number(idProductoEstate) && producto.push({ "id": elem.id, "estatus_producto": elem.status.id })
                    )

                    setEstatusProducto(saveStatusProducto);

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                    )
                    let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                    setProductoUnico(limpiaProducto);

                    let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                    setima(limpioImagen);

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && idStatusProd.push({ "idStatusP": elem.status_id })
                    )
                    setIdStatusProducto(idStatusProd[0].idStatusP);
                }
                // recorre por pedido
                else if (resultadoPedido.pedido && Number(idProductoEstate) === 0) {
                    if (resultadoPedido.pedido[0]) {

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            imagenesProductos.push({ "url": elem.images[0]?.url })
                        )
                        setima(imagenesProductos);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
               
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // regresar a pagina pedidos
    const RegresarPedidos = () => {
        removeStorage('saveStatus');
        removeStorage('saveStatusProducto');
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const handleBack = () => {
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">

                        <div className='menuMiCuentaAD'>
                            <MenuMiCuenta />
                        </div>

                        <div className='contenidoGeneralAD'>
                            <div className="boxBtnRegresarDktopRojo">
                                <div className="icoBackClose" onClick={handleBack}></div>
                                <button className="btnBackADLogo" onClick={handleBack}></button>
                            </div>

                            <div className="boxGeneralAD">

                                <div className="boxRightAD marginT60">
                                    <div className="boxCancelacionExitosa">
                                        <div className="boxContenido">

                                            {/* Bloque copy pedido ha sido cancelado */}
                                            {((!PEDIDOS_PRODUCTO_ENTREGADO.includes(Number(saveIdStatusProducto))) && 
                                            ((Number(estatusPedido) === PEDIDOS_PENDIENTES_PAGO) || PEDIDOS_PENDIENTES_AUTORIZAR.includes(Number(estatusPedido)) || idProductoEstate === 0)) &&
                                                <>
                                                    <img alt="check-eliminado" src={urlCheck} />
                                                    <h3>Tu pedido ha sido cancelado</h3>
                                                </>
                                            }
                                            {(Number(estatusPedido) !== Number(PEDIDOS_PENDIENTES_PAGO)) && !PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido) &&
                                                (idProductoEstate !== 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(Number(saveIdStatusProducto))) &&
                                                <>
                                                    <img alt="check-eliminado" src={urlCheck} />
                                                    <h3>Tu pedido ha sido cancelado.</h3>
                                                </>
                                            }

                                            <>
                                                {/* bloque pendiente de autorizacion y  bloque pedido autorizado*/}
                                                {payment.id != undefined &&
                                                    <>
                                                        {(
                                                            (idProductoEstate === 0 && Number(estatusPedido) === Number(PEDIDOS_PENDIENTES_PAGO) && PENDIENTE.includes(Number(payment.id)))
                                                            || (idProductoEstate !== 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(Number(saveIdStatusProducto)))
                                                            || (idProductoEstate === 0 && PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido))
                                                        )
                                                            ? <BloqueFormasPago payment={payment.id} setResultRefoundProp={setResultRefoundProp} setLoading={setLoading} />
                                                            : null
                                                        }
                                                    </>
                                                }

                                                {/* bloque pedido entregado */}
                                                {(idProductoEstate !== 0 && PEDIDOS_PRODUCTO_ENTREGADO.includes(Number(saveIdStatusProducto))) &&
                                                    <>
                                                        <img alt="check-eliminado" src={urlCheck} />
                                                        <h3>Revisaremos tu caso y nos contactaremos contigo para darte una solución.</h3>
                                                    </>
                                                }
                                            </>
                                        </div>

                                        <div className="boxBtnRegresar">
                                            <button className="btnRegresar" onClick={RegresarPedidos}>Regresar a mis pedidos</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>

            <FooterPortal />
        </>
    )
}