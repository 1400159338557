import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './filtrosDesktop.sass';
import InputRange from 'react-input-range';

export default function FiltrosDesktop({
    tieneReview, activarEstrellas, handleActivarEstrellas,
    tieneEnvioGratis, activarEnvioGratis, handleEnvioGratis,
    tieneFulfillment, activarFullfilment, handleActivarFullfilment,
    seleccionarCategoria, listaCategorias, idCategoria,
    seleccionarMarca, listaMarcas, checkedItem, seleccionarDiscount, checkedDiscount,
    precioMax, precioMenor, precioMin, PrecioMax, filtrarPrecio, limpiarFiltros,
    verMasCategorias, VerMasCate, verMasMarcas, VerMasMar, history, valorDescuento, minimo, maximo,
    actualizarMaxDesdeBarra, actualizarMinDesdeBarra
}) {

    let longitudMarcas = 0;
    let longitudCategorias = 0;
    if (listaMarcas)
        longitudMarcas = listaMarcas.length;
    if (listaCategorias)
        longitudCategorias = listaCategorias.length;

    useEffect(() => {
        for (let i = 1; i < 8; i++) {
            var valor = $("#d" + i).val();
            if (valor < checkedDiscount) {
                document.getElementById("discount" + i).classList.add("none");
            }
        }
    }, [])

    const onKeyDown = (event) => {
        if ((event.keyCode < 48 || event.keyCode > 57)
            && (event.keyCode < 96 || event.keyCode > 105)
            && event.keyCode != 190 && event.keyCode != 110 && event.keyCode !== 8 && event.keyCode !== 9) {
            event.preventDefault();
        }
    }

    const [valorBarra, setValorBarra] = useState({ min: 0, max: 0 },);
    useEffect(() => {
        setValorBarra({ min: minimo, max: maximo });
    }, [minimo, maximo])

    const cambiarBarraPrecios = (e) => {
        setValorBarra(e);
    }

    useEffect(() => {
        if (Number(valorBarra.min)) {
            //==! Number(minimo)) {
            actualizarMinDesdeBarra(Number(valorBarra.min));
        }
        if (Number(valorBarra.max)) {
            // ==! Number(maximo)) {
            actualizarMaxDesdeBarra(Number(valorBarra.max));
        }
    }, [valorBarra.min, valorBarra.max])

    return (

        <div className="contenedorFiltros">
            <div className="titulo">
                <span className="h2">Filtros</span>
                {(history.location.pathname.includes("/orden=")
                    || history.location.pathname.includes("/preciomenor=")
                    || history.location.pathname.includes("/preciomayor=")
                    || history.location.pathname.includes("/marca=")
                    || history.location.pathname.includes("/categoria=")
                    || history.location.pathname.includes("/estrellas=")
                    || history.location.pathname.includes("/enviogratis=")
                    || history.location.pathname.includes("/fulfillment=")
                    || history.location.pathname.includes("/descuento=")) &&
                    <p onClick={limpiarFiltros} className="limpiarFiltros"></p>
                }
            </div>

            {/* seccion estrellas ==*/}
            {tieneReview === true
                ? <div className="seccionEstrellas">
                    <div className="cajaEstrellas">
                        <div className="estrellas">
                            <span className="startYellow" id="1"></span>
                            <span className="startYellow" id="2"></span>
                            <span className="startYellow" id="3"></span>
                            <span className="startYellow" id="4"></span>
                            <p>o más</p>
                        </div>

                        <div className="cajaSwitch">
                            <label>
                                <input type="checkbox" className="input" checked={activarEstrellas === true} onChange={handleActivarEstrellas}></input>
                                <div className="toggle">
                                    <span className="selector"></span>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="barra"></div>
                </div>
                : null
            }

            {/* seccion envio gratis ==*/}
            {tieneEnvioGratis === true
                ? <div className="seccionEnvioFiltros">
                    <div className="cajaEnvio">
                        <div className="cajaTexto">
                            <p> ENVÍO GRATIS</p>
                        </div>

                        <div className="cajaSwitch">
                            <label>
                                {/* defaultChecked={activarEnvioGratis} */}
                                <input type="checkbox" className="input" checked={activarEnvioGratis} onChange={handleEnvioGratis}></input>
                                <div className="toggle">
                                    <span className="selector"></span>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="barra"></div>
                </div>
                : null
            }

            {/* seccion express ==*/}
            {tieneFulfillment === true
                ? <div className="seccionFulfillment">
                    <div className="cajaFulfillment">
                        <div className="cajaTexto">
                            <img className="express" src="./img/Express.svg" alt="express"></img>
                        </div>

                        <div className="cajaSwitch">
                            <label>
                                <input type="checkbox" className="input" checked={activarFullfilment} onChange={handleActivarFullfilment}></input>
                                <div className="toggle">
                                    <span className="selector"></span>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="barra"></div>
                </div>
                : null
            }

            {/* seccion categoria */}
            {listaCategorias && listaCategorias.length > 0 && <div className="contentModalCategoria largo contenidoCategorias">
                <div className="seccionSuperior">
                    <span className="h2">Departamentos</span>
                </div>

                <div className="dataModalDescuentos iteracion">
                    <ul onChange={seleccionarCategoria}>
                        {listaCategorias && listaCategorias.map((ca, key) => {
                            return ((verMasCategorias === false
                                ? key <= 4 && <li key={ca.id} >
                                    <label name={ca.id} htmlFor={ca.id}>  {ca.name} </label>
                                    <input
                                        className="radio"
                                        type="checkbox"
                                        value={ca.name}
                                        id={ca.id}
                                        name="categoria"
                                        checked={idCategoria === ca.id ? true : false} />
                                </li>
                                : <li key={ca.id} >
                                    <label name={ca.id} htmlFor={ca.id}>  {ca.name} </label>
                                    <input className="radio" type="checkbox" value={ca.name} id={ca.id} name="categoria"
                                        checked={idCategoria === ca.id ? true : false} />
                                </li>
                            ))
                        }
                        )}
                    </ul>

                    {longitudCategorias > 4 &&
                        <div className="boxVerMasCategorias">
                            {verMasCategorias === false
                                ? <p onClick={VerMasCate} className="textoVerMas"> Ver todas</p>
                                : <p onClick={VerMasCate} className="textoVerMenos"> Ver menos</p>
                            }
                        </div>
                    }
                </div>
            </div>
            }

            {/* seccion marcas */}
            {listaMarcas && listaMarcas.length > 0 && <div className="contentModalMarca largo contenidoCategorias">
                <div className="seccionSuperior">
                    <span className="h2">Marcas</span>
                </div>

                <div className="dataModalDescuentos iteracion">

                    <ul onChange={seleccionarMarca}>
                        {listaMarcas && listaMarcas.map((mar, key) => {
                            return ((verMasMarcas === false
                                ? key <= 4 && mar.marca != "*" &&
                                <li key={mar.marca}>
                                    <label name={mar.marca} htmlFor={mar.marca}> {mar.marca} </label>
                                    <input className="radio" type="checkbox" id={mar.marca} value={mar.marca} name="marca"
                                        checked={checkedItem === mar.marca ? true : false} />
                                </li>
                                : mar.marca != "*" && <li key={mar.marca}>
                                    <label name={mar.marca} htmlFor={mar.marca}> {mar.marca} </label>
                                    <input className="radio" type="checkbox" id={mar.marca} value={mar.marca} name="marca"
                                        checked={checkedItem === mar.marca ? true : false} />
                                </li>
                            ))
                        }
                        )}
                    </ul>
                    {longitudMarcas > 4 &&
                        <div className="boxVerMasMarcas">
                            {verMasMarcas === false
                                ? <p onClick={VerMasMar} className="textoVerMas"> Ver todas</p>
                                : <p onClick={VerMasMar} className="textoVerMenos"> Ver menos</p>
                            }
                        </div>
                    }
                </div>
            </div>
            }

            {/* seccion descuentos */}
            {valorDescuento >= 10 &&
                <div className="contentModalCategoria largo contenidoCategorias">
                    <div className="seccionSuperior">
                        <span className="h2">Descuento</span>
                    </div>

                    <div className="dataModalDescuentos iteracion">
                        <ul onChange={seleccionarDiscount}>
                            {valorDescuento >= 10 &&
                                < li id="discount1">
                                    <label name="d1" htmlFor="d1"> Descuento del 10% o más </label>
                                    <input className="radio" id="d1" type="checkbox" value={Number(10)} name="discount" checked={Number(checkedDiscount) === Number(10) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 20 &&
                                <li id="discount2">
                                    <label name="d2" htmlFor="d2"> Descuento del 20% o más </label>
                                    <input className="radio" id="d2" type="checkbox" value={Number(20)} name="discount" checked={Number(checkedDiscount) === Number(20) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 30 &&
                                <li id="discount3">
                                    <label name="d3" htmlFor="d3"> Descuento del 30% o más </label>
                                    <input className="radio" id="d3" type="checkbox" value={Number(30)} name="discount" checked={Number(checkedDiscount) === Number(30) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 40 &&
                                <li id="discount4">
                                    <label name="d4" htmlFor="d4"> Descuento del 40% o más </label>
                                    <input className="radio" id="d4" type="checkbox" value={Number(40)} name="discount" checked={Number(checkedDiscount) === Number(40) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 50 &&
                                <li id="discount5">
                                    <label name="d5" htmlFor="d5"> Descuento del 50% o más </label>
                                    <input className="radio" id="d5" type="checkbox" value={Number(50)} name="discount" checked={Number(checkedDiscount) === Number(50) ? true : false} />
                                </li>
                            }
                            {valorDescuento >= 60 &&
                                <li id="discount6">
                                    <label name="d6" htmlFor="d6"> Descuento del 60% o más </label>
                                    <input className="radio" id="d6" type="checkbox" value={Number(60)} name="discount" checked={Number(checkedDiscount) === Number(60) ? true : false} />
                                </li>
                            }
                            {Number(valorDescuento) >= 70 &&
                                <li id="discount7">
                                    <label name="d7" htmlFor="d7"> Descuento del 70% o más </label>
                                    <input className="radio" id="d7" type="checkbox" value={Number(70)} name="discount" checked={Number(checkedDiscount) === Number(70) ? true : false} />
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            }
            {valorDescuento <= 0 && <div className="borderTop"></div>}

            {/* seccion precios */}
            <div className="seccionPrecio">
                {(maximo && minimo)
                    ? <div className="texto">
                        <p>Precio</p>
                    </div>
                    : null
                }
                {(maximo && minimo)
                    &&
                    <div className="sliderPrecioDesk">
                        <InputRange
                            maxValue={maximo}
                            minValue={minimo}
                            value={valorBarra}
                            onChange={(e) => cambiarBarraPrecios(e)}
                            step={2}
                        />
                    </div>
                }

                <div className="precios">
                    {precioMenor && Number(precioMenor) !== 0 && minimo != undefined
                        ? <>
                            <label>$</label>
                            <input type="number" placeholder={"Min $" + minimo?.toLocaleString("en-US")} onChange={precioMin} value={Number(precioMenor)} onKeyDown={onKeyDown} pattern="[0-9]{0,13}" />
                        </>
                        : minimo != undefined
                            ? <>
                                <label>$</label>
                                <input placeholder={"Min $" + minimo?.toLocaleString("en-US")} onChange={precioMin} value="" onKeyDown={onKeyDown} pattern="[0-9]{0,13}" />
                            </>
                            : null
                    }
                    {PrecioMax && Number(PrecioMax) !== 0 && maximo != undefined
                        ? <>
                            <label>$</label>
                            <input type="number" placeholder={"Max $" + maximo?.toLocaleString("en-US")} onChange={precioMax} value={PrecioMax} pattern="[0-9]{0,13}" onKeyDown={onKeyDown} />
                        </>
                        : maximo != undefined
                            ? <>
                                <label>$</label>
                                <input placeholder={"Max $" + maximo?.toLocaleString("en-US")} onChange={precioMax} value="" pattern="[0-9]{0,13}" onKeyDown={onKeyDown} />
                            </>
                            : null
                    }

                </div>
            </div>


            {/* boton aplicar filtro precio */}
            <div className="botonFiltrarDes">
                <button className="bFiltrar" onClick={() => filtrarPrecio(precioMenor, PrecioMax)} >Aplicar filtros</button>
            </div>

        </div >
    )
}