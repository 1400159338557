import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';

export function PoliticaCompraDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Compra Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Política de Compra</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <ul className="ulQuestionDisc">
                                    <li>El número de orden que se asigna al realizar la transacción en el sitio de Sears no implica la aceptación de la transacción. En caso de tener algún problema con tu orden te será comunicado por correo electrónico o vía telefónica.</li>
                                    <li>Cualquier situación sobre el surtido y entrega de tu producto, te avisaremos oportunamente.</li>
                                    <li>Es posible que la imagen no corresponda al producto publicado.</li>
                                    <li>Si pagas con depósito referenciado, cuentas con TRES días para realizar tu pago. Haz clic aquí.</li>
                                    <li>En caso de no recibir tu pago, será cancelada tu orden.</li>

                                    <li>“Si pagas con CHEQUE, el pedido será liberado una vez confirmado el pago”.</li>
                                    <li>Los tiempos de entrega varían en función del destino y ocurren en cualquier momento del día.</li>
                                    <li>Es necesario que la persona registrada como destinatario sea quien recibe la mercancía.</li>
                                    <li>Consulta la sección de políticas para ver la mercancía aceptable en devolución.</li>
                                    <li>Para cualquier duda o aclaración de tu orden de compra, llama al: <a href="tel:015553451606">(01-55) 5345 1606</a> o <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a></li>

                                    <li>Para compras con Tarjeta Sears, American Express, Tarjeta de Crédito VISA o Mastercard serás contactado en las próximas 24 horas al número telefónico indicado en tu orden, para validar tu compra.</li>
                                    <li>En el caso de pago con tarjeta Sears, te pedimos tengas a la mano el estado de cuenta para indicarnos el no. de cliente internet así verificamos la autenticidad del titular.</li>
                                    <li>En el caso del pago con Tarjeta AMEX, VISA y Mastercard te pedidos contactes a tu banco y solicites el no. de transacción con la que se registró el pago de tu pedido y enviarlo a la siguiente dirección de correo autorizaciones@sears.com.mx</li>
                                    <li>En casos de preventas, se cobra de inmediato tu orden y se generará el envío al momento de surtirse el producto.</li>
                                    <li>En ciertas compras, Sears se reserva el derecho de realizar solicitud adicional de documentos y firmas.· En Mesa de Regalos la entrega de regalos está configurada de acuerdo a las especificaciones de los Festejados. Nos reservamos el derecho de utilizar la envoltura más adecuada para asegurar la entrega del regalo en óptimas condiciones.</li>
                                    <li>Los precios y promociones aplican solo en la compra en Sears.com.mx</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function PoliticaCompraMobile() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Compra Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Política de compra</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <ul className="ulDeclaracionesDisc">
                                    <li>El número de orden que se asigna al realizar la transacción en el sitio de Internet de Sears no implica la aceptación de la transacción. En caso de tener algún problema con tu orden te será comunicado por correo electrónico o vía telefónica.</li>
                                    <li>Cualquier situación sobre el surtido y entrega de tu producto, te avisaremos oportunamente.</li>
                                    <li>Es posible que la imagen no corresponda al producto publicado.</li>
                                    <li>Si pagas con depósito referenciado, cuentas con TRES días para realizar tu pago. Haz clic aquí.</li>
                                    <li>En caso de no recibir tu pago, será cancelada tu orden.</li>

                                    <li>“Si pagas con CHEQUE, el pedido será liberado una vez confirmado el pago”.</li>
                                    <li>Los tiempos de entrega varían en función del destino y ocurren en cualquier momento del día.</li>
                                    <li>Es necesario que la persona registrada como destinatario sea quien recibe la mercancía.</li>
                                    <li>Consulta la sección de políticas para ver la mercancía aceptable en devolución.</li>
                                    <li>Para cualquier duda o aclaración de tu orden de compra, llama al: <a href="tel:015553451606">(01-55) 5345 1606</a> o <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a></li>

                                    <li>Para compras con Tarjeta Sears, American Express, Tarjeta de Crédito VISA o Mastercard serás contactado en las próximas 24 horas al número telefónico indicado en tu orden, para validar tu compra.</li>
                                    <li>En el caso de pago con tarjeta Sears, te pedimos tengas a la mano el estado de cuenta para indicarnos el no. de cliente internet así verificamos la autenticidad del titular.</li>
                                    <li>En el caso del pago con Tarjeta AMEX, VISA y Mastercard te pedidos contactes a tu banco y solicites el no. de transacción con la que se registró el pago de tu pedido y enviarlo a la siguiente dirección de correo autorizaciones@sears.com.mx</li>
                                    <li>En casos de preventas, se cobra de inmediato tu orden y se generará el envío al momento de surtirse el producto.</li>
                                    <li>En ciertas compras, Sears se reserva el derecho de realizar solicitud adicional de documentos y firmas.· En Mesa de Regalos la entrega de regalos está configurada de acuerdo a las especificaciones de los Festejados. Nos reservamos el derecho de utilizar la envoltura más adecuada para asegurar la entrega del regalo en óptimas condiciones.</li>
                                    <li>Los precios y promociones aplican solo en la compra en Sears.com.mx</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export function PoliticaCompra() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Compra');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <PoliticaCompraDesktop />

                : isMobile === 'movile'
                    ? null
                    : <PoliticaCompraMobile />
            }
        </>
    )
}