import React from 'react';
import './style.sass';
import ReactDOM from 'react-dom';

function Modal({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        genero,
        arreglo,
        isMobile 
    } = props

    return (
        <div className={`modal ${type}`}>
            <div className={isMobile ===  "desktop"? "contentModal modalTallas": "contentModal modalTallasM"}>
                <div className={type === "full" ? "contentCloseTallas" : "contentCloseTallas"}>
                    <div className={type === "full" ? "btn closeX" : "btn closeX"} onClick={onClose}></div>
                    {title
                        ? <p className="title">{title}</p>
                        : null
                    }
                </div>

                <div className="dataModalTallas">
                    <h2>Guía de tallas de {genero}</h2>

                    <div className="formasPago">
                        <p>Medidas del Producto</p>
                    </div>

                    <table className="tableTallas">
                        <thead>
                            <tr className="">
                                <th className="li2">Talla</th>
                                <th className="li2">US</th>
                                <th className="li2">Europa</th>
                                <th className="li2">Largo del pie (cm)</th>
                            </tr>
                        </thead>

                        <tbody>
                            {arreglo.map((talla, id) =>
                                <tr key={talla.id}>
                                    <th className="thMx">{talla.mx}</th>
                                    <td>{talla.us}</td>
                                    <td>{talla.eu}</td>
                                    <td>{talla.largo}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}


export default function ModalTallas({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        genero,
        arreglo,
        isMobile 
    } = props

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} genero={genero} arreglo={arreglo} isMobile ={isMobile }>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}