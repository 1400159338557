import React, { useState, useEffect } from 'react';
import './style.sass';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
// import { BASE_PATH_PORTAL, PORTAL_URL } from 'constants/general';
import { useIsMobile } from 'hooks/useIsMobile';
import ReactDOM from 'react-dom';

export default function ModuleCreditCardPromotion(props) {

    const {
        credit_card_months
    } = props;

    const history = useHistory();
    // const location = useLocation();
    const prevUrl = document.referrer;
    const urlLink = window.location.href;
    const hashLink = window.location.hash;
    const { isMobile } = useIsMobile();

    const [showModal, SetShowModal] = useState(false);
    const showCreditCardData = () => {
        SetShowModal(true);
    }
    const handleClose = () => {
        history.goBack();
        // history.push(location.pathname)
    }
    useEffect(() => {
        //console.log('referer: ', prevUrl)
        if (prevUrl === "") {
            //history.push(location.pathname)
            //console.log(history)
            if (hashLink === "#modalCreditCard") {
                showCreditCardData();
            } else {
                SetShowModal(false);
            }
        } else {
            // console.log('referer is in else: ', prevUrl)
            // console.log('hashLink is in else: ', hashLink)
            if (hashLink === "#modalCreditCard") {
                showCreditCardData();
            } else {
                SetShowModal(false);
            }
        }
    }, [hashLink, urlLink])

    return (
        <div className={isMobile === "desktop"? "moduleCreditCardPromo creditBottom": "moduleCreditCardPromo creditSoloTop2"}>
            <Link to={"#modalCreditCard"}
                className="lastMonthCreditCard"
                onClick={showCreditCardData}
            >
                <img src="/img/creditCard2.svg" alt="Logo Tarjetas de Credito" loading="lazy" width="35" height="26" />
                <p> Ver promociones de tarjetas bancarias
                    {/* {credit_card_months &&
                    credit_card_months[credit_card_months.length - 1].months} x <CurrencyFormat value={credit_card_months[credit_card_months.length - 1].price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} /> */}
                </p>
            </Link>

            {showModal &&
                <ModalPortalCardPromotion onClose={handleClose} isMobile={isMobile} type={isMobile === "desktop" ? "" : "full"} title={isMobile === "desktop" ? "Promociones tarjetas bancarias" : "Promociones bancarias"}>
                    <div className="textEspecificacion dataModal11">
                        <div className="formasPagocopy">
                            {isMobile && isMobile === "desktop"
                                ? <p>Otras tarjetas</p>
                                : <p>Tarjetas bancarias</p>
                            }
                            {/* <img src={`/img/creditoAmex_40px.png`} loading="lazy" alt="formas de pago" /> */}
                        </div>

                        <ul className="ultarjetasmodal">
                            {credit_card_months?.map((datos, i) =>
                                    <li key={i}>
                                        {datos.months} {datos?.months === 1 ? <>Mes de </> : <>Meses de </>}
                                        <CurrencyFormat
                                            decimalScale={2}
                                            FixedDecimalScale={true} 
                                            value={datos?.price % 1 === 0 ? datos?.price + '.001' : datos?.price} 
                                            displayType={'text'} 
                                            thousandSeparator={true} 
                                            prefix={'$'} 
                                            renderText={value => <span>{value}*</span>}>
                                        </CurrencyFormat>
                                    </li>
                                )
                            }
                        </ul>

                        {/* <p className="costos">*Más costos de envío en pedidos menores a $499.00</p> */}

                        {/* <a href={`${PORTAL_URL}/c/claroPromo/`} className="terminos">Consultar lineamientos</a> */}
                    </div>
                </ModalPortalCardPromotion>
            }
        </div>
    )
}

function Modal({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        isMobile
    } = props;

    return (
        <div className={`modal ${type}`}>
            <div className={isMobile === "desktop" ? "contentModal modalTallas" : "contentModal modalTallasM"}>
                <div className={type === "full" ? "contentCloseTallas" : "contentCloseTallas"}>
                    <div className={type === "full" ? "btn closeX" : "btn closeX"} onClick={onClose}></div>
                    {title
                        ? <p className="title">{title}</p>
                        : null
                    }
                </div>

                <div className="dataModalTallas dataModal11">
                    {children}
                </div>
            </div>
        </div>
    )
}


export function ModalPortalCardPromotion({ ...props }) {
    const {
        children,
        onClose,
        type,
        closeBody,
        title,
        isMobile
    } = props;

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} closeBody={closeBody} isMobile={isMobile}>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}