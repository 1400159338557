import React, { Fragment, useState, useEffect } from 'react';
import './style.sass';
import StarsFive from 'components/StarsFive';
import ModalReviews from 'components/ProductDetail/Reviews/ModalReviews';
import ModalStars from 'components/Modal';
import ModalFiltros from 'components/ProductDetail/Reviews/ModalReviews/modalFiltros';
import { Link, useHistory, useLocation } from 'react-router-dom';
import StarsPrincipal from 'components/StarsFive/starsPrincipal';
import ModalOrdenamiento from '../ModalReviews/modalOrdenamiento';

export default function ModuleReviews({ response }) {
    const { bottomline, reviews } = response;
    const { average_score, total_review, star_distribution } = bottomline;

    const history = useHistory();
    const location = useLocation();
    const urlLink = window.location.href;
    const hashLink = window.location.hash;

    // mostrar modal de reviews
    const [showModal, SetShowModal] = useState(false);
    const showStars = () => {
        SetShowModal(true);
    };

    // cerrar modal de reviews
    const handleClose = () => {
        // history.push(location.pathname);
        history.goBack(-1);
    };

    // detecta si el modal esta abierto mediante url
    useEffect(() => {
        if (hashLink === "#modalStarsProduct") {
            showStars();
        } else {
            SetShowModal(false);
        }
    }, [hashLink, urlLink]);

    return (
        <div className="moduleOpinion" >
            <Link to={'#modalStarsProduct'} className="moduleClientOpinion" onClick={showStars}>
                <p className="h3">Opiniones de los clientes</p>
                <div className="scoreGeneral">
                    <div className="promedioTotal">
                        {Number.parseFloat(average_score).toFixed(1)}
                    </div>
                    <div className="rankGeneral">
                        <StarsPrincipal score={average_score} />
                        <div className="promedy">Promedio de {total_review} calificaciones</div>
                    </div>
                </div>

                <div className="contentCardOpinion">
                    {reviews.map(({ score, title, content, images_data, videos_data }, ind) =>
                        <Fragment key={ind}>
                            {ind < 0
                                ?
                                <div className="cardReviewsStars">
                                    <StarsFive score={score} />
                                    <p className="h3">{title}</p>
                                    <p>{content}</p>
                                    <div className="contImageReviews">
                                        {videos_data && videos_data.map(image =>
                                            <img src={image.thumbnail_url} alt={image.id} key={image.id} />
                                        )}

                                        {images_data && images_data.map(image =>
                                            <img src={image.thumb_url} alt={image.id} key={image.id} />
                                        )}
                                    </div>
                                </div>
                                : null
                            }
                        </Fragment>
                    )
                    }
                </div>
            </Link>

            {showModal && <ModalStars onClose={handleClose} type="full" title="Opiniones de los clientes">
                <ModalReviews
                    reviews={reviews}
                    star_distribution={star_distribution}
                    total_review={total_review}
                    average_score={average_score}
                    nombre={response.products[0].name}
                />
            </ModalStars>
            }

        </div>
    )
}

export function ModuleReviewsDesktop({ response }) {
    const { bottomline, reviews } = response;
    const { average_score, total_review, star_distribution } = bottomline;

    const [ordenamiento, setOrdenamiento] = useState(3);
    const [ordenado, setOrdenado] = useState([]);

    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [valorRadioButton, setValorRadioButton] = useState(0);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
    }, [reviews, bottomline]);

    // cierra seccion filtros estrellas
    const cerrar = () => {
        setMostrarFiltros(false);
    };

    // establece orden seleccionado
    const handleOrdenamiento = (valor) => {
        setOrdenamiento(Number(valor.target.value));
        ascendente(valor.target.value);
    };

    // abre modal mostrar filtros reviews
    const handleMostrarFiltros = () => {
        setMostrarFiltros(!mostrarFiltros);
    };

    function RankingStart({ cantStar, total, position }) {

        const promedyPerStar = (totalStar, cantStar) => {
            const total = Math.round((cantStar / totalStar) * 100);
            return total;
        };

        const progressBar = promedyPerStar(total, cantStar);

        return (
            <div className="promedyRankStars">
                <div className="rankS">{position}</div>
                <div className="barraProgres">
                    <span style={{ width: progressBar + '%' }}></span>
                </div>
                <div className="totalPerStar">{cantStar}</div>
            </div>
        )
    }

    function VoteReview({ idUser, voteType, voteCant }) {
        const [votes, setVotes] = useState(voteCant);

        const voteReviewClick = () => {
            var reviewsLocalS = window.localStorage.getItem(`review-${idUser}`);

            if (reviewsLocalS) {
                if (reviewsLocalS === 'up') {
                    if (votes !== 0)
                        setVotes(votes - 1);
                    window.localStorage.removeItem(`review-${idUser}`);
                }
                else if (reviewsLocalS === 'down') {
                    if (votes !== 0)
                        setVotes(votes - 1);
                    window.localStorage.removeItem(`review-${idUser}`);
                }
            } else {
                window.localStorage.setItem(`review-${idUser}`, voteType);
                setVotes(votes + 1);
            }
        }

        return (
            <span onClick={voteReviewClick} className={voteType === 'up' ? 'votesUp' : 'votesDown'}>{votes}</span>
        )
    }

    // funcionamiento para ordenamiento
    const [mostrarOrdenamiento, setMostrarOrdenamiento] = useState(false)
    const [valorRadioButtonOrdenamiento, setValorRadioButtonOrdenamiento] = useState(0)
    // cierra modal de orden
    const cerrarOrdenamiento = () => {
        setMostrarOrdenamiento(false);
    }
    // activa modal orden
    const handleMostrarOrdenamiento = () => {
        setMostrarOrdenamiento(!mostrarOrdenamiento);
    }
    // recibe seleccion de radio modal
    const envioRadioButtonOrdenamiento = (valor) => {
        setValorRadioButtonOrdenamiento(Number(valor));
        setOrdenamiento(Number(valor));
        ascendente(valor);
    }

    function ascendente(ordena) {
        if (Number(ordena) === 5) {//mas recientes
            setOrdenado(reviews.sort(((a, b) => new Date(b.created_at.slice(0, -14)) - new Date(a.created_at.slice(0, -14)))));
        }
        if (Number(ordena) === 4) { // mas antiguos
            setOrdenado(reviews.sort(((a, b) => new Date(a.created_at.slice(0, -14)) - new Date(b.created_at.slice(0, -14)))));
        }
        if (Number(ordena) === 3) { //mayor a menor
            setOrdenado(reviews.sort(((a, b) => b.score - a.score)));
        }
        if (Number(ordena) === 2) { //menor a mayor
            setOrdenado(reviews.sort(((a, b) => a.score - b.score)));
        }
    }

    const envioRadioButton = (valor) => {
        setValorRadioButton(Number(valor));
    };

    const filtrado = () => {
        if (Number(valorRadioButton) != 0) {
            setOrdenado(reviews.filter(estrellas => String(estrellas.score)?.indexOf(String(valorRadioButton)) === 0));
        }
    };

    useEffect(() => {
        ascendente(3);
    }, [reviews, bottomline]);

    useEffect(() => {
        filtrado();
    }, [valorRadioButton]);

    const urlLink = location.href;
    const hashLink = location.hash;

    useEffect(() => {
        if (String(hashLink) === "#modalStarsProduct") {
            colocatePositionAnchor();
        }
    }, [hashLink, urlLink]);

    const colocatePositionAnchor = () => {
        let positionOpinion = document.getElementById('modalStarsProduct').getBoundingClientRect().y;
        window.scrollTo(0, positionOpinion);
        setTimeout(function () {
            history.push(location.pathname);
        }, 300)
    };

    return (
        <>
            <div className="moduleOpinionDesk" >
                <h2>Opiniones de los clientes</h2>
                <span id="modalStarsProduct" name="modalStarsProduct" className="tagLabelNavigate"></span>
                <div className="promedyRank">
                    <div className="prom">
                        <div className="promedioTotal">{Number.parseFloat(average_score).toFixed(1)}</div>
                        <div className="boxOpiniones">
                            <StarsPrincipal score={average_score} />
                            <div className="promedy"> {total_review} {total_review === 1 ? <>opinión</> : <>opiniones</>}</div>
                        </div>
                    </div>



                    {star_distribution &&
                        Object.entries(star_distribution).reverse().map(
                            ([key, value]) => {
                                return (
                                    <RankingStart key={key} cantStar={value} total={total_review} position={key} />
                                )
                            })
                    }
                </div>

                <div className="contentCardOpinionDesktop">

                    <div className="boxOrdenarFiltros">
                        <div className="boxof">
                            <div className="ordenar">
                                <label>Ordenar por:</label>
                                <p onClick={handleMostrarOrdenamiento}>
                                    {ordenamiento === 5
                                        ? "Más recientes"
                                        : ordenamiento === 4
                                            ? "Más antiguas"
                                            : ordenamiento === 3
                                                ? "Mayor valoración"
                                                : ordenamiento === 2
                                                    ? "Menor valoración"
                                                    : null
                                    }
                                </p>
                                {/* <select name="ordenamiento" defaultValue="3" onChange={handleOrdenamiento}>
                                    <option value="1" name="1">Más recientes</option>
                                    <option value="2" name="2">Más antiguos</option>
                                    <option value="3" name="3">Mayor valoración</option>
                                    <option value="4" name="4">Menor valoración</option>
                                </select> */}
                                {mostrarOrdenamiento === true && <ModalOrdenamiento onClose={mostrarOrdenamiento} type="" title="" ordenamiento={ordenamiento} cerrar={cerrarOrdenamiento} envioRadioButton={envioRadioButtonOrdenamiento} />}
                            </div>
                            <div className="filtros">
                                <p onClick={handleMostrarFiltros}>Filtros {valorRadioButton != 0 ? <>(1)</> : <>(0)</>}</p>
                                {mostrarFiltros === true && <ModalFiltros onClose={mostrarFiltros} type="" title="" cerrar={cerrar} envioRadioButton={envioRadioButton} />}
                            </div>
                        </div>
                    </div>
                    <div className="contentCardOpinion">

                        {ordenado.map(({ id, user, score, title, verified_buyer, content, created_at, votes_up, votes_down, videos_data, images_data }, ind) =>
                            <div key={ind} className="cardReviewsDetailsStars">
                                <div className="textPrincipalCard">
                                    <div className="firstLetter">{user.display_name.toUpperCase().split('')[0]}</div>
                                    <div className="nameBuyer">
                                        <p className="h3">{user.display_name}
                                            {verified_buyer
                                                ? <span className="verifiedBuyer">Compra Verificada</span>
                                                : null
                                            }
                                        </p>
                                        <StarsFive score={score} />
                                    </div>
                                </div>
                                <div className="titleDescription">
                                    <p className="h3">{title}</p>
                                    <p>{content}</p>
                                    <div className="contImageReviews">
                                        {videos_data && videos_data.map(image =>
                                            <img key={image.id} src={image.thumbnail_url} alt={image.id} />
                                        )}

                                        {images_data && images_data.map(image =>
                                            <img key={image.id} src={image.thumb_url} alt={image.id} />
                                        )}
                                    </div>
                                </div>
                                <div className="dateVotes">
                                    <p>{created_at.split('T')[0]}</p>
                                    <VoteReview idUser={id} voteType='up' voteCant={votes_up} />
                                    <VoteReview idUser={id} voteType='down' voteCant={votes_down} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>
    )
}