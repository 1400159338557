import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';
import ModalInstalacion from './modalServicioInstalacion';
import './style.sass';

export default function ModuleServicioInstalacion({ ...props }) {
    const { skuProducto } = props;

    const history = useHistory();
    const location = useLocation();
    const prevUrl = document.referrer;
    const urlLink = window.location.href;
    const hashLink = window.location.hash;
    const { isMobile } = useIsMobile();

    const [showModal, SetShowModal] = useState(false);

    const showModalServicioInstalacion = () => {
        SetShowModal(true);
    };

    const handleClose = () => {
        history.goBack(-1);
        // history.push(location.pathname);
    };


    useEffect(() => {
        if (prevUrl === "") {
            if (hashLink === "#modalServicioInstalacion") {
                showModalServicioInstalacion();
            } else {
                SetShowModal(false);
            }
        } else {
            if (hashLink === "#modalServicioInstalacion") {
                showModalServicioInstalacion();
            } else {
                SetShowModal(false);
            }
        }
    }, [hashLink, urlLink])


    return (
        <>
            <Link to={"#modalServicioInstalacion"} className="btnInstalacion" id="btnInstalacion" onClick={showModalServicioInstalacion}>
                <img src="/img/servicio-tecnico.png" alt="Logo-instalacion" loading="lazy" width="35" height="26" />
                <p>Servicio de instalación gratis</p>
            </Link>

            {showModal && <ModalInstalacion onClose={handleClose} type={isMobile === "desktop" ? "" : "full"} title={isMobile === "desktop" ? "Servicio Instalación" : "Servicio de Instalación"} isMobile={isMobile}/>}
        </>
    )
}