import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import Loading from 'components/Loading'
import Header from 'pages/Header/HeaderNavegacion'
import Footer from 'pages/Footer/FooterDesktop'
import FormAddress from 'components/Address/FormAddress'
import { postAddress } from 'services/Address'

import { decodeStorage } from 'functions/storageBase64'
import {useValidateSession} from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';

export default function CrearDireccion () {
    
    //- Codigo de instana para track de pagina 
    useEffect(()=>{
        window.ineum('page', 'My Account | Create Address Page');
        window.scrollTo(0, 0)
    },[])

    const history = useHistory()
    const [ loading, setLoading ] = useState(true)
    const [ returnDataForm, setReturnDataForm ] = useState()
    const [ succesOK, setSuccessOk ] = useState()


    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession } = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "CrearDreccion", timeRef: 1500})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */
    
    // funcion que manda la direccion al servicio
    useEffect(()=>{
        if(returnDataForm){
            checkValidateSession(true)
            setActionOk('postAddress')
        }
    },[returnDataForm])
    
    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS || Date.now() <= decodeStorage('access_token_exp') * 1000 ){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            if(actionOK === 'postAddress'){
                setServiceRT(false)
                setActionOk(false)

                postAddress(returnDataForm)
                .then(setSuccessOk)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */
    
    
    // redireccion una ves que el sevicio responde  
    useEffect(()=>{
        if(succesOK){
            history.replace('/mi-cuenta/direcciones-envio/')
            setSuccessOk(false)
        }
        setLoading(false)
    },[succesOK])

    // funcion que retorna la direccion del formulario
    function successForm(val){
        setReturnDataForm(val)
    }
   	//recibe la direccion del CP    
       const [updateCPBar, setUpdateCPBar] = useState()
       const changeDirectionBar = (val) => {
           setUpdateCPBar(val)
       }
       const [upCP, setUpCP] = useState()
       useEffect(()=>{
           if(upCP){
               //console.log('')
           }
       },[upCP,updateCPBar])

       
    // recibe el id de la direccion predeterminada
    function changeDefaultA(val) {
    }


    return(
        <>
             {loading
                ? <Loading />
                : null
            }
            <Header setUpCP={setUpCP}  changeDirectionBar={changeDirectionBar}/>
            <main>
                <div className="container formDirections">
                    <FormAddress
                        accesibilidad={true}
                        success={successForm}
                        changeDefaultA={changeDefaultA}
                        longitudDirecciones={false}
                    />
                </div>
            </main>
            <Footer />
        </>
    )
}