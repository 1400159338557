import React, { useEffect, useState } from 'react'
import Header from 'pages/Header/HeaderSimple'
import { useHistory, useLocation } from 'react-router-dom'
import Loading from 'components/Loading'
import { useStorage64 } from 'hooks/useStorageBase64'
import { useIsMobile } from 'hooks/useIsMobile'
import UpdatePassword from 'pages/LoginRegistro/UpdatePassword'
import RecoveryPassword from 'pages/LoginRegistro/RecoveryPassword'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'

export default function Password() {

    const { isMobile } = useIsMobile()
    const [isLoged] = useStorage64('session_token', '')
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const location = useLocation()


    //- Codigo de instana para track de pagina
    const [authToken, setAuthToken] = useState(null)
    const [updatePage, setUpdatePage] = useState(false)
    useEffect(() => {
        if(location.search){
            if(location.search?.indexOf('?auth=') >= 0){
                let auth = location.search.split('=')
                window.ineum('page', 'Update Password Page');
                window.scrollTo(0, 0)
                console.log('location1: ', auth[1])
                setAuthToken(auth[1])
            }
        }else{
            window.ineum('page', 'Recovery Password Page');
            window.scrollTo(0, 0)
            console.log('location2: ', location.search)
            setAuthToken(null)
            setUpdatePage(false)
        }
    }, [updatePage])


    // valida si tienes sesion activa y regresa 1 al historico
    useEffect(() => {
        setLoading(false)
    }, [isLoged])

    const desktopView = () => document.querySelector('#root').classList.add('desktopView')

    useEffect(() => {
        if (isMobile) {
            if (isMobile === 'desktop') {
                document.querySelector('#root').classList.add('desktopView')
            }
        }
    }, [isMobile])

    return (
        <>
            { loading && <Loading />}
            <ErrorBoundary>
                <Header pageClass={isMobile !== 'desktop' ? "hidden" : "registroHeader"}/>
            </ErrorBoundary>
            <main>
                <section className="loginRegistroPage">
                    <div className="container">
                        {!authToken
                            ? <RecoveryPassword />
                            : <UpdatePassword tokenUpdate={authToken} setUpdatePage={setUpdatePage}/>
                        }
                    </div>
                </section>
            </main>
        </>
    )
}