import {BASE_PATH_API, TIMEOUT_TIME, STAGE_DIRNAME, BASE_PATH_API_DEV, VARIANTS_PER_PORTAL} from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'

export default async function getUserProfile (){
    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");
    let apiURL = null

    if(STAGE_DIRNAME === "dev"){
        //apiURL = `${BASE_PATH_API_DEV.userProfile}/app/v1/user-info?token=${isLoged}`
        apiURL = `${BASE_PATH_API_DEV.userProfile}/app/v1/user-info`
    }else{
        //apiURL = `${BASE_PATH_API}/app/v1/user-info?token=${isLoged}`
        apiURL = `${BASE_PATH_API}/app/v1/user-info`
    }
    
    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        let errorMsg = "Network Error"
        if(error.request.status !== 0){
            errorMsg = error.response.data.msg
        }
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': errorMsg
        }
    })
}



export async function getDeleteAccount(request){

    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");
    let apiURL = null

    if(STAGE_DIRNAME === "dev"){
        //apiURL = `${BASE_PATH_API_DEV.userProfile}/app/v1/user-info?token=${isLoged}`
        apiURL = `${BASE_PATH_API_DEV.deleteAccount}/app/v1/delete-account`
    }else{
        //apiURL = `${BASE_PATH_API}/app/v1/user-info?token=${isLoged}`
        apiURL = `${BASE_PATH_API}/app/v1/delete-account`
    }

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: request
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        let errorMsg = "Network Error"
        if(error.request.status !== 0){
            errorMsg = error.response.data.msg
        }
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': errorMsg
        }
    })
}