import React, { useState, useEffect } from 'react';
import './style.sass';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BASE_PATH_PORTAL } from './../../../constants/general';
// import ModalWa from 'components/Modal';
import { useIsMobile } from 'hooks/useIsMobile';
import ReactDOM from 'react-dom'

export default function ModuleWarrantyProduct(props) {

    const {
        warrantyDetails
    } = props;

    const history = useHistory();
    const location = useLocation();
    const urlLink = window.location.href;
    const hashLink = window.location.hash;
    const { isMobile } = useIsMobile();

    const [showModal, SetShowModal] = useState(false);
    const showWarranty = () => {
        SetShowModal(true);
    };

    const handleClose = () => {
        history.push(location.pathname);
    };

    useEffect(() => {
        if (hashLink === "#modalWarranty") {
            showWarranty();
        } else {
            SetShowModal(false);
        }
    }, [hashLink, urlLink]);

    useEffect(() => {
        if (hashLink === "#modalWarranty") {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        }
        else {
            document.getElementsByTagName("body")[0].style.overflow = "auto"
        }
    }, [hashLink]);

    return (
        <>
            <div className={isMobile === "desktop" ? "warranty" : "warrantyMobile"}>
                <Link to={"#modalWarranty"} className="warran">
                    <div className="warrantyL">
                        <img src="/img/garantiaRed.png" alt="logo-Garantia" width="40" height="40"/>
                    </div>

                    <div className="warrantyR" onClick={showWarranty}>
                        <p className="titulo">Garantía</p>
                        <ul className="opciones">

                            {warrantyDetails[0]?.seller &&
                                <li>Vendedor: <span>{warrantyDetails[0]?.seller?.time}</span></li>
                            }

                            {warrantyDetails[0]?.manufacturer &&
                                <li>Fabricante: <span>{warrantyDetails[0]?.manufacturer?.time}</span></li>
                            }
                            {warrantyDetails[1]?.manufacturer &&
                                <li>Fabricante: <span>{warrantyDetails[1]?.manufacturer?.time}</span></li>
                            }

                        </ul>
                    </div>
                </Link>

                {showModal &&
                    <ModalWa opciones={warrantyDetails[0]} onClose={handleClose} type={isMobile === "desktop" ? "" : "full"} title={isMobile === "desktop" ? "Garantía" : "Garantía"}>
                        <div className={isMobile === "desktop" ? "textEspecificacion dataModalWarrenty" : "textEspecificacion dataModalWarrentyMobile"}>
                            <div className="warrantyR">

                                <ul className="opciones">
                                    {warrantyDetails[0]?.seller &&
                                        <li>Garantía con vendedor: {warrantyDetails[0]?.seller?.time}</li>
                                    }

                                    {warrantyDetails[0]?.manufacturer &&
                                        <li>Garantía con fabricante: {warrantyDetails[0]?.manufacturer?.time}</li>
                                    }
                                    
                                    {warrantyDetails[1]?.manufacturer &&
                                        <li>Garantía con fabricante: {warrantyDetails[1]?.manufacturer?.time}</li>
                                    }

                                </ul>

                            </div>
                            <div className="condiciones">
                                <p className="titulo">Condiciones</p>

                                <ul className="ulCondiciones">
                                    <li>El producto no debe presentar golpes o alteraciones de ningún tipo.</li>
                                    <li>El producto debe ser devuelto en el mismo estado en que lo recibiste.</li>
                                    <li>Empaca tu devolución de forma segura.</li>
                                </ul>

                                <div className="pPoliticas">
                                    <p>*Conoce más acerca de las <Link to="/politica-devolucion/" className="enlaceDevoluciones"> Políticas de Garantía</Link></p>

                                </div>
                            </div>
                        </div>
                    </ModalWa>
                }
            </div >
        </>
    )
}

function Modal({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        isMobile
    } = props

    return (
        <div className={`modal ${type}`}>
            <div className={isMobile === "desktop" ? "contentModal modalTallas" : "contentModal modalTallasM"}>
                <div className={type === "full" ? "contentCloseTallas" : "contentCloseTallas"}>
                    <div className={type === "full" ? "btn closeX" : "btn closeX"} onClick={onClose}></div>
                    {title
                        ? <p className="title">{title}</p>
                        : null
                    }
                </div>

                <div className="dataModalTallas">
                    {children}
                </div>
            </div>
        </div>
    )
}


export function ModalWa({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        isMobile
    } = props

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} isMobile={isMobile}>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}