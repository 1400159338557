import React, { useEffect, useState } from 'react';
import BarFilter from '../../components/BarFilter/index';
// import { useStorage64 } from 'hooks/useStorageBase64';
import { removeDuplicates } from 'functions/removeDuplicates';

export default function Filtros({ valorDescuento, minimo, maximo, seEnviaVista, searchResults, searchParams, params, setSearchParams, history, results, primeraParte, marcaP, tieneEnvioGratis, tieneFulfillment, tieneReview }) {

    const [pagina, setPagina] = useState(1);
    const [isShowing, setIsShowing] = useState(false); //mostrar modal filtros
    const [flagFiltar, setFlagFiltrar] = useState(false); // activar filtros seleccionados

    const [parametroOrdenar, setParametroOrdenar] = useState(''); // filtro ordenar asc / desc
    const [id, setId] = useState(0); // id seleccionado al presionar asc / desc
    const [valor, setValor] = useState(0);

    const [preciomenor, setPrecioMenor] = useState(0); //filtro precio menor
    const [PrecioMax, setPrecioMax] = useState(0); // filtro precio mayor

    const [activarEstrellas, setActivarEstrellas] = useState(false); // filtro por estrellas
    const [activarEnvioGratis, setActivarEnvioGratis] = useState(false); // filtro envio gratis
    const [activarFullfilment, setActivarFullfilment] = useState(false); // filtro por fullfilment

    const [discountVisible, setDiscountVisible] = useState(false); // mostrar modal discount
    const [checkedDiscount, setCheckedDiscount] = useState(0); // establecer descuento
    const [discountSeleccionado, setDiscountSeleccionado] = useState(0); // filtro descuento seleccionado 

    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(0); // filtro por categoria
    const [categoriaVisible, setCategoriaVisible] = useState(false); // mostar modal categoria
    const [categoria, setCategoria] = useState(''); // nombre de la categoria seleccionada
    const [idCategoria, setIdCategoria] = useState(0); // id de la categoria seleccionada

    const [marcaSeleccionada, setMarcaSeleccionada] = useState(''); // filtro por marca
    const [marcasVisible, setMarcasVisible] = useState(false); // mostrar modal marca
    const [checkedItem, setCheckedItem] = useState(null); // establecer marca 

    // convertir a  binario estrellas, enviogratis y fulfillment
    const [binarioEstrellas, setBinarioEstrellas] = useState(0);
    const [binarioEnvioGratis, setBinarioEnvioGratis] = useState(0);
    const [binarioFulfillment, setBinarioFulfillment] = useState(0);

    const [vistaMosaico, setVistaMosaico] = useState(true);
    const [vistaLista, setVistaLista] = useState(false);

    // const [listaSeleccionada, setListaSeleccionada] = useStorage64(vistaMosaico);
    const [flagFiltrarFulfillment,] = useState(false);
    const [anterior, setAnterior] = useState(false);

    const handleChangeExpress = () => {
        setAnterior(activarFullfilment);
        setActivarFullfilment(!activarFullfilment);
    }

    const handleActivarFullfilment = () => {
        setActivarFullfilment(!activarFullfilment);
    }

    useEffect(() => {
        if (anterior !== activarFullfilment) {
            filtrarExpress(activarFullfilment);
        }
    }, [anterior])

    function filtrarExpress(activar) {
        if (activar === true) {
            setActivarFullfilment(true);
            setBinarioFulfillment(1);

        } else {
            setActivarFullfilment(false);
            setBinarioFulfillment(0);
        }
        setFlagFiltrar(true);
    }

    const handleMoscaico = () => {
        setVistaMosaico(true);
        seEnviaVista(vistaMosaico);
    }

    const handleLista = () => {
        setVistaMosaico(false);
        seEnviaVista(vistaMosaico);
    }

    // activar/ desactivar filtro estrellas
    const HandleActivarEstrellas = () => {
        setActivarEstrellas(!activarEstrellas);
    }

    // activar / desactivar filtro envio gratis
    const handleEnvioGratis = () => {
        setActivarEnvioGratis(!activarEnvioGratis);

        if (activarEnvioGratis)
            setBinarioEnvioGratis(1);
        else
            setBinarioEnvioGratis(0);
    }

    // parametro recibido al presionar boton ordenar asc/ desc
    function activarOrdenar(e) {
        setId(e.target.id);
    }

    // funcion para activar el filtrado
    function filtrar(categoriaP2, checkedItemP2, estrellas, envioGratis, descuento, orden, vistaM, fulfillment) {

        if (Number(categoriaP2) !== 0) {
            setCategoriaSeleccionada(categoriaP2);
        } else {
            setCategoriaSeleccionada(0);
        }

        if (checkedItemP2 != null) {
            setMarcaSeleccionada(checkedItemP2);
        } else {
            setMarcaSeleccionada('');
        }

        if (estrellas === true) {
            setActivarEstrellas(true);
            setBinarioEstrellas(1);
        } else {
            setActivarEstrellas(false);
            setBinarioEstrellas(0);
        }

        if (envioGratis === true) {
            setActivarEnvioGratis(true);
            setBinarioEnvioGratis(1);
        } else {
            setActivarEnvioGratis(false);
            setBinarioEnvioGratis(0);
        }

        if (fulfillment === true) {
            setActivarFullfilment(true);
            setBinarioFulfillment(1);
        } else {
            setActivarFullfilment(false);
            setBinarioFulfillment(0);
        }

        if (Number(descuento) !== 0) {
            setDiscountSeleccionado(descuento);
            setCheckedDiscount(descuento);
        } else { setDiscountSeleccionado(''); }

        if (orden !== 1) {
            setId(orden);
        }

        if (vistaM === true) {
            setVistaMosaico(true);
        } else {
            setVistaMosaico(false);
        }

        setFlagFiltrar(true);
        setIsShowing(false);
    }

    // limpiar todos los filtros seleccionados en el modal filtros
    function limpiar() {
        setFlagFiltrar(false);
        setValor(1);
        setId(1);
        // limpia categoria
        setCategoria('');
        setIdCategoria(0);
        setCategoriaSeleccionada(0);
        // limpia marca 
        setCheckedItem(null);
        setMarcaSeleccionada("");
        setCheckedItem(null);
        // limpia precios
        setPrecioMax(0);
        setPrecioMenor(0);
        // limpia estrellas
        setActivarEstrellas(false);
        setActivarEnvioGratis(false);
        setActivarFullfilment(false);
        //limpia descuento
        setCheckedDiscount(0);
        setDiscountSeleccionado(0);
        // limpia vistas
        setVistaMosaico(true);
        setVistaLista(false);

        setBinarioFulfillment(0);
        setBinarioEstrellas(0);
        setBinarioEnvioGratis(0);

        history.replace(primeraParte + "/pagina=1");
    }

    // para limpiar el filtro descuento
    const limpiarDiscount = () => {
        setCheckedDiscount(0); // borra el descuento seleccionado
        setDiscountVisible(false); // regresa al modal de filtros

        history.replace(primeraParte + "/pagina=1");
    }

    // limpiar categoria
    const limpiarCategoria = () => {
        setCategoria(''); // borra categoria
        setIdCategoria(0); // borra id
        setCategoriaVisible(false); // regresa a la ventana de filtros

        history.replace(primeraParte + "/pagina=1");
    }

    // limpiar marca
    const limpiarMarca = () => {
        setCheckedItem(''); // borra marca
        setMarcasVisible(false); // regresa a la ventana de filtros

        history.replace(primeraParte + "/pagina=1");
    }

    //seleccionar descuento 
    const seleccionarDiscount = (e) => {
        var descuento = Number(e.target.value);
        setCheckedDiscount(descuento);
    }

    // seleccionar categoria
    const seleccionarCategoria = (event) => {
        setCategoria(event.target.value);
        setIdCategoria(event.target.id);
    }

    // activar boton filtrar marcas
    const filtrarMarcas = () => {
        setMarcasVisible(false);
    }

    // seleccionar marca 
    const seleccionarMarca = (e) => {
        const marca = String(e.target.value);
        setCheckedItem(marca);
    }

    // actualizar seleccion de ordenamiento
    useEffect(() => {
        let valor1 = 1;
        let valor2 = 2;
        let valor3 = 3;

        // RELEVANCIA
        if (Number(id) === Number(valor1)) {
            setValor(valor1);
            setFlagFiltrar(false);
            setParametroOrdenar('');
        }
        // MENOR A MAYOR
        if (Number(id) === Number(valor2)) {
            setValor(valor2);
            setFlagFiltrar(false);
            setParametroOrdenar('A:Y');
        }
        // MAYOR A MENOR
        if (Number(id) === Number(valor3)) {
            setValor(valor3);
            setFlagFiltrar(false);
            setParametroOrdenar('D:Y');
        }
    }, [id])

    // filtro menor y mayor precio
    const precioMin = (e) => {
        setPrecioMenor(Number(e.target.value));
    }
    const precioMax = (e) => {
        setPrecioMax(Number(e.target.value));
    }

    const actualizarMaxDesdeBarra = (e) => {
        setPrecioMax(Number(e));
    }
    const actualizarMinDesdeBarra = (e) => {
        setPrecioMenor(Number(e));
    }

    useEffect(() => {
        setPrecioMenor(minimo);
    }, [minimo])
    useEffect(() => {
        setPrecioMax(maximo);
    }, [maximo])

    useEffect(() => {

        if (flagFiltar === true && flagFiltrarFulfillment === false) {
            if (marcaSeleccionada.length !== 0) {
                setSearchParams({
                    ...searchParams,
                    precioMenor: preciomenor,
                    precioMayor: PrecioMax,
                    categoria: categoriaSeleccionada,
                    marca: marcaSeleccionada,
                    order: parametroOrdenar,
                    start: binarioEstrellas, //activarEstrellas,
                    shipping: binarioEnvioGratis, //activarEnvioGratis,
                    fulfillment: binarioFulfillment, //activarFullfilment,
                    discount: checkedDiscount,
                    page: 1
                });
            }
            if (marcaSeleccionada.length === 0) {
                setSearchParams({
                    ...searchParams,
                    precioMenor: preciomenor,
                    precioMayor: PrecioMax,
                    categoria: categoriaSeleccionada,
                    marca: null,
                    order: parametroOrdenar,
                    start: binarioEstrellas, //activarEstrellas,
                    shipping: binarioEnvioGratis, //activarEnvioGratis,
                    fulfillment: binarioFulfillment, //activarFullfilment,
                    discount: checkedDiscount,
                    page: 1
                });
            }
            setFlagFiltrar(false);
        }

        // __________________________________________________
        let param_descuento = '';
        let param_fulfillment = '';
        let param_enviogratis = '';
        let param_estrellas = '';
        let param_categoria = '';
        let param_marca = '';
        let param_precios = '';
        let param_orden = null;

        // se aplica cualquier filtro
        if ((searchParams.order != '' ||
            Number(searchParams.discount) !== 0 ||
            Number(searchParams.fulfillment) === 1 ||
            Number(searchParams.shipping) === 1 ||
            Number(searchParams.start) === 1 ||
            Number(searchParams.categoria) !== 0 ||
            searchParams.marca != null ||
            ((Number(searchParams.precioMenor) !== 0 
            && Number(searchParams.precioMayor) !== 0)))) {

            if (Number(searchParams.discount) !== 0) {
                param_descuento = (`/descuento=${Number(searchParams.discount)}`);
            }
            if (Number(searchParams.fulfillment) === 1) {
                param_fulfillment = (`/fulfillment=${searchParams.fulfillment}`);
            }

            if (Number(searchParams.shipping) === 1) {
                param_enviogratis = (`/enviogratis=${searchParams.shipping}`);
            }
            if (Number(searchParams.start) === 1) {
                param_estrellas = (`/estrellas=${searchParams.start}`);
            }
            if (Number(searchParams.categoria) !== 0) {
                param_categoria = (`/categoria=${Number(searchParams.categoria)}`);
            }
            if (searchParams.marca != null) {
                param_marca = (`/marca=${searchParams.marca}`);
            }
            if (Number(searchParams.precioMenor) !== 0 && Number(searchParams.precioMayor) !== 0
                && searchParams.precioMenor != undefined && searchParams.precioMayor != undefined) {
                param_precios = (`/preciomenor=${Number(searchParams.precioMenor)}/preciomayor=${Number(searchParams.precioMayor)}`);
            }
            if (searchParams.order != null) {
                if (String(searchParams.order) === '')
                    param_orden = '';
                else {
                    if (searchParams.order === 'A:Y') //menor
                        param_orden = (`/orden=A`);
                    if (searchParams.order === 'D:Y') //mayor
                        param_orden = (`/orden=D`);
                }

            }

            history.replace(primeraParte + param_marca + param_categoria + param_precios + param_descuento + param_estrellas + param_enviogratis + param_fulfillment + param_orden + '/pagina=' + searchParams.page);
        }
        // no se aplican filtros
        if ((String(searchParams.order) === "") && Number(searchParams.discount) === 0 && Number(searchParams.fulfillment) === 0 && Number(searchParams.shipping) === 0 && Number(searchParams.start) === 0 && Number(searchParams.categoria) === 0 && searchParams.marca == null && (Number(searchParams.precioMenor) === 0 && Number(searchParams.precioMayor) === 0)) {
            history.replace(primeraParte + '/pagina=' + searchParams.page);
        }

    }, [flagFiltar])



    // actualizar parametros de la url y recordar campos al actualizar la pagina
    const updateParamsFilters = ({ params }) => {
        const urlParams = params.split('/');
        urlParams.shift();

        let marca = null;
        let precioMenor = null;
        let precioMayor = null;
        let category = null;
        let page = null;
        let starts = null;
        let shipping = null;
        let fulfillment = null;
        let discount = null;
        let orden = null;

        for (let i = 0; i <= (urlParams.length - 1); i++) {

            if (urlParams[i]?.indexOf('preciomenor=') >= 0) {
                precioMenor = urlParams[i]?.replace('preciomenor=', '');
            }
            if (urlParams[i]?.indexOf('preciomayor=') >= 0) {
                precioMayor = urlParams[i]?.replace('preciomayor=', '');
            }
            if (urlParams[i]?.indexOf('marca=') >= 0) {
                marca = urlParams[i]?.replace('marca=', '');
            }
            if (urlParams[i]?.indexOf('categoria=') >= 0) {
                category = urlParams[i]?.replace('categoria=', '');
            }
            if (urlParams[i]?.indexOf('estrellas=') >= 0) {
                starts = urlParams[i]?.replace('estrellas=', '');
            }
            if (urlParams[i]?.indexOf('enviogratis=') >= 0) {
                shipping = urlParams[i]?.replace('enviogratis=', '');
            }
            if (urlParams[i]?.indexOf('fulfillment=') >= 0) {
                fulfillment = urlParams[i]?.replace('fulfillment=', '');
            }
            if (urlParams[i]?.indexOf('descuento=') >= 0) {
                discount = urlParams[i]?.replace('descuento=', '');
            }
            if (urlParams[i]?.indexOf('orden=') >= 0) {
                orden = urlParams[i]?.replace('orden=', '');
            }
            if (urlParams[i]?.indexOf('pagina=') >= 0) {
                page = urlParams[i]?.replace('pagina=', '');
            }
        }

        if (precioMenor != null) {
            setPrecioMenor(precioMenor);
        }
        if (precioMayor != null) {
            setPrecioMax(precioMayor);
        }
        if (marca != null) {
            setCheckedItem(marcaP);
        }
        if (category != null) {
            setIdCategoria(category);
        }

        if (starts != null) {
            if (Number(starts) === 1) {
                setBinarioEstrellas(1);
                setActivarEstrellas(true);
            } else {
                setActivarEstrellas(false);
                setBinarioEstrellas(0);
            }
        }

        if (shipping != null) {
            if (Number(shipping) === 1) {
                setBinarioEnvioGratis(1);
                setActivarEnvioGratis(true);
            } else {
                setActivarEnvioGratis(false);
                setBinarioEnvioGratis(0);
            }
        }

        if (fulfillment != null) {
            if (Number(fulfillment) === 1) {
                setActivarFullfilment(true);
                setBinarioFulfillment(1);
            } else {
                setActivarFullfilment(false);
                setBinarioFulfillment(0);
            }
        }

        if (discount != null) {
            setCheckedDiscount(discount);
        }

        if (orden != null) {
            // setValor(orden);
            if (orden === 'A')//menor
            {
                setValor(2);
                setId(2);
            }
            if (orden === 'D')//mayor
            {
                setValor(3);
                setId(3);
            }
        }
        if (orden == null) {
            setId(1);
            setValor(1);
        }

        if (page != undefined && page > 1) {
            setPagina(pagina);
        } else {
            setPagina(1);
        }
    }

    // ejecutar updateParamsFilters cuando pararms cambie
    useEffect(() => {
        updateParamsFilters({ params });
    }, [params]) // ANTES []

    const listaCategorias = [];
    const listaMarcas = [];

    if (searchResults.searchResult) {
        if (Number(searchResults.searchResult.GSP.RES.M) !== 0) {

            searchResults.searchResult.GSP.RES.PARM.PMT.map((item) => {
                return item?.DN === 'breadcrumbs'
                    ? (item?.PV && item.PV != "undefined" && item.PV != undefined)
                        ? ((item.PV).length > 1
                            ? item.PV.map((ca) =>
                                listaCategorias.push({ id: ca.id, name: ca.name })
                            )
                            : listaCategorias.push({ id: item.PV.id, name: item.PV.name }))
                        : null
                    : null
            })

            searchResults.searchResult.GSP.RES.PARM.PMT.map((item2) => {
                return item2.DN === 'marcas'
                    ? (item2.PV && item2.PV != "undefined" && item2.PV != undefined)
                        ? ((item2.PV).length > 1
                            ? item2.PV.map((mar) =>
                                listaMarcas.push({ marca: String(mar.V).toLowerCase() })
                            )
                            : listaMarcas.push({ marca: String(item2.PV.V).toLowerCase() }))
                        : null
                    : null
            })

        }
    }

    if (listaMarcas.length > 0) {
        var uniqueArray = removeDuplicates(listaMarcas, "marca");
    } else {
        var uniqueArray = false;
    }


    if (listaCategorias && listaCategorias.length > 0) {
        var categoriasUnicas = removeDuplicates(listaCategorias, "name")
    } else {
        var categoriasUnicas = false;
    }

    return (
        <BarFilter
            results={results} isShowing={isShowing} setIsShowing={setIsShowing}
            limpiar={limpiar} filtrar={filtrar} history={history}
            checkedItem={checkedItem} setCheckedItem={setCheckedItem} marcaSeleccionada={marcaSeleccionada} setMarcasVisible={setMarcasVisible}
            marcasVisible={marcasVisible} filtrarMarcas={filtrarMarcas} limpiarMarca={limpiarMarca} seleccionarMarca={seleccionarMarca}
            categoria={categoria} categoriaSeleccionada={categoriaSeleccionada} categoriaVisible={categoriaVisible} setCategoria={setCategoria}
            setCategoriaVisible={setCategoriaVisible} idCategoria={idCategoria} seleccionarCategoria={seleccionarCategoria} limpiarCategoria={limpiarCategoria}
            activarEstrellas={activarEstrellas} HandleActivarEstrellas={HandleActivarEstrellas}
            activarEnvioGratis={activarEnvioGratis} handleEnvioGratis={handleEnvioGratis}
            activarFullfilment={activarFullfilment} handleActivarFullfilment={handleActivarFullfilment}

            precioMenor={preciomenor} PrecioMax={PrecioMax} precioMin={precioMin} precioMax={precioMax} minimo={minimo} maximo={maximo}
            valor={valor} activarOrdenar={activarOrdenar}
            listaCategorias={categoriasUnicas} listaMarcas={uniqueArray}
            params={params}
            seEnviaVista={seEnviaVista}
            setDiscountVisible={setDiscountVisible} discountVisible={discountVisible} limpiarDiscount={limpiarDiscount} seleccionarDiscount={seleccionarDiscount} discountSeleccionado={discountSeleccionado}
            checkedDiscount={checkedDiscount} setCheckedDiscount={setCheckedDiscount}
            tieneEnvioGratis={tieneEnvioGratis} tieneFulfillment={tieneFulfillment} tieneReview={tieneReview}

            setVistaLista={setVistaLista} setVistaMosaico={setVistaMosaico} vistaMosaico={vistaMosaico} vistaLista={vistaLista} handleMoscaico={handleMoscaico}
            handleLista={handleLista} handleChangeExpress={handleChangeExpress} valorDescuento={valorDescuento}

            actualizarMaxDesdeBarra={actualizarMaxDesdeBarra}
            actualizarMinDesdeBarra={actualizarMinDesdeBarra}
        />
    )
}