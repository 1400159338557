import React from 'react'
import './style.sass'
import { BASE_PATH_RESOURCES, PORTAL_URL } from 'constants/general'

export default function ButtonSellinClaroshop(props) {
    const { id, title } = props;

    //let url = "https://sears-app.uniko.co/login?sears-product=https:%2F%2Fwww.sears.com.mx/producto/" + id + "/" + title;
    let url = `/Mesa-de-Regalos/?sears-product=${window.location.href}`;

    return (
        <>
            <div className="add-mesa" id="add-mesa">
                <a className="add-mesa-btn btn" href={url} rel="noopener noreferrer">
                    Agregar a Mesa de Regalos
                </a>
            </div>
        </>
    )
}