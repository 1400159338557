import {BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL} from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'


// Funcion Get Direccion Mi Cuenta
export  async function CartAflilados({cart_Afiliados}){
    // trae la variable session token
    const accessToken = "Bearer " + decodeStorage("access_token");
    const email = JSON.parse(window.atob( accessToken.split('.')[1] )).email
    const isLoged = decodeStorage('session_token')
        
    // const apiURL = `${BASE_PATH_API}/vendedores-afiliados/v1/cart/detail?hash_cart=${cart_Afiliados}`
    const apiURL = `${BASE_PATH_API}/vendedores-afiliados/v1/tag`

    const newData = {
        vendor_hash_cart: cart_Afiliados,
        user_email: email
    }

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        // withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: newData
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}

// Funcion Post Terrific
export  async function CartTerrific(cart_Afiliados){
    // trae la variable session token
    const accessToken = "Bearer " + decodeStorage("access_token");
    // const email = JSON.parse(window.atob( accessToken.split('.')[1] )).email
    // const isLoged = decodeStorage('session_token')
        
    // const apiURL = `${BASE_PATH_API}/vendedores-afiliados/v1/cart/detail?hash_cart=${cart_Afiliados}`
    const apiURL = `${BASE_PATH_API}/live/v1/carrito`

    const newData = cart_Afiliados

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        // withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: newData
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}