import React, { useState, useEffect } from 'react'
import './style.sass'
import { decodeStorage, encodeStorage } from 'functions/storageBase64'
import { getDeliveryDate } from 'services/DeliveryDate'
import BarraCP from 'components/BarCP/BarHeaderCP'
import { Link, useHistory } from 'react-router-dom'
import { ENVIO_GRATIS } from 'constants/general';

export default function DeliveryDate({ ...props }) {
    const {
        is_digital,
        id_Product,
        skuChildren,
        stock,
        status,
        changeDirectionBar,
        skuChildrenFF,
        fullfilment,
        updateFF,
        isfree,
        sale_price,
        showExpress,
        sizeColorLongitud,
        sizeColorSKU,
        tallaColor,
        setPreseleccionar,
        colorText,
        setSuperExpres,
        superExpres
    } = props

    // console.log('props: ',props)

    // console.log(" tiene talla colr--> ", sizeColorLongitud, " lo seleccino-->", sizeColorSKU, " cambio.. ", colorText)
    // if (tallaColor) {
    //     console.log(" contenido color - talla ------> ", tallaColor[0].color)
    //     // {tallaColor && tallaColor[0].color && tallaColor[0].sizes
    // }
    const history = useHistory();
    const codigoPostal = decodeStorage('codigoPostal')
    const [dataShipping, getDataShipping] = useState()
    const [deliveryDateResp, setDeliveryDateResp] = useState(false);
    const [mostrarCP, setMostrarCP] = useState(false)
    const [cPostal, setCPostal] = useState(codigoPostal)
    const [tieneValorSeleccionado, setTieneValorSeleccionado]= useState(false);

    //- Funcion que va por la fecha estimada cuando existe un CP
    useEffect(() => {
        if (codigoPostal) {
            if (skuChildrenFF) {
                getDeliveryDate({ idProduct: id_Product, sku: String(skuChildrenFF), codPostal: codigoPostal })
                    .then(setDeliveryDateResp)
            } else {
                getDeliveryDate({ idProduct: id_Product, sku: skuChildren, codPostal: codigoPostal })
                    .then(setDeliveryDateResp)
            }
        }
    }, [codigoPostal, id_Product, skuChildrenFF])

    useEffect(() => {
        if (deliveryDateResp) {
            getDataShipping(deliveryDateResp?.data?.shipping)
            //setSuperExpres(deliveryDateResp?.data?.shipping?.type)
            //setSuperExpres(deliveryDateResp?.data?.shipping?.type)
        }
    }, [deliveryDateResp])

    //cambia valor para mostrar modulo de cp
    const addDeliveryDate = () => {
        setMostrarCP(!mostrarCP);
    }

    const clickPreseleccionarTalla = ()=>{
        // console.log("hice click-.....")
        
        setPreseleccionar(true);
    };

    const [superE, setSuperE] = useState(false)
    useEffect(() => {
        if (fullfilment) {
            if (fullfilment?.fulfillment) {
                if (!decodeStorage('firstVisit')) {
                    if (document.querySelector('.moduleShopCardProduct .fullFilment')) {
                        history.push(`#modalExpress`, null);
                        encodeStorage('firstVisit', true);
                    }
                }
            }
        }
        if(superExpres === "super_express" || superExpres === true){
            //console.log('superExpres!: ',superExpres)
            setSuperE("true")
        }else{
            setSuperE(false)
        }
    }, [fullfilment, superExpres])
    
    // console.log('DD fullfilment: ', fullfilment)
    // console.log('DD superExpres: ', superExpres)
    // console.log("TC longitud ----> ", sizeColorLongitud, " TC seleccionado ---->", sizeColorSKU, " cambio color : ", colorText)

    return (
        <>
            {is_digital
                ? <div className="moduleIsDigital">
                    <p>Al aprobar el pago enviaremos tu producto por correo electrónico.</p>
                </div>
                : 
                    stock >= 1 && status &&
                        <div className="moduleEnvioGratis">
                            <div className="moduleShipping">
                                <div className="int">
                                    <div className="int2">
                                        {isfree === false || sale_price <= ENVIO_GRATIS
                                            ? <span className="noIsFree">Envío a domicilio </span>
                                            : <span className="noIsFree">Envío gratis</span>
                                        }
                                        {fullfilment?.fulfillment != null && sizeColorLongitud !== 0
                                            ? updateFF === "true"
                                                ? <Link to="#modalExpress" className="fullFilment" onClick={showExpress}>Express</Link>
                                                : null
                                            : fullfilment?.fulfillment != null && <Link to="#modalExpress" className="fullFilment" onClick={showExpress}>Express</Link>
                                        }
                                    </div>
                                    {sizeColorLongitud > 0
                                        ? sizeColorSKU && sizeColorSKU != null
                                            ? !dataShipping
                                                ? <div className="cpDetallebox">
                                                    <BarraCP changeCP={changeDirectionBar} clase="cpDetalle" />
                                                </div>

                                                : <div className="dateDelivery1">
                                                    <BarraCP changeCP={changeDirectionBar} clase="cpDelivery" fechaEstimada={dataShipping.date_text} />
                                                </div>
                                            : <p className="deliverySinTalla" onClick={clickPreseleccionarTalla}>Selecciona
                                                {tallaColor && tallaColor[0]?.color && tallaColor[0]?.sizes
                                                    ? <> color/talla </>
                                                    : tallaColor && tallaColor[0]?.color && !tallaColor[0]?.sizes
                                                        ? <> color </>
                                                        : null
                                                } para conocer cuando te llega</p>
                                        : sizeColorLongitud === 0 && (sizeColorSKU == null || sizeColorSKU === false) && !dataShipping
                                            ?
                                                <div className="cpDetallebox">
                                                    <BarraCP changeCP={changeDirectionBar} clase="cpDetalle" />
                                                </div>
                                            :
                                                sizeColorLongitud === 0 && (sizeColorSKU == null || sizeColorSKU === false) && dataShipping &&
                                                    <div className="dateDelivery1">
                                                        <BarraCP changeCP={changeDirectionBar} clase="cpDelivery" fechaEstimada={dataShipping.date_text} />
                                                    </div>
                                    }


                                    {/* {!dataShipping
                                        ? <>
                                            <div className="cpDetallebox">
                                                <BarraCP changeCP={changeDirectionBar} clase="cpDetalle" />
                                            </div>

                                        </>
                                        : <p className="dateDelivery1">  <BarraCP changeCP={changeDirectionBar} clase="cpDelivery" fechaEstimada={dataShipping.date_text} /> </p>
                                    } */}
                                </div>
                            </div>
                        </div>

            }
        </>
    )
}