import React, { useState, useEffect } from 'react'
import './style.sass'
import ModalDescription from 'components/Modal'
import { Link, useHistory } from 'react-router-dom'

export default function ModuleSpecificationProduct({ ...props }) {

    const {
        attributes
    } = props

    const history = useHistory()
    const urlLink = window.location.href
    const hashLink = window.location.hash

    const [showModal, SetShowModal] = useState(false)
    const showSpecific = () => {
        SetShowModal(true)
    }

    const handleClose = () => {
        history.goBack(1)
    }

    useEffect(() => {
        if (hashLink === "#modalSpecification") {
            showSpecific()
        } else {
            SetShowModal(false)
        }
    }, [hashLink, urlLink])

    const atributesArray = Object.entries(attributes).map(([key, value]) => {
        return (
            <li key={key}>
                <span className="atributesKeyM">{key.replace(/_/g, " ")}: </span>
                <span className="atributesValueM">{value}</span>
            </li>
        )
    })

    return (
        <div className="moduleEspecificacion">
            <div className="moduleLinkEspecificacion">
                {/* <Link to={'#modalSpecification'} onClick={showSpecific} className="moduleLinkEspecificacion"> */}
                <div className="modulePartEspecificacion">
                    <p className="h3">Especificaciones</p>
                </div>
                <div className="textEspecificacion">
                    <ul className="listAttributes">
                        {attributes
                            ? Object.entries(attributes).map(([key, value], ind) => {
                                return (
                                    ind <= 9
                                        ? <li key={ind}>
                                            <span className="atributesKey">{key.replace(/_/g, " ")}</span>
                                            <span className="atributesValue">{value}</span>
                                        </li>
                                        : null
                                )
                            })
                            : null
                        }
                    </ul>

                    <Link to={'#modalSpecification'} onClick={showSpecific} className="verMasEsp">Ver más especificaciones</Link>
                </div>
            </div>
            {/* </Link> */}
            {showModal && <ModalDescription onClose={handleClose} type="full" title="Especificaciones" closeBody="backBodyBtn">
                <div className="textEspecificacion">
                    <ul className="listAttributes">{atributesArray}</ul>
                </div>
            </ModalDescription>}
        </div>
    )
}