import React from 'react'
import ReactDOM from 'react-dom'

import './style.sass'

function Toast({...props}){
    const {
        children,
        onClose,
        type,
        time
    } =props


    setTimeout(onClose,
        (time * 1000)
    )
    
    return(
        <div className={`toast ${type}`} onClick={onClose}>
            <div className="dataToast">
                {children}
            </div>
        </div>
    )
}

export default function ToastPortal ({...props}){
    const {
        children,
        onClose,
        type,
        time
    } = props

    return ReactDOM.createPortal(
        <Toast onClose={onClose} type={type} time={time}>
            {children}
        </Toast>,
        document.getElementById('modal-root')
    )
}