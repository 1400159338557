import React from 'react'
import './style.sass'

export default function DisclaimerNotice(props){
    const {
        descripDisclamer = '*La promoción aplica sólo a productos participantes. Precios y ofertas sujetos a cambios sin previo aviso. Hasta agotar existencias.'
    } = props

    return (
        <div className="disclaimerNotice">
            <p>{descripDisclamer}</p>
        </div>
    )
}