import React, { Suspense } from 'react';
import loadable from '@loadable/component'

import { useClearCacheCtx } from 'react-clear-cache';
import { Router, Route, Redirect, Switch } from "react-router-dom";
import PrivateRoute from 'routers/privateRouter';
import BrowserHistory from 'routers/history';
import Provider from 'context/storeProvider';

import Home from 'pages/Home';
import Authenticated from 'pages/LoginRegistro/Authenticated';
import Login from 'pages/LoginRegistro/Login';
import Registro from 'pages/LoginRegistro/Registro';
import Afiliados from 'pages/Afiliados';
import Livestream from 'pages/Livestream';
import Resultados from 'pages/Buscador/Results';
import MenuCategoria from 'pages/Categorias/MenuCategoria';
import Buscador from 'pages/Buscador/Search';
import CategoriaPage from 'pages/Categorias/SeccionCategoria/indexSuspense';
import TiendaPage from 'pages/Tienda/ResultsTiendas/indexSuspense';
import { TiendasOficiales } from 'pages/Tienda/SeccionT';
import { CornerPage } from 'pages/Corner';
import DetalleProducto from 'pages/Producto/indexSuspense';
import Landings from 'pages/Landings';
import RecuperarContrasenia from 'pages/LoginRegistro/Password';
import Carrito from 'pages/Carrito';
import MisPedidos from 'pages/MiCuenta/MisPedidos';
import MisDirecciones from 'pages/MiCuenta/MisDirecciones/SeleccionarDireccion';
import MisDireccionesAdd from 'pages/MiCuenta/MisDirecciones/CrearDireccion';
import MisDireccionesEdit from 'pages/MiCuenta/MisDirecciones/EditarDireccion';
import MiWishlist from 'pages/MiCuenta/MiWishlist';
import MiCuenta from 'pages/MiCuenta/MiCuenta';
import { DeleteAccount } from 'pages/MiCuenta/DeleteAccount';
import LoginMiTelmex from 'pages/MiCuenta/MiTelmex/loginTelmexPage';
import MiTelmexComponent from 'components/MiTelmex/MiTelmex/MiTelmexComponent';
import QuestionsMiTelmexPage from 'pages/MiCuenta/MiTelmex/questionsMiTelmex';
import Contacto from 'pages/Contacto';
import AcercaSears from 'pages/AcercaSears';
import Privacidad from 'pages/Politicas/index';
import Ajustes from 'pages/Ajustes';
import { MailsCorreoCompleto } from 'pages/Mails/VerCorreoCompleto';
import Pruebas from 'pages/PruebaModules';
import Error404 from 'pages/Error/Error404';

import { MesaRegalos } from 'pages/MesaRegalos';

import { DetallePedido } from 'pages/MiCuenta/MisPedidos/detallePedido';
import { SectionEnqueNecesitasAyuda } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista1-vista2';
import { MotivoCancelacion } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista3-motivo';
import { CancelarPedido } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista5-pedidoCancelado';
import { FormularioDatosReembolso } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista35-datoReembolso';
import { SolicitarCambioDevolucion } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista23-solicitarCambioDevolucion';
import { AdjuntarFotografias } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista36-adjuntarFotografias';
import { SectionProcesoRecoleccionEnCamino } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista6-nopuedeCancelar';
import { CuentanosTuProblema } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista21-cuentanosTuProblema';
import { NoFunciona } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista7-noFunciona';
import { CambioDevolucion } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista22-CambioDevolucion';


import ContactoSears from 'pages/Contacto/contactoSears';
import { Autocenter } from 'pages/Autocenter/autocenter';
import { DudasComentarios } from 'pages/Autocenter/dudasComentarios';
import { FaqCredito } from 'pages/Autocenter/faqCredito';
import { FormasPagoAyuda, FormasPagoCreditoSears, FormasPagoDeposito, FormasPagoExtranjero, FormasPagoMonedero, FormasPagoPaypal, FormasPagoTarjetasBancarias, FormasPagoConveniencia } from 'pages/Autocenter/formasPago';
import { PreguntasFrecuentes } from 'pages/Autocenter/preguntasFrecuentes';
import { PoliticaCancelacion } from 'pages/Politicas/politicaCancelacion';
import { AvisoPrivacidad } from 'pages/Politicas/avisoPrivacidad';
import { PoliticaDevolucion } from 'pages/Politicas/politicaDevolucion';
import { PoliticaEnvio } from 'pages/Politicas/politicaEnvio';
import { PoliticaCompra } from 'pages/Politicas/politicaCompra';
import { PoliticaMarketPlace } from 'pages/Politicas/politicaMarketPlace';
import { ServicioConexion } from 'pages/Politicas/servicioConexion';
import { AvisoAutocenter } from 'pages/Politicas/avisoAutocenter';
import { AvisoProspectos } from 'pages/Politicas/avisoProspectos';
import { AvisoMesaDeRegalos } from 'pages/Politicas/avisoMesaDeRegalos';
import { TerminosCondiciones } from 'pages/Politicas/terminosCondiciones';
import { TarjetaMagica } from 'pages/Politicas/tarjetaMagica';
import { AcercaNosotros } from 'pages/AcercaNosotros/acercaNosotros';
import ServicioCliente from 'pages/ServicioCliente';
import PagosPoliticas from 'pages/PagosPoliticas';
import Servicios from 'pages/Servicios';
import { AgendarCita } from 'pages/Autocenter/agendarCita';
import { CategoriasHijas } from 'components/CardCategory';
import { LocalizadorTiendas } from 'pages/LocalizadorTiendas';
import MenuPrincipal from 'components/Menu';
import SinDirecciones from 'pages/MiCuenta/MisDirecciones/SinDirecciones';
import { ReviewsWrite } from 'pages/Reviews';

// import { ArticuloAdicional } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista8-articuloAdicional';
import { ApareceComoEntregado } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista9-apareceComoEntregado';
import { ApareceComoEntregadoFotos } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista92-apareceComoEntregadoFotos';
import { ElegirTienda } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista95-elegirTienda';
import { ComoPrefieresDevolver } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista37-comoPrefieresDevolver';
import { SolicitarRecoleccion } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista93-solicitarRecoleccion';
import { CondicionesPaquete } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista31-condicionesPaquete';
import { NoterminoComoEsperabamos } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista24-NoterminoComoEsperabamos';
import { TengoMiPaquete } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista91-tengoMiPaquete';
import { ProcesoAclaracion } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista91-procesoAclaracion';
import { Typ } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista96-typ';
import { SellerTyp } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista96-sellerTYP';

import PreLogin from 'pages/LoginRegistro/PreLogin';
import TemplateTest from 'pages/Test'


BrowserHistory.listen(location => {
  //console.log('Set page to', location.pathname);
  // eslint-disable-next-line no-undef
  //ineum('page', location.pathname);
  // Note that the above can result in many useless pages when you are making use of path parameters.
  // In these cases you will have to define the page via different means, e.g. by creating a custom
  // Route component which accepts a 'pageName' property.
});

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  return (
    <>
      <Provider>
        <Router history={BrowserHistory}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            <Route path={[
              "/resultados/q=:busqueda?",
              "/preciomenor=:mp?",
              "/preciomayor=:pm?",
              "/marca=:idMarca?",
              "/categoria=:idCategoria?",
              "/estrellas=:estrella?",
              "/enviogratis=:ship?",
              "/fulfillment=:ful?",
              "/descuento=:dis?",
              "/orden=:ord?"
            ]} component={Resultados} />

            <Route path="/buscador" component={Buscador} />

            <Route path="/departamentos" exact component={MenuCategoria} />

            <Route path="/categoria" exact component={MenuCategoria} />
            <Route path="/categoria/:id_cat" exact component={CategoriasHijas} />
            <Route path="/categoria/:id_cat?/:seo?/:page" component={CategoriaPage} />

            <Route path="/producto/:id?/:seo?" component={DetalleProducto} />

            <Route path="/tiendas" component={TiendasOficiales} />
            <Route path="/tienda/:id_Tienda?/:seo?" component={TiendaPage} />
            <Route path="/corner/:id_Corner?/:seo?" component={CornerPage} />

            <Route path="/afiliados/:hash_cart" component={Afiliados} />
            <Route path="/livestream/:hash_cart" component={Livestream} />

            <Route path="/landing/:landingName" component={Landings} />
            {/* <Route path="/l/:landingName" component={Landings} /> */}

            <Route path="/authenticated" component={Authenticated} />
            <Route path="/login" component={Login} />
            <Route path="/pre_login" component={PreLogin} />
            <Route path="/registro" exact component={Registro} />
            <Route path="/recuperar-contrasena/" component={RecuperarContrasenia} />
            <Route path="/contacto" component={Contacto} />
            <Route path="/acerca-sears" component={AcercaSears} />
            <Route path="/privacidad" component={Privacidad} />
            <Route path="/servicios-cliente" component={ServicioCliente} />
            {/* <Route path="/politicas-formas-pago" component={PagosPoliticas} /> */}
            <Route path="/formas-pago/credito-sears" component={FormasPagoCreditoSears} />
            <Route path="/formas-pago/paypal" component={FormasPagoPaypal} />
            <Route path="/formas-pago/monedero" component={FormasPagoMonedero} />
            <Route path="/formas-pago/tarjetas-bancarias" component={FormasPagoTarjetasBancarias} />
            <Route path="/formas-pago/deposito" component={FormasPagoDeposito} />
            <Route path="/formas-pago/extranjero" component={FormasPagoExtranjero} />
            <Route path="/formas-pago/conveniencia" component={FormasPagoConveniencia} />
            <Route path="/servicios" component={Servicios} />
            <Route path="/Mesa_Regalos" component={MesaRegalos} />
            <Route path="/agendar-cita" component={AgendarCita} />

            <Route path="/test" component={TemplateTest} />
            {/* <Route path="/contactanos/" component={ContactoSears} /> */}
            <Route path="/menu-mas" component={MenuPrincipal} />
            <Route path="/dudas-comentarios/" component={DudasComentarios} />
            <Route path="/formas-pago/" component={FormasPagoAyuda} />
            <Route path="/preguntas-frecuentes/" component={PreguntasFrecuentes} />
            <Route path="/auto-center" component={Autocenter} />
            <Route path="/preguntas-frecuentes-credito" component={FaqCredito} />
            <Route path="/aviso-privacidad" component={AvisoPrivacidad} />
            <Route path="/aviso-privacidad-app" component={AvisoPrivacidad} />
            <Route path="/aviso-privacidad-prospectos" component={AvisoProspectos} />
            <Route path="/aviso-privacidad-auto-center" component={AvisoAutocenter} />
            <Route path="/aviso-privacidad-mesa-de-regalos" component={AvisoMesaDeRegalos} />
            <Route path="/politica-cancelacion" component={PoliticaCancelacion} />
            <Route path="/politica-devolucion" component={PoliticaDevolucion} />
            <Route path="/politica-envio" component={PoliticaEnvio} />
            <Route path="/politica-compra" component={PoliticaCompra} />
            <Route path="/terminos-condiciones-market-place" component={PoliticaMarketPlace} />
            <Route path="/terminos-condiciones" component={TerminosCondiciones} />
            <Route path="/tarjeta-magica" component={TarjetaMagica} />
            <Route path="/servicio-de-conexion" component={ServicioConexion} />
            <Route path="/acerca-nosotros" component={AcercaNosotros} />
            <Route path="/localizador-tiendas" component={LocalizadorTiendas} />

            <Route path="/testModules" component={Pruebas} />
            <Route path="/verCorreoCompleto" component={MailsCorreoCompleto} />
            <Route path="/correo" component={MailsCorreoCompleto} />

            <Route path="/loginMiTelmex/" component={LoginMiTelmex} />
            <Route path="/mi-cuenta/mitelmex/" component={MiTelmexComponent} />

            <PrivateRoute path="/mi-cuenta/questions/" component={QuestionsMiTelmexPage} />
            <PrivateRoute path="/mi-cuenta/direcciones-envio/" exact component={MisDirecciones} />
            <PrivateRoute path="/mi-cuenta/direcciones-envio/editar/:id" component={MisDireccionesEdit} />
            <PrivateRoute path="/mi-cuenta/direcciones-envio/agregar" component={MisDireccionesAdd} />
            <PrivateRoute path="/mi-cuenta/direcciones-envio/sin-direcciones" component={SinDirecciones} />

            <PrivateRoute path="/mi-cuenta/mis-pedidos/dias=:days/pagina=:pages" component={MisPedidos} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido" component={DetallePedido} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto?" component={DetallePedido} />

            {/* rutas para ayuda dinamica */}
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/necesitas-ayuda" component={SectionEnqueNecesitasAyuda} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/necesitas-ayuda" component={SectionEnqueNecesitasAyuda} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/motivo-cancelacion" component={MotivoCancelacion} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/motivo-cancelacion" component={MotivoCancelacion} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/cancelacion-exitosa" component={CancelarPedido} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/cancelacion-exitosa" component={CancelarPedido} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/datos-bancarios" component={FormularioDatosReembolso} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/datos-bancarios" component={FormularioDatosReembolso} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/no-puede-cancelar" component={SectionProcesoRecoleccionEnCamino} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/que-quieres-hacer" component={CambioDevolucion} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/solicitar-cambio-devolucion" component={SolicitarCambioDevolucion} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/cuentanos-tu-problema" component={CuentanosTuProblema} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/no-funciona" component={NoFunciona} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/adjuntar-fotografias" component={AdjuntarFotografias} />
            
            <PrivateRoute path="/mi-cuenta/borrar-cuenta" exact component={DeleteAccount} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/no-termino-como-esperabamos" component={NoterminoComoEsperabamos}/>
            {/* <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/articulo-adicional" component={ArticuloAdicional} /> */}
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/condiciones-del-paquete" component={CondicionesPaquete} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/no-entregado-que-quieres-hacer/" component={ApareceComoEntregado} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/no-entregado-que-quieres-hacer/fotografias" component={ApareceComoEntregadoFotos} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/como-prefieres-devolver" component={ComoPrefieresDevolver} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/solicitar-recoleccion" component={SolicitarRecoleccion} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/elegir-tienda" component={ElegirTienda} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/tengo-mi-paquete" component={TengoMiPaquete} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/proceso-de-aclaracion" component={ProcesoAclaracion} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/typ" component={Typ} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/seller-typ" component={SellerTyp} />

            <PrivateRoute path="/mi-cuenta/listadeseos/" component={MiWishlist} />
            <PrivateRoute path="/mi-cuenta/" component={MiCuenta} />
            

            <PrivateRoute path="/carrito/" exact ><Redirect to="/carrito/detalle" /></PrivateRoute>

            <PrivateRoute path="/carrito/detalle" component={Carrito} />
            <PrivateRoute path="/ajustes" component={Ajustes} />
            <Route path="/escribir-opinion" component={ReviewsWrite} />
            <Route path="/write-a-review" component={ReviewsWrite} />

            <Route path="/:anything*" component={Error404} />
          </Switch>
        </Router>
      </Provider>
    </>
  );
}

export default App;
