
//New
export const getCategoryAdapter = (data) =>{
    
    let newData
    if(data){
        let categories
        if(data?.data?.length){
            categories = data?.data?.map((cat,index) => {
                let subCat
                if(cat?.children?.length){
                    subCat = cat?.children?.map((subCateg, ind) => {
                        let subCatInt
                        if(subCateg?.children?.length){
                            subCatInt = subCateg?.children?.map((subCat2, inde) => {
                                let subCatInt2 = []
                                return {
                                    estatus: subCat2?.status,
                                    icon: subCat2?.icon,
                                    id_cat: subCat2?.external_id,
                                    nombre: subCat2?.name,
                                    order: inde,
                                    padre: subCateg?.external_id,
                                    seo: subCat2?.seo,
                                    subcategorias: subCatInt2,
                                    visible: subCat2?.visible
                                }
                            })
                        }else{
                            subCatInt = []
                        }
                        return {
                            estatus: subCateg?.status,
                            icon: subCateg?.icon,
                            id_cat: subCateg?.external_id,
                            nombre: subCateg?.name,
                            order: ind,
                            padre: cat?.external_id,
                            seo: subCateg?.seo,
                            subcategorias: subCatInt,
                            visible: subCateg?.visible
                        }
                    })
                } 
                else{
                    subCat = []
                }

                return {
                    estatus: cat?.status,
                    icon: cat?.icon,
                    id_cat: cat?.external_id,
                    nombre: cat?.name,
                    order: index,
                    seo: cat?.seo,
                    subcat: subCat,
                    visible: cat?.visible
                }  
            })
        }
        
        newData = categories

    }
    
    // console.log('data1: ', data)
    // console.log('newData1: ', newData)

    return newData
}

//Old
export const getCategoryAdapterOld = (data) =>{
    
    let newData
    if(data){
        newData = {
            ...data
        }
    }

    // console.log('data2: ', data)
    // console.log('newData2: ', newData)

    return  data
}