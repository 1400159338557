import React, { useState, useEffect } from 'react'
import { useTWA } from 'hooks/useTWA'
import './style.sass'

export function DownloadApp(props){
    const {
        section
    } = props

    const [viewDownloadApp, setViewDownloadApp] = useState(false)
    const {isTWA} = useTWA()

    const appUpdate =  window.sessionStorage.getItem('appDownload')
    const appUpdateP =  window.sessionStorage.getItem('appDownloadP')
    let appUpdateNull =  window.localStorage.getItem('appDownloadNull')

    const [iOS, setiOS] = useState(false)

    useEffect(()=>{
        if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            //setiOS(true)
            if(appUpdateNull <= 50000){
                if(appUpdate !== "true"){
                    if(section === "home"){
                        setViewDownloadApp(true)
                    }
                    else if(section === "producto"){
                        if(appUpdateP  !== "true"){
                            setViewDownloadApp(true)
                        }
                    }
                }
            }
        }else if(/Android/i.test(navigator.userAgent)){
            if(navigator.userAgent?.indexOf('wv') === -1){
                if(navigator.userAgent?.indexOf('Mobile') >= 1){
                    if(appUpdateNull <= 50000){
                        if(appUpdate !== "true"){
                            if(section === "home"){
                                setViewDownloadApp(true)
                            }
                            else if(section === "producto"){
                                if(appUpdateP  !== "true"){
                                    setViewDownloadApp(true)
                                }
                            }
                        }
                    }
                }
            }
        }
        else{
            setiOS(true)
        }
    },[])

    const updateApp = () =>{
        if(section === "home"){
            window.sessionStorage.setItem('appDownload', true)
        }
        else if(section === "producto"){
            window.sessionStorage.setItem('appDownload', true)
        }
        //window.onPromoClick('descargar-nueva-app','cintillo-home','version7',1)
        //window.location.href = 'https://claroshop.onelink.me/mwNR/2dc47272'//'https://claroshop.page.link/descargar'
        if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            window.location.href = 'https://apps.apple.com/mx/app/sears/id1610895867'
        }else if(/Android/i.test(navigator.userAgent)){
            window.location.href = 'https://play.google.com/store/apps/details?id=com.americamovil.sears&referrer=utm_source%3Ddirect%26utm_medium%3Dbanner%26utm_term%3Ddescargar-app%26utm_campaign%3Dlanzamiento'

        }
        setViewDownloadApp(false)
    }
    const closeApp = () =>{
        appUpdateNull = Number(appUpdateNull) + 1
        if(section === "home"){
            window.sessionStorage.setItem('appDownload', true)
            window.localStorage.setItem('appDownloadNull', appUpdateNull)
        }
        else if(section === "producto"){
            window.sessionStorage.setItem('appDownload', true)
            window.localStorage.setItem('appDownloadNull', appUpdateNull)
        }
        //window.onPromoClick('botón-cerrar','cintillo-home','version7',1)
        setViewDownloadApp(false)
    }
 
    return(
        !isTWA &&
            !iOS &&
                viewDownloadApp &&
                    <>
                        <div className="barraUpdateApp">
                            <h2 onClick={updateApp}>Descarga la app</h2>
                            <p onClick={updateApp}>y obtén una navegación personalizada</p>
                            <div onClick={updateApp} className="btn rojo">Descargar</div>
                            {/* <div className="iconAppUpdate" onClick={updateApp}></div>
                            <div className="textAppUpdate" onClick={updateApp}></div>
                            <div className="downloadAppUpdate" onClick={updateApp}></div> */}
                            <div className="closeAppUpdate" onClick={closeApp}></div>
                        </div>
                    </>
    )
}