import React, { useState, useEffect } from 'react';
import './style.sass';
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';
import ModalTallas from './modalTallas';

export default function ModuleGuiaTallas(props) {

    const { category, catPadre, catHija_Hombre, catHija_Mujer, catHija_Nino, catHija_Nina, tallasNino, tallasNina, tallasHombre, tallasMujer } = props;

    const [catPadre2, setCatPadre] = useState();
    const [catHija_Hombre2, setCatHija_Hombre] = useState();
    const [catHija_Mujer2, setCatHija_Mujer] = useState();
    const [catHija_Nino2, setCatHija_Nino] = useState();
    const [catHija_Nina2, setCatHija_Nina] = useState();

    useEffect(() => {
        if (catPadre && catHija_Nina && catHija_Nino && catHija_Mujer && catHija_Hombre) {
            setCatPadre(catPadre);
            setCatHija_Hombre(catHija_Hombre);
            setCatHija_Mujer(catHija_Mujer);
            setCatHija_Nino(catHija_Nino);
            setCatHija_Nina(catHija_Nina);
        }
    }, [catPadre, catHija_Hombre, catHija_Mujer, catHija_Nina, catHija_Nino])


    const history = useHistory();
    const prevUrl = document.referrer;
    const urlLink = window.location.href;
    const hashLink = window.location.hash;
    const { isMobile } = useIsMobile();

    const [showModal, SetShowModal] = useState(false);
    const showModalTallas = () => {
        SetShowModal(true);
    };

    const handleClose = () => {
        // history.push(location.pathname);
        history.goBack();
    };

    useEffect(() => {
        if (prevUrl === "") {
            if (hashLink === "#modalGuiaTallas") {
                showModalTallas();
            } else {
                SetShowModal(false);
            }
        } else {
            if (hashLink === "#modalGuiaTallas") {
                showModalTallas();
            } else {
                SetShowModal(false);
            }
        }
    }, [hashLink, urlLink])

    return (
        (category[1]?.id && category[2]?.id && category[1]?.id === Number(catHija_Hombre2) && category[2]?.id === Number(catPadre2))  // caballero / zapateria
            ? <div className="moduleGuiaTallas">
                <Link to={"#modalGuiaTallas"} className="textGuiaTallas" onClick={showModalTallas} >
                    <img src="/img/regla.png" alt="Logo-regla" loading="lazy" width="35" height="26" />
                    <p> </p>
                </Link>

                {showModal && <ModalTallas arreglo={tallasHombre} genero="Hombre" onClose={handleClose} type={isMobile === "desktop" ? "" : "full"} title={isMobile === "desktop" ? "Guía de Tallas" : "Guía de Tallas"} isMobile={isMobile} />}
            </div>
            : (category[1]?.id && category[2]?.id && category[1]?.id === Number(catHija_Mujer2) && category[2]?.id === Number(catPadre2)) // dama / zapateria
                ? <div className="moduleGuiaTallas">
                    <Link to={"#modalGuiaTallas"} className="textGuiaTallas" onClick={showModalTallas} >
                        <img src="/img/regla.png" alt="Logo-regla" loading="lazy" width="35" height="26" />
                        <p> </p>
                    </Link>

                    {showModal && <ModalTallas arreglo={tallasMujer} genero="Mujer" onClose={handleClose} type={isMobile === "desktop" ? "" : "full"} title={isMobile === "desktop" ? "Guía de Tallas" : "Guía de Tallas"} isMobile={isMobile} />}
                </div>
                : (category[1]?.id && category[2]?.id && category[1]?.id === Number(catHija_Nino2) && category[2]?.id === Number(catPadre2)) // niños / zapateria
                    ? <div className="moduleGuiaTallas">
                        <Link to={"#modalGuiaTallas"} className="textGuiaTallas" onClick={showModalTallas} >
                            <img src="/img/regla.png" alt="Logo-regla" loading="lazy" width="35" height="26" />
                            <p> </p>
                        </Link>

                        {showModal && <ModalTallas arreglo={tallasNino} genero="Niño" onClose={handleClose} type={isMobile === "desktop" ? "" : "full"} title={isMobile === "desktop" ? "Guía de Tallas" : "Guía de Tallas"} isMobile={isMobile} />}
                    </div>
                    : (category[1]?.id && category[2]?.id && category[1]?.id === Number(catHija_Nina2) && category[2]?.id === Number(catPadre2)) // niñas / zapateria
                        ? <div className="moduleGuiaTallas">
                            <Link to={"#modalGuiaTallas"} className="textGuiaTallas" onClick={showModalTallas} >
                                <img src="/img/regla.png" alt="Logo-regla" loading="lazy" width="35" height="26" />
                                <p> </p>
                            </Link>

                            {showModal && <ModalTallas arreglo={tallasNina} genero="Niña" onClose={handleClose} type={isMobile === "desktop" ? "" : "full"} title={isMobile === "desktop" ? "Guía de Tallas" : "Guía de Tallas"} isMobile={isMobile} />}
                        </div>
                        : <div className="moduleGuiaTallas"></div>
    )
}