import React from'react'
import SlidersEmarsys from 'components/Emarsys/SlidersEmarsys'

export default function S_Emarsys(){

    return(
        <>
            <section id="recomendationSlider">
                <SlidersEmarsys
                    logica={'PERSONAL'}
                    type={'recomendation'}
                    typeView={'blockView'}
                    title={"Te recomendamos"}
                    idContent={'recomendationSlider'}
                    btnType={'add'}
                    cant={12}
                />
            </section>

            <section id="homeSlider">
                <SlidersEmarsys
                    logica={'HOME_1'}
                    idContent={'homeSlider'}
                    //typeView={'blockView'}
                    title={"Logica Home"}
                />
            </section>

            <section id="relatedSlider">
                 <SlidersEmarsys
                    logica={'RELATED'}
                    idContent={'relatedSlider'}
                    idProd={626181}
                    title={"Logica Relacionados"}
                    //btnType={'add'}
                />
            </section>

            <section id="cartSlider">
                <SlidersEmarsys
                    logica={'CART'}
                    idContent={'cartSlider'}
                    title={"Logica Carrito"}
                    cart={[{item: "624813", price: 1259, quantity: 1}]}
                />            
            </section>
            
            <section id="categorySlider">
                <SlidersEmarsys
                    logica={'CATEGORY'}
                    idContent={'categorySlider'}
                    breadcrumb={'videojuegos>playstation>juegos'}
                    title={"Logica Categoria"}
                />
             </section>

            <section id="searchSlider">
                <SlidersEmarsys
                    logica={'SEARCH'}
                    idContent={'searchSlider'}
                    searchTerm={'zelda'}
                    title={"Logica Busqueda"}
                />            
            </section>

                <section id="departamentSlider" >
                    <SlidersEmarsys
                        logica={'DEPARTMENT'}
                        idContent={'departamentSlider'}
                        breadcrumb={'xbox'}
                        title={"Logica Departamentos"}
                    />            
                </section>


            <section id="alsoBoutghtSlider">
                <SlidersEmarsys
                    logica={'ALSO_BOUGHT'}
                    idContent={'alsoBoutghtSlider'}
                    title={"Logica Also Bouught"}
                    idProd={1377032}
                    cant={10}
                />           
            </section>

            <section id="personalSlider1">
                <SlidersEmarsys
                    logica={'PERSONAL_1'}
                    idContent={'personalSlider1'}
                    title={"Logica Personal"}
                    cant={10}
                />
            </section>

            <section id="popularSlider">
                <SlidersEmarsys
                    logica={'POPULAR'}
                    idContent={'popularSlider'}
                    breadcrumb={'videojuegos>playstation>juegos'}
                    title={"Logica Popular"}
                    cant={10}
                />
            </section>

            <section id="homeSlider4">
                <SlidersEmarsys
                    logica={'HOME'}
                    idContent={'homeSlider4'}
                    typeView={'sliderView'}
                    title={"Logica Home 4"}
                    cant={15}
                />
            </section>

            <section id="homeSlider2">
                <SlidersEmarsys
                    logica={'HOME_2'}
                    idContent={'homeSlider2'}
                    typeView={'scrollView'}
                    title={"Logica Home 2"}
                    cant={10}
                />
            </section>

            <section id="homeSlider3">
                <SlidersEmarsys
                    logica={'HOME_3'}
                    idContent={'homeSlider3'}
                    typeView={'blockView'}
                    title={"Logica Home 3"}
                    cant={10}
                />
            </section>
        </>
    )
}