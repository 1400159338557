import React from 'react'
import './style.sass'

export default function InputType({ ...params }) {

    const {
        type,
        name,
        id,
        placeholder,
        value,
        change,
        focus,
        errorMessage,
        max,
        min,
        refs,
        readonly,
        disabled,
        autocomplete,
        children,
        clase,
        maxLength,
        onFocus,
        onBlur,
        flag,
        texto,
        onKeyDown,
    } = params

    return (<>
        {clase == 'AddressPage'
            ? < div className="fileset1">
                <input
                    type={type}
                    name={name}
                    id={id}
                    // placeholder={placeholder}
                    onChange={change}
                    onFocus={focus}
                    value={value}
                    max={max}
                    min={min}
                    readOnly={readonly}
                    disabled={disabled}
                    autoComplete={autocomplete}
                    data-input={autocomplete}
                    ref={refs}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    flag={flag}
                    texto={texto}
                    onKeyDown={onKeyDown}
                />
                <label htmlFor={id} for={id}>{placeholder}</label>
                {/* {children} */}

                {errorMessage
                    ? <div className="error">{errorMessage}</div>
                    : null
                }

                {flag && !errorMessage && <p className="copyHelp">{texto}</p>}
            </div>
            : <label className="fileset">
                <input
                    type={type}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    onChange={change}
                    onFocus={focus}
                    value={value}
                    max={max}
                    min={min}
                    readOnly={readonly}
                    disabled={disabled}
                    autoComplete={autocomplete}
                    data-input={autocomplete}
                    ref={refs}
                    maxLength={maxLength}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    flag={flag}
                    texto={texto}
                    onKeyDown={onKeyDown}
                    maxLength={maxLength}
                />
                {children}

                {errorMessage
                    ? <div className="error">{errorMessage}</div>
                    : null
                }
                {flag && !errorMessage && <p className="copyHelp">{texto}</p>}
            </label>
        }
    </>
    )
}

// Input tipo android
export function InputTypeAndroid({ ...params }) {

    const {
        type,
        name,
        id,
        placeholder,
        value,
        change,
        focus,
        errorMessage,
        max,
        min,
        refs,
        readonly,
        disabled,
        setNumero,
        autocomplete,
        children,
        maxLength,
        pattern
    } = params

    const handleNumero = (e) => {
        let numero = e.target.value;
        if(pattern === "number"){
            if(numero.length != 0){
                setNumero(numero.replace(/[^\d]+/g,''));
            }else{
                setNumero('')
            }
        }
        else if(pattern === "numberText"){
            if(numero.length != 0){
                setNumero(numero.replace(/[!¿@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/¡?]+/g,''));
                //setNumero(numero.replace(/[^\w\s]/g,''));
                console.log(':   ',numero.replace(/[!¿@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/¡?]+/g,''))
            }else{
                setNumero('')
            }
        }else{
            // setNumero(numero);
        }
    }

    return (
        < div className="fieldset2">
            <input
                type={type}
                name={name}
                id={id}
                placeholder=" "
                onChange={change}
                onChange={handleNumero}
                onFocus={focus}
                value={value}
                max={max}
                min={min}
                readOnly={readonly}
                disabled={disabled}
                autoComplete={autocomplete}
                data-input={autocomplete}
                ref={refs}
                maxLength={maxLength}
            />
            <label htmlFor={id} for={id}>{placeholder}</label>
            {/* {children} */}

            {errorMessage
                ? <div className="error">{errorMessage}</div>
                : null
            }
        </div>
    )
}

export function InputTypeDesktop({ ...params }) {

    const {
        type,
        name,
        id,
        placeholder,
        value,
        change,
        focus,
        errorMessage,
        max,
        min,
        refs,
        readonly,
        disabled,
        autocomplete,
        children,
        clase,
        maxLength,
        onFocus,
        onBlur,
        flag,
        texto,
        onKeyDown,
    } = params

    return (<>
        < div className="fileset desktInput">
            <label htmlFor={id} for={id}>{placeholder}</label>

            <input
                type={type}
                name={name}
                id={id}
                // placeholder={placeholder}
                onChange={change}
                onFocus={focus}
                value={value}
                max={max}
                min={min}
                readOnly={readonly}
                disabled={disabled}
                autoComplete={autocomplete}
                data-input={autocomplete}
                ref={refs}
                onFocus={onFocus}
                onBlur={onBlur}
                flag={flag}
                texto={texto}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
            />

            {errorMessage ? <div className="error">{errorMessage}</div> : null}

            {flag && !errorMessage && <p className="copyHelp">{texto}</p>}
        </div>
    </>
    )
}

// Input tipo android
export function InputTypeContact({ ...params }) {

    const {
        type,
        name,
        id,
        placeholder,
        value,
        change,
        focus,
        errorMessage,
        max,
        min,
        refs,
        readonly,
        disabled,
        autocomplete,
        children,
        maxLength,
        texto
    } = params

    return (
        < div className="fieldsetContact">
            <input
                type={type}
                name={name}
                id={id}
                onChange={change}
                onFocus={focus}
                value={value}
                max={max}
                min={min}
                readOnly={readonly}
                disabled={disabled}
                autoComplete={autocomplete}
                data-input={autocomplete}
                ref={refs}
                maxLength={maxLength}
                placeholder ={placeholder}
            />
            {/* <label htmlFor={id} for={id}>{texto}</label> */}

            {errorMessage
                ? <div className="error">{errorMessage}</div>
                : null
            }
        </div>
    )
}