import React, { useState, useEffect } from 'react';
import { getPedido, getNoFunciona, getSolicitarGarantia } from 'services/AyudaDinamicaServices';
import { Link, useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import Loading from 'components/Loading';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useIsMobile } from 'hooks/useIsMobile';
import { NoFuncionaDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista7-noFunciona-desktop';
import { NO_IMAGE } from 'constants/general';
import { useToken } from 'hooks/useToken';
import { repareTextHtml } from 'functions/repareDataService';

export function NoFuncionaMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [loading, setLoading] = useState();
    const [seller, setSeller] = useState();
    const [nombreProducto, setNombreProducto] = useState(false);
    const [infoNoFunciona, setInfoNofunciona] = useState(false);
    const [infoSolicitarGarantia, setInfoSolicitarGarantia] = useState(false);
    const codigoSears = 2430;

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido && infoNoFunciona && infoSolicitarGarantia) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido,infoNoFunciona, infoSolicitarGarantia])


    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    // obtiene informacion prodcuto no funciona
    useEffect(() => {
        getNoFunciona()
            .then(setInfoNofunciona);
    }, [])

    // obtiene informacion prodcuto solicitar garantia
    useEffect(() => {
        getSolicitarGarantia()
            .then(setInfoSolicitarGarantia);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista7-no funciona o no enciende / solicitar garantia-mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];
        let shopProducto = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                        )

                        // guarda informacion sobre el vendedor del producto
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && shopProducto.push({ "idTienda": elem.shop.idTienda, "nameTienda": elem.shop.name })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )

                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setSeller(shopProducto[0].idTienda);
                        setNombreProducto(productoNombre);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setLoading(true);
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a mis pedidos
    const handleEntendido = () => {
        history.push("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    // redireccionar a cual es la falla
    const handleContinuar = () => {
        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/motivo-cancelacion");
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        {Number(seller) === codigoSears
                            ? <p className="title">No funciona o no enciende</p>
                            : <p className="title">Solicitar Garantía</p>
                        }
                    </div>
                    
                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            <div className="contenedorImagenesIgual1">
                                <ul className='ulIgual1'>
                                    {ima && ima.map((elemento, i) =>
                                        <li key={i}>
                                            <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                        </li>
                                    )}
                                </ul>
                            </div>

                            {ima && ima.length > 1
                                ? <div className='tituloImagenes'>
                                    <p>Cuéntanos tu problema</p>
                                </div>
                                : <div className='tituloImagenes'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>
                            }
                        </div>

                        {Number(seller) === codigoSears
                            ? <div className="boxSolicitarDevolucion marginT60">
                                {infoNoFunciona && infoNoFunciona?.refound[0]?.message_parent
                                    ? <p className='devolucionp1'>{infoNoFunciona?.refound[0]?.message_parent}</p>
                                    : <p className='devolucionp1'>Podrás hacer válida la garantía directamente con el fabricante del producto, ubica sus centros de servicio en la documentación que recibiste con tu compra.</p>
                                }

                                <Link to="/politica-devolucion" className=''>Políticas de Garantía</Link>

                                <div className="boxBtnContinuarDevolucion marginT60">
                                    <button className="btnContinuarDevolucion" onClick={handleEntendido}>Regresar a mis pedidos</button>
                                </div>
                            </div>
                            : <div className='boxSolicitarDevolucion marginT60'>

                                {infoSolicitarGarantia && infoSolicitarGarantia?.refound[0]?.message_parent
                                    ? <p className='devolucionp1'>{infoSolicitarGarantia?.refound[0]?.message_parent}</p>
                                    : <p className='devolucionp1'>Antes de solicitar garantía por favor revisa las condiciones colocadas en la descripción del artículo dentro del portal.</p>
                                }
                                <Link to="/politica-devolucion" className=''>Políticas de Garantía</Link>

                                <div className="boxBtnContinuarDevolucion marginT60">
                                    <button className="btnContinuarDevolucion" onClick={handleContinuar}>Continuar</button>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}
export function NoFunciona() {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <NoFuncionaDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <NoFuncionaMovil />
                : null
            }
        </>
    )
}