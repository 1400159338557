import React,{ useState } from 'react';
import './style.sass';
import { Link } from 'react-router-dom';

export default function LowerFooter(){

    const [terminos ] = useState({ title: "Términos y condiciones", url: "/terminos-condiciones/"})
    const [politicas ] = useState({ title: "Políticas de privacidad", url: "/aviso-privacidad/"})

    return (
        <div className="lowerfooter">
            <div className="legales">
                <Link to={`${terminos.url}`} title={terminos.title}  >{terminos.title}</Link>
                <span>|</span>
                <Link to={`${politicas.url}`} title={politicas.title} >{politicas.title}</Link>
            </div>
        </div>
    )
}