import React, { useState, useEffect } from 'react';
import { getPedido, postTYP, postGenerarGuia } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import FooterPortal from 'pages/Footer/FooterDesktop';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import './ayudaDinamicaDesktop.sass';
import { removeDuplicates } from 'functions/removeDuplicates';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { useToken } from 'hooks/useToken';
import { ModalError } from '../vista4-modal';
import ModalPortal from 'components/Modal';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';

export function TypDesktop() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];

    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [productoUnico, setProductoUnico] = useState(false);
    const [resultadoPedido, setResultadoPedido] = useState(false);

    const [saveStatusProducto,] = useStorage64('saveStatusProducto');
    const [estatusProducto, setEstatusProducto] = useState(0);
    const [loading, setLoading] = useState(false);

    const [direccionPedido, setDireccionPedido] = useStorage64('storageDireccionPedido');
    const [infoPostTYP, setInfoPostTYP] = useState(false);
    const [informacion, setInformacion] = useState(false);
    const [recoleccionDomicilio] = useState("storageRecoleccionDomicilio");
    const [esEnvioDomicilio, setEsEnvioDomicilio] = useState(false);
    const [direccionTiendaStorage] = useStorage64('storageDireccionTienda');
    const [idTicketStorage] = useStorage64('storageIdTicket');
    const [correoUsuario] = useStorage64('storageCorreoUsuario');
    const [sellerId, setSellerId] = useState(false);
    const [idTiendaFisica, setIdTiendaFisica] = useState(false);
    const [StorageOpcionDevolver] = useStorage64('storageDevolverOpcion');
    const [generarGuia, setGenerarGuia] = useState(false);
    let urlCheck = "/img/checkMark.svg";

    // console.log("storage opcion devolver -> ", StorageOpcionDevolver)
    // console.log(" direccion d typ d-- ", direccionPedido)

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedidos
    useEffect(() => {
        window.scrollTo(0, 0);

        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //post para obtener informacion typ
    useEffect(() => {

        if (idProductoEstate && idTicketStorage) {
            // envio a tienda
            if (Number(StorageOpcionDevolver) === 1) {
                let street = direccionTiendaStorage[0]?.street;
                let postal_code = direccionTiendaStorage[0]?.zip_code;
                let place_name = direccionTiendaStorage[0]?.name;
                let suburb = direccionTiendaStorage[0]?.suburb;
                let city = direccionTiendaStorage[0]?.state;
                let town = direccionTiendaStorage[0]?.province;
                let phone = direccionTiendaStorage[0]?.phone?.lada + direccionTiendaStorage[0]?.phone?.number;
                console.log("postTYP_1")
                postTYP({
                    "ticket_id": idTicketStorage,
                    "product_id": idProductoEstate,
                    "assosiated_address": {
                        "street": street,
                        "postal_code": postal_code,
                        "place_name": place_name,
                        "suburb": suburb,
                        "city": city,
                        "town": town,
                        "phone": phone
                    }
                })
                    .then(setInfoPostTYP);
            }
            // envio a domicilio
            else {
                let street = direccionPedido?.street;
                let postal_code = direccionPedido?.postal_code;
                let place_name = "";
                let suburb = direccionPedido?.suburb;
                let city = direccionPedido?.city;
                let town = direccionPedido?.state;
                let phone = direccionPedido?.phone;
                let company_name = decodeStorage('storageCompanyName');
                console.log("postTYP_2")
                
                console.log("company_name",company_name);

                console.log("lo que se envia TRAKING: ",{
                    "ticket_id": idTicketStorage,
                    "product_id": idProductoEstate,
                    "assosiated_address": {
                        "street": street,
                        "postal_code": postal_code,
                        "place_name": place_name,
                        "suburb": suburb,
                        "city": city,
                        "town": town,
                        "phone": phone
                    },
                    "tracking_data": {
                        "company_name": "Fedex"
                    }});

                postTYP({
                    "ticket_id": idTicketStorage,
                    "product_id": idProductoEstate,
                    "assosiated_address": {
                        "street": street,
                        "postal_code": postal_code,
                        "place_name": place_name,
                        "suburb": suburb,
                        "city": city,
                        "town": town,
                        "phone": phone
                    },
                    "tracking_data": {
                        "company_name": "Fedex"
                    }
                })
                    .then(setInfoPostTYP);
            }
        }
    }, [idProductoEstate, idTicketStorage, direccionTiendaStorage])

    useEffect(() => {
        if (infoPostTYP) {
            setInformacion(infoPostTYP.data.thankyou);
            setEsEnvioDomicilio(true);
        }
    }, [infoPostTYP])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido && infoPostTYP && generarGuia) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, infoPostTYP, generarGuia])


    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista96- typ -desktop" })
    const [tokenJWT, setTokenJWT] = useState(false)
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let producto = [];
        let imagenProducto = [];
        let idStatusProd = [];
        let shopProducto = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {

                if (idProductoEstate !== 0) {
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.id) === Number(idProductoEstate) && producto.push({ "id": elem?.id, "estatus_producto": elem?.status.id })
                    )

                    setEstatusProducto(saveStatusProducto);

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem?.images[0]?.url, "id": elem?.related_product_id })
                    )
                    let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                    setProductoUnico(limpiaProducto);

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && idStatusProd.push({ "idStatusP": elem?.status_id })
                    )

                    // guarda informacion sobre el vendedor del producto
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && shopProducto.push({ "idTienda": elem.shop.idTienda, "nameTienda": elem?.shop?.name, "public_shop_id": elem.shop.public_shop_id })
                    )
                    setSellerId(shopProducto[0].idTienda);
                    setIdTiendaFisica(shopProducto[0].public_shop_id);


                    if (!direccionPedido) {
                        setDireccionPedido(resultadoPedido.pedido[0]?.address_details);
                    }

                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {

                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);

                    if (Number(StorageOpcionDevolver) === 2) {
                        let street = direccionPedido?.street;
                        let postal_code = direccionPedido?.postal_code;
                        let place_name = "";
                        let suburb = direccionPedido?.suburb;
                        let city = direccionPedido?.city;
                        let town = direccionPedido?.state;
                        let phone = direccionPedido?.phone;
                        console.log("postTYP_3")
                        postTYP({
                            "ticket_id": idTicketStorage,
                            "product_id": idProductoEstate,
                            "assosiated_address": {
                                "street": street,
                                "postal_code": postal_code,
                                "place_name": place_name,
                                "suburb": suburb,
                                "city": city,
                                "town": town,
                                "phone": phone
                            }
                        })
                            .then(setInfoPostTYP);
                    }
                    else {
                        let street = direccionTiendaStorage[0]?.street;
                        let postal_code = direccionTiendaStorage[0]?.zip_code;
                        let place_name = direccionTiendaStorage[0]?.name;
                        let suburb = direccionTiendaStorage[0]?.suburb;
                        let city = direccionTiendaStorage[0]?.state;
                        let town = direccionTiendaStorage[0]?.province;
                        let phone = direccionTiendaStorage[0]?.phone?.lada + direccionTiendaStorage[0]?.phone?.number;
                        console.log("postTYP_4")
                        postTYP({
                            "ticket_id": idTicketStorage,
                            "product_id": idProductoEstate,
                            "assosiated_address": {
                                "street": street,
                                "postal_code": postal_code,
                                "place_name": place_name,
                                "suburb": suburb,
                                "city": city,
                                "town": town,
                                "phone": phone
                            }
                        })
                            .then(setInfoPostTYP);
                    }
                }, 2000);
            }
        }

    }, [resultadoPedido])


    // regresar a pagina pedidos
    const RegresarPedidos = () => {
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const handleBack = () => {
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    const [showModalError, SetShowModalError] = useState(false);
    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);
    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    // funcion para aplicar la descarga de etiqueta


    useEffect(() => {
        if (sellerId && idProductoEstate) {
            if (Number(StorageOpcionDevolver) === 1) {
                let subsidiary_id = direccionTiendaStorage[0]?.id;
                let seller_id = sellerId;
                // console.log("valor sellerid: ", sellerId, " valor id producto:", idProductoEstate)
                setLoading(true);

                postGenerarGuia({
                    "email": correoUsuario,
                    "related_product": idProductoEstate,
                    "ticket_id": idTicketStorage,
                    "subsidiary_id": idTiendaFisica,
                    "seller_id": seller_id,
                    "return_type_id": 1
                }).then(setGenerarGuia);
            }
            else {
                let subsidiary_id = idTiendaFisica;
                let seller_id = sellerId;

                setLoading(true);

                postGenerarGuia({
                    "email": correoUsuario,
                    "related_product": idProductoEstate,
                    "ticket_id": idTicketStorage,
                    "subsidiary_id": subsidiary_id,
                    "seller_id": seller_id,
                    "return_type_id": 4
                }).then(setGenerarGuia);
            }
        }

    }, [StorageOpcionDevolver, sellerId, idProductoEstate])
    // console.log(" id ticket: ", idTicketStorage)

    useEffect(() => {
        if (generarGuia) {
            // console.log("antes de etiqueta 1...", generarGuia);
            if (generarGuia && generarGuia.metadata?.http_status === 200) {
                // console.log(" se creo etiqueta...");
                setLoading(false);
            } else if (generarGuia && generarGuia.http_status === 401) {
                setTokenJWT(true);

                if (Number(StorageOpcionDevolver) === 1) {
                    let subsidiary_id = direccionTiendaStorage[0]?.id;
                    let seller_id = sellerId;

                    setLoading(true);

                    postGenerarGuia({
                        "email": correoUsuario,
                        "related_product": idProductoEstate,
                        "ticket_id": idTicketStorage,
                        "subsidiary_id": subsidiary_id,
                        "seller_id": seller_id,
                        "return_type_id": 4
                    }).then(setGenerarGuia);
                }
                else {
                    let subsidiary_id = idTiendaFisica;
                    let seller_id = sellerId;

                    setLoading(true);

                    postGenerarGuia({
                        "email": correoUsuario,
                        "related_product": idProductoEstate,
                        "ticket_id": idTicketStorage,
                        "subsidiary_id": subsidiary_id,
                        "seller_id": seller_id,
                        "return_type_id": 4
                    }).then(setGenerarGuia);
                }
            }
            else if (generarGuia.metadata?.http_status !== 200 && generarGuia.metadata?.http_status !== 401) {
                setLoading(false);
                SetShowModalError(true);
            }

        }
    }, [generarGuia])

    const DescargarEtiquetas = () => {
        if (generarGuia) {
            if (generarGuia?.metadata?.http_status === 200) {
                if(Number(StorageOpcionDevolver) === 2){
                    // console.log(" se creo etiqueta 2...", generarGuia);
                    window.open(`${generarGuia?.data?.external_axxi_response?.externalMessage?.link_guia}`,'_blank');
                }else{
                    //etiqueta para opción 1
                    window.open(`${generarGuia?.data?.external_axxi_response?.externalMessage?.link_etiqueta}`,'_blank');
                }
            }
            else if (generarGuia.metadata?.http_status !== 200 && generarGuia.metadata?.http_status !== 401) {
                SetShowModalError(true);
            }
        }
    }



    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">

                        <div className='menuMiCuentaAD'>
                            <MenuMiCuenta />
                        </div>

                        <div className='contenidoGeneralAD'>
                            <div className="boxBtnRegresarDktopRojo">
                                <div className="icoBackClose" onClick={handleBack}></div>
                                <button className="btnBackADLogo" onClick={handleBack}></button>
                            </div>

                            <div className="boxGeneralAD">
                                <div className="boxRightAD marginT60">
                                    <div className="boxCancelacionExitosa">
                                        <div className="boxContenido">

                                            <div>
                                                <img alt="check-pal" src={urlCheck} />
                                                {informacion && informacion.success_text.map((info, iPrincipal) =>
                                                    iPrincipal === 0 && <h3 key={iPrincipal} className="fontSize22">{info.message_parent}</h3>
                                                )}
                                            </div>

                                            {/*<div className='marginT30'>
                                                 <h3 className='fontSize22'>Debes entregar tu producto a más tardar {informacion && informacion.expiration.replace(/-/gi, "/")}</h3>
                                            </div>*/}

                                            <div className='marginT60 borderGray textLeft'>
                                                {Number(StorageOpcionDevolver) === 1
                                                    ? <>
                                                        <div className='contenedorTYPicon'>
                                                            <p className='icono'></p>
                                                            <h4 className='bold'>Dirección de tienda</h4>
                                                        </div>
                                                        {direccionTiendaStorage
                                                            ? <p className='styleP colorGray'>{direccionTiendaStorage[0].street} {direccionTiendaStorage[0].number}, {direccionTiendaStorage[0].suburb} C.P. {direccionTiendaStorage[0].zip_code}, {direccionTiendaStorage[0].province}, {direccionTiendaStorage[0].state}</p>
                                                            : null
                                                        }
                                                    </>
                                                    : <>
                                                        <div className='contenedorTYPicon'>
                                                            <h4 className='bold'>Dirección de recolección</h4>
                                                        </div>
                                                        <p className='styleP colorGray'>{direccionPedido.street} {direccionPedido.suburb} {direccionPedido.postal_code}, {direccionPedido.city}, {direccionPedido.state}</p>
                                                    </>
                                                }
                                            </div>

                                            <>
                                                {informacion && informacion.success_text.map((info, iPrincipal) =>
                                                    iPrincipal === 1 &&
                                                    <div className='marginT60 textLeft' key={iPrincipal}>
                                                        <h3 className='bold '>{info?.message_parent}</h3>

                                                        {info.child != null &&
                                                            <ul className='marginT30'>
                                                                {info.child.map((hijos, i) =>
                                                                    hijos.type === 'object' ?
                                                                        <h3 className='bold '>{hijos.message_parent?.company_name}</h3>
                                                                        :<li key={i} className="styleP">{hijos?.message_parent}</li>
                                                                )}
                                                            </ul>
                                                        }
                                                    </div>
                                                )}
                                            </>

                                        </div>

                                        <div className="boxBtnRegresar marginB40 marginT60">
                                            {Number(StorageOpcionDevolver) === 2
                                                ? generarGuia?.data?.external_axxi_response?.externalMessage?.status === 200 ? <button className="btnRegresar" onClick={DescargarEtiquetas}>Descargar etiquetas</button> : <button className="btnRegresar" onClick={RegresarPedidos}>Regresar a mis pedidos</button>
                                                : generarGuia?.data?.external_axxi_response?.externalMessage?.status === 200 ? <button className="btnRegresar" onClick={DescargarEtiquetas}>Descargar etiquetas</button> : <button className="btnRegresar" onClick={RegresarPedidos}>Ir a mis pedidos</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {showModalError &&
                    <ModalPortal type="modalSB" onClose={handleCloseError} >
                        <ModalError okRegresar={regresarPedidos} />
                    </ModalPortal>
                }
            </main>
            <FooterPortal />
        </>
    )

}