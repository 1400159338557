import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';

export function ServicioConexionDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Servicio de conexion Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Servicio de Conexión</p>
                            </div>
                        </section>
                        <div className="contentPreguntas">
                            <div className="conexion">

                                <div className="conexionR">
                                    <div className="question1">
                                        <p>Solo válido en productos con la etiqueta de Servicio de Conexión.</p>

                                        <p className="h3red">1. La instalación incluye:</p>

                                        <ul className="ulQuestionDisc pleft">
                                            <li>Conexión a la toma corriente.</li>
                                            <li>Revisión de presión de agua.</li>
                                            <li>Revisión de nivel de voltaje (127 VCA).</li>
                                            <li>Revisión de la correcta polaridad del contacto.</li>
                                            <li>Nivelación del aparato.</li>
                                            <li>Demostración de funciones básicas.</li>
                                            <li>Recomendaciones para el correcto funcionamiento.</li>
                                            <li>Desanclaje LAVADORA.</li>
                                            <li>Conexión de mangueras agua fría y caliente LAVADORA.</li>
                                            <li>Colocación de la manguera de drenado LAVADORA.</li>
                                            <li>Conexión de toma de agua REFRIGERADOR.</li>
                                            <li>Conexión.</li>
                                            <li>Quitar y poner puertas de sus aparatos.</li>
                                        </ul>
                                    </div>

                                    <div className="question1">
                                        <p className="h3red">2. Que NO incluye la conexión:</p>

                                        <ul className="ulQuestionDisc pleft">
                                            <li>Desconectar el aparato usado.</li>
                                            <li>Trabajos de plomería.</li>
                                            <li>Trabajos de electricidad.</li>
                                            <li>Maniobras y desplazamientos del aparato.</li>
                                            <li>Materiales adicionales.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="conexionL">
                                    <div className="question">
                                        <div className="img">
                                            <img src="/img/servicio-tecnico.png" alt="servio-tecnico" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function ServicioConexionMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Clientes Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Servicio de Conexión</p>
                    </div>

                    <div className="dataModal">
                        <div className="boxAN">

                            <div className="bxSlider">
                                <div className="contenedorImagenes">
                                    <ul>
                                        <img src="/img/servicio-tecnico.png" alt={"logo-servicio-tecnico"} width={100} />
                                    </ul>
                                </div>


                                <p className="pSlider">Solo válido en productos con la etiqueta de Servicio de Conexión</p>
                                <p className="h3 colorRed">Incluye:</p>

                                <ul className="ulDeclaraciones">
                                    <li>Conexión a la toma corriente</li>
                                    <li>Revisión de presión de agua</li>
                                    <li>Revisión de nivel de voltaje (127 VCA)</li>
                                    <li>Revisión de la correcta polaridad del contacto</li>
                                    <li>Nivelación del aparato</li>
                                    <li>Demostración de funciones básicas</li>
                                    <li>Recomendaciones para el correcto funcionamiento</li>
                                    <li>Desanclaje LAVADORA</li>
                                    <li>Conexión de mangueras agua fría y caliente LAVADORA</li>
                                    <li>Colocación de la manguera de drenado LAVADORA</li>
                                    <li>Conexión de toma de agua REFRIGERADOR</li>
                                    <li>Conexión</li>
                                    <li>Quitar y poner puertas de sus aparatos</li>
                                </ul>

                                <p className="h3 colorRed">NO incluye:</p>

                                <ul className="ulDeclaraciones">
                                    <li>Desconectar el aparato usado.</li>
                                    <li>Trabajos de plomería</li>
                                    <li>Trabajos de electricidad</li>
                                    <li>Maniobras y desplazamientos del aparato</li>
                                    <li>Materiales adicionales</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function ServicioConexion() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina
    useEffect(() => {
        window.ineum('page', 'Servicio de conexión');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <ServicioConexionDesktop />

                : isMobile === 'movile'
                    ? null
                    : <ServicioConexionMobile />
            }
        </>
    )
}