import React, { useState, useEffect } from 'react';
import { getPedido } from 'services/AyudaDinamicaServices';
import CardProductDetail, { CardProductDetailMobile } from 'components/CardProduct/CardMyAccount/CardProductDetail';
import { useHistory, useLocation } from 'react-router-dom';
import './detallePedido.sass';
import Loading from 'components/Loading';
import Header from 'pages/Header/HeaderNavegacion';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useIsMobile } from 'hooks/useIsMobile';
import Footer from 'pages/Footer/FooterDesktop';
import CardProductDetailDesktop from 'components/CardProduct/CardMyAccount/CardProductDetail';

import { decodeStorage } from 'functions/storageBase64';
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';

export function DetallePedidoDesktop(props) {
    const history = useHistory();
    const params = props.location.pathname;
    const separar = params.split('/');
    let idPedido
    const [idPedidoShow, setIdPedidoShow] = useState()
    const [idPedidoM, setIdPedidoM] = useState(idPedido)

    const [products, setProducts] = useState([]);
    const [buy_date, setBuyDate] = useState();
    const [total, setTotal] = useState(0);
    const [costoEnvio, setCostoEnvio] = useState();
    const [costoProducto, setCostoProducto] = useState();
    const [entregarA, setEntregarA] = useState();
    const [direccionEnvio, setDireccionEnvio] = useState();
    const [tipoMesa, setTipoMesa] = useState();
    const [, setEstatusPedido] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idFp, setIdFp] = useState();
    const [nombreFp, setNombreFp] = useState();
    const [nombreStatus, setNombreStatus] = useState();

    // MOSTRAR LOADING HASTA QUE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [resultadoPedido]);

    // se realiza la peticion del pedido solicitado
    useEffect(() => {
        if(separar[3] === 'dias'){
            history.replace(`/mi-cuenta/mis-pedidos/dias=30/pagina=1`)
        }
        else if(separar.length === 4){
            if(separar[3].indexOf('dias') >= 0 ){
                history.replace(`/mi-cuenta/mis-pedidos/dias=30/pagina=1`)
            }else{
                idPedido = separar[3]
            }
        }else if(separar.length === 6){
            idPedido = separar[5]
            history.replace(`/mi-cuenta/mis-pedidos/${idPedido}`)
        }else{
            idPedido = separar[2]
            history.replace(`/mi-cuenta/mis-pedidos/${idPedido}`)
        }
    }, []);

    useEffect(() => {
        if(idPedido){
            setIdPedidoM(idPedido)
            setIdPedidoShow(idPedido)
        }
    }, [idPedido]);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton

        const refreshCyR = useToken({firstActive: false, name: "detallePedidoDesckop", timeRef: 1500})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        setTimeout(function(){
            setActionOk('ready')
        },700)
    }, []);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK, refreshCyR])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            // Va al servicio para traer las direcciones
            if(serviceRT){
                if(actionOK === 'ready'){
                    if(idPedidoShow){
                        setServiceRT(false)
                        setActionOk(false)
                        getPedido(idPedidoShow)
                        .then(setResultadoPedido)
                        setIdPedidoShow()
                    }
                }
            }
        }, [actionOK, idPedidoShow, serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    // se obtiene la informacion que se necesita mostrar del pedido
    useEffect(() => {
        if (resultadoPedido) {
            if (resultadoPedido?.pedido) {
                setBuyDate(resultadoPedido?.pedido[0]?.buy_date);
                setTotal(resultadoPedido?.pedido[0]?.total);
                setProducts(resultadoPedido?.pedido[0]?.products);
                setEstatusPedido(resultadoPedido?.pedido[0]?.axxi?.status_axxi_id);
                setCostoEnvio(resultadoPedido?.pedido[0]?.shipping);
                setCostoProducto(resultadoPedido?.pedido[0]?.sub_total);
                setEntregarA(resultadoPedido?.pedido[0]?.deliery_to);
                setTipoMesa(resultadoPedido?.pedido[0]?.tipo_mesa);
                setDireccionEnvio(resultadoPedido?.pedido[0]?.address);
                setIdFp(resultadoPedido?.pedido[0]?.payment_method?.id);
                setNombreFp(resultadoPedido?.pedido[0]?.payment_method?.name);
                setNombreStatus(resultadoPedido?.pedido[0]?.status?.name);
            }
        }
    }, [resultadoPedido]);

    // regresar a la pagina anterior
    const onClose = () => {
        history.goBack(-1);
    };

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const [traePedido, setTraePedido] = useState(null)
    useEffect(()=>{
        if(resultadoPedido?.pedido){
            if(resultadoPedido?.pedido[0]?.order_number){
                setTraePedido(resultadoPedido?.pedido[0]?.order_number)
            }
        }
    },[resultadoPedido])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            {loading && <Loading />}
            <main>
                <section className="miCuentaContainer">
                    <div className="container myAccountSection">

                        <MenuMiCuenta />

                        <div className="detallePedido full">
                            <div className="contentModal">

                                <div className="contentClose">
                                    <div className="btn close" onClick={onClose} ></div>
                                    <p className="title">Pedido No. {idPedidoM}</p>
                                </div>
                                <div className="dataModal">
                                    {traePedido
                                        ?
                                            <CardProductDetailDesktop
                                                total={total} 
                                                buy_date={buy_date} 
                                                products={products}
                                                costoEnvio={costoEnvio}
                                                costoProducto={costoProducto}
                                                direccionEnvio={direccionEnvio}
                                                entregarA={entregarA}
                                                idFp={idFp}
                                                nombreFp={nombreFp}
                                                nombreStatus={nombreStatus}
                                                loading={loading}
                                                tipoMesa={tipoMesa}
                                            />
                                        : null
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export function DetallePedidoMobile(props) {
    const history = useHistory();
    const params = props.location.pathname;
    const separar = params.split('/');
    let idPedido
    const [idPedidoShow, setIdPedidoShow] = useState()
    const [idPedidoM, setIdPedidoM] = useState(idPedido)

    const [products, setProducts] = useState([]);
    const [buy_date, setBuyDate] = useState();
    const [total, setTotal] = useState(0);
    const [costoEnvio, setCostoEnvio] = useState();
    const [costoProducto, setCostoProducto] = useState();
    const [entregarA, setEntregarA] = useState();
    const [direccionEnvio, setDireccionEnvio] = useState();
    const [, setEstatusPedido] = useState(0);
    const [tipoMesa, setTipoMesa] = useState();
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idFp, setIdFp] = useState();
    const [nombreFp, setNombreFp] = useState();
    const [nombreStatus, setNombreStatus] = useState();

    // MOSTRAR LOADING HASTA QUE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [resultadoPedido]);

    // se realiza la peticion del pedido solicitado
    useEffect(() => {
        if(separar[3] === 'dias'){
            history.replace(`/mi-cuenta/mis-pedidos/dias=30/pagina=1`)
        }
        else if(separar.length === 4){
            if(separar[3].indexOf('dias') >= 0 ){
                history.replace(`/mi-cuenta/mis-pedidos/dias=30/pagina=1`)
            }else{
                idPedido = separar[3]
            }
        }else if(separar.length === 6){
            idPedido = separar[5]
            history.replace(`/mi-cuenta/mis-pedidos/${idPedido}`)
        }else{
            idPedido = separar[2]
            history.replace(`/mi-cuenta/mis-pedidos/${idPedido}`)
        }
    }, []);

    useEffect(() => {
        if(idPedido){
            setIdPedidoM(idPedido)
            setIdPedidoShow(idPedido)
        }
    }, [idPedido]);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton

        const refreshCyR = useToken({firstActive: false, name: "detallePedidoMovil", timeRef: 1000})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        setTimeout(function(){
            setActionOk('ready')
        },700)
    }, []);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK, refreshCyR])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            // Va al servicio para traer las direcciones
            if(serviceRT){
                if(actionOK === 'ready'){
                    if(idPedidoShow){
                        setServiceRT(false)
                        setActionOk(false)
                        getPedido(idPedidoShow)
                        .then(setResultadoPedido)
                        setIdPedidoShow()
                    }
                }
            }
        }, [actionOK, idPedidoShow, serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    // se obtiene la informacion que se necesita mostrar del pedido
    useEffect(() => {
        if (resultadoPedido) {
            if (resultadoPedido?.pedido) {
                setBuyDate(resultadoPedido?.pedido[0]?.buy_date);
                setTotal(resultadoPedido?.pedido[0]?.total);
                setProducts(resultadoPedido?.pedido[0]?.products);
                setEstatusPedido(resultadoPedido?.pedido[0]?.axxi?.status_axxi_id);
                setCostoEnvio(resultadoPedido?.pedido[0]?.shipping);
                setCostoProducto(resultadoPedido?.pedido[0]?.sub_total);
                setEntregarA(resultadoPedido?.pedido[0]?.deliery_to);
                setDireccionEnvio(resultadoPedido?.pedido[0]?.address);
                setTipoMesa(resultadoPedido?.pedido[0]?.tipo_mesa);
                setIdFp(resultadoPedido?.pedido[0]?.payment_method?.id);
                setNombreFp(resultadoPedido?.pedido[0]?.payment_method?.name);
                setNombreStatus(resultadoPedido?.pedido[0]?.status?.name);
            }
        }
    }, [resultadoPedido]);

    // regresar a la pagina anterior
    const onClose = () => {
        history.goBack(-1);
    };

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    
    const [traePedido, setTraePedido] = useState(null)
    useEffect(()=>{
        if(resultadoPedido?.pedido){
            if(resultadoPedido?.pedido[0]?.order_number){
                setTraePedido(resultadoPedido?.pedido[0]?.order_number)
            }
        }
    },[resultadoPedido])

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedidoMobile full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Pedido No. {idPedidoM}</p>
                    </div>
                    <div className="dataModalMobile">
                    {traePedido
                        ?
                            <CardProductDetailMobile
                                total={total} 
                                buy_date={buy_date} 
                                products={products}
                                costoEnvio={costoEnvio}
                                costoProducto={costoProducto}
                                direccionEnvio={direccionEnvio}
                                entregarA={entregarA}
                                idFp={idFp}
                                nombreFp={nombreFp}
                                nombreStatus={nombreStatus}
                                loading={loading}
                            />
                        : null
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export function DetallePedido(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <DetallePedidoDesktop location={props.location}
                    />
                    : isMobile === 'movile'
                        ? null
                        : <DetallePedidoMobile location={props.location}
                        />
                : null
            }
        </>
    )
}
