import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import { BASE_PORTAL } from 'constants/general';
import { Accordion, AccordionItem } from 'react-light-accordion';

export function FaqCreditoDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Preguntas Frecuentes Crédito');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>
            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>
                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Preguntas Frecuentes Crédito</p>
                            </div>
                        </section>
                        <div className="contentDudas">
                            <div className="dudas2 espacio2">
                                <h3>Ya me autorizaron mi tarjeta ¿Cuánto tiempo se tardan en enviar mi tarjeta?</h3>
                                <p className='answers'>Una vez autorizada tu crédito Sears, se enviará a tu domicilio en un lapso de 7 a 15 días hábiles. Puedes acudir a una tienda Sears a solicitar la tarjeta plástica o una tarjeta temporal válida por un día.</p>
                            </div>
                            <div className="dudas2 espacio2">
                                <h3>Si el pago realizado en tienda no se ve reflejado en la cuenta ¿Con quién se tendría que dirigir para solicitar la aplicación del pago?</h3>
                                <p className='answers'>Tiene que llamar al <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a> donde se le dará el seguimiento, es importante tener su ticket de pago en mano.</p>
                            </div>
                            <div className="dudas2 espacio2">
                                <h3>Si al realizar la compra no se respeta la promoción solicitada, ¿Con quién tiene que solicitar la aplicación correcta?</h3>
                                <p className='answers'>Tienen que llamar al <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a> es importante tener su ticket de compra a la mano y verificar que este tenga marcada la promoción solicitada, si no es este el caso, debe acudir a la Tienda directamente.</p>
                            </div>
                            <div className="dudas2 espacio2">
                                <h3>¿Cómo puedo utilizar los puntos generados en mí crédito Sears?</h3>
                                <p className='answers'>Los puntos pueden ser utilizados al realizar compras en las tiendas Sears, solo debe indicarse al vendedor que tu compra la harás con tus puntos acumulados. Debes tener al menos 500 para tu primera compra.</p>
                            </div>
                            <div className="dudas2 espacio2">
                                <h3>¿Por qué mis compras no generan puntos?</h3>
                                <p className='answers'>Las compras que generan puntos son las que se marcan exclusivamente al plan revolvente a excepción de perfumería.</p>
                            </div>
                            <div className="dudas2 espacio2">
                                <h3>Reporté mi tarjeta perdida. ¿Cuánto tiempo se tardan en reponérmela?</h3>
                                <p className='answers'>Una vez reportada en los números <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a>, en un plazo de 7 a 15 días se enviará tu reposición.</p>
                            </div>
                            <div className="dudas2 espacio2">
                                <h3>¿Cuál es el CAT de la tarjeta de crédito?</h3>
                                <p className='answers'>CAT 30.10% promedio ponderado. Para fines informativos. Septiembre 2, 2022</p>
                            </div>
                            <div className="dudas2 espacio2">
                                <h3>Si no tengo una sucursal cercana de Sears ¿Qué opciones tengo para pagar?</h3>
                                <ul className="ulD2">
                                    <li className='liD2'>
                                        Internet <a className="liA" href={`${BASE_PORTAL}/Credito-Sears/pago-express`}>www.sears.com.mx/Credito-Sears/pago-express/</a>
                                    </li>
                                    <li className='liD2'>
                                        Tiendas Sanborns
                                    </li>
                                    <li className='liD2'>
                                        Tiendas Dax
                                    </li>
                                    <li className='liD2'>
                                        Banamex, portal bancario o en sucursales
                                    </li>
                                    <div>
                                        <div className='tablePayment'>
                                            <div>
                                                <p>Sucursal</p>
                                                <p>Cuenta</p>
                                                <p>Referencia</p>
                                                <p>CLABE</p>
                                            </div>
                                            <div>
                                                <p>870</p>
                                                <p>540457</p>
                                                <p>Número de cuenta de la tarjeta</p>
                                                <p>002180087005404571</p>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="dudas2 espacio2">
                                <h3>¿Puedo consultar mi saldo o ver mi estado de cuenta en línea?</h3>
                                <p className='answers'>Si, puedes consultarlo, con tu NIP, en la sección “CREDITO” y después “Estado de Cuenta". Para solo consultar su saldo puedes hacerlo en la página o comunícate al <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a>, marca # y después 2 para consultar el saldo sin necesidad de algún ejecutivo.</p>
                            </div>
                            <div className="dudas2 espacio2">
                                <h3>¿Cómo puedo obtener mi NIP?</h3>
                                <p className='answers'>Acude al departamento de Servicio al Cliente de tu tienda Sears más cercana, o al teléfono <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a></p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export function FaqCreditoMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Preguntas Frecuentes Crédito');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal contentModalPadd">

                    <div className="contentClose contentC">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Preguntas Frecuentes Crédito</p>
                    </div>

                    <div className="dataModal">
                        <div className="dataModal padding10PF">
                            <div className="boxAN">
                                <div className="bxSlider">

                                    <Accordion atomic={true}>

                                        <AccordionItem title="Ya me autorizaron mi tarjeta ¿Cuánto tiempo se tardan en enviar mi tarjeta?">
                                            <p className="pSliderL">Una vez autorizada tu crédito Sears, se enviará a tu domicilio en un lapso de 7 a 15 días hábiles. Puedes acudir a una tienda Sears a solicitar la tarjeta plástica o una tarjeta temporal válida por un día.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Si el pago realizado en tienda no se ve reflejado en la cuenta ¿Con quién se tendría que dirigir para solicitar la aplicación del pago?">
                                            <p className="pSliderL">Tiene que llamar al <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a> donde se le dará el seguimiento, es importante tener su ticket de pago en mano.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Si al realizar la compra no se respeta la promoción solicitada, ¿Con quién tiene que solicitar la aplicación correcta?">
                                            <p className="pSliderL">Tienen que llamar al <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a> es importante tener su ticket de compra a la mano y verificar que este tenga marcada la promoción solicitada, si no es este el caso, debe acudir a la Tienda directamente.</p>
                                        </AccordionItem>

                                        <AccordionItem title="¿Cómo puedo utilizar los puntos generados en mí crédito Sears?">
                                            <p className="pSliderL">Los puntos pueden ser utilizados al realizar compras en las tiendas Sears, solo debe indicarse al vendedor que tu compra la harás con tus puntos acumulados. Debes tener al menos 500 para tu primera compra.</p>
                                        </AccordionItem>

                                        <AccordionItem title="¿Por qué mis compras no generan puntos?">
                                            <p className="pSliderL">Las compras que generan puntos son las que se marcan exclusivamente al plan revolvente a excepción de perfumería.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Reporté mi tarjeta perdida. ¿Cuánto tiempo se tardan en reponérmela?">
                                            <p className="pSliderL">Una vez reportada en los números <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a>, en un plazo de 7 a 15 días se enviará tu reposición.</p>
                                        </AccordionItem>

                                        <AccordionItem title="¿Cuál es el CAT de la tarjeta de crédito?">
                                            <p className="pSliderL">CAT 30.10% promedio ponderado. Para fines informativos. Septiembre 2, 2022</p>
                                        </AccordionItem>

                                        <AccordionItem title="Si no tengo una sucursal cercana de Sears ¿Qué opciones tengo para pagar?">
                                            <p className="pSliderL">Puedes realizar tus compras con cualquiera de las siguientes formas de pago:</p>
                                            <ul className="ulDeclaracionesDiscAccordion">
                                                <li>Internet <a href={`${BASE_PORTAL}/Credito-Sears/pago-express`}>www.sears.com.mx/Credito-Sears/pago-express</a></li>
                                                <li>Tiendas Sanborns</li>
                                                <li>Tiendas Dax</li>
                                                <li>Banamex, portal bancario o en sucursales</li>
                                            </ul>
                                            <div className="pSliderL">
                                                <p>Sucursal: 870</p>
                                                <p>Cuenta: 540457</p>
                                                <p>Referencia: Número de cuenta de la tarjeta</p>
                                                <p>CLABE: 002180087005404571</p>
                                            </div>

                                        </AccordionItem>

                                        <AccordionItem title="¿Puedo consultar mi saldo o ver mi estado de cuenta en línea?">
                                            <p className="pSliderL">Si, puedes consultarlo, con tu NIP, en la sección “CREDITO” y después “Estado de Cuenta". Para solo consultar su saldo puedes hacerlo en la página o comunícate al <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a>, marca # y después 2 para consultar el saldo sin necesidad de algún ejecutivo.</p>
                                        </AccordionItem>

                                        <AccordionItem title="¿Cómo puedo obtener mi NIP?">
                                            <p className="pSliderL">Acude al departamento de Servicio al Cliente de tu tienda Sears más cercana, o al teléfono <a href='tel:015551303900'>55-5130-3900</a> o <a href='tel:8009002123'>800-900-2123</a></p>
                                        </AccordionItem>

                                    </Accordion>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function FaqCredito() {
    const { isMobile } = useIsMobile();

    useEffect(() => {
        window.ineum('page', 'Preguntas Frecuentes Crédito');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <FaqCreditoDesktop />

                : isMobile === 'movile'
                    ? null
                    : <FaqCreditoMobile />
            }
        </>
    )
}