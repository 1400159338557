import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import { ElegirTiendaDesktop } from './AD-Desktop/vista95-elegirTienda-desktop';
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import { API_KEY } from 'constants/general';
import getLocalizadorTiendaS from 'services/getLocalizadorTiendas';
import { repareTextSimple } from 'functions/repareDataService';
import { useStorage64 } from 'hooks/useStorageBase64';
import { postCreateTiket } from 'services/AyudaDinamicaServices';
import { ModalError } from './vista4-modal';
import ModalPortal from 'components/Modal';
const { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } = require("react-google-maps");


export function ElegirTiendaMovil({ tiendas, response, loading2 }) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [mostrarUno, setMostrarUno] = useState(true);
    const [mostrarDos, setMostrarDos] = useState(false);
    const [valorSearch, setValorSearch] = useState('');
    const search = useRef(null);
    const [, setBandera] = useState(false);
    let respuesta = [];
    const focusTextInput = () => search.current.focus();

    const { isMobile } = useIsMobile();
    const urlGoogle = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${API_KEY}`;
    const latitudMexico = 19.4381193;     // LATITUD INICIAL DE SEARS POLANCO (TIENDA PRINCIPAL)
    const longitudMexico = -99.2069279;   // LONGITUD INICIAL DE SEARS POLANCO (TIENDA PRINCIPAL)
    const [latitud, setLatitud] = useState(latitudMexico);
    const [longitud, setLongitud] = useState(longitudMexico);
    // LATITUD Y LONGITUD ALMACENADA CUANDO EL USUARIO YA DIO PERMISOS
    const storageLatitud = decodeStorage('latitud');
    const storageLongitud = decodeStorage('longitud');
    const [isOpen, setIsOpen] = useState(false);
    const [ventanaAbierta, setVentanaAbierta] = useState(false);
    const [primeraPosicion, setPrimeraPosicion] = useState([]);

    //variables para crear ticket
    const [crearTicket, setCrearTicket] = useState(false);
    const [infoTicketEntregado] = useStorage64("infoTicketEntregado");
    const [storageActionId] = useStorage64("storageActionId");
    const [storageMotivoEntregado] = useStorage64("storageMotivoEntregado");
    const [storageImgProducto] = useStorage64("storageImgProducto");
    const [storageDatosBancarios] = useStorage64("storageDatosBancarios");
    const [storageDevolverOpcion] = useStorage64("storageDevolverOpcion");
    const [correoUsuario] = useStorage64('storageCorreoUsuario');
    const [, setDireccionTiendaStorage] = useStorage64('storageDireccionTienda');

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'AD elegir tiendas mobile');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    // flag para mostrar pestana de mapa
    const handleMostrarUno = () => {
        setMostrarDos(false);
        setMostrarUno(true);
    }

    // flag para mostrar pestana de listado
    const handleMostrarDos = () => {
        setMostrarUno(false);
        setMostrarDos(true);
    }

    // revisa si se tiene valor en el campo de busqueda
    useEffect(() => {
        if (valorSearch.length > 0) {
            setBandera(true);
        } else {
            setBandera(false);
        }
    }, [valorSearch])

    // borrar el contenido buscado
    const borrar = (e) => {
        if (Number(e.keyCode) === 8) {
            if (valorSearch.length === 1)
                setValorSearch("");
            if (valorSearch.length === 0)
                setValorSearch("");
        }
    }

    // funcion llamada en el boton buscar del input
    const buscar = (e) => {
        if (String(e.target.value) === "") {
            setValorSearch('');
        }
        if (String(e.target.value) !== "") {
            setValorSearch(e.target.value);
        }
    }

    // detecta la longitud y latitud inicial
    useEffect(() => {
        // ya tiene una ubicacion permitida
        if (storageLatitud && storageLongitud) {
            setLatitud(Number(storageLatitud));
            setLongitud(Number(storageLongitud));
        }
        // no tiene ubicacion permitida
        else {
            // revisa si explorardor tiene geolocalizacion
            if (isMobile && isMobile !== 'desktop') {
                if ("geolocation" in navigator) {
                    console.log("Geolocalization Available");
                    encodeStorage('permisoNegado', "permitido");

                    // solicita coordenadas 
                    navigator.geolocation.getCurrentPosition(position => {
                        encodeStorage('latitud', position.coords.latitude);
                        encodeStorage('longitud', position.coords.longitude);
                        setLatitud(position.coords.latitude);
                        setLongitud(position.coords.longitude);
                        encodeStorage('permisoNegado', "permitido");
                    }, e => {
                        console.log("usuario rechaza permisos. ", e);
                        if (e.code === e.PERMISSION_DENIED) {
                            // pop up dialog asking for location
                            encodeStorage('permisoNegado', "negado");
                            removeStorage("latitud")
                            removeStorage("longitud")
                            setLatitud(latitudMexico);
                            setLongitud(longitudMexico);
                        }
                        setMostrarUno(false);
                        setMostrarDos(true);
                    });
                }
                // explorador no tiene disponible geolocalizacion
                else {
                    console.log("Geolocalization not Available.");
                    setLatitud(latitudMexico);
                    setLongitud(longitudMexico);
                }
            }
        }
    }, [])

    // abre ventana de direccion del marcador seleccionado
    const handleToggle = (e, id) => {
        setVentanaAbierta(id);
        setIsOpen(!isOpen);
    }

    // mapa general con todos los marcadores
    const GoogleMapLocalizador = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: Number(latitud), lng: Number(longitud) }}
            center={{ lat: Number(latitud), lng: Number(longitud) }}
        >
            {props.isMarkerShown &&
                tiendas.map((marker, id) => (
                    marker.status === true && <Fragment key={id}>
                        <Marker
                            position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
                            key={marker.id}
                            labelStyle={{ textAlign: "center", width: '220px', fontSize: "14px", paddingTop: "8px", marginTop: "30px" }}
                            labelAnchor={{ x: (220 / 2) + 8, y: 80 }}
                            label={{ text: `${marker.name}`, color: "#e4002b", top: "20px", position: "relative", fontWeight: "400" }}
                            onClick={(e) => handleToggle(e, marker.id)}
                            icon={{
                                url: '/img/pinSearsPeque.png',
                                labelOrigin: new window.google.maps.Point(85, 45),
                                anchor: new window.google.maps.Point(16, 32)
                            }}
                            labelClass='map-price-marker'
                            markerWithLabel={window.MarkerWithLabel}
                            labelContent="<div class='marker'></div>"
                        >

                            {isOpen && ventanaAbierta === marker.id &&

                                <InfoWindow onCloseClick={(e) => handleToggle(e, marker.id)}  >
                                    <div className="ventanaInfo">
                                        <div className="nameTienda">
                                            <p>{marker.name}</p>
                                        </div>
                                        <div className="direccionTienda">
                                            <span></span>
                                            <p>{marker.street} {marker.number} {marker.suburb}, {marker.zin_code}, {marker.state}</p>
                                        </div>
                                        <div className="horarioTienda">
                                            <span></span>
                                            <p>Horario de {marker.schedule}</p>
                                        </div>
                                        <div className="telefonoTienda">
                                            <span></span>
                                            <p>Tel. ({marker.phone.lada}) {marker.phone.number}</p>
                                        </div>

                                        <div className="botones">
                                            <div className="btnElegir">
                                                <button className="elegir" onClick={(e) => ElegirTienda(marker.external_id, marker.name , marker.street, marker.number, marker.suburb, marker.zip_code, marker.state, marker.province, marker.phone)}>Elegir esta tienda</button>
                                            </div>
                                        </div>
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    </Fragment>
                ))
            }
        </GoogleMap>
    ))

    // mapa con marcador filtrado
    const GoogleMapLocalizadorConFiltro = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
            center={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
        >
            <Marker
                position={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
                key={primeraPosicion.id}
                labelStyle={{ textAlign: "center", width: '220px', fontSize: "14px", paddingTop: "8px", fontWeight: "400" }}
                labelAnchor={{ x: (220 / 2) + 8, y: 80 }}
                onClick={(e) => handleToggle(e, primeraPosicion.id)}
                label={{ text: `${primeraPosicion.name}`, color: "#e4002b" }}
                icon={{
                    url: '/img/pinSearsPeque.png',
                    labelOrigin: new window.google.maps.Point(85, 45),
                    anchor: new window.google.maps.Point(16, 32)
                }}
            >
                {isOpen && ventanaAbierta === primeraPosicion.id &&

                    <InfoWindow onCloseClick={(e) => handleToggle(e, primeraPosicion.id)}  >
                        <div className="ventanaInfo">
                            <div className="nameTienda">
                                <p>{primeraPosicion.name}</p>
                            </div>
                            <div className="direccionTienda">
                                <span></span>
                                <p> {primeraPosicion.street} {primeraPosicion.number} {primeraPosicion.suburb}, {primeraPosicion.zin_code}, {primeraPosicion.state}</p>
                            </div>
                            <div className="horarioTienda">
                                <span></span>
                                <p>Horario de {primeraPosicion.schedule}</p>
                            </div>
                            <div className="telefonoTienda">
                                <span></span>
                                <p>Tel. ({primeraPosicion.phone.lada}) {primeraPosicion.phone.number}</p>
                            </div>

                            <div className="botones">
                                <div className="btnElegir">
                                    <button className="elegir" onClick={(e) => ElegirTienda(primeraPosicion.external_id, primeraPosicion.name,primeraPosicion.street, primeraPosicion.number, primeraPosicion.suburb, primeraPosicion.zip_code, primeraPosicion.state,  primeraPosicion.province, primeraPosicion.phone)}>Elegir esta tienda</button>
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                }
            </Marker>

        </GoogleMap>
    ))

    // obtiene la primera posicion del resultado de busqueda
    useEffect(() => {
        if (respuesta.length > 0) {
            setPrimeraPosicion(respuesta[0]);
            setLatitud(respuesta[0].latitude);
            setLongitud(respuesta[0].longitude);
        }
    }, [respuesta])

    // actualiza la ubicacion guardada del usuario
    const ClickPedirUbicacion = () => {

        if (storageLatitud && storageLongitud) {
            setLatitud(Number(storageLatitud));
            setLongitud(Number(storageLongitud));
        }
        else {
            // revisa si explorardor tiene geolocalizacion
            if ("geolocation" in navigator) {
                console.log("Geolocalization Available");

                // solicita coordenadas 
                navigator.geolocation.getCurrentPosition(position => {
                    encodeStorage('latitud', position.coords.latitude);
                    encodeStorage('longitud', position.coords.longitude);
                    setLatitud(position.coords.latitude);
                    setLongitud(position.coords.longitude);
                    encodeStorage('permisoNegado', "permitido");
                }, e => {
                    console.log("usuario rechaza permisos. ", e);
                    encodeStorage('permisoNegado', "negado");
                    setMostrarUno(false);
                    setMostrarDos(true);
                    removeStorage("latitud");
                    removeStorage("longitud");
                });
            }
            // explorador no tiene disponible geolocalizacion
            else {
                console.log("Geolocalization not Available.");
                setLatitud(latitudMexico);
                setLongitud(longitudMexico);
            }
        }
    }

    // regresar a la pagina anterior
    const onClose = () => {
        history.goBack(-1);
    }

    //********************* FUNCIONAMIENTO PARA AYUDA DINAMICA ************************ */
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    const [showModalError, SetShowModalError] = useState(false);
    const [remover, setRemover] = useState(false);

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i]?.indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    //guarda los parametros de la tienda seleccionada
    const ElegirTienda = (idTienda, name, street, number, suburb, zip_code, state, province, phone) => {

        let direccionTienda = [{ "id":idTienda, "name": name, "street": street, "number": number, "suburb": suburb, "zip_code": zip_code, "state": state, "province": province, "phone": phone }];
        setDireccionTiendaStorage(direccionTienda);

        let client_id = infoTicketEntregado.client_id;
        let order_id = infoTicketEntregado.order_id;
        let order_number = infoTicketEntregado.order_number;
        let ticket_type_id = infoTicketEntregado.ticket_type_id;
        let action_id = storageActionId;
        let reason_id = infoTicketEntregado.reason_id;
        let description = storageMotivoEntregado;
        let refound_details;
        if (storageDatosBancarios) {
            refound_details = storageDatosBancarios;
        } else {
            refound_details = [];
        }

        let products_to_cancel = [{ "id": infoTicketEntregado.products_to_cancel[0].id, "return_type": storageDevolverOpcion, "subsidiary_id": idTienda }];
        let additional_details = storageImgProducto;
        let correo = correoUsuario;

        setRemover(true);

        postCreateTiket({
            reason_id,
            order_id,
            order_number,
            client_id,
            description,
            products_to_cancel,
            ticket_type_id,
            refound_details,
            additional_details,
            action_id,
            correo
        })
            .then(setCrearTicket);

    }

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    const [idTicketStorage, setIdTicketStorage] = useStorage64('storageIdTicket');
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {

                    if (idProductoEstate !== 0) {
                        setIdTicketStorage(crearTicket.data.id);
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/typ");
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }


    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">
                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Elige una tienda</p>
                    </div>

                    {loading2 && <Loading />}

                    {response &&
                        response.statusCode === 200
                        ? tiendas &&
                        <div className="dataModal dataModalLocalizador">

                            <div className="barraInferiorDudas barraTiendas">
                                <div className="barraDudas">
                                    <p className={mostrarUno === true ? 'activaSeccionLocalizador p1Dudas' : 'noActivaDudas p1Dudas'} onClick={handleMostrarUno}>Tiendas cercanas</p>
                                    <p className={mostrarDos === true ? 'activaSeccionLocalizador p2Dudas' : 'noActivaDudas p2Dudas'} onClick={handleMostrarDos}>Listado</p>
                                </div>
                            </div>

                            <div className="boxTiendasMobile">
                                {mostrarUno &&
                                    <>
                                        <div className="bxUpdateGeo">
                                            <p onClick={ClickPedirUbicacion}>Actualizar ubicación</p>
                                        </div>

                                        <div className="mapaTiendas">
                                            <GoogleMapLocalizador
                                                isMarkerShown={true}
                                                googleMapURL={urlGoogle}
                                                containerElement={<div style={{ height: '550px', marginBottom: '30px' }} />}
                                                mapElement={<div style={{ height: "100%" }} />}
                                                loadingElement={<p>Cargando...</p>}
                                                center={{ lat: Number(latitud), lng: Number(longitud) }}
                                            />
                                        </div>
                                    </>
                                }

                                {mostrarDos &&
                                    <>
                                        <div className="buscadorTienda">
                                            <label>
                                                <input aria-label="Buscador Tiendas" autoComplete="off" type="search" placeholder="Buscar por nombre de tienda o C.P."
                                                    id="buscadorStore" className="buscadorStore"
                                                    value={valorSearch}
                                                    ref={search}
                                                    onChange={(text1) => buscar(text1)}
                                                    onKeyDown={(e) => borrar(e)}>
                                                </input>
                                            </label>
                                        </div>

                                        <div className="listadoTiendas">
                                            {tiendas && tiendas.filter((dataInterna) => {
                                                if (valorSearch == null || valorSearch === '') {
                                                    return dataInterna
                                                }
                                                else if (
                                                    repareTextSimple(String(dataInterna.name).toLowerCase()).includes(repareTextSimple(String(valorSearch).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.state).toLowerCase()).includes(repareTextSimple(String(valorSearch).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.suburb).toLowerCase()).includes(repareTextSimple(String(valorSearch).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.zip_code).toLowerCase()).includes(repareTextSimple(String(valorSearch).toLowerCase()))
                                                ) {
                                                    respuesta.push(dataInterna)
                                                    return dataInterna
                                                }
                                            }).map((tienda, indice) =>
                                                tienda.status === true && <>
                                                    <div className="cardTienda">

                                                        <div className="titulo">{String(tienda.name).toUpperCase()}</div>

                                                        <div className="direccion">
                                                            <span></span>
                                                            <p>{tienda.street} {tienda.number} {tienda.suburb}, C.P. {tienda.zip_code}, {tienda.state}</p>
                                                        </div>

                                                        <div className="horario">
                                                            <span></span>
                                                            <p>Horario de {tienda.schedule}</p>
                                                        </div>

                                                        <div className="telefono">Tel. ({tienda.phone.lada}) {tienda.phone.number}</div>

                                                        <div className="botones">
                                                            <div className="btnElegir">
                                                                <button className="elegir" onClick={(e) => ElegirTienda(tienda.external_id, tienda.name, tienda.street, tienda.number, tienda.suburb, tienda.zip_code, tienda.state,  tienda.province, tienda.phone)}>Elegir esta tienda</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                            )}

                                            {valorSearch.length > 0 && respuesta.length === 0
                                                && <div className="bxSinResultados">
                                                    <p>No se encontraron tiendas con el nombre o c.p. indicado.</p>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        : null
                    }

                    {showModalError &&
                        <ModalPortal type="modalSB" onClose={handleCloseError} >
                            <ModalError okRegresar={regresarPedidos} />
                        </ModalPortal>
                    }
                </div>
            </div>
        </>
    )
}


export function ElegirTienda(props) {
    const { isMobile } = useIsMobile();
    const [response, setResponse] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tiendas, setTiendas] = useState([]);

    //- Funcion que va al servicio de localizador de tiendas
    useEffect(() => {
        getLocalizadorTiendaS()
            .then(setResponse);
    }, [])

    //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
    useEffect(() => {
        if (response) {
            if (response.statusCode === 200) {
                if (response?.data) {
                    if (response?.data?.data) {
                        setTiendas(response?.data?.data);
                    }
                }
            }
        }
    }, [response])

    useEffect(() => {
        if (tiendas) {
            if (Object.keys(tiendas).length > 0) {
                setLoading(false);
            }
        }
    }, [tiendas])


    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <ElegirTiendaDesktop tiendas={tiendas} response={response} loading2={loading} />
                    : isMobile === 'movile'
                        ? null
                        : <ElegirTiendaMovil tiendas={tiendas} response={response} loading2={loading} />
                : null
            }
        </>
    )
}