import React, { useState, useEffect } from 'react';
import { getPedido, postCreateTiket, postGenerarLinks, putObtenerLinks } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import ImageUploader from "react-images-upload";
import swal from 'sweetalert'
import { useStorage64 } from 'hooks/useStorageBase64';
import { FORMA_PAGO_POST_PAGO, FORMA_PAGO_REEMBOLSABLE, NO_IMAGE } from 'constants/general';
import Loading from 'components/Loading';
import { ModalError } from './vista4-modal';
import ModalPortal from 'components/Modal';
import { useIsMobile } from 'hooks/useIsMobile';
import { AdjuntarFotografiasDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista36-adjuntarFotografias-desktop.js';
import { useToken } from 'hooks/useToken';
import { removeDuplicates } from 'functions/removeDuplicates';

export function AdjuntarFotografiasMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [cargoFotos, setCargoFotos] = useState(false);
    const [pictures, setPictures] = useState([]);

    //nueva forma de cargar imagenes
    const [pictures1, setPictures1] = useState([]);
    const [longitud1, setLongitud1] = useState();
    const [pictures2, setPictures2] = useState([]);
    const [longitud2, setLongitud2] = useState();
    const [pictures3, setPictures3] = useState([]);
    const [longitud3, setLongitud3] = useState();
    const [pictures4, setPictures4] = useState([]);
    const [longitud4, setLongitud4] = useState();
    const [picturesArray, setPicturesArray] = useState();

    const [storageReasonId] = useStorage64('storageIdProblem');
    const [storageActionId] = useStorage64('storageActionId');
    const [storageMotivoEntregado] = useStorage64('storageMotivoEntregado');
    const [, setStorageImgProducto] = useStorage64('storageImgProducto');
    const profileData = useStorage64('profileData');
    const [idClaro, setIdClaro] = useState(false);
    const [, setEstatusPedido] = useState(0);
    const [formaPago, setFormaPago] = useState(0);
    const [orderNumber, setOrderNumber] = useState(0);
    const [almacenarImagenes, setAlmacenarImagenes] = useState(false);
    const [loading, setLoading] = useState(false);
    const [remover, setRemover] = useState(false);
    const [crearTicket, setCrearTicket] = useState(false);
    const [showModalError, SetShowModalError] = useState(false);
    const [ima, setima] = useState(false);

    // nuevas variables para flujo de sears
    const [, setGuardarDatosTicket] = useStorage64('storageDatosActualizadosTicket');
    const [peso_volumetrico, setPeso_volumetrico] = useState(false);
    const [YaNoSePuedeRegalarProducto] = useStorage64("storageyanosepuederegalar");
    const [, setStorageOpcionDevolver] = useStorage64('storageDevolverOpcion', '');
    const [correoUsuario] = useStorage64('storageCorreoUsuario');
    const [shopId, setShopId] = useState(false);
    const [, setRecoleccionDomicilio] = useState("storageRecoleccionDomicilio", "");
    const [VieneDeNoTerminoComoEsperabamos] = useStorage64("storageEsUnRegalo");
    const [precioProducto, setPrecioProducto] = useState(false);
    const [montoParaComparar, setMontoParaComparar] = useState(false);
    const [idTiendaFisica, setIdTiendaFisica] = useState(false);
    const [tipoGuia, setTipoGuia] = useState(false);

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // obtengo el id del usuario del storage
    useEffect(() => {
        if (profileData != "undefined") {
            setIdClaro(profileData[0]?.idClaro);
        }
    }, [profileData])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista36-uploadImagenes-mobile" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];
        let pesovolumetrico = [];
        let shopProducto = [];
        let precio = [];
        let montoComparacion = [];
        let arrayGuia = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                    setOrderNumber(resultadoPedido.pedido[0].order_number);

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                    )
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && pesovolumetrico.push({ "peso_volumetrico": elem.dynamic_help?.return_by_volumetric_weight })
                    )
                    // guarda informacion sobre el vendedor del producto
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && shopProducto.push({ "idTienda": elem.shop?.idTienda, "nameTienda": elem.shop?.name })
                    )
                    // almacena el precio del producto
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && precio.push({ "price": elem?.price })
                    )
                    // almacena el monto con el que se compara el precio
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && montoComparacion.push({ "monto": elem?.dynamic_help?.price_compare })
                    )
                    // guarda informacion sobre el tipo de guia
                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        Number(elem.related_product_id) === Number(idProductoEstate) && arrayGuia.push({ "tipoGuia": elem.tracking.guide_type })
                    )

                    let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                    setima(limpioImagen);

                    setPeso_volumetrico(pesovolumetrico[0].peso_volumetrico);
                    setShopId(shopProducto[0].idTienda);
                    setPrecioProducto(precio[0]?.price);
                    setMontoParaComparar(montoComparacion[0]?.monto);
                    setIdTiendaFisica(shopProducto[0].public_shop_id);
                    setTipoGuia(arrayGuia[0].tipoGuia);
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {

                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // cerrar modal, regresa 1 en el historial
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a cual es tu motivo
    const [respuestaUpload, setRespuestaUpload] = useState(false);
    const [newArrayImages, setnewArrayImages] = useState(false);
    const [respuestaPut1, setRespuestaPut1] = useState(false);
    const [respuestaPut2, setRespuestaPut2] = useState(false);
    const [respuestaPut3, setRespuestaPut3] = useState(false);
    const [respuestaPut4, setRespuestaPut4] = useState(false);
    const [longitud, setLongitud] = useState();
    const [ruta1, setRuta1] = useState(false);
    const [ruta2, setRuta2] = useState(false);
    const [ruta3, setRuta3] = useState(false);
    const [ruta4, setRuta4] = useState(false);
    const [activarPut, setActivarPut] = useState(false);

    const handleContinuar = () => {
        let arrayImagenes = [];
        let unionArrays = [pictures1[pictures1.length - 1], pictures2[pictures2.length - 1], pictures3[pictures3.length - 1], pictures4[pictures4.length - 1]];
        let arrayLongitud = [longitud1, longitud2, longitud3, longitud4];
        let imagenesIngresadas = arrayLongitud.filter(numero => numero === 1).length;

        // si tiene menos de 3 imagenes
        if (imagenesIngresadas < 3) {
            swal({
                type: 'info',
                text: "¡Aún te faltan fotos! Deben ser mínimo 3. ",
                timer: "4000",
                showConfirm: false
            })
        }
        else if (imagenesIngresadas >= 3) {
            // si tiene 3 o mas imagenes
            setPicturesArray(unionArrays);

            unionArrays.map((ima) =>
                ima != undefined
                    ? ima.length > 0 &&
                    arrayImagenes.push({ "lastModified": ima[0]?.lastModified, "name": ima[0]?.name, "size": ima[0]?.size, "type": ima[0]?.type, "webkitRelativePath": ima[0]?.webkitRelativePath })
                    : null
            )

            //guarda longitud para saber si son 3 o 4 imagenes
            setLongitud(arrayImagenes.length);
            setActivarPut(true);

        }
    }

    //----- envio de servicio post para reservar espacio para imagenes ----------
    useEffect(() => {
        if (idClaro && orderNumber && idProductoEstate) {
            //const urlPrincipio = idClaro + "/" + orderNumber + "/";
            const urlPrincipio = orderNumber + "/";
            const arrayImagenes = [urlPrincipio + "image_1_" + idProductoEstate + ".jpg", urlPrincipio + "image_2_" + idProductoEstate + ".jpg", urlPrincipio + "image_3_" + idProductoEstate + ".jpg", urlPrincipio + "image_4_" + idProductoEstate + ".jpg"];

            postGenerarLinks(arrayImagenes)
                .then(setRespuestaUpload);
        }
    }, [idClaro, orderNumber, idProductoEstate])

    // -------------- guardo respuesta post upload --------------
    useEffect(() => {
        if (respuestaUpload?.statusCode === 200) {
            setnewArrayImages(respuestaUpload?.data?.[`signed-urls`]);
        }
    }, [respuestaUpload])

    //** PRIMERA PETICION PUT **/ 
    useEffect(() => {
        if (activarPut === true) {
            setLoading(true);

            putObtenerLinks(
                { imagen: ruta1[0], url: newArrayImages[0] }
            )
                .then(setRespuestaPut1);
        }
    }, [activarPut])

    //** SEGUNDA PETICION PUT **/ 
    useEffect(() => {
        if (respuestaPut1.statusCode === 200) {
            setLoading(true);

            putObtenerLinks(
                { imagen: ruta2[0], url: newArrayImages[1] }
            )
                .then(setRespuestaPut2);
        }
    }, [respuestaPut1])

    //** TERCERA PETICION PUT **/ 
    useEffect(() => {
        if (respuestaPut2.statusCode === 200) {
            setLoading(true);
            putObtenerLinks(
                { imagen: ruta3[0], url: newArrayImages[2] }
            )
                .then(setRespuestaPut3);
        }
    }, [respuestaPut2])

    //** CUARTA PETICION PUT **/ 
    useEffect(() => {
        if (respuestaPut3.statusCode === 200) {
            if (longitud > 3) {
                setLoading(true);

                putObtenerLinks(
                    { imagen: ruta4[0], url: newArrayImages[3] }
                )
                    .then(setRespuestaPut4);

            } else {
                setLoading(false);

                setTimeout(function () {
                    setAlmacenarImagenes(true);
                }, 1000);

                setActivarPut(false);
            }
        }
    }, [respuestaPut3])

    //** ACCION CUANDO TIENE 4 IMAGENES LUEGO DEL PUT **/ 
    useEffect(() => {
        if (respuestaPut4.statusCode === 200) {

            setLoading(false);

            setTimeout(function () {
                setAlmacenarImagenes(true);
            }, 1000);

            setActivarPut(false);
        }

    }, [respuestaPut4])


    useEffect(() => {
        if (almacenarImagenes === true) {
            setAlmacenarImagenes(false);
            //let urlParaImagen = idClaro + "/" + orderNumber + "/";
            let urlParaImagen = orderNumber + "/";
            let arrayImagenes = [];

            picturesArray.map((ima, i) =>
                ima != undefined
                    ? ima.length > 0 &&
                        // arrayImagenes.push({ "document_url": urlParaImagen + "image_" + (i + 1) + "_" + idProductoEstate + "." + String(ima[0].type).split("/")[1], "order": i + 1 })
                        arrayImagenes.push({ "document_url": urlParaImagen + "image_" + (i + 1) + "_" + idProductoEstate + ".jpg", "order": i + 1 })
                    : null
            )
            setStorageImgProducto(arrayImagenes);

            //------ crear ticket ------------
            let reason_id = storageReasonId;
            let order_id = orderNumber;
            let order_number = orderNumber;
            let client_id = idClaro;
            let description = storageMotivoEntregado ? storageMotivoEntregado : "";
            let ticket_type_id = 4; // siempre es 4, si es devolucion
            let refound_details = [];
            let action_id = storageActionId ? storageActionId : 0;
            let additional_details = arrayImagenes;
            let correo = correoUsuario;

            // Si forma de pago es paypal , tarjeta credito / debito, etc. y es 
            // TENGO UN PROBLEMA CON MI PRODUCTO, ACTIONS: 1, 2, 3, 4 - ACTIONS: 8 (no es lo que compre),9(mi producto parece usado o de exhibicion)
            console.log("Entra en las imagenes cargadas");
            console.log("FORMA_PAGO_REEMBOLSABLE.includes(formaPago)", FORMA_PAGO_REEMBOLSABLE,formaPago);
            console.log("storageReasonId, storageActionId", storageReasonId, storageActionId);
            if (FORMA_PAGO_REEMBOLSABLE.includes(formaPago) &&
                ((storageReasonId === 9 && storageActionId === 1) ||
                    (storageReasonId === 9 && storageActionId === 2) ||
                    (storageReasonId === 9 && storageActionId === 3) ||
                    (storageReasonId === 9 && storageActionId === 4))) {

                //--------- viene de flujo: no termino como esperabamos GRATIS
                if (VieneDeNoTerminoComoEsperabamos === true) {

                    let products_to_cancel = [{ "id": idProductoEstate, "return_type": null, "subsidiary_id": null }];
                    setRemover(true); // activa loading    

                    postCreateTiket({
                        reason_id,
                        order_id,
                        order_number,
                        client_id,
                        description,
                        products_to_cancel,
                        ticket_type_id,
                        refound_details,
                        additional_details,
                        action_id,
                        correo
                    })
                        .then(setCrearTicket);
                }

                //FLUJO --- viene de reason 9. tengo un problema con mi producto, action: 1,2,3,4 menor a 200 pero ha devuelto 2 Y NO SE PUEDE REGALAR
                if (YaNoSePuedeRegalarProducto === true) {
                    setStorageOpcionDevolver(1);
                    history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/elegir-tienda");
                }
                if (YaNoSePuedeRegalarProducto === false && (precioProducto >= montoParaComparar)) {
                    history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/como-prefieres-devolver");
                }
            }

            // Si forma de pago es paypal , tarjeta credito / debito, etc. y es 
            // TENGO UN PROBLEMA CON MI PRODUCTO, ACTIONS: 8 (no es lo que compre),9(mi producto parece usado o de exhibicion)
            else if (FORMA_PAGO_REEMBOLSABLE.includes(formaPago) &&
                ((storageReasonId === 9 && storageActionId === 8) ||
                    (storageReasonId === 9 && storageActionId === 9))) {
                // verifica el peso del producto menor a 60 kg
                if (peso_volumetrico === true) {
                    // se guardan en storage imagenes cargadas
                    setStorageImgProducto(additional_details);

                    // redireccion a como prefieres devolver tu producto
                    history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/como-prefieres-devolver");
                }
                // peso mayor a 60kg, se crea ticket de devolucion
                else {
                    setRemover(true);
                    let products_to_cancel = [{ "id": idProductoEstate, "return_type": 2, "subsidiary_id": null }];
                    // se crea ticket 
                    postCreateTiket({
                        reason_id,
                        order_id,
                        order_number,
                        client_id,
                        description,
                        products_to_cancel,
                        ticket_type_id,
                        refound_details,
                        additional_details,
                        action_id,
                        correo
                    })
                        .then(setCrearTicket);
                }
            }

            //caso forma de pago reembolsable y 9. tengo un problema con mi producto - 
            //10. mi producto no funciona correctamente o no enciende
            else if (storageReasonId === 9 && storageActionId === 10) {
                let products_to_cancel = [{ "id": idProductoEstate, "return_type": null, "subsidiary_id": null }];
                // se crea ticket 
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }

            //caso forma de pago reembolsable y 9. tengo un problema con mi producto - 11. mi producto esta maltratado o roto
            //caso forma de pago reembolsable y 14. caso recibi el paquete vacio
            else if (FORMA_PAGO_REEMBOLSABLE.includes(formaPago) &&
                (storageReasonId === 14 || (storageReasonId === 9 && storageActionId === 11))) {
                let products_to_cancel = [{ "id": idProductoEstate, "return_type": null, "subsidiary_id": null }];

                // se crea ticket 
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }

            //forma de pago no reembolsable y 9. tengo un problema con mi producto - 1,2,3,4
            //forma de pago no reembolsable y 9. tengo un problema con mi producto - 8. no es lo que compre
            //forma de pago no reembolsable y 9. tengo un problema con mi producto - 9. mi producto parece usado o de exhibicion
            //forma de pago no reembolsable y 9. tengo un problema con mi producto - 11. mi producto esta maltratado o roto
            //forma de pago no reembolsable y 9. tengo un problema con mi producto - 9. mi producto parece usado o de exhibicion
            //forma de pago no reembolsable y 14. caso recibi el paquete vacio
            else if (FORMA_PAGO_POST_PAGO.includes(formaPago) &&
                ((storageReasonId === 9 && storageActionId === 11) ||
                    storageReasonId === 14 ||
                    (storageReasonId === 9 && storageActionId === 9) ||
                    (storageReasonId === 9 && storageActionId === 8) ||
                    (storageReasonId === 9 && storageActionId === 9) ||
                    (storageReasonId === 9 && (storageActionId === 1 || storageActionId === 2 || storageActionId === 3 || storageActionId === 4)))) {

                // se guardan en storage imagenes cargadas
                setStorageImgProducto(additional_details);

                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/datos-bancarios");
            }
        }
    }, [almacenarImagenes])

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    const [idTicketStorage, setIdTicketStorage] = useStorage64('storageIdTicket');
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {
                    if (idProductoEstate === 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                    }
                    else if (idProductoEstate !== 0) {
                        setIdTicketStorage(crearTicket.data.id);
                        //--------- viene de flujo: no termino como esperabamos
                        if (VieneDeNoTerminoComoEsperabamos === true) {
                            history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
                        }
                        // es reason 9 y action: 8 ó 9
                        // es reason 9 y action 1, 2, 3, 4
                        if ((storageReasonId === 9 && storageActionId === 8) || (storageReasonId === 9 && storageActionId === 9) ||
                            (storageReasonId === 9 && (storageActionId === 1 || storageActionId === 2 || storageActionId === 3 || storageActionId === 4))) {
                            //es guia automatica
                            if (tipoGuia === 2) {
                                setRecoleccionDomicilio(2);
                                history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/typ");
                            }
                            //no es guia automatica
                            else {
                                history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/seller-typ");
                            }
                        }
                        //cualquier otro caso de cancelacion por producto
                        else {
                            history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cancelacion-exitosa");
                        }
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    // almacena en el array las fotos subidas posicion1
    const onDrop1 = (picture) => {
        let ruta1 = document.querySelectorAll('input[name="upload1"]')[0].files;
        setRuta1(ruta1);
        setLongitud1(picture.length);
        setPictures1([...pictures1, picture]);
        setPictures([...pictures, picture]);
    }

    // almacena en el array las fotos subidas posicion2
    const onDrop2 = (picture) => {
        let ruta2 = document.querySelectorAll('input[name="upload2"]')[0].files;
        setRuta2(ruta2);
        setLongitud2(picture.length);
        setPictures2([...pictures2, picture]);
        setPictures([...pictures, picture]);
    }

    // almacena en el array las fotos subidas posicion3
    const onDrop3 = (picture) => {
        let ruta3 = document.querySelectorAll('input[name="upload3"]')[0].files;
        setRuta3(ruta3);
        setLongitud3(picture.length);
        setPictures3([...pictures3, picture]);
        setPictures([...pictures, picture]);
    }

    // almacena en el array las fotos subidas posicion2
    const onDrop4 = (picture) => {
        let ruta4 = document.querySelectorAll('input[name="upload4"]')[0].files;
        setRuta4(ruta4);
        setLongitud4(picture.length);
        setPictures4([...pictures4, picture]);
        setPictures([...pictures, picture]);
    }

    // revisa el valor del array de pictures para activar o desactivar el boton de continuar
    useEffect(() => {
        if ((pictures1[pictures1.length - 1] !== undefined && pictures1[pictures1.length - 1].length > 0) ||
            (pictures2[pictures2.length - 1] !== undefined && pictures2[pictures2.length - 1].length > 0) ||
            (pictures3[pictures3.length - 1] !== undefined && pictures3[pictures3.length - 1].length > 0) ||
            (pictures4[pictures4.length - 1] !== undefined && pictures4[pictures4.length - 1].length > 0)
        ) {
            setCargoFotos(true);
        }
        else {
            setCargoFotos(false);
        }
    }, [pictures1, pictures2, pictures3, pictures4])

    // detectar maximo de fotos cargado y minimo
    useEffect(() => {
        if (pictures1[pictures1.length - 1] !== undefined && pictures1[pictures1.length - 1].length > 0) {
            document.querySelector('.chooseFileButton').classList.add('noneButton');
            document.querySelector('.aniadePhotos').classList.add('noneButton');
        }
        else {
            document.querySelector('.chooseFileButton').classList.remove('noneButton');
            document.querySelector('.aniadePhotos').classList.remove('noneButton');
        }
    }, [pictures1])

    useEffect(() => {
        if (pictures2[pictures2.length - 1] !== undefined && pictures2[pictures2.length - 1].length > 0) {
            document.querySelector('.chooseFileButton2').classList.add('noneButton');
            document.querySelector('.aniadePhotos2').classList.add('noneButton');
        }
        else {
            document.querySelector('.chooseFileButton2').classList.remove('noneButton');
            document.querySelector('.aniadePhotos2').classList.remove('noneButton');
        }
    }, [pictures2])

    useEffect(() => {
        if (pictures3[pictures3.length - 1] !== undefined && pictures3[pictures3.length - 1].length > 0) {
            document.querySelector('.chooseFileButton3').classList.add('noneButton');
            document.querySelector('.aniadePhotos3').classList.add('noneButton');
        }
        else {
            document.querySelector('.chooseFileButton3').classList.remove('noneButton');
            document.querySelector('.aniadePhotos3').classList.remove('noneButton');
        }
    }, [pictures3])

    useEffect(() => {
        if (pictures4[pictures4.length - 1] !== undefined && pictures4[pictures4.length - 1].length > 0) {
            document.querySelector('.chooseFileButton4').classList.add('noneButton');
            document.querySelector('.aniadePhotos4').classList.add('noneButton');
        }
        else {
            document.querySelector('.chooseFileButton4').classList.remove('noneButton');
            document.querySelector('.aniadePhotos4').classList.remove('noneButton');
        }
    }, [pictures4])

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Adjuntar imágenes:</p>
                    </div>
                    <div className="dataModal">
                        <div className='headerImagenesTitulo'>
                            {ima && ima.length > 1
                                ? <div className="contenedorImagenesMas1">
                                    <ul className='ulMas1'>
                                        {ima && ima.map((elemento, i) => i <= 2
                                            && <li key={i}>
                                                <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={50} />
                                            </li>
                                        )}
                                        {/* mas de 3 */}
                                        {ima?.length > 3 && <li className="mas1"> más ({ima.length - 3})</li>}
                                    </ul>
                                </div>
                                : <div className="contenedorImagenesIgual1">
                                    <ul className='ulIgual1'>
                                        {ima && ima.map((elemento, i) =>
                                            <li key={i}>
                                                <img src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={100} />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            }
                            <div className='tituloImagenes'><p>A continuación te mostraremos algunos ejemplos de las imágenes que deberás subir.</p></div>
                        </div>

                        <div className="boxGeneralAdjuntar">

                            <div className="boxFotos">
                                <div className='tituloporfoto'>
                                    <li>1 foto de la caja en la que llegó tu producto.</li>
                                </div>
                                <div className='boxInternoPorFoto'>
                                    <div className='referencia'>
                                        <img src='/img/ejemplo1.png' alt='ejemplo-1'></img>
                                    </div>
                                    <div className='carga'>
                                        <ImageUploader
                                            withIcon={false}
                                            withPreview={true}
                                            buttonText=""
                                            onChange={onDrop1}
                                            imgExtension={[".jpg", ".jpeg", ".png"]}
                                            maxFileSize={10485760} //{5242880}
                                            fileTypeError={"No es una extensión de archivo compatible, debe ser JPG ó PNG"}
                                            fileSizeError={"El tamaño del archivo es demasiado grande."}
                                            name="upload1"
                                            singleImage={true}
                                        />

                                        <h3 className="aniadePhotos">Sube tu fotografía aquí</h3>
                                    </div>
                                </div>



                                <div className='tituloporfoto'>
                                    <li>1 foto de la etiqueta de la guía que venía pegada a tu paquete.</li>
                                </div>
                                <div className='boxInternoPorFoto'>
                                    <div className='referencia'>
                                        <img src='/img/ejemplo2.svg' alt='ejemplo-2'></img>
                                    </div>
                                    <div className='carga'>
                                        <ImageUploader
                                            withIcon={false}
                                            withPreview={true}
                                            buttonText=""
                                            onChange={onDrop2}
                                            imgExtension={[".jpg", ".jpeg", ".png"]}
                                            maxFileSize={10485760} //{5242880}
                                            fileTypeError={"No es una extensión de archivo compatible, debe ser JPG ó PNG"}
                                            fileSizeError={"El tamaño del archivo es demasiado grande."}
                                            buttonClassName={"chooseFileButton2"}
                                            name="upload2"
                                            singleImage={true}
                                        />

                                        <h3 className="aniadePhotos2">Sube tu fotografía aquí</h3>
                                    </div>
                                </div>



                                <div className='tituloporfoto'>
                                    <li>1 foto de la caja del producto.</li>
                                </div>
                                <div className='boxInternoPorFoto'>
                                    <div className='referencia'>
                                        <img src='/img/ejemplo3.png' alt='ejemplo-3'></img>
                                    </div>
                                    <div className='carga'>
                                        <ImageUploader
                                            withIcon={false}
                                            withPreview={true}
                                            buttonText=""
                                            onChange={onDrop3}
                                            imgExtension={[".jpg", ".jpeg", ".png"]}
                                            maxFileSize={10485760} //{5242880}
                                            fileTypeError={"No es una extensión de archivo compatible, debe ser JPG ó PNG"}
                                            fileSizeError={"El tamaño del archivo es demasiado grande."}
                                            buttonClassName={"chooseFileButton3"}
                                            name="upload3"
                                            singleImage={true}
                                        />

                                        <h3 className="aniadePhotos3">Sube tu fotografía aquí</h3>
                                    </div>
                                </div>



                                <div className='tituloporfoto'>
                                    <li>1 foto del producto (únicamente en caso de que ya esté abierto).</li>
                                </div>
                                <div className='boxInternoPorFoto'>
                                    <div className='referencia'>
                                        <img src='/img/ejemplo4.png' alt='ejemplo-4'></img>
                                    </div>
                                    <div className='carga'>
                                        <ImageUploader
                                            withIcon={false}
                                            withPreview={true}
                                            buttonText=""
                                            onChange={onDrop4}
                                            imgExtension={[".jpg", ".jpeg", ".png"]}
                                            maxFileSize={10485760} //{5242880}
                                            fileTypeError={"No es una extensión de archivo compatible, debe ser JPG ó PNG"}
                                            fileSizeError={"El tamaño del archivo es demasiado grande."}
                                            buttonClassName={"chooseFileButton4"}
                                            name="upload4"
                                            singleImage={true}
                                        />

                                        <h3 className="aniadePhotos4">Sube tu fotografía aquí</h3>
                                    </div>
                                </div>

                            </div>

                            <p className="copy1 copyGris">Asegurate que se vean bien los datos y el producto.</p>
                            <p className="copy2 copyGris">Aceptamos jpg ó png de hasta 10 MB de tamaño.</p>

                            <div className="boxBtnContinuarFotos">
                                <button className="btnContinuarFotos" onClick={handleContinuar} disabled={cargoFotos ? false : true}>Continuar</button>
                            </div>
                        </div>


                        {showModalError &&
                            <ModalPortal type="modalSB" onClose={handleCloseError} >
                                <ModalError okRegresar={regresarPedidos} />
                            </ModalPortal>
                        }
                    </div>

                </div>
            </div >
        </>
    )
}

export function AdjuntarFotografias() {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <AdjuntarFotografiasDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <AdjuntarFotografiasMovil />
                : null
            }
        </>
    )
}