import React, { useEffect, useState } from 'react';
import './style.sass';
import ReactDOM from 'react-dom';

function Modal({ ...props }) {
    const {
        type,
        title,
        cerrar,
        envioRadioButton,
        ordenamiento
    } = props;

    const [valorRadio, setValorRadio] = useState();

    useEffect(()=>{
        setValorRadio("orden"+ordenamiento);
    },[ordenamiento])

    const handleValorRadio = (valor) => {
        setValorRadio(valor.target.id);
    };

    const handleAplicar = () => {
        if (valorRadio === 'orden5') {
            envioRadioButton(5);
        }
        if (valorRadio === 'orden2') {
            envioRadioButton(2);
        }
        if (valorRadio === 'orden3') {
            envioRadioButton(3);
        }
        if (valorRadio === 'orden4') {
            envioRadioButton(4);
        }
        cerrar();
    };

    return (
        <div className={`modal ${type}`}>
            <div className="contentModal">
                <div className="contentCloseAN2 paddingLR">
                    <div className="btn closeAN2" onClick={cerrar}></div>
                    {title ? <p className="title ">{title}</p> : null}
                </div>
                <div className="dataModal11">

                    <div className="internoFiltros" aria-label="opciones" >
                        <div className="contenedorEstrellas" onChange={handleValorRadio}>
                            <p className="tituloOrden">Ordenar por: </p>
                            <label name="orden5" htmlFor="orden5">
                                <span className="conNumero">
                                    <span className="numero">Más recientes</span>
                                </span>

                                <input id="orden5" name="opciones" value="orden5" type="radio" checked={"orden5" === valorRadio ? true : false}></input>
                            </label>

                            <label name="orden4" htmlFor="orden4">
                                <span className="conNumero">
                                    <span className="numero">Más antiguas</span>
                                </span>

                                <input id="orden4" name="opciones" value="orden4" type="radio" checked={"orden4" === valorRadio ? true : false}></input>
                            </label>

                            <label name="orden3" htmlFor="orden3">
                                <span className="conNumero">
                                    <span className="numero">Mayor valoración</span>
                                </span>

                                <input id="orden3" name="opciones" value="orden3" type="radio" checked={"orden3" === valorRadio ? true : false}></input>
                            </label>

                            <label name="orden2" htmlFor="orden2">
                                <span className="conNumero">
                                    <span className="numero">Menor valoración</span>
                                </span>

                                <input id="orden2" name="opciones" value="orden2" type="radio" checked={"orden2" === valorRadio ? true : false}></input>
                            </label>
                        </div>

                        <div className="filtrosButtons">
                            <button className="b-aplicar" onClick={handleAplicar}>APLICAR</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default function ModalOrdenamiento({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        cerrar,
        envioRadioButton,
        ordenamiento
    } = props

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} cerrar={cerrar} envioRadioButton={envioRadioButton} ordenamiento={ordenamiento}>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}