import React from 'react';
import './style.sass';

export function SectionOpcionesSearsMobile(props) {
    const { position } = props;

    const opciones = [
        { 'id': 0, 'image': "devoluciones.png", 'nombre': 'Devoluciones sin costo', "url": "/mi-cuenta" },
        { 'id': 1, 'image': "recogertienda.png", 'nombre': 'Recoge en cualquier tienda', "url": "/mi-cuenta" },
        { 'id': 2, 'image': "cancelaciones.png", 'nombre': '¿Te equivocaste? Cancela tus compras', "url": "/mi-cuenta" },
        { 'id': 3, 'image': "factura.png", 'nombre': 'Factura tus compras', "url": "http://facturaelectronica.sears.com.mx/" },
        { 'id': 4, 'image': "credito_sears.png", 'nombre': 'Consulta tu crédito Sears', "url": "/credito" },
        { 'id': 5, 'image': "servicio_tecnico.png", 'nombre': 'Servicio técnico sin costo', "url": "https://searsserviceteam.com.mx/" }
    ]

    return (
        <div className="contTiendasOficialesSears tiendasMobileSears">
            <div className="container">
                <div className="slideTiendas">
                    {opciones.map(({ id, nombre, image }) =>
                        <div className="card" key={id}>
                            <div className="dataTienda">
                                <p>{nombre}</p>
                            </div>
                            <img loading="lazy" src={"/img/home/"+image} alt={nombre} height="65" />
                        </div>
                    )
                    }
                </div>
            </div>
        </div>
    )
}

export function SectionOpcionesSears(props) {
    const {
        position
    } = props;

    return (
        <section className={`sectionIcos homePosition${position}`}>
            <div className="container">
                <div className="banner-principalSears" id="banner-principalSears">
                    <div className="promociones-bancarias">
                        <div className="promocion">
                            <div className="imagen-promocionHome">
                                <img src={`/img/home/exchange-1.svg`} alt="devoluciones" loading="lazy" width="100" height="100" />
                            </div>
                            <div className="texto-promocion">
                                <p>DEVOLUCIONES <br /><span>SIN COSTO</span></p>
                            </div>
                        </div>

                        <div className="promocion">
                            <div className="imagen-promocionHome">
                                <img src={`/img/home/recoger-tienda.png`} alt="recoger-en-tienda" loading="lazy" width="100" height="100" />
                            </div>
                            <div className="texto-promocion">
                                <p>RECOGE EN <br /><span>CUALQUIER TIENDA</span></p>
                            </div>
                        </div>

                        <div className="promocion">
                            <div className="imagen-promocionHome">
                                <img src={`/img/home/delivery-box.svg`} alt="cancelaciones" loading="lazy" width="140" height="100" />
                            </div>
                            <div className="texto-promocion">
                                <p>¿TE EQUIVOCASTE? <br /><span>CANCÉLALO AHORA</span></p>
                            </div>
                        </div>

                        <div className="promocion">
                            <div className="imagen-promocionHome">
                                <img src={`/img/home/invoice-4.svg`} alt="facturacion" loading="lazy" width="100" height="100" />
                            </div>
                            <div className="texto-promocion">
                                <p>FACTURA TUS <br /><span>COMPRAS</span></p>
                            </div>
                        </div>

                        <div className="promocion">
                            <div className="imagen-promocionHome">
                                <img src={`/img/home/tarjeta.svg`} alt="credito" loading="lazy" width="100" height="100" />
                            </div>
                            <div className="texto-promocion">
                                <p>CONSULTA TU <br /><span>CRÉDITO SEARS</span></p>
                            </div>
                        </div>

                        <div className="promocion">
                            <div className="imagen-promocionHome">
                                <img src={`/img/home/technical-support.svg`} alt="servicio-tecnico" loading="lazy" width="100" height="100" />
                            </div>
                            <div className="texto-promocion">
                                <p>SERVICIO TÉCNICO <br /><span>SIN COSTO</span></p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
}