import React, { useState, useEffect } from 'react';
import { getPedido, getCopysDevolucion } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import { removeDuplicates } from 'functions/removeDuplicates';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useToken } from 'hooks/useToken';
import FooterPortal from 'pages/Footer/FooterDesktop';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import { NO_IMAGE } from 'constants/general';
import { repareTextHtml } from 'functions/repareDataService';

export function SolicitarCambioDevolucionDesktop() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nombreProducto, setNombreProducto] = useState(false);
    const [informacion, setInformacion] = useState(false);
    const [infoServicio, setInfoServicio] = useState(false);


    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido && informacion) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //obtiene informacion para copys
    useEffect(() => {
        getCopysDevolucion()
            .then(setInformacion);
    }, [])

    useEffect(() => { setInfoServicio(informacion) }, [informacion])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista23desktop" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];
        let productoNombre = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )
                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setNombreProducto(productoNombre);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);
                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);

                    getCopysDevolucion()
                        .then(setInformacion);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a cual es tu motivo
    const handleContinuar = () => {
        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/motivo-cancelacion");
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const [mostrarDrop, setMostrarDrop] = useState(false);
    const ActivarDrop = () => {
        setMostrarDrop(!mostrarDrop);
    }

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">
                        <div className='menuMiCuentaAD'>
                            <MenuMiCuenta />
                        </div>

                        <div className='contenidoGeneralAD'>
                            <div className="boxBtnRegresarDktop">
                                <div className="icoBack" onClick={onClose}></div>
                                <button className="btnBackAD" onClick={onClose}>Solicitar Devolución</button>
                            </div>

                            <div className="boxGeneralAD">

                                <div className="boxLeftAD">
                                    <div className="contSectionRed">
                                        <div className={ima.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                            {ima.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={70} />)}
                                        </div>

                                        <div className='textDektop'><p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p></div>

                                    </div>
                                </div>

                                <div className="boxRightAD">
                                    {/* que paso con tu producto */}
                                    <div className="boxSolicitarDevolucion">

                                        <h3 className='marginT30'>{Array.isArray(infoServicio?.informacion) && infoServicio?.informacion[0].message_parent}</h3>

                                        <ul className="normasL1 marginT30">
                                            {Array.isArray(informacion?.informacion) && informacion?.informacion[0].child.map((inf, id) =>
                                                String(inf.type).toLowerCase() === "dropdown"
                                                    ? <>
                                                        <li key={id} className={mostrarDrop ? "liLink link flechaUp" : "liLink link flechaBottom"} onClick={(e) => ActivarDrop()}>{inf.message_parent} </li>
                                                        {mostrarDrop
                                                            ? <>
                                                                {Array.isArray(inf?.child) && inf?.child.length > 0 && inf?.child !== null
                                                                    ? <ul className="normasL1">
                                                                        {inf?.child.map((inf2, id2) =>
                                                                            <li key={id2}>{inf2?.message_parent}</li>
                                                                        )}
                                                                    </ul>
                                                                    : null
                                                                }
                                                            </>
                                                            : null
                                                        }
                                                    </>
                                                    : <li key={id}>{inf.message_parent} </li>
                                            )}
                                        </ul>

                                        {/* <p className="nota textLeft marginB40">{Array.isArray(informacion?.informacion) && informacion?.informacion[1].message_parent}</p> */}

                                        {Array.isArray(informacion?.informacion) && informacion?.informacion[1]
                                            ? <div className="boxPoliticas marginB40" >
                                                {String(informacion?.informacion[1].type).toLowerCase() === "link"
                                                    ? <a href={informacion?.informacion[1]?.additional_params?.url} className='link' target="_blank" rel="noopener noreferrer">
                                                        {informacion?.informacion[1]?.message_parent}
                                                    </a>
                                                    : <p className=''>{informacion?.informacion[1]?.message_parent}</p>
                                                }
                                            </div>
                                            : null
                                        }

                                        <div className="boxBtnContinuarDevolucion">
                                            <button className="btnContinuarDevolucion" onClick={handleContinuar}>Continuar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>

            <FooterPortal />
        </>
    )
}