import { useState, useEffect } from 'react';
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken'
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import { getCartNew, postCartNew, putCartNew, patchCartNew, getCartProduct } from 'services/Cart'
import { getCartAdapter } from 'adapters/cart_Adapter'

import { useDispatch } from 'context/storeProvider'
import { TYPES } from 'context/storeReducer'

export function useCart (props = false) {

    const dispatch = useDispatch()

    const {
        timeRef = 100,
        firstActive = true,
        name
    } = props

    const [readyActive, setReadyActive] = useState(firstActive)
    useEffect(()=>{
        if(firstActive){
            setReadyActive(true)
        }else{
            setReadyActive(false)
        }
        if(name){
            // console.log('nombreHookCart: ',name)
        }
    },[firstActive])

    let isLoged = decodeStorage('session_token')
    let SS_respCart = window.sessionStorage.getItem('respCart')


    const [cartResp, setCartResp] = useState()
    const [cartCount, setCartCount] = useState(SS_respCart ? JSON.parse( SS_respCart )?.data?.data?.total_items : null)
    const [idProducts, setIdProducts] = useState()
    const [idProductsSS, setIdProductsSS] = useState()
    const [idProductsR, setIdProductsR] = useState()

    const [cartProducts, setCartProducts] = useState()
    const [cartSend, setCartSend] = useState()
    const [okAdd, setOkAdd] = useState()
    const [carrito, setCarrito] = useState( SS_respCart ? JSON.parse( SS_respCart ) : null  )


    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession } = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "NewCart", timeRef: 1000})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)    
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            if(actionOK === 'show'){
                setServiceRT(false)
                getCartNew()
                .then(setCartResp)
                setActionOk(false)
            }
            else if(actionOK === 'add'){
                setServiceRT(false)
                postCartNew(cartSend)
                .then(setCartResp)
                setCartSend()
                setActionOk(false)
                setOkAdd(true)
            }
            else if(actionOK === 'update'){
                setServiceRT(false)
                patchCartNew(cartSend)
                .then(setCartResp)
                setCartSend()
                setActionOk(false)
            }
            else if(actionOK === 'remove'){
                setServiceRT(false)
                putCartNew(cartSend)
                .then(setCartResp)
                setCartSend()
                setActionOk(false)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        if(readyActive){
            if(isLoged){
                checkValidateSession(true)
                setActionOk('show')
            }
        }
    },[])

    useEffect(() => {
        if(cartResp){
            // console.log('cart resp: ', cartResp)
            let productServices = cartResp?.data?.data?.products
            if(productServices?.length >= 1){

                if(cartResp?.data?.data?.owner){
                    encodeStorage('idCarrito', cartResp?.data?.data?.owner)
                }
                const listProd = []
                let arrP = productServices?.map((prod, id) => {
                    listProd.push(prod?.csId)
                })
                // setIdProducts( String(listProd?.sort()) )
                setIdProductsR( String(listProd) )
                setIdProducts( String(listProd) )
                if(window.sessionStorage.getItem('respCart')){
                    
                    let productServicesSS = JSON.parse( window.sessionStorage.getItem('respCart') )?.data?.data?.products
                    const listProdSS = []
                    let arrPSS = productServicesSS?.map((prod, id) => {
                        listProdSS.push(prod?.id)
                    })
                    // setIdProductsSS( String(listProdSS?.sort()) )
                    setIdProductsSS( String(listProdSS) )
                }
                setCartCount(productServices ? productServices?.length : 0 )
                encodeStorage('total_items', productServices ? productServices?.length : 0)
                dispatch({type: TYPES.UPDATE_CART_COUNT, payload: productServices ? productServices?.length : 0 })
            }else{
                setCarrito( getCartAdapter([]) )
                setCartCount( productServices ? productServices?.length : 0 )
                encodeStorage('total_items', productServices ? productServices?.length : 0)
                dispatch({type: TYPES.UPDATE_CART_COUNT, payload: productServices ? productServices?.length : 0 })
            }
            
        }
    },[cartResp])

    useEffect(()=>{
        // console.log('idProducts: ',idProducts,' idProductsSS: ', idProductsSS)
        if(idProducts != idProductsSS ){
            if(idProducts){
                getCartProduct(idProducts)
                .then(setCartProducts)
                setIdProducts()
                setIdProductsSS()
            }
        }
    },[idProducts, idProductsSS])

    // useEffect(()=>{
    //     if(carrito){
    //         console.log('carrito: ', carrito)
    //     }
    // },[carrito])

    const CartCompleteP = (ids, products) => {
        
        let idProd = ids?.split(',')
        let productos = products

        let cartTotal = idProd.map(id => {
            let productsCart = productos.filter(product => product.id === Number(id) )[0]
            return productsCart
        })

        // console.log('ids: ',idProd)
        // console.log('products: ',cartTotal)

        return cartTotal
    } 

    useEffect(()=>{
        if(cartProducts){
            let cartProd = CartCompleteP(idProductsR, cartProducts?.data?.data)
            // let productArray = cartProducts?.data?.data?.map((producto, ind) => {
            let productArray = cartProd.map((producto, ind) => {
                // let quantity = cartResp?.data?.data?.products.filter(idClaro => idClaro?.csId === producto?.id)[0]?.quantity
                // let childrenSku = cartResp?.data?.data?.products.filter(idClaro => idClaro?.csId === producto?.id)[0]?.childrenSku

                // console.log('quantity', cartResp?.data?.data?.products[ind]?.quantity)
                // console.log('childrenSku ', cartResp?.data?.data?.products[ind]?.childrenSku)

                let product  = {
                    ...producto,
                    cart_quantity: cartResp?.data?.data?.products[ind]?.quantity ? cartResp?.data?.data?.products[ind]?.quantity : 1,
                    childrenSku: cartResp?.data?.data?.products[ind]?.childrenSku
                }
                return product
            })
            setCarrito( getCartAdapter(productArray) )
            window.sessionStorage.setItem('respCart', JSON.stringify( getCartAdapter(productArray) ) )
            setTimeout(function(){
                setOkAdd(false)
            },700)
        }
    },[cartProducts])


    const Product_Add = (val) => {
        let prodAdd = val
        let productArray = []
        const addP = prodAdd?.map( productPortal => {
            productArray.push({
                "csId": productPortal?.id_product,
                "quantity": productPortal?.quantity ? productPortal?.quantity : "1",
                "childrenSku": productPortal?.children_sku ? productPortal?.children_sku : "0",
                "tag": productPortal?.tag ? productPortal?.tag : null,
                "indentifier": "string",
                "createdAt": 0
            })
            return productArray
        })
        window.sessionStorage.removeItem('respCart')
        setIdProducts()
        setIdProductsSS()
        setTimeout(function(){
            setCartSend(productArray)
            checkValidateSession(true)
            setActionOk('add')
        },300)
    }

    const Product_Update = (val) => {
        let prodUpdate = val
        let productArray = []
        const updateP = prodUpdate?.map( productPortal => {
            productArray.push({
                "csId": productPortal?.id_product,
                "quantity": productPortal?.quantity ? productPortal?.quantity : "1",
                "childrenSku": productPortal?.children_sku ? productPortal?.children_sku : "0",
                "tag": productPortal?.tag ? productPortal?.tag : null,
                "indentifier": "string",
                "createdAt": 0
            })
            return productArray
        })
        window.sessionStorage.removeItem('respCart')
        setIdProducts()
        setIdProductsSS()
        setTimeout(function(){
            setCartSend(productArray)
            checkValidateSession(true)
            setActionOk('update')
            // setActionOk('update')
        },300)
    }

    const Product_Delete = (val) => {
        let prodDelete = val
        let productArray = []
        const deleteP = prodDelete?.map( productPortal => {
            productArray.push({
                "csId": productPortal?.id_product,
                "quantity": productPortal?.quantity,
                "childrenSku": productPortal?.children_sku ? productPortal?.children_sku : "0",
                "tag": productPortal?.tag ? productPortal?.tag : null,
                "indentifier": "string",
                "createdAt": 0
            })
            return productArray
        })
        window.sessionStorage.removeItem('respCart')
        setIdProducts()
        setIdProductsSS()
        setTimeout(function(){
            setCartSend(productArray)
            checkValidateSession(true)
            setActionOk('remove')
            // setActionOk('remove')
        },300)
    }

    return {
        cartCount,
        carrito,
        okAdd,
        Product_Add,
        Product_Update,
        Product_Delete
    }
}