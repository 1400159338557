import React, { useState, useEffect, Fragment } from 'react';
import { getPedido, getReasons, postCreateTiket } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import { LABEL_PREPARANDO_PRODUCTO, PEDIDOS_PENDIENTES_PAGO, PEDIDOS_PENDIENTES_AUTORIZAR, PEDIDOS_PRODUCTO_ENTREGADO, FORMA_PAGO_POST_PAGO, FORMA_PAGO_REEMBOLSABLE, LABEL_PROCESO_RECOLECCION, LABEL_EN_CAMINO, PENDIENTE, NO_IMAGE, PEDIDOS_ENPROCESO_RECOLECCION, PEDIDOS_ENCAMINO, PEDIDOS_PREPARANDO_PRODUCTO } from 'constants/general';
import { removeDuplicates } from 'functions/removeDuplicates';
import { ModalError, ModalOcurrioUnError } from '../vista4-modal';
import ModalPortal from 'components/Modal';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeStorage } from 'functions/storageBase64';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useToken } from 'hooks/useToken';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import FooterPortal from 'pages/Footer/FooterDesktop';
import { repareTextHtml } from 'functions/repareDataService';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';

export function SectionEnqueNecesitasAyudaDesktop(props) {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [resultadoRazones, setResultadoRazones] = useState(false);
    const [crearTicket, setCrearTicket] = useState(false);
    const [ima, setima] = useState([]);
    const [estatusPedido, setEstatusPedido] = useState(false);
    const [orderNumber, setOrderNumber] = useState(0);
    const [productos, setProductos] = useState(false);
    const [productoUnico, setProductoUnico] = useState(false);
    const [mostrarCancelar, setMostrarCancelar] = useState(false);
    const [mostrarProblemas, setMostrarProblemas] = useState(false);
    const [razones, setRazones] = useState(false);
    const [showModal, SetShowModal] = useState(false);
    const [showModalError, SetShowModalError] = useState(false);
    const [razonSeleccionada, setRazonSeleccionada] = useState(false);
    const [nombreRazonSeleccionada, setNombreRazonSeleccionada] = useState("");
    const [loading, setLoading] = useState(false);
    const [remover, setRemover] = useState(false);
    const [idClaro, setIdClaro] = useState(false);
    const profileData = useStorage64('profileData');
    const [formaPago, setFormaPago] = useState(false);
    const [, setInformacionStorage] = useStorage64("infoTicket", "");
    const [, setSaveStatus] = useStorage64('saveStatus', '');
    const [statusProducto, setStatusProducto] = useState(0);
    const [guia, setGuia] = useState(0);
    const [, setSaveStatusProducto] = useStorage64('saveStatusProducto', '');
    const [, setSaveIdStatusProducto] = useStorage64('saveIdStatusProducto', '');
    const [, setStorageIdProblem] = useStorage64("storageIdProblem", "");
    const [, setInformacionStorageEntregado] = useStorage64("infoTicketEntregado", "");
    const [idEstatusDeProducto, setIdEstatusDeProducto] = useState(0);
    const [, setActionId] = useStorage64("storageActionId", "");
    const [, setStorageMotivoEntregado] = useStorage64('storageMotivoEntregado', '');
    const [companyName, setCompanyName] = useStorage64('storageCompanyName');
    const [isReturnable, setIsReturnable] = useState(true);
    const [flagEstadoNoManejado, setFlagEstadoNoManejado] = useState(false);
    const [nombreProducto, setNombreProducto] = useState(false);
    const [diasTranscurridos, setDiasTranscurridos] = useState(false);

    //variables para motivo de cancelacion
    const [valorInput, setValorInput] = useState("");
    const [contador, setContador] = useState(0);
    const [row, setRow] = useState(1);
    const [showMotivo, setShowMotivo] = useState(false);
    const [correoUsuario, setCorreoUsuario] = useStorage64('storageCorreoUsuario', '');
    const [, setDireccionPedido] = useStorage64("storageDireccionPedido", "");

    // obtengo el id del usuario del storage
    useEffect(() => {
        if (profileData != "undefined") {
            if (profileData) {
                setIdClaro(profileData[0]?.idClaro);
                setCorreoUsuario(profileData[0]?.correo);
            }
        }
    }, [profileData])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((resultadoRazones && resultadoRazones.razones) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, resultadoRazones])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista12- necesitas ayuda -desktop" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenesProductos = [];
        let productosGlobal = [];
        let imagenProducto = [];
        let producto = [];
        let productoStatus = [];
        let productoGuia = [];
        let productoIdStatus = [];
        let productoReturnable = [];
        let productoNombre = [];
        let dias_transcurridos = [];
        let tracking_data = [];

        removeStorage('storageIdProblem');
        removeStorage("infoTicket");
        removeStorage("saveStatus");
        removeStorage("saveStatusProducto");
        removeStorage("saveIdStatusProducto");
        removeStorage("storageIdProblem");
        removeStorage("storageCD");
        removeStorage("infoTicketEntregado");
        removeStorage("storageMotivoEntregado");
        removeStorage("storageActionId");
        removeStorage("storageImgProducto");
        removeStorage("storageDatosBancarios");
        removeStorage("storageDevolverOpcion");
        removeStorage("storageCorreoUsuario");
        removeStorage("storageyanosepuederegalar");
        removeStorage("storageEsUnRegalo");
        removeStorage("storageDireccionPedido");
        removeStorage("storageCompanyName");

        removeStorage("storageRecoleccionDomicilio");
        removeStorage("storageDireccionTienda");
        removeStorage("storageIdTicket");
        removeStorage("infoTicketEntregado");

        if (resultadoPedido) {

            //console.log("resultadoPedido: ",resultadoPedido);

            if (resultadoPedido.pedido && resultadoPedido.status === 200) {

                // recorre por pedido
                if (resultadoPedido.pedido && Number(idProductoEstate) === 0) {

                    if (resultadoPedido.pedido[0]) {

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            imagenesProductos.push({ "url": elem.images[0]?.url })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            productosGlobal.push({ "id": elem.related_product_id, "return_type": null, "subsidiary_id": null })
                        )

                        setima(imagenesProductos);
                        setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setOrderNumber(resultadoPedido.pedido[0].order_number);
                        setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                        setProductos(productosGlobal);
                    }
                }
                // recorre por producto
                else if (resultadoPedido?.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido?.pedido[0]) {

                        resultadoPedido.pedido[0].products.map((elem, i) =>{
                            //console.log("elem?.related_product_id: ",elem?.related_product_id)
                            //console.log("idProductoEstate: ",idProductoEstate)
                            if(Number(elem?.related_product_id) === Number(idProductoEstate) ){
                                console.log("elemento seleccionado",elem);
                               return imagenProducto.push({ "url": elem?.images[0]?.url, "id": elem?.related_product_id })
                           }
                        })
                        resultadoPedido.pedido[0].products.map((elem, i) =>{
                            if(Number(elem?.related_product_id) === Number(idProductoEstate) ){
                                return producto.push({ "id": elem?.related_product_id })
                            }
                        })
                        resultadoPedido.pedido[0].products.map((elem, i) =>{
                           if(Number(elem?.related_product_id) === Number(idProductoEstate) ){
                            return productoStatus.push({ "status": elem?.status })
                           }
                        })
                        resultadoPedido.pedido[0].products.map((elem, i) =>{
                            if(Number(elem?.related_product_id) === Number(idProductoEstate) ){
                                return productoGuia.push({ "code": elem?.tracking.code })
                            }
                        })
                        resultadoPedido.pedido[0].products.map((elem, i) =>{
                            if(Number(elem?.related_product_id) === Number(idProductoEstate) ){
                                return productoIdStatus.push({ "status_id": elem?.status_id })
                            }
                        })
                        resultadoPedido.pedido[0].products.map((elem, i) =>{
                            if(Number(elem?.related_product_id) === Number(idProductoEstate) ){
                                return productoReturnable.push({ "is_returnable": elem?.delivery_date?.is_returnable })
                            }
                        })
                        resultadoPedido.pedido[0].products.map((elem, i) =>{
                            //console.log("elem", elem);
                            //console.log("elem?.name: ", elem?.name);
                            if(Number(elem?.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem?.name }) ){
                                return productoNombre.push({ "nombre": elem?.name })
                            }
                        })
                        // almacena los dias transcurridos desde la compra
                        resultadoPedido.pedido[0].products.map((elem, i) =>{
                            if(Number(elem?.related_product_id) === Number(idProductoEstate) ){
                                return dias_transcurridos.push({ "dias_trans": elem?.dynamic_help?.options_available })
                            }
                        })

                        // almacena company_name
                        resultadoPedido.pedido[0].products.map((elem, i) =>{
                            if(Number(elem?.related_product_id) === Number(idProductoEstate) ){
                                return tracking_data.push({ "company_name": elem?.tracking?.company_name })
                            }
                        })

                        console.log("se asigna company_name--vista1", tracking_data[0].company_name);
                        setCompanyName(tracking_data[0].company_name);


                        console.log("decodeStorage____>",decodeStorage('storageCompanyName'));
                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                        setProductoUnico(limpiaProducto[0]?.id);
                        //console.log("antes de setear:", productoNombre);
                        //console.log("antes de setear dias_trans:", dias_transcurridos);
                        setNombreProducto(productoNombre);
                        setDiasTranscurridos(dias_transcurridos[0]?.dias_trans);

                        let limpiaStatus = removeDuplicates(productoStatus, idProductoEstate);
                        setSaveStatusProducto(limpiaStatus[0]?.status);
                        setStatusProducto(limpiaStatus[0]?.status);
                        let limpiaGuia = removeDuplicates(productoGuia, idProductoEstate);
                        setGuia(limpiaGuia[0]?.code);

                        setFormaPago(resultadoPedido.pedido[0]?.payment_method.id);
                        console.log("axxi", resultadoPedido.pedido[0]?.axxi);
                        setEstatusPedido(resultadoPedido.pedido[0]?.axxi.status_axxi_id);
                        setSaveStatus(resultadoPedido.pedido[0]?.axxi.status_axxi_id);
                        setOrderNumber(resultadoPedido.pedido[0]?.order_number);
                        setIdEstatusDeProducto(productoIdStatus[0]?.status_id);
                        setSaveIdStatusProducto(productoIdStatus[0]?.status_id);
                        setIsReturnable(productoReturnable[0]?.is_returnable);
                        setDireccionPedido(resultadoPedido.pedido[0]?.address_details);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {

                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                    getReasons()
                        .then(setResultadoRazones);
                }, 1000);
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode !== 401) {
                setFlagEstadoNoManejado(true);
            }
        }
    }, [resultadoPedido])

    // obtiene servio razones de cancelacion
    useEffect(() => {
        getReasons()
            .then(setResultadoRazones);
    }, [])

    useEffect(() => {
        if (resultadoRazones) {
            if (resultadoRazones.razones && resultadoRazones.status === 200) {
                setRazones(resultadoRazones.razones);
            }
            else if (resultadoRazones.statusCode && resultadoRazones.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getReasons()
                        .then(setResultadoRazones);
                }, 1000);
            }
            else if (resultadoRazones.statusCode && resultadoRazones.statusCode !== 401) {
                setFlagEstadoNoManejado(true);
            }
        }
    }, [resultadoRazones])

    // regresar a la pagina anterior
    const onClose = () => {
        history.goBack(-1);
    }

    // onclick al seleccionar razon de cancelacion
    const seleccionoReason = (reasonId, reasonName) => {

        let reason_id = reasonId;
        let order_id = orderNumber;
        let order_number = orderNumber;
        let client_id = idClaro;
        let description = reasonName;
        let ticket_type_id = 1; // siempre es 1 si es cancelacion
        let refound_details = [];
        let additional_details = [];
        let action_id = 0; //0 para cancelaciones por pedido
        let products_to_cancel = [{
            "id": productoUnico,
            "return_type": null,
            "subsidiary_id": null
        }];
        let correo = correoUsuario;

        //RAZON ID DIFERENTE DE: OTRO
        if (Number(reasonId) !== 8) {

            // pedidos PENDIENTE DE PAGO, PENDIENTE DE AUTORIZACION
            if (PEDIDOS_PENDIENTES_PAGO === estatusPedido || PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido)
            ) {

                SetShowModal(true);
                setRazonSeleccionada(reasonId);
                setNombreRazonSeleccionada(reasonName);
            }

            //YA--- pedidos autorizados CON producto-> PREPARANDO TU PRODUCTO pago REEMBOLSABLE (PAYPAL, TARJETA DE CREDITO, TARJETA DE DEBITO, TARJETA SEARS) y sin guia
            else if (idProductoEstate !== 0 && LABEL_PREPARANDO_PRODUCTO === String(statusProducto).toLowerCase() && FORMA_PAGO_REEMBOLSABLE.includes(formaPago) && guia == null
            ) {
                setRemover(true);

                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }

            // pedido autorizado CON producto-> PREPARANDO TU PRODUCTO pago NO REEMBOLSABLE(oxxo, seven, tienda o tienda departamental) y sin guia
            else if (idProductoEstate !== 0 && LABEL_PREPARANDO_PRODUCTO === String(statusProducto).toLowerCase() && FORMA_PAGO_POST_PAGO.includes(formaPago) && guia === null
            ) {

                setInformacionStorage({
                    "reason_id": reasonId,
                    "order_id": orderNumber,
                    "order_number": orderNumber,
                    "client_id": idClaro,
                    "description": reasonName,
                    "products_to_cancel": [{
                        "id": productoUnico,
                        "return_type": null,
                        "subsidiary_id": null
                    }],
                    "ticket_type_id": 1,
                    "additional_details": [],
                    "action_id": 0
                });

                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/datos-bancarios");
            }

            //YA--- pedidos autorizados CON producto EN PROCESO DE RECOLECCION - EN CAMINO
            else if (
                (idProductoEstate !== 0 && LABEL_PROCESO_RECOLECCION === String(statusProducto).toLocaleLowerCase()) ||
                (idProductoEstate !== 0 && LABEL_EN_CAMINO === String(statusProducto).toLocaleLowerCase())
            ) {

                setRemover(true);

                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }
        }
    }

    // cambia informacion de la pagina al presionar cancelar compra
    const handleCancelarCompra = () => {
        setMostrarCancelar(!mostrarCancelar);
    }

    //***************** PARA SECCION MOTIVO DE CANCELACION *******************/
    // actualizar valor del texto en input
    const cambiarValorInput = (valor) => {
        let texto = valor.target.value;
        let cont = String(texto).length;

        setValorInput(texto);
        setContador(cont);

        var maxrows = 8;
        var txt = texto;
        var cols = valor.target.cols;
        let arraytxt = txt.split('\n');
        let rows = arraytxt.length;

        for (let i = 0; i < arraytxt.length; i++) {
            rows += Number(arraytxt[i].length / cols);
        }
        if (rows > maxrows) { setRow(maxrows); }
        else { setRow(rows); }
    }

    const SeleccionarMotivoCancelacion = () => {
        setShowMotivo(!showMotivo);
    }

    const ContinuarMotivoCancelacion = () => {
        //parametros que se envian al ticket si el producto esta :
        // --- EN CAMINO --- EN PROCESO DE RECOLECCION --- PREPARANDO TU PRODUCTO
        let reason_id = 8;
        let order_id = orderNumber;
        let order_number = orderNumber;
        let client_id = idClaro;
        let description = valorInput;
        let ticket_type_id = 1; // siempre es 1 si es cancelacion
        let refound_details = []; // 
        let additional_details = []; // 
        let action_id = 0;
        let products_to_cancel = [{
            "id": productoUnico,
            "return_type": null,
            "subsidiary_id": null
        }];
        let correo = correoUsuario;

        //razon id es igual a : otro
        if (Number(reason_id) === 8) {
            // pedidos pendiente de pago, pendiente de autorizacion o 
            // pedidos autorizados producto-> preparando tu producto pago diferente de oxxo, seven, deposito y tienda departamental y sin guia
            if (PEDIDOS_PENDIENTES_PAGO === estatusPedido || PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido)) {

                SetShowModal(true);
                setRazonSeleccionada(reason_id);
                setNombreRazonSeleccionada(valorInput);
            }
            // pedido autorizado , producto preparando tu producto forma de pago oxxo, seven, tienda o tienda departamental y sin guia
            else if (idProductoEstate !== 0 && LABEL_PREPARANDO_PRODUCTO === String(statusProducto).toLowerCase() && FORMA_PAGO_POST_PAGO.includes(formaPago) && guia === null) {

                setInformacionStorage({
                    "reason_id": reason_id,
                    "order_id": order_id,
                    "order_number": order_number,
                    "client_id": idClaro,
                    "description": valorInput,
                    "products_to_cancel": [{
                        "id": productoUnico,
                        "return_type": null,
                        "subsidiary_id": null
                    }],
                    "ticket_type_id": 1,
                    "additional_details": [],
                    "action_id": 0,
                    "correo": correo
                });

                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/datos-bancarios");
            }
            //YA--- pedido autorizado con producto PREPARANDO TU PRODUCTO, forma de pago PAYPAL, TARJETA DE CREDITO, TARJETA DEBITO, TARJETA SEARS, ETC,  SIN GUIA
            else if (idProductoEstate !== 0 && LABEL_PREPARANDO_PRODUCTO === String(statusProducto).toLowerCase() && !FORMA_PAGO_POST_PAGO.includes(formaPago) && guia === null
            ) {

                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }

            //YA--- pedidos autorizados CON producto EN PROCESO DE RECOLECCION - EN CAMINO
            else if (
                (idProductoEstate !== 0 && LABEL_PROCESO_RECOLECCION === String(statusProducto).toLocaleLowerCase()) ||
                (idProductoEstate !== 0 && LABEL_EN_CAMINO === String(statusProducto).toLocaleLowerCase())
            ) {
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }
        }
    }

    const showModalRemove = () => SetShowModal(true);

    //******************** FIN MOTIVO DE CANCELACION *******************/

    // cerrar modal de eliminar pedidos
    const handleClose = () => SetShowModal(false);

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // accion para si cancelar pedido
    const siRemove = () => {
        let reason_id = razonSeleccionada;
        let order_id = orderNumber;
        let order_number = orderNumber;
        let client_id = idClaro;
        let description = nombreRazonSeleccionada;
        let ticket_type_id = 1; // siempre es 1 si es cancelacion
        // pedidos pendiente de pago / pendiente de autorizacion
        let refound_details = []; // 
        let additional_details = []; // 
        let action_id = 0; // si es pendiente de pago o pendiente de autorizar
        let correo = correoUsuario;

        // ocultar modal
        SetShowModal(false);
        setRemover(true);

        // enviar solicitud de cancelacion
        if (Number(razonSeleccionada) !== 8) {
            // PEDIDO---> pendiente de pago o pendiente de autorizacion 
            if ((estatusPedido === PEDIDOS_PENDIENTES_PAGO || PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido))) {
                let products_to_cancel = productos;
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }
        }

        if (Number(razonSeleccionada) === 8) {

            SetShowModal(false);
            setRemover(true);

            let reason_id = 8;
            let order_id = orderNumber;
            let order_number = orderNumber;
            let client_id = idClaro;
            let description = valorInput;
            let ticket_type_id = 1; // siempre es 1 si es cancelacion
            // si es pendiente de pago o pendiente de autorizar
            let refound_details = []; // 
            let additional_details = []; // 
            let action_id = 0;
            let correo = correoUsuario;

            // pedido pendiente de pago o pendiente de autorizar
            if (estatusPedido === PEDIDOS_PENDIENTES_PAGO || PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido)) {
                let products_to_cancel = productos;
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }
        }
    }

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {
                    if (idProductoEstate === 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                    }
                    else if (idProductoEstate !== 0) {
                        if ((LABEL_PROCESO_RECOLECCION === String(statusProducto).toLocaleLowerCase())
                            || (LABEL_EN_CAMINO === String(statusProducto).toLocaleLowerCase())) {
                            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/no-puede-cancelar");
                        }
                        else {
                            history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cancelacion-exitosa");
                        }
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    //no cancelar -> cerrar modal y regresar a mis pedidos
    const noRemove = () => {
        SetShowModal(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    //************************************* para pedidos entregados **************************************
    // activa bandera para mostrar seleccione un probleama
    const handleSelectProblema = () => {
        setMostrarProblemas(!mostrarProblemas);
    }

    // redireccion segun problema seleccionado 
    const seleccionoQuePaso = (id, name) => {

        removeStorage("storageIdProblem");
        setStorageIdProblem(id);

        // es lo que compre pero tengo un problema
        if (Number(id) === 9) {
            setInformacionStorageEntregado({
                "reason_id": id,
                "order_id": orderNumber,
                "order_number": orderNumber,
                "client_id": idClaro,
                "products_to_cancel": [{
                    "id": productoUnico,
                    "return_type": null,
                    "subsidiary_id": null
                }],
                "ticket_type_id": 4
            })

            setActionId(id);
            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cuentanos-tu-problema/");
        }

        // --------------------------- NUEVAS OPCIONES PARA SEARS -----------------------------
        //recibi el paquete vacio (14)
        else if (Number(id) === 14) {

            setInformacionStorageEntregado({
                "reason_id": id,
                "order_id": orderNumber,
                "order_number": orderNumber,
                "client_id": idClaro,
                "products_to_cancel": [{
                    "id": productoUnico,
                    "return_type": null,
                    "subsidiary_id": null
                }],
                "action_id": 0,
                "ticket_type_id": 4
            });

            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/condiciones-del-paquete/");
        }

        // aparece como entregado pero aun no lo recibo
        else if (Number(id) === 16) {

            setInformacionStorageEntregado({
                "reason_id": id,
                "order_id": orderNumber,
                "order_number": orderNumber,
                "client_id": idClaro,
                "products_to_cancel": [{
                    "id": productoUnico,
                    "return_type": null,
                    "subsidiary_id": null
                }],
                "ticket_type_id": 4,
                "description": name
            });

            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/no-entregado-que-quieres-hacer/");
        }
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    // OCULTA LA PANTALLA DE :EN QUE NECESITAS AYUDA PARA PENDIENTES DE PAGO Y PENDIENTE DE AUTORIZACION
    useEffect(() => {
        if (PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido) === true || Number(PEDIDOS_PENDIENTES_PAGO) === Number(estatusPedido)) {
            setMostrarCancelar(true);
        }
        else {
            setMostrarCancelar(false);
        }
    }, [estatusPedido])

    //useEffect(()=>{
    //    if(nombreProducto){
    //        console.log("nombreProducto:",nombreProducto)
    //    }
    //},[nombreProducto])


    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">

                    <div className="containerAD">
                        <div className='menuMiCuentaAD'>
                            <MenuMiCuenta />
                        </div>

                        <div className='contenidoGeneralAD'>
                            <div className="boxBtnRegresarDktop">

                                {mostrarCancelar === false && !mostrarProblemas
                                    ? <>
                                        <div className="icoBack" onClick={onClose}></div>
                                        <button className="btnBackAD" onClick={onClose}>¿En qué necesitas ayuda?</button>
                                    </>
                                    : mostrarCancelar && !PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto)
                                        ? <>
                                            
                                            <div className="icoBack" onClick={onClose}></div>
                                                
                                            {razonSeleccionada === 8 ?
                                                <button className="btnBackAD" onClick={onClose}>Confirmación</button>
                                                : <button className="btnBackAD" onClick={onClose}>¿Por qué quieres cancelar?</button>
                                            }
                                        </>
                                        : idProductoEstate !== 0 && PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto) &&
                                        <>
                                            <div className="icoBack" onClick={onClose}></div>
                                            <button className="btnBackAD" onClick={onClose}>¿Qué pasó con tu producto?</button>
                                        </>
                                }
                            </div>

                            <div className="boxGeneralAD">
                                <div className="boxLeftAD">
                                    <div className="contSectionRed">
                                        <div className={ima.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                            {ima && ima.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={70} />)}
                                        </div>

                                        {ima && ima.length > 1
                                            ? <div className='tituloImagenes'>
                                                {mostrarCancelar === false && !mostrarProblemas
                                                    ? <div className="textDektop"><p>¿En qué necesitas ayuda?</p></div>
                                                    : mostrarCancelar
                                                        ? razonSeleccionada === 8 ?
                                                            <div className="textDektop"><p>Confirmación</p> </div>
                                                            :<div className="textDektop"><p>¿Por qué quieres cancelar?</p> </div>
                                                        : mostrarProblemas && <div className="textDektop"> <p>¿Qué pasó con tu producto?</p> </div>
                                                }
                                            </div>
                                            : <div className='tituloImagenes'><p>{nombreProducto && repareTextHtml(nombreProducto[0]?.nombre)}</p></div>
                                        }
                                    </div>
                                </div>

                                {(showModal && idProductoEstate === 0)
                                    ? <>
                                        <div className="modalRemoveAddress">
                                            <div className="boxTextos">
                                                <h3 className='marginT30'>¿Estás seguro que deseas cancelar este pedido?</h3>
                                            </div>

                                            <div className="contBtns">
                                                <div className="btn btn-100 blanco" onClick={siRemove}>Cancelar</div>
                                                {/*<div className="btnContinuar" onClick={siRemove}>Continuar</div>*/}
                                            </div>
                                        </div>
                                    </>
                                    : (showModal && idProductoEstate !== 0) ?
                                        <>
                                            <div className="modalRemoveAddress">
                                                <div className="boxTextos">
                                                    <h3 className='marginT30'>¿Estás seguro que deseas cancelar este producto?</h3>
                                                </div>

                                                <div className="contBtns">
                                                    <div className="btn btn-100 blanco" onClick={siRemove}>Cancelar</div>
                                                    {/*<div className="btnContinuar" onClick={siRemove}>Continuar</div>*/}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className="boxRightAD">
                                            {/* muestra copy cancelar mi compra  */}
                                            {mostrarCancelar === false && idProductoEstate !== 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto)
                                                && (!PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido) && PEDIDOS_PENDIENTES_PAGO !== estatusPedido)
                                                ? <div className="contenedorListadoAD" onClick={handleCancelarCompra}>
                                                    <li>Cancelar mi compra</li>
                                                </div>
                                                : null
                                            }

                                            {mostrarCancelar === true && !PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto) &&
                                                <>
                                                    {PEDIDOS_ENPROCESO_RECOLECCION.includes(estatusPedido) 
                                                        || PEDIDOS_ENCAMINO.includes(estatusPedido)
                                                        || ((PEDIDOS_PENDIENTES_PAGO === estatusPedido && !PENDIENTE.includes(Number(formaPago)))) ?
                                                            <div className="copyEspera">
                                                                <p>Si ya realizaste tu pago espera a que se vea reflejado antes de cancelar.</p>

                                                                <p>Se cancelarán todos los productos de tu pedido.</p>
                                                            </div>
                                                        :null
                                                    }

                                                    <div className="boxUlRazonesAD">
                                                        <ul>
                                                            {razones && razones.map((razon) =>
                                                                razon.id < 8 ?
                                                                    <li id={razon.id} key={razon.id} onClick={() => seleccionoReason(razon.id, razon.name)}>
                                                                        <span className="nombreRazon">{razon.name}</span>
                                                                        <span className="iconoRazon"></span>
                                                                    </li>
                                                                    : razon.id === 8 &&
                                                                    <Fragment key={razon.id}>
                                                                        <>
                                                                            <li id={razon.id} onClick={SeleccionarMotivoCancelacion} className={showMotivo === true ? "borderNone arrowBottom" : ""}>
                                                                                <span className="nombreRazon">{razon.name}</span>
                                                                                <span className="iconoRazon"></span>
                                                                            </li>
                                                                        </>
                                                                        {showMotivo &&
                                                                            <>
                                                                                <div className="boxMotivoCancelacionVista12">
                                                                                    <textarea className={"inputMotivo"} placeholder="Escribe tu motivo aquí" cols={40}
                                                                                        rows={row} value={valorInput} onChange={cambiarValorInput}
                                                                                        type="text" maxLength={250} minLength={10}></textarea>
                                                                                </div>

                                                                                <div className="boxBtn">
                                                                                    <button className='btnContinuar' onClick={ContinuarMotivoCancelacion} disabled={valorInput.length === 0 ? true : false}>Continuar</button>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </Fragment>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </>
                                            }

                                            {/* seccion producto entregado */}
                                            {idProductoEstate !== 0 && PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto) &&
                                                <div className="boxUlRazonesAD">

                                                    {diasTranscurridos
                                                        ? <p className='marginT30 textLeft paddingLeft10'>{diasTranscurridos}</p>
                                                        : null
                                                    }

                                                    <ul>
                                                        {razones && razones.map((razon) =>
                                                            (Number(razon.id) > 8)
                                                                ? <li id={razon.id} key={razon.id} onClick={() => seleccionoQuePaso(razon.id, razon.name)}>
                                                                    <span className="nombreRazon">{razon.name}</span>
                                                                    <span className="iconoRazon"></span>
                                                                </li>
                                                                : null
                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                }
                            </div>
                        </div>

                        {/* mostrar modal estas seguro que deseas cancelar*/}
                        {/* {(showModal && idProductoEstate === 0) &&
                            <ModalPortal type="modalSB" onClose={handleClose} >
                                <ModalEstasSeguro remvoeAction={siRemove} awaitAction={noRemove} />
                            </ModalPortal>
                        } */}

                        {/* mostrar modal estas seguro que deseas cancelar por producto*/}
                        {/* {(showModal && idProductoEstate !== 0) &&
                            <ModalPortal type="modalSB" onClose={handleClose} >
                                <ModalEstasSeguroProducto remvoeAction={siRemove} awaitAction={noRemove} />
                            </ModalPortal>
                        } */}

                        {showModalError &&
                            <ModalPortal type="modalSB" onClose={handleCloseError} >
                                <ModalError okRegresar={regresarPedidos} />
                            </ModalPortal>
                        }

                        {/* diferente de producto entregado, pedido pendiente de pago , pedido pendiente de autorizar, pedido autorizado, preprarando tu producto */}
                        {flagEstadoNoManejado &&
                            <ModalPortal type="modalSB" onClose={handleCloseError} >
                                <ModalOcurrioUnError okRegresar={regresarPedidos} />
                            </ModalPortal>
                        }
                    </div>
                </section>
            </main>

            <FooterPortal />
        </>
    )
}