import { SEARCH_ANTEATER, PRODUCTS_PER_PAGINATION, TIMEOUT_TIME } from 'constants/general'
import axios from 'axios'

export default async function getCategory({ idtienda = '', subCategory = '', searchText = '', brand = '', higherPrice = '', lowerPrice = '', id_Category = '', shipping = '', fulfillment = '', discount = '', start = '', order = '', pagination = '' } = {}) {
    var prices, brands, byOreder, byCategory, dots, dotsShip, dotsSt, byPagination, starts, shippings, porCategoria, dotCategoria, full, dotsFull, disc, dotsDisc


    // ${brands}${dots}${prices}${dotsSt}${starts}${dotsShip}${shippings}&requiredobjects=${porCategoria}${dotCategoria}${byCategory}
    brand && higherPrice && lowerPrice
        ? dots = `.`
        : dots = ''

    brand && start
        ? dotsSt = `.`
        : higherPrice && lowerPrice && start
            ? dotsSt = `.`
            : dotsSt = ''

    brand && shipping
        ? dotsShip = `.`
        : start && shipping
            ? dotsShip = `.`
            : higherPrice && lowerPrice && shipping
                ? dotsShip = `.`
                : dotsShip = ''

    brand && fulfillment
        ? dotsFull = `.`
        : higherPrice && lowerPrice && fulfillment
            ? dotsFull = `.`
            : start && fulfillment
                ? dotsFull = `.`
                : shipping && fulfillment
                    ? dotsFull = `.`
                    : dotsFull = ''

    brand && discount
        ? dotsDisc = `.`
        : higherPrice && lowerPrice && discount
            ? dotsDisc = `.`
            : start && discount
                ? dotsDisc = `.`
                : shipping && discount
                    ? dotsDisc = `.`
                    : fulfillment && discount
                        ? dotsDisc = `.`
                        : dotsDisc = ''

    id_Category && idtienda // si categoria principal y categoria secundaria 
        ? dotCategoria = `.`
        : dotCategoria = ''

    higherPrice && lowerPrice
        ? prices = `sale_price>=${lowerPrice.replace('.','\\.')}.sale_price<=${higherPrice.replace('.','\\.')}`
        : prices = ''

    brand
        ? brands = `attribute_marca:${brand.replace(/\-/g,'\\-')}`
        : brands = ''

    idtienda
        ? porCategoria = `categories->id:${idtienda}`//`breadcrumbs->id:${id_Category}`//`categories->id:${idtienda}`
        : porCategoria = ''

    order
        ? byOreder = `meta:sale_price:${order}`
        : byOreder = ''

    start
        ? starts = `review>3`
        : starts = ''

    shipping
        ? shippings = `shipping_price:1`
        : shippings = ''

    fulfillment
        ? full = `fulfillment:1`
        : full = ''
        
    discount
        ? disc = `discount>=${discount}`
        : disc = ''


    id_Category
        ? byCategory = `categories->id:${id_Category}`
        : byCategory = ''

    pagination
        ? byPagination = pagination - 1
        : byPagination = 0

    // const apiURL = `${SEARCH_ANTEATER}&proxystylesheet=xml2json&oe=UTF-8&getfields=*&start=0&num=${PRODUCTS_PER_PAGINATION}&requiredobjects=categories->id:${id_cat}`
    const apiURL = SEARCH_ANTEATER + `&proxystylesheet=xml2json&oe=UTF-8&getfields=*&sort=${byOreder}&start=${byPagination * PRODUCTS_PER_PAGINATION}&num=${PRODUCTS_PER_PAGINATION}&requiredfields=${brands}${dots}${prices}${dotsSt}${starts}${dotsShip}${shippings}${dotsFull}${full}${dotsDisc}${disc}&requiredobjects=${porCategoria}${dotCategoria}${byCategory}&ds=marcas:attribute_marca:0:0:100:0:1.sale_precio:sale_price:1:1:1000.ranking:review:0:0:8:0:1.full:fulfillment:0:0:8:0:1.free:shipping_price:0:0:8:0:1.discount:discount:0:0:1000:0:1&do=breadcrumbs:breadcrumbs:id,name,padre:100:1`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    };


    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const searchResult = data
            if (status === 200) {
                if (!Array.isArray(searchResult)) {
                    return { searchResult }
                }
                else {
                    return { 'error': status, 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            if (error.message === 'Network Error') {
                console.log('Error de conexión')
            }
            else if (error.request) {
                console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                console.log('request 0')
            } else {
                if (error.response.status === 401) {
                    console.log(error.response.data.msg)
                    return { error: error.response.status, message: error.response.data.msg }
                }
                else if (error.response.status === 404) {
                    console.error(error.response);
                    console.log('request 404')
                }
                else if (error.response.status === 440) {
                    console.log('request 440')
                }
                else if (error.response.status === 500) {
                    console.error(error.response);
                    console.log('request 500')
                }
                else {
                    console.error(error.response.status);
                    console.error(error.response);
                }
            }
        })
}