import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';

export function PoliticaMarketPlaceDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Terminos y Condiciones Market Place Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Términos y Condiciones Market Place</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question">
                                <p className="black bold">CONTRATO DE PRESTACIÓN DE SERVICIOS DE PLATAFORMA SEARS OPERADORA MEXICO, S.A. DE C.V., MODALIDAD MARKET PLACE PARA LA EXHIBICIÓN DE BIENES Y SERVICIOS OFERTADOS POR TERCEROS AJENOS A SEARS, QUE CELEBRA POR UNA PARTE SEARS OPERADORA MEXICO, S.A. DE C.V. (EN LO SUCESIVO SEARS), Y POR LA OTRA EL USUARIO REGISTRADO EN EL PORTAL <Link to="/">www.sears.com</Link> (EL SITIO), DOCUMENTO QUE EN ADELANTE SE LE PODRÁ IDENTIFICAR COMO TÉRMINOS Y CONDICIONES SEARS MODALIDAD MARKET PLACE. CUALQUIER PERSONA QUE NO ACEPTE LOS TÉRMINOS Y CONDICIONES SEARS MODALIDAD MARKET PLACE, QUE TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y LA PLATAFORMA MARKET PLACE, YA QUE LA UTILIZACIÓN DEL MISMO IMPLICA EL CONSENTIMIENTO INEQUÍVOCO DEL USUARIO EL USO DEL SITIO, MODALIDAD SEARS MARKET SE ENTENDERÁ COMO EL TOTAL CONSENTIMIENTO A ESTOS TÉRMINOS Y CONDICIONES MARKET PLACE.</p>
                                <p className="black bold">Es obligación del USUARIO leer las condiciones establecidas en las Cláusulas de los presentes TÉRMINOS Y CONDICIONES MARKET PLACE, el Aviso de Privacidad (de ser actualizar el aviso de privacidad) de SEARS, así como los demás documentos incorporados a los mismos por referencia, previo al uso de la PLATAFORMA SEARS MODALIDAD MARKET PLACE; entendiendo los mismos y aceptando las obligaciones y alcances del presente documento.</p>
                            </div>

                            <div className="question1">
                                <h2 className="spacing">DECLARACIONES</h2>
                                <p className="h3 bold black">I.- Declara SEARS qué:</p>

                                <ul className="ulQuestionRomano">
                                    <li>Es una sociedad Anónima de Capital Variable organizada y existente de conformidad con las leyes de los Estados Unidos Mexicanos, que su registro federal de contribuyentes es SOM101125UEA y su página de internet <Link to="/">www.sears.com.mx</Link></li>
                                    <li>Su representante, cuenta con todas las facultades necesarias para la celebración del presente Contrato, mismas que no le han sido revocadas, modificadas, ni limitadas en forma alguna a esta fecha.</li>
                                    <li>Su domicilio fiscal y convencional es el ubicado en Lago Zurich No. 245, edificio Presa Falcón, piso 7, colonia Ampliación Granada, Miguel Hidalgo, C.P. 11529, Ciudad de México.</li>
                                    <li>Que es un prestador de servicios digitales ajeno a los bienes y/o servicios ofertados por EL VENDEDOR.</li>
                                </ul>

                                <p className="h3 bold black">II.- Declara EL CLIENTE qué:</p>
                                <ul className="ulQuestionRomano">
                                    <li>Es mayor de edad, y que cuenta con la capacidad suficiente para obligarse en los términos del presente contrato, y que conoce los derechos, obligaciones y alcances derivadas de las disposiciones aplicables a los servicios a que se refiere este Contrato.</li>
                                    <li>En caso de ser persona moral, su representante cuenta con las facultades legales suficientes para representarla y obligarla en términos de este Contrato.</li>
                                    <li>Que sus datos generales, domicilio, teléfono y correo electrónico establecido en el presente documento y/o en el formulario requisitado a través de la página www.sears.com.mx (en lo sucesivo el PORTAL), es información verídica, completa, correcta y actualizada, siendo responsable única y exclusivamente el mismo por la información proporcionada.</li>

                                    <li>El origen de los recursos y/o medios con los cuales efectuará los pagos en los términos del presente Contrato, son de su propiedad y proceden de fuente lícita y que no realizará transacciones destinadas a favorecer o encubrir actividades ilícitas.</li>
                                    <li>Que se encuentra consciente que todas las transacciones efectuadas en el portal www.sears.com.mx, a través del cual se encuentre asociada su usuario y contraseña serán atribuibles a su persona.</li>
                                    <li>Es su voluntad libre de cualquier vicio del consentimiento, celebrar el presente Contrato.</li>
                                </ul>

                                <p>Vertidas las declaraciones las Partes sujetan este Contrato a las siguientes:</p>

                                <h2>CLÁUSULAS</h2>
                                <p className="h3 bold black">1. Capacidad.</p>
                                <p>Los contenidos y servicios que ofrece el SITIO, están reservados y dirigidos únicamente para personas que tengan capacidad legal, por sí y no por terceros para contratar. Queda bajo la responsabilidad de los padres o tutores legales, supervisar la conducta de los menores de edad que ingresen al SITIO. En caso de inscribir a una persona moral como USUARIO, se deberá contar con la capacidad jurídica para contratar y obligarla en los términos del presente Contrato.</p>

                                <p className="h3 bold black">2. Objeto y Particularidades.</p>
                                <p>Los TÉRMINOS Y CONDICIONES MARKET PLACE, tienen por objeto regular única y exclusivamente el uso de la PLATAFORMA MARKET PLACE, misma que forma parte del SITIO, cuyo titular es SEARS, y a través de la cual el USUARIO puede adquirir bienes y/o servicios ofrecidos y vendidos por personas físicas o morales distintas a SEARS a quienes en lo sucesivo y de forma individual o colectiva se les denominará como el VENDEDOR.</p>
                                <p>SEARS presta el servicio de exhibición virtual de bienes y/o servicios que comercializa directamente el VENDEDOR, bajo sus propios términos y condiciones, a través de la publicación de ofertas y promociones de bienes y servicios bajo la modalidad PLATAFORMA MARKET PLACE, ofreciendo a los USUARIOS la posibilidad de su adquisición de acuerdo a las especificaciones de comercialización que el VENDEDOR publique para cada bien y/o servicio en específico, así como la mensajería electrónica entre el USUARIO y el VENDEDOR.</p>
                                <p>La comercialización de los bienes y/o servicios a través de la PLATAFORMA MARKET PLACE, se identificará de acuerdo a lo siguiente:</p>

                                <ul className="ulQuestionRomano">
                                    <li>Se identificará plenamente que los bienes y/o servicios respectivos, son ofertados por el VENDEDOR, y se comercializan bajo la modalidad MARKET PLACE, y se especificará el nombre comercial del VENDEDOR que comercializa el bien y/o servicio de que se trate.</li>
                                    <li>En cada publicación de bienes y/o servicios, el VENDEDOR pondrá a disposición del USUARIO, la información en idioma español (Precios en Moneda Nacional) de los bienes y/o servicios, que por lo menos será la siguiente (las cuales son enunciativas mas no limitativas y dependen exclusivamente del VENDEDOR):

                                    <ul className="ulQuestionDisc">
                                            <li>Imagen y descripción detallada del bien y/o servicio que se oferta.</li>
                                            <li>Cantidad de bienes y/o servicios disponibles.</li>
                                            <li>Condiciones de envío y de transporte de los bienes y/o servicios, o plazo para la prestación de los servicios.</li>
                                            <li>Imágenes digitalizadas de los Bienes y/o Servicios.</li>
                                            <li>El precio de venta, gastos adicionales y la vigencia de la oferta.</li>
                                            <li>Marca y modelo de los Bienes y/o servicios, según corresponda.</li>
                                            <li>Accesorios que incluyen los Bienes y/o Servicios.</li>
                                            <li>Condiciones y requerimientos de uso y manejo para la exigibilidad de las garantías.</li>
                                            <li>Plazo de garantía de los bienes y/o servicios.</li>
                                            <li>Condiciones de devoluciones de los bienes y/o servicios.</li>
                                        </ul>
                                    </li>

                                    <li>El VENDEDOR es plenamente responsable ante el USUARIO, de:
                                    <ul className="ulQuestionDisc">
                                            <li>La calidad del bien y/o servicio que comercialice.</li>
                                            <li>La gestión de venta.</li>
                                            <li>La entrega del bien o la prestación del servicio.</li>
                                            <li>El cobro del precio del bien y/o servicio.</li>
                                            <li>Otorgar o gestionar la garantía del bien y/o servicio que corresponda.</li>
                                            <li>Responder por cualquier infracción a derechos de propiedad industrial (marcas, patentes, modelos de utilidad, etc.), derechos de autor, derechos conexos, derechos de imagen, entre otros, relativos a los bienes y/o servicios que comercialice.</li>
                                            <li>De cualquier procedimiento que pudiera iniciar el USUARIO con motivo de la garantía del bien y/o servicio, o de la adquisición del mismo.</li>
                                            <li>A respetar el precio de venta de los bienes y/o servicios publicados por El VENDEDOR.</li>
                                            <li>A proporcionar la factura del producto y/o servicio adquirido en un término no mayor a 72 horas, después de haber sido solicitada por el USUARIO.</li>
                                            <li>Los bienes y/o servicios comercializados bajo la modalidad MARKET PLACE, serán entregados previo acuerdo entre el VENDEDOR y SEARS por medio de la mensajería que elijan, dentro del plazo ofrecido por los mismos o bien, el VENDEDOR será responsable de todos aquellos envíos que realice a través de la mensajería que elija.</li>
                                        </ul>
                                    </li>
                                </ul>

                                <p>SEARS facilita las transacciones y comunicaciones a través de la PLATAFORMA MARKET PLACE, pero no es el comprador ni el vendedor de los bienes y/o servicios ofrecidos por el VENDEDOR, ni su intermediario. SEARS facilita una PLATAFORMA digital en la que el USUARIO y el VENDEDOR pueden negociar y efectuar transacciones de bienes y/o servicios que son sujetos de comercialización en términos de la legislación vigente y aplicable en los Estados Unidos Mexicanos. De tal virtud el contrato de compraventa de cualquier bien y/o servicio ofrecido por el VENDEDOR, es celebrado única y exclusivamente entre el USUARIO y el VENDEDOR correspondiente. SEARS no es bajo ninguna circunstancia parte de esa relación contractual ni asume responsabilidad alguna relacionada con el mismo, ni ante terceros, ni actúa como representante del VENDEDOR. El VENDEDOR es el único responsable de la venta de sus bienes y/o servicios, tanto de la entrega, como de la facturación y garantía, así como de ofrecer asistencia en cuanto a las reclamaciones del USUARIO, o con respecto a cualquier asunto relacionado con el contrato de compraventa y/o prestación de servicios celebrado entre el USUARIO y el VENDEDOR, por lo tanto SEARS no es responsable de alguna infracción a la Ley Federal de Protección al Consumidor, ni en materia de propiedad intelectual (marcas, patentes, modelos de utilidad, diseños, derechos de autor, derechos conexos, derechos de imagen, entre otros), sin embargo SEARS se reserva el derecho a supervisar y eliminar temporal o permanentemente y en cualquier momento sin previa notificación al VENDEDOR, cualquier publicación o artículo que sea reportado por los USUARIOS que acrediten tener derecho o la facultad sobre el artículo de publicación o que se trate de mercancía que no reúna los elementos de legitimidad e identificación de la marca que ostentan, conforme a lo estipulado en la Cláusula 17.</p>
                                <p>Cualquier persona que quiera beneficiarse y hacer uso de los Servicios de la PLATAFORMA MARKET PLACE para la adquisición de los bienes y/o servicios ofertados por el VENDEDOR correspondiente, deberá suscribirse o estar ya suscrita en el SITIO. El registro de USUARIO se realiza a través del llenado del formulario de registro y siguiendo todos y cada uno de los pasos que se detallan en el SITIO. El USUARIO al aceptar los términos y condiciones en la plataforma MARKET PLACE otorga su consentimiento liso y llano para que sus datos puedan ser validados por las Sociedades de Información Crediticia, o en alguna Institución Financiera o Crediticia. La calidad de USUARIO y el uso de los servicios de la PLATAFORMA MARKET PLACE, infieren la aceptación irrefutable de los TÉRMINOS Y CONDICIONES correspondientes.</p>

                                <p className="h3 bold black">3. Registro de Usuario y Contraseña.</p>
                                <p>Para adquirir la calidad de USUARIO, es obligatorio completar todos y cada uno de los campos que integran el formulario de registro con datos válidos, completos, correctos y actualizados, para poder utilizar los servicios que brinda SEARS, así como el o los domicilios que registre para que se envíen los bienes y/o se presenten los servicios que adquiera el USUARIO a través de la PLATAFORMA MARKET PLACE. El USUARIO deberá completarlo con su información personal de manera exacta, precisa y bajo protesta de decir verdad ('Datos Personales') y asume el compromiso de actualizar los Datos Personales conforme y tantas veces resulte necesario.</p>
                                <p>Proporcionar cualquier información falsa o inexacta, constituye el incumplimiento de estos TÉRMINOS Y CONDICIONES MARKET PLACE, por lo que el USUARIO desde este momento acepta que de proporcionar información falsa e inexacta SEARS podrá previa notificación cancelar la o las cuentas del USUARIO. SEARS podrá utilizar diversos medios para identificar a sus USUARIOS, incluso solicitarles copia legible y actualizada de la credencial para votar vigente del USUARIO, que concuerde con los datos del padrón electoral, así como su Clave Única de Registro de Población que por sus siglas se identifica como CURP o el Registro Federal de Contribuyentes del USUARIO que por sus siglas se identifica como RFC. El USUARIO garantiza y responde, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales proporcionados a SEARS, por lo que el USUARIO otorga su consentimiento expreso para que SEARS pueda validar sus datos ante cualquier Organismo Público y/o Privado.</p>
                                <p>El USUARIO proporcionará un correo electrónico para identificar su cuenta, y generará una “Contraseña” para acceder a dicha cuenta, la contraseña alfanumérica la cual consistirá de entre 6 y 30 caracteres constituido por números y letras, tales elementos fungirán como mecanismo de seguridad para la autenticación del USUARIO. La Contraseña deberá integrarse con las especificaciones y/o algoritmos que el sistema del SITIO le indique, la Contraseña será necesaria para que el USUARIO pueda acceder a su cuenta. Es obligación del USUARIO mantener y custodiar en todo momento la confidencialidad de su Contraseña la cual es personalísima e intransferible a un tercero, ya que responderá de cualquier operación asociada al usuario y contraseña que le fue asignada.</p>
                                <p>El USUARIO reconoce de manera indubitable que la “Contraseña”, es sustitutivo de su firma autógrafa por una de carácter electrónico, por lo que las constancias documentales o técnicas en donde aparezca, tendrán la fuerza, validez y efectos que la legislación vigente aplicable, le atribuya.</p>
                                <p>La “Contraseña” podrá ser modificada posteriormente por el USUARIO de conformidad con lo previsto en el presente Contrato y las políticas que SEARS adopte para tal efecto. El USUARIO reconoce el carácter personal, confidencial e intransferible de la “Contraseña” la cual quedará bajo su más estricta custodia, control y cuidado, por lo que será de la exclusiva responsabilidad del USUARIO cualquier daño o perjuicio que pudiese sufrir y/o causar como consecuencia del uso indebido de la misma.</p>
                                <p>La Cuenta es personalísima, única e intransferible, y está prohibido que un mismo USUARIO inscriba o posea más de una Cuenta. En caso que SEARS detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá dar de baja la Cuenta respectiva, previa notificación que haga al USUARIO, al domicilio que éste haya registrado en el SITIO o a través de correo electrónico a la dirección electrónica de correo que el USUARIO haya proporcionado a SEARS.</p>
                                <p>El USUARIO será responsable por todas y cada una de las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido y el uso de su “Contraseña” es exclusivo y único del USUARIO. El USUARIO se compromete a notificar a SEARS en forma inmediata y por medio idóneo siendo éste, el correo electrónico enviado a la dirección <a href="mailto:clientes@sears.com.mx">clientes@sears.com.mx</a> o llamando telefónicamente al número <a href="tel:015553451606">015553451606</a>, cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta, cesión o transferencia de la Cuenta (incluyendo la reputación y calificaciones) bajo ningún título.</p>
                                <p>SEARS en cualquier momento y bajo la mínima sospecha, podrá solicitar cualquier información adicional para validar y confirmar la identidad del USUARIO, por lo cual lo notificará de forma inmediata al USUARIO relativo, al domicilio que el USUARIO haya registrado en el SITIO, o por vía telefónica o a través de correo electrónico a la dirección electrónica de correo que el USUARIO haya proporcionado a SEARS.</p>
                                <p>SEARS podrá restringir, suspender o cancelar la cuenta del USUARIO, si detecta algún uso indebido del SITIO de los servicios que se ofrecen en el mismo, notificando al USUARIO de dicha cancelación, al domicilio que éste haya registrado en el SITIO o a través de correo electrónico a la dirección electrónica que el USUARIO haya proporcionado a SEARS.</p>
                                <p>El USUARIO autoriza expresamente a SEARS, para que pueda confirmar los Datos Personales suministrados acudiendo a sociedades de información crediticia, entidades públicas, compañías especializadas o centrales de riesgo, de igual forma SEARS en la modalidad MARKET PLACE, podrá consultar el historial crediticio del Usuario, con las empresas de información crediticia que legalmente operan en los Estados Unidos Mexicanos, para lo cual el USUARIO autoriza de forma expresa a SEARS para realizar estas consultas, en términos de la legislación mexicana aplicable. La información que SEARS obtenga de estas entidades será tratada en forma confidencial.</p>
                                <p>SEARS reconocerá la calidad de USUARIO, a toda aquella persona que previo a usar los Servicios de la PLATAFORMA MARKET PLACE, ya se hubiere registrado en el SITIO, para adquirir bienes y/o servicios ofrecidos directamente por SEARS en la modalidad de Tienda virtual, por lo cual no será necesario que el USUARIO respectivo se registre nuevamente.</p>

                                <p className="h3 bold black">4. Privacidad de la información.</p>
                                <p>Para utilizar el Contenido y los Servicios ofrecidos por SEARS, el USUARIO deberá facilitar determinados datos de carácter personal. Su información personal se procesa y almacena en servidores o medios magnéticos que mantienen altos estándares de seguridad y protección tanto física como tecnológica. Para mayor información sobre la privacidad de los Datos Personales y casos en los que será revelada la información personal, el USUARIO podrá consultar el Aviso de privacidad, el cual está disponible en la página <Link to="/aviso-privacidad">http://www.sears.com.mx/aviso/</Link></p>

                                <p className="h3 bold black">5. Imágenes.</p>
                                <p>SEARS no es responsable y no garantiza que el color o los colores, detalles y tamaño de las imágenes que el USUARIO aprecia en su monitor o dispositivo electrónico en el que visualice los bienes y/o servicios, coincida exactamente con el color y detalles físicos de cada producto exhibido electrónicamente en la PLATAFORMA, toda vez que la resolución del monitor o dispositivo electrónico en el que el USUARIO aprecie (pudiendo variar las condiciones visuales particulares de cada persona) los artículos y/o descripción de los servicios o imágenes de estos, no sea de la calidad necesaria para una observación apropiada del mismo; por cuanto hace a definición, nitidez, resolución de pantalla (número de pixeles), escala. Aunado a que los colores aquí mostrados son aproximados a los reales y pueden variar dependiendo el acabado e iluminación de todos y cada uno de los bienes y/o servicios exhibidos.</p>

                                <p className="h3 bold black">6. Aprobación y Confirmación de Recepción del Pedido.</p>
                                <p>El USUARIO acepta y reconoce que todas las operaciones de compraventa en la PLATAFORMA MARKET PLACE, están sujetas a la disponibilidad de los bienes y/o servicios relativos. En el supuesto de inexistencia del bien y/o servicio correspondiente, EL VENDEDOR está obligado a realizar la cancelación del pedido de los bienes y/o servicios adquiridos por el USUARIO informando previamente a través de la PLATAFORMA MARKET PLACE, dicha situación a través de correo electrónico, mensaje de texto SMS o telefónicamente al USUARIO, así como realizar la devolución de pago correspondiente, en la misma forma en que fue realizado éste , por lo que EL VENDEDOR en todo momento es el único responsable del aviso oportuno de la cancelación del pedido y realizar la devolución del pago efectuado por el USUARIO.</p>
                                <p>El USUARIO acepta y reconoce que el VENDEDOR ni SEARS, estarán obligados a procesar pedido alguno bajo la modalidad PLATAFORMA MARKET PLACE, en caso de que la institución financiera que el USUARIO elija para hacer el pago correspondiente, rechace la transacción relativa, o en caso de que el USUARIO no haga el pago respectivo a través del corresponsal de pago que hubiere elegido, dentro del plazo conferido para tal efecto, o en caso de que se agoten las existencias del bien y/o servicio ofrecido.</p>
                                <p>SEARS podrá confirmar vía correo electrónico o telefónicamente con el USUARIO, los pedidos que éste último haya generado en el SITIO bajo la modalidad PLATAFORMA MARKET PLACE. En caso de que el USUARIO, no reconozca los pedidos, SEARS procederá a la cancelación de los mismos.</p>
                                <p>Una vez confirmado el pedido por el USUARIO, el VENDEDOR a través de la PLATAFORMA MARKET PLACE, enviará un correo electrónico a la cuenta de correo electrónico del USUARIO, en el que se detallará el o los bienes y/o servicios adquiridos por éste último, el precio total a pagar, y la forma de pago seleccionada por el USUARIO, esto con la finalidad de que el USUARIO realice el pago de los artículos y/o servicios respectivos y el VENDEDOR realice la entrega o prestación del servicio relativo, por lo que El USUARIO está obligado a confirmar la recepción del bien y/o servicio dentro de las 24 horas naturales siguientes a que recibió el mismo, de lo contrario se entenderá como recibido por EL USUARIO.</p>

                                <p className="h3 bold black">7. Precio y forma de pago.</p>
                                <p>El precio de los bienes y/o servicios será el que se publique conjuntamente con la imagen del bien y/o servicio respectivo. El precio de los bienes estará sujeto a cambio sin previo aviso, no obstante, el VENDEDOR respetará todos aquellos pedidos que estén aprobados en términos de la cláusula que precede.</p>
                                <p>En caso de que el VENDEDOR publique erróneamente el precio de los artículos y/o servicios ofertados en la PLATAFORMA MARKET PLACE, el VENDEDOR, deberá de respetar dicho precio al USUARIO que haya ofertado o seleccionado la adquisición del bien y/o servicio mediante la misma PLATAFORMA informará al USUARIO dicha situación, a través de correo electrónico, para que el USUARIO indique si desea el producto y/o servicio al precio correcto, o si desea cancelar la operación y que el VENDEDOR le devuelva el importe pagado o cancele el cargo correspondiente, de acuerdo a la forma de pago que el USUARIO haya elegido. En el supuesto de que no sea posible contactar al USUARIO a través de correo electrónico o telefónicamente al número que el USUARIO proporcionó en su Registro, el VENDEDOR procederá a la cancelación correspondiente dentro de las 48 horas siguientes al haber efectuado el pedido.</p>
                                <p>Cada bien y/o servicio bajo la modalidad PLATAFORMA MARKET PLACE, indicará los medios de pago que en su caso, el VENDEDOR habilitó para que el USUARIO liquide el precio del producto y/o servicio que pretenda adquirir. El USUARIO podrá consultar las condiciones particulares para las Formas de pago que se habiliten en el SITIO (<Link to="/">www.sears.com.mx</Link>), para liquidar el precio de los bienes y/o servicios que se ofrecen en la PLATAFORMA MARKET PLACE.</p>
                                <p>“Si el USUARIO elije la forma de pago DEPOSITO y realice su pago con CHEQUE, el pedido será liberado una vez confirmado el pago”.</p>
                                <p>Ni SEARS ni el VENDEDOR serán responsables por los pagos que el USUARIO realice, en formas diversas a las habilitadas en la PLATAFORMA MARKET PLACE.</p>
                                <p>Si y solo si, el USUARIO elige como forma de pago, la opción pago con cargo a recibo Telmex®, SEARS fungirá como el acreditante del USUARIO, otorgando al USUARIO, el crédito de acuerdo al plazo que para tal efecto elija el propio USUARIO, para liquidar el precio del o los bienes y/o servicios que el USUARIO seleccione, para lo cual el USUARIO deberá suscribir electrónicamente el Contrato de Crédito bastando para tal efecto, dar “clic” en el botón de aceptar términos y condiciones de crédito SEARS –Cargo a recibo Telmex®. El USUARIO reconoce y acepta expresamente que su acreedor respecto del precio del o los bienes y/o servicios que adquiera, será SEARS, no obstante el VENDEDOR mantendrá las responsabilidades señaladas del inciso a) al inciso g) del apartado III (tres romano) de la Cláusula 2 de estos TÉRMINOS Y CONDICIONES MARKET PLACE.</p>

                                <p className="h3 bold black">8. Facturación.</p>
                                <p>El VENDEDOR de los bienes y/o servicios comercializados a través de PLATAFORMA MARKET PLACE, será el responsable de emitir y enviar la factura correspondiente, a favor del USUARIO que la solicite, dentro de las 72 horas posteriores a la compra y hasta 30 (treinta) días calendario después de haber realizado la compra. SEARS queda liberada de cualquier responsabilidad al respecto.</p>

                                <p className="h3 bold black">9. Entrega de los bienes y/o servicios.</p>
                                <p>Cada oferta de bienes y/o servicio, indicará los medios de entrega que el VENDEDOR habilitó para que el USUARIO lo reciba, incluso el costo y plazo de envío o fecha de entrega estimada, siendo sabedor el usuario que los plazos estimados de entrega se podrán prolongar en casos de fuerza mayor, caso fortuito, emergencias sanitarias y/o contingencia sanitaria.</p>
                                <p>En relación con lo anterior, el USUARIO se hace sabedor de que los plazos de entrega también pueden prolongarse bajo los siguientes eventos; ventas especiales, Hot Sale, buen fin y venta navideña.</p>
                                <p>El riesgo de pérdida de los bienes y/o servicios será del VENDEDOR, hasta el momento en que los entregue al USUARIO o a la persona designada por éste para la recepción de los mismos.</p>
                                <p>En el supuesto de que el USUARIO no reciba el bien y/o servicio dentro del plazo ofrecido por el VENDEDOR, podrá reportar a SEARS dicha anomalía, para que éste a su vez notifique al VENDEDOR, el retraso de la entrega del bien y/o servicio adquirido por el USUARIO, así mismo el USUARIO registrará la recepción del producto, a través de la pestaña de seguimiento que se habilitará en la cuenta del usuario respecto al bien y/o servicio adquirido, a efecto que otorgue el consentimiento de haber recibido el bien y/o servicio que adquirió del VENDEDOR.</p>
                                <p>El USUARIO se obliga a revisar los bienes que el VENDEDOR le entregue y en caso de que los mismos se encuentren dañados o no correspondan a los que el USUARIO ordenó, para el caso de los servicios, el USUARIO documentará las inconsistencias que observe y deberá asentarlo en el acuse de recibo correspondiente.</p>

                                <p className="h3 bold black">10. Garantía de los Bienes y/o Servicios.</p>
                                <p>La garantía de los bienes y/o servicios se otorga directamente por el VENDEDOR o los fabricantes del bien y/o servicio correspondiente. La vigencia de dichas garantías se indicarán en la descripción de cada bien y/o servicio, y son emitidas y validadas por el VENDEDOR, fabricante o distribuidor de cada producto, por lo que SEARS, no asume responsabilidad alguna en torno a dichas garantías. SEARS auxiliará al USUARIO con la finalidad de contactar al VENDEDOR del producto de que se trate para hacer efectiva dicha garantía, sin que SEARS esté obligada a hacer efectiva la garantía correspondiente.</p>
                                <p>SEARS, no será responsable por las garantías otorgadas por el VENDEDOR, fabricantes o distribuidores de los bienes y/o servicios que se comercialicen a través de MARKET PLACE, y podrá realizar gestiones tendientes a proporcionar al USUARIO que así lo solicite mediante el SITIO, los datos de contacto del VENDEDOR relativo.</p>

                                <p className="h3 bold black">11. Envío de Promociones y Ofertas.</p>
                                <p>El USUARIO al utilizar la PLATAFORMA MARKET PLACE, acepta recibir correos electrónicos promocionales del SITIO, no obstante, el USUARIO podrá solicitar que lo des inscriban del news letter (avisos oportunos) correspondiente, para dejar de recibir correos promocionales.</p>
                                <p>Lo anterior de conformidad con la Ley Federal de Protección al Consumidor, así como del Acuerdo por el que se establecen las reglas de operación y funcionamiento del Registro Público de Consumidores.</p>

                                <p className="h3 bold black">12. Violaciones del Sistema o Bases de Datos.</p>
                                <p>No está permitida acción alguna o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operación del SITIO ni de la PLATAFORMA MARKET PLACE como en las ofertas, descripciones, cuentas o bases de datos de SEARS. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad industrial o de derechos de autor y/o a las prohibiciones estipuladas en este Contrato, podrá encuadrarse en una conducta dolosa y posiblemente constitutiva de delito tanto del fuero común como Federal.</p>

                                <p className="h3 bold black">13. Sanciones y Suspensión de Operaciones.</p>
                                <p>Sin perjuicio de otras medidas, SEARS podrá advertir, suspender de forma temporal o inhabilitar definitivamente la Cuenta del USUARIO, con notificación que haga SEARS al USUARIO, al domicilio que éste haya registrado en el SITIO o a través de correo electrónico a la dirección electrónica que el USUARIO haya proporcionado a SEARS, iniciar las acciones que estime pertinentes y/o suspender la prestación de sus Servicios bajo la sospecha que: (a) se quebrantara alguna ley, o cualquiera de las estipulaciones de estos TÉRMINOS Y CONDICIONES MARKET PLACE y demás políticas de SEARS; (b) si incumpliera sus compromisos adquiridos como USUARIO bajo los términos y condiciones presentes; (c) si se incurriera a criterio de SEARS en conductas o actos dolosos o fraudulentos o mal intencionados; (d) no pudiera verificarse la identidad del USUARIO o cualquier información proporcionada por él fuere errónea; (e) SEARS entendiera que las publicaciones u otras acciones pueden ser causa de responsabilidad para el USUARIO que las publicó, para SEARS o para otros Usuarios.</p>

                                <p className="h3 bold black">14. No Asociación.</p>
                                <p>Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre SEARS, el VENDEDOR ni el USUARIO.</p>

                                <p className="h3 bold black">15. Fallas en el Sistema.</p>
                                <p>SEARS no se responsabiliza por cualquier daño, perjuicio o pérdida que se ocasione al USUARIO, derivado de fallas en el sistema, en el servidor o en Internet. SEARS tampoco será responsable por cualquier virus que pudiera infectar el equipo del USUARIO como consecuencia del acceso, uso o examen del SITIO o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. El USUARIO NO podrá imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. SEARS no garantiza el acceso y uso continuado o ininterrumpido de su SITIO. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a SEARS; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. SEARS no será responsable por ningún error u omisión contenidos en el SITIO.</p>

                                <p className="h3 bold black">16. Responsabilidad Legal de los Bienes y/o Servicios del VENDEDOR.</p>
                                <p>El VENDEDOR es el responsable de contar las autorizaciones vigentes y/o documentación necesarias para la legal importación, estancia y comercialización de los bienes y/o servicios en los Estados Unidos Mexicanos, y que se publiquen en el SITIO, bajo la modalidad PLATAFORMA MARKET PLACE; Por lo que en caso de que dicha comercialización infrinja o vulnere derechos de terceros en materia de propiedad industrial, derechos de autor, derechos conexos, derechos derivados, patentes, derechos de imagen, nombres comerciales o cualquier derecho patrimonial o moral de terceros, el VENDEDOR será el único responsable respecto a tales posibles infracciones.</p>
                                <p>En caso de que se presente alguna reclamación de terceros en términos de la Cláusula 17 siguiente, SEARS dará de baja la publicación del bien y/o servicio que se presuma infringe alguno de los derechos referidos en esta cláusula, y canalizará la reclamación relativa al VENDEDOR del bien y/o servicio respectivo, hasta la solución de la presunta infracción.</p>

                                <p className="h3 bold black">17. Reclamaciones en Materia de Propiedad Intelectual.</p>
                                <p>SEARS respeta los derechos de propiedad intelectual de terceros, por lo cual implementó un proceso para que a través de éste, se le puedan reportar bienes que se ofertan en el SITIO bajo la modalidad PLATAFORMA MARKET PLACE y que el USUARIO o cualquier tercero considere que se están infringiendo derechos Propiedad Intelectual de terceros.</p>
                                <p>Esta implementación tiene por objeto permitir a los titulares de derechos de propiedad intelectual (derechos de autor, derechos conexos, marcas, patentes, derechos de imagen, entre otros) que notifiquen a SEARS si creen que sus derechos están siendo vulnerados por la venta de algún o algunos de los bienes que se exhiben en el SITIO.</p>
                                <p>En este supuesto, SEARS procederá a dar de baja el bien o bienes ofertados bajo la modalidad PLATAFORMA MARKET PLACE. SEARS podrá reactivar la exhibición de dichos bienes una vez que el posible afectado notifique a SEARS la solución de dicha controversia.</p>
                                <p>Cualquier persona física o moral que sea titular de un derecho de propiedad intelectual podrá presentar el reclamo cuando considere que se le está vulnerando un derecho. En el caso de personas morales, esta notificación deberá ser presentada por su representante legal o quien esté autorice mediante documento escrito.</p>

                                <p>Para efectuar dicha notificación, se deberá acreditar fehacientemente ante SEARS con los documentos o títulos idóneos y necesarios que ostenten la titularidad de los Derechos de Propiedad Intelectual y/o derechos de autor, que se presumen vulnerados, lo anterior con la finalidad de integrar el expediente respectivo, se deberá enviar una copia del ocurso con firma autógrafa, a través del cual se informe la presunta infracción, copia de identificación oficial, título de registro a favor del ocursante, documentación que acredite la legítima representación del presunto titular afectado, al correo: <a href="mailto:clientes@sears.com">clientes@sears.com</a> (siendo esta documentación enunciativa mas no limitativa.)</p>
                                <p>SEARS, dará el respectivo valor a constancias de trámites en proceso de inscripción.</p>
                                <p>Una vez que SEARS reciba la notificación en comento, procederá a comunicarlo al VENDEDOR, la existencia de una inconformidad respecto a derechos de propiedad intelectual respecto a los Bienes que éste comercializa, además revisará los documentos aportados y requerirá al VENDEDOR correspondiente para que dentro de las 24 (veinticuatro) horas siguientes al requerimiento, aporte las pruebas pertinentes para comprobar que no se están violando los derechos de propiedad Intelectual de terceros, por lo que una vez fenecido el término anteriormente citado SEARS procederá a suspender la página para el caso de no contar con la documentación requerida.</p>
                                <p>En caso de no ser procedente la reclamación, se contestará al correo electrónico del ocursante, informando los motivos de esta decisión. Si por el contrario, SEARS logra verificar que los bienes y publicaciones efectivamente infringen derechos de terceros, serán eliminados definitivamente del SITIO, y aportará al titular afectado, la información que requiera en términos de la Legislación aplicable.</p>

                                <p className="h3 bold black">18. Anexos.</p>
                                <p>Forma parte integral e inseparable de los TÉRMINOS Y CONDICIONES SEARS MODALIDAD MARKET PLACE, el aviso de privacidad de SEARS, publicado en el SITIO.</p>

                                <p className="h3 bold black">19. Modificaciones del Acuerdo.</p>
                                <p>SEARS, se reserva el derecho de modificar en cualquier momento los TÉRMINOS Y CONDICIONES SEARS MODALIDAD MARKET PLACE, dichas modificaciones surtirán efectos cuando se publiquen en el SITIO. SEARS se obliga a notificar al USUARIO a través de correo electrónico que enviará a la cuenta de correo que el USUARIO haya registrado en el SITIO, que se modificaron dichos TÉRMINOS Y CONDICIONES MARKET PLACE, siendo obligación del USUARIO revisar en el SITIO, las modificaciones de los TÉRMINOS Y CONDICIONES MARKET PLACE. En caso de que el USUARIO no esté de acuerdo con las modificaciones de los TÉRMINOS Y CONDICIONES MARKET PLACE, deberá abstenerse de manera inmediata de usar los Servicios materia del presente Contrato, de lo contrario, si continúa usando los Servicios de PLATAFORMA MARKET PLACE, se entenderá que el USUARIO tácitamente acepta las modificaciones que sufran los TÉRMINOS Y CONDICIONES MARKET PLACE.</p>

                                <p className="h3 bold black">20. Aviso de Privacidad.</p>
                                <p>El USUARIO reconoce que SEARS ha puesto a su disposición el Aviso de Privacidad correspondiente, mismo que puede ser consultado en el apartado de Aviso de Privacidad del SITIO, así como que ha sido informado conforme a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento sobre la identidad y domicilio de SEARS; los datos personales que SEARS recabará; las finalidades primarias y secundarias del tratamiento de dichos datos; los medios ofrecidos para limitar el uso o divulgación de sus datos y/o revocar su consentimiento, así como para ejercer sus derechos de acceso, rectificación, cancelación y oposición; las transferencias de sus datos, que en su caso, se efectúan para el cumplimiento de las obligaciones derivadas del presente Contrato.</p>
                                <p>SEARS, conforme a la normatividad aplicable, podrá realizar cambios o actualizaciones a su Aviso de Privacidad, mismos que dará a conocer al USUARIO a través de su página de internet siguiente <Link to="/aviso-privacidad">https://www.sears.com.mx/Aviso_Privacidad/</Link>, por lo que el USUARIO será responsable de revisar periódicamente el SITIO, para tener conocimiento de dichos cambios y/o actualizaciones.</p>

                                <p className="h3 bold black">21. Consentimiento.</p>
                                <p>El USUARIO confirma su entendimiento y aceptación a los TÉRMINOS Y CONDICIONES MARKET PLACE, la utilización por parte del USUARIO de cualquiera de los servicios de PLATAFORMA MARKET PLACE infiere su aceptación expresa a los mismos. En caso de que el USUARIO no acepte estos TÉRMINOS Y CONDICIONES MARKET PLACE, deberá abstenerse de utilizar de manera inmediata la PLATAFORMA MARKET PLACE.</p>

                                <p className="h3 bold black">22. Domicilio.</p>
                                <p>Se fija como domicilio de SEARS el ubicado en Norte 45, No. 1014, colonia Industrial Vallejo, C.P. 02300, Azcapotzalco, Ciudad de México.</p>
                                <p>Si tienes alguna duda sobre los Términos y Condiciones Generales o demás políticas y principios que rigen SEARS consulta nuestra sección de Ayuda.</p>

                                <p className="h3 bold black">23. Solución de Controversias y Competencia Jurisdiccional.</p>
                                <p>La Procuraduría Federal del Consumidor es competente para conocer en la vía administrativa para resolver cualquier controversia que se suscite sobre la interpretación o cumplimiento de este contrato entre el USUARIO y el VENDEDOR.</p>
                                <p>En lo que respecta a la vía judicial, para la interpretación y cumplimiento de presente contrato las partes se someten a la legislación aplicable en los Estados Unidos Mexicanos y a la jurisdicción y competencia de los Tribunales competentes con sede en la Ciudad de México, renunciando expresamente a cualquier otro fuero que por otra razón de sus domicilios o vecindad presentes o futuros, o por cualquier otra causa, pudiera corresponderles.</p>

                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export function PoliticaMarketPlaceMobile() {
    const history = useHistory();
    const isNativeApp = window.location.search.indexOf('isNativeApp=true')
    // cerrar modal
    const onClose = () => {
        // history.goBack(-1);
        window.location.href = "/acerca-sears"
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Terminos y Condiciones');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">
                    {isNativeApp < 0
                        ?
                            
                            <div className="contentCloseAN">
                                <div className="btn closeBack" onClick={onClose} ></div>
                                <p className="title">Términos y Condiciones Market Place</p>
                            </div>
                        : null
                    }
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <div className="contenedorImagenes">
                                    <ul>
                                        <img src="/img/logo-sears.svg" alt={"logo-sears"} width={100} />
                                    </ul>
                                </div>

                                <p className="pSliderL bold">CONTRATO DE PRESTACIÓN DE SERVICIOS DE PLATAFORMA SEARS OPERADORA MEXICO, S.A. DE C.V., MODALIDAD MARKET PLACE PARA LA EXHIBICIÓN DE BIENES Y SERVICIOS OFERTADOS POR TERCEROS AJENOS A SEARS, QUE CELEBRA POR UNA PARTE SEARS OPERADORA MEXICO, S.A. DE C.V. (EN LO SUCESIVO SEARS), Y POR LA OTRA EL USUARIO REGISTRADO EN EL PORTAL <Link to="/">www.sears.com</Link> (EL SITIO), DOCUMENTO QUE EN ADELANTE SE LE PODRÁ IDENTIFICAR COMO TÉRMINOS Y CONDICIONES SEARS MODALIDAD MARKET PLACE. CUALQUIER PERSONA QUE NO ACEPTE LOS TÉRMINOS Y CONDICIONES SEARS MODALIDAD MARKET PLACE, QUE TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y LA PLATAFORMA MARKET PLACE, YA QUE LA UTILIZACIÓN DEL MISMO IMPLICA EL CONSENTIMIENTO INEQUÍVOCO DEL USUARIO EL USO DEL SITIO, MODALIDAD SEARS MARKET SE ENTENDERÁ COMO EL TOTAL CONSENTIMIENTO A ESTOS TÉRMINOS Y CONDICIONES MARKET PLACE.</p>

                                <p className="pSliderL bold">Es obligación del USUARIO leer las condiciones establecidas en las Cláusulas de los presentes TÉRMINOS Y CONDICIONES MARKET PLACE, el Aviso de Privacidad (de ser actualizar el aviso de privacidad) de SEARS, así como los demás documentos incorporados a los mismos por referencia, previo al uso de la PLATAFORMA SEARS MODALIDAD MARKET PLACE; entendiendo los mismos y aceptando las obligaciones y alcances del presente documento.</p>

                                <div className="bxDeclaraciones">
                                    <h2 className="colorRed">DECLARACIONES</h2>
                                    <p className="h3 colorNegro">I.- Declara SEARS qué:</p>

                                    <ul className="ulDeclaracionesRomano">
                                        <li>Es una sociedad Anónima de Capital Variable organizada y existente de conformidad con las leyes de los Estados Unidos Mexicanos, que su registro federal de contribuyentes es SOM101125UEA y su página de internet www.sears.com.mx</li>
                                        <li>Su representante, cuenta con todas las facultades necesarias para la celebración del presente Contrato, mismas que no le han sido revocadas, modificadas, ni limitadas en forma alguna a esta fecha.</li>
                                        <li>Su domicilio fiscal y convencional es el ubicado en Lago Zurich No. 245, edificio Presa Falcón, piso 7, colonia Ampliación Granada, Miguel Hidalgo, C.P. 11529, Ciudad de México.</li>
                                        <li>Que es un prestador de servicios digitales ajeno a los bienes y/o servicios ofertados por EL VENDEDOR.</li>
                                    </ul>

                                    <p className="h3 colorNegro">II.- Declara EL CLIENTE qué:</p>
                                    <ul className="ulDeclaracionesRomano">
                                        <li>Es mayor de edad, y que cuenta con la capacidad suficiente para obligarse en los términos del presente contrato, y que conoce los derechos, obligaciones y alcances derivadas de las disposiciones aplicables a los servicios a que se refiere este Contrato.</li>
                                        <li>En caso de ser persona moral, su representante cuenta con las facultades legales suficientes para representarla y obligarla en términos de este Contrato.</li>
                                        <li>Que sus datos generales, domicilio, teléfono y correo electrónico establecido en el presente documento y/o en el formulario requisitado a través de la página www.sears.com.mx (en lo sucesivo el PORTAL), es información verídica, completa, correcta y actualizada, siendo responsable única y exclusivamente el mismo por la información proporcionada.</li>

                                        <li>El origen de los recursos y/o medios con los cuales efectuará los pagos en los términos del presente Contrato, son de su propiedad y proceden de fuente lícita y que no realizará transacciones destinadas a favorecer o encubrir actividades ilícitas.</li>
                                        <li>Que se encuentra consciente que todas las transacciones efectuadas en el portal www.sears.com.mx, a través del cual se encuentre asociada su usuario y contraseña serán atribuibles a su persona.</li>
                                        <li>Es su voluntad libre de cualquier vicio del consentimiento, celebrar el presente Contrato.</li>
                                    </ul>

                                    <p className="pSliderL">Vertidas las declaraciones las Partes sujetan este Contrato a las siguientes:</p>

                                    <h2 className="colorRed">CLÁUSULAS</h2>
                                    <p className="h3 colorNegro">1. Capacidad.</p>
                                    <p className="pSliderL">Los contenidos y servicios que ofrece el SITIO, están reservados y dirigidos únicamente para personas que tengan capacidad legal, por sí y no por terceros para contratar. Queda bajo la responsabilidad de los padres o tutores legales, supervisar la conducta de los menores de edad que ingresen al SITIO. En caso de inscribir a una persona moral como USUARIO, se deberá contar con la capacidad jurídica para contratar y obligarla en los términos del presente Contrato.</p>

                                    <p className="h3 colorNegro">2. Objeto y Particularidades.</p>
                                    <p className="pSliderL">Los TÉRMINOS Y CONDICIONES MARKET PLACE, tienen por objeto regular única y exclusivamente el uso de la PLATAFORMA MARKET PLACE, misma que forma parte del SITIO, cuyo titular es SEARS, y a través de la cual el USUARIO puede adquirir bienes y/o servicios ofrecidos y vendidos por personas físicas o morales distintas a SEARS a quienes en lo sucesivo y de forma individual o colectiva se les denominará como el VENDEDOR.</p>
                                    <p className="pSliderL">SEARS presta el servicio de exhibición virtual de bienes y/o servicios que comercializa directamente el VENDEDOR, bajo sus propios términos y condiciones, a través de la publicación de ofertas y promociones de bienes y servicios bajo la modalidad PLATAFORMA MARKET PLACE, ofreciendo a los USUARIOS la posibilidad de su adquisición de acuerdo a las especificaciones de comercialización que el VENDEDOR publique para cada bien y/o servicio en específico, así como la mensajería electrónica entre el USUARIO y el VENDEDOR.</p>
                                    <p className="pSliderL">La comercialización de los bienes y/o servicios a través de la PLATAFORMA MARKET PLACE, se identificará de acuerdo a lo siguiente:</p>

                                    <ul className="ulDeclaracionesRomano">
                                        <li>Se identificará plenamente que los bienes y/o servicios respectivos, son ofertados por el VENDEDOR, y se comercializan bajo la modalidad MARKET PLACE, y se especificará el nombre comercial del VENDEDOR que comercializa el bien y/o servicio de que se trate.</li>
                                        <li>En cada publicación de bienes y/o servicios, el VENDEDOR pondrá a disposición del USUARIO, la información en idioma español (Precios en Moneda Nacional) de los bienes y/o servicios, que por lo menos será la siguiente (las cuales son enunciativas mas no limitativas y dependen exclusivamente del VENDEDOR):

                                    <ul className="ulDeclaracionesDisc">
                                                <li>Imagen y descripción detallada del bien y/o servicio que se oferta.</li>
                                                <li>Cantidad de bienes y/o servicios disponibles.</li>
                                                <li>Condiciones de envío y de transporte de los bienes y/o servicios, o plazo para la prestación de los servicios.</li>
                                                <li>Imágenes digitalizadas de los Bienes y/o Servicios.</li>
                                                <li>El precio de venta, gastos adicionales y la vigencia de la oferta.</li>
                                                <li>Marca y modelo de los Bienes y/o servicios, según corresponda.</li>
                                                <li>Accesorios que incluyen los Bienes y/o Servicios.</li>
                                                <li>Condiciones y requerimientos de uso y manejo para la exigibilidad de las garantías.</li>
                                                <li>Plazo de garantía de los bienes y/o servicios.</li>
                                                <li>Condiciones de devoluciones de los bienes y/o servicios.</li>
                                            </ul>
                                        </li>

                                        <li>El VENDEDOR es plenamente responsable ante el USUARIO, de:
                                    <ul className="ulDeclaracionesDisc">
                                                <li>La calidad del bien y/o servicio que comercialice.</li>
                                                <li>La gestión de venta.</li>
                                                <li>La entrega del bien o la prestación del servicio.</li>
                                                <li>El cobro del precio del bien y/o servicio.</li>
                                                <li>Otorgar o gestionar la garantía del bien y/o servicio que corresponda.</li>
                                                <li>Responder por cualquier infracción a derechos de propiedad industrial (marcas, patentes, modelos de utilidad, etc.), derechos de autor, derechos conexos, derechos de imagen, entre otros, relativos a los bienes y/o servicios que comercialice.</li>
                                                <li>De cualquier procedimiento que pudiera iniciar el USUARIO con motivo de la garantía del bien y/o servicio, o de la adquisición del mismo.</li>
                                                <li>A respetar el precio de venta de los bienes y/o servicios publicados por El VENDEDOR.</li>
                                                <li>A proporcionar la factura del producto y/o servicio adquirido en un término no mayor a 72 horas, después de haber sido solicitada por el USUARIO.</li>
                                                <li>Los bienes y/o servicios comercializados bajo la modalidad MARKET PLACE, serán entregados previo acuerdo entre el VENDEDOR y SEARS por medio de la mensajería que elijan, dentro del plazo ofrecido por los mismos o bien, el VENDEDOR será responsable de todos aquellos envíos que realice a través de la mensajería que elija.</li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <p className="pSliderL">SEARS facilita las transacciones y comunicaciones a través de la PLATAFORMA MARKET PLACE, pero no es el comprador ni el vendedor de los bienes y/o servicios ofrecidos por el VENDEDOR, ni su intermediario. SEARS facilita una PLATAFORMA digital en la que el USUARIO y el VENDEDOR pueden negociar y efectuar transacciones de bienes y/o servicios que son sujetos de comercialización en términos de la legislación vigente y aplicable en los Estados Unidos Mexicanos. De tal virtud el contrato de compraventa de cualquier bien y/o servicio ofrecido por el VENDEDOR, es celebrado única y exclusivamente entre el USUARIO y el VENDEDOR correspondiente. SEARS no es bajo ninguna circunstancia parte de esa relación contractual ni asume responsabilidad alguna relacionada con el mismo, ni ante terceros, ni actúa como representante del VENDEDOR. El VENDEDOR es el único responsable de la venta de sus bienes y/o servicios, tanto de la entrega, como de la facturación y garantía, así como de ofrecer asistencia en cuanto a las reclamaciones del USUARIO, o con respecto a cualquier asunto relacionado con el contrato de compraventa y/o prestación de servicios celebrado entre el USUARIO y el VENDEDOR, por lo tanto SEARS no es responsable de alguna infracción a la Ley Federal de Protección al Consumidor, ni en materia de propiedad intelectual (marcas, patentes, modelos de utilidad, diseños, derechos de autor, derechos conexos, derechos de imagen, entre otros), sin embargo SEARS se reserva el derecho a supervisar y eliminar temporal o permanentemente y en cualquier momento sin previa notificación al VENDEDOR, cualquier publicación o artículo que sea reportado por los USUARIOS que acrediten tener derecho o la facultad sobre el artículo de publicación o que se trate de mercancía que no reúna los elementos de legitimidad e identificación de la marca que ostentan, conforme a lo estipulado en la Cláusula 17.</p>
                                    <p className="pSliderL">Cualquier persona que quiera beneficiarse y hacer uso de los Servicios de la PLATAFORMA MARKET PLACE para la adquisición de los bienes y/o servicios ofertados por el VENDEDOR correspondiente, deberá suscribirse o estar ya suscrita en el SITIO. El registro de USUARIO se realiza a través del llenado del formulario de registro y siguiendo todos y cada uno de los pasos que se detallan en el SITIO. El USUARIO al aceptar los términos y condiciones en la plataforma MARKET PLACE otorga su consentimiento liso y llano para que sus datos puedan ser validados por las Sociedades de Información Crediticia, o en alguna Institución Financiera o Crediticia. La calidad de USUARIO y el uso de los servicios de la PLATAFORMA MARKET PLACE, infieren la aceptación irrefutable de los TÉRMINOS Y CONDICIONES correspondientes.</p>

                                    <p className="h3 colorNegro">3. Registro de Usuario y Contraseña.</p>
                                    <p className="pSliderL">Para adquirir la calidad de USUARIO, es obligatorio completar todos y cada uno de los campos que integran el formulario de registro con datos válidos, completos, correctos y actualizados, para poder utilizar los servicios que brinda SEARS, así como el o los domicilios que registre para que se envíen los bienes y/o se presenten los servicios que adquiera el USUARIO a través de la PLATAFORMA MARKET PLACE. El USUARIO deberá completarlo con su información personal de manera exacta, precisa y bajo protesta de decir verdad ('Datos Personales') y asume el compromiso de actualizar los Datos Personales conforme y tantas veces resulte necesario.</p>
                                    <p className="pSliderL">Proporcionar cualquier información falsa o inexacta, constituye el incumplimiento de estos TÉRMINOS Y CONDICIONES MARKET PLACE, por lo que el USUARIO desde este momento acepta que de proporcionar información falsa e inexacta SEARS podrá previa notificación cancelar la o las cuentas del USUARIO. SEARS podrá utilizar diversos medios para identificar a sus USUARIOS, incluso solicitarles copia legible y actualizada de la credencial para votar vigente del USUARIO, que concuerde con los datos del padrón electoral, así como su Clave Única de Registro de Población que por sus siglas se identifica como CURP o el Registro Federal de Contribuyentes del USUARIO que por sus siglas se identifica como RFC. El USUARIO garantiza y responde, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales proporcionados a SEARS, por lo que el USUARIO otorga su consentimiento expreso para que SEARS pueda validar sus datos ante cualquier Organismo Público y/o Privado.</p>
                                    <p className="pSliderL">El USUARIO proporcionará un correo electrónico para identificar su cuenta, y generará una “Contraseña” para acceder a dicha cuenta, la contraseña alfanumérica la cual consistirá de entre 6 y 30 caracteres constituido por números y letras, tales elementos fungirán como mecanismo de seguridad para la autenticación del USUARIO. La Contraseña deberá integrarse con las especificaciones y/o algoritmos que el sistema del SITIO le indique, la Contraseña será necesaria para que el USUARIO pueda acceder a su cuenta. Es obligación del USUARIO mantener y custodiar en todo momento la confidencialidad de su Contraseña la cual es personalísima e intransferible a un tercero, ya que responderá de cualquier operación asociada al usuario y contraseña que le fue asignada.</p>
                                    <p className="pSliderL">El USUARIO reconoce de manera indubitable que la “Contraseña”, es sustitutivo de su firma autógrafa por una de carácter electrónico, por lo que las constancias documentales o técnicas en donde aparezca, tendrán la fuerza, validez y efectos que la legislación vigente aplicable, le atribuya.</p>
                                    <p className="pSliderL">La “Contraseña” podrá ser modificada posteriormente por el USUARIO de conformidad con lo previsto en el presente Contrato y las políticas que SEARS adopte para tal efecto. El USUARIO reconoce el carácter personal, confidencial e intransferible de la “Contraseña” la cual quedará bajo su más estricta custodia, control y cuidado, por lo que será de la exclusiva responsabilidad del USUARIO cualquier daño o perjuicio que pudiese sufrir y/o causar como consecuencia del uso indebido de la misma.</p>
                                    <p className="pSliderL">La Cuenta es personalísima, única e intransferible, y está prohibido que un mismo USUARIO inscriba o posea más de una Cuenta. En caso que SEARS detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá dar de baja la Cuenta respectiva, previa notificación que haga al USUARIO, al domicilio que éste haya registrado en el SITIO o a través de correo electrónico a la dirección electrónica de correo que el USUARIO haya proporcionado a SEARS.</p>
                                    <p className="pSliderL">El USUARIO será responsable por todas y cada una de las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido y el uso de su “Contraseña” es exclusivo y único del USUARIO. El USUARIO se compromete a notificar a SEARS en forma inmediata y por medio idóneo siendo éste, el correo electrónico enviado a la dirección <a href="mailto:clientes@sears.com.mx">clientes@sears.com.mx</a> o llamando telefónicamente al número <a href="tel:015553451606">015553451606</a>, cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta, cesión o transferencia de la Cuenta (incluyendo la reputación y calificaciones) bajo ningún título.</p>
                                    <p className="pSliderL">SEARS en cualquier momento y bajo la mínima sospecha, podrá solicitar cualquier información adicional para validar y confirmar la identidad del USUARIO, por lo cual lo notificará de forma inmediata al USUARIO relativo, al domicilio que el USUARIO haya registrado en el SITIO, o por vía telefónica o a través de correo electrónico a la dirección electrónica de correo que el USUARIO haya proporcionado a SEARS.</p>
                                    <p className="pSliderL">SEARS podrá restringir, suspender o cancelar la cuenta del USUARIO, si detecta algún uso indebido del SITIO de los servicios que se ofrecen en el mismo, notificando al USUARIO de dicha cancelación, al domicilio que éste haya registrado en el SITIO o a través de correo electrónico a la dirección electrónica que el USUARIO haya proporcionado a SEARS.</p>
                                    <p className="pSliderL">El USUARIO autoriza expresamente a SEARS, para que pueda confirmar los Datos Personales suministrados acudiendo a sociedades de información crediticia, entidades públicas, compañías especializadas o centrales de riesgo, de igual forma SEARS en la modalidad MARKET PLACE, podrá consultar el historial crediticio del Usuario, con las empresas de información crediticia que legalmente operan en los Estados Unidos Mexicanos, para lo cual el USUARIO autoriza de forma expresa a SEARS para realizar estas consultas, en términos de la legislación mexicana aplicable. La información que SEARS obtenga de estas entidades será tratada en forma confidencial.</p>
                                    <p className="pSliderL">SEARS reconocerá la calidad de USUARIO, a toda aquella persona que previo a usar los Servicios de la PLATAFORMA MARKET PLACE, ya se hubiere registrado en el SITIO, para adquirir bienes y/o servicios ofrecidos directamente por SEARS en la modalidad de Tienda virtual, por lo cual no será necesario que el USUARIO respectivo se registre nuevamente.</p>

                                    <p className="h3 colorNegro">4. Privacidad de la información.</p>
                                    <p className="pSliderL">Para utilizar el Contenido y los Servicios ofrecidos por SEARS, el USUARIO deberá facilitar determinados datos de carácter personal. Su información personal se procesa y almacena en servidores o medios magnéticos que mantienen altos estándares de seguridad y protección tanto física como tecnológica. Para mayor información sobre la privacidad de los Datos Personales y casos en los que será revelada la información personal, el USUARIO podrá consultar el Aviso de privacidad, el cual está disponible en la página <Link to="/aviso-privacidad">http://www.sears.com.mx/aviso/</Link></p>

                                    <p className="h3 colorNegro">5. Imágenes.</p>
                                    <p className="pSliderL">SEARS no es responsable y no garantiza que el color o los colores, detalles y tamaño de las imágenes que el USUARIO aprecia en su monitor o dispositivo electrónico en el que visualice los bienes y/o servicios, coincida exactamente con el color y detalles físicos de cada producto exhibido electrónicamente en la PLATAFORMA, toda vez que la resolución del monitor o dispositivo electrónico en el que el USUARIO aprecie (pudiendo variar las condiciones visuales particulares de cada persona) los artículos y/o descripción de los servicios o imágenes de estos, no sea de la calidad necesaria para una observación apropiada del mismo; por cuanto hace a definición, nitidez, resolución de pantalla (número de pixeles), escala. Aunado a que los colores aquí mostrados son aproximados a los reales y pueden variar dependiendo el acabado e iluminación de todos y cada uno de los bienes y/o servicios exhibidos.</p>

                                    <p className="h3 colorNegro">6. Aprobación y Confirmación de Recepción del Pedido.</p>
                                    <p className="pSliderL">El USUARIO acepta y reconoce que todas las operaciones de compraventa en la PLATAFORMA MARKET PLACE, están sujetas a la disponibilidad de los bienes y/o servicios relativos. En el supuesto de inexistencia del bien y/o servicio correspondiente, EL VENDEDOR está obligado a realizar la cancelación del pedido de los bienes y/o servicios adquiridos por el USUARIO informando previamente a través de la PLATAFORMA MARKET PLACE, dicha situación a través de correo electrónico, mensaje de texto SMS o telefónicamente al USUARIO, así como realizar la devolución de pago correspondiente, en la misma forma en que fue realizado éste , por lo que EL VENDEDOR en todo momento es el único responsable del aviso oportuno de la cancelación del pedido y realizar la devolución del pago efectuado por el USUARIO.</p>
                                    <p className="pSliderL">El USUARIO acepta y reconoce que el VENDEDOR ni SEARS, estarán obligados a procesar pedido alguno bajo la modalidad PLATAFORMA MARKET PLACE, en caso de que la institución financiera que el USUARIO elija para hacer el pago correspondiente, rechace la transacción relativa, o en caso de que el USUARIO no haga el pago respectivo a través del corresponsal de pago que hubiere elegido, dentro del plazo conferido para tal efecto, o en caso de que se agoten las existencias del bien y/o servicio ofrecido.</p>
                                    <p className="pSliderL">SEARS podrá confirmar vía correo electrónico o telefónicamente con el USUARIO, los pedidos que éste último haya generado en el SITIO bajo la modalidad PLATAFORMA MARKET PLACE. En caso de que el USUARIO, no reconozca los pedidos, SEARS procederá a la cancelación de los mismos.</p>
                                    <p className="pSliderL">Una vez confirmado el pedido por el USUARIO, el VENDEDOR a través de la PLATAFORMA MARKET PLACE, enviará un correo electrónico a la cuenta de correo electrónico del USUARIO, en el que se detallará el o los bienes y/o servicios adquiridos por éste último, el precio total a pagar, y la forma de pago seleccionada por el USUARIO, esto con la finalidad de que el USUARIO realice el pago de los artículos y/o servicios respectivos y el VENDEDOR realice la entrega o prestación del servicio relativo, por lo que El USUARIO está obligado a confirmar la recepción del bien y/o servicio dentro de las 24 horas naturales siguientes a que recibió el mismo, de lo contrario se entenderá como recibido por EL USUARIO.</p>

                                    <p className="h3 colorNegro">7. Precio y forma de pago.</p>
                                    <p className="pSliderL">El precio de los bienes y/o servicios será el que se publique conjuntamente con la imagen del bien y/o servicio respectivo. El precio de los bienes estará sujeto a cambio sin previo aviso, no obstante, el VENDEDOR respetará todos aquellos pedidos que estén aprobados en términos de la cláusula que precede.</p>
                                    <p className="pSliderL">En caso de que el VENDEDOR publique erróneamente el precio de los artículos y/o servicios ofertados en la PLATAFORMA MARKET PLACE, el VENDEDOR, deberá de respetar dicho precio al USUARIO que haya ofertado o seleccionado la adquisición del bien y/o servicio mediante la misma PLATAFORMA informará al USUARIO dicha situación, a través de correo electrónico, para que el USUARIO indique si desea el producto y/o servicio al precio correcto, o si desea cancelar la operación y que el VENDEDOR le devuelva el importe pagado o cancele el cargo correspondiente, de acuerdo a la forma de pago que el USUARIO haya elegido. En el supuesto de que no sea posible contactar al USUARIO a través de correo electrónico o telefónicamente al número que el USUARIO proporcionó en su Registro, el VENDEDOR procederá a la cancelación correspondiente dentro de las 48 horas siguientes al haber efectuado el pedido.</p>
                                    <p className="pSliderL">Cada bien y/o servicio bajo la modalidad PLATAFORMA MARKET PLACE, indicará los medios de pago que en su caso, el VENDEDOR habilitó para que el USUARIO liquide el precio del producto y/o servicio que pretenda adquirir. El USUARIO podrá consultar las condiciones particulares para las Formas de pago que se habiliten en el SITIO (<Link to="/">www.sears.com.mx</Link>), para liquidar el precio de los bienes y/o servicios que se ofrecen en la PLATAFORMA MARKET PLACE.</p>
                                    <p className="pSliderL">“Si el USUARIO elije la forma de pago DEPOSITO y realice su pago con CHEQUE, el pedido será liberado una vez confirmado el pago”.</p>
                                    <p className="pSliderL">Ni SEARS ni el VENDEDOR serán responsables por los pagos que el USUARIO realice, en formas diversas a las habilitadas en la PLATAFORMA MARKET PLACE.</p>
                                    <p className="pSliderL">Si y solo si, el USUARIO elige como forma de pago, la opción pago con cargo a recibo Telmex®, SEARS fungirá como el acreditante del USUARIO, otorgando al USUARIO, el crédito de acuerdo al plazo que para tal efecto elija el propio USUARIO, para liquidar el precio del o los bienes y/o servicios que el USUARIO seleccione, para lo cual el USUARIO deberá suscribir electrónicamente el Contrato de Crédito bastando para tal efecto, dar “clic” en el botón de aceptar términos y condiciones de crédito SEARS –Cargo a recibo Telmex®. El USUARIO reconoce y acepta expresamente que su acreedor respecto del precio del o los bienes y/o servicios que adquiera, será SEARS, no obstante el VENDEDOR mantendrá las responsabilidades señaladas del inciso a) al inciso g) del apartado III (tres romano) de la Cláusula 2 de estos TÉRMINOS Y CONDICIONES MARKET PLACE.</p>

                                    <p className="h3 colorNegro">8. Facturación.</p>
                                    <p className="pSliderL">El VENDEDOR de los bienes y/o servicios comercializados a través de PLATAFORMA MARKET PLACE, será el responsable de emitir y enviar la factura correspondiente, a favor del USUARIO que la solicite, dentro de las 72 horas posteriores a la compra y hasta 30 (treinta) días calendario después de haber realizado la compra. SEARS queda liberada de cualquier responsabilidad al respecto.</p>

                                    <p className="h3 colorNegro">9. Entrega de los bienes y/o servicios.</p>
                                    <p className="pSliderL">Cada oferta de bienes y/o servicio, indicará los medios de entrega que el VENDEDOR habilitó para que el USUARIO lo reciba, incluso el costo y plazo de envío o fecha de entrega estimada, siendo sabedor el usuario que los plazos estimados de entrega se podrán prolongar en casos de fuerza mayor, caso fortuito, emergencias sanitarias y/o contingencia sanitaria.</p>
                                    <p className="pSliderL">En relación con lo anterior, el USUARIO se hace sabedor de que los plazos de entrega también pueden prolongarse bajo los siguientes eventos; ventas especiales, Hot Sale, buen fin y venta navideña.</p>
                                    <p className="pSliderL">El riesgo de pérdida de los bienes y/o servicios será del VENDEDOR, hasta el momento en que los entregue al USUARIO o a la persona designada por éste para la recepción de los mismos.</p>
                                    <p className="pSliderL">En el supuesto de que el USUARIO no reciba el bien y/o servicio dentro del plazo ofrecido por el VENDEDOR, podrá reportar a SEARS dicha anomalía, para que éste a su vez notifique al VENDEDOR, el retraso de la entrega del bien y/o servicio adquirido por el USUARIO, así mismo el USUARIO registrará la recepción del producto, a través de la pestaña de seguimiento que se habilitará en la cuenta del usuario respecto al bien y/o servicio adquirido, a efecto que otorgue el consentimiento de haber recibido el bien y/o servicio que adquirió del VENDEDOR.</p>
                                    <p className="pSliderL">El USUARIO se obliga a revisar los bienes que el VENDEDOR le entregue y en caso de que los mismos se encuentren dañados o no correspondan a los que el USUARIO ordenó, para el caso de los servicios, el USUARIO documentará las inconsistencias que observe y deberá asentarlo en el acuse de recibo correspondiente.</p>

                                    <p className="h3 colorNegro">10. Garantía de los Bienes y/o Servicios.</p>
                                    <p className="pSliderL">La garantía de los bienes y/o servicios se otorga directamente por el VENDEDOR o los fabricantes del bien y/o servicio correspondiente. La vigencia de dichas garantías se indicarán en la descripción de cada bien y/o servicio, y son emitidas y validadas por el VENDEDOR, fabricante o distribuidor de cada producto, por lo que SEARS, no asume responsabilidad alguna en torno a dichas garantías. SEARS auxiliará al USUARIO con la finalidad de contactar al VENDEDOR del producto de que se trate para hacer efectiva dicha garantía, sin que SEARS esté obligada a hacer efectiva la garantía correspondiente.</p>
                                    <p className="pSliderL">SEARS, no será responsable por las garantías otorgadas por el VENDEDOR, fabricantes o distribuidores de los bienes y/o servicios que se comercialicen a través de MARKET PLACE, y podrá realizar gestiones tendientes a proporcionar al USUARIO que así lo solicite mediante el SITIO, los datos de contacto del VENDEDOR relativo.</p>

                                    <p className="h3 colorNegro">11. Envío de Promociones y Ofertas.</p>
                                    <p className="pSliderL">El USUARIO al utilizar la PLATAFORMA MARKET PLACE, acepta recibir correos electrónicos promocionales del SITIO, no obstante, el USUARIO podrá solicitar que lo des inscriban del news letter (avisos oportunos) correspondiente, para dejar de recibir correos promocionales.</p>
                                    <p className="pSliderL">Lo anterior de conformidad con la Ley Federal de Protección al Consumidor, así como del Acuerdo por el que se establecen las reglas de operación y funcionamiento del Registro Público de Consumidores.</p>

                                    <p className="h3 colorNegro">12. Violaciones del Sistema o Bases de Datos.</p>
                                    <p className="pSliderL">No está permitida acción alguna o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operación del SITIO ni de la PLATAFORMA MARKET PLACE como en las ofertas, descripciones, cuentas o bases de datos de SEARS. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad industrial o de derechos de autor y/o a las prohibiciones estipuladas en este Contrato, podrá encuadrarse en una conducta dolosa y posiblemente constitutiva de delito tanto del fuero común como Federal.</p>

                                    <p className="h3 colorNegro">13. Sanciones y Suspensión de Operaciones.</p>
                                    <p className="pSliderL">Sin perjuicio de otras medidas, SEARS podrá advertir, suspender de forma temporal o inhabilitar definitivamente la Cuenta del USUARIO, con notificación que haga SEARS al USUARIO, al domicilio que éste haya registrado en el SITIO o a través de correo electrónico a la dirección electrónica que el USUARIO haya proporcionado a SEARS, iniciar las acciones que estime pertinentes y/o suspender la prestación de sus Servicios bajo la sospecha que: (a) se quebrantara alguna ley, o cualquiera de las estipulaciones de estos TÉRMINOS Y CONDICIONES MARKET PLACE y demás políticas de SEARS; (b) si incumpliera sus compromisos adquiridos como USUARIO bajo los términos y condiciones presentes; (c) si se incurriera a criterio de SEARS en conductas o actos dolosos o fraudulentos o mal intencionados; (d) no pudiera verificarse la identidad del USUARIO o cualquier información proporcionada por él fuere errónea; (e) SEARS entendiera que las publicaciones u otras acciones pueden ser causa de responsabilidad para el USUARIO que las publicó, para SEARS o para otros Usuarios.</p>

                                    <p className="h3 colorNegro">14. No Asociación.</p>
                                    <p className="pSliderL">Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre SEARS, el VENDEDOR ni el USUARIO.</p>

                                    <p className="h3 colorNegro">15. Fallas en el Sistema.</p>
                                    <p className="pSliderL">SEARS no se responsabiliza por cualquier daño, perjuicio o pérdida que se ocasione al USUARIO, derivado de fallas en el sistema, en el servidor o en Internet. SEARS tampoco será responsable por cualquier virus que pudiera infectar el equipo del USUARIO como consecuencia del acceso, uso o examen del SITIO o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. El USUARIO NO podrá imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. SEARS no garantiza el acceso y uso continuado o ininterrumpido de su SITIO. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a SEARS; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. SEARS no será responsable por ningún error u omisión contenidos en el SITIO.</p>

                                    <p className="h3 colorNegro">16. Responsabilidad Legal de los Bienes y/o Servicios del VENDEDOR.</p>
                                    <p className="pSliderL">El VENDEDOR es el responsable de contar las autorizaciones vigentes y/o documentación necesarias para la legal importación, estancia y comercialización de los bienes y/o servicios en los Estados Unidos Mexicanos, y que se publiquen en el SITIO, bajo la modalidad PLATAFORMA MARKET PLACE; Por lo que en caso de que dicha comercialización infrinja o vulnere derechos de terceros en materia de propiedad industrial, derechos de autor, derechos conexos, derechos derivados, patentes, derechos de imagen, nombres comerciales o cualquier derecho patrimonial o moral de terceros, el VENDEDOR será el único responsable respecto a tales posibles infracciones.</p>
                                    <p className="pSliderL">En caso de que se presente alguna reclamación de terceros en términos de la Cláusula 17 siguiente, SEARS dará de baja la publicación del bien y/o servicio que se presuma infringe alguno de los derechos referidos en esta cláusula, y canalizará la reclamación relativa al VENDEDOR del bien y/o servicio respectivo, hasta la solución de la presunta infracción.</p>

                                    <p className="h3 colorNegro">17. Reclamaciones en Materia de Propiedad Intelectual.</p>
                                    <p className="pSliderL">SEARS respeta los derechos de propiedad intelectual de terceros, por lo cual implementó un proceso para que a través de éste, se le puedan reportar bienes que se ofertan en el SITIO bajo la modalidad PLATAFORMA MARKET PLACE y que el USUARIO o cualquier tercero considere que se están infringiendo derechos Propiedad Intelectual de terceros.</p>
                                    <p className="pSliderL">Esta implementación tiene por objeto permitir a los titulares de derechos de propiedad intelectual (derechos de autor, derechos conexos, marcas, patentes, derechos de imagen, entre otros) que notifiquen a SEARS si creen que sus derechos están siendo vulnerados por la venta de algún o algunos de los bienes que se exhiben en el SITIO.</p>
                                    <p className="pSliderL">En este supuesto, SEARS procederá a dar de baja el bien o bienes ofertados bajo la modalidad PLATAFORMA MARKET PLACE. SEARS podrá reactivar la exhibición de dichos bienes una vez que el posible afectado notifique a SEARS la solución de dicha controversia.</p>
                                    <p className="pSliderL">Cualquier persona física o moral que sea titular de un derecho de propiedad intelectual podrá presentar el reclamo cuando considere que se le está vulnerando un derecho. En el caso de personas morales, esta notificación deberá ser presentada por su representante legal o quien esté autorice mediante documento escrito.</p>

                                    <p className="pSliderL">Para efectuar dicha notificación, se deberá acreditar fehacientemente ante SEARS con los documentos o títulos idóneos y necesarios que ostenten la titularidad de los Derechos de Propiedad Intelectual y/o derechos de autor, que se presumen vulnerados, lo anterior con la finalidad de integrar el expediente respectivo, se deberá enviar una copia del ocurso con firma autógrafa, a través del cual se informe la presunta infracción, copia de identificación oficial, título de registro a favor del ocursante, documentación que acredite la legítima representación del presunto titular afectado, al correo: <a href="mailto:clientes@sears.com">clientes@sears.com</a> (siendo esta documentación enunciativa mas no limitativa.)</p>
                                    <p className="pSliderL">SEARS, dará el respectivo valor a constancias de trámites en proceso de inscripción.</p>
                                    <p className="pSliderL">Una vez que SEARS reciba la notificación en comento, procederá a comunicarlo al VENDEDOR, la existencia de una inconformidad respecto a derechos de propiedad intelectual respecto a los Bienes que éste comercializa, además revisará los documentos aportados y requerirá al VENDEDOR correspondiente para que dentro de las 24 (veinticuatro) horas siguientes al requerimiento, aporte las pruebas pertinentes para comprobar que no se están violando los derechos de propiedad Intelectual de terceros, por lo que una vez fenecido el término anteriormente citado SEARS procederá a suspender la página para el caso de no contar con la documentación requerida.</p>
                                    <p className="pSliderL">En caso de no ser procedente la reclamación, se contestará al correo electrónico del ocursante, informando los motivos de esta decisión. Si por el contrario, SEARS logra verificar que los bienes y publicaciones efectivamente infringen derechos de terceros, serán eliminados definitivamente del SITIO, y aportará al titular afectado, la información que requiera en términos de la Legislación aplicable.</p>

                                    <p className="h3 colorNegro">18. Anexos.</p>
                                    <p className="pSliderL">Forma parte integral e inseparable de los TÉRMINOS Y CONDICIONES SEARS MODALIDAD MARKET PLACE, el aviso de privacidad de SEARS, publicado en el SITIO.</p>

                                    <p className="h3 colorNegro">19. Modificaciones del Acuerdo.</p>
                                    <p className="pSliderL">SEARS, se reserva el derecho de modificar en cualquier momento los TÉRMINOS Y CONDICIONES SEARS MODALIDAD MARKET PLACE, dichas modificaciones surtirán efectos cuando se publiquen en el SITIO. SEARS se obliga a notificar al USUARIO a través de correo electrónico que enviará a la cuenta de correo que el USUARIO haya registrado en el SITIO, que se modificaron dichos TÉRMINOS Y CONDICIONES MARKET PLACE, siendo obligación del USUARIO revisar en el SITIO, las modificaciones de los TÉRMINOS Y CONDICIONES MARKET PLACE. En caso de que el USUARIO no esté de acuerdo con las modificaciones de los TÉRMINOS Y CONDICIONES MARKET PLACE, deberá abstenerse de manera inmediata de usar los Servicios materia del presente Contrato, de lo contrario, si continúa usando los Servicios de PLATAFORMA MARKET PLACE, se entenderá que el USUARIO tácitamente acepta las modificaciones que sufran los TÉRMINOS Y CONDICIONES MARKET PLACE.</p>

                                    <p className="h3 colorNegro">20. Aviso de Privacidad.</p>
                                    <p className="pSliderL">El USUARIO reconoce que SEARS ha puesto a su disposición el Aviso de Privacidad correspondiente, mismo que puede ser consultado en el apartado de Aviso de Privacidad del SITIO, así como que ha sido informado conforme a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento sobre la identidad y domicilio de SEARS; los datos personales que SEARS recabará; las finalidades primarias y secundarias del tratamiento de dichos datos; los medios ofrecidos para limitar el uso o divulgación de sus datos y/o revocar su consentimiento, así como para ejercer sus derechos de acceso, rectificación, cancelación y oposición; las transferencias de sus datos, que en su caso, se efectúan para el cumplimiento de las obligaciones derivadas del presente Contrato.</p>
                                    <p className="pSliderL">SEARS, conforme a la normatividad aplicable, podrá realizar cambios o actualizaciones a su Aviso de Privacidad, mismos que dará a conocer al USUARIO a través de su página de internet siguiente <Link to="/aviso-privacidad">https://www.sears.com.mx/Aviso_Privacidad/</Link>, por lo que el USUARIO será responsable de revisar periódicamente el SITIO, para tener conocimiento de dichos cambios y/o actualizaciones.</p>

                                    <p className="h3 colorNegro">21. Consentimiento.</p>
                                    <p className="pSliderL">El USUARIO confirma su entendimiento y aceptación a los TÉRMINOS Y CONDICIONES MARKET PLACE, la utilización por parte del USUARIO de cualquiera de los servicios de PLATAFORMA MARKET PLACE infiere su aceptación expresa a los mismos. En caso de que el USUARIO no acepte estos TÉRMINOS Y CONDICIONES MARKET PLACE, deberá abstenerse de utilizar de manera inmediata la PLATAFORMA MARKET PLACE.</p>

                                    <p className="h3 colorNegro">22. Domicilio.</p>
                                    <p className="pSliderL">Se fija como domicilio de SEARS el ubicado en Norte 45, No. 1014, colonia Industrial Vallejo, C.P. 02300, Azcapotzalco, Ciudad de México.</p>
                                    <p className="pSliderL">Si tienes alguna duda sobre los Términos y Condiciones Generales o demás políticas y principios que rigen SEARS consulta nuestra sección de Ayuda.</p>

                                    <p className="h3 colorNegro">23. Solución de Controversias y Competencia Jurisdiccional.</p>
                                    <p className="pSliderL">La Procuraduría Federal del Consumidor es competente para conocer en la vía administrativa para resolver cualquier controversia que se suscite sobre la interpretación o cumplimiento de este contrato entre el USUARIO y el VENDEDOR.</p>
                                    <p className="pSliderL">En lo que respecta a la vía judicial, para la interpretación y cumplimiento de presente contrato las partes se someten a la legislación aplicable en los Estados Unidos Mexicanos y a la jurisdicción y competencia de los Tribunales competentes con sede en la Ciudad de México, renunciando expresamente a cualquier otro fuero que por otra razón de sus domicilios o vecindad presentes o futuros, o por cualquier otra causa, pudiera corresponderles.</p>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export function PoliticaMarketPlace() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Terminos y Condiciones Market Place');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <PoliticaMarketPlaceDesktop />

                : isMobile === 'movile'
                    ? null
                    : <PoliticaMarketPlaceMobile />
            }
        </>
    )
}