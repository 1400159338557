import React from 'react'
import CardProductSkeleton from 'components/CardProduct/CardSkeleton'

export function HomeSkeletonCarruselNew(props) {
    const {
        position
    } = props

	return(
		<>
            <section className={`homePosition${position}`}>
                <div className="moduleCarruselHome">
                    <div className="container">
                        <p className="h3 bones"></p>
                        <div className="cardLine">
                            <CardProductSkeleton  cantidad={4}/>
                        </div>
                    </div>
                </div>
                <div className="moduleCarruselHome">
                    <div className="container">
                        <p className="h3 bones"></p>
                        <div className="cardGrid">
                            <CardProductSkeleton  cantidad={8}/>
                        </div>
                    </div>
                </div>
            </section>
		</>
	);
}


export function HomeSkeletonCarrusel(props) {
    const {
        position
    } = props

	return(
		<>
            <section className={`homePosition${position}`}>
                <div className="moduleCarruselHome">
                    <div className="container">
                        <p className="h3 bones"></p>
                        <div className="cardGrid">
                            <CardProductSkeleton  cantidad={4}/>
                        </div>
                    </div>
                </div>
                <div className="moduleCarruselHome">
                    <div className="container">
                        <p className="h3 bones"></p>
                        <div className="cardGrid">
                            <CardProductSkeleton  cantidad={8}/>
                        </div>
                    </div>
                </div>
            </section>
		</>
	);
}