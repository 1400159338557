import React from 'react';
import './style.sass';
import ReactDOM from 'react-dom';
import '../Modal/style.sass';

export default function ModalCategoria({ limpiarCategoria, verCategorias, seleccionarCategoria, listaCategorias, idCategoria }) {
    return (
        ReactDOM.createPortal(
            <div className="modal full mCategorias">
                <div className="contentModal largo contenidoCategorias">

                    <div className="seccionSuperiorCategoria">
                        <div className="contentClose">
                            <span onClick={verCategorias} className="btn close" > </span>
                            <p className="title" onClick={verCategorias}>Por departamento</p>

                            <span className="limpiarText" onClick={limpiarCategoria}></span>
                        </div>
                    </div>

                    <div className="dataModal iteracion">
                        <ul onChange={seleccionarCategoria}>
                            {listaCategorias.map((ca) =>
                                <li key={ca.id} >
                                    <label name={ca.id} htmlFor={ca.id}>  {ca.name} </label>
                                    <input className="radio" type="radio" value={ca.name} id={ca.id} name="categoria"
                                        checked={idCategoria === ca.id ? true : false}>
                                    </input>
                                </li>
                            )}
                        </ul>
                    </div>

                </div>
            </div>
            , document.getElementById('modal-root')
        )
    )
}