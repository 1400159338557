import React from 'react';
import ShareButton from '../../components/Share';
import { repareImage } from 'functions/repareDataService';

export default function OficialStore({ ...props }) {
    const storeImg = props.store[props.store.findIndex(item => item.N === props.logo)].V;
    const storeTitle = props.store[props.store.findIndex(item => item.N === props.title)].V;

    return (
        <div className="contOficalStore">
            <div className="stOficial">
                {storeImg.length > 5 && <img loading="lazy" src={repareImage(storeImg)} alt={storeTitle} width="110" height="50" /> }
                <div className="textOficial">
                    <h1 className="storeO">{storeTitle}</h1>
                    <p>Tienda Oficial</p>
                </div>


            </div>
            <ShareButton title={storeTitle} />
        </div>
    )
}