import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory } from "react-router-dom"
import { useStorage64 } from 'hooks/useStorageBase64'
import { BASE_PATH_PORTAL } from 'constants/general'
import { removeStorage } from 'functions/storageBase64'
import { useLogout } from 'hooks/useLogin'
import { useIsMobile } from 'hooks/useIsMobile'

import './styleheaderLogin.sass'

export function HeaderMiCuenta({ ...props }) {
    const {
        exactUrl,
        ruta = '/login',
        iconClass,
        anchor,
        showOnlyLogin,
        title,
        profile
    } = props

    const [isLoged] = useStorage64('session_token', '')
    const [profileData] = useStorage64('profileData', '')
    const [dataFace] = useStorage64('dataFacebook', '')
    const [nombre, setNombre] = useState(profileData.nombre)
    const history = useHistory()
    const hostName = window.location.href
    const pathName = window.location.pathname
    const [nombre0, setnombre0] = useState("")
    const { logOut } = useLogout()
    const { isMobile } = useIsMobile();

    let nombreArray = []

    useEffect(() => {

        if (isLoged && (!profileData || !dataFace)) {
            setNombre(profile.nombre)
            if (profile.nombre) {
                nombreArray = profile.nombre.split(' ')
                setnombre0(nombreArray[0])
            }
        }
    }, [profile, nombre])

    useEffect(() => {
        if (isLoged && dataFace.name) {
            nombreArray = dataFace.name.split(' ')
            setnombre0(nombreArray[0])
        }
    }, [dataFace])

    return (
        isMobile === "desktop"
            ?
                <li className="login icoMiCuenta">
                    {isLoged && (nombre || nombre0)
                        ? <>
                            <span className="textReference">
                                <span className="smallText">Bienvenido</span> {String(nombre0).length > 8 ? <>{String(nombre0).substring(0, 7)} ...</> : String(nombre0)}
                            </span>

                            <div className="box__login" id="box__login">
                                <dl>
                                    <dt>
                                        <Link to={"/mi-cuenta"}>Entrar a mi cuenta</Link>
                                    </dt>
                                    <dt>
                                        {ruta === '/login'
                                            ? <Link to={`/login?redirect=${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/dias=30/pagina=1`}>Revisar mis pedidos</Link>
                                            : <Link to={`${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/dias=30/pagina=1`}>Revisar mis pedidos</Link>
                                        }

                                    </dt>
                                </dl>
                                <dl className="logOut">
                                    <dt>
                                        <p>¿ No eres {nombre0} ?</p>
                                        <div className="cerrar" onClick={logOut}>Cerrar mi sesión</div>
                                    </dt>
                                </dl>
                            </div>
                        </>
                        : <>
                            <span className="textReference">
                                <span className="smallText"></span> Registrate
                        </span>
                            <div className="box__login" id="box__login">
                                <dl className="logIn">
                                    <dt>
                                        <Link className="redBtn btn-login" to={`/login?redirect=${hostName}`} >Ingresar</Link>
                                    </dt>
                                    <dd>
                                        <p>¿Eres Nuevo?</p>
                                        <Link className="standardBtn btn-registro" to={`/registro?redirect=${hostName}`}>Regístrate</Link>
                                    </dd>
                                </dl>
                            </div>
                        </>
                    }
                </li>
            :
                Boolean(anchor) !== true
                    ?
                        <NavLink
                            exact={exactUrl}
                            to={ruta}
                            className={'login icoMiCuenta'}
                            activeClassName="active"
                            aria-label={`Ir a menu ${title}`}
                            //data-url={ruta}
                        >
                            <span className="textReferenceMo">
                                <span className="smallTextMo">{isLoged && (nombre || nombre0) ? `${String(nombre0).substring(0, 7)} ...` : title}</span>
                            </span>
                        </NavLink>
                    :
                        <a
                            href={ruta}
                            className={pathName === ruta ? `login icoMiCuenta active` : iconClass}
                            aria-label={`Ir a menu ${title}`}
                        >
                            <span className="textReferenceMo">
                                <span className="smallTextMo">{isLoged && (nombre || nombre0) ? `${String(nombre0).substring(0, 7)} ...` : title}</span>
                            </span>
                        </a>
    )
}