import React, {useState, useEffect} from "react"
import "./style.sass"
import { BASE_PATH_RESOURCES, STAGE_DIRNAME } from 'constants/general'

export function MesaRegalos(){
    let urllocal = "https://www.sears.com.mx/"
    
    const [showModal, setShowModal] = useState(false)
    const [urlUnico, setUrlUnico] = useState('https://sears-app.uniko.co/')
    
    useEffect(()=>{
        if(STAGE_DIRNAME !== 'prod'){
            setUrlUnico("https://sears-app-test.uniko.co/")
        }
    },[])

    const modalActive = () => {
        setShowModal(true)
    }
    const modalHidden = () => {
        setShowModal(false)
    }

    const preguntasFrecuentes = (e) => {
        e.target.parentNode.classList.toggle("active")
    }

    return(
        <div className="landingMesa">
            <link href={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/css/styleMesaRegalos_0.css`} rel="stylesheet"></link>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway%3A100%2C200%2C300%2C400%2C500%2C600%2C700%2C800%2C900%2C100italic%2C300italic%2C400italic%2C700italic%7CMontserrat%3A100%2C200%2C300%2C400%2C500%2C600%2C700%2C800%2C900%2C100italic%2C300italic%2C400italic%2C700italic&amp;subset=latin%2Clatin-ext&amp;ver=1.0.1" type="text/css" media="all" />
            <header>
                <div className="container">
                    <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/sears-mesa-de-regalos-digital.png`} alt="Logo" className="logo" loading="lazy" />
                    <ul className="menuMesaTop">
                        <li><span className="activeModal" onClick={modalActive}>INGRESAR A MI MESA</span></li>
                        <li><a href="https://sears.uniko.co/como-funciona/" target="_blank">COMO FUNCIONA</a></li>
                        <li><a href="https://sears.uniko.co/blog/" target="_blank">BLOG</a></li>
                    </ul>
                </div>
            </header>

            <div className="wrapper">
           
                <section className="backgroundTemplate">
                    <span className="pxBG"></span>
                    <div className="container">
                        <h1>CONOCE LA MESA DE REGALOS DIGITAL SEARS</h1>
                        <h3>Regalos que transforman tu estilo de vida</h3>
                        <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home&amp;utm_campaign=abrir_mesa_header`} className="btn btn-rojo">CREAR MI MESA DIGITAL</a>
                    </div>
                </section>
            
                <section className="background-gris">
                    <div className="container contentModule">
                        <div className="cardModule">
                            <h3>¿ERES EL INVITADO?</h3>
                            <p>BUSCA UNA MESA DE REGALOS</p>
                            <a href={`${urlUnico}search?utm_source=sears_uniko&amp;utm_medium=home&amp;utm_campaign=home_boton_buscar_mesa`} className="btn btn-gris">BUSCAR MESA</a>
                        </div>
                        <div className="cardModule">
                            <h3>¿ERES EL FESTEJADO?</h3>
                            <p>INGRESA A TU MESA DE REGALOS</p>
                            <span className="btn btn-gris activeModal" onClick={modalActive}>INGRESAR A MI MESA</span>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <h2>¿CÓMO FUNCIONA?</h2>
                        <div className="contCardDataFirst lineBottom">
                            <div className="cardData">
                                <i className="qode_icon_font_awesome fa fa-desktop qode_icon_element" style={{color: "#e4002b;"}}></i>
                                <h4>PASO 1</h4>
                                <p>Abre tu mesa de regalos digital en sears-app.uniko.co</p>
                            </div>
                            <div className="cardData">
                                <i className="qode_icon_font_awesome fa fa-user qode_icon_element" style={{color: "#e4002b;"}}></i>
                                <h4>PASO 2</h4>
                                <p>Elige el tipo de evento y selecciona los mejores productos de nuestro catálogo</p>
                            </div>
                            <div className="cardData">
                                <i className="qode_icon_font_awesome fa fa-gift qode_icon_element" style={{color: "#e4002b;"}}></i>
                                <h4>PASO 3</h4>
                                <p>Recibe todo el dinero en tu monedero electrónico Sears y compra lo que más te guste</p>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <h2>OPCIONES PARA TODOS LOS EVENTOS</h2>
                        <div className="contCardEvent">
                            <div className="cardEvent">
                                <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/opcion1.jpg`} alt="BODA" loading="lazy" />
                                <div className="cardDataEvent">
                                    <h4>BODA</h4>
                                    <p>Dile "sí, acepto" y celebra tu boda con los mejores productos</p>
                                    <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home_uniko&amp;utm_campaign=abrir_mesa_boda`} target="_blank" className="btn btn-gris">ABRIR MI MESA</a>
                                </div>

                            </div>
                            <div className="cardEvent">
                                <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/opcion2.jpg`} alt="BABY SHOWER" loading="lazy" />
                                <div className="cardDataEvent">
                                    <h4>BABY SHOWER</h4>
                                    <p>Prepara todo para la llegada de tu bebé.</p>
                                    <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home_uniko&amp;utm_campaign=abrir_mesa_baby_shower`} target="_blank" className="btn btn-gris">ABRIR MI MESA</a>
            
                                </div>
                            </div>
                            <div className="cardEvent">
                                <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/opcion3.jpg`} alt="TU CUMPLEANOS" loading="lazy" />
                                <div className="cardDataEvent">
                                    <h4>TU CUMPLEAÑOS</h4>
                                    <p>¡Un años más! Festeja a lo grande y recibe todo lo que soñaste</p>
                                    <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home_uniko&amp;utm_campaign=abrir_mesa_cumple`} target="_blank" className="btn btn-gris">ABRIR MI MESA</a>
                                </div>
                            </div>
                            <div className="cardEvent">
                                <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/opcion4.jpg`} alt="GRADUACION" loading="lazy" />
                                <div className="cardDataEvent">
                                    <h4>GRADUACIÓN</h4>
                                    <p>Tantos años de esfuerzo merecer recompensa</p>
                                    <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home_uniko&amp;utm_campaign=abrir_mesa_boda_graduacion`} target="_blank" className="btn btn-gris">ABRIR MI MESA</a>
                                </div>
                            </div>
                            <div className="cardEvent">
                                <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/opcion5.jpg`} alt="DESPEDIDA DE SOLTERA/O" loading="lazy" />
                                <div className="cardDataEvent">
                                    <h4>DESPEDIDA DE SOLTERA/O</h4>
                                    <p>Has que los preparativos sean más divertidos!</p>
                                    <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home_uniko&amp;utm_campaign=abrir_mesa_despedida`} target="_blank" className="btn btn-gris">ABRIR MI MESA</a>
                                </div>
                            </div>
                            <div className="cardEvent">
                                <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/opcion6.jpg`} alt="TU NUEVO HOGAR" loading="lazy" />
                                <div className="cardDataEvent">
                                    <h4>TU NUEVO HOGAR</h4>
                                    <p>Decora tu nuevo hogar con los mejores productos</p>
                                    <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home_uniko&amp;utm_campaign=abrir_mesa_nuevo_hogar`} target="_blank" className="btn btn-gris">ABRIR MI MESA</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="otherEvents">
                            <h3>¿OTROS EVENTOS?</h3>
                            <p>Festeja cualquier momento especial tu graduación, tu nuevo hogar, bautizos y primeras comuniones, en fin... Lo que sea que celebres. Tenemos la mesa de regalos digital perfecta para que tu evento sea inolvidable</p>
                            <div className="contentBtn">
                                <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home&amp;utm_campaign=abrir_mesa_lo_mejor_de_la_mesa`} target="_blank" className="btn btn-rojo">ABRIR MI MESA DIGITAL</a>
                            </div>
                        </div>
                    </div>
                </section>
            
                <section className="background-gris">
                    <div className="container contentMod">
                        <h2>LO MEJOR DE LA MESA DE REGALOS DIGITAL SEARS</h2>
                        <div className="contentCard">
                            <div className="card">
                                <i className="qode_icon_font_awesome fa fa-gift qode_icon_element" style={{color: "#000000;"}}></i>
                                <h3>ARMA TU MESA</h3>
                                <p>Armar tu mesa te toma tan solo unos minutos y puedes hacerlo desde la comodidad de tu casa</p>
                            </div>
                            <div className="card">
                                <i className="qode_icon_font_awesome fa fa-shopping-cart qode_icon_element" style={{color: "#000000;"}}></i>
                                <h3>TUS INVITADOS</h3>
                                <p>Tus invitados disfrutan de una nueva manera de comprar de forma rápida, fácil y súper segura</p>
                            </div>
                            <div className="card">
                                <i className="qode_icon_font_awesome fa fa-usd qode_icon_element" style={{color: "#000000;"}}></i>
                                <h3>100% DE TU DINERO</h3>
                                <p>Recibes el 100% del dinero en tu monedero electrónico Sears para comprar lo que quieras</p>
                            </div>
                            <div className="card">
                                <i className="qode_icon_font_awesome fa fa-heart qode_icon_element" style={{color: "#000000;"}}></i>
                                <h3>DISFRUTA</h3>
                                <p>Envía agradecimientos online a todos tus invitados y disfruta de sus regalos</p>
                            </div>
                        </div>
                        <div className="contentBtn">
                            <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home&amp;utm_campaign=abrir_mesa_lo_mejor_de_la_mesa`} target="_self" className="btn btn-rojo">CREAR MI MESA DIGITAL</a>
                        </div>
                    </div>
                </section>

                <section className="preguntasFrecuentes">
                    <div className="container">
                        <h2>¿POR QUÉ NECESITAS UNA MESA DE REGALOS DIGITAL SEARS?</h2>
                        <div className="contCardDataSecond">
                            <div className="cardData">
                                <h3>DISEÑA CON LIBERTAD Y FESTEJA CUALQUIER OCASIÓN</h3>
                                <p>Tu boda, un bautizo, baby shower, graduaciones, 15 años… ahora cada momento tendrá tu sello y será aún más especial. Elige los mejores productos de nuestro catálogo, encuentra inspiración en las marcas más exclusivas y diseña las experiencias que quieras para personalizar cada momento con lo que más te gusta.</p>
                                <div className="contentImage">
                                    <img width="500" height="500" src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/sears-mesa-de-regalos-disena-tu-mesa.jpg?fit=500%2C500&amp;ssl=1`} loading="lazy" />
                                    <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home&amp;utm_campaign=abrir_mesa_disena_con_libertad`} target="_self" className="btn btn-rojo">CREAR MI MESA DIGITAL</a>
                                </div>
                            </div>
                            <div className="cardData">
                                <div className="contentImage">
                                    <img width="500" height="500" src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/image2.jpg`} loading="lazy" />
                                    <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home&amp;utm_campaign=abrir_mesa_disena_con_libertad`} target="_self" className="btn btn-rojo">CREAR MI MESA DIGITAL</a>
                                </div>
                                <h3>LA MEJOR INSPIRACIÓN Y LAS MEJORES MARCAS</h3>
                                <p>Todo un mundo de posibilidades para crear, compartir y diseñar tu mundo. Echa a volar tu imaginación y diseña un estilo de vida que marque esos momentos que jamás olvidarás. Con la mesa de regalos digital Sears recibes solo los regalos que tú quieras, siempre envueltos en una experiencia como ninguna.</p>
                            </div>
                            <div className="cardData">
                                <h3>TODO LO QUE SIEMPRE SOÑASTE</h3>
                                <p>A un click de distancia. Superar tus propias expectativas jamás había sido tan sencillo y divertido. Encuentra los mejores productos de tecnología, decoración, moda, joyería, accesorios para el hogar y artículos para bebé en el catálogo más exclusivo de productos. Compra lo que tú quieras y disfruta de una nueva forma de dar y recibir regalos con tu monedero electrónico Sears.</p>
                                <div className="contentImage">
                                    <img width="500" height="500" src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/sears-mesa-de-regalos-lo-que-sonaste.jpg?fit=500%2C500&amp;ssl=1`} loading="lazy" />
                                    <a href={`${urlUnico}login?utm_source=sears_uniko&amp;utm_medium=web_home&amp;utm_campaign=abrir_mesa_disena_con_libertad`} target="_self" className="btn btn-rojo">CREAR MI MESA DIGITAL</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <h2>PREGUNTAS FRECUENTES</h2>
                        <div className="accordion">
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>1. ¿Existe Alguna Diferencia Entre La Mesa De Regalos Tradicional Y La Digital?</dt>
                                <dd>La mesa de regalos tradicional es aquella que se abre directamente en tienda de forma personal, la mesa de regalos digital Sears la puedes crear con el acceso a sears-app.uniko.co. El servicio y la atención que te damos seguirán siendo las mismas, queremos que disfrutes de tus eventos ¡desde dondequiera que estés!</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>2. Si Ya Abrí Mi Mesa De Regalos En Tienda, ¿Cuál Es El Proceso Que Debo Seguir?</dt>
                                <dd>El proceso continúa directamente en tienda y con los accesos provistos cuando la abriste. De cualquier modo, si deseas hacer un cambio a la mesa de regalos digital Sears será posible si accedes a sears-app.uniko.co</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>3. ¿Cuáles Son Los Beneficios De CREAR Una Mesa De Regalos Digital En Sears?</dt>
                                <dd>Los beneficios son muchísimos: acceso al catálogo exclusivo de Sears, diseño personalizado de la mesa con los regalos que más te gusten, compras seguras para todos tus invitados, url personalizada para compartir con tus invitados, asistencia y ayuda por parte de nuestros asesores y todo el acumulado de tus regalos directamente en el monedero electrónico Sears para que lo gastes ¡en lo que tú quieras!</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>4. ¿Cómo Se Gestiona El Dinero De Mi Mesa De Regalos?</dt>
                                <dd>El proceso es 100% transparente y podrás acceder a tu mesa de regalos digital en cualquier momento. Ahí podrás ver en tiempo real cuántos y cuáles regalos has recibido y, del mismo modo, podrás saber cuánto llevas acumulado en tu monedero electrónico Sears. Si te gusta la idea, siempre podrás añadir más artículos a la mesa.</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>5. ¿Qué Es Un Monedero Electrónico Y Cómo Puedo Hacer Uso De Él?</dt>
                                <dd>Tu monedero electrónico es una tarjeta de regalo virtual que se genera al crear tu mesa de regalos digital. Con el número de tu monedero electrónico Sears podrás hacer compras y disfrutar de todo lo que tus invitados te han regalado en la tienda física y en sears.com.mx</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>6. ¿Cuáles Son Los Pasos Para Armar La Mesa De Regalos Digital?</dt>
                                <dd>Solo tienes que acceder a sears-app.uniko.co, seleccionar el evento que más te guste y sumar todos los productos que quieras a la mesa de regalos. Recuerda que la puedes compartir con todos tus invitados para que ellos puedan acceder y hacer compras para ti.</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>7. ¿Qué Regalos Puedo Añadir A Mi Mesa?</dt>
                                <dd>Todos los productos del catálogo de Sears podrás añadirlos a la mesa de regalos.</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>8. ¿Dónde Puedo Gastar El Dinero Acumulado De Mi Mesa De Regalos Digital?</dt>
                                <dd>Lo puedes gastar en tiendas SEARS y en sears.com.mx</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>9. ¿Existen Garantías De Seguridad Para Hacer Las Compras Online?</dt>
                                <dd>Contamos con todas las garantías para que tú y tus invitados puedan disfrutar de la mesa de regalos digital Sears. En caso de tener dudas o preguntas, siempre podrás contactar a nuestro equipo para guiarte paso a paso.</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>10. ¿Quién Puede Ayudar A Mis Invitados Si Tienen Alguna Duda?</dt>
                                <dd>Nuestros asesores estarán felices de ayudarte a ti y a tus invitados si es que tienen alguna pregunta.</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>11. ¿Puedo Tener Dos Mesas En Sears, Una Tradicional Y Otra Digital?</dt>
                                <dd>Por ahora solo puedes decidirte por una. Recuerda que la mesa de regalos tradicional se abre directamente en tienda física, mientras que la digital la puedes CREAR accediendo a sears-app.uniko.co</dd>
                            </dl>
                            <dl onClick={(e) => preguntasFrecuentes(e)}>
                                <dt>12. ¿Puedo cancelar el evento de mi mesa de regalos digital?</dt>
                                <dd>Puedes cancelar el evento haciendo click en el botón de cancelar evento ubicado en el apartado de "Mis Datos". No pueden hacerse cancelaciones una vez que ya se han recibido regalos dentro de la mesa digital a ese evento, o bien, si la mesa cuenta con órdenes en proceso de ser pagadas. Los eventos celebrados se cierran automáticamente 90 días posteriores a la fecha de celebración del evento.</dd>
                            </dl>

                        </div>
                    </div>
                </section>
            </div>

            <footer className="background-gris">
                <div className="container">
                    <div className="textwidget">
                        <p>
                            <a href="https://sears.uniko.co/wp-content/uploads/2020/08/aviso_de_privacidad_sears.pdf" target="_blank" rel="noopener noreferrer">AVISO&nbsp;DE PRIVACIDAD</a>
                        </p>
                        <p>COPYRIGHT 2020 – UNIKO®</p>
                    </div>

                    <div className="textwidget">
                        <p>
                            <a href="https://sears.uniko.co/facturacion/">FACTURACIÓN</a>
                        </p>
                        <p>MÉTODOS DE PAGO</p>
                        <p>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/Screenshot-2020-07-29-at-08.33.31.png?resize=147%2C26&amp;ssl=1`} alt="" width="147" height="26" data-recalc-dims="1" loading="lazy" /><noscript><img className="alignnone wp-image-328 " src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/Screenshot-2020-07-29-at-08.33.31.png?resize=147%2C26&#038;ssl=1`} alt="" width="147" height="26" data-recalc-dims="1" loading="lazy" /></noscript>
                        </p>
                    </div>
                </div>
            </footer>
            {showModal
                ?
                    <section class="modal show">
                        <div class="contModal">
                            <div class="modalClose" onClick={modalHidden}></div>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/Sears/uniko/img/sears-mesa-de-regalos-pareja.jpg?scale=400`} alt="modal"/>
                            <h3>¿EN DÓNDE ABRISTE TU MESA DE REGALOS?</h3>
                            <div>
                                <a href="https://wap-mesa-regalos.sears.com.mx/" target="_self" class="btn btn-rojo">ABRÍ MI MESA EN TIENDA</a>
                                <p>*Fuí a mi tienda Sears y abrí mi mesa con un asesor en tienda</p>
                                <a href={`${urlUnico}/login?utm_source=sears_uniko&amp;utm_medium=web_home&amp;utm_campaign=abrir_mesa_header`} target="_self" class="btn btn-rojo">ABRÍ MI MESA DIGITAL</a>
                                <p>*Abrí mi mesa digital desde el sitio web de Sears</p>
                            </div>
                        </div>
                    </section>
                : null
            }
        </div>
    )
}