import React, { useState, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import './tabs.sass';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CurrencyFormat from 'react-currency-format';
import { repareTextHtml } from 'functions/repareDataService';
import { ErrorBoundary } from 'components/Error/ErrorBoundary'

export function ModuleTabsMobile({ ...props }) {
    const {
        payment_methods,
        promoDepart,
    } = props;

    const [promoDepartamental, setPromoDepartamentla] = useState(false);

    useEffect(() => {
        if (promoDepart && promoDepart.length > 0) {
            setPromoDepartamentla(promoDepart)
        }
    }, [promoDepart])

    const [mostrarPromociones, setMostrarPromociones] = useState(false);
    const HandleMostrarPromociones = () => {
        setMostrarPromociones(!mostrarPromociones);
    }

    return (
        <div className="tabMobile">
            <ErrorBoundary>
                {promoDepartamental || (payment_methods?.find(item => item.id === 4468) && payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0) 
                    ?
                        <div onClick={HandleMostrarPromociones} className="secPromociones">
                            <p>Promociones</p>
                        </div>
                    : null
                }
            </ErrorBoundary>

            <ErrorBoundary>
                {mostrarPromociones === true &&
                    <Tabs forceRenderTabPanel={true}>
                        <ErrorBoundary>
                            <TabList id="tabMobile">
                                {(promoDepartamental)
                                    ? <Tab>Tarjetas Sears</Tab>
                                    : null
                                }

                                {(payment_methods?.find(item => item.id === 4468) && payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0)
                                    ? <Tab>Tarjetas Bancarias</Tab>
                                    : null
                                }
                            </TabList>
                        </ErrorBoundary>

                        <ErrorBoundary>
                            <TabPanel>
                                {(promoDepartamental && promoDepartamental?.length > 0)
                                    ? <div className="seccionTarjetaSears">
                                        <ul className="ulTarjetasSearsMobile">
                                            {promoDepartamental && promoDepartamental?.length > 0 &&
                                                promoDepartamental.map(((promo, i) =>
                                                    <>
                                                        {promo?.promotion &&
                                                            <li key={i} style={{ paddingBottom: "10px" }}>
                                                                {promo?.promotion?.name}
                                                                {promo?.promotion && promo?.promotion?.id === 0
                                                                    ?
                                                                        <ul className="intoPromo">
                                                                            {
                                                                                promo?.promotion?.best_plan?.map((plan) =>
                                                                                    <li style={{ paddingBottom: "10px" }}>
                                                                                        {plan?.months} mensualidades fijas de {plan?.monthly_payments}
                                                                                    </li>)
                                                                            }
                                                                        </ul>
                                                                    : null
                                                                }
                                                            </li>
                                                        }
                                                    </>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    : null
                                }
                            </TabPanel>
                        </ErrorBoundary>

                        <ErrorBoundary>
                            {(payment_methods?.find(item => item.id === 4468) && payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0)
                                ? <TabPanel>
                                    <div className="seccionTarjetaBancaria">
                                        <ul className="ulpagosMobile">
                                            {payment_methods?.find(item => item.id === 4468)?.promotion[0]?.monthly_payments &&
                                                payment_methods?.find(item => item.id === 4468)?.promotion[0]?.monthly_payments?.map(datos =>
                                                    <li>
                                                        {datos?.months} {datos?.months === 1 ? <>Mes de </> : <>Meses de </>}
                                                        <CurrencyFormat
                                                            decimalScale={2}
                                                            FixedDecimalScale={true}
                                                            value={datos?.price % 1 === 0 ? datos?.price + '.001' : datos?.price}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            renderText={value => <span>{value}*</span>}>
                                                        </CurrencyFormat>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </TabPanel>
                                : null
                            }
                        </ErrorBoundary>
                    </Tabs>
                }
            </ErrorBoundary>
        </div>
    )
}

export default function ModuleTabsDesktop({ ...props }) {
    const {
        id,
        description,
        attributes,
        payment_methods,
        promoDepart,
        formasPagoArray,        
        // , warrantyDetails 
    } = props;

    const [tamanoLista, setTamanoLista] = useState(false);
    const [promoDepartamental, setPromoDepartamentla] = useState(false);

    let atributesArray = attributes != null && Object.entries(attributes)?.map(([key, value]) => {
        return (
            <li key={key}>
                <span className="atributesKeyM">{key?.replace(/_/g, " ")}: </span>
                <span className="atributesValueM">{value}</span>
            </li>
        )
    })


    useEffect(() => {
        var elementos = document.getElementsByClassName("react-tabs__tab-list");
        var ele2 = elementos[0]?.childElementCount;

        if (ele2)
            setTamanoLista(Number(ele2));

    }, [])

    useEffect(() => {
        if (promoDepart && promoDepart?.length > 0) {
            setPromoDepartamentla(promoDepart)
        }else{
            setPromoDepartamentla(false)
        }
    }, [promoDepart])

    let CRev = []
    let MPCSears = []
    let MSISears = []
    const [CRevUS, setCRevUS] = useState()
    const [MPCSearsUS, setMPCSearsUS] = useState()
    const [MSISearsUS, setMSISearsUS] = useState()
    useEffect(()=>{
        if(promoDepartamental){
            promoDepartamental.forEach(cardMonts => {
                if(cardMonts?.promotion?.payment_method === 4375 ){
                    CRev.push(cardMonts)
                }else if(cardMonts?.promotion?.payment_method === 4384 ){
                    MPCSears.push(cardMonts)
                }else if(cardMonts?.promotion?.payment_method === 4385 ){
                    MSISears.push(cardMonts )
                }
            })
            setCRevUS(CRev)
            setMPCSearsUS(MPCSears)
            setMSISearsUS(MSISears)
        }
    },[promoDepartamental])


    const [formasPago, setFormasPago] = useState();
    useEffect(()=>{
            if(formasPagoArray){
                setFormasPago(formasPagoArray);
            }
    },[formasPagoArray])

    return (
        <ErrorBoundary>

            <Tabs forceRenderTabPanel={true}>
                <ErrorBoundary>
                    <TabList id={tamanoLista > 2 ? "alineacion" + tamanoLista : "alineacion2"}>
                        <Tab>Descripción</Tab>
                        {((payment_methods?.find(item => item.id === 4468) && payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0) ||
                            (promoDepartamental))
                            ? <Tab>Promociones</Tab>
                            : null
                        }
                        <Tab>Formas de Pago</Tab>
                    </TabList>
                </ErrorBoundary>

                <ErrorBoundary>
                    <TabPanel>
                        <>
                            <ErrorBoundary>
                                {description?.length >= 1
                                    ? <div className="moduleDescriptionSears">
                                        <div dangerouslySetInnerHTML={{ __html: description }} className="textDescriptionSears"></div>
                                        <div id="flix-inpage"></div>
                                    </div>
                                    : null
                                }
                            </ErrorBoundary>

                            <ErrorBoundary>
                                {attributes && Object.keys(attributes)?.length >= 1
                                    ? <div className="moduleEspecificacionSears">
                                        <div className="modulePartEspecificacionSears">
                                            <p className="h3">Especificaciones</p>
                                        </div>
                                        <div className="textEspecificacionSears">
                                            <ul className="listAttributesSears">
                                                {attributes &&
                                                    Object.entries(attributes)?.map(([key, value], ind) =>
                                                        <li key={ind}>
                                                            <span className="atributesKeySears">{key?.replace(/_/g, " ")}</span>
                                                            <span className="atributesValueSears">{value}</span>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    : null
                                }
                            </ErrorBoundary>
                        </>
                    </TabPanel>
                </ErrorBoundary>

                <ErrorBoundary>
                    {((payment_methods?.find(item => item.id === 4468) && payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0) ||
                        (promoDepartamental))
                        ? <TabPanel>
                            <div className="pagosprincipal">
                                <ErrorBoundary>
                                    {(promoDepartamental && promoDepartamental?.length > 0)
                                        ? <div className="unoPagos">
                                            <h3>Tarjetas Sears</h3>

                                            <ul className="ulTarjetasSears">
                                                {CRevUS?.length >= 1 &&
                                                    <li>Crédito Revolvente Sears
                                                        <ul className="intoPromo">
                                                            {CRevUS?.map(((promo, i) =>
                                                                    <li key={i}> {promo?.promotion?.name} </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </li>
                                                }
                                                {MSISearsUS?.length >= 1 &&
                                                    <li>Meses sin intereses Sears
                                                        <ul className="intoPromo">
                                                            {MSISearsUS?.map(((promo, i) =>
                                                                    <li key={i}> {promo?.promotion?.name} </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </li>
                                                }
                                                {MPCSearsUS?.length >= 1 &&
                                                    <li>Mejor plan de crédito Sears
                                                        <ul className="intoPromo">
                                                            {MPCSearsUS[0]?.promotion?.best_plan?.map((plan,i) =>
                                                                <li key={i}>
                                                                    {plan?.months} mensualidades fijas de {plan?.monthly_payments?.toFixed(2)}
                                                                </li>)
                                                            }
                                                        </ul>
                                                    </li>
                                                }


                                            </ul>
                                        </div>
                                        : null
                                    }
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    {(payment_methods?.find(item => item.id === 4468) && payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0)
                                        ? <div className="dosPagos">
                                            <h3>Otras tarjetas</h3>
                                            <ul className="uldospagos">
                                                {payment_methods?.find(item => item.id === 4468)?.promotion[0]?.monthly_payments &&
                                                    payment_methods?.find(item => item.id === 4468)?.promotion[0]?.monthly_payments?.map((datos, ind) =>
                                                        <li key={ind}>
                                                            {datos.months} {datos.months === 1 ? <>Mes de </> : <>Meses de </>}
                                                            <CurrencyFormat
                                                                decimalScale={2}
                                                                FixedDecimalScale={true}
                                                                value={datos.price % 1 === 0 ? datos.price + '.001' : datos.price}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                prefix={'$'}
                                                                renderText={value => <span>{value}*</span>}>
                                                            </CurrencyFormat>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        : <div className="dosPagos"></div>
                                    }
                                </ErrorBoundary>
                            </div>
                        </TabPanel>
                        : null
                    }
                </ErrorBoundary>

                <ErrorBoundary>
                    <TabPanel>
                        <div className="moduleFormaPagoSears">
                            <p>Comprar en Sears.com.mx es muy fácil con nuestras múltiples formas de pago. <span className="">¡Elige la que más te convenga!</span></p>
                            <div className="moduloUl">
                                <ul className="ulFp1">
                                    {formasPago && formasPago.map((forma, id) =>
                                        (forma.id < 3) && <li key={forma.id} className="liFp">
                                            <div className="bxImg"><img src={forma.url_logo} alt={forma.name} width="60" height="60"></img></div>
                                            <div className="bxName">{forma.name}</div>
                                        </li>
                                    )}
                                </ul>

                                <ul className="ulFp2">
                                    {formasPago && formasPago.map((forma, id) =>
                                        (forma.id > 2 && forma.id <6) && <li key={forma.id} className="liFp">
                                            <div className="bxImg"><img src={forma.url_logo} alt={forma.name} width="60" height="60"></img></div>
                                            <div className="bxName">{forma.name}</div>
                                        </li>
                                    )}
                                </ul>

                                <ul className="ulFp2">
                                    {formasPago && formasPago.map((forma, id) =>
                                        (forma.id > 5) && <li key={forma.id} className="liFp">
                                            <div className="bxImg"><img src={forma.url_logo} alt={forma.name} width="60" height="60"></img></div>
                                            <div className="bxName">{forma.name}</div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </TabPanel>
                </ErrorBoundary>

                {/* {warrantyDetails === null || !warrantyDetails ? null
                    :
                    <TabPanel>
                        <div className="moduleWarrantySears">
                            {warrantyDetails && warrantyDetails.length >= 1
                                ? <div className="warrantyRSears">
                                    <p className="titulo"></p>
                                    <ul className="opciones">
                                        {warrantyDetails[0].manufacturer &&
                                            <li>Garantía con Fabricante: <span>{warrantyDetails[0].manufacturer.time}</span></li>
                                        }
                                        {warrantyDetails[0].seller &&
                                            <li>Garantía con Vendedor: <span>{warrantyDetails[0].seller.time}</span></li>
                                        }
                                    </ul>
                                </div>
                                : null
                            }

                            <div className="condicionesSears">
                                <p className="titulo">Condiciones:</p>

                                <ul className="ulCondiciones">
                                    <li>El producto no debe presentar golpes o alteraciones de ningún tipo.</li>
                                    <li>El producto debe ser devuelto en el mismo estado en que lo recibiste.</li>
                                    <li>Empaca tu devolución de forma segura.</li>
                                </ul>

                                <div className="pEnlaceDevoluciones">*Conoce más acerca de las <Link to="/politica-devolucion" className="enlaceDevoluciones">Políticas de Garantía</Link></div>
                            </div>

                        </div>
                    </TabPanel>
                } */}
            </Tabs >
        </ErrorBoundary>
    )
}