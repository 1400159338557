import React, { useState, useEffect } from 'react';
import { getPedido, getTipoRetorno, postCreateTiket } from 'services/AyudaDinamicaServices';
import FooterPortal from "pages/Footer/FooterDesktop";
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useToken } from 'hooks/useToken';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import { NO_IMAGE } from 'constants/general';
import { removeDuplicates } from 'functions/removeDuplicates';
import ModalPortal from 'components/Modal';
import { ModalError } from '../vista4-modal';
import { removeStorage } from 'functions/storageBase64';
import { repareTextHtml } from 'functions/repareDataService';

export function ComoPrefieresDevolverDesktop() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nombreProducto, setNombreProducto] = useState(false);
    const [opciones, setOpciones] = useState(false);
    const [listOpciones, setListOpciones] = useState(false);

    const [StorageOpcionDevolver, setStorageOpcionDevolver] = useStorage64('storageDevolverOpcion', '');
    const [peso_volumetrico, setPeso_volumetrico] = useState(false);
    // crear ticket
    const [showModalError, SetShowModalError] = useState(false); //mostrar modal error
    const [crearTicket, setCrearTicket] = useState(false);
    const [remover, setRemover] = useState(false); // activa loading cuando se comienza a crear el ticket
    const [infoTicketEntregado] = useStorage64("infoTicketEntregado");
    const [storageActionId] = useStorage64("storageActionId");
    const [storageMotivoEntregado] = useStorage64("storageMotivoEntregado");
    const [storageImgProducto] = useStorage64("storageImgProducto");
    const [storageDatosBancarios] = useStorage64("storageDatosBancarios");
    const [recoleccionDomicilio, setRecoleccionDomicilio] = useState("storageRecoleccionDomicilio", "");
    const [correoUsuario] = useStorage64('storageCorreoUsuario');
    const [storageReasonId] = useStorage64('storageIdProblem');
    const [shopId, setShopId] = useState(false);
    const [idTiendaFisica, setIdTiendaFisica] = useState(false);
    const [tipoGuia, setTipoGuia] = useState(false);
    const [idTicketStorage, setIdTicketStorage] = useStorage64('storageIdTicket');

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((opciones && opciones.TipoRetorno) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, opciones])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido);
    }, [])

    //------------------ INICIO PARA REFRESCAR TOKEN ---------------------------//
    const refreshCyR = useToken({ firstActive: false, name: "vista37- como prefieres devolver - desktop" });
    const [tokenJWT, setTokenJWT] = useState(false);
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        if (tokenJWT) {
            refreshCyR.forceToken(true);
            setTokenJWT(false);
        }
    }, [tokenJWT])

    useEffect(() => {
        if (refreshCyR.newToken) {
            setContinuar(true);
        }
    }, [refreshCyR.newToken])
    //------------------ FIN PARA REFRESCAR TOKEN ------------------------------//

    useEffect(() => {
        let imagenProducto = [];
        let productoNombre = [];
        let pesovolumetrico = [];
        let shopProducto = [];
        let arrayGuia = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido && resultadoPedido.status === 200) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0]?.url, "id": elem.related_product_id })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoNombre.push({ "nombre": elem.name })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && pesovolumetrico.push({ "peso_volumetrico": elem.dynamic_help?.return_by_volumetric_weight })
                        )
                        // guarda informacion sobre el vendedor del producto
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && shopProducto.push({ "idTienda": elem.shop?.idTienda, "nameTienda": elem.shop?.name, "public_shop_id": elem.shop?.public_shop_id })
                        )
                        // guarda informacion sobre el tipo de guia
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && arrayGuia.push({ "tipoGuia": elem.tracking?.guide_type})
                        )

                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        setNombreProducto(productoNombre);

                        setPeso_volumetrico(pesovolumetrico[0].peso_volumetrico);
                        setShopId(shopProducto[0].idTienda);
                        setIdTiendaFisica(shopProducto[0].public_shop_id);
                        setTipoGuia(arrayGuia[0].tipoGuia);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getPedido(idPedido)
                        .then(setResultadoPedido);
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // obtengo list tipo de retorno
    useEffect(() => {
        getTipoRetorno()
            .then(setOpciones);
    }, [])

    // almaceno listado de action
    useEffect(() => {
        let arrayOpciones = [];

        if (opciones) {
            if (opciones.TipoRetorno && opciones.status === 200) {
                opciones.TipoRetorno.map((act, i) =>
                    arrayOpciones.push({ "id": act.id, "name": act.name, "order": act.order })
                )
                console.log("opciones",opciones);
                console.log("arrayOpciones",arrayOpciones)
                setListOpciones(arrayOpciones);
            }
            else if (opciones.statusCode && opciones.statusCode === 401) {
                setTokenJWT(true);

                setTimeout(() => {
                    getTipoRetorno()
                        .then(setOpciones);
                }, 1000);
            }
        }
    }, [opciones])


    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    // cerrar modal, regresa 1 en el historial
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a elegir tienda o genera el ticket
    const handleContinuarAseleccion = (id) => {
        console.log("id seleccionado:",id);
        setStorageOpcionDevolver(id);

        if (Number(id) === 1) {
            setRecoleccionDomicilio(1);
            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/elegir-tienda");
        }
        else if (Number(id) === 2) {
            if (peso_volumetrico === true) {
                setRecoleccionDomicilio(2);

                let client_id = infoTicketEntregado?.client_id;
                let order_id = infoTicketEntregado?.order_id;
                let order_number = infoTicketEntregado?.order_number;
                let ticket_type_id = 3;
                let action_id = storageActionId;
                let reason_id = infoTicketEntregado?.reason_id;
                let description = storageMotivoEntregado;
                let refound_details;

                if (storageDatosBancarios) {
                    refound_details = storageDatosBancarios;
                } else {
                    refound_details = [];
                }

                let products_to_cancel = [{ "id": infoTicketEntregado.products_to_cancel[0].id, "return_type": id, "subsidiary_id": idTiendaFisica }];
                let additional_details = storageImgProducto;
                let correo = correoUsuario;

                setRemover(true); // activa loading
                
                console.log("lo que se envia al seleccionar:",{
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                });
                console.log("storageMotivoEntregado", storageMotivoEntregado)
                console.log("infoTicketEntregado", infoTicketEntregado);
                console.log("recoleccionDomicilio", recoleccionDomicilio);

                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id,
                    correo
                })
                    .then(setCrearTicket);
            }
        }
    }

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {

                    if (idProductoEstate !== 0) {
                        setIdTicketStorage(crearTicket.data.id);
                        // es reason 9 y action: 8 ó 9
                        // es reason 9 y action 1, 2, 3, 4
                        if ((storageReasonId === 9 && storageActionId === 8) || (storageReasonId === 9 && storageActionId === 9) ||
                            (storageReasonId === 9 && (storageActionId === 1 || storageActionId === 2 || storageActionId === 3 || storageActionId === 4))) {
                            //es guia automatica
                            if (tipoGuia === 2) {
                                history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/typ");
                            }
                            //no es guia automatica
                            else {
                                history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/seller-typ");
                            }
                        }
                    }
                    else {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }
    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">

                    <div className="containerAD">
                        <div className='menuMiCuentaAD'>
                            <MenuMiCuenta />
                        </div>

                        <div className='contenidoGeneralAD'>
                            <div className="boxBtnRegresarDktop">
                                <div className="icoBack" onClick={onClose}></div>
                                <button className="btnBackAD" onClick={onClose}>¿Cómo prefieres devolver tu producto?</button>
                            </div>

                            <div className="boxGeneralAD">
                                <div className="boxLeftAD">
                                    <div className="contSectionRed">
                                        <div className="contSectionRed">
                                            <div className={ima?.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                                {ima?.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url ? elemento.url : NO_IMAGE} alt={"imagen" + i} width={70} />)}
                                            </div>

                                            <div className="textDektop"> <p>{nombreProducto && repareTextHtml(nombreProducto[0].nombre)}</p> </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="boxRightAD">
                                    <div className="boxUlRazonesAD">
                                        <ul className="">
                                            {listOpciones && listOpciones.map((problem) =>
                                                <li id={problem.id} key={problem.id} onClick={() => handleContinuarAseleccion(problem.id)}>
                                                    <span className="nombreRazon">{problem.name}</span>
                                                    <span className="iconoRazon"></span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {showModalError &&
                    <ModalPortal type="modalSB" onClose={handleCloseError} >
                        <ModalError okRegresar={regresarPedidos} />
                    </ModalPortal>
                }
            </main>

            <FooterPortal />
        </>
    )
}