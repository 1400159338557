import React from 'react'
import './style.sass'
import { Link } from "react-router-dom"
import Slider from "react-slick";
import { repareImage } from 'functions/repareDataService'

export function TiendasSliderMovilNew(props) {
    const {
        tiendas,
        styleView
    } = props

    return (
        <div className="contTiendasOficiales tiendasMobile">
            <div className="container">
                <div className="slideTiendas">
                    {
                        tiendas.map(({ external_id, name, logo }) =>
                            <div className="card" key={external_id}>
                                <img loading="lazy" src={repareImage(logo)} alt={name} height="65" />
                                <div className="dataTienda">
                                    <p>{name}</p>
                                    <Link to={`/tienda/${external_id}/${name.replace(/ /ig, '-')}/pagina=1`} className="btn rojo">Visitar tienda</Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}


export function TiendasSliderMovil({ tiendas }) {
    return (
        <div className="contTiendasOficiales tiendasMobile">
            <div className="container">
                <div className="slideTiendas">
                    {
                        tiendas.map(({ id, nombre, image }) =>
                            <div className="card" key={id}>
                                <img loading="lazy" src={repareImage(image)} alt={nombre} height="65" />
                                <div className="dataTienda">
                                    <p>{nombre}</p>
                                    <Link to={`/tienda/${id}/${nombre.replace(/ /ig, '-')}/pagina=1`} className="btn rojo">Visitar tienda</Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}


export function TiendasSlider({ tiendas }) {
    const settings = {
        infinite: true,
        arrows: true,
        dots: false,
        speed: 200,
        // swipe: true,
        // touchMove: true,
        // centerPadding: "60px",
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        // accessibility: false,
        centerMode: false,
        variableWidth: true
        // responsive: [
        //     {
        //         breakpoint: 1400,
        //         settings: {
        //             slidesToShow: 4,
        //             slidesToScroll: 1,
        //         }
        //     },
        //     {
        //         breakpoint: 1200,
        //         settings: {
        //             slidesToShow: 4,
        //             slidesToScroll: 1,
        //         }
        //     },
        //     {
        //         breakpoint: 900,
        //         settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 1,
        //         }
        //     },
        //     {
        //         breakpoint: 650,
        //         settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 1,
        //         }
        //     }
        // ]
    };


    return (
        <div className="contTiendasOficiales">
            <div className="container">
                <div className="slideTiendas">
                    <Slider {...settings} id="sliderTiendas">
                        {tiendas.map(({ id, nombre, image }) =>
                            <div className="card" key={id}>
                                <img loading="lazy" src={repareImage(image)} alt={nombre} height="65" />
                                <div className="dataTienda">
                                    <p>{nombre}</p>
                                    <Link to={`/tienda/${id}/${nombre.replace(/ /ig, '-')}/pagina=1`} className="btn rojo">Visitar tienda</Link>
                                </div>
                            </div>
                        )
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}


export function TiendasSliderNew(props) {
    const {
        tiendas,
        styleView
    } = props
    const settings = {
        infinite: true,
        arrows: true,
        dots: false,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
        variableWidth: true
    };


    return (
        <div className="contTiendasOficiales">
            <div className="container">
                <div className="slideTiendas">
                    <Slider {...settings} id="sliderTiendas">
                        {tiendas.map(({ external_id, name, logo }) =>
                            <div className="card" key={external_id}>
                                <img loading="lazy" src={repareImage(logo)} alt={name} height="65" />
                                <div className="dataTienda">
                                    <p>{name}</p>
                                    <Link to={`/tienda/${external_id}/${name.replace(/ /ig, '-')}/pagina=1`} className="btn rojo">Visitar tienda</Link>
                                </div>
                            </div>
                        )
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}