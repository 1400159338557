import React from 'react'
import ReactDOM from 'react-dom'
import { GTM_CODE } from 'constants/general'
import { ClearCacheProvider } from 'react-clear-cache'
import './index.sass'
import App from './App'
//import * as serviceWorker from './serviceWorker'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: GTM_CODE,
  dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  
  <ClearCacheProvider filename="build.json" duration={3600000}>
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </ClearCacheProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
//serviceWorker.register();
