import {BASE_PATH_API} from 'constants/general';
import axios from 'axios';

export default async function getLocalizadorTiendas() {
    const apiURL = BASE_PATH_API + '/sellers/v1/subsidiaries';

    const config = {
        method: 'get',
        url: apiURL
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        let errorMsg = "Network Error";
        if(error.request.status !== 0){
            errorMsg = error.response.data.msg;
        }
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': errorMsg
        }
    })
}