import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Header from 'pages/Header/HeaderSimple'
import './styles.sass'
import { PORTAL_URL } from 'constants/general'

export default function AcercaSears() {
    const history = useHistory()

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Acerca Sears Section');
        window.scrollTo(0, 0)
    }, [])

    const backHistory = () => {
        // history.go(-1)
        window.location.href = "/menu-mas"
    }

    return (
        <>
            <Header pageClass="hidden" />
            <div className="sectionTitleServicioCliente">
                <button onClick={backHistory}>Acerca de Sears</button>
            </div>

            <div className="opcionesCliente">
                <ul className="listaExterna">
                    <Link to="/acerca-nosotros" className="enlaces">
                        <li className="telefono"><span className="nombre">Acerca de Nosotros</span> <span className="icono"></span></li>
                    </Link>

                    <li className="verified">
                        <span className="nombre">Términos y Condiciones</span>
                    </li>
                    <ul className="listaInterna">
                        <Link to={"/terminos-condiciones"} className="enlaces">
                            <li><span className="nombreI">Términos y condiciones Tienda virtual</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/terminos-condiciones-market-place"} className="enlaces">
                            <li><span className="nombreI">Términos y Condiciones Market Place</span><span className="icono"></span></li>
                        </Link>
                    </ul>

                    <li className="balance"><span className="nombre">Aviso de Privacidad</span> </li>
                    <ul className="listaInterna">
                        <Link to={"/aviso-privacidad"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad Clientes</span><span className="icono"></span></li>
                        </Link>

                        <Link to={"/aviso-privacidad-prospectos"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad Prospectos</span><span className="icono"></span></li>
                        </Link>

                        <Link to={"/aviso-privacidad-auto-center"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad AutoCenter</span><span className="icono"></span></li>
                        </Link>

                        <Link to={"/tarjeta-magica"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad Tarjeta Mágica SEARS</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/aviso-privacidad-mesa-de-regalos"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad Mesa de Regalos</span><span className="icono"></span></li>
                        </Link>
                    </ul>
                </ul>
            </div>
        </>
    )
}