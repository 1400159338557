import React, { useState, useEffect } from 'react'
import Loading from 'components/Loading'
// import './style.sass'
import MiTelmex from 'pages/MiCuenta/MiTelmex/MiTelmexPage'
import { postListaBlanca } from 'services/MiTelmexServices'
import { useStorage64 } from 'hooks/useStorageBase64'
import ModalBlackList from 'components/Modal/modalBlackList'
import LoginMiTelmex from 'pages/MiCuenta/MiTelmex/loginTelmexPage'
import QuestionsMiTelmexPage from 'pages/MiCuenta/MiTelmex/questionsMiTelmex'
import Header from 'pages/Header/HeaderNavegacion'
import BarraCP from 'components/BarCP/BarHeaderCP'
import Footer from 'pages/Footer/FooterDesktop'
import { useHistory } from 'react-router-dom'

export default function MiTelmexComponent(props) {

    const [telefono] = useStorage64('telefono_usuario', '') //obtiene el telefono desde el storage
    const [successListaBlanca, getSuccessListaBlanca] = useState(false)
    const [loading, setLoading] = useState(true)
    const [info, setInfo] = useState([])
    const [infoError, setInfoError] = useState([])
    const [renderizar, setrenderizar] = useState(false)
    const [questions, setQuestions] = useState(false)
    const [modalToggle, setModalToggle] = useState(false) // mostrar modal
    const history = useHistory()

    const preguntas = [
        { "question": "Tiene venta financiada vigente", "question_number": 6 },
        { "question": "El monto de su ultimo pago fue superior a 200", "question_number": 4 },
        { "question": "Ha realizado alguna vez cambio de domicilio ", "question_number": 12 },
        { "question": "Vive ud en el número 0", "question_number": 2 },
        { "question": "La fecha de su último pago fue en el mes 10", "question_number": 5 }
    ]

    // envia la peticion al servicio lista blanca
    useEffect(() => {
        const env = [{ phone: telefono }]
        postListaBlanca({ raw: env })
            .then(getSuccessListaBlanca)
    }, [])

    // si no ha cargado la informacion muestra loading
    useEffect(() => {
        if(successListaBlanca){
            if (Object.keys(successListaBlanca).length > 0) {
                setLoading(false)
            }
            else {
                setLoading(true)
            }
        }else {
            setLoading(true)
        }
    }, [successListaBlanca])

    // recibe la respuesta del servicio de lista blanca
    const informacion = []
    const infoErrorArray = []
    useEffect(() => {
        if (successListaBlanca) {
            [successListaBlanca].map((ar, i) => {
                var lis = Object.entries(ar)
                lis.map((item, j) => {
                    return (item[0] === "data"
                        ? informacion.push(item[1][0])
                        : null
                    )
                })
            })
            setInfo(informacion[0])
        }

        if (successListaBlanca) {
            [successListaBlanca].map((ar, i) => {
                var lis = Object.entries(ar)
                lis.map((item, j) => {
                    // console.log(" item: ", item[0])
                    return (item[0] === "metadata"
                        ? infoErrorArray.push(item[1]) // console.log("interno item: ", item[1])//
                        : null
                    )
                })
            })
            setInfoError(infoErrorArray[0])
        }
    }, [successListaBlanca])

    useEffect(() => {
        if (info != undefined && info != '') {

            if (info.telmex_months != null && info.credit != null && info.credit.balance != 0 && info.credit.limit != 0) { // si me lleva a ver balance
                setrenderizar(true)
            }

            // console.log("questions: ", info.questions)
            if (info.questions && info.questions != null) {
                setQuestions(true)
                // console.log("trae preguntas")
            }
        }
        if (info === undefined) {
            history.push("/loginMiTelmex")
        }
        if (!info) {
            history.push("/loginMiTelmex")
        }

        if (infoError.is_error === true && infoError.http_status !== 200 && infoError.http_status !== 201) {
            // console.log(" es un error: ", infoError.message)
            setModalToggle(true)
        }

    }, [info])

    /** funcion para cerrar modal */
    const closeModal = () => {
        setModalToggle(false)
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState()
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val)
    }
    const [upCP, setUpCP] = useState()
    useEffect(()=>{
        if(upCP){
            //console.log('')
        }
    },[upCP,updateCPBar])

    return (
        <>

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar}/>
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP"/>	
            </section>

            {/* muestra loading */}
            {loading && <Loading type="telmexLoading" />}
            {/* muestra login mi telmex */}
            {/* {renderizar === false ? <LoginMiTelmex /> : null} */}
            {/* muesta pagina balance telmex */}
            {renderizar === true ? <MiTelmex telefono={info.phone} credito={info.credit} meses={info.telmex_months} /> : null}
            {/* muestra pagina questions */}
            {questions === true ? <QuestionsMiTelmexPage preguntas={preguntas} /> : null}
            {/* muestra el modal */}
            {modalToggle ? <ModalBlackList openModal={modalToggle} cerrarModal={closeModal} mensaje={infoError.message} /> : null}

            <Footer />
        </>
    )
}