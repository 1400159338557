import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ErrorType from 'pages/Error/ErrorType';
import { repareImage, repareTitleSEO } from 'functions/repareDataService'
import { encodeStorage, removeStorage } from 'functions/storageBase64';
import { useStorage64 } from 'hooks/useStorageBase64';

function CardStoreSimple(props) {
    const {
        id,
        name,
        logo,
        corner
    } = props

    let imageUrl = repareImage(logo)
    let titleSeo = repareTitleSEO(name);
    let title = encodeURI(titleSeo)

    const storeSelect = (name) => {
        encodeStorage("storeSelec", name)
    }


    return (
        <article className="cardStoreSimple">
            {corner
                ? <Link to={`/corner/${id}/${title}/pagina=1`} title={name} onClick={() => storeSelect(name)}>
                    <img src={imageUrl} alt={name} loading="lazy" />
                </Link>
                : <Link to={`/tienda/${id}/${title}/pagina=1`} title={name} onClick={() => storeSelect(name)}>
                    <img src={imageUrl} alt={name} loading="lazy" />
                </Link>
            }
        </article>
    )
}

export function TiendasOficialesTemplateDesktop(props) {

    const {
        responseSuccess,
        statusCode,
        errors
    } = props

    const [typeSearch, setTypeSearch] = useState('');
    const searchStore = useRef();
    const [bandera, setBandera] = useState(false);
    let respuesta = [];
    const [store] = useStorage64('storeSelec', '');

    // funcion llamada en el boton buscar del input
    const buscar = (e) => {
        if (String(e.target.value) === "") {
            setTypeSearch('')
        }
        if (String(e.target.value) !== "") {
            setTypeSearch(e.target.value)
        }
    }

    useEffect(() => {
        if (typeSearch.length > 0) {
            setBandera(true)
        } else {
            setBandera(false)
        }
    }, [typeSearch])

    const borrar = (e) => {
        if (Number(e.keyCode) === 8) {
            if (typeSearch.length === 1)
                setTypeSearch("")
            if (typeSearch.length === 0)
                setTypeSearch("")
        }
    }

    const handleFocusSearcherStore = () => {
        searchStore.current.focus();
    }

    useEffect(() => {
        if (store && store.length > 0) {
            setTypeSearch(store)
            removeStorage("storeSelec")
        }
    }, [])

    return (
        <>
            <main>
                <section>
                    <div className="tituloTiendas">
                        <div className="container">
                            <h1>Tiendas Oficiales</h1>
                        </div>
                    </div>
                    {statusCode === 200
                        ?
                        <div className="container">
                            <div className="searchTienda">
                                <input id="inputTienda" name="strBusquedaPedido" type="search" placeholder="Buscar una tienda"
                                    value={typeSearch}
                                    // defaultValue={typeSearch}
                                    ref={searchStore}
                                    onChange={(text1) => buscar(text1)}
                                    onKeyDown={(e) => borrar(e)}
                                    onFocus={handleFocusSearcherStore}
                                />
                                <button id="btnBuscarTienda" className="bt__search" onClick={handleFocusSearcherStore} ></button>
                            </div>

                            <nav className="navigateLetterStore">
                                {responseSuccess &&
                                    <>
                                        {Object.entries(responseSuccess).map(([tiendas, arregloTiendas], ind) =>
                                            <div key={ind} className="contTotalStore">
                                                {(typeSearch == null || typeSearch == '') && <a href={`/tiendas#label_${tiendas}`} >{tiendas}</a>}
                                            </div>
                                        )}
                                    </>
                                }
                            </nav>

                            <div className="contendidoTiendas">
                                {responseSuccess &&
                                    <>
                                        {Object.entries(responseSuccess).map(([tiendas, arregloTiendas], ind) =>
                                            <div key={ind} className="contTotalStore">
                                                {(typeSearch == null || typeSearch == '') && <p className="h3" >{tiendas} <span id={`label_${tiendas}`} className="tagLabelNavigate"></span></p>}
                                                <div className="contStore">

                                                    {arregloTiendas.filter((data) => {
                                                        if (typeSearch == null || String(typeSearch) === '') {
                                                            return data
                                                        }
                                                        if ((String(data.name).toLowerCase()).includes(String(typeSearch).toLowerCase())) {
                                                            respuesta.push(data)
                                                            return data
                                                        }
                                                    }).map(({ id, name, logo, status, corner }) =>
                                                        status &&
                                                        <CardStoreSimple
                                                            key={id}
                                                            id={id}
                                                            name={name}
                                                            logo={logo}
                                                            corner={corner}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {bandera === true && respuesta.length === 0 && <p className="textNoEncontrado">No encontramos tiendas con ese nombre. </p>}
                                    </>
                                }
                            </div>
                        </div>
                        : null
                    }
                    <ErrorType
                        codeStatus={errors.error}
                        errorText={errors.response}
                    />
                </section>
            </main>
        </>

    )
}