import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';


export function PoliticaEnvioDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Envio Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Política de Envío</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">

                                <ul className="ulQuestionDisc">
                                    <li>Nuestro servicio de entrega sólo está disponible en la República Mexicana.</li>
                                    <li>Sears subcontrata a las mejores empresas especializadas en mensajería para llevar a cabo la entrega.</li>
                                    <li>Es importante especificar si tu compra es un regalo, para no enviar la envoltura y no agregar los tickets al paquete.</li>
                                    <li>Revisa que todos tus datos están correctos y completos ya que de esto depende el éxito de tu entrega.</li>
                                    <li>El tiempo de entrega inicia a partir de la aplicación del cobro, lo cual te será notificado por correo electrónico en un periodo aproximado de 48 a 72 horas a partir de la compra.</li>

                                    <li>Los tiempos de entrega varían de acuerdo a la mensajería seleccionada en función del destino y ocurren en cualquier horario del día.</li>
                                    <li>Con el fin de ofrecerte mayor seguridad en tu entrega, toda nuestra mercancía viaja asegurada.</li>
                                    <li>Todos tus productos adquiridos serán empacados sin cargo. Adicionalmente, para mandar tus regalos empacaremos con la imagen institucional de Sears (excepto para productos de gran volumen como línea blanca, muebles, pantallas, etc).</li>
                                    <li>Como complemento de tus regalos, podrás colocar un mensaje de felicitación que se imprime en una tarjeta colocándola en la envoltura del regalo, sin costo.</li>
                                    <li>Al recibir su paquete, le recomendamos revisar que no presente ningún defecto o alteración, de ser así le pedimos por favor no recibirlo, anotar los comentarios en la boleta de la mensajería y reportarlo de inmediato al área de Atención a Clientes vía telefónica en el D.F. y área metropolitana al <a href="tel:015553451606">(01-55) 53 45 16 06</a> y desde el interior de la República al <a href="tel:018008368246">(01 800) 836 82 46</a>, si prefieres vía correo electrónico a <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a>, de lo contrario Sears no se hará responsable por los daños que puedan presentar los productos.</li>

                                    <li>Para reportes de alguna de las mensajerías contratadas por Sears, por favor llámanos a nuestro Centro de Atención a Clientes al <a href="tel:015553451606">(01-55) 53 45 16 06</a> o desde el interior de la República al <a href="tel:018008368246">(01 800) 836 82 46</a> en cuanto recibas el paquete para iniciar la aclaración.</li>
                                    <li>Al momento de la entrega en tu domicilio, le será solicitada a la persona que reciba la mercancía una identificación oficial (credencial de elector, pasaporte, forma migratoria FM2 o licencia de conducir), por lo que te pedimos tener este documento a la mano.</li>
                                    <li>Si compraste algún producto de gran volumen (línea blanca, pantallas, muebles) y tu entrega es en el Valle de México, no se cobrará el envío ya que aplica como entrega realizada con Envíos Sears. En estos casos, la entrega se realizará en función de la programación disponible y la accesibilidad del destino.</li>
                                    <li>Ningún personal del equipo de envíos de Sears ni de las mensajerías contratadas está autorizado a instalar equipos, retirar puertas, ni volar mercancía.</li>
                                    <li>La instalación de plomería, electricidad y volado no están incluidos en el servicio de entrega, por lo que en ciertos domicilios puede aplicar un costo adicional, que te cotizaremos post-venta.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function PoliticaEnvioMobile() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Envio Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Política de envío</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <ul className="ulDeclaracionesDisc">
                                    <li>Nuestro servicio de entrega sólo está disponible en la República Mexicana.</li>
                                    <li>Sears subcontrata a las mejores empresas especializadas en mensajería para llevar a cabo la entrega.</li>
                                    <li>Es importante especificar si tu compra es un regalo, para no enviar la envoltura y no agregar los tickets al paquete.</li>
                                    <li>Revisa que todos tus datos están correctos y completos ya que de esto depende el éxito de tu entrega.</li>
                                    <li>El tiempo de entrega inicia a partir de la aplicación del cobro, lo cual te será notificado por correo electrónico en un periodo aproximado de 48 a 72 horas a partir de la compra.</li>

                                    <li>Los tiempos de entrega varían de acuerdo a la mensajería seleccionada en función del destino y ocurren en cualquier horario del día.</li>
                                    <li>Con el fin de ofrecerte mayor seguridad en tu entrega, toda nuestra mercancía viaja asegurada.</li>
                                    <li>Todos tus productos adquiridos serán empacados sin cargo. Adicionalmente, para mandar tus regalos empacaremos con la imagen institucional de Sears (excepto para productos de gran volumen como línea blanca, muebles, pantallas, etc).</li>
                                    <li>Como complemento de tus regalos, podrás colocar un mensaje de felicitación que se imprime en una tarjeta colocándola en la envoltura del regalo, sin costo.</li>
                                    <li>Al recibir su paquete, le recomendamos revisar que no presente ningún defecto o alteración, de ser así le pedimos por favor no recibirlo, anotar los comentarios en la boleta de la mensajería y reportarlo de inmediato al área de Atención a Clientes vía telefónica en el D.F. y área metropolitana al (01-55) 53 45 16 06 y desde el interior de la Republica al <a href="tel:018008368246">(01 800) 836 82 46</a>, si prefieres vía correo electrónico a <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a>, de lo contrario Sears no se hará responsable por los daños que puedan presentar los productos.</li>

                                    <li>Para reportes de alguna de las mensajerías contratadas por Sears, por favor llámanos a nuestro Centro de Atención a Clientes al <a href="tel:015553451606">(01-55) 53 45 16 06</a> o desde el interior de la Republica al <a href="tel:018008368246">(01 800) 836 82 46</a> en cuanto recibas el paquete para iniciar la aclaración.</li>
                                    <li>Al momento de la entrega en tu domicilio, le será solicitada a la persona que reciba la mercancía una identificación oficial (credencial de elector, pasaporte, forma migratoria FM2 o licencia de conducir), por lo que te pedimos tener este documento a la mano.</li>
                                    <li>Si compraste algún producto de gran volumen (línea blanca, pantallas, muebles) y tu entrega es en el Valle de México, no se cobrará el envío ya que aplica como entrega realizada con Envíos Sears. En estos casos, la entrega se realizará en función de la programación disponible y la accesibilidad del destino.</li>
                                    <li>Ningún personal del equipo de envíos de Sears ni de las mensajerías contratadas está autorizado a instalar equipos, retirar puertas, ni volar mercancía.</li>
                                    <li>La instalación de plomería, electricidad y volado no están incluidos en el servicio de entrega, por lo que en ciertos domicilios puede aplicar un costo adicional, que te cotizaremos post-venta.</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export function PoliticaEnvio() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Envio');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <PoliticaEnvioDesktop />

                : isMobile === 'movile'
                    ? null
                    : <PoliticaEnvioMobile />
            }
        </>
    )
}