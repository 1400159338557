import React from 'react'

export function HomeSkeletonSliderP(props) {
    const {
        position
    } = props

	return(
		<>
            <section className={`sectionIcos homePosition${position}`}>
                <div className="contSlider sliderPrincipal">
                    <div className="container contSlideP skeleton">
                        <div className="img bones" width="655" height="368"/>
                    </div>
                </div>
            </section>
		</>
	);
}

export default React.memo(HomeSkeletonSliderP)