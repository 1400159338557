import React from 'react'
import './style.sass'
import {Link} from 'react-router-dom'

export default function ErrorConnectionServices ({...props}) {
    const {
        errorCode
    } = props
    return(
        <div className="errorConnectionServices">
            <img src="/img/conexionError.svg" alt="Error de conexión" width="450" height="450" loading="lazy"/>
            <p className="titleError">Error de conexión</p>
            <p className="codeError">Error code: "{errorCode}"</p>
            <Link className="btn rojo" to={`/`} title="Ir al inicio">Ir al inicio</Link>
        </div>
    )
}