import React, { useState, useEffect } from 'react';

export function MessageUpdate(props) {
    const {
        cantidad,
        stock,
        updateAction,
        awaitAction
    } = props;

    const update = () => updateAction(cantProduct);
    const recapacitar = () => awaitAction(true);
    const [cantProduct, setCantProduct] = useState(cantidad);

    useEffect(() => {
        if (cantProduct === '') {
            return setCantProduct('');
        }
        else if (cantProduct <= 1) {
            return setCantProduct(1);
        }
        else if (cantProduct >= (stock)) {
            return setCantProduct(stock);
        }
        else if (cantProduct > 99) {
            return setCantProduct(99);
        }
        else { return }

    }, [stock, cantProduct])

    // actualiza cantidad del producto
    const changeCant = evt => {
        if (evt.target.value === '') {
            setCantProduct('');
        }
        if (evt.target.value <= 99) {
            setCantProduct(evt.target.value);
        }
        else {
            setCantProduct(99);
        }
    }

    const lostBlur = (evt) => {
        if (evt.target.value === '') {
            return setCantProduct(1);
        }
        if (cantProduct <= 99) {
            setCantProduct(cantProduct);
        }
        else {
            setCantProduct(99);
        }
    }

    return (
        <div className="modalRemoveAddress">
            <p>Ingresa la cantidad</p>
            <input type="number"
                name="cantidad"
                onChange={changeCant}
                onBlur={lostBlur}
                value={cantProduct}
                pattern="[0-9]*"
                min="1"
                max="99"
                className="inputNumber"
            />
            <div className="contBtns">
                <div className="btn blanco" onClick={recapacitar}>Cancelar</div>
                <div className="btn blanco" onClick={update}>Aceptar</div>
            </div>
        </div>
    )
}