import React, { useState, useEffect } from 'react';
import './style.sass';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';
import { useFilterVerification } from 'hooks/useFilterVerification';
import { urlGetFilterFunction } from 'functions/urlGetFilterFunction';
import getStore from 'services/getStore';
import { PRODUCTS_PER_PAGINATION } from 'constants/general';


import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import FiltrosDeskLogica from 'components/filtrosDesktop/filtrosDesk';
import ErrorSearchServices from 'components/Error/ErrorSearch';
import OficialStore from 'components/OficialStore';
import CardProduct from 'components/CardProduct/ClasicCard';
import Filtros from 'components/filtros/filtros';
import BarraCP from 'components/BarCP/BarHeaderCP';
import Paginador from 'components/Paginator';
import BarraResultados from 'components/filtrosDesktop/barraResultados';
import { anteaterProductAdapter } from 'adapters/products_Adapter';

export default function Tiendas(props) {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Store Result Page');
        window.scrollTo(0, 0);
    }, []);

    const [vista, setVista] = useState(false);
    const [ordenS, setOrdenS] = useState(1);

    // setea orden seleccionado relevancia, mayor precio, menor precio
    const obtenerOrdenSeleccionado = (orden) => {
        setOrdenS(orden);
    };

    // setea vista seleccionada mosaico / lista
    const seEnviaVista = (vistaS) => {
        setVista(vistaS);
    };

    // envia la vista seleccionada al componente desktop
    const seEnviaVistaDesktop = (vistaL, vistaM) => {

        if (vistaL === true) {
            setVista(true);
        }
        if (vistaM === true) {
            setVista(false);
        }
    };

    const { isMobile } = useIsMobile();
    const params = props.location.pathname;
    const [productStore, setProductStore] = useState([]);
    const [searchParams, setSearchParams] = useState({
        busqueda: null,
        idtienda: null,
        subCategory: null,
        marca: null,
        precioMenor: null,
        precioMayor: null,
        categoria: null,
        start: null,
        shipping: null,
        page: null,
        order: null,
        fulfillment: null,
        discount: null
    });

    const filters = urlGetFilterFunction();
    const [paginacion, setPaginacion] = useState(0);
    var arreglopProductos = [];
    const [cantidadPaginas, setCantidadPaginas] = useState();

    // actualiza de parametros cuando cambian
    useEffect(() => {
        updateParamsTiendas();
    }, [params]);

    // update parametros 
    const updateParamsTiendas = () => {
        setSearchParams({
            busqueda: filters.busqueda,
            idtienda: filters.idtienda,
            subCategory: filters.subCategory,
            marca: filters.marca,
            precioMenor: filters.precioMenor,
            precioMayor: filters.precioMayor,
            categoria: filters.categoria,
            page: filters.page,
            order: filters.order,
            start: filters.start,
            shipping: filters.shipping,
            fulfillment: filters.fulfillment,
            discount: filters.discount
        });
    };

    // asigna valores al getStore
    useEffect(() => {

        if (!searchParams.idtienda && !searchParams.subCategory) {
            return
        } else {
            getStore({
                searchText: searchParams.busqueda, idtienda: searchParams.idtienda, subCategory: searchParams.subCategory, brand: searchParams.marca,
                lowerPrice: searchParams.precioMenor, higherPrice: searchParams.precioMayor, id_Category: searchParams.categoria,
                order: searchParams.order, pagination: searchParams.page, start: searchParams.start, shipping: searchParams.shipping, fulfillment: searchParams.fulfillment,
                discount: searchParams.discount
            })
                .then(setProductStore)
            window.scrollTo(0, 0);
        }
    }, [searchParams]);

    const url_Per_Pagination = params.replace('/pagina=' + searchParams.page, '');
    const logitud = params.split('pagina=').pop();

    // funciones para la paginacion
    useEffect(() => {
        if (Number(logitud) < 1) {
            setSearchParams({
                ...searchParams,
                page: 1
            });
        }

        if (Number(logitud) > 100) {
            setSearchParams({
                ...searchParams,
                page: 100
            });
        }
    }, [productStore]);

    // obtiene la cantidad de paginas 
    useEffect(() => {
        if (productStore) {
            if (productStore.searchResult) {
                setCantidadPaginas(Number(Math.ceil(productStore.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION)));
            }
        }
    }, [productStore]);

    // para saber si el resultado de busqueda contiene los filtros: enviogratis, fulfillment, review
    const variablesFiltrosTienda = useFilterVerification(productStore);

    // si el parametro pagina en la url es menor a 1
    if (Number(logitud) < 1) {
        return <Redirect to="pagina=1" />
    }

    // si el parametro pagina en la url es mayor a 100 o el parmetro pagina es mayor que la cantidad total de paginas y la cantidad de paginas es mayor a 100
    if (Number(logitud) > 100) {
        return <Redirect to="pagina=1" />
    }

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
        setUpdateCPBar(val);
    };

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar]);

    const [structData, setStructData] = useState(false)
    const [marcaBrand, setMarcaBrand] = useState(false)
    useEffect(()=> {
    
        if(productStore?.searchResult?.GSP?.RES?.R?.length > 1){
            let dataPerProduct = productStore?.searchResult?.GSP?.RES?.R.map( (item, index) => {
                let product = `{
                    "@type": "ListItem",
                    "position": "${index + 1}",
                    "item": {   
                        "@type": "Product",
                        "image": "${item.MT[item.MT.findIndex(dato => dato.N === "link" )].V}",
                        "url": "https://www.sears.com.mx/producto/${item.MT[item.MT.findIndex(dato => dato.N === "id" )].V}/${item.MT[item.MT.findIndex(dato => dato.N === "title_seo" )].V.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "name": "${item.MT[item.MT.findIndex(dato => dato.N === "title" )].V.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "description": "${item.MT[item.MT.findIndex(dato => dato.N === "description" )].V.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "brand": {
                            "@type": "Brand",
                            "name": "${item.MT[item.MT.findIndex(dato => dato.N === "attribute_marca" )].V.replace(/\n/gi, " ").replace(/"/gi, "")}"
                        },
                        "sku": "${item.MT[item.MT.findIndex(dato => dato.N === "sku" )].V}",
                        "review": {
                            "@type":"Review",
                        "author":{
                            "@type":"Person",
                            "name": "Usuario de Sears"
                            },
                            "reviewRating": {
                                "@type": "Rating",
                                "bestRating": "5",
                                "ratingValue":  "${item.MT[item.MT.findIndex(dato => dato.N === "review" )].V}",
                                "worstRating": "0"
                            }
                        },
                        "offers": {
                            "@type": "Offer",
                            "price": "${item.MT[item.MT.findIndex(dato => dato.N === "sale_price" )].V}"
                        }
                    }
                }`
                return product.replace(/\s+/g, ' ')
            })

            setStructData(`{
                "@context": "https://schema.org",
                "@type": "ItemList",
                "url": "https://www.sears.com.mx${params}",
                "numberOfItems": "${productStore?.searchResult?.GSP?.RES?.R?.length}",
                "itemListElement": [${dataPerProduct}]
            }`)

            setMarcaBrand(productStore?.searchResult?.GSP?.RES?.R[0].MT[productStore?.searchResult?.GSP?.RES?.R[0].MT.findIndex(dato => dato.N === "store" )].V)
        }

    },[productStore])

    let structDataStore = `{
        "@context":"https://schema.org",
        "@graph":[
            {
                "@type":"SearchResultsPage",
                "id":"${window.location.pathname.split("/")[3]}",
                "name":"${window.location.pathname.split("/")[3]}",
                "about":"SEARS.COM.MX | Envíos a todo México · Más de 500 mil productos con todas las Formas de Pago  Compras 100% Seguras · Lo mejor en Moda, Línea blanca, Pantallas, Computo, Celulares, Juguetes, Muebles, Ferretería y más · Click & Recoge en Tienda"
            }
        ]
    }`

    return (
        <>
            <Helmet>
                {marcaBrand
                    ? <title>{`Tienda ${marcaBrand} | Sears.com.mx`}</title>
                    : <title>{`Tienda | Sears.com.mx`}</title>
                }
                <link rel="canonical" href={`https://www.sears.com.mx/tienda/${params.split('/')[2]}/${params.split('/')[3]}/`}  />
                <meta name="Description" content="SEARS.COM.MX | Envíos a todo México · Más de 500 mil productos con todas las Formas de Pago  Compras 100% Seguras · Lo mejor en Moda, Línea blanca, Pantallas, Computo, Celulares, Juguetes, Muebles, Ferretería y más · Click & Recoge en Tienda" />
                <script type="application/ld+json">
                    { structData }
                </script>
                <script type="application/ld+json">
                    { structDataStore }
                </script>
            </Helmet>

            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
            <section className="barCpResponsive">
                <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionCategoryProducts">
                    <div className="container">
                        {productStore &&
                            productStore.searchResult
                            ?
                            <>
                                {isMobile
                                    ? isMobile === 'movil'
                                        ? <div className="contProductCategory">

                                            {/* componente para filtros */}
                                            {Number(productStore.searchResult.GSP.RES.M) !== 0 &&
                                                <Filtros results={productStore.searchResult.GSP.RES.M}
                                                    // searchResults={productStore.searchResult.GSP.RES.M} 
                                                    params={params}
                                                    searchParams={searchParams} searchResults={productStore} setSearchParams={setSearchParams}
                                                    getSearch={getStore} setSearchResults={setProductStore} history={props.history}
                                                    primeraParte={`/tienda/${searchParams.idtienda}/${searchParams.subCategory}`}
                                                    primeraParteConPagina={`/tienda/${searchParams.idtienda}/${searchParams.subCategory}/pagina=` + searchParams.page}

                                                    categoriaP={searchParams.categoria}
                                                    precioMayorP={searchParams.precioMayor} precioMenorP={searchParams.precioMenor}
                                                    startP={searchParams.start} shippingP={searchParams.shipping}
                                                    marcaP={searchParams.marca}
                                                    pageP={searchParams.page} orderP={searchParams.order}
                                                    busquedaP={searchParams.busqueda}
                                                    idtiendaP={searchParams.idtienda}
                                                    subCategoryP={searchParams.subCategory}
                                                    fulfillmentP={searchParams.fulfillment}
                                                    discountP={searchParams.discount}
                                                    minimo={productStore.minimo} maximo={productStore.maximo}
                                                    seEnviaVista={seEnviaVista}
                                                    valorDescuento={productStore.valorDescuento}
                                                    tieneEnvioGratis={productStore.tieneEnvioGratis}
                                                    tieneFulfillment={productStore.tieneFulfillment}
                                                    tieneReview={productStore.tieneReview}
                                                />
                                            }

                                            {/* si no se encontraron resultados de busqueda con el filtro aplicado*/}
                                            {Number(productStore.searchResult.GSP.RES.M) === 0
                                                && ((searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, envio, precios
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, precios
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //envio, precios
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // marca
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, marca
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, estrellas
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, envio
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,estrellas
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio,marca
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas,envio
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //categoria,marca,precios-
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,estrellas
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas,marcas,precios,
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //marca,precios,envio
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,envio,estrellas
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,estrellas,envio
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,marca,categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,marca,categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,envio,categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio,estrellas
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, categoria,marca,envios,estrella
                                                    || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca,envios,estrella

                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, envio
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment,marca, precios
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, envio
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, envio 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, envio, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas, envio, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, envio, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrella, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, envio
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, envio, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, estrella, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, envio, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas, envio, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrellas, envio
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, estrellas
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, envio, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, envio, 
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrellas, envio
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, envio, categoria
                                                    || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, estrellas, envio, marca, categoria, precios


                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount estrellas precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping precios
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount fulfillment precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca categoria precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca start precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca shipping precios
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca fulfillment precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria start precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria shipping precios
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria fulfillment precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start shipping precios
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start fulfillment precios
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping fulfillment precios
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // disocunt marca categoria shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start 
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios start shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start shipping
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping fulfillment
                                                    || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria marca start shipping fulfillment precios
                                                )

                                                ? <>
                                                    <div className="sinResultados">
                                                        <div className="imagen">
                                                            <img src="/img/searchError.svg" alt="No se encontraron resultados" width="120" height="120" loading="lazy" />
                                                        </div>

                                                        <div className="texto">
                                                            <h2> Se encontraron 0 resultados</h2>
                                                            <p> Por favor intenta con un filtro diferente</p>
                                                        </div>

                                                    </div>
                                                </>
                                                // si no se encontraron resultados de busqueda
                                                : (Number(productStore.searchResult.GSP.RES.M) === 0 && (searchParams.discount == null && searchParams.fulfillment == null && searchParams.categoria == null && searchParams.marca == null && searchParams.precioMayor == null && searchParams.precioMenor == null && searchParams.shipping == null && searchParams.start == null)
                                                )
                                                    ? <ErrorSearchServices searchQ={productStore.searchResult.GSP.Q} suggestion={productStore.searchResult.GSP.Spelling ? productStore.searchResult.GSP.Spelling.Suggestion.V : null} />
                                                    : null
                                            }

                                            {/* si encontro resultados trae el nombre de la tienda */}
                                            {Number(productStore.searchResult.GSP.RES.M) !== 0 &&
                                                productStore.searchResult.GSP.RES.M != undefined &&
                                                productStore.searchResult.GSP.RES.R.length > 0
                                                ? <OficialStore store={productStore.searchResult.GSP.RES.R[0].MT} logo={'store_logo'} title={'store'} />
                                                : productStore.searchResult.GSP.RES.R
                                                    ? <OficialStore store={productStore.searchResult.GSP.RES.R.MT} logo={'store_logo'} title={'store'} />
                                                    : null
                                            }

                                            {/* si encontro resultados trae los productos */}
                                            {Number(productStore.searchResult.GSP.RES.M) !== 0 &&
                                                <>
                                                    {(productStore.searchResult.GSP.RES.R) != undefined
                                                        ?
                                                        ((productStore.searchResult.GSP.RES.R).length > 1
                                                            ? productStore.searchResult.GSP.RES.R.map(({ MT }, index) => {
                                                                arreglopProductos.push({
                                                                    nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                    nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                    precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                    id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                    precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                    foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                    descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                    store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                    review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                    total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                    shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                    fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                    breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                    attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                    telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                    telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                    credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                    credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                    index: index,
                                                                    cyr: MT[MT.findIndex(item => item.N === "cyr")] ? MT[MT.findIndex(item => item.N === "cyr")].V : null,
                                                                    promotions: MT[MT.findIndex(item => item.N === "promotions")] ? MT[MT.findIndex(item => item.N === "promotions")].V : null,
                                                                })
                                                            }
                                                            )

                                                            : [productStore.searchResult.GSP.RES.R].map(({ MT }, index) => {
                                                                arreglopProductos.push({
                                                                    nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                    nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                    precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                    id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                    precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                    foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                    descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                    store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                    review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                    total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                    shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                    fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                    breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                    attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                    telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                    telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                    credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                    credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                    index: index,
                                                                    cyr: MT[MT.findIndex(item => item.N === "cyr")] ? MT[MT.findIndex(item => item.N === "cyr")].V : null,
                                                                    promotions: MT[MT.findIndex(item => item.N === "promotions")] ? MT[MT.findIndex(item => item.N === "promotions")].V : null,
                                                                })
                                                            })
                                                        )
                                                        : null
                                                    }

                                                    {arreglopProductos.map((producto, id) =>
                                                        <CardProduct
                                                            key={id} id={producto?.id}
                                                            nombre={producto?.nombre} nombreSEO={producto?.nombreSEO}
                                                            precio={producto?.precio} precioLista={producto?.precioLista}
                                                            foto={producto?.foto} descuento={producto?.descuento}
                                                            store={producto?.store} review={producto?.review}
                                                            shipping_price={producto?.shipping_price} fulfillment={producto?.fulfillment}
                                                            categories={producto?.breadcrumbs} brand={producto?.attribute_marca}
                                                            vista={vista}
                                                            creditCardMonths={producto?.credit_card_months}
                                                            creditCardPrice={producto?.credit_card_price}
                                                            telmexMonths={producto?.telmex_months}
                                                            telmexPrice={producto?.telmex_price}
                                                            comments={producto?.total_review}
                                                            index={producto?.index}
                                                            clicRecoge={producto?.cyr}
                                                            pagoSears={producto?.promotions}
                                                            priority={producto?.index <= 12 ? "High" : null}
                                                            productTotal={anteaterProductAdapter(arreglopProductos[producto?.index])}
                                                        />
                                                    )}
                                                </>
                                            }
                                        </div>
                                        : isMobile === 'desktop'
                                            ? <div className="contProductCategoryDesk">
                                                {Number(productStore.searchResult.GSP.RES.M) !== 0 &&
                                                    <div className="boxFiltrosDesk">
                                                        <FiltrosDeskLogica
                                                            searchParams={searchParams}
                                                            results={productStore.searchResult.GSP.RES.M}
                                                            // searchResults={productStore.searchResult.GSP.RES.M}
                                                            searchResults={productStore}
                                                            getSearch={getStore}
                                                            setSearchResults={setProductStore}
                                                            setSearchParams={setSearchParams} history={props.history} params={params}
                                                            primeraParte={`/tienda/${searchParams.idtienda}/${searchParams.subCategory}`}
                                                            marcaP={searchParams.marca}
                                                            tieneEnvioGratis={productStore.tieneEnvioGratis}
                                                            tieneFulfillment={productStore.tieneFulfillment}
                                                            tieneReview={productStore.tieneReview}
                                                            minimo={productStore.minimo} maximo={productStore.maximo}
                                                            ordenS={ordenS} setOrdenS={setOrdenS}
                                                            valorDescuento={productStore.valorDescuento}
                                                        />
                                                    </div>
                                                }

                                                <div className={Number(productStore.searchResult.GSP.RES.M) !== 0 ? "boxGeneralProductosResultados" : "boxSinResultados"}>
                                                    {Number(productStore.searchResult.GSP.RES.M) !== 0 &&
                                                        <BarraResultados results={productStore.searchResult.GSP.RES.M} seEnviaVista={seEnviaVistaDesktop} vista={vista}
                                                            primeraParte={`/tienda/${searchParams.idtienda}/${searchParams.subCategory}`}
                                                            history={props.history} params={params}
                                                            searchParams={searchParams} setSearchParams={setSearchParams}
                                                            obtenerOrdenSeleccionado={obtenerOrdenSeleccionado}
                                                        />
                                                    }
                                                    <div className="boxProductosCategory">
                                                        {/* si no se encontraron resultados de busqueda con el filtro aplicado*/}
                                                        {Number(productStore.searchResult.GSP.RES.M) === 0
                                                            && ((searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, envio, precios
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, precios
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //envio, precios
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // marca
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, marca
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, estrellas
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, envio
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,estrellas
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio,marca
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas,envio
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //categoria,marca,precios-
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,estrellas
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas,marcas,precios,
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //marca,precios,envio
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,envio,estrellas
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,estrellas,envio
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,marca,categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,marca,categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,envio,categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio,estrellas
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, categoria,marca,envios,estrella
                                                                || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca,envios,estrella

                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, envio
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment,marca, precios
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, envio
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, envio 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, envio, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas, envio, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, envio, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrella, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, envio
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, envio, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, estrella, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, envio, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas, envio, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrellas, envio
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, estrellas
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, envio, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, envio, 
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrellas, envio
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, envio, categoria
                                                                || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, estrellas, envio, marca, categoria, precios


                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount estrellas precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping precios
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount fulfillment precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca categoria precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca start precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca shipping precios
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca fulfillment precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria start precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria shipping precios
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria fulfillment precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start shipping precios
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start fulfillment precios
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping fulfillment precios
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // disocunt marca categoria shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start 
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios start shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start shipping
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping fulfillment
                                                                || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria marca start shipping fulfillment precios
                                                            )

                                                            ? <>
                                                                <div className="sinResultados">
                                                                    <div className="imagen">
                                                                        <img src="/img/searchError.svg" alt="No se encontraron resultados" width="120" height="120" loading="lazy" />
                                                                    </div>

                                                                    <div className="texto">
                                                                        <h2> Se encontraron 0 resultados</h2>
                                                                        <p> Por favor intenta con un filtro diferente</p>
                                                                    </div>

                                                                </div>
                                                            </>
                                                            // si no se encontraron resultados de busqueda
                                                            : (Number(productStore.searchResult.GSP.RES.M) === 0 && (searchParams.discount == null && searchParams.fulfillment == null && searchParams.categoria == null && searchParams.marca == null && searchParams.precioMayor == null && searchParams.precioMenor == null && searchParams.shipping == null && searchParams.start == null))
                                                                ? <ErrorSearchServices searchQ={productStore.searchResult.GSP.Q} suggestion={productStore.searchResult.GSP.Spelling ? productStore.searchResult.GSP.Spelling.Suggestion.V : null} />
                                                                : null
                                                        }

                                                        {/* si encontro resultados trae el nombre de la tienda */}
                                                        {Number(productStore.searchResult.GSP.RES.M) !== 0 &&
                                                            productStore.searchResult.GSP.RES.M != undefined &&
                                                            productStore.searchResult.GSP.RES.R.length > 0
                                                            ? <OficialStore store={productStore.searchResult.GSP.RES.R[0].MT} logo={'store_logo'} title={'store'} />
                                                            : productStore.searchResult.GSP.RES.R
                                                                ? <OficialStore store={productStore.searchResult.GSP.RES.R.MT} logo={'store_logo'} title={'store'} />
                                                                : null
                                                        }

                                                        {/* si encontro resultados trae los productos */}
                                                        {Number(productStore.searchResult.GSP.RES.M) !== 0 &&
                                                            <>
                                                                {(productStore.searchResult.GSP.RES.R) != undefined
                                                                    ?
                                                                    ((productStore.searchResult.GSP.RES.R).length > 1
                                                                        ? productStore.searchResult.GSP.RES.R.map(({ MT }, index) => {
                                                                            arreglopProductos.push({
                                                                                nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                                nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                                precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                                id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                                precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                                foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                                descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                                store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                                review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                                total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                                shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                                fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                                breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                                attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                                telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                                telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                                credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                                credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                                index: index,
                                                                                cyr: MT[MT.findIndex(item => item.N === "cyr")] ? MT[MT.findIndex(item => item.N === "cyr")].V : null,
                                                                                promotions: MT[MT.findIndex(item => item.N === "promotions")] ? MT[MT.findIndex(item => item.N === "promotions")].V : null,
                                                                            })
                                                                        }
                                                                        )

                                                                        : [productStore.searchResult.GSP.RES.R].map(({ MT }, index) => {
                                                                            arreglopProductos.push({
                                                                                nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                                nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                                precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                                id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                                precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                                foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                                descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                                store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                                review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                                total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                                shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                                fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                                breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                                attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                                telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                                telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                                credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                                credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                                index: index,
                                                                                cyr: MT[MT.findIndex(item => item.N === "cyr")] ? MT[MT.findIndex(item => item.N === "cyr")].V : null,
                                                                                promotions: MT[MT.findIndex(item => item.N === "promotions")] ? MT[MT.findIndex(item => item.N === "promotions")].V : null,
                                                                            })
                                                                        })
                                                                    )
                                                                    : null
                                                                }

                                                                {arreglopProductos.map((producto, id) =>
                                                                    <CardProduct
                                                                        key={id} id={producto?.id}
                                                                        nombre={producto?.nombre} nombreSEO={producto?.nombreSEO}
                                                                        precio={producto?.precio} precioLista={producto?.precioLista}
                                                                        foto={producto?.foto} descuento={producto?.descuento}
                                                                        store={producto?.store} review={producto?.review}
                                                                        shipping_price={producto?.shipping_price} fulfillment={producto?.fulfillment}
                                                                        categories={producto?.breadcrumbs} brand={producto?.attribute_marca}
                                                                        vista={vista}
                                                                        creditCardMonths={producto?.credit_card_months}
                                                                        creditCardPrice={producto?.credit_card_price}
                                                                        telmexMonths={producto?.telmex_months}
                                                                        telmexPrice={producto?.telmex_price}
                                                                        comments={producto?.total_review}
                                                                        index={producto?.index}
                                                                        clicRecoge={producto?.cyr}
                                                                        pagosSears={producto?.promotions}
                                                                        priority={producto?.index <= 12 ? "High" : null}
                                                                        productTotal={anteaterProductAdapter(arreglopProductos[producto?.index])}
                                                                    />
                                                                )}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    : null
                                }
                                {/* paginacion */}
                                <Paginador setPaginacion={setPaginacion} searchParams1={searchParams} searchParams={searchParams} url_Per_Pagination={url_Per_Pagination} searchResults={productStore} setSearchParams={setSearchParams} />
                            </>
                            : null
                        }
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}