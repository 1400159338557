import React, { useState, useEffect } from 'react';
import './style.sass';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { SelecDireccionTemplateDesktop } from 'pages/MiCuenta/MisDirecciones/SeleccionarDireccion/SeleccionaDirecionDesktop';
import { SelecDireccionTemplateMobile } from 'pages/MiCuenta/MisDirecciones/SeleccionarDireccion/SeleccionaDirecionMobile';
import { getAddress, deleteAddress, putDefaultAddress } from 'services/Address';
import { useStorage64 } from 'hooks/useStorageBase64';
import { useIsMobile } from 'hooks/useIsMobile';

import { decodeStorage } from 'functions/storageBase64';
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';

export default function SeleccionarDireccion() {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account | Selected Address Page');
        window.scrollTo(0, 0);
    }, [])

    const { isMobile } = useIsMobile();
    const [loading, setLoading] = useState(true);
    const [rawAddress, setRawAddress] = useState({});
    const [address, setAddress] = useState({});
    const [defaultAddress, sendDefaultAddress] = useState('');
    const [updatePredet, sendUpdatePredet] = useState(false);
    const [deleteAddres, sendDeleteAddress] = useState('');
    const [responseDelete, sendResponseDelete] = useState('');
    const [errorText, setErrorText] = useState('');
    const [direcctionsData, setDirecctionsData] = useStorage64('sendDirections', '');
    const history = useHistory();


    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession, atExp, actTime} = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "selectAddress", timeRef: 1500})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        if(responseDelete || updatePredet){
            checkValidateSession(true)
            setActionOk('ready')
        }
        else{
            setTimeout(function(){
                checkValidateSession(true)
                setActionOk('ready')
            },700)
        }
    }, [responseDelete, updatePredet])
     // Selecciona la direccion por default
    useEffect(() => {
        if (defaultAddress) {
            checkValidateSession(true)
            setActionOk('defaultAddress')
        }
    }, [defaultAddress])
    // manda el id de la direccion a eliminar
    useEffect(() => {
        if (deleteAddres) {
            checkValidateSession(true)
            setActionOk('deleteAddress')
        }
    }, [deleteAddres])

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            // Va al servicio para traer las direcciones
            if(actionOK === 'ready'){
                setServiceRT(false)
                getAddress()
                .then(setRawAddress)
                setActionOk(false)
            }
            else  if(actionOK === 'defaultAddress'){
                setServiceRT(false)
                putDefaultAddress({ id: defaultAddress })
                .then(sendUpdatePredet)
                setActionOk(false)
            }
            else  if(actionOK === 'deleteAddress'){
                setServiceRT(false)
                deleteAddress({ idAddress: deleteAddres })
                .then(sendResponseDelete)
                setActionOk(false)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */



    // Formatea la respuesta para ser generica
    useEffect(() => {
        if (rawAddress) {
            if (rawAddress.data) {
                if (rawAddress.statusCode === 200) {
                    if (typeof (rawAddress.data) === 'object') {
                        setAddress(rawAddress.data.data);
                    }
                    else {
                        setAddress({});
                        setErrorText(rawAddress.data);
                        setLoading(false);
                    }
                }
                else if (rawAddress.statusCode !== undefined) {
                    let response = JSON.parse(rawAddress.data);
                    setAddress({});
                    setErrorText(response.data.msg);
                    setLoading(false);
                }
            }
        }
    }, [rawAddress])

    // Pide las direcciones y evalua si tiene alguna direccion
    useEffect(() => {
        if (address) {
            if (Object.keys(address).length) {
                if (Object.keys(address.address).length) {
                    setLoading(false);
                    setDirecctionsData(address);
                }
                else {
                    history.replace("/mi-cuenta/direcciones-envio/sin-direcciones");
                    // history.replace('/mi-cuenta/direcciones-envio/agregar');
                }
            }
        }
    }, [address])


    // recibe el id de la direccion predeterminada
    function changeDefaultA(val) {
        sendDefaultAddress(val);
        setLoading(true);
    }

    // funcion para borrar el producto regresa el id
    function removeValueA(val) {
        sendDeleteAddress(val);
        setLoading(true);
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            {loading
                ? <Loading />
                : null
            }

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} headerEnCategoria={"headerListaDeseos"} tituloPagina="Mis direcciones de envío" />
            {isMobile && (isMobile === 'desktop' || isMobile === 'movile')
                ? <section className="barCpResponsive">
                    <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
                </section>
                : null
            }

            {isMobile
                ? isMobile === 'desktop'
                    ? <SelecDireccionTemplateDesktop
                        rawAddress={rawAddress}
                        address={address}
                        errorText={errorText}
                        changeDefaultA={changeDefaultA}
                        removeValueA={removeValueA}
                    />
                    : <SelecDireccionTemplateMobile
                            rawAddress={rawAddress}
                            address={address}
                            errorText={errorText}
                            changeDefaultA={changeDefaultA}
                            removeValueA={removeValueA}
                        />
                : null
            }
            <Footer />
        </>
    )
}