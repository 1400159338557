//New
export const getReviewAdapter = (data) =>{
    
    let newData
    if(data){
        let reviews = data?.producto?.reviews?.commentaries?.map(rev =>{
            return {
                "id": 403709923,
                "score": rev?.score,
                "votes_up": 0,
                "votes_down": 0,
                "content": rev?.commentary,
                "title": rev?.title,
                "created_at": "2021-01-08T00:00:00.000Z",
                "deleted": false,
                "verified_buyer": true,
                "source_review_id": null,
                "sentiment": null,
                "custom_fields": null,
                "product_id": data?.producto?.id,
                "user": {
                    "user_id": 0,
                    "social_image": null,
                    "user_type": "User",
                    "is_social_connected": 0,
                    "display_name":rev?.nickname
                }
            }
        })

        newData = {
            "pagination": {
                "page": 1,
                "per_page": "100",
                "total": data?.producto?.reviews?.total_reviews
            },
            "bottomline": {
                "total_review": data?.producto?.reviews?.total_reviews,
                "average_score": data?.producto?.reviews?.score,
                "total_organic_reviews": 0,
                "organic_average_score": 0,
                "star_distribution": {
                    "1": data?.producto?.reviews?.stars?.star_1,
                    "2": data?.producto?.reviews?.stars?.star_2,
                    "3": data?.producto?.reviews?.stars?.star_3,
                    "4": data?.producto?.reviews?.stars?.star_4,
                    "5": data?.producto?.reviews?.stars?.star_5
                },
                "custom_fields_bottomline": null
            },
            "grouping_data": [],
            "products": {
                "id": data?.producto?.id,
                "domain_key": "",
                "name": "",
                "social_links": {
                    "linkedin": "",
                    "facebook": "",
                    "twitter": "",
                    "google_oauth2": ""
                },
                "embedded_widget_link": "",
                "testimonials_product_link": "",
                "product_link": "",
                "image_url": ""
            },
            "product_tags": null,
            "reviews": reviews ? reviews : []
        }
    }

    // console.log('data1: ', data)
    // console.log('newData1: ', newData)

    return {
        response: newData
    } 
}

//Old
export const getReviewAdapterOld = (data) =>{
    
    let newData
    if(data){
        newData = {
            ...data
        }
    }

    // console.log('data: ', data)
    // console.log('newData: ', newData)

    return newData
}