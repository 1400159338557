import React from 'react'
import './style.sass'
import { Link } from "react-router-dom"
import { NO_IMAGE } from 'constants/general'
import Slider from "react-slick"
import { repareImage, repareTitleSEO, repareSectionURL } from 'functions/repareDataService'
import { dataLayerTapsQuick } from 'functions/dataLayerFunction'

export function QuickAccessMobile({ quickAccess }) {
    return (
        <div className="contentQuickAccess quickMovil">
            <div className="container">
                <div className="quickAccess">
                    {
                        quickAccess.map(({ name, link, image }, index) =>
                            typeof(link) !== 'function'
                                ?
                                    <ModuleQuickA
                                        key={index}
                                        index={index}
                                        name={name}
                                        link={link}
                                        image={repareImage(image)}
                                    ></ModuleQuickA>
                                : null
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export function QuickAccessMobileNew(props){
        const {
            quickAccess,
            styleView
        } = props

    return (
        <div className="contentQuickAccess quickMovil">
            <div className="container">
                <div className="quickAccess">
                    {quickAccess.map(({ name, link, image }, index) =>
                        typeof(link) !== 'function'
                            ?
                                <ModuleQuickANew
                                    key={index}
                                    index={index}
                                    name={name}
                                    link={link}
                                    image={repareImage(image)}
                                ></ModuleQuickANew>
                            : null
                    )}
                </div>
            </div>
        </div>
    )
}

export function ModuleQuickA({ ...props }) {
    const {
        index,
        name,
        link,
        image
    } = props

    let url
    let newSeo = '/' + repareTitleSEO(name) + '/pagina=1'

    if (index === 0) {
        url = '/categoria'
    } else {
        url = repareSectionURL(link) + newSeo
    }

    return (
        <Link to={url} title={name} >
            <img
                loading="eager"
                src={image != null ? image : NO_IMAGE}
                alt={name}
                width="78"
                height="78"
                decoding="async"
                fetchpriority="high"
            />
            <p>{name}</p>
        </Link>
    )
}

export function ModuleQuickANew({ ...props }) {
    const {
        index,
        name,
        link,
        image
    } = props


    const clickBotonTaggeo = (index, name, link ) => {
        //console.log('este el quick numero: ', index)
        dataLayerTapsQuick(index, name, link);
    }

    let url

    const seccionLink = link.split('/')[1]

    if (seccionLink === "categoria") {
        if (link.split('/')[3] === undefined) {
            let newSeo = '/' + repareTitleSEO(name) + '/pagina=1'
            url = link.replace('/landing/categorias', '/categoria').replace(/\/$/g, '') + newSeo
        } else {
            url = link.replace('/landing/categorias', '/categoria').replace(/\/$/g, '') + '/pagina=1'
        }
    } else {
        if (index === 0) {
            url = '/categoria'
        } else {
            if (link) {

                url = repareSectionURL(link) + '/pagina=1'
            }
        }
    }

    return (
        <>
            {seccionLink === "c"
                ?
                <a href={link} title={name} onClick={ (event) => clickBotonTaggeo(index, name, link)} >
                    <img
                        loading="eager"
                        src={image + '?scale=80' != null ? image + '?scale=80' : NO_IMAGE}
                        alt={name}
                        width="78"
                        height="78"
                        decoding="async"
                        fetchpriority="high"    
                    />
                    <p>{name}</p>
                </a>
                :
                url &&
                <Link to={url} title={name} onClick={ (event) => clickBotonTaggeo(index, name, link)} >
                    <img
                        loading="eager"
                        src={image + "?scale=80" != null ? image + "?scale=80" : NO_IMAGE}
                        alt={name}
                        width="78"
                        height="78"
                        decoding="async"
                        fetchpriority="high"
                    />
                    <p>{name}</p>
                </Link>
            }
        </>
    )
}

export function QuickAccess(props) {
    const {
        quickAccess,
        styleView
    } = props

    const settings = {
        infinite: true,
        arrows: true,
        dots: false,
        speed: 700,
        swipe: true,
        touchMove: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        centerMode: false,
        variableWidth: true

    };

    return (
        <div className="contentQuickAccess">
            <div className="container">
                <div className="quickAccess">
                    <Slider {...settings} id="sliderTiendas">
                        {
                            quickAccess.map(({ name, link, image }, index) =>
                                typeof(link) !== 'function'
                                    ?
                                        <ModuleQuickANew
                                            key={index}
                                            index={index}
                                            name={name}
                                            link={link}
                                            image={repareImage(image)}
                                        ></ModuleQuickANew>
                                    : null
                            )
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}