import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from 'pages/Header/HeaderSimple';
import './style.sass';

export default function ServicioCliente() {
    const history = useHistory()

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Client Section Services');
        window.scrollTo(0, 0)
    }, [])

    const backHistory = () => {
        // history.goBack(-1);
        window.location.href = "/menu-mas"
    }

    return (
        <>
            <Header pageClass="hidden" />
            <div className="sectionTitleServicioCliente">
                <button onClick={backHistory}>¿Necesitas ayuda?</button>
            </div>

            <div className="opcionesCliente">
                <ul className="listaExterna">
                    <Link to="/preguntas-frecuentes" className="enlaces">
                        <li className="telefono"><span className="nombre">Preguntas Frecuentes</span> <span className="icono"></span></li>
                    </Link>

                    <Link to="/dudas-comentarios" className="enlaces">
                        <li className="telefono"><span className="nombre">Dudas y comentarios</span> <span className="icono"></span></li>
                    </Link>

                    <Link to="/formas-pago" className="enlaces">
                        <li className="telefono"><span className="nombre">Formas de Pago</span> <span className="icono"></span></li>
                    </Link>

                    <li className="verified">
                        <span className="nombre">Políticas</span>
                    </li>
                    <ul className="listaInterna">
                        <Link to={"/politica-devolucion/"} className="enlaces">
                            <li><span className="nombreI">Devolución</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/politica-cancelacion"} className="enlaces">
                            <li><span className="nombreI">Cancelación</span><span className="icono"></span></li>
                        </Link>

                        <Link to={"/politica-envio"} className="enlaces">
                            <li><span className="nombreI">Envío</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/politica-compra"} className="enlaces">
                            <li><span className="nombreI">Compra</span><span className="icono"></span></li>
                        </Link>
                    </ul>

                </ul>
            </div>
        </>
    )
}