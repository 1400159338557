import React from 'react'
import './style.sass'
import { NavLink, useHistory } from "react-router-dom"

import { HeaderMiCuenta } from 'components/Header/HeaderMiCuenta'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { VARIANTS_PER_PORTAL } from 'constants/general'
import { BurbleCredit } from 'components/Burble/Credit'


export function HeaderBarMovile(props){

    const {
        isLoged,
        profile
    } = props

    const hostName = window.location.origin;
    const history = useHistory();
    const pathName = window.location.pathname

    return(
        <div className="ocultarMenu2">
            <BurbleCredit />
            {VARIANTS_PER_PORTAL?.menuMobile.map( ({title, iconClass, url, privateUrl, exactUrl, anchor, showOnlyLogin}, index) => 
                {
                    return(
                        <ErrorBoundary key={index}>
                            {
                                !showOnlyLogin || isLoged
                                    ?
                                        title !== "Registrate"
                                            ? Boolean(anchor) !== true
                                                ?
                                                    <NavLink
                                                        exact={exactUrl}
                                                        to={privateUrl ? isLoged ? privateUrl : `/login?redirect=${hostName}${privateUrl}` : url}
                                                        className={iconClass}
                                                        activeClassName="active"
                                                        aria-label={`Ir a menu ${title}`}
                                                        //data-url={privateUrl ? isLoged ? privateUrl : `/login?redirect=${hostName}/${privateUrl}` : url}
                                                    >
                                                        <span className="textReferenceMo">
                                                            <span className="smallTextMo">{title}</span>
                                                        </span>
                                                    </NavLink>
                                                :
                                                    <a
                                                        href={privateUrl ? privateUrl : url}
                                                        className={pathName === privateUrl || pathName === url ? `${iconClass} active` : iconClass}
                                                        aria-label={`Ir a menu ${title}`}
                                                    >
                                                        <span className="textReferenceMo">
                                                            <span className="smallTextMo">{title}</span>
                                                        </span>
                                                    </a>
                                            : <HeaderMiCuenta
                                                exactUrl={exactUrl}
                                                ruta={privateUrl ? isLoged ? privateUrl : `/login?redirect=${hostName}${privateUrl}` : url}
                                                className={iconClass}
                                                anchor={anchor}
                                                showOnlyLogin={showOnlyLogin}
                                                title={title}
                                                profile={profile}
                                            />
                                    : null
                            }
                        </ErrorBoundary>
                    )}
                )
            }
        </div>
    )
}