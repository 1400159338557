import React, {Fragment} from 'react';
import { VARIANTS_PER_PORTAL } from 'constants/general'
import { Link } from "react-router-dom";
import { BurbleCredit } from 'components/Burble/Credit'
import {URL_SERVICIO} from 'constants/general';

export function HeaderPromo() {

    const marcas = [
        {
            "indicador": 1,
            "marcas": [
                { "id": 3452, "url": "home nature", "logo": "/img/marcas/home-nature.png" },
                { "id": 3506, "url": "vintage home", "logo": "/img/marcas/vintage.png" },
                { "id": 3453, "url": "craftsman", "logo": "/img/marcas/craftsman.png" },
                { "id": 3455, "url": "pole position", "logo": "/img/marcas/pole.png" },
                { "id": 3456, "url": "philosphy", "logo": "/img/marcas/philosophylogo.png" },
                { "id": 3460, "url": "elle", "logo": "/img/marcas/elle.png" },
                { "id": 3461, "url": "basel", "logo": "/img/marcas/basel.png" },
                { "id": 3457, "url": "carlo corinto", "logo": "/img/marcas/Carlo-Corinto.png" },
                { "id": 3462, "url": "fukka", "logo": "/img/marcas/fukka.png" },
                { "id": 3459, "url": "jeanious company", "logo": "/img/marcas/jeanious.png" },
                { "id": 3458, "url": "carosello", "logo": "/img/marcas/carosello.png" },
                { "id": 3502, "url": "for intelligent trainers", "logo": "/img/marcas/trainer.png" },
                { "id": 3503, "url": "romero britto", "logo": "/img/marcas/britto.png" }
            ]
        },
        {
            "indicador": 2,
            "marcas": [
                { "id": 3497, "url": "levis", "logo": "/img/marcas/levis.png" },
                { "id": 3500, "url": "dockers", "logo": "/img/marcas/dockers.png" },
                { "id": 3501, "url": "chaps", "logo": "/img/marcas/chaps.png" },
                { "id": 3501, "url": "bosch", "logo": "/img/marcas/bosch.png" },
            ]
        }
    ]

    const showCredit = VARIANTS_PER_PORTAL.menuMobile.find(pos => pos.iconClass === "icoCredito" )

    return (
        <nav className="promoHeader">
            <ul className="ulMenuMarcas">
                {/* <li className="menuMarcas">
                    <span>Nuestras Marcas</span>
                    <div className="contentMarcas">
                        <ul className="ulMarcas">
                            {marcas.map((marca, id) =>
                                <Fragment key={id}>
                                    {marca.indicador === 1
                                        ? <div className="nuestras"><p>Nuestras Marcas</p></div>
                                        : <div className="otras"><p>Otras Marcas</p></div>
                                    }

                                    {marca.marcas.map((mar, ide) =>
                                        <li className="liMarcas" key={ide}>
                                            <Link to={`/resultados/q=${mar.url}/marca=${mar.url}/pagina=1`}>
                                                <img src={mar.logo} alt={mar.url}></img>
                                            </Link>
                                        </li>
                                    )}
                                </Fragment>
                            )}
                        </ul>
                    </div>
                </li> */}

                <li className="menuMesa">
                    {/* <a href="/Mesa-de-Regalos/" target="_blank" rel="noopener noreferrer">Mesa de Regalos</a> */}
                    {/* <Link to="/Mesa_Regalos?utm_source=direct&utm_medium=menu-superior-home-sears-com&utm_campaign=mesa_de_regalos">Mesa de Regalos</Link> */}
                    <a href="/Mesa-de-Regalos/" title="Mesa de Regalos">Mesa de Regalos</a>
                </li>
                {showCredit &&
                    <li className="menuCredito">
                        <a href={`${showCredit.url}`} >Crédito</a>
                        <BurbleCredit />
                    </li>
                }
                <li className="menuCredito">
                    <Link to={`/localizador-tiendas/`} >Localiza tu tienda</Link>
                </li>
                <li className="menuCredito">
                    <a href={URL_SERVICIO} rel="noopener noreferrer" >Servicios</a>
                </li>    
                <li className="menuCredito">
                    <a href="https://serviciosgfi.inbursa.com:4070/multiplataforma/mvc/others/autotalPublico/init?ca=PUBLICO&pa=P&pro=SEARS#no-back-button" target="blank_" rel="noopener noreferrer" >Seguros</a>
                </li>   
            </ul>
        </nav>
    )
}